import AppBar from "@material-ui/core/AppBar";
import Badge from "@material-ui/core/Badge";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AvTimerIcon from "@material-ui/icons/AvTimer";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import NotificationsIcon from "@material-ui/icons/Notifications";
import PersonIcon from "@material-ui/icons/Person";
import ScheduleIcon from "@material-ui/icons/Schedule";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import clsx from "clsx";
import Echo from "laravel-echo";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import {
  Link,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { toast } from "react-toastify";
import auth from "../../routingRules/auth";
import AddPrescription from "./AddPrescription";
import AddSchedule from "./AddSchedule";
import Appointments from "./Appointments";
import "./css/siderHeader.css";
import PatientHistory from "./PatientHistory";
import Prescriptions from "./Prescriptions";
import Profile from "./profile";
import ResetPassword from "./reset-password";
import Schedules from "./Schedules";
import ShowPrescriptionDetails from "./ShowPrescriptionDetails";
import VideoConference from "./VideoConference";
import ViewScheduleDetails from "./ViewScheduleDetails";
import Pusher from "pusher-js";
import TestList from "./invoices/TestList";
import TestDetails from "./invoices/TestDetails";
import { DoctorAPI } from "../../AxiosRequest/DoctorAPI";
import ReferDocTestList from "./ReferDocTestList";
import { Icon } from "@material-ui/core";
import AddTestInvoice from "./AddTestInvoice";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  nested: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    fontSize: 14,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },

  appBar: {
    backgroundColor: "#3f4d67",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },

  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  hide: {
    display: "none",
  },
  drawerPaper: {
    backgroundColor: "#3f4d67",
    paddingBottom: "15vh",
    width: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      top: "65px",
    },
    [theme.breakpoints.down("sm")]: {
      top: "55px",
    },
    [theme.breakpoints.down("xs")]: {
      top: "0",
    },
  },

  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: "4rem",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  list: {
    color: "#a9b7d0",
    "&:hover": {
      color: "#fff!important",
      // backgroundColor: "blue!important",
    },
  },
  selected: {
    color: "#fff",
    backgroundColor: "rgba(255, 201, 201, 0.08)!important",
    "&:hover": {
      // color: "#fff",
    },
    // background: "blue",
  },
}));

function Main(props) {
  let history = useHistory();
  let match = useRouteMatch();
  const classes = useStyles();
  const theme = useTheme();
  const [mainDoc, setMainDoc] = React.useState(true);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [notificationData, setNotificationData] = React.useState([]);
  const [onlinePatients, setOnlinePatients] = React.useState([]);
  const token = JSON.parse(localStorage.getItem("HHDoctorToken"));
  const options = {
    broadcaster: "pusher",
    key: `${process.env.REACT_APP_PUSHER_KEY}`,
    cluster: `${process.env.REACT_APP_PUSHER_CLUSTER}`,
    encrypted: true,
    authEndpoint: `${process.env.REACT_APP_BASE_URL}/broadcasting/users/auth`,

    auth: {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    },
  };
  window.Echo = new Echo(options);
  useEffect(() => {
    var doctorId = localStorage.getItem("doctorId");
    window.Echo.private(`doctor.activity.${doctorId}`).listen(
      ".doctor.activities",
      (e) => {
        addNotification(e);

        toast.success(e.message);
        console.log(e);
      }
    );

    var channel = window.Echo.join("online");
    channel
      .here((members) => {
        // For example
        console.log("here", members);
        // addPatientsOnline(members);
      })
      .joining((member) => {
        // For example
        console.log("joining", member);
        jointPatientsOnline(member);
      })
      .leaving((member) => {
        // For example
        console.log("leaving", member);
        removePatientsOnline(member);
      });
    return () => {};
  }, []);

  useEffect(() => {
    GetDoctorDetails();

    // getusers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const GetDoctorDetails = () => {
    DoctorAPI.get(`auth/me`)
      .then((response) => {
        console.log("GetDoctorDetails sss", response.data.data);
        if (response.data.data.type === "Regular") {
          setMainDoc(true);
        } else {
          setMainDoc(false);
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  console.log("heonlinePatientsre", onlinePatients);
  useEffect(() => {
    getLocalNotification();

    // getusers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    saveLocalNotification();
  }, [notificationData]);
  const saveLocalNotification = () => {
    var doctorId = localStorage.getItem("doctorId");
    localStorage.setItem(
      `HHDoctor${doctorId}Notification`,
      JSON.stringify(notificationData)
    );
  };
  const getLocalNotification = () => {
    var doctorId = localStorage.getItem("doctorId");
    if (localStorage.getItem(`HHDoctor${doctorId}Notification`) === null) {
      localStorage.setItem(
        `HHDoctor${doctorId}Notification`,
        JSON.stringify([])
      );
    } else {
      let localNotification = JSON.parse(
        localStorage.getItem(`HHDoctor${doctorId}Notification`)
      );

      setNotificationData(localNotification);
    }
  };
  useEffect(() => {
    getLocalPatients();

    // getusers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    saveLocalPatients();
  }, [onlinePatients]);
  const saveLocalPatients = () => {
    var doctorId = localStorage.getItem("doctorId");
    localStorage.setItem(
      `HHDoctor${doctorId}PatientOnline`,
      JSON.stringify(onlinePatients)
    );
  };
  const getLocalPatients = () => {
    var doctorId = localStorage.getItem("doctorId");
    if (localStorage.getItem(`HHDoctor${doctorId}PatientOnline`) === null) {
      localStorage.setItem(
        `HHDoctor${doctorId}PatientOnline`,
        JSON.stringify([])
      );
    } else {
      let localNotification = JSON.parse(
        localStorage.getItem(`HHDoctor${doctorId}PatientOnline`)
      );

      setOnlinePatients(localNotification);
    }
  };
  const removePatientsOnline = (obj) => {
    console.log("pateinsfkdsdsf remove", obj);
    var doctorId = localStorage.getItem("doctorId");
    let localNotification = JSON.parse(
      localStorage.getItem(`HHDoctor${doctorId}PatientOnline`)
    );

    let data = [];
    if (localNotification && localNotification.length > 0) {
      data = localNotification;
    }
    console.log("pateinsfkdsdsf", data);
    const patients = data.filter((o) => o.id !== obj.id);
    console.log("pateinsfkdsdsf", patients);
    setOnlinePatients(patients);
  };
  console.log("online patienssss", onlinePatients);
  const jointPatientsOnline = (p) => {
    if (p.type === "patient") {
      var doctorId = localStorage.getItem("doctorId");
      let localNotification = JSON.parse(
        localStorage.getItem(`HHDoctor${doctorId}PatientOnline`)
      );

      let data = [];
      if (localNotification && localNotification.length > 0) {
        data = localNotification;
      }
      if (data.filter((item) => item.id === p.id).length === 0) {
        data.push(p);
      }

      setOnlinePatients(data);
    }
  };
  const addPatientsOnline = (p) => {
    var doctorId = localStorage.getItem("doctorId");
    let localNotification = JSON.parse(
      localStorage.getItem(`HHDoctor${doctorId}PatientOnline`)
    );

    let data = [];
    if (localNotification && localNotification.length > 0) {
      data = localNotification;
    }

    const mergedArray = [...data, ...p];
    const unique = [
      ...new Map(mergedArray.map((item) => [item["id"], item])).values(),
    ];

    console.log("unique====>", unique);
    setOnlinePatients(unique);
  };
  const addNotification = (noti) => {
    var doctorId = localStorage.getItem("doctorId");
    let localNotification = JSON.parse(
      localStorage.getItem(`HHDoctor${doctorId}Notification`)
    );

    let data = [];
    if (localNotification && localNotification.length > 0) {
      data = localNotification;
    }
    let obj = { ...noti };
    obj.read = false;

    data.push(obj);

    setNotificationData(data);
  };

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };
  console.log("notificationData====>", notificationData);
  const handleMenuItemClick = (data, index) => {
    console.log("data====>", data);
    if (data.type === "patient_new_appointment") {
      history.push(`/doctor/schedule-details/${data.data.id}`);
    }
    const newState = notificationData.map((obj) =>
      obj.data.id === data.data.id ? { ...obj, read: true } : obj
    );
    setNotificationData(newState);
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  const logOut = () => {
    auth.logout(() => {
      var doctorId = localStorage.getItem("doctorId");
      localStorage.removeItem(`HHDoctor${doctorId}Notification`);
      localStorage.removeItem(`HHDoctor${doctorId}PatientOnline`);
      history.push("/");
    });
  };
  const profile = () => {
    history.push("/doctor/profile");
  };
  const resetPassword = () => {
    history.push("/doctor/reset-password");
  };
  // useEffect(() => {
  //   history.push("/doctor/appointments");
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const drawer = (
    <div>
      <div className="text-center">
        {/* <img
          className="app-bar-logo"
          src={`${process.env.PUBLIC_URL}/assets/images/newLogo.jpg`}
          alt="app-bar-logo"
        /> */}
      </div>
      <Divider />

      <List>
        {/* <ListItem button component={Link} to={`${match.url}`}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem> */}
        {mainDoc && (
          <>
            <ListItem
              classes={{ root: classes.list, selected: classes.selected }}
              button
              component={Link}
              to={`${match.url}/schedule`}
            >
              <ListItemIcon>
                <ScheduleIcon />
              </ListItemIcon>
              <ListItemText primary="Schedules" />
            </ListItem>

            <ListItem
              classes={{ root: classes.list, selected: classes.selected }}
              button
              component={Link}
              to={`${match.url}/prescriptions`}
            >
              <ListItemIcon>
                <NoteAddIcon />
              </ListItemIcon>
              <ListItemText primary="Prescriptions" />
            </ListItem>

            <ListItem
              classes={{ root: classes.list, selected: classes.selected }}
              button
              component={Link}
              to={`${match.url}/appointments`}
            >
              <ListItemIcon>
                <AvTimerIcon />
              </ListItemIcon>
              <ListItemText primary="Appointments" />
            </ListItem>
          </>
        )}

        {!mainDoc && (
          <ListItem
            classes={{ root: classes.list, selected: classes.selected }}
            button
            component={Link}
            to={`${match.url}/test-list`}
          >
            <ListItemIcon>
              <Icon>biotech</Icon>
            </ListItemIcon>

            <ListItemText primary="Test List" />
          </ListItem>
        )}

        <ListItem
          classes={{ root: classes.list, selected: classes.selected }}
          button
          component={Link}
          to={`${match.url}/reports`}
        >
          <ListItemIcon>
            <Icon>list_alt</Icon>
          </ListItemIcon>
          <ListItemText primary="Reports" />
        </ListItem>

        {/* <ListItem button>
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText primary="Reports" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <LayersIcon />
          </ListItemIcon>
          <ListItemText primary="Integrations" />
        </ListItem> */}
      </List>
      <Divider />
    </div>
  );

  const container =
    props.window !== undefined ? () => window().document.body : undefined;

  return (
    <div>
      {/* <CssBaseline /> */}
      <AppBar className={classes.appBar} position="fixed">
        <Toolbar className={classes.toolbar}>
          <Hidden smUp implementation="css">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              edge="start"
              className={clsx(classes.menuButton)}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Hidden xsDown implementation="css">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton)}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>

          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Doctor Portal
          </Typography>

          <Tooltip title="Change Password">
            <MenuItem>
              <IconButton
                onClick={handleClickListItem}
                aria-label="show 11 new notifications"
                color="inherit"
              >
                <Badge
                  badgeContent={
                    notificationData.filter((o) => o.read === false).length
                  }
                  color="secondary"
                >
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </MenuItem>
          </Tooltip>
          <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {notificationData.map((option, index) => (
              <MenuItem
                style={{ whiteSpace: "normal", maxWidth: "220" }}
                key={index}
                // disabled={index === 0}
                selected={option.read === false}
                onClick={() => handleMenuItemClick(option, index)}
              >
                <p> {option.message}</p>
              </MenuItem>
            ))}
          </Menu>
          <Tooltip title="Change Password">
            <IconButton onClick={resetPassword} color="inherit">
              <VpnKeyIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Profile">
            <IconButton onClick={profile} color="inherit">
              <PersonIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Logout">
            <IconButton onClick={logOut} color="inherit" aria-label="logout">
              <ExitToAppIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.appBarSpacer} />
        <Switch>
          <Route exact={true} path={`${match.path}/reports`}>
            <TestList mainDoc={mainDoc} />
          </Route>
          <Route path={`${match.path}/reports/:testId`}>
            <TestDetails mainDoc={mainDoc} />
          </Route>

          <Route path={`${match.path}/add-prescription/:addPrescriptionId`}>
            <AddPrescription />{" "}
          </Route>

          <Route path={`${match.path}/video-conference/:addPrescriptionId`}>
            <VideoConference />{" "}
          </Route>

          <Route path={`${match.path}/prescriptions`}>
            <Prescriptions />
          </Route>

          <Route path={`${match.path}/prescription-details/:appointmentId`}>
            <ShowPrescriptionDetails />
          </Route>
          <Route path={`${match.path}/patient-history/:patientId`}>
            <PatientHistory />
          </Route>
          <Route path={`${match.path}/profile`}>
            <Profile />
          </Route>
          <Route path={`${match.path}/reset-password`}>
            <ResetPassword />
          </Route>
          <Route path={`${match.path}/add-invoice`}>
            <AddTestInvoice />
          </Route>
          {mainDoc && (
            <React.Fragment>
              <Route exact path={`${match.path}/schedule`}>
                <Schedules />
              </Route>
              <Route exact path={`${match.path}/appointments`}>
                <Appointments onlinePatients={onlinePatients} />
              </Route>
              <Route path={`${match.path}/schedule-details/:scheduleId`}>
                <ViewScheduleDetails />
              </Route>
              <Route path={`${match.path}/add-schedule`}>
                <AddSchedule />{" "}
              </Route>
              <Route path={`${match.path}/edit_schedule/:editScheduleid`}>
                <AddSchedule />{" "}
              </Route>
            </React.Fragment>
          )}
          {!mainDoc && (
            <React.Fragment>
              <Route exact path={`${match.path}/test-list`}>
                <ReferDocTestList />
              </Route>
            </React.Fragment>
          )}
        </Switch>
      </main>
    </div>
  );
}

Main.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Main;
