import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";

import * as Yup from "yup";
import { Layout } from "antd";

import { DiagnosticAPI } from "../../../AxiosRequest/DiagnosticAPI";

const { Content } = Layout;

export default function ResetPassword() {
  const [open, setOpen] = useState(false);
  const [erroropen, setErrorOpen] = useState(false);

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Backend Errors Handle
  const [backendErrorMessage, setBackendErrorMessage] = useState({});

  const validationSchema = Yup.object({
    old_password: Yup.string()
      .min(6, "Password must contain at least 6 characters")
      .required("Enter your password"),
    new_password: Yup.string()
      .min(6, "Password must contain at least 6 characters")
      .required("Enter your password"),
    confirm_password: Yup.string()
      .required("Confirm your password")
      .oneOf([Yup.ref("new_password")], "Password does not match"),
  });
  const initialValues = {
    old_password: "",
    new_password: "",
    confirm_password: "",
  };

  const onSubmit = (values, { resetForm }) => {
    console.log(values);

    DiagnosticAPI.post("auth/reset-password", values)
      .then((response) => {
        console.log(response);
        if (response.data.message) {
          setSuccessMessage(response.data.message);
          setOpen(true);
          setErrorOpen(false);
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.data.message) {
          setErrorMessage(error.response.data.message);
          setErrorOpen(true);
          setOpen(false);
        }
        if (error.response.data.data !== undefined) {
          setErrorOpen(true);
          setOpen(false);
        }

        if (error.response.data.errors !== undefined) {
          setBackendErrorMessage(error.response.data.errors);
        }
      });
  };
  return (
    <div>
      <Layout style={{ minHeight: "100vh" }}>
        <Content style={{ margin: "0 16px" }}>
          <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 22 }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-3"></div>
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="add-institution">
                        <Formik
                          initialValues={initialValues}
                          validationSchema={validationSchema}
                          onSubmit={onSubmit}
                        >
                          {({ isValid, dirty }) => (
                            <Form>
                              <div className="form-group row ">
                                <label className="col-sm-3 offset-sm-1">
                                  Old Password
                                </label>
                                <div className="col-sm-7">
                                  <Field
                                    className=" form-control"
                                    type="text"
                                    name="old_password"
                                  />
                                  <ErrorMessage
                                    name="old_password"
                                    component="div"
                                    className="error-message"
                                  />
                                  <p className="error-message">
                                    {backendErrorMessage.old_password}
                                  </p>
                                </div>
                              </div>
                              <div className="form-group row ">
                                <label className="col-sm-3 offset-sm-1">
                                  New Password
                                </label>
                                <div className="col-sm-7">
                                  <Field
                                    className=" form-control"
                                    type="text"
                                    name="new_password"
                                  />
                                  <ErrorMessage
                                    name="new_password"
                                    component="div"
                                    className="error-message"
                                  />
                                  <p className="error-message">
                                    {backendErrorMessage.new_password}
                                  </p>
                                </div>
                              </div>
                              <div className="form-group row ">
                                <label className="col-sm-3 offset-sm-1">
                                  Confirm Password
                                </label>
                                <div className="col-sm-7">
                                  <Field
                                    className=" form-control"
                                    type="text"
                                    name="confirm_password"
                                  />
                                  <ErrorMessage
                                    name="confirm_password"
                                    component="div"
                                    className="error-message"
                                  />
                                  <p className="error-message">
                                    {backendErrorMessage.confirm_password}
                                  </p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-12 my-4">
                                  <Collapse in={open}>
                                    <Alert
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setOpen(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      {successMessage}
                                    </Alert>
                                  </Collapse>
                                  <Collapse in={erroropen}>
                                    <Alert
                                      severity="error"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setErrorOpen(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      {errorMessage}
                                    </Alert>
                                  </Collapse>
                                </div>
                              </div>
                              <div className="form-group row ">
                                <label className="col-sm-3 offset-sm-1"></label>
                                <div className="col-sm-7 text-right">
                                  <button
                                    type="submit"
                                    className="btn btn-lg btn-success"
                                  >
                                    Reset Password
                                  </button>
                                </div>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3"></div>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </div>
  );
}
