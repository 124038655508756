import React from "react";

import Slider from "react-slick";
import { useHistory } from "react-router-dom";
var settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 2,
  slidesToScroll: 2,
  initialSlide: 0,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const ASA = ({ title = "Test Packages" }) => {
  let history = useHistory();
  const routeToAvailableTests = (route) => {
    history.push(`/available-tests/${route}`);
  };
  return (
    <div className="container">
      <div class="row mb-4">
        <div class="col-md-6 col-lg-6 mt-2">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/Diabetes-Day-banner-ASA.png`}
            class="img-fluid"
            alt="Diabetes Day ASA"
          />
        </div>
        <div class="col-md-6 col-lg-6 d-flex align-items-center justify-content-center mt-2">
          <div className="event-desc">
            <h4>Free Health Camp</h4>
            <h5>Date :16 November 2022</h5>
            <h5>Time : Wednesday, 08.00 AM</h5>
            <h5>Venue : ASA University Campus</h5>
          </div>
        </div>
      </div>
      <section class="our-team">
        <div class="container container-custom">
          <div class="row">
            <div class="col-md-12">
              <div class="sub-title_center">
                <h2>{title}</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="doctors-slider">
                <Slider {...settings}>
                  <div
                    onClick={() =>
                      routeToAvailableTests("DNA-Solution/Thyroid Panel")
                    }
                  >
                    <div class="team-img_block blue">
                      <div class="team-img-socila-block">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/dna/Thyroid-Panel.png`}
                          class="img-fluid"
                          alt="Thyroid Pannel"
                        />
                      </div>
                    </div>
                    <h4 className=" text-center">Thyroid Panel</h4>
                  </div>
                  <div
                    onClick={() =>
                      routeToAvailableTests("DNA-Solution/Thalasemia Screening")
                    }
                  >
                    <div class="team-img_block blue">
                      <div class="team-img-socila-block">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/dna/Thalasemia-Screening.png`}
                          class="img-fluid"
                          alt="Thyroid Pannel"
                        />
                      </div>
                    </div>
                    <h4 className=" text-center">Thalasemia Screening</h4>
                  </div>
                  <div
                    onClick={() =>
                      routeToAvailableTests("DNA-Solution/Liver Function Test")
                    }
                  >
                    <div class="team-img_block blue">
                      <div class="team-img-socila-block">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/dna/Liver-Function-Test.png`}
                          class="img-fluid"
                          alt="Liver Function Test"
                        />
                      </div>
                    </div>
                    <h4 className=" text-center">Liver Function Test</h4>
                  </div>
                  <div
                    onClick={() =>
                      routeToAvailableTests(
                        "DNA-Solution/Kidney Function Test (KFT)"
                      )
                    }
                  >
                    <div class="team-img_block blue">
                      <div class="team-img-socila-block">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/dna/Kindney-Function-Test-(KFT).png`}
                          class="img-fluid"
                          alt="Kidney Function Test (KFT)"
                        />
                      </div>
                    </div>
                    <h4 className=" text-center">Kidney Function Test (KFT)</h4>
                  </div>
                  <div
                    onClick={() =>
                      routeToAvailableTests("DNA-Solution/Vitamin D")
                    }
                  >
                    <div class="team-img_block blue">
                      <div class="team-img-socila-block">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/dna/Vitamin-D.png`}
                          class="img-fluid"
                          alt="Vitamin D"
                        />
                      </div>
                    </div>
                    <h4 className=" text-center">Vitamin D</h4>
                  </div>

                  <div
                    onClick={() =>
                      routeToAvailableTests("DNA-Solution/Lipid Profile")
                    }
                  >
                    <div class="team-img_block blue">
                      <div class="team-img-socila-block">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/dna/Lipid-Profile.png`}
                          class="img-fluid"
                          alt="Lipid Profile"
                        />
                      </div>
                    </div>
                    <h4 className=" text-center">Lipid Profile</h4>
                  </div>
                  <div
                    onClick={() =>
                      routeToAvailableTests(
                        "DNA-Solution/Hepatitis Panel (SGOT, SGPT, T. BILL., HBsAg, HBeAg)"
                      )
                    }
                  >
                    <div class="team-img_block blue">
                      <div class="team-img-socila-block">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/dna/Hepatitis-Panel.png`}
                          class="img-fluid"
                          alt="Hepatitis Panel"
                        />
                      </div>
                    </div>
                    <h4 className=" text-center">Hepatitis Panel</h4>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ASA;
