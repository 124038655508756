import React from "react";
import "./css/video.css";

const Lobby = ({
  username,
  handleUsernameChange,
  roomName,
  handleRoomNameChange,
  handleSubmit,
  doctorDetails,
}) => {
  return (
    <div className="lobby-form">
      <form onSubmit={handleSubmit}>
        {/* <h2>Enter a room</h2> */}
        {/* <div>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="field"
            value={username}
            onChange={handleUsernameChange}
            required
          />
        </div> */}

        {/* <div>
          <label htmlFor="room">Room name:</label>
          <input
            type="text"
            id="room"
            value={roomName}
            onChange={handleRoomNameChange}
            required
          />
        </div> */}
        {doctorDetails !== "" && (
          <button type="submit">Start Video Call</button>
        )}
      </form>
    </div>
  );
};

export default Lobby;
