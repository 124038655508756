import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import axios from "axios";
import * as Yup from "yup";
import { TextField as TextFieldSecond } from "formik-material-ui";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "@material-ui/pickers";
import { CorporateAPI } from "../../../AxiosRequest/CorporateAPI";
import moment from "moment";

import _ from "lodash";

const FormikDatePicker = ({
  name,
  form: { setFieldValue },
  field: { value },
  ...rest
}) => {
  // console.log(rest);
  return (
    <DatePicker
      disableFuture
      openTo="year"
      format="dd/MM/yyyy"
      views={["year", "month", "date"]}
      name={name}
      clearable
      autoOk
      inputVariant="outlined"
      // handle clearing outside => pass plain array if you are not controlling value outside
      mask={(value) =>
        value
          ? [/[0-3]/, /\d/, "/", /0|1/, /\d/, "/", /1|2/, /\d/, /\d/, /\d/]
          : []
      }
      onChange={(value) => {
        console.log("setting value to", value);
        setFieldValue("date_of_birth", value);
      }}
      value={value}
      animateYearScrolling={false}
    />
  );
};
export default function PatientRegistration() {
  let history = useHistory();
  const [policeStations, setPoliceStations] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [signedUpMessage, setSignedUpMessage] = useState("");
  const [singnedUp, setSignedUp] = useState(false);
  const [stationSearchTerm, setStationSearchTerm] = useState("");
  const [districtSearchTerm, setDistrictSearchTerm] = useState("");
  // Backend Error Messages
  const [nameErros, setNameErrors] = useState([]);
  const [phoneErros, setPhoneError] = useState([]);
  const [emailError, setEmailError] = useState([]);
  const [passportError, setPassportError] = useState([]);
  const [ageErrors, setAgeErrors] = useState([]);
  const [genderErrors, setGenderErrors] = useState([]);

  const [bloodGroupErrors, setBloodgroupErrors] = useState([]);

  const [passwordErrors, setPasswordErrors] = useState([]);
  const [passwordConfirmationErrors, setPaswordConfirmationErros] = useState(
    []
  );
  const [district, setDistrict] = useState("");

  useEffect(() => {
    GetPoliceStations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stationSearchTerm]);
  const GetPoliceStations = () => {
    // let url = `http://localhost:3001/purchase_order/${paramID}`;
    axios
      .get(`police-stations?name=${stationSearchTerm}`)
      .then((response) => {
        console.log("pppppp", response.data.data.data);
        setPoliceStations(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const handleOnInputCapturePolice = _.debounce((event) => {
    setStationSearchTerm(event.target.value);
  }, 500);

  const validationSchema = Yup.object({
    name: Yup.string().required("Name field is required"),
    phone: Yup.string().required("Please enter your phone number"),
    // email: Yup.string().email("Please Enter a valid Email"),
    password: Yup.string()
      .min(6, "Password must contain at least 6 characters")
      .required("Enter your password"),
    confirm_password: Yup.string()
      .required("Confirm your password")
      .oneOf([Yup.ref("password")], "Password does not match"),
    date_of_birth: Yup.date()
      .required("Date of Birth field is required")
      .typeError("Date of Birth field is required"),
    gender: Yup.string().required("Gender field is required"),
    // blood_group: Yup.string().required("Blood Group field is required"),
  });
  const initialValues = {
    name: "",
    phone: "",
    email: "",
    date_of_birth: null,
    gender: "",
    address: "",
    police_station_id: "",
    password: "",
    confirm_password: "",
  };

  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    setLoading(true);
    let objToPost = { ...values };
    if (objToPost.police_station_id === "") {
      delete objToPost.police_station_id;
    }

    Object.keys(objToPost).forEach(
      (k) => objToPost[k] === "" && delete objToPost[k]
    );
    console.log("obe to post", objToPost);
    objToPost.date_of_birth = moment(values.date_of_birth).format("YYYY-MM-DD");

    CorporateAPI.post("patients", objToPost)
      .then((response) => {
        console.log(response);
        console.log(response.data.message);
        setSignedUp(true);
        setSignedUpMessage("Patient successfully registered");
        setLoading(false);
        history.push("/corporate/patient/list");
      })
      .catch((error) => {
        console.log(error.response);
        setLoading(false);
        if (error.response.data.message) {
        }

        if (error.response.data.errors !== undefined) {
          let nameErrors = error.response.data.errors.name;
          let emailError = error.response.data.errors.email;
          let passportError = error.response.data.errors.passport;
          let phoneErrors = error.response.data.errors.phone;
          let birthErrors = error.response.data.errors.date_of_birth;
          let sexErrors = error.response.data.errors.gender;
          let bloodErrors = error.response.data.errors.blood_group;
          let passwordErrors = error.response.data.errors.password;

          let confirmPasswordErrors =
            error.response.data.errors.confirm_password;

          if (nameErrors !== undefined) {
            setNameErrors(nameErrors);
          }
          if (emailError !== undefined) {
            setEmailError(emailError);
          }
          if (passportError !== undefined) {
            setPassportError(passportError);
          }
          if (phoneErrors !== undefined) {
            setPhoneError(phoneErrors);
          }
          if (birthErrors !== undefined) {
            setAgeErrors(birthErrors);
          }
          if (sexErrors !== undefined) {
            setGenderErrors(sexErrors);
          }
          if (bloodErrors !== undefined) {
            setBloodgroupErrors(bloodErrors);
          }
          if (passwordErrors !== undefined) {
            setPasswordErrors(passwordErrors);
          }
          if (confirmPasswordErrors !== undefined) {
            setPaswordConfirmationErros(confirmPasswordErrors);
          }
        }
      });
  };
  return (
    <div>
      <div className="doc-register-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <div className="row">
                <div className="col-lg-12">
                  <div className="doc-register-form">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      {({ setFieldValue, isValid, dirty }) => (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group row ">
                                <label className="col-3 col-sm-12">Name</label>
                                <div className="col-8 col-sm-12">
                                  <Field
                                    name="name"
                                    type="text"
                                    className="form-control"
                                    placeholder="Name"
                                  />
                                  <ErrorMessage
                                    name="name"
                                    component="div"
                                    className="error-message"
                                  />
                                  {nameErros.map((item, i) => (
                                    <p className="error-message" key={i}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group row ">
                                <label className="col-3 col-sm-12">Phone</label>
                                <div className="col-8 col-sm-12">
                                  <Field
                                    name="phone"
                                    type="text"
                                    className="form-control"
                                    placeholder="Phone/Mobile Number"
                                  />
                                  <ErrorMessage
                                    name="phone"
                                    component="div"
                                    className="error-message"
                                  />
                                  {phoneErros.map((item, i) => (
                                    <p className="error-message" key={i}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row ">
                                <label className="col-3 col-sm-12 ">
                                  Gender
                                </label>
                                <div className="col-8 col-sm-12">
                                  <Field
                                    className="form-control"
                                    as="select"
                                    name="gender"
                                  >
                                    <option value=""></option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Others">Others</option>
                                  </Field>
                                  <ErrorMessage
                                    name="gender"
                                    component="div"
                                    className="error-message"
                                  />
                                  {genderErrors.map((item, i) => (
                                    <p className="error-message" key={i}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row ">
                                <label className="col-3 col-sm-12">Email</label>
                                <div className="col-8 col-sm-12">
                                  <Field
                                    name="email"
                                    type="email"
                                    className="form-control"
                                    placeholder="Email"
                                  />
                                  <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="error-message"
                                  />
                                  {emailError.map((item, i) => (
                                    <p className="error-message" key={i}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group row ">
                                <label className="col-3 col-sm-12">
                                  Date of Birth
                                </label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <div className="col-8 col-sm-12">
                                    <Field
                                      component={FormikDatePicker}
                                      name="date_of_birth"
                                    />

                                    <ErrorMessage
                                      name="date_of_birth"
                                      component="div"
                                      className="error-message"
                                    />

                                    {ageErrors.map((item, i) => (
                                      <p className="error-message" key={i}>
                                        {item}
                                      </p>
                                    ))}
                                  </div>
                                </MuiPickersUtilsProvider>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row ">
                                <label className="col-3 col-sm-12">
                                  Police Station
                                </label>
                                <div className="col-8 col-sm-12">
                                  <Autocomplete
                                    freeSolo
                                    id="disabledHelperText"
                                    name="police_station_id"
                                    options={policeStations}
                                    onInputCapture={(event) => {
                                      event.persist(); // As we are accessing the event in asynchronous way.
                                      handleOnInputCapturePolice(event);
                                    }}
                                    // getOptionLabel={(option) => option.name}
                                    getOptionLabel={(option) => {
                                      // return option.name || "";
                                      return (
                                        option.name +
                                          " " +
                                          "(" +
                                          option.district +
                                          ")" || ""
                                      );
                                    }}
                                    onChange={(e, value) => {
                                      if (value == null) {
                                        setFieldValue("police_station_id", "");
                                      } else {
                                        console.log(value);
                                        console.log(value.id);
                                        setFieldValue(
                                          "police_station_id",
                                          value.id
                                        );
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <Field
                                        component={TextFieldSecond}
                                        {...params}
                                        onChange={(e, value) => {
                                          console.log(e.target.value);
                                          setFieldValue("police_station_id", 0);
                                        }}
                                        name="police_station_id"
                                        variant="outlined"
                                        fullWidth
                                      />
                                    )}
                                  />
                                  <ErrorMessage
                                    name="police_station_id"
                                    component="div"
                                    className="error-message"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row ">
                                <label className="col-3 col-sm-12">
                                  Address
                                </label>
                                <div className="col-8 col-sm-12">
                                  <Field
                                    name="address"
                                    type="text"
                                    className="form-control"
                                    placeholder="Your Address"
                                  />
                                  <ErrorMessage
                                    name="address"
                                    component="div"
                                    className="error-message"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group row ">
                                <label className="col-3 col-sm-12 ">
                                  Password
                                </label>
                                <div className="col-8 col-sm-12">
                                  <Field
                                    name="password"
                                    type="password"
                                    className="form-control "
                                    placeholder="password"
                                  />
                                  <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="error-message"
                                  />
                                  {passwordErrors.map((item, i) => (
                                    <p className="error-message" key={i}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group row ">
                                <label className="col-3 col-sm-12 ">
                                  Confirm Password
                                </label>
                                <div className="col-8 col-sm-12">
                                  <Field
                                    name="confirm_password"
                                    type="password"
                                    className="form-control "
                                    placeholder="password"
                                  />
                                  <ErrorMessage
                                    name="confirm_password"
                                    component="div"
                                    className="error-message"
                                  />
                                  {passwordConfirmationErrors.map((item, i) => (
                                    <p className="error-message" key={i}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="form-group row ">
                            <label className="col-sm-5 offset-sm-1">
                              <button
                                disabled={loading}
                                // disabled={!isValid || !dirty}
                                type="submit"
                                className="btn btn-lg btn-success"
                              >
                                {loading && (
                                  <i className="fa fa-refresh fa-spin"></i>
                                )}
                                {loading && <span>Registering</span>}
                                {!loading && <span> Register</span>}
                              </button>
                            </label>
                            <div className="col-8 col-sm-12"></div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-11 offset-sm-1">
                              {signedUpMessage}
                            </label>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-1"></div>
        </div>
      </div>
    </div>
  );
}

const bloodGroups = [
  { name: "A+" },
  { name: "B+" },
  { name: "O+" },
  { name: "AB+" },
  { name: "A-" },
  { name: "B-" },
  { name: "O-" },
  { name: "AB-" },
];
