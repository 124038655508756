import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CreateIcon from "@material-ui/icons/Create";
import { HospitalAPI } from "../../../AxiosRequest/HospitalAPI";
import Pagination from "@material-ui/lab/Pagination";
import _ from "lodash";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

export default function DoctorList() {
  const [doctorList, setDoctorList] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [docId, setDocId] = useState(1);

  const [selecedStatus, setSelectedStatus] = useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [params, setParams] = useState({
    per_page: 15,
    page: 1,
  });

  useEffect(() => {
    getDocList();
  }, [params]);

  const getDocList = () => {
    HospitalAPI.get(`doctors`, { params: params })
      .then((response) => {
        setDoctorList(response.data.data.data);
        setTotalPage(response.data.data.last_page);
        setPage(response.data.data.current_page);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };

  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);

  const changeStatus = (status, id) => {
    setSelectedStatus(status);
    setDocId(id);
    setOpenModal(true);
  };
  const updateDoctor = () => {
    let data = {
      status: selecedStatus,
    };
    HospitalAPI.post(`doctors/${docId}/update-status`, data)
      .then((response) => {
        setOpenModal(false);
        alert("You have successfully updated the doctor status");
        getDocList();
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-6"></div>
        <div className="col-md-6">
          <div className=" text-right">
            <Link
              to={`/hospital/doctor/register`}
              type="button"
              className="btn btn-sm btn-primary mb-1"
            >
              Add Doctor
            </Link>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <div className="form-group my-2">
            <select
              className="form-control"
              onChange={(e) => handleColumnWiseSearch(e.target.value, "status")}
            >
              <option value="">All</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
          </div>
        </div>
        <div className="col-md-9 text-right">
          <div className="form-group my-2">
            <input
              placeholder="Search Doctor"
              className="form-control"
              type="text"
              onChange={(e) => handleColumnWiseSearch(e.target.value, "search")}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mt-4">
          <div className=" table-responsive">
            <table className="table table-striped table-bordered">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">BMDC</th>
                  {/* <th scope="col">Email</th> */}
                  <th scope="col">Contact </th>
                  <th scope="col">Degree</th>
                  <th scope="col">Department</th>
                  {/* <th scope="col">Designation</th> */}
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {doctorList.map((doctor, i) => (
                  <tr key={i}>
                    <td>{doctor.name}</td>
                    <td>{doctor.bmdc_number}</td>
                    {/* <td>{doctor.email}</td> */}
                    <td>{doctor.phone}</td>
                    <td>
                      {doctor.doctor_degrees.map((degree, i) => (
                        <div key={i}>
                          {degree.degree}
                          {i === doctor.doctor_degrees.length - 1 ? "" : ","}
                        </div>
                      ))}
                    </td>
                    <td>{doctor.department}</td>
                    {/* <td>{doctor.designation}</td> */}
                    <td>
                      <select
                        className="form-control"
                        value={doctor.status}
                        onChange={(e) =>
                          changeStatus(e.target.value, doctor.id)
                        }
                      >
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </select>
                    </td>

                    <td>
                      <Link
                        to={`/admin/doctor/view/${doctor.id}`}
                        type="button"
                        className="btn btn-sm btn-primary m-1"
                      >
                        <VisibilityIcon />
                      </Link>
                      <Link
                        to={`/admin/doctor/update/${doctor.id}`}
                        type="button"
                        className="btn btn-outline-secondary"
                      >
                        <CreateIcon />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row">
        <Pagination
          color="primary"
          count={totalPage}
          page={page}
          onChange={handleChange}
        />
      </div>

      <Dialog
        fullWidth
        maxWidth={`sm`}
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <DialogTitle>Change Doctor Status</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure to update status?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="mr-auto"
            onClick={() => setOpenModal(false)}
            color="default"
          >
            Close
          </Button>
          <Button
            className="ml-auto"
            onClick={() => updateDoctor()}
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
