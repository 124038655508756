import React, { useState, useEffect } from "react";
import { TextField as TextFieldSecond } from "formik-material-ui";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import moment from "moment";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import LockOpenIcon from "@material-ui/icons/LockOpen";
// import { FormikTextField } from "formik-material-fields";
import * as Yup from "yup";
import axios from "axios";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
const FormikDatePicker = ({
  name,
  form: { setFieldValue },
  field: { value },
  ...rest
}) => {
  // console.log(rest);
  return (
    <DatePicker
      disableFuture
      openTo="year"
      format="dd/MM/yyyy"
      views={["year", "month", "date"]}
      name={name}
      keyboard
      clearable
      autoOk
      // handle clearing outside => pass plain array if you are not controlling value outside
      mask={(value) =>
        value
          ? [/[0-3]/, /\d/, "/", /0|1/, /\d/, "/", /1|2/, /\d/, /\d/, /\d/]
          : []
      }
      disableOpenOnEnter
      onChange={(value) => {
        setFieldValue("date_of_birth", value);
      }}
      value={value}
      animateYearScrolling={false}
      readOnly
    />
  );
};

const options = [
  { id: "10", text: "One" },
  { id: "20", text: "Two" },
  { id: "30", text: "Three" },
];

const DoctorUpdate = () => {
  const [value, setValue] = useState([
    { id: "10", text: "One" },
    { id: "20", text: "Two" },
  ]);
  let history = useHistory();
  let { doc } = useParams();
  const [docDetails, setDocDetails] = useState({});
  const [designations, setDesignations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [institutions, setInstitutions] = useState([]);

  const [desigValue, setDesigvalue] = useState("");
  const [deptValue, setDeptValue] = useState("");
  const [currentIns, setCurrentIns] = useState("");
  const [passingIns, setPassingIns] = useState("");
  const [degreeList, setDegreeList] = useState([]);
  const [degreeValue, setDegreeValue] = useState([]);

  const [degreeListChosen, setDegreeListChosen] = useState([]);

  const [fileForPreview, setFileForPreview] = useState("");
  const [uploadedFile, setUploadedFile] = useState("");
  const [fileName, setFileName] = useState("Choose an Image File");

  const [loading, setLoading] = useState(false);
  const [signedUpMessage, setSignedUpMessage] = useState("");
  const [singnedUp, setSignedUp] = useState(false);

  // Backend Error Messages
  const [nameErros, setNameErrors] = useState([]);
  const [emailError, setEmailError] = useState([]);
  const [phoneErros, setPhoneError] = useState([]);
  const [ageErrors, setAgeErrors] = useState([]);
  const [bmdcErrors, setBMDCErrors] = useState([]);
  const [designationErrors, setDesignationErrors] = useState([]);
  const [departmentErrors, setDepartmentErrors] = useState([]);
  const [institutionErrors, setInstitutionErrors] = useState([]);
  const [degreeErrors, setDegreeErrors] = useState([]);
  const [practiceYearErrors, setPracticeYearErrors] = useState([]);
  const [passigngYearErrors, setPassingYearErrors] = useState([]);
  const [passingInstituitionErrors, setPassingInstituitionErrors] = useState(
    []
  );
  const [newPatientVisitErrors, setNewPatientVisitErrors] = useState([]);
  const [oldPatientVisitErrors, setOldPatientVisitErrors] = useState([]);
  const [reportVisitErrors, setReportVisitErrors] = useState([]);
  const [oldPatientVisitValidity, setOldPatientVisitValidity] = useState([]);
  const [passwordErrors, setPasswordErrors] = useState([]);
  const [passwordConfirmationErrors, setPaswordConfirmationErros] = useState(
    []
  );
  const [photoErrors, setPhotoErrors] = useState([]);
  const [selectedDate, handleDateChange] = useState(new Date());

  useEffect(() => {
    getDocDetails();
  }, []);

  const getDocDetails = () => {
    AdminAPI.get(`doctors/${doc}`)
      .then((response) => {
        console.log("doc details", response.data.data);
        setDocDetails(response.data.data);
        setDesigvalue(response.data.data.designation);
        setDeptValue(response.data.data.department);
        setCurrentIns(response.data.data.institution);
        setPassingIns(response.data.data.passing_institution);
        const selected = response.data.data.doctor_degrees;
        setDegreeValue(selected);
        const date = response.data.data.date_of_birth;
        handleDateChange(date);
        // setDegreeValue(nee);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const validationSchema = Yup.object({
    // date_of_birth: Yup.date().required("Date of Birth field is required"),
    designation: Yup.string().required("Designation field is required"),
    department: Yup.string().required("Department field is required"),
    institution: Yup.string().required("Institution field is required"),
    // bmdc_number: Yup.string().required("Please enter your BMDC number"),
    // passing_institution: Yup.string().required(
    //   "Passing Institution field is required"
    // ),
    // degrees: Yup.string().required("Degree field is required"),

    // acceptTerms: Yup.bool().oneOf(
    //   [true],
    //   "Accept Terms & Conditions is required"
    // ),
  });
  const initialValues = {
    name: docDetails.name,
    phone: docDetails.phone,
    email: docDetails.email,
    date_of_birth: selectedDate,
    designation: docDetails.designation,
    department: docDetails.department,
    institution: docDetails.institution,
    bmdc_number: docDetails.bmdc_number,
    passing_institution: docDetails.passing_institution,
    passing_year: docDetails.passing_year,
    practice_year: docDetails.practice_year,
    new_patient_visit: docDetails.new_patient_visit,
    old_patient_visit: docDetails.old_patient_visit,
    report_visit: docDetails.report_visit,
    old_patient_valid_days: docDetails.old_patient_valid_days,
    acceptTerms: false,
  };

  useEffect(() => {
    getDesignations();
    getDegrees();
    getDepartments();
    getInstutitions();
  }, []);

  const getDesignations = () => {
    axios
      .get("designations")
      .then((response) => {
        setDesignations(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const getDegrees = () => {
    AdminAPI.get("degrees")
      .then((response) => {
        setDegreeList(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const getDepartments = () => {
    axios
      .get("departments")
      .then((response) => {
        setDepartments(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const getInstutitions = () => {
    AdminAPI.get("institutions")
      .then((response) => {
        setInstitutions(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const onSubmit = (values, { resetForm }) => {
    setLoading(true);
    console.log("objj");
    let objToSend = {};
    objToSend.department = values.department;
    objToSend.designation = values.designation;
    objToSend.institution = values.institution;
    objToSend.passing_institution = values.passing_institution;
    objToSend.degrees = degreeListChosen;

    AdminAPI.put(`doctors/${doc}`, objToSend)
      .then((response) => {
        console.log(response.data.message);
        getDocDetails();
        alert(response.data.message);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response);
        setLoading(false);
      });

    // setLoading(false);
  };

  return (
    <div>
      <div className="doc-register-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-md-12">
                  <div className="doc-register-form">
                    <div className="text-center">
                      <LockOpenIcon />
                    </div>

                    <Formik
                      enableReinitialize
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      {({ setFieldValue, errors, status, touched }) => (
                        <Form>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label">
                              Name
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="name"
                                type="text"
                                className="form-control"
                                placeholder="Your Name"
                                readOnly={true}
                              />
                              <ErrorMessage
                                name="name"
                                component="div"
                                className="error-message"
                              />
                              {nameErros.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))}
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label">
                              Email
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="email"
                                type="email"
                                className="form-control"
                                placeholder="Email"
                                readOnly={true}
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="error-message"
                              />
                              {emailError.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))}
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label">
                              Phone
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="phone"
                                type="text"
                                className="form-control"
                                placeholder="Phone/Mobile Number"
                                readOnly={true}
                              />
                              <ErrorMessage
                                name="phone"
                                component="div"
                                className="error-message"
                              />
                              {phoneErros.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))}
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label">
                              Date of Birth
                            </label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <div className="col-sm-7">
                                <Field
                                  component={FormikDatePicker}
                                  name="date_of_birth"
                                  readOnly={true}
                                />

                                <ErrorMessage
                                  name="date_of_birth"
                                  component="div"
                                  className="error-message"
                                />

                                {ageErrors.map((item, i) => (
                                  <p className="error-message" key={i}>
                                    {item}
                                  </p>
                                ))}
                              </div>
                            </MuiPickersUtilsProvider>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label ">
                              BMDC Number
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="bmdc_number"
                                type="text"
                                className="form-control"
                                placeholder="BMDC Number"
                                readOnly={true}
                              />
                              <ErrorMessage
                                name="bmdc_number"
                                component="div"
                                className="error-message"
                              />
                              {bmdcErrors.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))}
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label">
                              Designation
                            </label>
                            <div className="col-sm-7">
                              <Autocomplete
                                freeSolo
                                id="disabledHelperText"
                                name="designation"
                                options={designations}
                                value={desigValue}
                                getOptionLabel={(option) =>
                                  option.name || desigValue
                                }
                                onInputCapture={(event) => {
                                  event.persist(); // As we are accessing the event in asynchronous way.
                                  setFieldValue(
                                    "designation",
                                    event.target.value
                                  );
                                  // console.log(event.target.value);
                                }}
                                onChange={(event, value) => {
                                  console.log("value", value);
                                  if (value == null) {
                                    setFieldValue("designation", "");
                                  } else {
                                    setFieldValue("designation", value.name);
                                  }
                                }}
                                renderInput={(params) => (
                                  <Field
                                    component={TextFieldSecond}
                                    {...params}
                                    name="designation"
                                    variant="outlined"
                                    fullWidth
                                  />
                                )}
                              />
                              <ErrorMessage
                                name="designation"
                                component="div"
                                className="error-message"
                              />
                              {designationErrors.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))}
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label">
                              Department
                            </label>
                            <div className="col-sm-7">
                              <Autocomplete
                                freeSolo
                                id="disabledHelperText"
                                name="department"
                                options={departments}
                                value={deptValue}
                                getOptionLabel={(option) =>
                                  option.name || deptValue
                                }
                                onChange={(e, value) => {
                                  if (value == null) {
                                    setFieldValue("department", "");
                                  } else {
                                    setFieldValue("department", value.name);
                                  }
                                }}
                                onInputCapture={(event) => {
                                  event.persist(); // As we are accessing the event in asynchronous way.
                                  setFieldValue(
                                    "department",
                                    event.target.value
                                  );
                                  // console.log(event.target.value);
                                }}
                                renderInput={(params) => (
                                  <Field
                                    component={TextFieldSecond}
                                    {...params}
                                    name="department"
                                    variant="outlined"
                                    fullWidth
                                  />
                                )}
                              />

                              <ErrorMessage
                                name="department"
                                component="div"
                                className="error-message"
                              />
                              {departmentErrors.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))}
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label">
                              Current Institution
                            </label>
                            <div className="col-sm-7">
                              <Autocomplete
                                id="disabledHelperText"
                                freeSolo
                                options={institutions}
                                value={currentIns}
                                getOptionLabel={(option) =>
                                  option.name || currentIns
                                }
                                onChange={(event, value) => {
                                  if (value == null) {
                                    console.log(null);
                                  } else {
                                    setFieldValue("institution", value.name);
                                  }
                                }}
                                onInputCapture={(event) => {
                                  event.persist(); // As we are accessing the event in asynchronous way.
                                  setFieldValue(
                                    "institution",
                                    event.target.value
                                  );
                                  // console.log(event.target.value);
                                }}
                                renderInput={(params) => (
                                  <Field
                                    component={TextFieldSecond}
                                    {...params}
                                    name="institution"
                                    variant="outlined"
                                  />
                                )}
                              />
                              <ErrorMessage
                                name="institution"
                                component="div"
                                className="error-message"
                              />
                              {institutionErrors.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))}
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label">
                              Degree
                            </label>
                            <div className="col-sm-7">
                              {degreeValue && (
                                <Autocomplete
                                  multiple
                                  id="tags-filled"
                                  options={degreeList.map(
                                    (option) => option.name
                                  )}
                                  defaultValue={degreeValue.map(
                                    (option) => option.degree
                                  )}
                                  freeSolo
                                  // value={degreeValue}
                                  renderTags={(value, getTagProps) => {
                                    setDegreeListChosen(value);

                                    return value.map((option, index) => (
                                      <Chip
                                        variant="outlined"
                                        label={option}
                                        {...getTagProps({ index })}
                                      />
                                    ));
                                  }}
                                  renderInput={(params) => (
                                    <Field
                                      component={TextFieldSecond}
                                      {...params}
                                      name="degress"
                                      variant="outlined"
                                    />
                                  )}
                                />
                              )}

                              <ErrorMessage
                                name="degress"
                                component="div"
                                className="error-message"
                              />
                              {degreeErrors.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))}
                            </div>
                          </div>

                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label ">
                              Passing Institution
                            </label>
                            <div className="col-sm-7">
                              <Autocomplete
                                id="disabledHelperText"
                                freeSolo
                                options={institutions}
                                value={passingIns}
                                getOptionLabel={(option) =>
                                  option.name || passingIns
                                }
                                onChange={(event, value) => {
                                  if (value == null) {
                                    console.log(null);
                                  } else {
                                    setFieldValue(
                                      "passing_institution",
                                      value.name
                                    );
                                  }
                                }}
                                onInputCapture={(event) => {
                                  event.persist(); // As we are accessing the event in asynchronous way.
                                  setFieldValue(
                                    "passing_institution",
                                    event.target.value
                                  );
                                  // console.log(event.target.value);
                                }}
                                renderInput={(params) => (
                                  <Field
                                    component={TextFieldSecond}
                                    {...params}
                                    name="passing_institution"
                                    variant="outlined"
                                  />
                                )}
                              />
                              <ErrorMessage
                                name="passing_institution"
                                component="div"
                                className="error-message"
                              />
                              {passingInstituitionErrors.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))}
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label ">
                              Practicing Year
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="practice_year"
                                type="text"
                                className="form-control"
                                placeholder="Practice Year"
                                readOnly={true}
                              />
                              <ErrorMessage
                                name="practice_year"
                                component="div"
                                className="error-message"
                              />
                              {practiceYearErrors.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))}
                            </div>
                          </div>

                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label ">
                              Passing Year
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="passing_year"
                                type="text"
                                className="form-control"
                                placeholder="Passing Year"
                                readOnly={true}
                              />
                              <ErrorMessage
                                name="passing_year"
                                component="div"
                                className="error-message"
                              />
                              {passigngYearErrors.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))}
                            </div>
                          </div>

                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label ">
                              New Patient Visit
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="new_patient_visit"
                                type="text"
                                className="form-control "
                                placeholder="Amount (Taka)"
                                readOnly={true}
                              />
                              <ErrorMessage
                                name="new_patient_visit"
                                component="div"
                                className="error-message"
                              />
                              {newPatientVisitErrors.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))}
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label ">
                              Old Patient Visit
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="old_patient_visit"
                                type="text"
                                className="form-control "
                                placeholder="Amount (Taka)"
                                readOnly={true}
                              />
                              <ErrorMessage
                                name="old_patient_visit"
                                component="div"
                                className="error-message"
                              />
                              {oldPatientVisitErrors.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))}
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label ">
                              Old Patient Validity
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="old_patient_valid_days"
                                type="text"
                                className="form-control "
                                placeholder="Days"
                                readOnly={true}
                              />
                              <ErrorMessage
                                name="old_patient_valid_days"
                                component="div"
                                className="error-message"
                              />
                              {oldPatientVisitValidity.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))}
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label ">
                              Report Visit
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="report_visit"
                                type="text"
                                className="form-control "
                                placeholder="Amount (Taka)"
                                readOnly={true}
                              />
                              <ErrorMessage
                                name="report_visit"
                                component="div"
                                className="error-message"
                              />
                              {reportVisitErrors.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))}
                            </div>
                          </div>

                          <div className="form-group row ">
                            <label className="col-sm-5 offset-sm-1">
                              <button
                                disabled={loading}
                                // disabled={!isValid || !dirty}
                                type="submit"
                                className="btn btn-lg btn-success"
                              >
                                {loading && (
                                  <i className="fa fa-refresh fa-spin"></i>
                                )}
                                {loading && <span>Updating</span>}
                                {!loading && <span> Update</span>}
                              </button>
                            </label>
                            <div className="col-sm-7"></div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-11 offset-sm-1">
                              {signedUpMessage}
                            </label>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2"></div>
        </div>
      </div>
    </div>
  );
};

export default DoctorUpdate;
