import axios from "axios";
import history from "../history";

const PharmacyAPI = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/pharmacy`,
  // baseURL: "http://beta.heal-holmes.com/api/api/pharmacy",
});

PharmacyAPI.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const pharmacyToken = JSON.parse(localStorage.getItem("pharmacyToken"));

      if (pharmacyToken) {
        config.headers.Authorization = `Bearer ${pharmacyToken}`;
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);
PharmacyAPI.interceptors.response.use(
  (response) => {
    console.log(response.status);
    // console.log(response.status);
    // Edit response config
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      const path = document.location.pathname;
      console.log(path);
      localStorage.removeItem("pharmacyToken");
      history.push("/pharmacy/login");
      // if (path !== "/doctor/login") {
      //   return (window.location.href = "/doctor/login");
      // }
    }
    console.log(error);
    return Promise.reject(error);
  }
);

export { PharmacyAPI };
