import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import _ from "lodash";
import moment from "moment";
import GetAppIcon from "@material-ui/icons/GetApp";
import axios from "axios";
import { DiagnosticAPI } from "../../../AxiosRequest/DiagnosticAPI";

export default function ReportList() {
  const [reportList, setReportList] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);

  const [params, setParams] = useState({
    page: 1,
    per_page: 15,
  });

  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };

  useEffect(() => {
    DiagnosticAPI.get(`test-reports`, { params: params })
      .then((response) => {
        setReportList(response.data.data.data);
        setTotalPage(response.data.meta.last_page);
        setPage(response.data.meta.current_page);
      })
      .catch((error) => {});
  }, [params]);

  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);

  const filterByDepartment = (event) => {
    const selectedType = event.target.value;
    handleColumnWiseSearch(selectedType, "type");
  };

  const download = (testId, report) => {
    axios
      .get(`files/testInvoiceDetails/${testId}/${report}`, {
        responseType: "blob",
      })
      .then((response) => {
        const file = new Blob([response.data]); //Build a URL from the file
        const fileURL = window.URL.createObjectURL(file); //Open the URL on new Window
        var a = document.createElement("a");
        a.href = fileURL;
        a.download = report;
        a.click();
        window.URL.revokeObjectURL(fileURL);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-10">
            <input
              placeholder="Search"
              className="form-control"
              type="text"
              onChange={(e) => {
                handleColumnWiseSearch(e.target.value, "search");
              }}
            />
          </div>
        </div>
        {reportList.length == 0 && (
          <div className="row">
            <div className="col-12">
              <h4 className="text-primary">Report not available yet.</h4>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-12">
            <table className="custom-responsive-table">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Patient</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Date</th>
                  <th scope="col">Price</th>
                  <th scope="col">Discount</th>
                  <th scope="col">Bill</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {reportList.map((report, i) => (
                  <tr key={i}>
                    <td data-column="Name">
                      {report.diagnostic_test !== undefined && (
                        <span>
                          {" "}
                          {report.test_invoice.type === "BCorporate" ? (
                            <span>{report.corporate_test.name}</span>
                          ) : (
                            <span>{report.diagnostic_test.name}</span>
                          )}{" "}
                        </span>
                      )}
                    </td>
                    <td data-column="Patient">
                      {report.test_invoice.patient !== undefined ? (
                        <span>{report.test_invoice.patient.name} </span>
                      ) : (
                        <span>{report.test_invoice.patient_name} </span>
                      )}
                    </td>
                    <td data-column="Phone">
                      {report.test_invoice.patient !== undefined && (
                        <span>{report.test_invoice.patient.phone} </span>
                      )}
                    </td>
                    <td data-column="Date">
                      {moment(report.created_at).format("DD/MM/YYYY")}
                    </td>
                    <td data-column="Price">{Math.ceil(report.price)}</td>
                    <td data-column="Discount">{Math.ceil(report.discount)}</td>
                    <td data-column="Bill">{Math.ceil(report.bill)}</td>
                    <td data-column="Report">
                      {report.report !== null && (
                        <button
                          className="btn  btn-sm btn-success"
                          onClick={() =>
                            download(report.test_invoice_id, report.report)
                          }
                        >
                          <GetAppIcon />
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="row">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}
