import React, { useState, useEffect } from "react";
import { CorporateAPI } from "../../../AxiosRequest/CorporateAPI";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import _ from "lodash";
import moment from "moment";
import axios from "axios";

export default function TestList() {
  const [testList, setTestList] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);

  const [params, setParams] = useState({
    page: 1,
    per_page: 15,
  });
  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };
  useEffect(() => {
    CorporateAPI.get(`test-invoices`, { params: params })
      .then((response) => {
        console.log("tetttt", response.data.data);
        setTestList(response.data.data.data);
        setTotalPage(response.data.data.last_page);
        setPage(response.data.data.current_page);
      })
      .catch((error) => {});
  }, [params]);
  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);
  const filterByDepartment = (event) => {
    const selectedType = event.target.value;
    handleColumnWiseSearch(selectedType, "type");
  };

  const downloadPDF = (id) => {
    console.log("iddddd", id);
    CorporateAPI.get(`test-invoices/${id}/pdf`, {
      method: "GET",
      responseType: "blob", //Force to receive data in a Blob Format
    })
      .then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" }); //Build a URL from the file
        const fileURL = URL.createObjectURL(file); //Open the URL on new Window
        var a = document.createElement("a");
        a.href = fileURL;
        a.download = "Heal-holmes-invoice_" + id + ".pdf";
        a.click();
        window.URL.revokeObjectURL(fileURL);

        console.log("pdf response", response, file);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const downloadReport = (testId, report) => {
    console.log("Report name", report)
    axios
      .get(`files/testInvoiceDetails/${testId}/${report}`, {
        responseType: "blob",
      })
      .then((response) => {
        const file = new Blob([response.data]); //Build a URL from the file
        const fileURL = window.URL.createObjectURL(file); //Open the URL on new Window
        var a = document.createElement("a");
        a.href = fileURL;
        a.download = report;
        a.click();
        window.URL.revokeObjectURL(fileURL);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="mx-2">
      <div className="row mt-2">
        <div className="col-md-10">
            <input
              placeholder="Search"
              className="form-control"
              type="text"
              onChange={(e) => {
                handleColumnWiseSearch(e.target.value, "search");
              }}
            />
          
        </div>
        <div className="col-md-2 text-right">
          <Link
            type="button"
            to={`/corporate/test/add`}
            className="btn btn-sm btn-info"
          >
            Book Test
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <table className="custom-responsive-table">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Patient</th>
                <th scope="col">Address</th>
                <th scope="col">Phone</th>
                <th scope="col">DOB</th>
                <th scope="col">Diagnostic</th>
                <th scope="col"></th>
              </tr>
            </thead>

            {testList.map((invoice, i) => (
              <tbody>
                <tr key={i}>
                  <td className="invoice-row" data-column="Date">
                    {moment(invoice.test_date).format("DD/MM/YYYY")}
                  </td>
                  <td className="invoice-row" data-column="Patient">
                    {invoice.patient !== null
                      ? invoice.patient.name
                      : invoice.patient_name}
                  </td>
                  <td className="invoice-row" data-column="Patient">
                    {invoice.address}
                  </td>
                  <td className="invoice-row" data-column="Phone">
                    {invoice.patient !== null ? invoice.patient.phone : ""}
                  </td>
                  <td className="invoice-row" data-column="DOB">
                    {invoice.patient !== null ? (
                      <span>
                        {invoice.patient.date_of_birth != null && (
                          <span>
                            {moment(invoice.patient.date_of_birth).format(
                              "DD/MM/YYYY"
                            )}
                          </span>
                        )}
                      </span>
                    ) : (
                      <span>
                        {invoice.date_of_birth != null && (
                          <span>
                            {moment(invoice.date_of_birth).format(
                              "DD/MM/YYYY"
                            )}
                          </span>
                        )}
                      </span>
                    )}
                  </td>
                  <td className="invoice-row" data-column="Diagnostic">
                    {invoice.service_provider !== undefined && (
                      <span>{invoice.service_provider.name} </span>
                    )}
                  </td>
                  <td className="invoice-row">
                    <Link
                      type="button"
                      to={`/corporate/test/test-invoices/${invoice.id}`}
                      className="btn btn-sm btn-outline-primary"
                    >
                      Details
                    </Link>

                    <button
                      onClick={() => downloadPDF(invoice.id)}
                      className="btn btn-sm btn-outline-success"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                {invoice.test_invoice_details.map((invoiceDetail, j) => (
                  <tr key={"detail" + j}>
                    <td className="empty-left-border"></td>
                    <td className="empty-left-border"></td>
                    <td className="empty-left-border"></td>
                    <td className="empty-left-border"></td>
                    <td className="empty-left-border"></td>
                    <td className="test-color" data-column="Test">
                      {invoice.type === "BCorporate" ? (
                        <span>
                          {invoiceDetail.corporate_test !== null &&
                            invoiceDetail.corporate_test.name}
                        </span>
                      ) : (
                        <span>
                          {invoiceDetail.diagnostic_test.name !== null &&
                            invoiceDetail.diagnostic_test.name}
                        </span>
                      )}
                    </td>
                    <td className="test-color" data-column="Report">
                      {invoiceDetail.report !== null ? (
                        <button
                          className="btn  btn-sm btn-success"
                          onClick={() =>
                            downloadReport(invoice.id, invoiceDetail.report)
                          }
                        >
                          Report
                        </button>
                      ) : (
                        <span>{invoiceDetail.status}</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            ))}
          </table>
        </div>
      </div>

      <div className="row">
        <Pagination
          color="primary"
          count={totalPage}
          page={page}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}
