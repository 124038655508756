import React, { useState } from "react";
import {
  Navbar,
  Nav,
  FormControl,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "./header2.css";
import { Box, Typography } from "@material-ui/core";

import { alpha, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appbar: {
    backgroundColor: "#FFF",
  },
  appbar2: {
    backgroundColor: "#1D520487",
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));
export default function Navbar2(props) {
  const classes = useStyles();
  let history = useHistory();
  const [value, setValue] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // const CustomToggle = () => (
  //   <img
  //     width="30px"
  //     src={`${process.env.PUBLIC_URL}/assets/images/search-icon.png`}
  //     alt="search-icon"
  //   />
  // );
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      style={{ cursor: "pointer", padding: "0 20px" }}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </span>
  ));
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Search..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          {/* <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul> */}
        </div>
      );
    }
  );
  return (
    <div style={{ width: "100%" }}>
      <AppBar className={classes.appbar} position="fixed">
        <Toolbar>
          {" "}
          <div className="LOGO-NAME-BAR">
            <div
              onClick={() => {
                history.push(`/`);
              }}
              style={{ cursor: "pointer" }}
              className="LOGO"
            >
              {/* <img
                className="logo-trans"
                alt="Logo trans"
                src="logo-trans-1.png"
              /> */}
              <div
                onClick={() => {
                  history.push(`/`);
                }}
                style={{ cursor: "pointer" }}
                className="text-wrapper"
              >
                <Link to="/">
                  {" "}
                  <img
                    className="nav-brand-image"
                    src={`${process.env.PUBLIC_URL}/assets/images/logo-trans.png`}
                    alt="heal-holmes-logo"
                  />
                </Link>
              </div>
            </div>
            <div className="WORK-TIME">
              <p className="WORK-TIME-am-pm">
                <span className="span">WORK TIME:</span>
                <span className="text-wrapper-2">
                  {" "}
                  10AM - 6PM
                  <br />
                </span>
              </p>
              <div className="div">SUNDAY - SATURDAY</div>
              {/* <img className="img" alt="Callender" src="callender.png" /> */}
            </div>
            <div className="CONTACT">
              {/* <img className="img" alt="Contact" src="contact.png" /> */}
              <div className="overlap-group">
                <div className="text-wrapper-3">CONTACT:</div>
                <div className="text-wrapper-4">09642919191</div>
                <div className="text-wrapper-5">heal.holmes@gmail.com</div>
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>

      {/* <div className="nav_height"></div> */}
      <Navbar fixed={"top"} bg="heal-holmes-bg" expand="lg">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Link to="/" type="button" className="btn btn-lg btn-text-custom">
              Home
            </Link>
            <Link
              to="/packages"
              type="button"
              className="btn btn-lg btn-text-custom"
            >
              Packgages
            </Link>
            <DropdownButton
              className="drop-button-new"
              id="dropdown-basic-button"
              title="Sign In"
              variant="custom"
            >
              <Dropdown.Item as={Link} to="/doctor/login">
                Doctor
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item as={Link} to="/patient/login">
                Patient
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item as={Link} to="/diagnostic/login">
                Diagnostic
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item as={Link} to="/pharmacy/login">
                Pharmacy
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item as={Link} to="/agent/login">
                Agent
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item as={Link} to="/corporate/login">
                Corporate
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item as={Link} to="/hospital/login">
                Hospital
              </Dropdown.Item>
            </DropdownButton>
            <DropdownButton
              className="drop-button-new"
              id="dropdown-basic-button"
              title="Sign Up"
              variant="custom"
            >
              <Dropdown.Item as={Link} to="/doctor/register">
                Doctor
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item as={Link} to="/patient/register">
                Patient
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item as={Link} to="/diagnostic/register">
                Diagnostic
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item as={Link} to="/pharmacy/register">
                Pharmacy
              </Dropdown.Item>
            </DropdownButton>

            <Link
              to="/health-card"
              type="button"
              className="btn btn-lg btn-text-custom"
            >
              Health Card
            </Link>

            <Link
              to="/about-us"
              type="button"
              className="btn btn-lg btn-text-custom"
            >
              About Us
            </Link>
          </Nav>
          <Nav className="ml-auto">
            <Link
              to="/available-tests"
              type="button"
              className="btn btn-lg btn-custom-nav"
            >
              Book Now
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <div className="nav_height"></div>
    </div>
  );
}
