import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import Pagination from "@material-ui/lab/Pagination";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import _ from "lodash";

export default function TransactionList() {
  const [transactionList, setTransactionList] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [transactionId, setTransactionId] = useState(Number);

  const [defaultStatus, setDefaultStatus] = useState("");
  const [selecedStatus, setSelectedStatus] = useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [params, setParams] = useState({
    per_page: 15,
    page: 1,
  });

  useEffect(() => {
    getTransactionList();
  }, [params]);

  const getTransactionList = () => {
    AdminAPI.get(`transactions`, { params: params })
      .then((response) => {
        setTransactionList(response.data.data.data);
        setTotalPage(response.data.data.last_page);
        setPage(response.data.data.current_page);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };

  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);
  const updateTransaction = (status, id) => {
    console.log(id, status);
    if (status === "Active") {
      setDefaultStatus("Inactive");
      setSelectedStatus("Inactive");
    }
    if (status === "Inactive") {
      setDefaultStatus("Active");
      setSelectedStatus("Active");
    }

    setTransactionId(id);
    setOpenModal(true);
  };

  const changeStatus = (e) => {
    setSelectedStatus(e.target.value);
  };
  const submitStatus = () => {
    let data = {
      status: selecedStatus,
    };
    AdminAPI.post(`bank-accounts/${transactionId}/update-status`, data)
      .then((response) => {
        alert("You have successfully updated the account status");
        getTransactionList();
        setOpenModal(false);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  return (
    <div>
      <div>
        <div className="row">
          <div className="col-md-3 form-group">
            <select
              className="form-control"
              onChange={(e) => {
                handleColumnWiseSearch(e.target.value, "status");
              }}
            >
              <option value="">All</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
          </div>
          <div className="col-md-4 text-right form-group">
            <input
              placeholder="Search account"
              className="form-control"
              type="text"
              onChange={(e) =>
                handleColumnWiseSearch(e.target.value, "search")
              }
            />
          </div>
          <div className="col-md-4 form-group">
            <Link
              to={`/admin/transaction/add`}
              type="button"
              className="btn btn-outline-secondary float-right"
            >
              New Transaction
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className=" table-responsive">
              <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">UID</th>
                    <th scope="col">Client</th>
                    <th scope="col">Receiver</th>
                    <th scope="col">Type</th>
                    <th scope="col">Method</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {transactionList.map((transaction, i) => (
                    <tr key={i}>
                      <td>{transaction.unique_id}</td>
                      <td>{transaction.client_name}</td>
                      <td>{transaction.receiver_name}</td>
                      <td>{transaction.income_expense}</td>
                      <td>{transaction.method}</td>
                      <td>{transaction.amount}</td>
                      <td>{transaction.status}</td>
                      <td>
                        <Link
                          to={`/admin/transaction/view/${transaction.id}`}
                          type="button"
                          className="btn btn-sm btn-primary mb-1"
                        >
                          <VisibilityIcon />
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handleChange}
          />
        </div>
        <Dialog
          fullWidth
          maxWidth={`sm`}
          open={openModal}
          onClose={() => setOpenModal(false)}
        >
          <DialogTitle id="max-width-dialog-title">
            Change Account Status
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <select
                className=" form-control"
                defaultValue={defaultStatus}
                onChange={(event) => changeStatus(event)}
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenModal(false)} color="primary">
              Close
            </Button>
            <Button onClick={() => submitStatus()} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
