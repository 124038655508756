import React, { useState, useEffect } from "react";
import Pagination from "@material-ui/lab/Pagination";

import { Modal } from "antd";
import { Link } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import moment from "moment";

import "./css/doc-dashboard.css";
import "./css/schedule.css";
import { DoctorAPI } from "../../AxiosRequest/DoctorAPI";
import { getDayName } from "../../utils/helpers";

export default function Schedules() {
  const [schedulesData, setSchedulesData] = useState([]);
  const [statusModal, setStatusModal] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);

  const [params, setParams] = useState({
    per_page: 15,
    page: 1,
  });

  useEffect(() => {
    GetAllSchedules();
  }, [params]);
  const GetAllSchedules = () => {
    DoctorAPI.get("schedules", { params: params })
      .then((response) => {
        setSchedulesData(response.data.data.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };

  const changeStatus = (e, id) => {
    let url = `schedules/${id}/update-status`;
    DoctorAPI.post(url, {
      status: e.target.value,
    })
      .then((response) => {
        console.log(response);
        setStatusMessage(response.data.message);
        setStatusModal(true);
      })
      .catch((error) => {
        console.log(error.response);
        setStatusMessage(error.response.data.message);
      });
  };
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-right">
            <Link
              to="/doctor/add-schedule"
              type="button"
              className="btn btn-lg btn-success"
            >
              Add Schedule
            </Link>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <table className="custom-responsive-table">
            <thead>
              <tr>
                <th>Day</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Location</th>
                <th>Status</th>

                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {schedulesData.map((row, index) => (
                <tr key={index}>
                  <td data-column="Day"> {getDayName(row.day)} </td>
                  <td data-column="Start Time">
                    {" "}
                    {moment(row.start_time, "hh:mm A").format("hh:mm A")}{" "}
                  </td>
                  <td data-column="End Time">
                    {" "}
                    {moment(row.end_time, "hh:mm A").format("hh:mm A")}{" "}
                  </td>
                  <td data-column="Location"> {row.location}</td>
                  <td data-column="Status">
                    {" "}
                    <select
                      className=" form-control"
                      defaultValue={row.status}
                      onChange={(event) => changeStatus(event, row.id)}
                    >
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </select>
                  </td>
                  <td data-column="Actions">
                    {" "}
                    <Link to={`/doctor/edit_schedule/${row.id}`}>
                      <i
                        className="fa fa-pencil-square-o"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <Modal
            title=""
            centered
            visible={statusModal}
            onOk={() => setStatusModal(false)}
            onCancel={() => setStatusModal(false)}
          >
            <p>{statusMessage}</p>
          </Modal>
        </div>
      </div>
    </div>
  );
}
