import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import { AgentAPI } from "../../../AxiosRequest/AgentAPI";
import Pagination from "@material-ui/lab/Pagination";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import moment from "moment";
import _ from "lodash";

export default function PatientList() {
  const [patientList, setPatientList] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [patientId, setpatientId] = useState(Number);

  const [defaultStatus, setDefaultStatus] = useState("");
  const [selecedStatus, setSelectedStatus] = useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [params, setParams] = useState({
    per_page: 15,
    page: 1,
  });

  useEffect(() => {
    getPatientList();
  }, [params]);

  const getPatientList = () => {
    AgentAPI.get(`patients`, { params: params })
      .then((response) => {
        console.log("Patient response", response.data);
        setPatientList(response.data.data.data);
        setTotalPage(response.data.data.last_page);
        setPage(response.data.data.current_page);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };

  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);
  const updatePatient = (status, id) => {
    console.log(id, status);
    if (status === "Active") {
      setDefaultStatus("Inactive");
      setSelectedStatus("Inactive");
    }
    if (status === "Inactive") {
      setDefaultStatus("Active");
      setSelectedStatus("Active");
    }

    setpatientId(id);

    setOpenModal(true);
  };

  const changeStatus = (e) => {
    setSelectedStatus(e.target.value);
  };
  const submitStatus = () => {
    let data = {
      status: selecedStatus,
    };
    AgentAPI.post(`patients/${patientId}/update-status`, data)
      .then((response) => {
        alert("You have successfully updated the patient status");
        getPatientList();
        setOpenModal(false);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <input
                placeholder="Search Patient"
                className="form-control"
                type="text"
                onChange={(e) =>
                  handleColumnWiseSearch(e.target.value, "search")
                }
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className=" text-right">
              <Link
                to={`/agent/patient/register`}
                type="button"
                className="btn btn-sm btn-primary mb-1"
              >
                Add Patient
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className=" table-responsive">
              <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Contact </th>
                    <th scope="col">Passport</th>
                    <th scope="col">Birth date</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {patientList.map((patient, i) => (
                    <tr key={i}>
                      <td>{patient.name}</td>
                      <td>{patient.email}</td>
                      <td>{patient.phone}</td>
                      <td>{patient.passport}</td>
                      <td>{moment(patient.date_of_birth).format("DD/MM/YYYY")}</td>
                      
                      <td>
                        <Link
                          to={`/agent/patient/view/${patient.id}`}
                          type="button"
                          className="btn btn-sm btn-primary mb-1"
                        >
                          View
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handleChange}
          />
        </div>
        <Dialog
          fullWidth
          maxWidth={`sm`}
          open={openModal}
          onClose={() => setOpenModal(false)}
        >
          <DialogTitle id="max-width-dialog-title">
            Change Patient Status
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <select
                className=" form-control"
                defaultValue={defaultStatus}
                onChange={(event) => changeStatus(event)}
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenModal(false)} color="primary">
              Close
            </Button>
            <Button onClick={() => submitStatus()} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
