import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import Pagination from "@material-ui/lab/Pagination";
import VisibilityIcon from '@material-ui/icons/Visibility';
import _ from "lodash";
export default function PatientUploadedTest() {
  let history = useHistory();
  const [uploadedList, setUploadedList] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);

  const [params, setParams] = useState({
    Type: "Test",
    per_page: 15,
    page: 1,
  });

  useEffect(() => {
    getUploadedTest();
  }, [params]);

  const getUploadedTest = () => {
    AdminAPI.get(`uploaded-prescriptions`, { params: params })
      .then((response) => {
        console.log(response.data.data);
        setUploadedList(response.data.data.data);
        setTotalPage(response.data.data.last_page);
        setPage(response.data.data.current_page);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);

  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };
  const routeToUploadDetails = (id) => {
    history.push(`/admin/test/uploaded-test/${id}`);
  };
  const routeToInvoiceDetails = (id) => {
    history.push(`/admin/test/view/${id}`);
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-3 form-group">
          <select className="form-control"
            onChange={(e) => {
              handleColumnWiseSearch(e.target.value, "status");
            }}
          >
            <option value="">All</option>
            <option value="Pending">Pending</option>
            <option value="Reviewed">Reviewed</option>
          </select>
        </div>

        <div className="col-md-4 ml-auto">
          <input
            type="text"
            onChange={(e) => handleColumnWiseSearch(e.target.value, "search")}
            className="form-control"
            placeholder="Search"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive">
            <table className="table table-sm table-striped table-bordered custom-sm">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Diagnostic Name</th>
                  <th scope="col">Patient</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {uploadedList.map((test, i) => (
                  <tr key={i}>
                    <td data-column="Date">
                      {moment(test.created_at).format("DD/MM/YYYY HH:mm:ss")}
                    </td>
                    <td data-column="Diagnostic Name">
                      {test.service_provider.name}
                    </td>
                    <td data-column="Patient">{test.patient.name}</td>
                    <td data-column="Status">{test.status}</td>
                    <td data-column="Action">
                      <button
                        onClick={() => routeToUploadDetails(test.id)}
                        className="btn btn-sm btn-outline-secondary"
                      >
                        <VisibilityIcon />
                      </button>
                      {test.status == "Reviewed" && test.test_invoice != null &&
                        <button
                          onClick={() => routeToInvoiceDetails(test.test_invoice.id)}
                          className="btn btn-sm btn-primary mx-2"
                        >
                          <VisibilityIcon />
                        </button>
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="row">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handleChange}
          />
        </div>
    </div>
  );
}
