import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { TextField as TextFieldSecond } from "formik-material-ui";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Link } from "react-router-dom";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import axios from "axios";
import * as Yup from "yup";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";

import _, { isEmpty, parseInt, values } from "lodash";

export default function PatientRegistration() {
  let history = useHistory();
  let { pId } = useParams();

  const [policeStations, setPoliceStations] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [policeStationValue, setPoliceStationValue] = useState("");
  const [policeStationID, setPoliceStationID] = useState("");
  const [stationSearchTerm, setStationSearchTerm] = useState("");
  const [patientDetails, setPatientDetails] = useState({});
  const [districtList, setDistrictList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [signedUpMessage, setSignedUpMessage] = useState("");
  const [singnedUp, setSignedUp] = useState(false);
  const [districtSearchTerm, setDistrictSearchTerm] = useState("");
  // Backend Error Messages
  const [nameErros, setNameErrors] = useState([]);
  const [phoneErros, setPhoneError] = useState([]);
  const [ageErrors, setAgeErrors] = useState([]);
  const [genderErrors, setGenderErrors] = useState([]);
  const [emailErrors, setEmailErrors] = useState([]);
  const [passportErrors, setPassportErrors] = useState([]);
  const [areaErrors, setAreaErrors] = useState([]);

  const [bloodGroupErrors, setBloodgroupErrors] = useState([]);

  const [passwordErrors, setPasswordErrors] = useState([]);
  const [passwordConfirmationErrors, setPaswordConfirmationErros] = useState(
    []
  );
  const [district, setDistrict] = useState("");
  useEffect(() => {
    if (pId) getPatientDetails();
  }, []);
  function validationOnCondition(dob, age) {
    let error;
    console.log("DOB", dob, age);
    if (isEmpty(dob) && isEmpty(age)) {
      error = "Please fill up at least DOB or age";
    }

    return error;
  }
  const getPatientDetails = () => {
    AdminAPI.get(`patients/${pId}`)
      .then((response) => {
        setPatientDetails(response.data.data);
        setPoliceStationValue(response.data.data.police_station.name);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  useEffect(() => {
    GetPoliceStations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stationSearchTerm]);
  useEffect(() => {
    GetCoupons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetPoliceStations = () => {
    // let url = `http://localhost:3001/purchase_order/${paramID}`;
    axios
      .get(`police-stations?name=${stationSearchTerm}`)
      .then((response) => {
        setPoliceStations(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const GetCoupons = () => {
    // let url = `http://localhost:3001/purchase_order/${paramID}`;
    AdminAPI.get(`coupons`)
      .then((response) => {
        setCoupons(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleOnInputCapturePolice = _.debounce((event) => {
    setStationSearchTerm(event.target.value);
  }, 500);
  function validateRequirement(value) {
    let error;

    if (!value) {
      error = "Required!";
    }

    return error;
  }
  const validationSchema = Yup.object({
    name: Yup.string().required("Name field is required"),
    phone: Yup.string().required("Please enter your phone number"),
    // email: Yup.string().email("Please Enter a valid Email"),
    // password: Yup.string()
    //   .min(6, "Password must contain at least 6 characters")
    //   .required("Enter your password"),
    // confirm_password: Yup.string()
    //   .required("Confirm your password")
    //   .oneOf([Yup.ref("password")], "Password does not match"),
    // date_of_birth: Yup.date()
    //   // .required("Date of Birth field is required")
    //   .typeError("Date of Birth field is required"),
    gender: Yup.string().required("Gender field is required"),
    // blood_group: Yup.string().required("Blood Group field is required"),
  });
  const validationSchemaUpdate = Yup.object({
    name: Yup.string().required("Name field is required"),
    phone: Yup.string().required("Please enter your phone number"),
    // email: Yup.string().email("Please Enter a valid Email"),

    // date_of_birth: Yup.date()
    //   .required("Date of Birth field is required")
    //   .typeError("Date of Birth field is required"),
    gender: Yup.string().required("Gender field is required"),
    // blood_group: Yup.string().required("Blood Group field is required"),
  });
  const initialValues = {
    name: "" || patientDetails.name,
    phone: "" || patientDetails.phone,
    gender: "" || patientDetails.gender,

    email: "" || patientDetails.email,
    passport: "" || patientDetails.passport,
    police_station_id: "" || patientDetails.police_station_id,
    area: "" || patientDetails.area,
    password: "",
    confirm_password: "",
    has_coupon: false || patientDetails.has_coupon,
    coupon_id: "" || patientDetails.coupon_id,
    date_of_birth: patientDetails.date_of_birth || null,
    age: "" || patientDetails.age,
  };

  const onSubmit = (values, { resetForm }) => {
    setLoading(true);
    let objToPost = { ...values };
    if (objToPost.police_station_id === "") {
      delete objToPost.police_station_id;
    }
    if (objToPost.police_station_id === "") {
      delete objToPost.police_station_id;
    }
    Object.keys(objToPost).forEach(
      (k) => objToPost[k] === "" && delete objToPost[k]
    );

    if (pId) {
      delete objToPost.passport;
      delete objToPost.confirm_password;
    }
    if (objToPost.has_coupon === false) {
      delete objToPost.coupon_id;
    }
    if (objToPost.has_coupon === true) {
      parseInt(objToPost.coupon_id);
    }
    if (isEmpty(objToPost.age)) {
      delete objToPost.age;
    }

    if (isEmpty(objToPost.date_of_birth)) {
      delete objToPost.date_of_birth;
    }

    if (!pId) {
      AdminAPI.post("patients", objToPost)
        .then((response) => {
          console.log(response);
          console.log(response.data.message);
          setSignedUp(true);
          setSignedUpMessage("Patient successfully registered");
          setLoading(false);
          history.push("/admin/patient/list");
        })
        .catch((error) => {
          console.log(error.response);
          setLoading(false);
          if (error.response.data.message) {
          }

          if (error.response.data.errors !== undefined) {
            let nameErrors = error.response.data.errors.name;
            let emailErrors = error.response.data.errors.email;
            let phoneErrors = error.response.data.errors.phone;
            let birthErrors = error.response.data.errors.date_of_birth;
            let sexErrors = error.response.data.errors.gender;
            let bloodErrors = error.response.data.errors.blood_group;
            let passwordErrors = error.response.data.errors.password;
            let passportErrors = error.response.data.errors.passport;
            let areaErrors = error.response.data.errors.area;

            let confirmPasswordErrors =
              error.response.data.errors.confirm_password;

            if (nameErrors !== undefined) {
              setNameErrors(nameErrors);
            }
            if (phoneErrors !== undefined) {
              setPhoneError(phoneErrors);
            }
            if (birthErrors !== undefined) {
              setAgeErrors(birthErrors);
            }
            if (sexErrors !== undefined) {
              setGenderErrors(sexErrors);
            }
            if (bloodErrors !== undefined) {
              setBloodgroupErrors(bloodErrors);
            }
            if (passwordErrors !== undefined) {
              setPasswordErrors(passwordErrors);
            }
            if (confirmPasswordErrors !== undefined) {
              setPaswordConfirmationErros(confirmPasswordErrors);
            }
            if (emailErrors !== undefined) {
              setEmailErrors(emailErrors);
            }
            if (passportErrors !== undefined) {
              setPassportErrors(passportErrors);
            }
            if (areaErrors !== undefined) {
              setAreaErrors(areaErrors);
            }
          }
        });
    } else {
      AdminAPI.put(`patients/${pId}`, objToPost)
        .then((response) => {
          setLoading(false);
          history.push("/admin/patient/list");
        })
        .catch((error) => {
          console.log(error.response);
          setLoading(false);
          if (error.response.data.message) {
          }

          if (error.response.data.errors !== undefined) {
            let nameErrors = error.response.data.errors.name;
            let emailErrors = error.response.data.errors.email;
            let phoneErrors = error.response.data.errors.phone;
            let birthErrors = error.response.data.errors.date_of_birth;
            let sexErrors = error.response.data.errors.gender;
            let bloodErrors = error.response.data.errors.blood_group;
            let passwordErrors = error.response.data.errors.password;
            let passportErrors = error.response.data.errors.passport;
            let areaErrors = error.response.data.errors.area;

            let confirmPasswordErrors =
              error.response.data.errors.confirm_password;

            if (nameErrors !== undefined) {
              setNameErrors(nameErrors);
            }
            if (phoneErrors !== undefined) {
              setPhoneError(phoneErrors);
            }
            if (birthErrors !== undefined) {
              setAgeErrors(birthErrors);
            }
            if (sexErrors !== undefined) {
              setGenderErrors(sexErrors);
            }
            if (bloodErrors !== undefined) {
              setBloodgroupErrors(bloodErrors);
            }
            if (passwordErrors !== undefined) {
              setPasswordErrors(passwordErrors);
            }
            if (confirmPasswordErrors !== undefined) {
              setPaswordConfirmationErros(confirmPasswordErrors);
            }
            if (emailErrors !== undefined) {
              setEmailErrors(emailErrors);
            }
            if (passportErrors !== undefined) {
              setPassportErrors(passportErrors);
            }
            if (areaErrors !== undefined) {
              setAreaErrors(areaErrors);
            }
          }
        });
    }
  };
  return (
    <div>
      <div className="doc-register-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <div className="row">
                <div className="col-lg-12">
                  <div className="doc-register-form">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={
                        !pId ? validationSchema : validationSchemaUpdate
                      }
                      onSubmit={onSubmit}
                      enableReinitialize
                    >
                      {({
                        setFieldValue,
                        isValid,
                        dirty,
                        values,
                        setTouched,
                      }) => (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group row">
                                <label className="col-sm-4">
                                  Name <span className="text-danger">*</span>{" "}
                                </label>
                                <div className="col-sm-8">
                                  <Field
                                    name="name"
                                    type="text"
                                    className="form-control"
                                    placeholder="Name"
                                  />
                                  <ErrorMessage
                                    name="name"
                                    component="div"
                                    className="error-message"
                                  />
                                  {nameErros.map((item, i) => (
                                    <p className="error-message" key={i}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group row">
                                <label className="col-sm-4">
                                  Phone <span className="text-danger">*</span>{" "}
                                </label>
                                <div className="col-sm-8">
                                  <Field
                                    name="phone"
                                    type="text"
                                    className="form-control"
                                    placeholder="Phone/Mobile Number"
                                  />
                                  <ErrorMessage
                                    name="phone"
                                    component="div"
                                    className="error-message"
                                  />
                                  {phoneErros.map((item, i) => (
                                    <p className="error-message" key={i}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row">
                                <label className="col-sm-4 ">
                                  Gender <span className="text-danger">*</span>{" "}
                                </label>
                                <div className="col-sm-8">
                                  <Field
                                    className="form-control"
                                    as="select"
                                    name="gender"
                                  >
                                    <option value=""></option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Others">Others</option>
                                  </Field>
                                  <ErrorMessage
                                    name="gender"
                                    component="div"
                                    className="error-message"
                                  />
                                  {genderErrors.map((item, i) => (
                                    <p className="error-message" key={i}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>

                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <div className="form-group row ">
                              <label className="col-sm-3 offset-sm-1">
                                Date of birth{" "}
                              </label>
                              <div className="col-sm-3">
                                <DatePicker
                                  disableFuture
                                  format="dd/MM/yyyy"
                                  views={["year", "month", "date"]}
                                  name={"date_of_birth"}
                                  clearable
                                  autoOk
                                  inputVariant="outlined"
                                  // handle clearing outside => pass plain array if you are not controlling value outside
                                  mask={(value) =>
                                    value
                                      ? [
                                          /[0-3]/,
                                          /\d/,
                                          "/",
                                          /0|1/,
                                          /\d/,
                                          "/",
                                          /1|2/,
                                          /\d/,
                                          /\d/,
                                          /\d/,
                                        ]
                                      : []
                                  }
                                  onChange={(value) => {
                                    // setDOB(value);
                                    setFieldValue(
                                      "date_of_birth",
                                      moment(value).format("YYYY-MM-DD")
                                    );
                                    setTouched({
                                      date_of_birth: false,
                                      age: false,
                                    });
                                  }}
                                  value={values.date_of_birth}
                                  animateYearScrolling={false}
                                  renderInput={(params) => (
                                    <Field
                                      component={TextFieldSecond}
                                      {...params}
                                      // onChange={(e, value) => {
                                      //   console.log(e.target.value);
                                      //   // setFieldValue("police_station_id", 0);
                                      // }}
                                      name="date_of_birth"
                                      variant="outlined"
                                      validate={validateRequirement}
                                      fullWidth
                                    />
                                  )}
                                />
                                {/* <Field
                            component={DateOfBirthDatePicker}
                            name="date_of_birth"
                            onChange={(e) => {
                              handleChange(e);
                              console.log("EEEE", e);
                            }}
                            // validate={validateRequirement}
                            validate={() =>
                              validationOnCondition(
                                values.date_of_birth,
                                values.age
                              )
                            }
                          /> */}
                                <ErrorMessage
                                  name="date_of_birth"
                                  component="div"
                                  className="error-message"
                                />
                              </div>

                              <div className="col-sm-4">
                                <div className="form-group row ">
                                  <label className="col-sm-3 offset-sm-1">
                                    Or Age
                                  </label>
                                  <div className="col-sm-8">
                                    <Field
                                      className=" form-control"
                                      name="age"
                                      validate={() =>
                                        validationOnCondition(
                                          values.date_of_birth,
                                          values.age
                                        )
                                      }
                                    />
                                    <ErrorMessage
                                      name="age"
                                      component="div"
                                      className="error-message"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </MuiPickersUtilsProvider>
                          <div className="row">
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row">
                                <label className="col-sm-4">Email</label>
                                <div className="col-sm-8">
                                  <Field
                                    name="email"
                                    type="text"
                                    className="form-control"
                                    placeholder="Email address"
                                  />
                                  <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="error-message"
                                  />
                                  {emailErrors.map((item, i) => (
                                    <p className="error-message" key={i}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row">
                                <label className="col-sm-4">Passport</label>
                                <div className="col-sm-8">
                                  <Field
                                    name="passport"
                                    type="text"
                                    className="form-control"
                                    placeholder="Passport no"
                                  />
                                  <ErrorMessage
                                    name="passport"
                                    component="div"
                                    className="error-message"
                                  />
                                  {passportErrors.map((item, i) => (
                                    <p className="error-message" key={i}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group row">
                                <label className="col-sm-4">
                                  Police station
                                </label>
                                <div className="col-sm-8">
                                  <Autocomplete
                                    freeSolo
                                    id="disabledHelperText"
                                    name="police_station_id"
                                    options={policeStations}
                                    // value={policeStationValue}
                                    onInputCapture={(event) => {
                                      event.persist(); // As we are accessing the event in asynchronous way.
                                      handleOnInputCapturePolice(event);
                                    }}
                                    // getOptionLabel={(option) => option.name}
                                    getOptionLabel={(option) => {
                                      // return option.name || "";
                                      if (pId)
                                        return (
                                          option.name || policeStationValue
                                        );
                                      return (
                                        option.name +
                                          " " +
                                          "(" +
                                          option.district +
                                          ")" || policeStationValue
                                      );
                                    }}
                                    onChange={(e, value) => {
                                      if (value == null) {
                                        setFieldValue("police_station_id", "");
                                        setPoliceStationValue("");
                                      } else {
                                        console.log(value);
                                        console.log(value.id);
                                        setFieldValue(
                                          "police_station_id",
                                          value.id
                                        );
                                        setPoliceStationValue(value.name);
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <Field
                                        component={TextFieldSecond}
                                        {...params}
                                        onChange={(e, value) => {
                                          console.log(e.target.value);
                                          setFieldValue("police_station_id", 0);
                                        }}
                                        name="police_station_id"
                                        variant="outlined"
                                        fullWidth
                                      />
                                    )}
                                  />
                                  <ErrorMessage
                                    name="police_station_id"
                                    component="div"
                                    className="error-message"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group row">
                                <label className="col-sm-2">Address</label>
                                <div className="col-sm-10">
                                  <Field
                                    name="area"
                                    type="text"
                                    className="form-control"
                                    placeholder="Address"
                                  />
                                  <ErrorMessage
                                    name="area"
                                    component="div"
                                    className="error-message"
                                  />
                                  {areaErrors.map((item, i) => (
                                    <p className="error-message" key={i}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          {!pId && (
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group row">
                                  <label className="col-sm-4 ">
                                    Password{" "}
                                    <span className="text-danger">*</span>{" "}
                                  </label>
                                  <div className="col-sm-8">
                                    <Field
                                      name="password"
                                      type="password"
                                      className="form-control "
                                      placeholder="password"
                                    />
                                    <ErrorMessage
                                      name="password"
                                      component="div"
                                      className="error-message"
                                    />
                                    {passwordErrors.map((item, i) => (
                                      <p className="error-message" key={i}>
                                        {item}
                                      </p>
                                    ))}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group row">
                                  <label className="col-sm-4 ">
                                    Confirm Password{" "}
                                    <span className="text-danger">*</span>{" "}
                                  </label>
                                  <div className="col-sm-8">
                                    <Field
                                      name="confirm_password"
                                      type="password"
                                      className="form-control "
                                      placeholder="password"
                                    />
                                    <ErrorMessage
                                      name="confirm_password"
                                      component="div"
                                      className="error-message"
                                    />
                                    {passwordConfirmationErrors.map(
                                      (item, i) => (
                                        <p className="error-message" key={i}>
                                          {item}
                                        </p>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="row">
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row">
                                <label className="col-sm-4">Coupon Code?</label>
                                <div className="col-sm-8">
                                  <Field
                                    name="has_coupon"
                                    type="checkbox"
                                    className="form-controla"
                                  />
                                  <ErrorMessage
                                    name="has_coupon"
                                    component="div"
                                    className="error-message"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {values.has_coupon === true && (
                            <div className="row">
                              <div className="col-md-6">
                                {" "}
                                <div className="form-group row">
                                  <label className="col-sm-4 ">
                                    Coupon Code{" "}
                                    <span className="text-danger">*</span>{" "}
                                  </label>
                                  <div className="col-sm-8">
                                    <Field
                                      className="form-control"
                                      as="select"
                                      name="coupon_id"
                                    >
                                      <option value=""></option>
                                      {coupons.length > 0 &&
                                        coupons.map((c, i) => (
                                          <option key={i} value={c.id}>
                                            {c.code}
                                          </option>
                                        ))}
                                    </Field>
                                    <ErrorMessage
                                      name="coupon_id"
                                      component="div"
                                      className="error-message"
                                    />
                                    {genderErrors.map((item, i) => (
                                      <p className="error-message" key={i}>
                                        {item}
                                      </p>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {!pId ? (
                            <div className="form-group row">
                              <label className="col-sm-5 offset-sm-1">
                                <button
                                  disabled={loading}
                                  // disabled={!isValid || !dirty}
                                  type="submit"
                                  className="btn btn-lg btn-success"
                                >
                                  {loading && (
                                    <i className="fa fa-refresh fa-spin"></i>
                                  )}
                                  {loading && <span>Registering</span>}
                                  {!loading && <span> Register</span>}
                                </button>
                              </label>
                              <div className="col-sm-8"></div>
                            </div>
                          ) : (
                            <div className="form-group row">
                              <label className="col-sm-5 offset-sm-1">
                                <button
                                  disabled={loading}
                                  // disabled={!isValid || !dirty}
                                  type="submit"
                                  className="btn btn-lg btn-success"
                                >
                                  {loading && (
                                    <i className="fa fa-refresh fa-spin"></i>
                                  )}
                                  {loading && <span>Updating</span>}
                                  {!loading && <span> Update</span>}
                                </button>
                              </label>
                              <div className="col-sm-8"></div>
                            </div>
                          )}

                          <div className="form-group row">
                            <label className="col-sm-11 offset-sm-1">
                              {signedUpMessage}
                            </label>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-1"></div>
        </div>
      </div>
    </div>
  );
}
