import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { DoctorAPI } from "../../AxiosRequest/DoctorAPI";
import TextField from "@material-ui/core/TextField";
import _, { debounce } from "lodash";
import Pagination from "@material-ui/lab/Pagination";
import { Link } from "react-router-dom";
export default function ReferDocTestList({ admin }) {
  const [diagnosticList, setDiagnosticList] = useState([]);
  const [methodList, setMethodList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [methodSearchTerm, setMethodSearchTerm] = useState("");
  const [sampleSearchTerm, setSampleSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [diagnosticID, setDiagnosticID] = useState("");
  const [testList, setTestList] = useState([]);
  const [params, setParams] = useState({
    page: 1,
    per_page: 15,
  });

  useEffect(() => {
    getDiagnosticList();
    getTestList();
  }, []);

  const getDiagnosticList = () => {
    axios
      .get("diagnostics")
      .then((response) => {
        console.log(response.data.data.data);
        setDiagnosticList(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  function handleChangePage(event, value) {
    setParams({ ...params, page: value });
  }
  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);
  useEffect(() => {
    getTestList();
  }, [params]);
  useEffect(() => {
    getMethodList();
  }, [methodSearchTerm]);
  useEffect(() => {
    getSampleList();
  }, [sampleSearchTerm]);
  const getTestList = () => {
    DoctorAPI.get("diagnostic-tests", {
      params: params,
    })
      .then((response) => {
        setTestList(response.data.data.data);
        setCurrentPage(response.data.data.current_page);
        setTotalPage(response.data.data.last_page);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleOnInputCaptureMethod = debounce((event) => {
    setMethodSearchTerm(event.target.value);
  }, 500);
  const handleOnInputCaptureType = debounce((event) => {
    setSampleSearchTerm(event.target.value);
  }, 500);
  const getMethodList = () => {
    DoctorAPI.get(`diagnostic-test-methods?method=${methodSearchTerm}`)
      .then((response) => {
        setMethodList(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const getSampleList = () => {
    DoctorAPI.get(
      `diagnostic-test-sample-types?sample_type=${sampleSearchTerm}`
    )
      .then((response) => {
        setTypeList(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  return (
    <div>
      <div className="container-fluid">
        <div className="row ">
          <div className="col-md-10">
            <div className="form-group row">
              <label className="col-md-4 col-12">Select Diagonstic</label>
              <div className="col-md-8 col-12">
                <Autocomplete
                  id="disabledHelperText"
                  name="diagnostic_id"
                  options={diagnosticList.filter((dig) => dig.id !== 1)}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, value) => {
                    if (value == null) {
                      setDiagnosticID("");
                      setParams({
                        ...params,
                        service_provider_id: null,
                      });
                    } else {
                      setDiagnosticID(value.id);
                      setParams({
                        ...params,
                        service_provider_id: value.id,
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="diagnostic_id"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="col-md-2 text-right"></div>
        </div>
        {typeList.length > 0 && (
          <div className="row ">
            <div className="col-md-10">
              <div className="form-group row">
                <label className="col-md-4 col-12">Select Sample Type</label>
                <div className="col-md-8 col-12">
                  <Autocomplete
                    id="disabledHelperText"
                    name="diagnostic_id"
                    options={typeList}
                    getOptionLabel={(option) => option.sample_type}
                    onInputCapture={(event) => {
                      event.persist(); // As we are accessing the event in asynchronous way.
                      handleOnInputCaptureType(event);
                    }}
                    onChange={(e, value) => {
                      if (value === null) {
                        handleColumnWiseSearch("", "sample_type");
                        return;
                      }
                      handleColumnWiseSearch(value.sample_type, "sample_type");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="diagnostic_id"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-2 text-right"></div>
          </div>
        )}
        {methodList.length > 0 && (
          <div className="row ">
            <div className="col-md-10">
              <div className="form-group row">
                <label className="col-md-4 col-12">Select Method</label>
                <div className="col-md-8 col-12">
                  <Autocomplete
                    id="disabledHelperText"
                    name="diagnostic_id"
                    options={methodList}
                    getOptionLabel={(option) => option.method}
                    onInputCapture={(event) => {
                      event.persist(); // As we are accessing the event in asynchronous way.
                      handleOnInputCaptureMethod(event);
                    }}
                    onChange={(e, value) => {
                      if (value === null) {
                        handleColumnWiseSearch("", "method");
                        return;
                      }
                      handleColumnWiseSearch(value.method, "method");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="diagnostic_id"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-2 text-right"></div>
          </div>
        )}

        <div className="row my-2">
          <div className="col-md-5 form-group">
            <input
              type="text"
              onChange={(e) => handleColumnWiseSearch(e.target.value, "name")}
              className="form-control"
              placeholder="Search Test"
            />
            {/* <input
              type="text"
              onChange={(e) => handleColumnWiseSearch(e.target.value, "name")}
              className="form-control"
              placeholder="Search Test"
            /> */}
          </div>
          {/* <div className="col-md-4 form-group">
            <select
              className="form-control"
              onChange={(e) => {
                handleColumnWiseSearch(e.target.value, "method");
              }}
            >
              <option value="">Select Method</option>
              {methodList.length > 0 &&
                methodList.map((method) => (
                  <option value={method.method}>{method.method}</option>
                ))}
            </select>
          </div> */}
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="custom-responsive-table">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Test Name</th>
                    <th scope="col">Lab</th>
                    <th scope="col">Method</th>
                    <th scope="col">Details</th>
                    <th scope="col">Sample Type</th>
                    <th scope="col">TAT</th>
                    <th scope="col">Test Price</th>
                    <th scope="col">Discounted Price</th>

                    <th scope="col">Home Sample</th>
                  </tr>
                </thead>
                <tbody>
                  {testList.map((test, i) => (
                    <tr key={i}>
                      <td data-column="Test Name">{test.name}</td>

                      <td data-column="Lab">{test.service_provider.name}</td>
                      <td data-column="Method">
                        {test.method ? test.method : ""}
                      </td>
                      <td data-column="Details">
                        {test.details ? test.details : ""}
                      </td>
                      <td data-column="Sample Type">
                        {test.sample_type ? test.sample_type : ""}
                      </td>
                      <td data-column="TAT">{test.tat ? test.tat : ""}</td>
                      <td data-column="Test Price">{Math.ceil(test.price)}</td>
                      <td data-column="Discounted Price">
                        {test !== undefined && (
                          <React.Fragment>
                            {test.discount_type === "%" ? (
                              <span>
                                {Math.ceil(
                                  test.price -
                                    (test.price / 100) * test.discount
                                )}
                              </span>
                            ) : (
                              <span>
                                {Math.ceil(test.price - test.discount)}
                              </span>
                            )}
                          </React.Fragment>
                        )}
                      </td>

                      <td data-column="Home Sample">
                        {test.home_delivery ? "Yes" : "No"}
                      </td>
                      {/* <td data-column="status">
                        {" "}
                        {test.status == "Active" ? (
                          <span>{test.status}</span>
                        ) : (
                          <span className="text-warning">{test.status}</span>
                        )}{" "}
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row">
          <Pagination
            color="primary"
            count={totalPage}
            page={currentPage}
            onChange={handleChangePage}
          />
        </div>
      </div>
    </div>
  );
}
