class Auth {
  constructor() {
    const diagnosticToken = localStorage.getItem("diagnosticToken");

    if (diagnosticToken !== null) {
      this.authenticated = true;
    }
  }

  login(cb) {
    this.authenticated = true;

    cb();
  }
  logout(cb) {
    this.authenticated = false;
    localStorage.removeItem("diagnosticToken");
    cb();
  }

  isAuthenticated() {
    return this.authenticated;
  }
}

export default new Auth();
