import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import HospitalForm from "./HospitalForm";

export default function HospitalDetails() {
  return (
    <div>
      <HospitalForm />
    </div>
  );
}
