import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { TextField as TextFieldSecond } from "formik-material-ui";
import axios from "axios";
import Badge from "@material-ui/core/Badge";

import Menu from "@material-ui/core/Menu";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import {
  makeStyles,
  fade,

  // useTheme
} from "@material-ui/core/styles";
import _ from "lodash";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Pagination from "@material-ui/lab/Pagination";
import { useHistory, useParams } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Popper from "@material-ui/core/Popper";
import { Link } from "react-router-dom";
import AddShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";

import DeleteIcon from "@material-ui/icons/Delete";
import TextField from "@material-ui/core/TextField";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { withStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import "./css/home.css";
import * as Yup from "yup";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Navbar2 from "../Shared/Navbar2";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";

import Divider from "@material-ui/core/Divider";

import MenuIcon from "@material-ui/icons/Menu";
import { Icon } from "@material-ui/core";
import "./css/login.css";
import Footer from "../Shared/Footer";
const drawerWidthCart = 320;
const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge);
const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    top: "10%",
    left: "10%",
    overflow: "hidden",
    height: "100%",
    maxHeight: 500,
    display: "block",
  },
  icnBtnRoot: {
    padding: "0px",
  },
  header: {
    padding: "12px 0",
    borderBottom: "1px solid darkgrey",
  },
  content: {
    padding: 12,
    overflow: "scroll",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,

    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: "#3f51b5",
    fontWeight: "700",
  },
  hide: {
    display: "none",
  },

  drawerCart: {
    width: drawerWidthCart,
    flexShrink: 0,
  },
  drawerPaperCart: {
    width: drawerWidthCart,
    [theme.breakpoints.up("sm")]: {
      top: "65px",
      width: drawerWidthCart,
    },
    [theme.breakpoints.down("sm")]: {
      top: "55px",
      width: "100%",
    },
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: -drawerWidth,
  },

  contentShift2: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up("sm")]: {
      marginRight: drawerWidthCart,
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  root: {
    display: "flex",
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

export default function TestDetailsHome(props) {
  const storage = localStorage.getItem("names")
    ? JSON.parse(localStorage.getItem("names"))
    : [];
  let history = useHistory();
  const theme = useTheme();
  const isTabMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let { diagName, testName } = useParams();
  const [open, setOpen] = useState(false);
  const [policeStations, setPoliceStations] = useState([]);
  const [diagnosticList, setDiagnosticList] = useState([]);
  const [users, setUsers] = useState([]);
  const [tableData, setTableData] = useState(storage);
  const [totalRegularPrice, setTotalRegularPrice] = useState(Number);
  const [totalDiscountPrice, setTotalDiscountPrice] = useState(Number);
  const [totalPrice, setTotalPrice] = useState(Number);
  const [policeStationID, setPoliceStationID] = useState("");
  const [diagnosticID, setDiagnosticID] = useState("");
  const [erroropen, setErrorOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [testList, setTestList] = useState([]);
  const [openCart, setOpenCart] = React.useState(true);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Backend Errors Handle
  const [backendErrorMessage, setBackendErrorMessage] = useState({});

  const [sameMedicineErrors, setSameMedicineErrors] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const [openDetails, setOpeDetails] = React.useState(false);
  const [openCheck, setOpenCheck] = React.useState(false);
  const [stationSearchTerm, setStationSearchTerm] = useState("");
  const [diagnosticValue, setDiagnosticValue] = useState("");
  const [homeSample, setHomeSample] = React.useState(false);
  const [covid, setCovid] = React.useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [params, setParams] = useState({
    page: 1,
    per_page: 15,
  });
  const [files, setFiles] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [previews, setPreviews] = useState([]);
  const [upFile, setUpFile] = useState("");
  const [openFile, setOpenFile] = useState(false);
  const [addFirstIndex, setAddFirstIndex] = useState(false);
  const [availableSlot, setAvailableSlot] = useState([]);
  let some = [];
  const [activeStep, setActiveStep] = React.useState(0);

  //   console.log("test namemmmmmmmmeaesfdsaf asdf dsa fdsf ", diagName, testName);
  useEffect(() => {
    if (isMobile) {
      console.log("Hello is mobile");
      setOpenCart(false);
    } else {
      setOpenCart(true);
    }
  }, [isMobile]);

  useEffect(() => {
    if (diagName && testName) {
      setAddFirstIndex(true);
      if (diagName === "Thyrocare") {
        setDiagnosticValue("Thyrocare");
        setDiagnosticID(4);
        setParams({
          ...params,
          service_provider_id: 4,
          test: testName,
        });
      } else if (diagName === "DNA-Solution") {
        setDiagnosticValue("DNA Solution");
        setDiagnosticID(5);
        setParams({
          ...params,
          service_provider_id: 5,
          test: testName,
        });
      } else if (diagName === "Stemz-Healthcare") {
        setDiagnosticValue("Stemz Healthcare");
        setDiagnosticID(6);
        setParams({
          ...params,
          service_provider_id: 6,
          test: testName,
        });
      } else if (diagName === "CRL-Diagnostics") {
        setDiagnosticValue("CRL Diagnostics");
        setDiagnosticID(14);
        setParams({
          ...params,
          service_provider_id: 14,
          test: testName,
        });
      } else if (diagName === "the-pathlabs") {
        setDiagnosticValue("The Pathlabs");
        setDiagnosticID(15);
        setParams({
          ...params,
          service_provider_id: 18,
          test: testName,
        });
      } else if (diagName === "covid") {
        // setCovid(true);
        // // getDiagnosticList();
        // setSearchField("a");
        // setDiagnosticValue("Stemz Healthcare");
        // setDiagnosticID(6);
        // setParams({
        //   ...params,
        //   service_provider_id: 6,
        //   test: "covid",
        // });
      } else {
        // console.log("diag nameeeee====>", diagName);
        // if (diagName !== "covid-travellers") {
        //   getDiagnosticListCovid(diagName);
        // }
        // setDiagnosticValue("Stemz Healthcare");
        // setDiagnosticID(6);
        // setParams({
        //   ...params,
        //   service_provider_id: 6,
        // });
      }
    } else {
      // setDiagnosticValue("Stemz Healthcare");
      // setDiagnosticID(6);
      // setParams({
      //   ...params,
      //   service_provider_id: 6,
      // });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.diagName]);

  useEffect(() => {
    GetPoliceStations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stationSearchTerm]);
  const GetPoliceStations = () => {
    // let url = `http://localhost:3001/purchase_order/${paramID}`;
    axios
      .get(`police-stations?name=${stationSearchTerm}`)
      .then((response) => {
        console.log("pppppp", response.data.data.data);
        setPoliceStations(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const handleOnInputCaptureMed = _.debounce((event) => {
    setStationSearchTerm(event.target.value);
  }, 500);

  const handleOpenDetails = () => {
    setOpeDetails(true);
  };

  const handleCloseDetails = () => {
    setOpeDetails(false);
  };

  const handleOpenCheck = () => {
    // setOpenCheck(true);
  };
  const handleDrawerOpenCart = () => {
    setOpenCart(!openCart);
  };
  const handleCloseCheck = () => {
    setOpenCheck(false);
  };

  useEffect(() => {
    if (params.test !== "" && params.test !== undefined) {
      getTestList();
    }
  }, [params]);

  const getTestList = () => {
    axios
      .get("https://heal-holmes.com/api/api/diagnostic-tests", {
        params: params,
      })
      .then((response) => {
        // console.log("testttttt", response.data);
        let testList = [...response.data.data];
        if (diagName && diagName.includes("general")) {
          let idsToRemoveGeneral = [1058];

          console.log("test list", testList);
          let general = testList.filter(
            (item) => !idsToRemoveGeneral.includes(item.id)
          );
          setTestList(general);
        } else if (diagName && diagName.includes("traveller")) {
          let idsToRemoveTraveller = [1055, 1056, 1057];

          console.log("test list", testList);
          let traveller = testList.filter(
            (item) => !idsToRemoveTraveller.includes(item.id)
          );
          setTestList(traveller);
        } else if (diagName && testName) {
          setTestList(response.data.data);
          if (addFirstIndex === true) {
            addToCart(response.data.data[0], 0);
          }
        } else {
          setTestList(response.data.data);
        }

        // let newArray = response.data.data;
        // console.log("cccccc boool", covid);

        //   console.log("cccccc", covidLabs);

        //
        setCurrentPage(response.data.current_page);
        setTotalPage(response.data.last_page);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  // const getusers = () => {
  //   axios
  //     .get("tests?diagnostic_id=1")
  //     .then((response) => {
  //       console.log(response.data.data);
  //       setUsers(response.data.data);
  //       // setTestList(response.data.data);
  //     })
  //     .catch((error) => {
  //       console.log(error.response);
  //     });
  // };
  useEffect(() => {
    getLocalTest();
    // console.log("testing general", diagName);
    // if (
    //   diagName &&
    //   diagName !== "covid-general" &&
    //   diagName !== "covid-travellers"
    // ) {
    //   getDiagnosticList();
    // } else if (diagName === undefined) {
    //   getDiagnosticList();
    // }

    GetPoliceStations();
    // getusers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [covid]);
  useEffect(() => {
    const testsTotalPrice = tableData.map((o) => parseInt(o.price));

    const testsTotalPercentageDiscount = tableData
      .filter((o) => o.discount_type === "%")
      .map((o) => {
        return parseInt((o.price / 100) * o.discount);
      });
    // const testsTotalPercentageDiscount = tableData
    //   .filter((o) => o.discount_type === "%")
    //   .map((o) => {
    //     return parseInt((o.price / 100) * o.discount);
    //   });
    const testsTotalFlatDiscount = tableData
      .filter((o) => o.discount_type === "Flat")
      .map((o) => parseInt(o.discount));
    // const testsTotalFlatDiscount = tableData.map((o) => {
    //   if () {
    //     return parseInt(o.price - o.discount);
    //   }
    // });
    console.log("total discoutn====>", testsTotalPercentageDiscount);
    console.log("total disaacout flat====>", testsTotalFlatDiscount);
    let totalPrice = testsTotalPrice.reduce(function (a, b) {
      return a + b;
    }, 0);
    setTotalRegularPrice(totalPrice);
    console.log("total", totalPrice, "aaaaaa");
    let totalDicountP = testsTotalPercentageDiscount.reduce(function (a, b) {
      return a + b;
    }, 0);
    let totalDicountF = testsTotalFlatDiscount.reduce(function (a, b) {
      return a + b;
    }, 0);

    let totalD = totalDicountP + totalDicountF;
    setTotalDiscountPrice(totalD);
    let actualPrice = totalPrice - totalD;
    console.log("total");
    setTotalPrice(actualPrice);
    console.log("dddddd", totalD, "aaaaaa", actualPrice);
    console.log("dddddd tttt", totalD, "aaaaaa", actualPrice);
    // let savedArray = JSON.parse(localStorage.getItem("names"));
    // if (savedArray.length > 0) {
    //   setTableData(savedArray);
    // }
    // localStorage.setItem("names", JSON.stringify(tableData));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    saveLocalTest();
  }, [tableData]);
  const saveLocalTest = () => {
    localStorage.setItem("testCart", JSON.stringify(tableData));
  };
  const getLocalTest = () => {
    if (localStorage.getItem("testCart") === null) {
      localStorage.setItem("testCart", JSON.stringify([]));
    } else {
      let localTest = JSON.parse(localStorage.getItem("testCart"));

      setTableData(localTest);

      if (localTest.length > 0) {
        setSearchField("a");
        setDiagnosticValue(localTest[0].service_provider.name);
        setDiagnosticID(localTest[0].service_provider.id);
        setParams({
          ...params,
          service_provider_id: localTest[0].service_provider.id,
        });
      }
    }
  };
  const handleChangeSample = (event) => {
    setHomeSample(event.target.checked);
  };

  const validationSchema = Yup.object({
    // diagnostic_id: Yup.number().required("This field is required"),
    police_station_id: Yup.number().required("This field is required"),
  });
  const initialValues = {
    area: "",
    police_station_id: "",
  };
  const handleDelete = (index) => {
    let copyArray = [...tableData];

    copyArray.splice(index, 1);
    console.log(copyArray);
    setTableData(copyArray);
  };
  const addToCart = (test, index) => {
    console.log("gggggg", test, index);
    if (tableData.some((el) => el.id === test.id)) {
      console.log("gggggg", index);
      let copyArrayCart = [...tableData];
      const filteredArray = copyArrayCart.filter((list) => {
        return list.id !== test.id;
      });
      console.log("aaaaaaaa", filteredArray);
      setTableData(filteredArray);
    } else if (!tableData.some((el) => el.id === test.id)) {
      setTableData([...tableData, test]);
    }
  };

  console.log("table data", tableData);

  const onSubmit = (values, { resetForm }) => {
    console.log("vvvvvvvvvvvvvvv", values, tableData.length, tableData);
    if (tableData.length > 0) {
      const testsArray = tableData.map((o) => {
        return {
          diagnostic_test_id: o.id,
        };
      });
      console.log("test aaarrrray", testsArray);

      let objToSend = {
        service_provider_id: diagnosticID,
        tests: testsArray,
        home_delivery: homeSample,
        // address: values.area,
        // police_station_id: values.police_station_id,
      };

      // console.log(service_provider_id, testsArray);
      //   PatientAPI.post("test-invoices", objToSend)
      //     .then((response) => {
      //       console.log(response);
      //       if (response.data.message) {
      //         setSuccessMessage(response.data.message);
      //         setOpen(true);
      //         setErrorOpen(false);
      //       }
      //     })
      //     .catch((error) => {
      //       console.log(error.response);
      //       if (error.response.data.message) {
      //         setErrorMessage(error.response.data.message);
      //         setErrorOpen(true);
      //         setOpen(false);
      //       }
      //       // if (error.response.data.data !== undefined) {
      //       //   setErrorOpen(true);
      //       //   setOpen(false);
      //       //   setSameMedicineErrors([
      //       //     ...sameMedicineErrors,
      //       //     error.response.data.data,
      //       //   ]);
      //       // }

      //       // if (error.response.data.errors !== undefined) {
      //       //   setBackendErrorMessage(error.response.data.errors);
      //       // }
      //     });
    }
  };
  useEffect(() => {
    if (availableSlot.includes(upFile) === false) {
      setAvailableSlot([...availableSlot, upFile]);
    }
  }, [upFile]);
  // useEffect(() => {
  //   saveLocalImage();
  // }, [availableSlot]);
  // const saveLocalImage = () => {
  //   localStorage.setItem("testImages", JSON.stringify(availableSlot));
  // };
  // console.log("uppp data", availableSlot);

  const uploadMultipleFiles = async (e) => {
    setAvailableSlot([]);
    const fileList = Array.from(e.target.files);
    console.log("filelist", fileList);

    if (checkMimeType(e)) {
      setFiles([...files, ...fileList]);
      const filePathsPromises = [];
      // console.log("files", files);
      for (let i = 0; i < fileList.length; i++) {
        let file = fileList[i];
        let bseFile = getBase64(file);

        const a = await bseFile;
        console.log("aaaaaaaa", a);

        let obj = {
          originalName: file.name,
          mimetype: file.type,
          preview: a,
        };
        filePathsPromises.push(obj);
      }
      // fileList.forEach(async (file) => {

      // });
      console.log("base 64, arrra", filePathsPromises);
      setPreviews([...filePathsPromises]);
      // const filePaths = await Promise.all(filePathsPromises);
      // const mappedFilesa = filePaths.map((base64File) => {
      //   console.log("base 64,", base64File);

      //   return {
      //     preview: base64File,
      //   };
      // });
      // setPreviews(mappedFilesa);
      // console.log("files mapped", mappedFilesa);
      // localStorage.setItem("blob", JSON.stringify(mappedFilesa));
      // return mappedFilesa;

      // for (let i = 0; i < fileList.length; i++) {
      //   getBase64(fileList[i]).then((base64) => {
      //     setUpFile(base64);
      //     // someArr.push(base64);
      //   });
      // }

      // const mappedFiles = fileList.map((file) => ({
      //   ...file,
      //   originalName: file.name,
      //   mimetype: file.type,
      //   preview: URL.createObjectURL(file),
      // }));
      // console.log("files mapped ppppp", mappedFiles);

      // if return true allow to setState
    }
  };
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  console.log("upfile", some);

  // const maxSelectFile = (event) => {
  //   let files = event.target.files; // create file object
  //   if (files.length > 5) {
  //     const msg = "Only 5 images can be uploaded at a time";
  //     event.target.value = null; // discard selected file
  //     alert(msg);
  //     return false;
  //   }
  //   return true;
  // };

  const checkMimeType = (event) => {
    //getting file object
    let files = event.target.files;
    //define message container
    let err = "";
    // list allow mime type
    //  "application/pdf"
    const types = ["image/png", "image/jpeg", "image/gif", "application/pdf"];
    // loop access array
    for (var x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every((type) => files[x].type !== type)) {
        // create error message and assign to container
        err += files[x].type + " is not a supported format\n";
      }
    }

    if (err !== "") {
      // if message not same old that mean has error
      event.target.value = null; // discard selected file
      alert(err);
      return false;
    }
    return true;
  };

  const upload = async () => {
    // localStorage.setItem("fileUpload", JSON.stringify("upload"));
    //
    // const fileArraysTOSend = [];
    // const pArr = [...previews];
    // for (let i = 0; i < pArr.length; i++) {
    //   const file = await getFile(pArr[i]);
    //   fileArraysTOSend.push(file);
    // }
    const upTestObj = {
      diagnosticID: diagnosticID,
      diagnosticValue: diagnosticValue,
      images: previews,
    };
    localStorage.setItem("upTestObj", JSON.stringify(upTestObj));
    history.push("/patient/login");
    // console.log("phototaaa push", fileArraysTOSend);
  };
  const getFile = async (paramObj) => {
    const { preview, originalName, mimetype } = paramObj;
    const blob = await fetch(preview).then((r) => r.blob());
    const newfileObj = new File([blob], `${originalName}`, { type: mimetype });
    return newfileObj;
  };

  return (
    <div>
      <Navbar2 />
      <Drawer
        className={classes.drawerCart}
        variant="persistent"
        anchor="right"
        open={openCart}
        classes={{
          paper: classes.drawerPaperCart,
        }}
      >
        <div className="shopptingCartButtona">
          <div className="shoppingCartButton">
            {/* <button>hello</button> */}
          </div>
          <div className="header-cart">
            <div className="row py-2">
              <div className="col-12">
                <div className="d-flex cart-flex">
                  <div className="px-2">
                    {" "}
                    <Icon fontSize="large">local_mall</Icon>{" "}
                  </div>
                  <div>
                    {" "}
                    <h3 className="">{tableData.length} Tests</h3>
                  </div>
                  <div className="px-4">
                    {" "}
                    <Button
                      onClick={() => handleDrawerOpenCart()}
                      variant="outlined"
                      color="primary"
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </div>
              {/* <div className="col-5"></div> */}
            </div>
          </div>
          <div className="cart-items-list">
            <table
              style={{ width: isTabMobile ? "100%" : "150px" }}
              className="table table-sm table-borderless"
            >
              <thead className=""></thead>
              <tbody>
                {tableData.map((row, index) => (
                  <tr key={index}>
                    <td>{row.name}</td>

                    <td>
                      {row !== undefined && (
                        <span>
                          {row.discount_type === "%" ? (
                            <span>
                              {Math.ceil(
                                row.price - (row.price / 100) * row.discount
                              )}
                            </span>
                          ) : (
                            <span>{Math.ceil(row.price - row.discount)}</span>
                          )}
                        </span>
                      )}
                    </td>
                    <td>
                      <IconButton
                        classes={{
                          root: classes.icnBtnRoot, // className name, e.g. `classes-nesting-root-x`
                        }}
                        onClick={() => handleDelete(index)}
                        color="secondary"
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </td>
                  </tr>
                ))}
                <tr>
                  <th scope="col">
                    <button
                      onClick={() => setTableData([])}
                      className="btn btn-sm btn-danger"
                    >
                      Clear Cart
                    </button>{" "}
                  </th>

                  <th scope="col">{Math.ceil(totalPrice)}</th>
                </tr>
                <tr>
                  <th scope="col">
                    <button
                      onClick={() => handleOpenDetails()}
                      className="btn btn-sm btn-primary"
                    >
                      View Details
                    </button>{" "}
                  </th>

                  <th scope="col"></th>
                  <th scope="col">
                    <Link
                      type="button"
                      to="/patient/register"
                      className="btn btn-sm btn-dark"
                    >
                      Checkout
                    </Link>{" "}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Drawer>

      <main
        className={clsx(classes.content, {
          [classes.contentShift2]: openCart,
        })}
      >
        <div onClick={() => handleDrawerOpenCart()} className="stickyHeader">
          <div className="text-center">
            <LocalMallOutlinedIcon fontSize="small" />
          </div>
          <h5 style={{ textAlign: "center", fontSize: "14px" }} className="">
            {tableData.length} Tests
          </h5>
          <h5 style={{ textAlign: "center", fontSize: "14px" }} className="">
            {totalPrice}
          </h5>
        </div>
        <div className="diagnostic-labs-home">
          <div className="container">
            <div style={{ width: "100%" }} className="test"></div>
            <div className="row ">
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <div className="test-details-image">
                      <img
                        className="test_package"
                        src={`${process.env.PUBLIC_URL}/assets/images/covid_test_package.png`}
                        alt="covid_test_package"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {diagnosticID !== "" && (
              <div className="row">
                <div className="col-md-12">
                  <div className="row"></div>
                </div>
              </div>
            )}

            <Dialog
              fullWidth
              maxWidth={`${isTabMobile ? "sm" : "md"}`}
              open={openDetails}
              onClose={handleCloseDetails}
            >
              <DialogTitle id="max-width-dialog-title">Report</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <table className="custom-responsive-table">
                    <thead className="">
                      <tr>
                        <th scope="col"> Name</th>
                        <th scope="col"> Home Sample</th>

                        <th scope="col">Regular Price</th>
                        <th scope="col">Discounted Price</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((row, index) => (
                        <tr key={index}>
                          <td data-column="Name">{row.name}</td>
                          <td data-column="Home Sample">
                            {" "}
                            {row.home_delivery ? "Yes" : "No"}
                          </td>
                          <td data-column="Regular Price">
                            {row !== undefined && (
                              <span>{Math.ceil(row.price)} </span>
                            )}
                          </td>
                          <td data-column="Discounted Price">
                            {row !== undefined && (
                              <span>
                                {row.discount_type === "%" ? (
                                  <span>
                                    {Math.ceil(
                                      row.price -
                                        (row.price / 100) * row.discount
                                    )}
                                  </span>
                                ) : (
                                  <span>
                                    {Math.ceil(row.price - row.discount)}
                                  </span>
                                )}
                              </span>
                            )}
                          </td>
                          <td data-column="Action">
                            <IconButton
                              onClick={() => handleDelete(index)}
                              color="secondary"
                              aria-label="delete"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </td>
                        </tr>
                      ))}
                      <tr>
                        {!isTabMobile && (
                          <React.Fragment>
                            <th scope="col"> </th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                          </React.Fragment>
                        )}

                        <th scope="col">Total Regular Price</th>
                        <th scope="col">{Math.ceil(totalRegularPrice)}</th>
                      </tr>
                      <tr>
                        {!isTabMobile && (
                          <React.Fragment>
                            <th scope="col"> </th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                          </React.Fragment>
                        )}
                        <th scope="col">Total Discount</th>
                        <th scope="col">{Math.ceil(totalDiscountPrice)}</th>
                      </tr>
                      <tr>
                        {!isTabMobile && (
                          <React.Fragment>
                            <th scope="col"> </th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                          </React.Fragment>
                        )}
                        <th scope="col">Total Payable</th>
                        <th scope="col">{Math.ceil(totalPrice)}</th>
                      </tr>
                    </tbody>
                  </table>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleCloseDetails()} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              fullWidth
              maxWidth={`${isTabMobile ? "sm" : "md"}`}
              open={openCheck}
              onClose={handleCloseCheck}
            >
              <DialogTitle id="max-width-dialog-title">Sumit</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({ setFieldValue, isValid, dirty }) => (
                      <Form>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1">
                            Police Station Area
                          </label>
                          <div className="col-sm-7">
                            <Autocomplete
                              freeSolo
                              id="disabledHelperText"
                              name="police_station_id"
                              options={policeStations}
                              onInputCapture={(event) => {
                                event.persist(); // As we are accessing the event in asynchronous way.
                                handleOnInputCaptureMed(event);
                              }}
                              // getOptionLabel={(option) => option.name}
                              getOptionLabel={(option) => {
                                // return option.name || "";
                                return (
                                  option.name +
                                    " " +
                                    "(" +
                                    option.district +
                                    ")" || ""
                                );

                                // let b = option.brand_name;
                                // let s = option.strength;
                                // let bd = b + " ( " + s + " " + " ) ";

                                // return b || "";
                              }}
                              onChange={(e, value) => {
                                if (value == null) {
                                  setFieldValue("police_station_id", "");
                                } else {
                                  console.log(value);
                                  console.log(value.id);
                                  setFieldValue("police_station_id", value.id);
                                }
                              }}
                              renderInput={(params) => (
                                <Field
                                  component={TextFieldSecond}
                                  {...params}
                                  onChange={(e, value) => {
                                    console.log(e.target.value);
                                    setFieldValue("police_station_id", 0);
                                  }}
                                  name="police_station_id"
                                  variant="outlined"
                                  fullWidth
                                />
                              )}
                            />
                            <ErrorMessage
                              name="police_station_id"
                              component="div"
                              className="error-message"
                            />
                          </div>
                        </div>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1">
                            Your Full Address
                          </label>
                          <div className="col-sm-7">
                            <Field
                              name="area"
                              type="text"
                              className="form-control"
                              placeholder="Your Address"
                            />
                            <ErrorMessage
                              name="area"
                              component="div"
                              className="error-message"
                            />
                          </div>
                        </div>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1"></label>
                          <div className="col-sm-7">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={homeSample}
                                  onChange={handleChangeSample}
                                />
                              }
                              label="Home Sample Collection"
                            />
                          </div>
                        </div>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1"></label>
                          <div className="col-sm-7">
                            {homeSample ? (
                              <span>
                                Total Price including Home Sample :{" "}
                                {totalPrice + 1000}
                              </span>
                            ) : (
                              <span>
                                Total Price without Home Sample : {totalPrice}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12 my-4">
                            <Collapse in={open}>
                              <Alert
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setOpen(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                {successMessage}
                              </Alert>
                            </Collapse>
                            <Collapse in={erroropen}>
                              <Alert
                                severity="error"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setErrorOpen(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                {errorMessage}
                              </Alert>
                            </Collapse>
                          </div>
                        </div>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1"></label>
                          <div className="col-sm-7 text-right">
                            <button
                              disabled={tableData.length === 0}
                              type="submit"
                              className="btn btn-lg btn-success"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleCloseCheck()} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              fullWidth
              maxWidth={`${isTabMobile ? "sm" : "md"}`}
              open={openFile}
              onClose={() => setOpenFile(false)}
            >
              <DialogTitle id="max-width-dialog-title">
                Upload Precription
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group multi-preview">
                        {(previews || []).map((url, key) => (
                          <img key={key} src={url.preview} alt="..." />
                        ))}
                      </div>

                      <div className="form-group">
                        <input
                          type="file"
                          className="form-control"
                          onChange={uploadMultipleFiles}
                          multiple
                        />
                        <p>You can upload your prescriptions here </p>
                      </div>
                      <button
                        onClick={() => upload()}
                        className="btn btn-sm btn-primary"
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenFile(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
