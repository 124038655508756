import React, { useState, useEffect } from "react";
// import { Button, Modal } from "react-bootstrap";
import Alert from "@material-ui/lab/Alert";
import { useParams } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import { PatientAPI } from "../../AxiosRequest/PatientAPI";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import axios from "axios";

import "./css/global.css";
import { isEmpty } from "lodash";

export default function DoctorSchedule(props) {
  let { doctorId } = useParams();
  const paramID = doctorId;
  const [doctorSchedule, setDoctorSchedule] = useState([]);
  const [doctorID, setDoctorID] = useState("");
  const [scheduleMessage, setScheduleMessage] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedDate, handleDateChange] = useState(new Date());
  const [selectedAppointment, setSelectedAppointment] = useState({});
  const [availableSlot, setAvailableSlot] = useState([]);
  const [bookedSlot, setBookedSlot] = useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [files, setFiles] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [checkedSlot, setCheckedSlot] = useState("");
  const [erroropen, setErrorOpen] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsgSameDay, setErrorMsgSameDay] = useState("");

  useEffect(() => {
    checkAvailableDate();
  }, [selectedDate]);
  useEffect(() => {
    if (moment(selectedDate).format("dddd") === selectedAppointment.day) {
      checkAvailableDate();
    }
  }, [selectedAppointment]);

  const checkAvailableDate = () => {
    PatientAPI.get(`schedules/${paramID}`, {
      params: {
        appointment_time: moment(selectedDate).format("YYYY-MM-DD"),
      },
    })
      .then((response) => {
        console.log("response", response.data);
        let da = response.data.patient_appointments;

        let newArr = da.map((arr, index) => {
          return moment(arr.appointment_time).format("HH:mm:ss");
        });

        setBookedSlot(newArr);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const uploadMultipleFiles = (e) => {
    const fileList = Array.from(e.target.files);
    if (checkMimeType(e)) {
      setFiles(fileList);
      const mappedFiles = fileList.map((file) => ({
        ...file,
        preview: URL.createObjectURL(file),
      }));

      setPreviews(mappedFiles);
      // if return true allow to setState
    }
  };

  // const maxSelectFile = (event) => {
  //   let files = event.target.files; // create file object
  //   if (files.length > 5) {
  //     const msg = "Only 5 images can be uploaded at a time";
  //     event.target.value = null; // discard selected file
  //     alert(msg);
  //     return false;
  //   }
  //   return true;
  // };
  console.log("booked slot", bookedSlot);
  const checkMimeType = (event) => {
    //getting file object
    let files = event.target.files;
    //define message container
    let err = "";
    // list allow mime type
    const types = ["image/png", "image/jpeg", "image/gif", "application/pdf"];
    // loop access array
    for (var x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every((type) => files[x].type !== type)) {
        // create error message and assign to container
        err += files[x].type + " is not a supported format\n";
      }
    }

    if (err !== "") {
      // if message not same old that mean has error
      event.target.value = null; // discard selected file
      alert(err);
      return false;
    }
    return true;
  };
  // const checkFileSize = (event) => {
  //   let files = event.target.files;
  //   let size = 150000;
  //   let err = "";
  //   for (var x = 0; x < files.length; x++) {
  //     if (files[x].size > size) {
  //       err += files[x].type + "is too large, please pick a smaller file\n";
  //     }
  //   }
  //   if (err !== "") {
  //     event.target.value = null;
  //     alert(err);
  //     return false;
  //   }

  //   return true;
  // };

  useEffect(() => {
    const doc = localStorage.getItem("docBooked")
      ? JSON.parse(localStorage.getItem("docBooked"))
      : "";

    console.log("doc", doc);

    if (doc !== "" || doc !== null || doc !== undefined) {
      localStorage.removeItem("docBooked");
    }
    GetDoctorSchedule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const GetDoctorSchedule = () => {
    // let url = `http://localhost:3001/purchase_order/${paramID}`;

    axios
      .get(`doctors/${paramID}/schedules`)
      .then((response) => {
        console.log("response", response.data.data);
        setDoctorSchedule(response.data.data.data);
        if (response.data.data.length === 0) {
          setScheduleMessage("No Schedule Available");
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const bookAppointment = (doc) => {
    console.log("dddddsoc", doc);

    let timeDiff = moment(doc.end_time, "HH:mm:ss").diff(
      moment(doc.start_time, "HH:mm:ss"),
      "minutes"
    );
    console.log("time difff", timeDiff / 20);

    var date = moment(doc.start_time, "h:mm:ss");

    // console.log(moment(count, "h:mm:ss").add(2, "minutes"));

    let i;
    // var number = 0;
    // var date;
    let bbb = moment(date, "h:mm:ss");
    let arr = [date.format("HH:mm:ss")];

    for (i = 0; i < timeDiff / 20 - 1; i++) {
      let added = moment(bbb, "h:mm:ss").add(20, "minutes");
      bbb = added;
      arr.push(moment(added).format("HH:mm:ss"));

      // setCount(bbb);
    }

    setAvailableSlot(arr);

    setSelectedAppointment(doc);
    setDoctorID(doc.id);
    setOpenModal(true);
  };

  const confirmAppointment = () => {
    console.log("file array", files);
    // if (files.length > 0) {
    //   data.append("reports", files);
    const formData = new FormData();
    for (var x = 0; x < files.length; x++) {
      formData.append("reports[]", files[x]);
    }
    // }
    let date = moment(selectedDate).format("YYYY-MM-DD");
    var time = moment(checkedSlot, "HH:mm:ss");

    // let time = moment(checkedSlot).format("HH:mm:ss")

    // formData.append("reports", files);
    formData.append(
      "appointment_time",
      date + " " + moment(time).format("HH:mm:ss")
    );
    // console.log("files", formData);

    PatientAPI.post(`appointment/${doctorID}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        console.log(response);
        setSuccessMsg("Your request has been submitted");
        setOpen(true);
        GetDoctorSchedule();

        setTimeout(() => {
          setOpenModal(false);
        }, 2500);
      })
      .catch((error) => {
        console.log(error.response.data.errors);
        setErrorOpen(true);

        if (error.response.data.errors !== undefined) {
          setErrorMsg(error.response.data.errors.appointment_time[0]);
          // setErrorMsgSameDay(
          //   error.response.data.errors.appointment[0].appointment_time
          // );
        }
      });
  };

  return (
    <div>
      <div className="container">
        <div className="row"></div>
        <div className="row">
          <div className="col-md-12 mt-4">
            {doctorSchedule.length !== 0 && (
              <div className=" table-responsive">
                <table className="table table-success">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Day</th>
                      <th scope="col">Start Time</th>
                      <th scope="col">End Time</th>
                      <th scope="col">Location</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {doctorSchedule.map((doc, i) => (
                      <tr key={i}>
                        <td>{doc.day_name}</td>
                        <td>
                          {moment(doc.start_time, "hh:mm A").format("hh:mm A")}
                        </td>
                        <td>
                          {moment(doc.end_time, "hh:mm A").format("hh:mm A")}
                        </td>
                        <td>{doc.location}</td>

                        <td>
                          <button
                            onClick={() => bookAppointment(doc)}
                            className="btn btn-sm btn-primary"
                          >
                            Book Appointment
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            <div className="text-center">
              <h1> {scheduleMessage}</h1>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Dialog
                open={openModal}
                onClose={() => setOpenModal(false)}
                fullWidth={true}
                maxWidth="sm"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Choose Date"}
                </DialogTitle>
                <DialogContent>
                  <DatePicker
                    autoOk
                    clearable
                    disablePast
                    format="dd/MM/yyyy"
                    value={selectedDate}
                    onChange={handleDateChange}
                  />
                  {moment(selectedDate).format("dddd") !==
                  selectedAppointment.day_name ? (
                    <p style={{ color: "red" }}>
                      Choose a date with {selectedAppointment.day_name}
                    </p>
                  ) : (
                    <div>
                      <p>You have choosen a valid date</p>
                      <div className="slot-area">
                        {availableSlot.map((slot, index) => (
                          <button
                            onClick={() => setCheckedSlot(slot)}
                            disabled={bookedSlot.includes(slot)}
                            // disabled={bookedSlot.some((el) =>
                            //   availableSlot.includes(el)
                            // )}
                            className={
                              bookedSlot.includes(slot)
                                ? "btn btn-sm btn-warning"
                                : "btn btn-sm btn-success"
                            }
                            key={index}
                          >
                            {moment(slot, "hh:mm A").format("hh:mm A")}
                          </button>
                        ))}
                      </div>
                      {checkedSlot !== "" && (
                        <p className="my-2">
                          Selected Slot :{" "}
                          <span style={{ fontWeight: "bold" }}></span>{" "}
                          {moment(checkedSlot, "hh:mm A").format("hh:mm A")}
                        </p>
                      )}
                    </div>
                  )}

                  <form>
                    <div className="form-group multi-preview">
                      {(previews || []).map((url, key) => (
                        <img key={key} src={url.preview} alt="..." />
                      ))}
                    </div>
                    <p>You can upload your report files below </p>

                    <div className="form-group">
                      <input
                        type="file"
                        className="form-control"
                        onChange={uploadMultipleFiles}
                        multiple
                      />
                    </div>
                    {/* <button
                      type="button"
                      className="btn btn-danger btn-block"
                      onClick={uploadFiles}
                    >
                      Upload
                    </button> */}
                  </form>

                  <div className="py-3">
                    <Collapse in={open}>
                      <Alert
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setOpen(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {successMsg}
                      </Alert>
                    </Collapse>
                  </div>
                  <div className="py-3">
                    <Collapse in={erroropen}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setErrorOpen(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {errorMsg} <br />
                        {moment(errorMsgSameDay).format(
                          "dddd Do MMMM, YYYY hh:mm A"
                        )}
                      </Alert>
                    </Collapse>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOpenModal(false)} color="primary">
                    Cancel
                  </Button>
                  <Button
                    disabled={
                      moment(selectedDate).format("dddd") !==
                        selectedAppointment.day_name || isEmpty(checkedSlot)
                    }
                    onClick={() => confirmAppointment()}
                    color="primary"
                  >
                    Confirm Appointment
                  </Button>
                </DialogActions>
              </Dialog>
            </MuiPickersUtilsProvider>
          </div>
        </div>
      </div>
    </div>
  );
}
