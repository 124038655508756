import Pagination from "@material-ui/lab/Pagination";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";

export default function DegreeList() {
  const [list, setList] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);

  const [params, setParams] = useState({
    per_page: 15,
    page: 1,
  });

  useEffect(() => {
    getList();
  }, [params]);

  const getList = () => {
    AdminAPI.get("degrees", { params: params })
      .then((response) => {
        setList(response.data.data.data);
        console.log(response.data.data);
        setTotalPage(response.data.data.last_page);
        setPage(response.data.data.current_page);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };

  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group my-2">
              <input
                placeholder="Search"
                className="form-control"
                type="text"
                onChange={(e) =>
                  handleColumnWiseSearch(e.target.value, "search")
                }
              />
            </div>
          </div>
          <div className="col-md-6 text-right">
            <Link
              to="/admin/degree/upload-csv"
              type="button"
              className="btn btn-md btn-secondary"
            >
              Upload CSV
            </Link>
            <Link
              to="/admin/degree/add"
              type="button"
              className="btn btn-md btn-primary mx-2"
            >
              Add Degree
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-4">
            <table className="custom-responsive-table">
              <thead>
                <tr>
                  <th scope="col">Name</th>

                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {list.map((list, i) => (
                  <tr key={i}>
                    <td data-column="Name">{list.name}</td>

                    <td data-column="Action">
                      <Link
                        to={`/admin/degree/view/${list.id}`}
                        type="button"
                        className="btn btn-sm btn-primary mb-1"
                      >
                        View
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}
