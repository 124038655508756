import React from "react";
import Footer from "../Shared/Footer";
import Navbar2 from "../Shared/Navbar2";
const PaymentRefundTerms = () => {
  return (
    <div>
      <Navbar2 />
      <div className="text-sections container">
        <h4>PAYMENT, REFUND AND CANCELATION & DELIVERY TERMS</h4>
        <ol type="I">
          <li>
            The Company shares much of the data, including Personal Information
            about you, with its parent, affiliates, subsidiaries, and joint
            ventures that are committed to serving your online needs and related
            services, throughout the world. To the extent that these entities
            have access to your Personal Information, they will treat it at
            least as protectively as they treat information they obtain from
            their other users. The Company's parent, affiliates, subsidiaries,
            and joint ventures follow privacy practices no less protective of
            all users than our practices described in this document, to the
            extent allowed by applicable law. The Company, its parent,
            affiliates, subsidiaries, its joint ventures, or any combination of
            such, will share some or all of your Personal Information with
            another business entity should we plan to, merge with, or be
            acquired by that business entity.
          </li>
          <li>
            It is understood and agreed by the User/Customer that the Services
            shall only commence after realization of money in the accounts of
            the Company incase online payment is being opted for by them.
          </li>
          <li>
            {" "}
            It is understood and agreed by the User/Customer that payment
            mechanisms may be governed by separate/additional terms of use
            prescribed by the Company.
          </li>
          <li>
            The Company reserves the right to refuse or cancel any order placed
            for a product/package that is listed at an incorrect price. This
            shall be regardless of whether the order has been confirmed and/or
            payment been levied via credit card. In the event the payment has
            been processed by the Company, the same shall be credited to your
            credit card account within 10 working days and duly notified to you
            by email/phone. Once the order has been placed and in case You wish
            to cancel/modify the same You may do so subject to
            cancellation/modification charges as prescribed.
          </li>
          <li>
            It is understood and agreed by the User/Customer that payment
            mechanisms may be governed by separate agreements between the third
            parties who provide facilities for such payment mechanism and the
            Company.
          </li>
          <li>
            It is understood and agreed by the User/Customer that in no event
            whatsoever, the Company shall take any responsibility or liability
            for malfunctioning or defect in any payment procedure. Payment of
            the Price shall be the sole responsibility of the User/Customer.
          </li>
          <li>
            {" "}
            The Company reserves the right to charge listing fees for certain
            listings, as well as transaction fees based on certain completed
            transactions using the Services through the Websites/Apps or any
            other fee. The Company further reserves the right to alter any and
            all fees from time to time, without notice.
          </li>
          <li>
            {" "}
            The User/Customer may be liable to pay all applicable charges, fees,
            duties, taxes, levies and assessments for availing the Services
            through the Websites/Apps. Further, the Company reserves the right o
            change the Fees upon its sole discretion without any prior notice to
            the Customers/Users.
          </li>
        </ol>

        <h4>REFUND AND CANCELLATION POLICY</h4>
        <ol type="I">
          <li>
            The E-Health Package is valid for the [7] days from the time and
            date of invoice generation. After 7 days, the Customer shall not be
            entitled to claim for Services and the company shall have the right
            to forfeit the fees already paid in such a case.
          </li>
          <li>
            Cancellation shall be acceptable only if the Customer informs the
            Company within 72 hours from the time of booking. 3% cancellation
            charges will be applicable on the total amount of invoice. The
            Customer can contact us through e-mail id, heal.holmes@gmail.com in
            case of cancellation and refund.
          </li>
          <li>
            The refund amount will be sent to the respective debit card/credit
            card/MFS/account from where payment was made and amount will not be
            refundable by any other mode.
          </li>
        </ol>
        <h4>DELIVERY</h4>
        <ol type="I">
          <li>
            The Company shall endeavor to release the test report/s to the
            Customer’s portal if so opted for by him/her within 12 [Twelve]
            hours after partner laboratories (where customer has chosen to
            perform the test) has released the report. Please allow the minimum
            time required for processing as specified for the test. Turn Around
            Time (TAT) for a test started from the sample received at the
            specific lab of selection and standard TAT is maintained by the
            partner laboratories to release the test report. If the customer has
            made partial payment against the desired services to be availed, in
            such circumstances, the company may not be able to release the test
            reports due administrative/technical reasons.
          </li>
          <li>
            The Customer/s may also collect the reports by hand during working
            hours on the date mentioned on the receipt from the designated
            collection center/branch of the Company’s Partnered laboratories
            where test/s were booked.
          </li>
          <li>
            The test reports may also be displayed on the Websites/Apps within
            the prescribed period.
          </li>
        </ol>
      </div>

      <Footer />
    </div>
  );
};

export default PaymentRefundTerms;
