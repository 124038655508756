import React, { useState, useEffect } from "react";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory, useParams, Link } from "react-router-dom";
import axios from "axios";
import { TextField } from "formik-material-ui";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import _ from "lodash";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "@material-ui/pickers";
import "../css/prescription.css";
const useStyles = makeStyles({
  root: {
    padding: "0px",
  },
  label: {
    textTransform: "capitalize",
  },
});

const FormikDatePicker = ({
  name,
  form: { setFieldValue },
  field: { value },
  ...rest
}) => {
  // console.log(rest);
  return (
    <DatePicker
      disablePast
      format="dd/MM/yyyy"
      views={["year", "month", "date"]}
      name={name}
      clearable
      autoOk
      inputVariant="outlined"
      // handle clearing outside => pass plain array if you are not controlling value outside
      mask={(value) =>
        value
          ? [/[0-3]/, /\d/, "/", /0|1/, /\d/, "/", /1|2/, /\d/, /\d/, /\d/]
          : []
      }
      onChange={(value) => {
        console.log("setting value to", value);
        console.log("setting value to", moment(value).format("DD/MM/YYYY"));

        setFieldValue("test_date", value);
      }}
      value={value}
      animateYearScrolling={false}
    />
  );
};

export default function AddTestPrecription() {
  const classes = useStyles();
  let history = useHistory();
  let { presId } = useParams();
  const [testList, setTestList] = useState([]);
  const paramID = parseInt(presId);
  const [testData, setTestData] = useState([]);
  const [testInputValue, setTestInputValue] = React.useState("");
  const [testInputValueId, setTestInputValueId] = React.useState("");
  const [testObj, setTestObj] = React.useState({});
  const [path, setPath] = useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openPhotoModal, setOpenPhotoModal] = React.useState(false);
  const [selectedImg, setSelectedImg] = React.useState({});
  const [showEndButton, setShowEndButton] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [testSearchTerm, setTestSearchTerm] = useState("");
  const [homeSample, setHomeSample] = React.useState(false);
  const [serviceID, setServiceID] = React.useState("");
  const [patientId, serPatientId] = useState(Number);
  const [totalRegularPrice, setTotalRegularPrice] = useState(Number);
  const [totalDiscountPrice, setTotalDiscountPrice] = useState(Number);
  const [totalPrice, setTotalPrice] = useState(Number);
  const [stationSearchTerm, setStationSearchTerm] = useState("");
  const [patientSearchTerm, setPatientSearchTerm] = useState("");
  const [policeStations, setPoliceStations] = useState([]);

  const [homeSampleCheck, setHomeSampleCheck] = React.useState(false);

  const [params, setParams] = useState({
    test: "",
    service_provider_id: "",
  });

  useEffect(() => {
    getTestDetails();
  }, []);

  const getTestDetails = () => {
    AdminAPI.get(`uploaded-prescriptions/${presId}`)
      .then((response) => {
        console.log("patient details", response.data);

        setParams({
          ...params,
          service_provider_id: response.data.data.service_provider_id,
        });
        setServiceID(response.data.data.service_provider_id);
        serPatientId(response.data.data.patient_id);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const handleOpenCheck = () => {
    const covidWithHomeSample = [];
    const covidWithOutHomeSample = [];
    const justHomeSample = [];
    const justNoHomeSample = [];
    for (let i = 0; i < testData.length; i++) {
      let obj = testData[i];
      if (obj.name.includes("COVID") && obj.home_delivery) {
        covidWithHomeSample.push(obj);
      } else if (obj.name.includes("COVID") && !obj.home_delivery) {
        covidWithOutHomeSample.push(obj);
      } else if (!obj.name.includes("COVID") && obj.home_delivery) {
        justHomeSample.push(obj);
      } else if (!obj.name.includes("COVID") && !obj.home_delivery) {
        justNoHomeSample.push(obj);
      }
    }

    const onlyCovid = [...covidWithHomeSample, ...covidWithOutHomeSample];
    if (
      onlyCovid.length > 0 &&
      justNoHomeSample.length === 0 &&
      justHomeSample.length === 0
    ) {
      console.log("No home collec");
      setHomeSampleCheck(false);
    }
    if (onlyCovid.length > 0 && justHomeSample.length > 0) {
      console.log("Home collection");
      setHomeSampleCheck(true);
    }
    if (onlyCovid.length === 0 && justHomeSample.length > 0) {
      console.log("Home collection");
      setHomeSampleCheck(true);
    }
    if (onlyCovid.length === 0 && justNoHomeSample.length > 0) {
      console.log("No home collection");
      setHomeSampleCheck(false);
    }
  };

  useEffect(() => {
    getTestList();
  }, [params]);
  const getTestList = (id) => {
    AdminAPI.get("diagnostic-tests", { params: params })
      .then((response) => {
        setTestList(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  useEffect(() => {
    const testsTotalPrice = testData.map((o) => parseInt(o.price));

    const testsTotalPercentageDiscount = testData
      .filter((o) => o.discount_type === "%")
      .map((o) => {
        return parseInt((o.price / 100) * o.discount);
      });
    // const testsTotalPercentageDiscount = testData
    //   .filter((o) => o.discount_type === "%")
    //   .map((o) => {
    //     return parseInt((o.price / 100) * o.discount);
    //   });
    const testsTotalFlatDiscount = testData
      .filter((o) => o.discount_type === "Flat")
      .map((o) => parseInt(o.discount));
    // const testsTotalFlatDiscount = testData.map((o) => {
    //   if () {
    //     return parseInt(o.price - o.discount);
    //   }
    // });
    console.log("total discoutn====>", testsTotalPercentageDiscount);
    console.log("total disaacout flat====>", testsTotalFlatDiscount);
    let totalPrice = testsTotalPrice.reduce(function (a, b) {
      return a + b;
    }, 0);
    setTotalRegularPrice(totalPrice);
    let totalDicountP = testsTotalPercentageDiscount.reduce(function (a, b) {
      return a + b;
    }, 0);
    let totalDicountF = testsTotalFlatDiscount.reduce(function (a, b) {
      return a + b;
    }, 0);

    let totalD = totalDicountP + totalDicountF;
    setTotalDiscountPrice(totalD);
    let actualPrice = totalPrice - totalDicountP;
    setTotalPrice(actualPrice);
    // let savedArray = JSON.parse(localStorage.getItem("names"));
    // if (savedArray.length > 0) {
    //   settestData(savedArray);
    // }
    // localStorage.setItem("names", JSON.stringify(testData));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testData]);

  // const getTestList = () => {

  //   axios
  //     .get(`tests?name=${testSearchTerm}`)
  //     .then((response) => {
  //       setTestList(response.data.data);
  //     })
  //     .catch((error) => {
  //       console.log(error.response);
  //     });
  // };
  useEffect(() => {
    GetPoliceStations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stationSearchTerm]);
  const GetPoliceStations = () => {
    // let url = `http://localhost:3001/purchase_order/${paramID}`;
    axios
      .get(`police-stations?name=${stationSearchTerm}`)
      .then((response) => {
        setPoliceStations(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const handleOnInputCapturePolice = _.debounce((event) => {
    setStationSearchTerm(event.target.value);
  }, 500);
  const handleChangeSample = (event) => {
    setHomeSample(event.target.checked);
  };

  const handleOnInputCaptureTest = _.debounce((event) => {
    setTestSearchTerm(event.target.value);
    setParams({ ...params, test: event.target.value });
  }, 500);

  const handleDeleteTest = (index) => {
    let copyArray = [...testData];

    copyArray.splice(index, 1);

    setTestData(copyArray);
  };

  const initialValues = {
    observation_details: "",
    test_id: "",
    test_name: "",
    medicine_id: "",
    name: "",
    rule: "",
    validity: "",
    timing: "",
    diagnosis: "",
    comment: "",
    police_station_id: "",
    test_date: new Date(),
  };

  const onSubmit = (values, { resetForm }) => {
    let testArray = testData.map((o) => {
      const obj = {
        diagnostic_test_id: o.id,
      };
      return obj;
    });

    AdminAPI.post(`test-invoices`, {
      patient_id: patientId,
      patient_uploaded_prescription_id: paramID,
      setvice_provider_id: serviceID,
      home_delivery: homeSample,
      tests: testArray,
      test_date: moment(values.test_date).format("YYYY-MM-DD"),
      police_station_id: values.police_station_id,
      address: values.address,
    })
      .then((response) => {
        console.log(response);
        setOpenDialog(true);
        setShowEndButton(true);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        alert(error.response.data.message);
      });
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={`${isMobile ? "sm" : "md"}`}
        open={openPhotoModal}
        onClose={() => setOpenPhotoModal(false)}
      >
        <DialogTitle id="max-width-dialog-title">Report</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <img
              width="100%"
              src={
                `${process.env.REACT_APP_PUBLIC_URL}` + path + "/" + selectedImg
              }
              alt="..."
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPhotoModal(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <div className="row">
        <div className="col-lg-6 col-sm-12">
          <div className="whole-prescription-area">
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
              {({
                setFieldValue,
                values,
                errors,
                isValid,
                dirty,
                resetForm,
                validateField,
              }) => (
                <Form>
                  <div className="form-group row">
                    <div className="col-md-12 col-lg-12 col-sm-12">
                      <div className="test-suggestion">
                        <div>
                          <div>
                            {/* <Field name={`friends[${index}].name`} /> */}
                            <div className="form-group row prescription">
                              <label className="col-md-3">Suggest Test</label>
                              <div className="col-sm-6">
                                <Autocomplete
                                  id="disabledHelperText"
                                  getOptionLabel={(option) =>
                                    option.name || ""
                                  }
                                  inputValue={testInputValue}
                                  onInputChange={(event, newInputValue) => {
                                    console.log(newInputValue);
                                    setTestInputValue(newInputValue);
                                  }}
                                  options={testList}
                                  onInputCapture={(event) => {
                                    event.persist(); // As we are accessing the event in asynchronous way.
                                    handleOnInputCaptureTest(event);
                                  }}
                                  onChange={(event, value) => {
                                    console.log("test value", value);

                                    if (value == null) {
                                      console.log("null");
                                    } else {
                                      setTestObj(value);
                                      setTestInputValue(value.name);
                                      setTestInputValueId(value.id);
                                      setTestSearchTerm(value.name);
                                      // setFieldValue(`test_id`, value.id);
                                      // setFieldValue(`test_name`, value.name);
                                    }
                                    console.log(values.test_id);
                                    console.log(values.test_name);
                                  }}
                                  renderInput={(params) => (
                                    <Field
                                      component={TextField}
                                      {...params}
                                      // onChange={(event) => {
                                      //   setFieldValue(
                                      //     `test_id`,
                                      //     event.target.value
                                      //   );
                                      //   setFieldValue(
                                      //     `test_name`,
                                      //     event.target.value
                                      //   );
                                      // }}
                                      name={`hekk`}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  name={`test_id`}
                                  component="div"
                                  className="error-message"
                                />
                              </div>
                              <label className="col-md-3">
                                <Button
                                  onClick={() => {
                                    console.log("id", testInputValueId);
                                    console.log("date", testData);
                                    if (testInputValueId !== "") {
                                      if (
                                        !testData.some(
                                          (el) => el.id === testInputValueId
                                        )
                                      ) {
                                        setTestData([...testData, testObj]);
                                        setTestInputValue("");
                                        setTestObj({});
                                        handleOpenCheck();
                                      }
                                    }
                                  }}
                                  variant="contained"
                                  color="primary"
                                >
                                  <CheckIcon />
                                </Button>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group row ">
                    <label className="col-sm-3 offset-sm-1">
                      Police Station Area
                    </label>
                    <div className="col-sm-7">
                      <Autocomplete
                        freeSolo
                        id="disabledHelperText"
                        name="police_station_id"
                        options={policeStations}
                        onInputCapture={(event) => {
                          event.persist(); // As we are accessing the event in asynchronous way.
                          handleOnInputCapturePolice(event);
                        }}
                        // getOptionLabel={(option) => option.name}
                        getOptionLabel={(option) => {
                          // return option.name || "";
                          return (
                            option.name + " " + "(" + option.district + ")" ||
                            ""
                          );
                        }}
                        onChange={(e, value) => {
                          if (value == null) {
                            setFieldValue("police_station_id", "");
                          } else {
                            console.log(value);
                            console.log(value.id);
                            setFieldValue("police_station_id", value.id);
                          }
                        }}
                        renderInput={(params) => (
                          <Field
                            component={TextField}
                            {...params}
                            onChange={(e, value) => {
                              console.log(e.target.value);
                              setFieldValue("police_station_id", 0);
                            }}
                            name="police_station_id"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                      <ErrorMessage
                        name="police_station_id"
                        component="div"
                        className="error-message"
                      />
                    </div>
                  </div>
                  <div className="form-group row ">
                    <label className="col-sm-3 offset-sm-1">
                      Your Full Address
                    </label>
                    <div className="col-sm-7">
                      <Field
                        name="address"
                        type="text"
                        className="form-control"
                        placeholder="Your Address"
                      />
                      <ErrorMessage
                        name="address"
                        component="div"
                        className="error-message"
                      />
                    </div>
                  </div>

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div className="form-group row ">
                      <label className="col-sm-3 offset-sm-1">Test Date</label>
                      <div className="col-sm-7">
                        <Field component={FormikDatePicker} name="test_date" />
                        <ErrorMessage
                          name="test_date"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>
                  </MuiPickersUtilsProvider>
                  {/* <div className="form-group row ">
                    <label className="col-sm-3 offset-sm-1"></label>
                    <div className="col-sm-7">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={homeSample}
                            onChange={handleChangeSample}
                          />
                        }
                        label="Home Sample"
                      />
                    </div>
                  </div> */}
                  {homeSampleCheck && (
                    <div>
                      <div className="form-group row ">
                        <label className="col-sm-3 offset-sm-1"></label>
                        <div className="col-sm-7">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={homeSample}
                                onChange={handleChangeSample}
                              />
                            }
                            label="Home Sample Collection"
                          />
                        </div>
                      </div>
                      <div className="form-group row ">
                        <label className="col-sm-3 offset-sm-1"></label>
                        <div className="col-sm-7">
                          {}
                          {homeSample ? (
                            <span>
                              Total Price including Home Sample :{" "}
                              {totalPrice + 1000}
                            </span>
                          ) : (
                            <span>
                              Total Price without Home Sample : {totalPrice}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {!homeSampleCheck && (
                    <div className="form-group row ">
                      <label className="col-sm-3 offset-sm-1"></label>
                      <div className="col-sm-7">
                        <span>Total Price : {totalPrice}</span>
                      </div>
                    </div>
                  )}
                  <div className="form-group row">
                    <div className="col-md-12">
                      <button
                        disabled={testData.length === 0}
                        className="btn btn-md btn-primary"
                        type="submit"
                      >
                        Create Invoice
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="col-lg-6 col-sm-12">
          <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div>
                <div className="row">
                  <div
                    style={{
                      borderRight: "1px solid black",
                    }}
                    className="col-md-1"
                  >
                    <div style={{ height: "auto" }}></div>
                  </div>
                  <div style={{ position: "relative" }} className="col-md-9">
                    <div style={{ minHeight: "400px" }}>
                      {testData.length > 0 && (
                        <table className="table table-borderless table-sm test-list">
                          <thead>
                            <tr>
                              <th scope="col">Test Name</th>
                              <th scope="col">Test Price</th>
                              <th scope="col">Discounted Price</th>

                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {testData.map((row, index) => (
                              <tr key={index}>
                                <td>{row.name}</td>
                                <td>{Math.ceil(row.price)}</td>

                                <td>
                                  {row !== undefined && (
                                    <span>
                                      {row.discount_type === "%" ? (
                                        <span>
                                          {Math.ceil(
                                            row.price -
                                              (row.price / 100) * row.discount
                                          )}
                                        </span>
                                      ) : (
                                        <span>
                                          {Math.ceil(row.price - row.discount)}
                                        </span>
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <IconButton
                                    classes={{
                                      root: classes.root,
                                    }}
                                    aria-label="delete"
                                    onClick={() => handleDeleteTest(index)}
                                    color="secondary"
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <th colSpan={2}>
                                <button
                                  onClick={() => setTestData([])}
                                  className="btn btn-sm btn-danger"
                                >
                                  Clear All
                                </button>{" "}
                              </th>
                            </tr>
                            <tr>
                              <th scope="col"></th>
                              <th scope="col"></th>

                              <th scope="col">Total Regular Price</th>
                              <th scope="col">
                                {Math.ceil(totalRegularPrice)}
                              </th>
                            </tr>
                            <tr>
                              <th scope="col"></th>
                              <th scope="col"></th>

                              <th scope="col">Total Discount</th>
                              <th scope="col">
                                {Math.ceil(totalDiscountPrice)}
                              </th>
                            </tr>
                            <tr>
                              <th scope="col"></th>
                              <th scope="col"></th>

                              <th scope="col">Total Payable</th>
                              <th scope="col">{Math.ceil(totalPrice)}</th>
                            </tr>
                          </tbody>
                        </table>
                      )}

                      <div className="">
                        <div
                          style={{
                            position: "absolute",
                            bottom: "0",
                            left: "0",
                          }}
                        >
                          <div style={{ fontSize: "8px", width: "98%" }}>
                            This electronic prescription has been generated
                            through telemedicine by the agreement of both
                            respective physician and patient and no signature is
                            required
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      width: "75%",
                      float: "left",
                      fontSize: "12px",
                    }}
                  ></div>
                </div>
                <div style={{ width: "100%" }}>
                  <hr />
                </div>
                <div style={{ width: "100%", fontSize: "8px" }}>
                  <p>
                    Heal-Holmes provides the platform for telemedicine and
                    diagnostic facilities for patient and physicians.
                  </p>
                  <p>
                    Heal-Holmes does not diagnose any disease or perform any
                    test by it self.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Success"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have successfully added your prescription
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
