import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { useHistory } from "react-router-dom";
import axios from "axios";
import "./css/home.css";
import Navbar2 from "../Shared/Navbar2";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Footer from "../Shared/Footer";
export default function DoctorListHome() {
  const theme = useTheme();
  const [photoPath, setPhotoPath] = useState({});
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  let history = useHistory();

  const [doctorList, setDoctorList] = useState([]);

  const routeToDocDetails = (id) => {
    history.push(`/doctor/${id}/schedules`);
  };

  useEffect(() => {
    getDocDetails();
  }, []);

  const getDocDetails = () => {
    axios
      .get(`doctors`)
      .then((response) => {
        let filteredArray = [...response.data.data.data];
        let newArray = filteredArray.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        let idsToRemove = [1, 8];
        setPhotoPath(response.data.path);
        const arra = newArray.filter((item) => !idsToRemove.includes(item.id));
        setDoctorList(arra);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  return (
    <div>
      <Navbar2 />
      <div className="doctors-departmenta my-3">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="doc-list-home">
                {/* {doctorList.slice(0, 10).map((doc, index) => (
                  
                   <div key={index}>
                    <div className="card-area-middle">
                      <div className="card">
                        <div className="card-doc-image">
                          {doc.id === 6 && (
                            <img
                              className="card-img-top-d"
                              src={`${process.env.REACT_APP_PUBLIC_URL}${photoPath}/${doc.photo}`}
                              alt="Modhurima Saha"
                            />
                          )}
                          {doc.id === 7 && (
                            <img
                              className="card-img-top-d"
                              src={`${process.env.PUBLIC_URL}/assets/images/Dr.-Debashish.png`}
                              alt="Dr Debashish"
                            />
                          )}

                          {doc.id !== 6 && doc.id !== 7 && (
                            <img
                              className="card-img-top-d"
                              src={`${process.env.REACT_APP_PUBLIC_URL}${photoPath}/${doc.photo}`}
                              alt="Dr "
                            />
                          )}
                        </div>

                        <div className="card-body">
                          <h5
                            style={{ textTransform: "uppercase" }}
                            className="card-title doc-dep-d-list"
                          >
                            {doc.department}
                          </h5>
                          <h4>{doc.name}</h4>
                          {!isMobile ? (
                            <React.Fragment>
                              <p>
                                {doc.doctor_degrees.length === 0 && (
                                  <span style={{ visibility: "hidden" }}>
                                    Some
                                  </span>
                                )}
                                {doc.doctor_degrees.map((degree, i) => (
                                  <span key={i}>
                                    {degree.degree}

                                    {i === doc.doctor_degrees.length - 1
                                      ? ""
                                      : ","}
                                  </span>
                                ))}
                              </p>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <p>
                                {doc.doctor_degrees.map((degree, i) => (
                                  <span key={i}>
                                    {degree.degree}

                                    {i === doc.doctor_degrees.length - 1
                                      ? ""
                                      : ","}
                                  </span>
                                ))}
                              </p>
                            </React.Fragment>
                          )}

                          <div className="doc-bottom-area">
                            <p>{doc.designation}</p>
                            <p>{doc.department}</p>
                            <p>{doc.institution}</p>
                          </div>

                          <div className="button-area">
                            <button
                              onClick={() => routeToDocDetails(doc.id)}
                              className="btn btn-home-default"
                            >
                              Make Appointment
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> 
                  
                ))} */}
                {doctorList.slice(0, 10).map((doc, index) => (
                  <div onClick={() => routeToDocDetails(doc.id)} key={index}>
                    <div
                      style={{ cursor: "pointer" }}
                      class="container_doc_box"
                    >
                      <div class="doc_details_box">
                        <h3>{doc.name} </h3>
                        <div className="doc_dts">
                          <p className="degree-area2">
                            {doc.doctor_degrees.length === 0 && (
                              <span style={{ visibility: "hidden" }}>Some</span>
                            )}
                            {doc.doctor_degrees.map((degree, i) => (
                              <span className="doc-degrees-title" key={i}>
                                {degree.degree}

                                {i === doc.doctor_degrees.length - 1 ? "" : ","}
                              </span>
                            ))}
                          </p>
                        </div>
                      </div>
                      {doc.id === 6 && (
                        <img
                          src={`${process.env.REACT_APP_PUBLIC_URL}${photoPath}/${doc.photo}`}
                          width="100%"
                          className="doc-list-s-image"
                          alt={doc.name}
                        />
                      )}
                      {doc.id !== 6 && (
                        <img
                          alt={doc.name}
                          width="100%"
                          className="doc-list-s-image"
                          src={`${process.env.REACT_APP_PUBLIC_URL}${photoPath}/${doc.photo}`}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
