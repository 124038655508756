import React from "react";
import { useParams } from "react-router-dom";
import Navbar2 from "../../Shared/Navbar2";
import Slider from "react-slick";
import { useHistory } from "react-router-dom";

import ASA from "./ASA";
import IUB from "./IUB";
import NSU from "./NSU";

var settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const Events = () => {
  const { org } = useParams();
  let history = useHistory();
  const routeToAvailableTests = (route) => {
    history.push(`/events/${route}`);
  };
  return (
    <div>
      <Navbar2 />
      {!org && <ASA />}
      {/* <div class="row">
          <div class="col-md-12">
            <div class="doctors-slider">
              <Slider {...settings}>
                <div onClick={() => routeToAvailableTests("nsu")}>
                  <div class="team-img_block blue">
                    <div class="team-img-socila-block">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/Diabetes-Day-banner.png`}
                        class="img-fluid"
                        alt="North South University"
                      />
                    </div>
                  </div>
                  <h4 className=" text-center">North South University</h4>
                </div>

                <div onClick={() => routeToAvailableTests("asa")}>
                  <div class="team-img_block blue">
                    <div class="team-img-socila-block">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/Diabetes-Day-banner-ASA.png`}
                        class="img-fluid"
                        alt="ASA University"
                      />
                    </div>
                  </div>
                  <h4 className=" text-center">ASA University</h4>
                </div>
                <div onClick={() => routeToAvailableTests("iub")}>
                  <div class="team-img_block blue">
                    <div class="team-img-socila-block">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/Diabetes-Day-banner-IUB.png`}
                        class="img-fluid"
                        alt="Independent University"
                      />
                    </div>
                  </div>
                  <h4 className=" text-center">Independent University</h4>
                </div>
              </Slider>
            </div>
          </div>
        </div> */}

      {/* {org && org === "nsu" && <NSU />}
      {org && org === "iub" && <IUB />} */}
      {org && org === "asa" && <ASA />}
    </div>
  );
};

export default Events;
