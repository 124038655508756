import axios from "axios";
import history from "../history";
const AgentAPI = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/agent`,
  // baseURL: "https://beta.heal-holmes.com/api/api/agent",
});

AgentAPI.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const agentToken = JSON.parse(localStorage.getItem("agentToken"));

      if (agentToken) {
        config.headers.Authorization = `Bearer ${agentToken}`;
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);
AgentAPI.interceptors.response.use(
  (response) => {
    console.log(response.status);
    // console.log(response.status);
    // Edit response config
    return response;
  },
  (error) => {
    if (error.response !== undefined) {
      if (error.response.status === 401) {
        localStorage.removeItem("agentToken");
        history.push("/agent/login");
      }
    }

    console.log(error);
    return Promise.reject(error);
  }
);

export { AgentAPI };
