import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";
import "./css/home.css";
import Navbar2 from "../Shared/Navbar2";
import MessengerCustomerChat from "react-messenger-customer-chat";
// import { ButtonGroup, Button } from "react-bootstrap";
// import { ButtonDropdown } from "react-bootstrap";
// import DropdownMenu from "react-bootstrap";
// import DropdownToggle from "react-bootstrap";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Footer from "../Shared/Footer";
import { Box } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: "0px",
    top: "-5px",
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {/* <Typography variant="h6">{children}</Typography> */}

      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
var settingsLab = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  autoplay: true,

  autoplaySpeed: 2500,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
export default function Home2() {
  let history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [doctorList, setDoctorList] = useState([]);
  const [photoPath, setPhotoPath] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openBanner, setOpenBanner] = React.useState(false);
  const [count, setCount] = useState(1);
  const [countM, setCountM] = useState(1);
  const [countS, setCountS] = useState(1);
  const [countXS, setCountXS] = useState(1);
  const handleClickOpenBanner = () => {
    setOpenBanner(true);
  };
  const handleCloseBanner = () => {
    setOpenBanner(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const routeToPatient = () => {
    history.push("/patient/login");
  };
  const routeToDocDetails = (id) => {
    history.push(`/doctor/${id}/schedules`);
  };
  const routeToAvailableDoctors = () => {
    history.push(`/available-doctors`);
  };
  const routeToAvailableTests = (route) => {
    if (route !== "Default") {
      history.push(`/available-tests/${route}`);
    } else {
      history.push(`/available-tests`);
    }
  };
  const setTextLimit = (text, size) => {
    if (!text) return "";
    text = text.toString();

    if (text.length <= size) {
      return text;
    }
    return text.substr(0, size) + "...";
  };

  useEffect(() => {
    getDocDetails();
    // handleClickOpenBanner();
  }, []);

  const getDocDetails = () => {
    axios
      .get(`doctors`)
      .then((response) => {
        let filteredArray = [...response.data.data.data];
        setPhotoPath(response.data.path);
        let newArray = filteredArray.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });

        let idsToRemove = [1, 8];
        const arra = newArray.filter((item) => !idsToRemove.includes(item.id));
        let dataC = arra.length;
        if (dataC === 1) {
          setCount(1);
          setCountM(1);
          setCountS(1);
          setCountXS(1);
        } else if (dataC === 2) {
          setCount(2);
          setCountM(2);
          setCountS(2);
          setCountXS(2);
        } else if (dataC === 3) {
          setCount(3);
          setCountM(3);
          setCountS(2);
          setCountXS(2);
        } else if (dataC >= 4) {
          setCount(4);
          setCountM(3);
          setCountS(2);
          setCountXS(2);
        }
        setDoctorList(arra);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const comingSoon = () => {
    alert("Coming Soon!");
  };
  const settingsDocs = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: count,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToScroll: count,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: countM,
          slidesToScroll: countM,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: countS,
          slidesToScroll: countS,
          initialSlide: countS,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: countXS,
          slidesToScroll: countXS,
        },
      },
    ],
  };
  return (
    <div style={{ width: "100%" }}>
      <Navbar2 showSearchIcon />

      <Dialog
        onClose={handleCloseBanner}
        aria-labelledby="customized-dialog-title"
        open={openBanner}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleCloseBanner}>
          <span>H</span>
        </DialogTitle>
        <DialogContent dividers>
          <img
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.healholmes"
              )
            }
            width="100%"
            src={`${process.env.REACT_APP_PUBLIC_URL}` + "files/covid-update"}
            alt="announcement banner"
            style={{ cursor: "pointer" }}
          />
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Save changes
          </Button>
        </DialogActions> */}
      </Dialog>
      <Slider
        settings={{
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
        }}
      >
        <div>
          <div
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "/assets/images/banner1.jpeg"
              })`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
            className="slider-banner-new"
          >
            {" "}
            <p className="your-loved-ones-will">
              Your Loved Ones Will
              <br />
              be In Safe Hands
            </p>
            <p className="lorem-ipsum-or">
              Your health and safety are our priority. Entrust your loved ones
              to our capable hands, where compassion meets expertise. With
              personalized care and unwavering dedication, we ensure your safety
              and comfort. Trust us to care for those who matter most to you.
            </p>
            {/* <Link
              to="/admin/login"
              type="button"
              className="btn btn-lg btn-custom-nav-banner"
            >
              Read More
            </Link> */}
          </div>
        </div>
      </Slider>
      {/* <div class="container">
        <div class="banner-slider">
          <div class="banner">
            <div class="row">
              <div class="col-12 col-md-6 col-lg-5 d-flex align-items-center">
                <div class="main-title">
                
                  <h1>You are in control of your health.</h1>
                  <p>
                    Today’s e-healthcare platform is changing and growing faster
                    than ever. As technology advances at exponential levels,
                    patients want to reduce cost, time, and hassles -free
                    immediate care for minor but urgent conditions when need it.
                    Heal Holmes Limited is offering all sorts of diagnostics
                    tests including general pathology, molecular,
                    histopathology, and radiology from partner labs and
                    hospitals at a reduced cost which up to 30%. You can get the
                    best healthcare services from renowned labs throughout Dhaka
                    city. Save your valuable time and expenditure up to 30%
                    while taking healthcare services from your desired lab you
                    preferred.
                  </p>
                  <Link class="btn btn-primary" to={`/available-tests`}>
                    Book Now
                  </Link>

                  <a
                    target="_blank"
                    href="https://www.youtube.com/watch?v=4Wi27hH5YC4"
                    class="play-btn popup-youtube"
                  >
                    <i class="fa fa-play"></i>
                  </a>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-7 d-flex align-items-end">
                <div class="anim-container flex-fill">
                  <svg
                    class="circle-svg"
                    width="100%"
                    height="100%"
                    viewBox="0 0 754 733"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      class="big-circle"
                      opacity="0.071"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M377 29C563.12 29 714 179.879 714 366C714 552.119 563.12 702.999 377 702.999C190.88 702.999 40 552.119 40 366C40 179.879 190.88 29 377 29Z"
                      fill="#4D72D0"
                    />
                    <path
                      class="small-circle"
                      opacity="0.051"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M376.471 120.995C512.043 120.995 621.947 230.898 621.947 366.471C621.947 502.043 512.043 611.946 376.471 611.946C240.898 611.946 130.995 502.043 130.995 366.471C130.995 230.898 240.898 120.995 376.471 120.995Z"
                      fill="#4D72D0"
                    />
                  </svg>

                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/hero-doctor-1.png`}
                    class="img-fluid animated-hero"
                    alt="hero"
                  />

                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="container">
        <div className="services-icons">
          <div className="online-result">
            <div className="div">
              <img
                className="testing"
                alt="Testing"
                src={`${process.env.PUBLIC_URL}/assets/images/onlineresult.png`}
              />
            </div>
            <div className="text-wrapper"> Online Result</div>
          </div>
          <div className="element-btn">
            <div className="div">
              <img
                className="pngitem"
                alt="Pngitem"
                src={`${process.env.PUBLIC_URL}/assets/images/247available.png`}
              />
            </div>
            <div className="text-wrapper-2">24/7 Availiablity</div>
          </div>
          <div className="specialized-service">
            <div className="div">
              <img
                className="first-aid-kit"
                alt="First aid kit"
                src={`${process.env.PUBLIC_URL}/assets/images/specialized.png`}
              />
            </div>
            <div className="text-wrapper-3">Specialized Service</div>
          </div>
          <div className="health-care-btn">
            <div className="div" />
            <div className="text-wrapper-4">Health Care</div>
            <img
              className="medical-team"
              alt="Medical team"
              src={`${process.env.PUBLIC_URL}/assets/images/healthcare.png`}
            />
          </div>
        </div>
      </div>
      {/* <div className="container"> */}
      <div className="short-story-about">
        <div className="short-story-of-hhs container">
          <p className="text-wrapper">Short Story About Heal Holmes</p>
          <p className="div">
            Heal Holmes Limited, is a complete e-healthcare organization
            involved in all kinds of Diagnostic services, home sample
            collection, Online- Consultation, Corporate healthcare services, and
            Medicine services from top pharmaceutical companies. Heal Holmes
            Limited has been offering all sorts of diagnostics tests including
            general pathology, molecular, histopathology, and radiology from
            partner labs and hospitals at a reduced cost for our valued Patients
            for the last two years. We have already given our footprint in the
            global arena for the sophisticated diagnostic support named NGS from
            a renowned organization, in India. In addition, in our platform, a
            qualitative no. of specialist Dr. has been already onboarded for
            online consultation
          </p>
        </div>
      </div>
      <div className="why-choose-container">
        <div className="row no-gutters">
          <div className="col-md-6">
            {" "}
            <div className="video">
              {/* <img
              className="vecteezy-asian"
              alt="Vecteezy asian"
              src={`${process.env.PUBLIC_URL}/assets/images/video_back.jpeg`}
            /> */}
              <img
                className="vector"
                alt="Vector"
                src={`${process.env.PUBLIC_URL}/assets/images/vector-play.svg`}
              />
            </div>
          </div>
          <div className="col-md-6">
            {" "}
            <div className="frame">
              <div className="text-wrapper">Why Choose Heal Holmes?</div>
            </div>
            <div class="reduce-costlab-btn" style={{ marginBottom: "64px" }}>
              <div>
                <Box className="Hooool">
                  <img
                    className="medical-team"
                    alt="Medical team"
                    src={`${process.env.PUBLIC_URL}/assets/images/check.png`}
                    width={55}
                  />
                  <Box className="REDUCED-COST-Save-up-to-30">
                    <p class="text-style-1">REDUCED COST</p>
                    <p class="text-style-2">Save up to 30%</p>
                  </Box>
                </Box>
              </div>
              <div>
                <Box className="Hooool" display={"flex"}>
                  <img
                    className="medical-team"
                    alt="Medical team"
                    src={`${process.env.PUBLIC_URL}/assets/images/check.png`}
                    width={55}
                  />
                  <Box className="REDUCED-COST-Save-up-to-30">
                    <p class="text-style-1">DESIRED LAB</p>
                    <p class="text-style-2">Multiple Labs</p>
                  </Box>
                </Box>
              </div>
            </div>
            <div class="reduce-costlab-btn">
              <div>
                <Box className="Hooool">
                  <img
                    className="medical-team"
                    alt="Medical team"
                    src={`${process.env.PUBLIC_URL}/assets/images/check.png`}
                    width={55}
                  />
                  <Box className="REDUCED-COST-Save-up-to-30">
                    <p class="text-style-1">HOME COLLECTION</p>
                    <p class="text-style-2">Home comfort service</p>
                  </Box>
                </Box>
              </div>
              <div>
                <Box className="Hooool" display={"flex"}>
                  <img
                    className="medical-team"
                    alt="Medical team"
                    src={`${process.env.PUBLIC_URL}/assets/images/check.png`}
                    width={55}
                  />
                  <Box className="REDUCED-COST-Save-up-to-30">
                    <p class="text-style-1">TEST RANGE</p>
                    <p class="text-style-2">1500+ Test</p>
                  </Box>
                </Box>
              </div>
            </div>
          </div>
        </div>
        {/* <div style={{ width: "50%" }}>
     
        </div>
        <div style={{ width: "50%" }}>

        </div> */}
      </div>
      {/* </div> */}

      <section class="spacea lighta mt-5 services_bg">
        <div class="container container-custom">
          <div class="row">
            <div class="col-md-12">
              <div class="heading-style1">
                <h2> Quality Services for You</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="service-slider">
                <div class="service-block">
                  <img
                    alt="#"
                    height={70}
                    src={`${process.env.PUBLIC_URL}/assets/images/test.png`}
                  />
                  <div className="services-block">
                    <h3 className="service-title">Diagnostic Test</h3>
                    <p className="desc">
                      In easy three steps book diagnostic tests from your
                      desired lab. <br />
                      Step 1). Choose your lab. <br />
                      Step 2). Select Diagnostic tests/upload prescriptions.{" "}
                      <br />
                      Step 3). Checkout by providing details.
                    </p>
                  </div>
                  <div className="middle-last">
                    <Link class="btn btn-dark" to={`/available-tests`}>
                      Book Now
                    </Link>
                  </div>

                  <div class="service-bg-icon">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/services-bg3.png`}
                      className="img-fluid"
                      alt="#"
                    />
                  </div>
                </div>
                <div class="service-block">
                  <img
                    height={70}
                    alt="#"
                    src={`${process.env.PUBLIC_URL}/assets/images/doc_1.png`}
                  />
                  <div className="services-block">
                    <h3 className="service-title">Doctor Consulation</h3>
                    <p className="desc">
                      Take online or chamber consultation from our doctors.
                      Select Doctor & Schedule and book appointment.
                    </p>
                  </div>
                  <div className="middle-last">
                    <Link class="btn btn-dark" to={`/available-doctors`}>
                      Available Doctors
                    </Link>
                  </div>
                  <div class="service-bg-icon">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/services-bg3.png`}
                      className="img-fluid"
                      alt="#"
                    />
                  </div>
                </div>
                <div class="service-block">
                  <img
                    height={70}
                    alt="#"
                    src={`${process.env.PUBLIC_URL}/assets/images/car.png`}
                  />
                  <div className="services-block">
                    <h3 className="service-title">Medicine Delivery</h3>
                    <p className="desc">
                      We are developing services to deliver Medicine at your
                      door step from the top Pharmaceuticals company.
                    </p>
                  </div>
                  <div className="middle-last">
                    <button
                      onClick={() => {
                        window.open("https://hhlmedicine.com", "_blank");
                      }}
                      className="btn btn-dark"
                    >
                      Order Now
                    </button>
                  </div>

                  <div class="service-bg-icon">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/services-bg3.png`}
                      className="img-fluid"
                      alt="#"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section class="space why-choose-block">
        <div class="container container-custom">
          <div class="row">
            <div class="col-md-12 col-lg-5">
              <h2>
                Why Choose
                <br /> Heal Holmes?
              </h2>
              <hr />
              <div class="row">
                <div class="col-md-6">
                  <div class="why-choose_list why-choose_list-br">
                    <i class="fa fa-check"></i>
                    <div class="why-choose_list-content">
                      <h5>REDUCED COST</h5>
                      <span>Save up to 30% </span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="why-choose_list why-choose_list-br">
                    <i class="fa fa-check"></i>
                    <div class="why-choose_list-content">
                      <h5>DESIRED LAB</h5>
                      <span>Multiple Labs</span>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-6">
                  <div class="why-choose_list why-choose_list-br">
                    <i class="fa fa-check"></i>
                    <div class="why-choose_list-content">
                      <h5>HOME COLLECTION</h5>
                      <span>Home comfort service</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="why-choose_list why-choose_list-br">
                    <i class="fa fa-check"></i>
                    <div class="why-choose_list-content">
                      <h5>TEST RANGE </h5>
                      <span>1500+ Test</span>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-12">
                 
                </div>
              </div>
            </div>
            <div class="col-md-12 col-lg-6 offset-lg-1">
              <div class="why-choose_right">
                <h2>
                  Emergency?
                  <br /> Contact Us.
                </h2>
                <p>
                  We Care for Your Health. If You Get into Any Trouble,
                  <br /> HHL Always Serves You to Fulfill Your Desired
                  Healthcare Service.
                  <br /> Our Hotline is Always Open for You. Feel Free to
                  Contact Us.
                </p>
                <div class="why-choose_list">
                  <div class="choose-icon">
                    <i class="fa fa-phone"></i>
                  </div>

                  <div class="why-choose_list-content">
                    <span>Call us now</span>
                    <h3>09642919191</h3>
                  </div>
                </div>
                <div class="why-choose_list">
                  <div class="choose-icon">
                    <i class="fa fa-envelope"></i>
                  </div>
                  <div class="why-choose_list-content">
                    <span>Mail us</span>
                    <h3>heal.holmes@gmail.com</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section class="space">
        <div class="container container-custom">
          <h2 className="Heal-Holmes-at-a-Glance">Heal Holmes at a Glance </h2>
          <div class="row">
            <div class="col-md-12 col-lg-6">
              <div class="news-img-block">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/play-img.png`}
                  class="img-fluid w-100"
                  alt="#"
                />
                <a
                  target="_blank"
                  class="video-play-button popup-youtube"
                  href="https://www.youtube.com/watch?v=4Wi27hH5YC4"
                >
                  <span></span>
                </a>
                <div id="video-overlay" class="video-overlay">
                  <a class="video-overlay-close">&times;</a>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-lg-6">
              <div class="video-play-text">
                <p>
                  Heal Holmes is a multivendor e-Health Care platform to Provide
                  complete healthcare service form patient's comfort zone. You
                  can book diagnostic Tests from your desired lab, can book
                  online/offline Doctor consultation & can also order medicine
                  with your Prescription. Heal Holmes Provides the same service
                  you can get from the labs but with 30% less. Patient can avail
                  home sample collection. Report will be delivered through the
                  mobile app/website in patient's accounts.
                </p>

                <hr />
                {/* <div class="newsletter-subscribe">
                  <h4>Subscribe to our Newsletter</h4>
                  <div class="newsletter-subscribe_form">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Your Email"
                    />
                    <a href="#">
                      <i class="fa fa-chevron-right"></i>
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="percentence-of-hhl">
        <div className="box">
          <h3>5000+ Happy Families</h3>
          <img
            width={70}
            alt="#"
            src={`${process.env.PUBLIC_URL}/assets/images/family.png`}
          />
        </div>
        <div className="box">
          <h3>1.5+ Year Of Service</h3>
          <img
            width={70}
            alt="#"
            src={`${process.env.PUBLIC_URL}/assets/images/service.png`}
          />
        </div>
        <div className="box">
          <h3>9 Number Of Labs</h3>
          <img
            width={70}
            alt="#"
            src={`${process.env.PUBLIC_URL}/assets/images/labs.png`}
          />
        </div>
        <div className="box">
          <h3>15000+ Tests Done</h3>
          <img
            width={70}
            alt="#"
            src={`${process.env.PUBLIC_URL}/assets/images/tests.png`}
          />
        </div>
      </div>
      <div className="container">
        <div class="Wellness-program">
          <div>
            <p class="Wellness-Program-by-Heal-Holmes">
              Wellness Program by Heal Holmes
            </p>
            <p class="Heal-Holmes-provides-great-health-solutions-for-as-little-as-1000tk-each-year">
              Heal Holmes provides great health solutions for as little as
              1000tk each year.
            </p>
          </div>
          <div className="card">
            <div className="box">
              <p class="Heal-Holmes-Existence">Heal Holmes Existence</p>
              <p className="desc">
                Hospital cash cover, prescription support, discounts at partner
                labs, and much more are all available. All for only Tk 1000 per
                year.
              </p>
            </div>
            <div className="card_check">
              <Box display={"flex"} gridGap={"16px"} alignItems={"center"}>
                <img
                  width={52}
                  alt="#"
                  src={`${process.env.PUBLIC_URL}/assets/images/check_bold.png`}
                />
                <span class="Lab-Diagnostic-Test-Cashback">
                  Lab & Diagnostic Test Cashback
                </span>
              </Box>
              <Box display={"flex"} gridGap={"16px"} alignItems={"center"}>
                <img
                  width={52}
                  alt="#"
                  src={`${process.env.PUBLIC_URL}/assets/images/check_bold.png`}
                />
                <span class="Lab-Diagnostic-Test-Cashback">
                  Partner Diagnostic Centre Discount
                </span>
              </Box>
              <Box display={"flex"} gridGap={"16px"} alignItems={"center"}>
                <img
                  width={52}
                  alt="#"
                  src={`${process.env.PUBLIC_URL}/assets/images/check_bold.png`}
                />
                <span class="Lab-Diagnostic-Test-Cashback">
                  24/7 TeleMedicine
                </span>
              </Box>
              <Box display={"flex"} gridGap={"16px"} alignItems={"center"}>
                <img
                  width={52}
                  alt="#"
                  src={`${process.env.PUBLIC_URL}/assets/images/check_bold.png`}
                />
                <span class="Lab-Diagnostic-Test-Cashback">OPD Cashback</span>
              </Box>
            </div>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <button className="btn btn-lg">Details</button>
            </Box>
          </div>
        </div>
      </div>

      <div className="emergency_contact my-5">
        {/* <div className="container"> */}
        <Box display={"flex"} justifyContent={"center"}>
          <Box className="parent">
            <Box>
              <img
                alt="#"
                src={`${process.env.PUBLIC_URL}/assets/images/5147268 1_1.png`}
              />
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-start"}
            >
              <p className="main">Emergency?</p>
              <p className="sub">Contact Us.</p>
              <p className="desc my-2">
                We Care for Your Health. If You Get into Any Trouble,HHL Always
                Serves You to Fulfill Your Desired Healthcare Service.Our
                Hotline is Always Open for You. Feel Free to Contact Us.
              </p>
              <Box
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                className="text mt-2"
                gridGap={"20px"}
              >
                <Box display={"flex"} gridGap={"16px"} alignItems={"center"}>
                  <img
                    alt="#"
                    src={`${process.env.PUBLIC_URL}/assets/images/call.png`}
                  />
                  <Box display={"flex"} flexDirection={"column"}>
                    {" "}
                    <p className="main">Call us now</p>
                    <p className="sub">09642919191</p>
                  </Box>
                </Box>
                <Box display={"flex"} gridGap={"16px"} alignItems={"center"}>
                  <img
                    alt="#"
                    src={`${process.env.PUBLIC_URL}/assets/images/mail.png`}
                  />
                  <Box display={"flex"} flexDirection={"column"}>
                    {" "}
                    <p className="main">Mail us</p>
                    <p className="sub">heal.holmes@gmail.com</p>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* <Box>
              {" "}
              <Box>
                <img
                  alt="#"
                  width={"300px"}
                  src={`${process.env.PUBLIC_URL}/assets/images/emer.png`}
                />
              </Box>
            </Box> */}
        </Box>
        {/* </div> */}
      </div>
      <section class="our-team">
        <div class="container container-custom">
          <div class="row">
            <div class="col-md-12">
              <div class="sub-title_center">
                <h2>Our Dedicated Lab Partners</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="doctors-slider">
                <Slider {...settings}>
                  <div onClick={() => routeToAvailableTests("Thyrocare")}>
                    <div class="team-img_block blue">
                      <div class="team-img-socila-block">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/TBL-logo-AI.png`}
                          class="img-fluid"
                          alt="Thyrocare Lab"
                        />
                      </div>
                    </div>
                  </div>
                  <div onClick={() => routeToAvailableTests("DNA-Solution")}>
                    <div class="team-img_block blue">
                      <div class="team-img-socila-block">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/DNA_Solution_Ltd.png`}
                          class="img-fluid"
                          alt="DNA Solution Lab"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => routeToAvailableTests("Stemz-Healthcare")}
                  >
                    <div class="team-img_block blue">
                      <div class="team-img-socila-block">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/Stemz.png`}
                          class="img-fluid"
                          alt="Stemz Healthcare Lab"
                        />
                      </div>
                    </div>
                  </div>
                  <div onClick={() => routeToAvailableTests("CRL-Diagnostics")}>
                    <div class="team-img_block blue">
                      <div class="team-img-socila-block">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/CRL.png`}
                          class="img-fluid"
                          alt="CRL Diagnostics"
                        />
                      </div>
                    </div>
                  </div>
                  <div onClick={() => routeToAvailableTests("praava-health")}>
                    <div class="team-img_block blue">
                      <div class="team-img-socila-block">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/Praava.png`}
                          class="img-fluid"
                          alt="Praava Health Care"
                        />
                      </div>
                    </div>
                  </div>
                  <div onClick={() => routeToAvailableTests("cccl")}>
                    <div class="team-img_block blue">
                      <div class="team-img-socila-block">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/CCCL.png`}
                          class="img-fluid"
                          alt="Cure & Care Clinical Lab"
                        />
                      </div>
                    </div>
                  </div>
                  <div onClick={() => routeToAvailableTests("oncos-molbiol")}>
                    <div class="team-img_block blue">
                      <div class="team-img-socila-block">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/Oncosmol.png`}
                          class="img-fluid"
                          alt="Oncos Molbiol Limited"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => routeToAvailableTests("universal-medical")}
                  >
                    <div class="team-img_block blue">
                      <div class="team-img-socila-block">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/Universal.png`}
                          class="img-fluid"
                          alt="Universal Medical College & Hospital"
                        />
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="our-team">
        <div class="container container-custom">
          <div class="row">
            <div class="col-md-12">
              <div class="sub-title_center">
                <h2>Our Dedicated Doctors</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="doctors-slider">
                <Slider {...settingsDocs}>
                  {doctorList.slice(0, 10).map((doc, index) => (
                    <div onClick={() => routeToDocDetails(doc.id)} key={index}>
                      <div class="team-img_block yellow">
                        <div class="team-img-socila-block">
                          <img
                            src={`${process.env.REACT_APP_PUBLIC_URL}${photoPath}/${doc.photo}`}
                            class="img-fluid"
                            alt="#"
                          />
                        </div>
                        <h4>{doc.name}</h4>
                        <p>{doc.designation}</p>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!--==================== Testimonials ====================--> */}
      <section class="our-team">
        <div class="container container-custom">
          <div class="row">
            <div class="col-md-12">
              <div class="sub-title_center">
                <h2>What Our Clients Say</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="doctors-slider">
                <Slider {...settings}>
                  <div class="testimonial-wrap">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/Syeda.jpg`}
                      class="img-fluid testi-img-icon"
                      alt="#"
                    />
                    <ul>
                      <li>
                        <i class="fa fa-star"></i>
                      </li>
                      <li>
                        <i class="fa fa-star"></i>
                      </li>
                      <li>
                        <i class="fa fa-star"></i>
                      </li>
                      <li>
                        <i class="fa fa-star"></i>
                      </li>
                      <li>
                        <i class="fa fa-star"></i>
                      </li>
                    </ul>
                    <p>
                      <span>H</span>eal Holmes, is one of the quick service
                      providers I have seen and they are very friendly. I wish
                      them every success in the future.
                    </p>
                    <span class="testi-name">
                      Syeda Shaila Ashraf, Corporate HR, Admin & IT Head,
                      Renaissance group
                    </span>
                    <div class="testi-styled-bg"></div>
                  </div>
                  <div class="testimonial-wrap quaternary-br-color">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/Sayem.jpg`}
                      class="img-fluid testi-img-icon"
                      alt="#"
                    />
                    <ul>
                      <li>
                        <i class="fa fa-star"></i>
                      </li>
                      <li>
                        <i class="fa fa-star"></i>
                      </li>
                      <li>
                        <i class="fa fa-star"></i>
                      </li>
                      <li>
                        <i class="fa fa-star"></i>
                      </li>
                      <li>
                        <i class="fa fa-star"></i>
                      </li>
                    </ul>
                    <p>
                      <span>I</span> had done covid test through Heal Holmes.
                      Got my report within a short period of time. They are very
                      professional in their services. I wish them all the best.
                    </p>
                    <span class="testi-name">
                      Sayem Ahmed , Islamic Science University of Malaysia.{" "}
                    </span>
                    <div class="testi-styled-bg"></div>
                  </div>
                  <div class="testimonial-wrap quaternary-br-color">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/Torun.jpg`}
                      class="img-fluid testi-img-icon"
                      alt="#"
                    />
                    <ul>
                      <li>
                        <i class="fa fa-star"></i>
                      </li>
                      <li>
                        <i class="fa fa-star"></i>
                      </li>
                      <li>
                        <i class="fa fa-star"></i>
                      </li>
                      <li>
                        <i class="fa fa-star"></i>
                      </li>
                      <li>
                        <i class="fa fa-star"></i>
                      </li>
                    </ul>
                    <p>
                      <span>T</span>hank you so much for your excellent service.
                      Your home sample collection and timely report delivered to
                      my account are excellent. Moreover huge savings through
                      Heal Holmes.
                    </p>
                    <span class="testi-name">
                      Shahadat Alam, Sr. Software Engineer. Daffodil Computers
                      Ltd.
                    </span>
                    <div class="testi-styled-bg">
                      {/* <img
                        src="images/testi-side-img_05.png"
                        class="img-fluid"
                        alt="#"
                      /> */}
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--//End Testimonials --> */}
      <section class="bg-img3 mt-4">
        <div class="container container-custom">
          {/* <div class="row">
            <div class="col-lg-5 offset-lg-7">
              <div class="consultant-content">
                <h2>We Believe in a Healthier You</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eius mod tempor incididunt ut labore et dolore magna
                  aliqua. Ut enim ad minim veniam, quis nostrud exercitation.
                </p>
                <p>
                  Adipisicing elit, sed do eius mod tempor incididunt ut labore
                  et dolore magna aliqua. Ut enim ad minim veniam,
                </p>
                <a href="#" class="btn btn-success">
                  Get a Consultant
                </a>
                <a
                  href="https://www.youtube.com/watch?v=pBFQdxA-apI"
                  class="popup-youtube"
                >
                  {" "}
                  <i class="fa fa-play"></i>
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      <Footer />
      <div className="row">
        <div className="col-md-12">
          <MessengerCustomerChat
            pageId="112975200580661"
            appId="434998947911975"
          />
        </div>
      </div>
    </div>
  );
}
