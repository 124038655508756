import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { DoctorAPI } from "../../AxiosRequest/DoctorAPI";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function PatientHistory() {
  let { patientId } = useParams();
  const [history, setHistoty] = useState([]);
  const [openPhotoModal, setOpenPhotoModal] = React.useState(false);
  const [selectedImg, setSelectedImg] = React.useState({});
  const [selectedImgID, setSelectedImgID] = React.useState({});
  const [showReports, setShowReports] = useState(false);
  const [reports, setReports] = useState([]);
  const [selectedId, setSelectedId] = useState(Number);
  const [reportMessage, setReportMessage] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const [path, setPath] = useState("");
  const paramID = parseInt(patientId);
  useEffect(() => {
    getPatientDetails();
  }, []);

  const getPatientDetails = () => {
    // let url = `http://localhost:3001/purchase_order/${paramID}`;
    DoctorAPI.get(`patient/${paramID}`)
      .then((response) => {
        console.log(response.data.data.patient_appointments);
        setHistoty(response.data.data.patient_appointments);
        if (response.data.data.patient_appointments.length === 0) {
          setReportMessage("No previous history found");
        }
        setPath(response.data.path);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleReports = (arr, id) => {
    setReports([...arr]);
    setSelectedId(id);
    setShowReports(!showReports);

    console.log(arr, id);
  };

  const handleReportsArray = (arr) => {
    console.log(arr);
    setReports([...arr]);
  };

  const openModal = (id) => {
    console.log(id);
    let imgArr = [...reports];
    let filtered = imgArr.filter((img) => img.id === id);
    console.log("filerte", filtered);
    setSelectedImg(filtered[0].report);
    setSelectedImgID(filtered[0].patient_appointment_id);
    setOpenPhotoModal(true);
  };
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="text-center">{reportMessage}</h2>
            {history.map((item, index) => (
              <div key={index} className="card">
                {item.doctor !== undefined && (
                  <h5 className="card-header">
                    Doctor :{item.doctor.name}
                    ,Department : {item.doctor.department.name}
                  </h5>
                )}
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="card">
                        <h5 className="card-header">Observations</h5>
                        <div className="card-body">
                          {item.prescription.prescribed_observations.map(
                            (data, index) => (
                              <React.Fragment key={index}>
                                <h5 className="card-title">{data.details}</h5>
                              </React.Fragment>
                            )
                          )}
                        </div>
                      </div>
                      <div className="card mt-2">
                        <h5 className="card-header">Tests</h5>
                        <div className="card-body">
                          {item.prescription.prescribed_tests.map(
                            (data, index) => (
                              <React.Fragment key={index}>
                                <h5 className="card-title">{data.name}</h5>
                              </React.Fragment>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <h5 className="card-header">Medicine</h5>
                        <div className="card-body">
                          {item.prescription.prescribed_medicines.map(
                            (data, index) => (
                              <React.Fragment key={index}>
                                <div className="card mb-1">
                                  <div className="card-body">
                                    <h5 className="card-title">
                                      {" "}
                                      {data.medicine.brand_name}
                                    </h5>
                                    <h6 className="card-subtitle mb-2 text-muted">
                                      {" "}
                                      <span style={{ fontWeight: "bold" }}>
                                        {" "}
                                        Validity :
                                      </span>
                                      {data.validity}
                                    </h6>
                                    <h6 className="card-subtitle mb-2 text-muted">
                                      <span style={{ fontWeight: "bold" }}>
                                        {" "}
                                        Rule :
                                      </span>{" "}
                                      {data.rule}
                                    </h6>
                                    <h6 className="card-subtitle mb-2 text-muted">
                                      <span style={{ fontWeight: "bold" }}>
                                        {" "}
                                        Before/After Meal :
                                      </span>{" "}
                                      {data.timing}
                                    </h6>
                                  </div>
                                </div>
                              </React.Fragment>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <button
                        onClick={() =>
                          handleReports(item.patient_uploaded_reports, item.id)
                        }
                        className="btn btn-lg btn-primary"
                      >
                        Show Reports
                      </button>

                      <br />

                      {item.id === selectedId && (
                        <div>
                          <div className=" multi-preview">
                            {(item.patient_uploaded_reports || []).map(
                              (url, key) => (
                                <img
                                  onClick={() => openModal(url.id)}
                                  key={key}
                                  src={
                                    `${process.env.REACT_APP_PUBLIC_URL}` +
                                    path +
                                    url.patient_appointment_id +
                                    "/" +
                                    url.report
                                  }
                                  alt="..."
                                />
                              )
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Dialog
        fullWidth
        maxWidth={`${isMobile ? "sm" : "md"}`}
        open={openPhotoModal}
        onClose={() => setOpenPhotoModal(false)}
      >
        <DialogTitle id="max-width-dialog-title">Report</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <img
              width="100%"
              src={
                `${process.env.REACT_APP_PUBLIC_URL}` +
                path +
                selectedImgID +
                "/" +
                selectedImg
              }
              alt="..."
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPhotoModal(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
