import React, { useState, useEffect } from "react";
import { TextField } from "formik-material-ui";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import * as Yup from "yup";
import { PatientAPI } from "../../AxiosRequest/PatientAPI";
import Navbar2 from "../Shared/Navbar2";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import { TextField as TextFieldSecond } from "formik-material-ui";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import _ from "lodash";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  input: {
    background: "#fff",
    border: "1px solid #007ffd",
    padding: "14px 5px!important",
  },
  fControl: {
    border: "1px solid #007ffd",
    minWidth: "100%",
    background: "#fff",
  },
  autoInput: {},
}));

const FormikDatePicker = ({
  name,
  form: { setFieldValue },
  field: { value },

  ...rest
}) => {
  // console.log(rest);
  return (
    <DatePicker
      disableFuture
      openTo="year"
      format="dd/MM/yyyy"
      views={["year", "month", "date"]}
      name={name}
      keyboard
      clearable
      autoOk
      // handle clearing outside => pass plain array if you are not controlling value outside
      mask={(value) =>
        value
          ? [/[0-3]/, /\d/, "/", /0|1/, /\d/, "/", /1|2/, /\d/, /\d/, /\d/]
          : []
      }
      disableOpenOnEnter
      onChange={(value) => {
        console.log("setting value to", value);
        setFieldValue("date_of_birth", value);
      }}
      value={value}
      animateYearScrolling={false}
      classes={{
        root: {
          border: "1px solid #007ffd",
          minWidth: "100%",
          background: "#fff",
        },
      }}
    />
  );
};
export default function PatientRegistration({ hideNav }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [signedUpMessage, setSignedUpMessage] = useState("");
  const [singnedUp, setSignedUp] = useState(false);
  // Backend Error Messages
  const [nameErros, setNameErrors] = useState([]);
  const [phoneErros, setPhoneError] = useState([]);
  const [ageErrors, setAgeErrors] = useState([]);
  const [genderErrors, setGenderErrors] = useState([]);

  const [passwordErrors, setPasswordErrors] = useState([]);
  const [passwordConfirmationErrors, setPaswordConfirmationErros] = useState(
    []
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const validationSchema = Yup.object({
    name: Yup.string().required("Name field is required"),
    phone: Yup.string().required("Please enter your phone number"),
    // email: Yup.string().email("Please Enter a valid Email"),
    password: Yup.string()
      .min(6, "Password must contain at least 6 characters")
      .required("Enter your password"),
    confirm_password: Yup.string()
      .required("Confirm your password")
      .oneOf([Yup.ref("password")], "Password does not match"),
    date_of_birth: Yup.date().required("Date of Birth field is required"),
    gender: Yup.string().required("Gender field is required"),
  });
  const initialValues = {
    name: "",
    phone: "",
    date_of_birth: null,
    gender: "",
    password: "",
    confirm_password: "",
  };

  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    setLoading(true);
    let objToPost = { ...values };
    objToPost.date_of_birth = moment(values.date_of_birth).format("YYYY-MM-DD");
    console.log(objToPost);
    PatientAPI.post("auth/register", objToPost)
      .then((response) => {
        console.log(response);
        console.log(response.data.message);
        setSignedUp(true);
        setSignedUpMessage(
          "You have successfully signed up. Press login to continue"
        );
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response);
        setLoading(false);
        if (error.response.data.message) {
        }

        if (error.response.data.errors !== undefined) {
          let nameErrors = error.response.data.errors.name;
          let phoneErrors = error.response.data.errors.phone;
          let birthErrors = error.response.data.errors.date_of_birth;
          let sexErrors = error.response.data.errors.gender;
          let passwordErrors = error.response.data.errors.password;

          let confirmPasswordErrors =
            error.response.data.errors.confirm_password;

          if (nameErrors !== undefined) {
            setNameErrors(nameErrors);
          }
          if (phoneErrors !== undefined) {
            setPhoneError(phoneErrors);
          }
          if (birthErrors !== undefined) {
            setAgeErrors(birthErrors);
          }
          if (sexErrors !== undefined) {
            setGenderErrors(sexErrors);
          }
          if (passwordErrors !== undefined) {
            setPasswordErrors(passwordErrors);
          }
          if (confirmPasswordErrors !== undefined) {
            setPaswordConfirmationErros(confirmPasswordErrors);
          }
        }
      });
  };
  return (
    <div>
      {hideNav ? <></> : <Navbar2 />}

      <div className="pa-register-section">
        <div className="container-pa">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-12">
                  <div className="pa-register-form">
                    <div className="form-new">
                      <h1 className="text-center main-header">
                        Submit Your Details
                      </h1>
                      {/* <h3 className="text-center py-2">Patient Registration</h3> */}

                      <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                      >
                        {({ setFieldValue, isValid, dirty }) => (
                          <Form>
                            <div className="form-group row">
                              <label className="col-sm-2 offset-sm-1 col-form-label">
                                Name
                              </label>
                              <div className="col-sm-7">
                                <Field
                                  name="name"
                                  type="text"
                                  className="form-control"
                                  placeholder="Your Name"
                                />
                                <ErrorMessage
                                  name="name"
                                  component="div"
                                  className="error-message"
                                />
                                {nameErros.map((item, i) => (
                                  <p className="error-message" key={i}>
                                    {item}
                                  </p>
                                ))}
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-2 offset-sm-1 col-form-label">
                                Mobile
                              </label>
                              <div className="col-sm-7">
                                <Field
                                  name="phone"
                                  type="text"
                                  className="form-control"
                                  placeholder="Your Mobile number"
                                />
                                <ErrorMessage
                                  name="phone"
                                  component="div"
                                  className="error-message"
                                />
                                {phoneErros.map((item, i) => (
                                  <p className="error-message" key={i}>
                                    {item}
                                  </p>
                                ))}
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-2 offset-sm-1 col-form-label">
                                Gender
                              </label>
                              <div className="col-sm-7">
                                <Field
                                  className="form-control"
                                  as="select"
                                  name="gender"
                                >
                                  <option value="">Gender</option>
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                  <option value="Others">Others</option>
                                </Field>
                                <ErrorMessage
                                  name="gender"
                                  component="div"
                                  className="error-message"
                                />
                                {genderErrors.map((item, i) => (
                                  <p className="error-message" key={i}>
                                    {item}
                                  </p>
                                ))}
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-2 offset-sm-1 col-form-label">
                                Date of birth
                              </label>
                              <div className="col-sm-7">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <div className="col-sm-12">
                                    <Field
                                      component={FormikDatePicker}
                                      name="date_of_birth"
                                    />

                                    <ErrorMessage
                                      name="date_of_birth"
                                      component="div"
                                      className="error-message"
                                    />

                                    {ageErrors.map((item, i) => (
                                      <p className="error-message" key={i}>
                                        {item}
                                      </p>
                                    ))}
                                  </div>
                                </MuiPickersUtilsProvider>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-2 offset-sm-1 col-form-label">
                                Password
                              </label>
                              <div className="col-sm-7">
                                <Field
                                  name="password"
                                  type="password"
                                  className="form-control "
                                  placeholder="Password"
                                />
                                <ErrorMessage
                                  name="password"
                                  component="div"
                                  className="error-message"
                                />
                                {passwordErrors.map((item, i) => (
                                  <p className="error-message" key={i}>
                                    {item}
                                  </p>
                                ))}
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-2 offset-sm-1 col-form-label">
                                Confirm Password
                              </label>
                              <div className="col-sm-7">
                                <Field
                                  name="confirm_password"
                                  type="password"
                                  className="form-control "
                                  placeholder="Confirm Password"
                                />
                                <ErrorMessage
                                  name="confirm_password"
                                  component="div"
                                  className="error-message"
                                />
                                {passwordConfirmationErrors.map((item, i) => (
                                  <p className="error-message" key={i}>
                                    {item}
                                  </p>
                                ))}
                              </div>
                            </div>
                            {!isMobile ? (
                              <div className="form-group row pt-3">
                                <div className="offset-sm-1 pt-2 mr-auto">
                                  Already have an account? Login
                                  <Link
                                    className="px-1"
                                    type="button"
                                    to="/patient/login"
                                  >
                                    here
                                  </Link>
                                  <label className="col-sm-11 offset-sm-1">
                                    {signedUpMessage} <br />
                                    {singnedUp && (
                                      <Link
                                        to="/patient/login"
                                        type="button"
                                        className="btn btn-lg btn-primary"
                                      >
                                        Login
                                      </Link>
                                    )}
                                  </label>
                                </div>
                                <label className="ml-auto">
                                  <button
                                    disabled={loading}
                                    // disabled={!isValid || !dirty}
                                    type="submit"
                                    className="btn btn-md btn-success"
                                  >
                                    {loading && (
                                      <i className="fa fa-refresh fa-spin"></i>
                                    )}
                                    {loading && <span>Signing Up</span>}
                                    {!loading && <span> Sign Up</span>}
                                  </button>
                                </label>
                              </div>
                            ) : (
                              <div className="form-group row pt-3 text-center">
                                <button
                                  disabled={loading}
                                  // disabled={!isValid || !dirty}
                                  type="submit"
                                  className="btn btn-md btn-success btn-block"
                                >
                                  {loading && (
                                    <i className="fa fa-refresh fa-spin"></i>
                                  )}
                                  {loading && <span>Signing Up</span>}
                                  {!loading && <span> Sign Up</span>}
                                </button>

                                <div className="pt-4">
                                  Already have an account? Login
                                  <Link
                                    className="px-1"
                                    type="button"
                                    to="/patient/login"
                                  >
                                    here
                                  </Link>
                                  <label className="col-sm-11 offset-sm-1">
                                    {signedUpMessage} <br />
                                    {singnedUp && (
                                      <Link
                                        to="/patient/login"
                                        type="button"
                                        className="btn btn-lg btn-primary btn-block"
                                      >
                                        Login
                                      </Link>
                                    )}
                                  </label>
                                </div>
                              </div>
                            )}
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-1"></div>
        </div>
      </div>
    </div>
  );
}
