import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { makeStyles } from "@material-ui/core/styles";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";
import Pagination from "@material-ui/lab/Pagination";
import { Link } from "react-router-dom";
export default function DiagnosticTest({ admin }) {
  const [diagnosticList, setDiagnosticList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [diagnosticID, setDiagnosticID] = useState("");
  const [testList, setTestList] = useState([]);
  const [params, setParams] = useState({
    page: 1,
    per_page: 15,
  });

  useEffect(() => {
    getDiagnosticList();
    getTestList();
  }, []);

  const getDiagnosticList = () => {
    AdminAPI.get("diagnostics")
      .then((response) => {
        console.log(response.data.data.data);
        setDiagnosticList(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  function handleChangePage(event, value) {
    setParams({ ...params, page: value });
  }
  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);
  useEffect(() => {
    getTestList();
  }, [params]);
  const getTestList = () => {
    AdminAPI.get("diagnostic-tests", {
      params: params,
    })
      .then((response) => {
        setTestList(response.data.data.data);
        setCurrentPage(response.data.data.current_page);
        setTotalPage(response.data.data.last_page);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  return (
    <div>
      <div className="container-fluid">
        <div className="row ">
          <div className="col-md-10">
            <div className="form-group row">
              <label className="col-md-4 col-12">Select Diagonstic</label>
              <div className="col-md-8 col-12">
                <Autocomplete
                  id="disabledHelperText"
                  name="diagnostic_id"
                  options={diagnosticList.filter((dig) => dig.id !== 1)}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, value) => {
                    if (value == null) {
                      setDiagnosticID("");
                      setParams({
                        ...params,
                        service_provider_id: null,
                      });
                    } else {
                      setDiagnosticID(value.id);
                      setParams({
                        ...params,
                        service_provider_id: value.id,
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="diagnostic_id"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="col-md-2 text-right">
            <Link
              className="btn btn-outline-secondary"
              type="button"
              to={"/admin/diagnostic-test/add-test"}
            >
              Add Diagnostic Test
            </Link>
          </div>
        </div>

        <div className="row my-2">
          <div className="col-md-4 form-group">
            <select
              className="form-control"
              onChange={(e) => {
                handleColumnWiseSearch(e.target.value, "status");
              }}
            >
              <option value="">All</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
          </div>
          <div className="col-md-6 form-group">
            <input
              type="text"
              onChange={(e) => handleColumnWiseSearch(e.target.value, "name")}
              className="form-control"
              placeholder="Search Test"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table table-sm table-striped table-bordered custom-sm">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Test Name</th>
                    <th scope="col">Lab</th>
                    <th scope="col">Price</th>
                    <th scope="col">Discount</th>
                    <th scope="col">Selling</th>
                    <th scope="col">Buying</th>
                    <th scope="col">H/C</th>
                    <th scope="col">Status</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {testList.map((test, i) => (
                    <tr key={i}>
                      <td data-column="Test Name">{test.name}</td>
                      <td data-column="Department">
                        {test.service_provider.name}
                      </td>
                      <td data-column="Price">{Math.ceil(test.price)}</td>
                      <td data-column="Discount">
                        {test !== undefined && (
                          <span>
                            {test.discount_type === "%" ? (
                              <span>{test.discount}%</span>
                            ) : (
                              <span>{test.discount} Tk.</span>
                            )}
                          </span>
                        )}
                      </td>
                      <td data-column="Selling">
                        {test !== undefined && (
                          <span>
                            {test.discount_type === "%" ? (
                              <span>
                                {Math.ceil(
                                  test.price -
                                    (test.price / 100) * test.discount
                                )}
                              </span>
                            ) : (
                              <span>
                                {Math.ceil(test.price - test.discount)}
                              </span>
                            )}
                          </span>
                        )}
                      </td>
                      <td data-column="Buying">
                        {admin && <>{Math.ceil(test.buying_price)}</>}{" "}
                      </td>
                      <td data-column="H/C">
                        {test.home_delivery ? "Yes" : "No"}
                      </td>
                      <td data-column="status">
                        {" "}
                        {test.status == "Active" ? (
                          <span>{test.status}</span>
                        ) : (
                          <span className="text-warning">{test.status}</span>
                        )}{" "}
                      </td>
                      <td data-column="Action">
                        <Link
                          type="button"
                          to={`/admin/diagnostic-tests/${test.id}`}
                          className="btn btn-sm btn-outline-primary mr-1"
                        >
                          <VisibilityIcon />
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row">
          <Pagination
            color="primary"
            count={totalPage}
            page={currentPage}
            onChange={handleChangePage}
          />
        </div>
      </div>
    </div>
  );
}
