import React, { useState, useEffect } from "react";
import { DiagnosticAPI } from "../../../AxiosRequest/DiagnosticAPI";
import Pagination from "@material-ui/lab/Pagination";
import GetAppIcon from "@material-ui/icons/GetApp";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { Layout } from "antd";
const { Content, Footer } = Layout;

export default function InvoiceList() {
  let history = useHistory();
  const [invoiceList, setInvoiceList] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [selectedStartDate, handleDateChangeStart] = useState(null);
  const [selectedEndDate, handleDateChangeEnd] = useState(null);
  const [params, setParams] = useState({
    page: 1,
    per_page: 15,
  });

  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };

  const handleDateChangeDate = (value, col) => {
    if (value !== null) {
      handleColumnWiseSearch(moment(value).format("YYYY-MM-DD"), col);
    } else {
      handleColumnWiseSearch(value, col);
    }
  };

  useEffect(() => {
    DiagnosticAPI.get(`test-invoices`, { params: params })
      .then((response) => {
        setInvoiceList(response.data.data);
        setTotalPage(response.data.last_page);
        setPage(response.data.current_page);
      })
      .catch((error) => {});
  }, [params]);

  const downloadPDF = (id) => {
    DiagnosticAPI.get(`test-invoices/${id}/pdf`, {
      method: "GET",
      responseType: "blob", //Force to receive data in a Blob Format
    })
      .then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" }); //Build a URL from the file
        const fileURL = URL.createObjectURL(file); //Open the URL on new Window
        window.open(fileURL);

        console.log("pdf response", response, file);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);

  const downloadReport = (testId, report) => {
    console.log("Report name", report);
    axios
      .get(`files/testInvoiceDetails/${testId}/${report}`, {
        responseType: "blob",
      })
      .then((response) => {
        const file = new Blob([response.data]); //Build a URL from the file
        const fileURL = window.URL.createObjectURL(file); //Open the URL on new Window
        var a = document.createElement("a");
        a.href = fileURL;
        a.download = report;
        a.click();
        window.URL.revokeObjectURL(fileURL);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div className="row my-2">
                <div className="col-md-6">
                  {" "}
                  <KeyboardDatePicker
                    clearable
                    value={selectedStartDate}
                    placeholder="Start Date"
                    onChange={(date) => {
                      handleDateChangeStart(date);
                      handleDateChangeDate(date, "start_date");
                    }}
                    format="MM/dd/yyyy"
                  />
                </div>

                <div className="col-md-6">
                  {" "}
                  <KeyboardDatePicker
                    clearable
                    value={selectedEndDate}
                    placeholder="End Date"
                    onChange={(date) => {
                      handleDateChangeEnd(date);
                      handleDateChangeDate(date, "end_date");
                    }}
                    format="MM/dd/yyyy"
                  />
                </div>
              </div>
            </MuiPickersUtilsProvider>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <input
                placeholder="Search"
                className="form-control"
                type="text"
                onChange={(e) => {
                  handleColumnWiseSearch(e.target.value, "search");
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className=" table-responsive">
              <table className="custom-responsive-table">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Patient name</th>
                    <th scope="col">Mobile</th>
                    <th scope="col">Age</th>
                    <th scope="col">Passport</th>
                    <th scope="col">Location</th>
                    <th scope="col">Test Date</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                {invoiceList.map((invoice, i) => (
                  <tbody>
                    <tr key={i}>
                      <td>{invoice.unique_id}</td>
                      <td>
                        {invoice.patient_name !== null
                          ? invoice.patient_name
                          : invoice.patient.name}
                      </td>
                      <td>
                        {invoice.type === "Corporate"
                          ? invoice.referred_by
                            ? invoice.referred_by.phone
                            : invoice.patient.phone
                          : invoice.patient.phone}
                      </td>
                      <td data-column="Age">
                        {invoice &&
                          invoice.patient &&
                          invoice.patient.date_of_birth && (
                            <>
                              {moment().diff(
                                invoice.patient.date_of_birth,
                                "years"
                              )}
                            </>
                          )}
                      </td>
                      <td>
                        {invoice.type === "Corporate" ? "" : invoice.passport}
                      </td>

                      <td>
                        {invoice.address},
                        {invoice.police_station_id !== null
                          ? invoice.police_station.name
                          : ""}
                        ,
                        {invoice.police_station_id !== null
                          ? invoice.police_station.district
                          : ""}
                      </td>
                      <td>
                        {invoice.test_date &&
                          moment(invoice.test_date).format("DD/MM/YYYY")}
                      </td>

                      <td>
                        <Link
                          type="button"
                          to={`/diagnostic/invoices/${invoice.id}`}
                          className="btn btn-sm btn-primary"
                        >
                          <VisibilityIcon />
                        </Link>
                        <button
                          onClick={() => downloadPDF(invoice.id)}
                          className="btn btn-sm btn-outline-success mx-1"
                        >
                          <GetAppIcon />
                        </button>
                      </td>
                    </tr>
                    {invoice.test_invoice_details.map((invoiceDetail, j) => (
                      <tr key={"detail" + j}>
                        <td className="empty-left-border"></td>
                        <td className="empty-left-border"></td>
                        <td className="empty-left-border"></td>
                        <td className="empty-left-border"></td>
                        <td className="empty-left-border"></td>
                        <td className="test-color" data-column="Test">
                          {invoice.type === "BCorporate" ? (
                            <span>
                              {invoiceDetail.corporate_test !== null &&
                                invoiceDetail.corporate_test.name}
                            </span>
                          ) : (
                            <span>
                              {invoiceDetail.diagnostic_test.name !== null &&
                                invoiceDetail.diagnostic_test.name}
                            </span>
                          )}
                        </td>
                        <td className="test-color" data-column="Report">
                          {invoiceDetail.report !== null ? (
                            <button
                              className="btn  btn-sm btn-success"
                              onClick={() =>
                                downloadReport(invoice.id, invoiceDetail.report)
                              }
                            >
                              Report
                            </button>
                          ) : (
                            <span>{invoiceDetail.status}</span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </div>
      </div>

      <Footer style={{ textAlign: "center" }}>
        {/* Ant Design ©2018 Created by Ant UED */}
      </Footer>

      <div className="form-group row">
        <Pagination
          color="primary"
          count={totalPage}
          page={page}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}
