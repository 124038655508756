import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import * as Yup from "yup";
import { Layout } from "antd";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";

export default function NonMaskingForm() {
  let history = useHistory();

  const [backendErrorMessage, setBackendErrorMessage] = useState({});
  // Backend ErrendErrorMessage, setBackendErrorMessage] = useState({});

  const validationSchema = Yup.object({
    phone: Yup.string()
      .typeError("This field is required")
      .matches(/^(01){1}[3-9]{1}\d{8}$/, "Enter a valid Mobile Number"),
    message: Yup.string()
      .required("This field is required")
      .max(612, "Max Character Limit Reached!!!"),
      type: Yup.string()
      .required("This field is required")
  });
  const initialValues = {
    phone: "",
    message: "",
    type:""
  };

  const onSubmit = (values, { resetForm }) => {
    AdminAPI.post("sms/send-non-masking-sms", values)
      .then((response) => {
        console.log(response);
        alert("Success");
        resetForm();
        // history.push("/admin/dashboard");
        if (response.data.message) {
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.data.errors) {
          setBackendErrorMessage(error.response.data.errors);
        }

        if (error.response.data.message) {
        }

        if (error.response.data.errors !== undefined) {
        }
      });
  };
  return (
    <div>
      <div className="container">
        <div className="row"></div>
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-md-12">
                <div className="add-institution">
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({ setFieldValue, isValid, dirty }) => (
                      <Form>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1">Phone</label>
                          <div className="col-sm-7">
                            <Field
                              className="form-control"
                              type="text"
                              name="phone"
                            />
                            <ErrorMessage
                              name="phone"
                              component="div"
                              className="error-message"
                            />

                            <p className="error-message">
                              {backendErrorMessage.phone}
                            </p>
                          </div>
                        </div>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1">Type</label>
                          <div className="col-sm-7">
                          <Field
                              className=" form-control"
                              as="select"
                              name="type"
                            >
                              <option value=""></option>
                              <option value="Admin">Admin</option>
                              <option value="Doctor">Doctor</option>
                              <option value="Patient">Patient</option>

                             
                            </Field>
                            <ErrorMessage
                              name="type"
                              component="div"
                              className="error-message"
                            />

                            <p className="error-message">
                              {backendErrorMessage.type}
                            </p>
                          </div>
                        </div>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1">
                            Message
                          </label>
                          <div className="col-sm-7">
                            <Field
                              className=" form-control"
                              type="text"
                              name="message"
                              component="textarea"
                              rows="10"
                            />
                            <ErrorMessage
                              name="message"
                              component="div"
                              className="error-message"
                            />

                            <p className="error-message">
                              {backendErrorMessage.message}
                            </p>
                          </div>
                        </div>

                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1"></label>
                          <div className="col-sm-7 text-right">
                            <button
                              type="submit"
                              className="btn btn-lg btn-success"
                            >
                              {"Submit"}
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
