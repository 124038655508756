import React, { useState, useEffect } from "react";
import { TextField } from "formik-material-ui";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { useParams, useHistory } from "react-router-dom";

import { AdminAPI } from "../../../AxiosRequest/AdminAPI";

import _ from "lodash";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";

const DateOfBirthDatePicker = ({
  name,
  form: { setFieldValue },
  field: { value },
  ...rest
}) => {
  // console.log(rest);
  return (
    <DatePicker
      disableFuture
      format="dd/MM/yyyy"
      views={["year", "month", "date"]}
      name={name}
      clearable
      autoOk
      inputVariant="outlined"
      // handle clearing outside => pass plain array if you are not controlling value outside
      mask={(value) =>
        value
          ? [/[0-3]/, /\d/, "/", /0|1/, /\d/, "/", /1|2/, /\d/, /\d/, /\d/]
          : []
      }
      onChange={(value) => {
        console.log("setting value to", value);
        console.log("setting value to", moment(value).format("YYYY-MM-DD"));

        setFieldValue("date_of_birth", moment(value).format("YYYY-MM-DD"));
      }}
      value={value}
      animateYearScrolling={false}
    />
  );
};
export default function UserForm() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [singnedUp, setSignedUp] = useState(false);
  const [policeStations, setPoliceStations] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [district, setDistrict] = useState("");
  const [stationSearchTerm, setStationSearchTerm] = useState("");
  const [districtSearchTerm, setDistrictSearchTerm] = useState("");
  const [districtValue, setDistrictValue] = useState("");
  const [policeStationValue, setPoliceStationValue] = useState("");
  let { id } = useParams();
  const [agentData, setAgentData] = useState({});

  //   console.log("form data", formData);

  // Backend Error Messages

  const [errorMessage, setErrorMessage] = useState({});

  useEffect(() => {
    if (id !== undefined) {
      getAgentData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getAgentData = () => {
    AdminAPI.get(`users/${id}`)
      .then((response) => {
        // setMedicineData(response.data[0]);
        console.log("response data", response.data);
        setAgentData(response.data.data);
        setDistrict(response.data.data.police_station.district);
        setDistrictValue(response.data.data.police_station.district);
        setPoliceStationValue(response.data.data.police_station.name);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const validationSchemaPost = Yup.object({
    name: Yup.string().required("Name field is required"),
    date_of_birth: Yup.string().required(" field is required"),
    role: Yup.string().required("Role field is required"),
    phone: Yup.string()
      .required("Please enter your phone number")
      .typeError("This field is required")
      .matches(/^(01){1}[3-9]{1}\d{8}$/, "Enter a valid Mobile Number"),
    message: Yup.string(),
    email: Yup.string()
      .email("Please Enter a valid Email")
      .required("Email field is required"),

    password: Yup.string()
      .min(6, "Password must contain at least 6 characters")
      .required("Enter your password"),
    confirm_password: Yup.string()
      .required("Confirm your password")
      .oneOf([Yup.ref("password")], "Password does not match"),
  });
  const validationSchemaUpdate = Yup.object({
    name: Yup.string().required("Name field is required"),
    role: Yup.string().required("Role field is required"),
    phone: Yup.string()
      .required("Please enter your phone number")
      .typeError("This field is required")
      .matches(/^(01){1}[3-9]{1}\d{8}$/, "Enter a valid Mobile Number"),
    message: Yup.string(),

    email: Yup.string()
      .email("Please Enter a valid Email")
      .required("Email field is required"),
  });
  const initialValues = {
    name: "" || agentData.name,
    phone: "" || agentData.phone,
    email: "" || agentData.email,
    role: "" || agentData.role,
    nid: "" || agentData.nid,
    date_of_birth: agentData.date_of_birth || null,
    password: "",
    confirm_password: "",
  };

  const onSubmit = (values, { resetForm }) => {
    if (id) {
      setLoading(true);
      console.log(values);
      let objToSend = { ...values };
      delete objToSend.password;
      delete objToSend.confirm_password;
      AdminAPI.put(`users/${id}`, objToSend)
        .then((response) => {
          console.log(response);
          console.log(response.data.message);
          setSignedUp(true);
          setSuccessMessage(response.data.message);
          setLoading(false);
          history.push("/admin/user/list");
        })
        .catch((error) => {
          console.log(error.response);
          setLoading(false);

          if (error.response.data.errors !== undefined) {
            setErrorMessage(error.response.data.errors);
          }
        });
    } else {
      setLoading(true);
      console.log(values);

      AdminAPI.post(`users`, values)
        .then((response) => {
          console.log(response);
          console.log(response.data.message);
          alert(response.data.message);
          setSuccessMessage(response.data.message);
          setLoading(false);
          setTimeout(() => {
            history.push("/admin/user/list");
          }, 1000);
        })
        .catch((error) => {
          console.log(error.response);
          setLoading(false);

          if (error.response.data.errors !== undefined) {
            setErrorMessage(error.response.data.errors);
          }
        });
    }

    //
  };
  return (
    <div>
      <div className="container">
        <div className="add-institution">
          <div className="row">
            <div className="col-md-12">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={
                  id ? validationSchemaUpdate : validationSchemaPost
                }
                onSubmit={onSubmit}
              >
                {({ setFieldValue, isValid, dirty }) => (
                  <Form>
                    <div className="form-group row ">
                      <label className="col-sm-3 offset-sm-1 col-form-label">
                        Name
                      </label>
                      <div className="col-sm-7">
                        <Field
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Your Name"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="error-message"
                        />
                        <p className="error-message">{errorMessage.name}</p>
                      </div>
                    </div>
                    <div className="form-group row ">
                      <label className="col-sm-3 offset-sm-1 col-form-label">
                        Phone
                      </label>
                      <div className="col-sm-7">
                        <Field
                          name="phone"
                          type="text"
                          className="form-control"
                          placeholder="Phone/Mobile Number"
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="error-message"
                        />
                        <p className="error-message">{errorMessage.phone}</p>
                      </div>
                    </div>
                    <div className="form-group row ">
                      <label className="col-sm-3 offset-sm-1 col-form-label">
                        Email
                      </label>
                      <div className="col-sm-7">
                        <Field
                          name="email"
                          type="email"
                          className="form-control"
                          placeholder="Email"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error-message"
                        />
                        <p className="error-message">{errorMessage.email}</p>
                      </div>
                    </div>

                    <div className="form-group row ">
                      <label className="col-sm-3 offset-sm-1 col-form-label">
                        Nid
                      </label>
                      <div className="col-sm-7">
                        <Field
                          name="nid"
                          type="text"
                          className="form-control"
                          placeholder="nid"
                        />
                        <ErrorMessage
                          name="nid"
                          component="div"
                          className="error-message"
                        />
                        <p className="error-message">{errorMessage.nid}</p>
                      </div>
                    </div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <div className="form-group row ">
                        <label className="col-sm-3 offset-sm-1">
                          Date of birth
                        </label>
                        <div className="col-sm-7">
                          <Field
                            component={DateOfBirthDatePicker}
                            name="date_of_birth"
                          />
                          <ErrorMessage
                            name="date_of_birth"
                            component="div"
                            className="error-message"
                          />
                          <p className="error-message">
                            {errorMessage.date_of_birth}
                          </p>
                        </div>
                      </div>
                    </MuiPickersUtilsProvider>

                    <div className="form-group row ">
                      <label className="col-sm-3 offset-sm-1 col-form-label">
                        Role
                      </label>
                      <div className="col-sm-7">
                        <Field className="form-control" as="select" name="role">
                          <option value=""></option>

                          <option value={"Moderate"}>Moderate</option>
                        </Field>
                        <ErrorMessage
                          name="role"
                          component="div"
                          className="error-message"
                        />
                        <p className="error-message">{errorMessage.role}</p>
                      </div>
                    </div>

                    {id ? (
                      ""
                    ) : (
                      <React.Fragment>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1 col-form-label ">
                            Password
                          </label>
                          <div className="col-sm-7">
                            <Field
                              name="password"
                              type="password"
                              className="form-control "
                              placeholder="password"
                            />
                            <ErrorMessage
                              name="password"
                              component="div"
                              className="error-message"
                            />
                            <p className="error-message">
                              {errorMessage.password}
                            </p>
                          </div>
                        </div>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1 col-form-label ">
                            Confirm Password
                          </label>
                          <div className="col-sm-7">
                            <Field
                              name="confirm_password"
                              type="password"
                              className="form-control "
                              placeholder="password"
                            />
                            <ErrorMessage
                              name="confirm_password"
                              component="div"
                              className="error-message"
                            />
                            <p className="error-message">
                              {errorMessage.confirm_password}
                            </p>
                          </div>
                        </div>
                      </React.Fragment>
                    )}

                    <div className="form-group row ">
                      <label className="col-sm-5 offset-sm-1">
                        <button
                          disabled={loading || id}
                          // disabled={!isValid || !dirty}
                          type="submit"
                          className="btn btn-lg btn-success"
                        >
                          {loading && <i className="fa fa-refresh fa-spin"></i>}
                          {loading && id && <span>Updating</span>}
                          {loading && !id && <span>Submitting</span>}
                          {!loading && id && <span> Update</span>}
                          {!loading && !id && <span> Submit</span>}
                        </button>
                      </label>
                      <div className="col-sm-7"></div>
                    </div>
                    <div className="form-group row ">
                      <label className="col-sm-11 offset-sm-1">
                        {successMessage}
                      </label>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
