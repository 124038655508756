import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import KeyboardVoiceIcon from "@material-ui/icons/KeyboardVoice";
import Icon from "@material-ui/core/Icon";
import SaveIcon from "@material-ui/icons/Save";
import { Link, useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "./css/global.css";
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

export default function PatientPortal() {
  let history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const routeTo = (route) => {
    history.push(`/patient/${route}`);
  };

  return (
    <div>
      {!isMobile ? (
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-3">
              <div className="img-btn-area">
                <div className="portal-img-card">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/available-doc.png`}
                    alt="banner"
                  />
                </div>
                <div className="portal-btn">
                  <button
                    onClick={() => routeTo("available-doctors")}
                    className="btn portal-linked-btn"
                  >
                    Doctors
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="img-btn-area">
                <div className="portal-img-card">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/Doctor-Appointment.PNG`}
                    alt="banner"
                  />
                </div>
                <div className="portal-btn">
                  <button
                    onClick={() => routeTo("appointments")}
                    className="btn portal-linked-btn"
                  >
                    Appointments
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="img-btn-area">
                <div className="portal-img-card">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/Prescription-Icon.PNG`}
                    alt="banner"
                  />
                </div>
                <div className="portal-btn">
                  <button
                    onClick={() => routeTo("prescriptions")}
                    className="btn portal-linked-btn"
                  >
                    Prescriptions
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-3">
              <div className="img-btn-area">
                <div className="portal-img-card">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/Diagnostic-Test.PNG`}
                    alt="banner"
                  />
                </div>
                <div className="portal-btn">
                  <button
                    onClick={() => routeTo("test/add")}
                    className="btn portal-linked-btn"
                  >
                    Book Test
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="img-btn-area">
                <div className="portal-img-card">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/invoice-icon.png`}
                    alt="banner"
                  />
                </div>
                <div className="portal-btn">
                  <button
                    onClick={() => routeTo("test/test-invoices")}
                    className="btn portal-linked-btn"
                  >
                    Test List
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="img-btn-area">
                <div className="portal-img-card">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/Download-report-icon.png`}
                    alt="banner"
                  />
                </div>
                <div className="portal-btn">
                  <button
                    onClick={() => routeTo("test/report-list")}
                    className="btn portal-linked-btn"
                  >
                    Download Reports
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-3">
              <div className="img-btn-area">
                <div className="portal-img-card">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/Upload-prescription-Icon.PNG`}
                    alt="banner"
                  />
                </div>
                <div className="portal-btn">
                  <button
                    onClick={() => routeTo("test/upload-prescription")}
                    className="btn portal-linked-btn"
                  >
                    Upload Prescription for Test
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="patient-portal">
          <div className="img-btn-area">
            <div className="portal-img-card">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/available-doc.png`}
                alt="banner"
              />
            </div>
            <div className="portal-btn">
              <button
                onClick={() => routeTo("available-doctors")}
                className="btn portal-linked-btn"
              >
                Doctors
              </button>
            </div>
          </div>
          <div className="img-btn-area">
            <div className="portal-img-card">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/Doctor-Appointment.PNG`}
                alt="banner"
              />
            </div>
            <div className="portal-btn">
              <button
                onClick={() => routeTo("appointments")}
                className="btn portal-linked-btn"
              >
                Appointments
              </button>
            </div>
          </div>
          <div className="img-btn-area">
            <div className="portal-img-card">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/Prescription-Icon.PNG`}
                alt="banner"
              />
            </div>
            <div className="portal-btn">
              <button
                onClick={() => routeTo("prescriptions")}
                className="btn portal-linked-btn"
              >
                Prescriptions
              </button>
            </div>
          </div>
          <div className="img-btn-area">
            <div className="portal-img-card">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/Diagnostic-Test.PNG`}
                alt="banner"
              />
            </div>
            <div className="portal-btn">
              <button
                onClick={() => routeTo("test/add")}
                className="btn portal-linked-btn"
              >
                Book Test
              </button>
            </div>
          </div>
          <div className="img-btn-area">
            <div className="portal-img-card">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/invoice-icon.png`}
                alt="banner"
              />
            </div>
            <div className="portal-btn">
              <button
                onClick={() => routeTo("test/test-invoices")}
                className="btn portal-linked-btn"
              >
                Test List
              </button>
            </div>
          </div>
          <div className="img-btn-area">
            <div className="portal-img-card">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/Download-report-icon.png`}
                alt="banner"
              />
            </div>
            <div className="portal-btn">
              <button
                onClick={() => routeTo("test/report-list")}
                className="btn portal-linked-btn"
              >
                Download Reports
              </button>
            </div>
          </div>
          <div className="img-btn-area">
            <div className="portal-img-card">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/Upload-prescription-Icon.PNG`}
                alt="banner"
              />
            </div>
            <div className="portal-btn">
              <button
                onClick={() => routeTo("test/upload-prescription")}
                className="btn portal-linked-btn"
              >
                Upload Prescription for Test
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
