import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { TextField as TextFieldSecond } from "formik-material-ui";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import moment from "moment";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import { DoctorAPI } from "../../AxiosRequest/DoctorAPI";

import LockOpenIcon from "@material-ui/icons/LockOpen";
// import { FormikTextField } from "formik-material-fields";
import * as Yup from "yup";
import Navbar2 from "../Shared/Navbar2";
import axios from "axios";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "@material-ui/pickers";
const FormikDatePicker = ({
  name,
  form: { setFieldValue },
  field: { value },
  ...rest
}) => {
  // console.log(rest);
  return (
    <DatePicker
      disableFuture
      openTo="year"
      format="dd/MM/yyyy"
      views={["year", "month", "date"]}
      name={name}
      keyboard
      clearable
      autoOk
      // handle clearing outside => pass plain array if you are not controlling value outside
      mask={(value) =>
        value
          ? [/[0-3]/, /\d/, "/", /0|1/, /\d/, "/", /1|2/, /\d/, /\d/, /\d/]
          : []
      }
      disableOpenOnEnter
      onChange={(value) => {
        console.log("setting value to", value);
        setFieldValue("date_of_birth", value);
      }}
      value={value}
      animateYearScrolling={false}
    />
  );
};

const DoctorRegistration = () => {
  const [designations, setDesignations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const [degreeList, setDegreeList] = useState([]);
  const [degreeListChosen, setDegreeListChosen] = useState([]);

  const [fileForPreview, setFileForPreview] = useState("");
  const [uploadedFile, setUploadedFile] = useState("");
  const [fileName, setFileName] = useState("Choose an Image File");
  const [showImage, setShowImage] = useState(false);

  const [loading, setLoading] = useState(false);
  const [signedUpMessage, setSignedUpMessage] = useState("");
  const [singnedUp, setSignedUp] = useState(false);

  // Backend Error Messages
  const [nameErros, setNameErrors] = useState([]);
  const [emailError, setEmailError] = useState([]);
  const [phoneErros, setPhoneError] = useState([]);
  const [ageErrors, setAgeErrors] = useState([]);
  const [bmdcErrors, setBMDCErrors] = useState([]);
  const [designationErrors, setDesignationErrors] = useState([]);
  const [departmentErrors, setDepartmentErrors] = useState([]);
  const [institutionErrors, setInstitutionErrors] = useState([]);
  const [degreeErrors, setDegreeErrors] = useState([]);
  const [practiceYearErrors, setPracticeYearErrors] = useState([]);
  const [passigngYearErrors, setPassingYearErrors] = useState([]);
  const [passingInstituitionErrors, setPassingInstituitionErrors] = useState(
    []
  );
  const [newPatientVisitErrors, setNewPatientVisitErrors] = useState([]);
  const [oldPatientVisitErrors, setOldPatientVisitErrors] = useState([]);
  const [reportVisitErrors, setReportVisitErrors] = useState([]);
  const [oldPatientVisitValidity, setOldPatientVisitValidity] = useState([]);
  const [passwordErrors, setPasswordErrors] = useState([]);
  const [passwordConfirmationErrors, setPaswordConfirmationErros] = useState(
    []
  );
  const [photoErrors, setPhotoErrors] = useState([]);
  const [selectedDate, handleDateChange] = useState(new Date());

  const fileHandle = (event) => {
    setFileForPreview(URL.createObjectURL(event.target.files[0]));
    setUploadedFile(event.target.files[0]);
    setFileName(event.target.files[0].name);

    setShowImage(true);
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Name field is required"),
    phone: Yup.string().required("Please enter your phone number"),
    email: Yup.string()
      .email("Please Enter a valid Email")
      .required("Email field is required"),
    password: Yup.string()
      .min(6, "Password must contain at least 6 characters")
      .required("Enter your password"),
    confirm_password: Yup.string()
      .required("Confirm your password")
      .oneOf([Yup.ref("password")], "Password does not match"),
    date_of_birth: Yup.date().required("Date of Birth field is required"),
    designation: Yup.string().required("Designation field is required"),
    department: Yup.string().required("Department field is required"),
    institution: Yup.string().required("Institution field is required"),
    bmdc_number: Yup.string().required("Please enter your BMDC number"),
    passing_institution: Yup.string().required(
      "Passing Institution field is required"
    ),
    // degrees: Yup.string().required("Degree field is required"),
    passing_year: Yup.number()
      .typeError("Passing Year must be a number")
      .required("Passing Year field is required"),
    practice_year: Yup.number()
      .typeError("Practice Year must be a number")
      .required("Practice Year field is required"),
    new_patient_visit: Yup.number()
      .typeError("New Patient Visit field must be a number")
      .required("New Patient Visit field is required"),
    old_patient_visit: Yup.number()
      .typeError("Old Patient Visit field must be a number")
      .required("Old Patient Visit field is required"),
    report_visit: Yup.number()
      .typeError("Report Visit field must be a number")
      .required("Report Visit field is required"),
    old_patient_valid_days: Yup.number()
      .typeError("Old Patient Validity field must be a number")
      .required("Old Patient Validity field is required"),
    acceptTerms: Yup.bool().oneOf(
      [true],
      "Accept Terms & Conditions is required"
    ),
  });
  const initialValues = {
    name: "",
    phone: "",
    email: "",
    password: "",
    confirm_password: "",
    date_of_birth: selectedDate,
    designation: "",
    department: "",
    institution: "",
    bmdc_number: "",
    passing_institution: "",
    // degrees: [],
    passing_year: "",
    practice_year: "",
    new_patient_visit: "",
    old_patient_visit: "",
    report_visit: Number,
    old_patient_valid_days: "",
    acceptTerms: false,
  };

  useEffect(() => {
    getDesignations();
    getDegrees();
    getDepartments();
    getInstutitions();
  }, []);

  const getDesignations = () => {
    axios
      .get("designations")
      .then((response) => {
        setDesignations(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const getDegrees = () => {
    axios
      .get("degrees")
      .then((response) => {
        setDegreeList(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const getDepartments = () => {
    axios
      .get("departments")
      .then((response) => {
        setDepartments(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const getInstutitions = () => {
    axios
      .get("institutions")
      .then((response) => {
        setInstitutions(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    setLoading(true);
    setAgeErrors([]);
    console.log("degree list", degreeListChosen);

    let formData = new FormData();
    formData.append("name", values.name);
    formData.append("phone", values.phone);
    formData.append("email", values.email);
    formData.append("password", values.password);
    formData.append("confirm_password", values.confirm_password);
    formData.append("bmdc_number", values.bmdc_number);
    formData.append(
      "date_of_birth",
      moment(values.date_of_birth).format("YYYY-MM-DD")
    );
    formData.append("degrees", JSON.stringify(degreeListChosen));
    formData.append("department", values.department);
    formData.append("designation", values.designation);
    formData.append("institution", values.institution);
    formData.append("new_patient_visit", values.new_patient_visit);
    formData.append("old_patient_valid_days", values.old_patient_valid_days);
    formData.append("old_patient_visit", values.old_patient_visit);
    formData.append("report_visit", values.report_visit);
    formData.append("passing_institution", values.passing_institution);
    formData.append("passing_year", values.passing_year);
    formData.append("practice_year", values.practice_year);
    formData.append("photo", uploadedFile);

    DoctorAPI.post("auth/register", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        console.log(response);
        setSignedUp(true);
        setSignedUpMessage(
          "You have successfully signed up. Press login to continue"
        );
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response);
        setLoading(false);

        if (error.response.data.errors !== undefined) {
          let nameErrors = error.response.data.errors.name;

          let emailError = error.response.data.errors.email;
          let phoneErrors = error.response.data.errors.phone;
          let birthErrors = error.response.data.errors.date_of_birth;
          let bmdcErrors = error.response.data.errors.bmdc_number;
          let designationErrors = error.response.data.errors.designation;
          let departmentErrors = error.response.data.errors.department;
          let institutionErrors = error.response.data.errors.institution;
          let degreeErrors = error.response.data.errors.degrees;
          let practiceYearErrors = error.response.data.errors.practice_year;
          let passigngYearErrors = error.response.data.errors.passing_year;
          let passingInstituitionErrors =
            error.response.data.errors.passing_institution;
          let newPatientVisitErrors =
            error.response.data.errors.new_patient_visit;
          let oldPatientVisitErrors =
            error.response.data.errors.old_patient_visit;
          let reportVisitErrors = error.response.data.errors.report_visit;
          let oldPatientVisitValidityErrors =
            error.response.data.errors.old_patient_valid_days;
          let passwordErrors = error.response.data.errors.password;
          let confirmPasswordErrors =
            error.response.data.errors.confirm_password;
          let photoErrors = error.response.data.errors.photo;

          if (nameErrors !== undefined) {
            setNameErrors(nameErrors);
          }
          if (emailError !== undefined) {
            setEmailError(emailError);
          }
          if (phoneErrors !== undefined) {
            setPhoneError(phoneErrors);
          }

          if (birthErrors !== undefined) {
            console.log("birthe", birthErrors);
            setAgeErrors(birthErrors);
          }
          if (bmdcErrors !== undefined) {
            setBMDCErrors(bmdcErrors);
          }
          if (designationErrors !== undefined) {
            setDesignationErrors(designationErrors);
          }
          if (departmentErrors !== undefined) {
            setDepartmentErrors(departmentErrors);
          }
          if (institutionErrors !== undefined) {
            setInstitutionErrors(institutionErrors);
          }
          if (degreeErrors !== undefined) {
            setDegreeErrors(degreeErrors);
          }
          if (practiceYearErrors !== undefined) {
            setPracticeYearErrors(practiceYearErrors);
          }
          if (passigngYearErrors !== undefined) {
            setPassingYearErrors(passigngYearErrors);
          }
          if (passingInstituitionErrors !== undefined) {
            setPassingInstituitionErrors(passingInstituitionErrors);
          }
          if (newPatientVisitErrors !== undefined) {
            setNewPatientVisitErrors(newPatientVisitErrors);
          }
          if (oldPatientVisitErrors !== undefined) {
            setOldPatientVisitErrors(oldPatientVisitErrors);
          }
          if (reportVisitErrors !== undefined) {
            setReportVisitErrors(reportVisitErrors);
          }
          if (oldPatientVisitValidityErrors !== undefined) {
            setOldPatientVisitValidity(oldPatientVisitValidityErrors);
          }
          if (passwordErrors !== undefined) {
            setPasswordErrors(passwordErrors);
          }
          if (confirmPasswordErrors !== undefined) {
            setPaswordConfirmationErros(confirmPasswordErrors);
          }

          if (photoErrors !== undefined) {
            setPhotoErrors(photoErrors);
          }
        }
      });
  };

  return (
    <div>
      <Navbar2 />
      <div className="doc-register-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="doc-register-form">
                    <div className="text-center">
                      <LockOpenIcon />
                    </div>
                    <h1 className="text-center">Heal-Holmes</h1>
                    <h3 className="text-center py-2">Doctor Registration</h3>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      {({ setFieldValue, errors, status, touched }) => (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row ">
                                <label className="col-sm-3">Name</label>
                                <div className="col-sm-8">
                                  <Field
                                    name="name"
                                    type="text"
                                    className="form-control"
                                    placeholder="Your Name"
                                  />
                                  <ErrorMessage
                                    name="name"
                                    component="div"
                                    className="error-message"
                                  />
                                  {nameErros.map((item, i) => (
                                    <p className="error-message" key={i}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row ">
                                <label className="col-sm-3">Email</label>
                                <div className="col-sm-8">
                                  <Field
                                    name="email"
                                    type="email"
                                    className="form-control"
                                    placeholder="Email"
                                  />
                                  <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="error-message"
                                  />
                                  {emailError.map((item, i) => (
                                    <p className="error-message" key={i}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row ">
                                <label className="col-sm-3">Phone</label>
                                <div className="col-sm-8">
                                  <Field
                                    name="phone"
                                    type="text"
                                    className="form-control"
                                    placeholder="Phone/Mobile Number"
                                  />
                                  <ErrorMessage
                                    name="phone"
                                    component="div"
                                    className="error-message"
                                  />
                                  {phoneErros.map((item, i) => (
                                    <p className="error-message" key={i}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row ">
                                <label className="col-sm-3">
                                  Date of Birth
                                </label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <div className="col-sm-8">
                                    <Field
                                      component={FormikDatePicker}
                                      name="date_of_birth"
                                    />

                                    <ErrorMessage
                                      name="date_of_birth"
                                      component="div"
                                      className="error-message"
                                    />

                                    {ageErrors.map((item, i) => (
                                      <p className="error-message" key={i}>
                                        {item}
                                      </p>
                                    ))}
                                  </div>
                                </MuiPickersUtilsProvider>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row ">
                                <label className="col-sm-3 ">BMDC Number</label>
                                <div className="col-sm-8">
                                  <Field
                                    name="bmdc_number"
                                    type="text"
                                    className="form-control"
                                    placeholder="BMDC Number"
                                  />
                                  <ErrorMessage
                                    name="bmdc_number"
                                    component="div"
                                    className="error-message"
                                  />
                                  {bmdcErrors.map((item, i) => (
                                    <p className="error-message" key={i}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row ">
                                <label className="col-sm-3">Designation</label>
                                <div className="col-sm-8">
                                  <Autocomplete
                                    freeSolo
                                    id="disabledHelperText"
                                    name="designation"
                                    options={designations}
                                    getOptionLabel={(option) => option.name}
                                    onInputCapture={(event) => {
                                      event.persist(); // As we are accessing the event in asynchronous way.
                                      setFieldValue(
                                        "designation",
                                        event.target.value
                                      );
                                      // console.log(event.target.value);
                                    }}
                                    onChange={(event, value) => {
                                      console.log("value", value);
                                      if (value == null) {
                                        setFieldValue("designation", "");
                                      } else {
                                        setFieldValue(
                                          "designation",
                                          value.name
                                        );
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <Field
                                        component={TextFieldSecond}
                                        {...params}
                                        name="designation"
                                        variant="outlined"
                                        fullWidth
                                      />
                                    )}
                                  />
                                  <ErrorMessage
                                    name="designation"
                                    component="div"
                                    className="error-message"
                                  />
                                  {designationErrors.map((item, i) => (
                                    <p className="error-message" key={i}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row ">
                                <label className="col-sm-3">Department</label>
                                <div className="col-sm-8">
                                  <Autocomplete
                                    freeSolo
                                    id="disabledHelperText"
                                    name="department"
                                    options={departments}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(e, value) => {
                                      if (value == null) {
                                        setFieldValue("department", "");
                                      } else {
                                        setFieldValue("department", value.name);
                                      }
                                    }}
                                    onInputCapture={(event) => {
                                      event.persist(); // As we are accessing the event in asynchronous way.
                                      setFieldValue(
                                        "department",
                                        event.target.value
                                      );
                                      // console.log(event.target.value);
                                    }}
                                    renderInput={(params) => (
                                      <Field
                                        component={TextFieldSecond}
                                        {...params}
                                        name="department"
                                        variant="outlined"
                                        fullWidth
                                      />
                                    )}
                                  />

                                  <ErrorMessage
                                    name="department"
                                    component="div"
                                    className="error-message"
                                  />
                                  {departmentErrors.map((item, i) => (
                                    <p className="error-message" key={i}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row ">
                                <label className="col-sm-3">
                                  Current Institution
                                </label>
                                <div className="col-sm-8">
                                  <Autocomplete
                                    id="disabledHelperText"
                                    freeSolo
                                    options={institutions}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, value) => {
                                      if (value == null) {
                                        console.log(null);
                                      } else {
                                        setFieldValue(
                                          "institution",
                                          value.name
                                        );
                                      }
                                    }}
                                    onInputCapture={(event) => {
                                      event.persist(); // As we are accessing the event in asynchronous way.
                                      setFieldValue(
                                        "institution",
                                        event.target.value
                                      );
                                      // console.log(event.target.value);
                                    }}
                                    renderInput={(params) => (
                                      <Field
                                        component={TextFieldSecond}
                                        {...params}
                                        name="institution"
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                  <ErrorMessage
                                    name="institution"
                                    component="div"
                                    className="error-message"
                                  />
                                  {institutionErrors.map((item, i) => (
                                    <p className="error-message" key={i}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row ">
                                <label className="col-sm-3">Degree</label>
                                <div className="col-sm-8">
                                  <Autocomplete
                                    multiple
                                    id="tags-filled"
                                    options={degreeList.map(
                                      (option) => option.name
                                    )}
                                    // defaultValue={[degreeList[0].name]}
                                    freeSolo
                                    renderTags={(value, getTagProps) => {
                                      setDegreeListChosen(value);

                                      return value.map((option, index) => (
                                        <Chip
                                          variant="outlined"
                                          label={option}
                                          {...getTagProps({ index })}
                                        />
                                      ));
                                    }}
                                    renderInput={(params) => (
                                      <Field
                                        component={TextFieldSecond}
                                        {...params}
                                        name="degress"
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                  <ErrorMessage
                                    name="degress"
                                    component="div"
                                    className="error-message"
                                  />
                                  {degreeErrors.map((item, i) => (
                                    <p className="error-message" key={i}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row ">
                                <label className="col-sm-3 ">
                                  Practicing Year
                                </label>
                                <div className="col-sm-8">
                                  <Field
                                    name="practice_year"
                                    type="text"
                                    className="form-control"
                                    placeholder="Practice Year"
                                  />
                                  <ErrorMessage
                                    name="practice_year"
                                    component="div"
                                    className="error-message"
                                  />
                                  {practiceYearErrors.map((item, i) => (
                                    <p className="error-message" key={i}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row ">
                                <label className="col-sm-3 ">
                                  Passing Year
                                </label>
                                <div className="col-sm-8">
                                  <Field
                                    name="passing_year"
                                    type="text"
                                    className="form-control"
                                    placeholder="Passing Year"
                                  />
                                  <ErrorMessage
                                    name="passing_year"
                                    component="div"
                                    className="error-message"
                                  />
                                  {passigngYearErrors.map((item, i) => (
                                    <p className="error-message" key={i}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row ">
                                <label className="col-sm-3 ">
                                  Passing Institution
                                </label>
                                <div className="col-sm-8">
                                  <Autocomplete
                                    id="disabledHelperText"
                                    freeSolo
                                    options={institutions}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, value) => {
                                      if (value == null) {
                                        console.log(null);
                                      } else {
                                        setFieldValue(
                                          "passing_institution",
                                          value.name
                                        );
                                      }
                                    }}
                                    onInputCapture={(event) => {
                                      event.persist(); // As we are accessing the event in asynchronous way.
                                      setFieldValue(
                                        "passing_institution",
                                        event.target.value
                                      );
                                      // console.log(event.target.value);
                                    }}
                                    renderInput={(params) => (
                                      <Field
                                        component={TextFieldSecond}
                                        {...params}
                                        name="passing_institution"
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                  <ErrorMessage
                                    name="passing_institution"
                                    component="div"
                                    className="error-message"
                                  />
                                  {passingInstituitionErrors.map((item, i) => (
                                    <p className="error-message" key={i}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row ">
                                <label className="col-sm-3 ">
                                  New Patient Visit
                                </label>
                                <div className="col-sm-8">
                                  <Field
                                    name="new_patient_visit"
                                    type="text"
                                    className="form-control "
                                    placeholder="Amount (Taka)"
                                  />
                                  <ErrorMessage
                                    name="new_patient_visit"
                                    component="div"
                                    className="error-message"
                                  />
                                  {newPatientVisitErrors.map((item, i) => (
                                    <p className="error-message" key={i}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row ">
                                <label className="col-sm-3 ">
                                  Old Patient Visit
                                </label>
                                <div className="col-sm-8">
                                  <Field
                                    name="old_patient_visit"
                                    type="text"
                                    className="form-control "
                                    placeholder="Amount (Taka)"
                                  />
                                  <ErrorMessage
                                    name="old_patient_visit"
                                    component="div"
                                    className="error-message"
                                  />
                                  {oldPatientVisitErrors.map((item, i) => (
                                    <p className="error-message" key={i}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group row ">
                                <label className="col-sm-3 ">
                                  Old Patient Validity
                                </label>
                                <div className="col-sm-8">
                                  <Field
                                    name="old_patient_valid_days"
                                    type="text"
                                    className="form-control "
                                    placeholder="Days"
                                  />
                                  <ErrorMessage
                                    name="old_patient_valid_days"
                                    component="div"
                                    className="error-message"
                                  />
                                  {oldPatientVisitValidity.map((item, i) => (
                                    <p className="error-message" key={i}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row ">
                                <label className="col-sm-3 ">
                                  Report Visit
                                </label>
                                <div className="col-sm-8">
                                  <Field
                                    name="report_visit"
                                    type="text"
                                    className="form-control "
                                    placeholder="Amount (Taka)"
                                  />
                                  <ErrorMessage
                                    name="report_visit"
                                    component="div"
                                    className="error-message"
                                  />
                                  {reportVisitErrors.map((item, i) => (
                                    <p className="error-message" key={i}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row ">
                                <label className="col-sm-3 ">Password</label>
                                <div className="col-sm-8">
                                  <Field
                                    name="password"
                                    type="password"
                                    className="form-control "
                                    placeholder="password"
                                  />
                                  <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="error-message"
                                  />
                                  {passwordErrors.map((item, i) => (
                                    <p className="error-message" key={i}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row ">
                                <label className="col-sm-3 ">
                                  Confirm Password
                                </label>
                                <div className="col-sm-8">
                                  <Field
                                    name="confirm_password"
                                    type="password"
                                    className="form-control "
                                    placeholder="password"
                                  />
                                  <ErrorMessage
                                    name="confirm_password"
                                    component="div"
                                    className="error-message"
                                  />
                                  {passwordConfirmationErrors.map((item, i) => (
                                    <p className="error-message" key={i}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row ">
                                <label className="col-sm-3 ">
                                  Upload Your Photo
                                </label>
                                <div className="col-sm-8">
                                  <div className="custom-file">
                                    <input
                                      type="file"
                                      className="custom-file-input"
                                      id="customFile"
                                      accept="image/*"
                                      onChange={fileHandle}
                                    />
                                    <label
                                      className="custom-file-label"
                                      htmlFor="customFile"
                                    >
                                      {fileName}
                                    </label>
                                  </div>

                                  {photoErrors.map((item, i) => (
                                    <p className="error-message" key={i}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6"></div>
                          </div>
                          <div className="row">
                            <div className="col-md-10">
                              <div className="form-group row ">
                                <label className="col-sm-3"></label>
                                <div className="col-sm-8">
                                  {showImage ? (
                                    <div className="uploaded-image-section">
                                      <img
                                        src={fileForPreview}
                                        className="uploadedImage"
                                        alt="uploadedImage"
                                      />
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="form-group row ">
                            <div className="col-sm-8 offset-1">
                              <Field
                                type="checkbox"
                                name="acceptTerms"
                                className={
                                  "form-check-input " +
                                  (errors.acceptTerms && touched.acceptTerms
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <label
                                htmlFor="acceptTerms"
                                className="form-check-label"
                              >
                                I declare that I am a BMDC registered doctor and
                                all the information given above are correct
                              </label>
                            </div>
                          </div>

                          <div className="form-group row ">
                            <label className="col-sm-5 offset-sm-1">
                              <button
                                disabled={loading}
                                // disabled={!isValid || !dirty}
                                type="submit"
                                className="btn btn-lg btn-success"
                              >
                                {loading && (
                                  <i className="fa fa-refresh fa-spin"></i>
                                )}
                                {loading && <span>Signing Up</span>}
                                {!loading && <span> Sign Up</span>}
                              </button>
                            </label>
                            <div className="col-sm-7"></div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-11 offset-sm-1">
                              {signedUpMessage}
                              {singnedUp && (
                                <Link
                                  to="/doctor/login"
                                  type="button"
                                  className="btn btn-lg btn-primary"
                                >
                                  Login
                                </Link>
                              )}
                            </label>
                          </div>
                          <div className="form-group row ">
                            <div className="col-sm-8 offset-sm-1">
                              Already have an account? Login
                              <Link
                                className="px-1"
                                type="button"
                                to="/doctor/login"
                              >
                                here
                              </Link>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorRegistration;
