import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  // useHistory,

  useParams,
} from "react-router-dom";
import { Link } from "react-router-dom";

import * as Yup from "yup";
import { Layout } from "antd";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";

const { Content } = Layout;

export default function BankAccountForm() {
  // let history = useHistory();
  let { id } = useParams();
  const [open, setOpen] = useState(false);
  const [erroropen, setErrorOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState({});
  const [accountData, setAccountData] = useState({});
  // Backend Errors Handle
  const [backendErrorMessage, setBackendErrorMessage] = useState({});

  useEffect(() => {
    if (id) {
      getAccountDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAccountDetails = () => {
    AdminAPI.get(`bank-accounts/${id}`)
      .then((response) => {
        console.log(response.data);
        if (response.data) {
          setAccountData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const validationSchema = Yup.object({
    bank: Yup.string().required("This field is required"),
    account_number: Yup.string().required("This field is required"),
    type: Yup.string().required("This field is required")
  });
  const initialValues = {
    bank: accountData.bank || "",
    account_number: accountData.account_number || "",
    type: accountData.type || "",
  };

  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    if (id) {
      AdminAPI.put(`bank-accounts/${id}`, values)
        .then((response) => {
          console.log(response);
          if (response.data.message) {
            setSuccessMessage(response.data.message);
            setOpen(true);
            setErrorOpen(false);
          }
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response.data.errors) {
            setErrorMessage(error.response.data.errors);
            setErrorOpen(true);
            setOpen(false);
          }

          if (error.response.data.message) {
            setErrorMessage(error.response.data.message);
            setErrorOpen(true);
            setOpen(false);
          }
          if (error.response.data.errors !== undefined) {
            setBackendErrorMessage(error.response.data.errors);
          }
        });
    } else {
      AdminAPI.post("bank-accounts", values)
        .then((response) => {
          console.log(response);
          if (response.data.message) {
            setSuccessMessage(response.data.message);
            setOpen(true);
            setErrorOpen(false);
          }
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response.data.errors) {
            setErrorMessage(error.response.data.errors);
            setErrorOpen(true);
            setOpen(false);
          }

          if (error.response.data.message) {
            setErrorMessage(error.response.data.message);
            setErrorOpen(true);
            setOpen(false);
          }

          if (error.response.data.errors !== undefined) {
            setBackendErrorMessage(error.response.data.errors);
          }
        });
    }
  };
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-right">
            <Link
              to="/admin/bank-account/list"
              type="button"
              className="btn btn-sm btn-success"
            >
              Bank Account List
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-md-12">
                <div className="add-institution">
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({ setFieldValue, isValid, dirty }) => (
                      <Form>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1">Bank</label>
                          <div className="col-sm-7">
                            <Field
                              className=" form-control"
                              type="text"
                              name="bank"
                            />
                            <ErrorMessage
                              name="bank"
                              component="div"
                              className="error-message"
                            />

                            <p className="error-message">
                              {backendErrorMessage.bank}
                            </p>
                          </div>
                        </div>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1">Account no</label>
                          <div className="col-sm-7">
                            <Field
                              className=" form-control"
                              type="text"
                              name="account_number"
                            />
                            <ErrorMessage
                              name="account_number"
                              component="div"
                              className="error-message"
                            />

                            <p className="error-message">
                              {backendErrorMessage.account_number}
                            </p>
                          </div>
                        </div>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1">Type</label>
                          <div className="col-sm-7">
                            <Field
                              className=" form-control"
                              type="text"
                              name="type"
                            />
                            <ErrorMessage
                              name="type"
                              component="div"
                              className="error-message"
                            />

                            <p className="error-message">
                              {backendErrorMessage.type}
                            </p>
                          </div>
                        </div>
                        
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1"></label>
                          <div className="col-sm-7 text-right">
                            <button
                              type="submit"
                              className="btn btn-lg btn-success"
                            >
                              {id ? "Update" : "Submit"}
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
