import React, { useState, useEffect } from "react";
import { DiagnosticAPI } from "../../../AxiosRequest/DiagnosticAPI";
import Pagination from "@material-ui/lab/Pagination";
import { useHistory } from "react-router-dom";
import { Layout } from "antd";
import _ from "lodash";
const { Content, Footer } = Layout;

export default function TestList() {
  let history = useHistory();
  const [testList, setTestList] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);

  const [params, setParams] = useState({
    page: 1,
    per_page: 15,
  });

  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };

  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);

  useEffect(() => {
    DiagnosticAPI.get(`/tests`, { params: params })
      .then((response) => {
        setTestList(response.data.data.data);
        setTotalPage(response.data.data.last_page);
        setPage(response.data.data.current_page);
      })
      .catch((error) => {});
  }, [params]);

  const routeAddTest = () => {
    history.push(`/diagnostic/tests/add`);
  };

  return (
    <div>
      <Layout style={{ minHeight: "100vh" }}>
        <Layout className="site-layout">
          <Content style={{ margin: "0 16px" }}>
            <div
              className="site-layout-background"
              style={{ padding: 24, minHeight: 22 }}
            >
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-4 form-group">
                    <select
                      className="form-control"
                      onChange={(e) => {
                        handleColumnWiseSearch(e.target.value, "status");
                      }}
                    >
                      <option value="">All</option>
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </select>
                  </div>
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      onChange={(e) =>
                        handleColumnWiseSearch(e.target.value, "search")
                      }
                      className="form-control"
                      placeholder="Search"
                    />
                  </div>
                  <div className="col-md-2 text-right">
                    <button
                      onClick={() => {
                        routeAddTest();
                      }}
                      className="btn btn-outline-secondary"
                    >
                      Add Test
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mt-4">
                    <div className=" table-responsive">
                      <table className="table table-hover">
                        <thead className="thead-dark">
                          <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Name</th>
                            <th scope="col">Department</th>
                            <th scope="col">Price</th>
                            <th scope="col">Discount</th>
                            <th scope="col">Discount Price</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {testList.map((test, i) => (
                            <tr key={i}>
                              <td>{test.unique_id}</td>
                              <td>{test.name}</td>
                              <td>{test.department}</td>
                              <td>{Math.ceil(test.price)}</td>
                              <td>
                                {test.discount}
                                {test.discount_type == "%" ? "%" : " Tk"}
                              </td>
                              <td>
                                {test.discount_type === "%"
                                  ? Math.ceil(
                                      test.price -
                                        (test.price * test.discount) / 100
                                    )
                                  : Math.ceil(test.price - test.discount)}
                              </td>
                              <td>{test.status}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <Pagination
                color="primary"
                count={totalPage}
                page={page}
                onChange={handleChange}
              />
            </div>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            {/* Ant Design ©2018 Created by Ant UED */}
          </Footer>
        </Layout>
      </Layout>
    </div>
  );
}
