import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import * as moment from "moment";

import { AdminAPI } from "../../../AxiosRequest/AdminAPI";

export default function ViewPrescriptions() {
  let history = useHistory();
  let { presId } = useParams();
  const [prescription, setPrescription] = useState({});
  const [path, setPath] = useState();

  useEffect(() => {
    getPatientDetails();
  }, []);

  const routeToAddPrescription = (id) => {
    history.push(`/admin/prescription/add/${id}`);
  };

  const getPatientDetails = () => {
    AdminAPI.get(`uploaded-prescriptions/${presId}`)
      .then((response) => {
        setPrescription(response.data.data);
        setPath(response.data.path);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  return (
    <div>
      <div className="container">
        <div className="d-flex justify-content-lg-end justify-content-sm-start mt-1">
          <button
            className="btn btn-lg btn-outline-secondary"
            onClick={() => {
              routeToAddPrescription(prescription.id);
            }}
          >
            Add Prescription
          </button>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="uploaded-prescriptions">
              <img
                src={
                  `${process.env.REACT_APP_PUBLIC_URL}${path}/${moment(prescription.created_at).format("YYYY-MM-DD")}/` +
                  prescription.file
                }
                width="100%"
                alt="pres"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
