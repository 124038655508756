import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import axios from "axios";
import Icon from "@material-ui/core/Icon";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getDayName } from "../../utils/helpers";
import Footer from "../Shared/Footer";
import Navbar2 from "../Shared/Navbar2";
import "./css/home.css";
import "./css/login.css";
import YoutubeEmbed from "./YoutubeEmbed";
export default function DoctorDetailsLogin() {
  const [doctorSchedule, setDoctorSchedule] = useState([]);
  const [doctorID, setDoctorID] = useState("");
  const [scheduleMessage, setScheduleMessage] = useState("");
  let { docId } = useParams();
  const [doctor, setDoctor] = useState({});
  const [photoPath, setPhotoPath] = useState({});
  const [open, setOpen] = useState(false);
  const [selectedDate, handleDateChange] = useState(new Date());
  const [selectedAppointment, setSelectedAppointment] = useState({});
  const [availableSlot, setAvailableSlot] = useState([]);
  const [bookedSlot, setBookedSlot] = useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [files, setFiles] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [checkedSlot, setCheckedSlot] = useState("");

  let history = useHistory();

  useEffect(() => {
    checkAvailableDate();
  }, [selectedDate]);
  useEffect(() => {
    if (moment(selectedDate).format("dddd") === selectedAppointment.day) {
      checkAvailableDate();
    }
  }, [selectedAppointment]);

  const checkAvailableDate = () => {
    axios
      .get(`doctors/${docId}/schedules`, {
        params: {
          appointment_time: moment(selectedDate).format("YYYY-MM-DD"),
        },
      })
      .then((response) => {
        console.log("response", response.data);
        let da = response.data.patient_appointments;

        let newArr = da.map((arr, index) => {
          return moment(arr.appointment_time).format("HH:mm:ss");
        });

        setBookedSlot(newArr);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const uploadMultipleFiles = (e) => {
    const fileList = Array.from(e.target.files);
    if (checkMimeType(e)) {
      setFiles(fileList);
      const mappedFiles = fileList.map((file) => ({
        ...file,
        preview: URL.createObjectURL(file),
      }));

      setPreviews(mappedFiles);
      // if return true allow to setState
    }
  };

  // const maxSelectFile = (event) => {
  //   let files = event.target.files; // create file object
  //   if (files.length > 5) {
  //     const msg = "Only 5 images can be uploaded at a time";
  //     event.target.value = null; // discard selected file
  //     alert(msg);
  //     return false;
  //   }
  //   return true;
  // };

  const checkMimeType = (event) => {
    //getting file object
    let files = event.target.files;
    //define message container
    let err = "";
    // list allow mime type
    const types = ["image/png", "image/jpeg", "image/gif", "application/pdf"];
    // loop access array
    for (var x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every((type) => files[x].type !== type)) {
        // create error message and assign to container
        err += files[x].type + " is not a supported format\n";
      }
    }

    if (err !== "") {
      // if message not same old that mean has error
      event.target.value = null; // discard selected file
      alert(err);
      return false;
    }
    return true;
  };
  // const checkFileSize = (event) => {
  //   let files = event.target.files;
  //   let size = 150000;
  //   let err = "";
  //   for (var x = 0; x < files.length; x++) {
  //     if (files[x].size > size) {
  //       err += files[x].type + "is too large, please pick a smaller file\n";
  //     }
  //   }
  //   if (err !== "") {
  //     event.target.value = null;
  //     alert(err);
  //     return false;
  //   }

  //   return true;
  // };

  useEffect(() => {
    GetDoctorSchedule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bookAppointment = (doc) => {
    console.log("dddddsoc", doc);

    let timeDiff = moment(doc.end_time, "HH:mm:ss").diff(
      moment(doc.start_time, "HH:mm:ss"),
      "minutes"
    );

    var date = moment(doc.start_time, "h:mm:ss");

    // console.log(moment(count, "h:mm:ss").add(2, "minutes"));

    let i;
    // var number = 0;
    // var date;
    let bbb = moment(date, "h:mm:ss");
    let arr = [date.format("HH:mm:ss")];

    for (i = 0; i < timeDiff / 20 - 1; i++) {
      let added = moment(bbb, "h:mm:ss").add(20, "minutes");
      bbb = added;
      arr.push(moment(added).format("HH:mm:ss"));

      // setCount(bbb);
    }

    setAvailableSlot(arr);

    setSelectedAppointment(doc);
    setDoctorID(doc.id);
    setOpenModal(true);
  };
  useEffect(() => {
    getDocDetails();
  }, []);

  const getDocDetails = () => {
    axios
      .get(`doctors/${docId}`)
      .then((response) => {
        console.log("doc details===>", response.data);
        setDoctor(response.data.data);
        setPhotoPath(response.data.path);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const GetDoctorSchedule = () => {
    // let url = `http://localhost:3001/purchase_order/${paramID}`;

    axios
      .get(`doctors/${docId}/schedules`)
      .then((response) => {
        console.log("response schedules====>", response.data.data);

        setDoctorSchedule(response.data.data.data);
        if (response.data.data.data.length === 0) {
          setScheduleMessage("No Schedule Available");
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const confirm = () => {
    // let url = `http://localhost:3001/purchase_order/${paramID}`;
    localStorage.setItem("docBooked", JSON.stringify(docId));
    history.push("/patient/register");
  };

  return (
    <div>
      {" "}
      <Navbar2 />
      <div className="login-section-doc-login">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              {/* <PatientLogin hideNavBar={true} /> */}
              <div style={{ marginTop: "1rem" }} className="card-area-middle">
                <div
                  style={{ background: "#fff", padding: "10px 0px" }}
                  className="card"
                >
                  <div className="row grid-view">
                    <div className="col-5">
                      {/* <div style={{ left: "55%" }} className="grid-img-title"> */}
                      <div className="card-doc-image">
                        {docId === "6" && (
                          <img
                            className="card-img-top-d"
                            src={`${process.env.REACT_APP_PUBLIC_URL}${photoPath}/${doctor.photo}`}
                            alt="Modhurima Saha"
                          />
                        )}

                        {docId !== "6" && docId !== "7" && (
                          <img
                            className="card-img-top-d"
                            src={`${process.env.REACT_APP_PUBLIC_URL}${photoPath}/${doctor.photo}`}
                            alt="Dr "
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-7">
                      <div className="grid-details">
                        <h4 className="doc-name-title">{doctor.name}</h4>
                        {doctor.doctor_degrees !== undefined && (
                          <p>
                            {doctor.doctor_degrees.length === 0 && (
                              <span style={{ visibility: "hidden" }}>Some</span>
                            )}
                            {doctor.doctor_degrees.map((degree, i) => (
                              <span key={i}>
                                {degree.degree}

                                {i === doctor.doctor_degrees.length - 1
                                  ? ""
                                  : ","}
                              </span>
                            ))}
                          </p>
                        )}

                        <div className="doc-bottom-area">
                          <p>{doctor.designation}</p>
                          <p>{doctor.department}</p>
                          <p>{doctor.institution}</p>
                          <h5
                            style={{
                              textTransform: "uppercase",
                              textAlign: "start",
                            }}
                            className="card-title doc-dep"
                          >
                            {doctor.department}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-4"> </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <h1 className="video-tut">
                    How to get Doctor Appointment and Join Telemedicine Video
                    Call{" "}
                  </h1>
                  <YoutubeEmbed embedId="wHSwJkA3DTo" />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12 mt-4">
                  {doctorSchedule.length !== 0 && (
                    <div className="">
                      {doctorSchedule.filter(
                        (item) => item.day_name === "Friday"
                      ).length > 0 && (
                        <div className="row azas">
                          <div className="col-md-3 d-flex justify-content-center align-items-center">
                            <div>Friday </div>
                          </div>
                          <div className="col-md-9">
                            {" "}
                            <div className="doc_slot">
                              {doctorSchedule
                                .filter((item, i) => item.day_name === "Friday")
                                .map((doc, i) => (
                                  <button
                                    onClick={() => bookAppointment(doc)}
                                    className="btn btn-sm btn-info"
                                  >
                                    {moment(doc.start_time, "hh:mm A").format(
                                      "hh:mm A"
                                    )}{" "}
                                    -{" "}
                                    {moment(doc.end_time, "hh:mm A").format(
                                      "hh:mm A"
                                    )}{" "}
                                  </button>
                                ))}
                            </div>
                          </div>
                        </div>
                      )}
                      {doctorSchedule.filter(
                        (item) => item.day_name === "Saturday"
                      ).length > 0 && (
                        <div className="row mt-2">
                          <div className="col-md-3 d-flex justify-content-center align-items-center">
                            <div>Saturday </div>
                          </div>
                          <div className="col-md-9">
                            {" "}
                            <div className="doc_slot">
                              {doctorSchedule
                                .filter(
                                  (item, i) => item.day_name === "Saturday"
                                )
                                .map((doc, i) => (
                                  <button
                                    onClick={() => bookAppointment(doc)}
                                    className="btn btn-sm btn-info"
                                  >
                                    {moment(doc.start_time, "hh:mm A").format(
                                      "hh:mm A"
                                    )}{" "}
                                    -{" "}
                                    {moment(doc.end_time, "hh:mm A").format(
                                      "hh:mm A"
                                    )}{" "}
                                  </button>
                                ))}
                            </div>
                          </div>
                        </div>
                      )}
                      {doctorSchedule.filter(
                        (item) => item.day_name === "Sunday"
                      ).length > 0 && (
                        <div className="row mt-2">
                          <div className="col-md-3 d-flex justify-content-center align-items-center">
                            <div>Sunday </div>
                          </div>
                          <div className="col-md-9">
                            {" "}
                            <div className="doc_slot">
                              {doctorSchedule
                                .filter((item, i) => item.day_name === "Sunday")
                                .map((doc, i) => (
                                  <button
                                    onClick={() => bookAppointment(doc)}
                                    className="btn btn-sm btn-info"
                                  >
                                    {moment(doc.start_time, "hh:mm A").format(
                                      "hh:mm A"
                                    )}{" "}
                                    -{" "}
                                    {moment(doc.end_time, "hh:mm A").format(
                                      "hh:mm A"
                                    )}{" "}
                                  </button>
                                ))}
                            </div>
                          </div>
                        </div>
                      )}
                      {doctorSchedule.filter(
                        (item) => item.day_name === "Monday"
                      ).length > 0 && (
                        <div className="row mt-2">
                          <div className="col-md-3 d-flex justify-content-center align-items-center">
                            <div>Monday </div>
                          </div>
                          <div className="col-md-9">
                            {" "}
                            <div className="doc_slot">
                              {doctorSchedule
                                .filter((item, i) => item.day_name === "Monday")
                                .map((doc, i) => (
                                  <button
                                    onClick={() => bookAppointment(doc)}
                                    className="btn btn-sm btn-info"
                                  >
                                    {moment(doc.start_time, "hh:mm A").format(
                                      "hh:mm A"
                                    )}{" "}
                                    -{" "}
                                    {moment(doc.end_time, "hh:mm A").format(
                                      "hh:mm A"
                                    )}{" "}
                                  </button>
                                ))}
                            </div>
                          </div>
                        </div>
                      )}
                      {doctorSchedule.filter(
                        (item) => item.day_name === "Tuesday"
                      ).length > 0 && (
                        <div className="row mt-2">
                          <div className="col-md-3 d-flex justify-content-center align-items-center">
                            <div>Tuesday </div>
                          </div>
                          <div className="col-md-9">
                            {" "}
                            <div className="doc_slot">
                              {doctorSchedule
                                .filter(
                                  (item, i) => item.day_name === "Tuesday"
                                )
                                .map((doc, i) => (
                                  <button
                                    onClick={() => bookAppointment(doc)}
                                    className="btn btn-sm btn-info"
                                  >
                                    {moment(doc.start_time, "hh:mm A").format(
                                      "hh:mm A"
                                    )}{" "}
                                    -{" "}
                                    {moment(doc.end_time, "hh:mm A").format(
                                      "hh:mm A"
                                    )}{" "}
                                  </button>
                                ))}
                            </div>
                          </div>
                        </div>
                      )}
                      {doctorSchedule.filter(
                        (item) => item.day_name === "Wednesday"
                      ).length > 0 && (
                        <div className="row mt-2">
                          <div className="col-md-3 d-flex justify-content-center align-items-center">
                            <div>Wednesday </div>
                          </div>
                          <div className="col-md-9">
                            {" "}
                            <div className="doc_slot">
                              {doctorSchedule
                                .filter(
                                  (item, i) => item.day_name === "Wednesday"
                                )
                                .map((doc, i) => (
                                  <button
                                    onClick={() => bookAppointment(doc)}
                                    className="btn btn-sm btn-info"
                                  >
                                    {moment(doc.start_time, "hh:mm A").format(
                                      "hh:mm A"
                                    )}{" "}
                                    -{" "}
                                    {moment(doc.end_time, "hh:mm A").format(
                                      "hh:mm A"
                                    )}{" "}
                                  </button>
                                ))}
                            </div>
                          </div>
                        </div>
                      )}
                      {doctorSchedule.filter(
                        (item) => item.day_name === "Thursday"
                      ).length > 0 && (
                        <div className="row mt-2">
                          <div className="col-md-3 d-flex justify-content-center align-items-center">
                            <div>Thursday </div>
                          </div>
                          <div className="col-md-9">
                            {" "}
                            <div className="doc_slot">
                              {doctorSchedule
                                .filter(
                                  (item, i) => item.day_name === "Thursday"
                                )
                                .map((doc, i) => (
                                  <button
                                    onClick={() => bookAppointment(doc)}
                                    className="btn btn-sm btn-info"
                                  >
                                    {moment(doc.start_time, "hh:mm A").format(
                                      "hh:mm A"
                                    )}{" "}
                                    -{" "}
                                    {moment(doc.end_time, "hh:mm A").format(
                                      "hh:mm A"
                                    )}{" "}
                                  </button>
                                ))}
                            </div>
                          </div>
                        </div>
                      )}

                      <div className=" table-responsive">
                        {/* <table className="table table-sm table-striped table-bordered custom-sm">
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col">Day</th>
                              <th scope="col">Start Time</th>
                              <th scope="col">End Time</th>
                              <th scope="col">Location</th>
                              <th scope="col">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {doctorSchedule.map((doc, i) => (
                              <tr key={i}>
                                <td>{getDayName(doc.day)}</td>
                                <td>
                                  {moment(doc.start_time, "hh:mm A").format(
                                    "hh:mm A"
                                  )}
                                </td>
                                <td>
                                  {moment(doc.end_time, "hh:mm A").format(
                                    "hh:mm A"
                                  )}
                                </td>
                                <td>{doc.location}</td>

                                <td>
                                  <button
                                    onClick={() => bookAppointment(doc)}
                                    className="btn btn-sm btn-primary"
                                  >
                                    Book Appointment
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table> */}
                      </div>
                    </div>
                  )}

                  <div className="text-center">
                    <h1> {scheduleMessage}</h1>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Dialog
                      open={openModal}
                      onClose={() => setOpenModal(false)}
                      fullWidth={true}
                      maxWidth="sm"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Choose Date"}
                      </DialogTitle>
                      <DialogContent>
                        <DatePicker
                          autoOk
                          clearable
                          disablePast
                          format="dd/MM/yyyy"
                          value={selectedDate}
                          onChange={handleDateChange}
                        />
                        {moment(selectedDate).format("dddd") !==
                        selectedAppointment.day_name ? (
                          <p style={{ color: "red" }}>
                            Choose a date with {selectedAppointment.day_name}
                          </p>
                        ) : (
                          <div>
                            <p>You have choosen a valid date</p>
                            <div className="slot-area">
                              {availableSlot.map((slot, index) => (
                                <button
                                  onClick={() => setCheckedSlot(slot)}
                                  disabled={bookedSlot.includes(slot)}
                                  // disabled={bookedSlot.some((el) =>
                                  //   availableSlot.includes(el)
                                  // )}
                                  className={
                                    bookedSlot.includes(slot)
                                      ? "btn btn-sm btn-warning"
                                      : "btn btn-sm btn-success"
                                  }
                                  key={index}
                                >
                                  {moment(slot, "hh:mm A").format("hh:mm A")}
                                </button>
                              ))}
                            </div>
                            {checkedSlot !== "" && (
                              <p className="my-2">
                                Selected Slot :{" "}
                                <span style={{ fontWeight: "bold" }}></span>{" "}
                                {moment(checkedSlot, "hh:mm A").format(
                                  "hh:mm A"
                                )}
                              </p>
                            )}
                          </div>
                        )}

                        <form>
                          <div className="form-group multi-preview">
                            {(previews || []).map((url, key) => (
                              <img key={key} src={url.preview} alt="..." />
                            ))}
                          </div>
                          <p>You can upload your report files below </p>

                          <div className="form-group">
                            <input
                              type="file"
                              className="form-control"
                              onChange={uploadMultipleFiles}
                              multiple
                            />
                          </div>
                          {/* <button
                      type="button"
                      className="btn btn-danger btn-block"
                      onClick={uploadFiles}
                    >
                      Upload
                    </button> */}
                        </form>

                        <div className="py-3"></div>
                        <div className="py-3"></div>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => setOpenModal(false)}
                          color="primary"
                        >
                          Cancel
                        </Button>
                        <Button
                          disabled={
                            moment(selectedDate).format("dddd") !==
                            selectedAppointment.day_name
                          }
                          onClick={() => confirm()}
                          color="primary"
                        >
                          Confirm Appointment
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
