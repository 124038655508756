import React from "react";
import { Route, Switch } from "react-router-dom";

import "./App.scss";
import doctorRegistration from "./components/Doctor/doctorRegistration";

import Home2 from "./components/Customer/Home2";
import Dashboard from "./components/Doctor/Dashboard";
import Login from "./components/Customer/Login";
import { ProtectedRoute } from "./routingRules/protectedRoute";
import { PatientProtectedRoute } from "./routingRules/protectedPatientRoute";
import { DiagnosticProtectedRoute } from "./routingRules/protectedDiagnosticRoute";
import { PharmacyProtectedRoute } from "./routingRules/protectedPharmacyRoute";
import { AdminProtectedRoute } from "./routingRules/protectedAdminRoute";
import { AgentProtectedRoute } from "./routingRules/protectedAgentRoute";
import { CorporateProtectedRoute } from "./routingRules/protectedCorporateRoute";
import BasicExample from "./components/Shared/BasicExample";

import PatientRegistration from "./components/Patient/PatientRegistration";
import PatientLogin from "./components/Patient/PatientLogin";
import PatientDashboard from "./components/Patient/PatientDashboard";
import DiagnosticDashboard from "./components/Diagnostic/DiagnosticDashboard";

import AdminLogin from "./components/Admin/AdminLogin";
import AdminDashboard from "./components/Admin/AdminDashboard";
import AdminRegistration from "./components/Admin/AdminRegistration";

// import AddPrescription from "./components/Doctor/AddPrescription";

import DashboardTwo from "./components/Dashboard/Dashboard";
// import VideoConference from "./components/Doctor/VideoConference";
import MaterialTab from "./components/Doctor/MaterialTab";
import DiagnosticRegistration from "./components/Diagnostic/DiagnosticRegistration";
import DiagnosticLogin from "./components/Diagnostic/DiagnosticLogin";
import PharmacyLogin from "./components/Pharmacy/PharmacyLogin";
import PharmacyRegistration from "./components/Pharmacy/PharmacyRegistration";
import PharmacyDashboard from "./components/Pharmacy/PharmacyDashboard";
import VideoConference from "./components/Doctor/VideoConference";
import DoctorDetailsLogin from "./components/Customer/DoctorDetailsLogin";
import DoctorListHome from "./components/Customer/DoctorListHome";
import TestListHome from "./components/Customer/TestListHome";
import TestDetailsHome from "./components/Customer/TestDetailsHome";
import SuccessMessage from "./components/Customer/SuccessMessage";
import AgentDashboard from "./components/Agents/AgentDashboard";
import AgentRegistration from "./components/Agents/AgentRegistration";
import AgentLogin from "./components/Agents/AgentLogin";
import CorporateLogin from "./components/Corporate/CorporateLogin";
import CorporateDashboard from "./components/Corporate/CorporateDashboard";
import AboutUs from "./components/Customer/AboutUs";
import PrivacyPolicy from "./components/Customer/PrivacyPolicy";
import PaymentRefundTerms from "./components/Customer/PaymentRefundTerms";
import TermsConditions from "./components/Customer/TermsConditions";
import HospitalRegistration from "./components/Hospital/HospitalRegistration";
import HospitalLogin from "./components/Hospital/HospitalLogin";
import HospitalDashboard from "./components/Hospital/HospitalDashboard";
import { HospitalProtectedRoute } from "./routingRules/protectedHospitalRoute";
import NotFound from "./components/NotFound";
import Packages from "./components/Customer/Packages";
import Events from "./components/Customer/Events";
import ForgetPassword from "./components/Patient/ForgetPassword";
import CardsPage from "./components/Customer/Cards";
import PaymentSuccess from "./components/Shared/PaymentSuccess";
import PaymentError from "./components/Shared/PaymentError";
import PaymentCancel from "./components/Shared/PaymentCancel";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={Home2} />
        <Route exact path="/health-card" component={CardsPage} />
        <Route exact path="/munzertopu" component={SuccessMessage} />
        <Route exact path="/test" component={BasicExample} />
        <Route exact path="/testmat" component={MaterialTab} />

        <Route exact path="/doctor/login" component={Login} />
        <Route exact path="/doctor/register" component={doctorRegistration} />
        {/* <Route exact path="/doctor/video" component={VideoConference} />
        <Route exact path="/doctor" component={Dashboard} /> */}

        {/* Diagnostic */}

        <Route
          exact
          path="/diagnostic/register"
          component={DiagnosticRegistration}
        />

        <Route exact path="/diagnostic/login" component={DiagnosticLogin} />
        {/* Agent */}

        <Route exact path="/corporate/login" component={CorporateLogin} />
        <CorporateProtectedRoute path="/corporate">
          <CorporateDashboard />
        </CorporateProtectedRoute>
        {/* Agent */}

        <Route exact path="/agent/register" component={AgentRegistration} />
        <Route exact path="/agent/login" component={AgentLogin} />
        <AgentProtectedRoute path="/agent">
          <AgentDashboard />
        </AgentProtectedRoute>

        <Route
          exact
          path="/hospital/register"
          component={HospitalRegistration}
        />
        <Route exact path="/hospital/login" component={HospitalLogin} />

        <HospitalProtectedRoute path="/hospital">
          <HospitalDashboard />
        </HospitalProtectedRoute>
        {/* Pharmacy */}

        <Route
          exact
          path="/pharmacy/register"
          component={PharmacyRegistration}
        />

        <Route exact path="/pharmacy/login" component={PharmacyLogin} />

        {/* Patient */}
        <Route exact path="/events">
          <Events />
        </Route>
        <Route exact path="/events/:org">
          <Events />
        </Route>
        <Route exact path="/packages">
          <Packages />
        </Route>
        {/* Patient */}
        <Route exact path="/patient/register">
          <PatientRegistration />
        </Route>
        <Route exact path="/patient/login" component={PatientLogin} />
        <Route
          exact
          path="/patient/card-payment-success/:id"
          component={PaymentSuccess}
        />
        <Route
          exact
          path="/patient/card-payment-fail/:id"
          component={PaymentError}
        />
        <Route
          exact
          path="/patient/card-payment-cancel/:id"
          component={PaymentCancel}
        />

        <Route
          exact
          path="/patient/forget-password"
          component={ForgetPassword}
        />
        <Route
          exact
          path="/doctor/:docId/schedules"
          component={DoctorDetailsLogin}
        />
        <Route exact path="/available-doctors" component={DoctorListHome} />
        <Route exact path="/available-tests" component={TestListHome} />
        <Route exact path="/about-us" component={AboutUs} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/terms-and-conditions" component={TermsConditions} />
        <Route
          exact
          path="/payment-refund-cancellation-delivery-terms"
          component={PaymentRefundTerms}
        />

        <Route
          exact
          path="/available-tests/:diagName?/:testName?"
          component={TestListHome}
        />
        <Route
          exact
          path="/test-details/:diagName?/:testName?"
          component={TestDetailsHome}
        />

        {/*Admin */}

        <Route exact path="/admin/register" component={AdminRegistration} />
        <Route exact path="/admin/login" component={AdminLogin} />

        {/* <Route>
          <NotFound />
        </Route> */}
        <PatientProtectedRoute path="/patient">
          <PatientDashboard />
        </PatientProtectedRoute>

        <ProtectedRoute path="/doctor">
          <Dashboard />
        </ProtectedRoute>
        <DiagnosticProtectedRoute path="/diagnostic">
          <DiagnosticDashboard />
        </DiagnosticProtectedRoute>
        <PharmacyProtectedRoute path="/pharmacy">
          <PharmacyDashboard />
        </PharmacyProtectedRoute>
        <AdminProtectedRoute path="/admin">
          <AdminDashboard />
        </AdminProtectedRoute>

        <Route exact path="/testing/dashboard">
          <DashboardTwo />
        </Route>

        <Route component={NotFound} />
      </Switch>
    </div>
  );
}

export default App;
