import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PatientAPI } from "../../../../AxiosRequest/PatientAPI";
import "./css/video.css";
import Lobby from "./Lobby";
import Room from "./Room";
const VideoChat = () => {
  let { videoId } = useParams();
  const paramID = parseInt(videoId);
  const [username, setUsername] = useState("");
  const [roomName, setRoomName] = useState("");
  const [appointmentDetails, setAppointmentDetails] = useState("");
  const [token, setToken] = useState(null);

  console.log("ccccccc", paramID);
  useEffect(() => {
    getAppointDetails();
    const videoToken = JSON.parse(localStorage.getItem("videoToken"));

    // if (patientToken !== null && patientToken !== undefined) {
    //   setPaToken(patientToken);
    // }

    if (videoToken !== null) {
      setToken(videoToken);
    }
    const roomName = JSON.parse(localStorage.getItem("roomName"));
    if (roomName !== null) {
      setRoomName(roomName);
    }
  }, []);
  const getAppointDetails = () => {
    PatientAPI.get(`appointments/${paramID}`)
      .then((response) => {
        console.log("video", response.data.data);
        setAppointmentDetails(response.data.data);
        if (response.data.data) {
          if (response.data.data.doctor !== undefined) {
            setRoomName(response.data.data.doctor.email);
          }
          if (response.data.data.patient !== undefined) {
            setUsername(response.data.data.patient.name);
          }
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleUsernameChange = useCallback((event) => {
    setUsername(event.target.value);
  }, []);

  const handleRoomNameChange = useCallback((event) => {
    setRoomName(event.target.value);
  }, []);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      let patientToken = JSON.parse(localStorage.getItem("patientToken"));
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${patientToken}`,
      };
      PatientAPI.post(`/access-token`, {
        patient_appointment_id: paramID,
      })
        .then((response) => {
          let videoToken = response.data.data.patient_token;
          if (videoToken !== undefined) {
            localStorage.setItem("videoToken", JSON.stringify(videoToken));
            localStorage.setItem("roomName", JSON.stringify(roomName));
            setToken(videoToken);
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
      // const data = await fetch(
      //   "https://beta.heal-holmes.com/api/api/doctor/access-token",
      //   {
      //     method: "POST",
      //     body: JSON.stringify({
      //       identity: username,
      //       room: roomName,
      //     }),
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: `Bearer ${patoken}`,
      //     },
      //   }
      // ).then((res) => res.json());
      // console.log(data);
      // setToken(data.token);
      // let videoToken = data.token;
      // localStorage.setItem("videoToken", JSON.stringify(videoToken));
      // localStorage.setItem("roomName", JSON.stringify(roomName));
    },
    [username, roomName]
  );
  const handleLogout = useCallback((event) => {
    setToken(null);
    localStorage.removeItem("videoToken");
    localStorage.removeItem("roomName");
  }, []);
  let render;
  if (token) {
    render = (
      <Room roomName={roomName} token={token} handleLogout={handleLogout} />
    );
  } else {
    render = (
      <Lobby
        username={username}
        roomName={roomName}
        handleUsernameChange={handleUsernameChange}
        handleRoomNameChange={handleRoomNameChange}
        handleSubmit={handleSubmit}
        appointmentDetails={appointmentDetails}
      />
    );
  }
  return render;
};

export default VideoChat;
