import React, { useState, useEffect } from "react";
import { PatientAPI } from "../../../AxiosRequest/PatientAPI";

import { Layout } from "antd";
import VideoChat from "./VideoComponents/VideoChat";

const { Content, Footer } = Layout;

export default function ShowVideoCall() {
  //   const [acceptedAppointments, setAcceptedAppointments] = useState([]);
  //   useEffect(() => {
  //     GetUpcomingAppointments();
  //   }, []);

  //   const GetUpcomingAppointments = () => {
  //     PatientAPI.get("appointments?status=Accepted")
  //       .then((response) => {
  //         setAcceptedAppointments(response.data.data);
  //       })
  //       .catch((error) => {});
  //   };
  return (
    <div>
      <Layout style={{ minHeight: "100vh" }}>
        <Layout className="site-layout">
          <Content style={{ margin: "0 16px" }}>
            <div
              className="site-layout-background"
              style={{ padding: 24, minHeight: 22 }}
            >
              <div className="container">
                <VideoChat />
              </div>
            </div>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            {/* Ant Design ©2018 Created by Ant UED */}
          </Footer>
        </Layout>
      </Layout>
    </div>
  );
}
