import axios from "axios";
import history from "../history";

const DiagnosticAPI = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/diagnostic`,
  // baseURL: "http://beta.heal-holmes.com/api/api/diagnostic",
});

DiagnosticAPI.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const diagnosticToken = JSON.parse(
        localStorage.getItem("diagnosticToken")
      );

      if (diagnosticToken) {
        config.headers.Authorization = `Bearer ${diagnosticToken}`;
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);
DiagnosticAPI.interceptors.response.use(
  (response) => {
    console.log(response.status);
    // console.log(response.status);
    // Edit response config
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      const path = document.location.pathname;
      console.log(path);
      localStorage.removeItem("diagnosticToken");
      history.push("/diagnostic/login");
      // if (path !== "/doctor/login") {
      //   return (window.location.href = "/doctor/login");
      // }
    }
    console.log(error);
    return Promise.reject(error);
  }
);

export { DiagnosticAPI };
