import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import Pagination from "@material-ui/lab/Pagination";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import _ from "lodash";

export default function OfferList() {
  const [OfferList, setOfferList] = useState([]);
  const [photoPath, setPhotoPath] = useState();
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [offerId, setOfferId] = useState(Number);

  const [defaultStatus, setDefaultStatus] = useState("");
  const [selecedStatus, setSelectedStatus] = useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [params, setParams] = useState({
    per_page: 15,
    page: 1,
  });

  useEffect(() => {
    getOfferList();
  }, [params]);

  const getOfferList = () => {
    AdminAPI.get(`offers`, { params: params })
      .then((response) => {
        setOfferList(response.data.data.data);
        setPhotoPath(response.data.path);
        setTotalPage(response.data.data.last_page);
        setPage(response.data.data.current_page);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };

  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);
  const updateOffer = (status, id) => {
    console.log(id, status);
    if (status === "Active") {
      setDefaultStatus("Inactive");
      setSelectedStatus("Inactive");
    }
    if (status === "Inactive") {
      setDefaultStatus("Active");
      setSelectedStatus("Active");
    }

    setOfferId(id);
    setOpenModal(true);
  };

  const changeStatus = (e) => {
    setSelectedStatus(e.target.value);
  };
  const submitStatus = () => {
    let data = {
      status: selecedStatus,
    };
    AdminAPI.post(`offers/${offerId}/update-status`, data)
      .then((response) => {
        alert("You have successfully updated the offer status");
        getOfferList();
        setOpenModal(false);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  return (
    <div>
      <div>
        <div className="row">
          <div className="col-md-3 form-group">
            <select
              className="form-control"
              onChange={(e) => {
                handleColumnWiseSearch(e.target.value, "status");
              }}
            >
              <option value="">All</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
          </div>
          <div className="col-md-4 text-right form-group">
            <input
              placeholder="Search Offer"
              className="form-control"
              type="text"
              onChange={(e) =>
                handleColumnWiseSearch(e.target.value, "search")
              }
            />
          </div>
          <div className="col-md-2 form-group">
            <Link
              to={`/admin/add-offer`}
              type="button"
              className="btn btn-outline-secondary float-right"
            >
              New Offer
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className=" table-responsive">
              <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Title</th>
                    <th scope="col">Description</th>
                    <th scope="col">Thumb</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {OfferList.map((offer, i) => (
                    <tr key={i}>
                      <td>{offer.title}</td>
                      <td>{offer.description}</td>
                      <td>
                        <img
                          src={
                            `${process.env.REACT_APP_PUBLIC_URL}${photoPath}/` +
                            offer.photo
                          }
                          alt="{offer.photo}"
                          height={40}
                        />
                      </td>
                      <td>
                        {offer.status == "Active" ? (
                          <button
                            type="button"
                            onClick={() =>
                              updateOffer(offer.status, offer.id)
                            }
                            className="btn btn-sm btn-info"
                          >
                            {offer.status === "Active" && "Active"}
                            {offer.status === "Inactive" && "Inactive"}
                            {offer.status === "" && "Not Available"}
                          </button>
                        ):(
                          <button
                            type="button"
                            onClick={() =>
                              updateOffer(offer.status, offer.id)
                            }
                            className="btn btn-sm btn-warning"
                          >
                            {offer.status === "Active" && "Active"}
                            {offer.status === "Inactive" && "Inactive"}
                            {offer.status === "" && "Not Available"}
                          </button>
                        )}
                      </td>

                      <td>
                        <Link
                          to={`/admin/view-offer/${offer.id}`}
                          type="button"
                          className="btn btn-sm btn-primary mb-1"
                        >
                          <VisibilityIcon />
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handleChange}
          />
        </div>
        <Dialog
          fullWidth
          maxWidth={`sm`}
          open={openModal}
          onClose={() => setOpenModal(false)}
        >
          <DialogTitle id="max-width-dialog-title">
            Change Offer Status
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <select
                className=" form-control"
                defaultValue={defaultStatus}
                onChange={(event) => changeStatus(event)}
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenModal(false)} color="primary">
              Close
            </Button>
            <Button onClick={() => submitStatus()} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
