import Pagination from "@material-ui/lab/Pagination";
import _ from "lodash";
import * as moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { DoctorAPI } from "../../AxiosRequest/DoctorAPI";
import "./css/doc-dashboard.css";
import "./css/schedule.css";

export default function Appointments({ onlinePatients }) {
  let history = useHistory();
  const [upcomingSchedules, setUpcomingSchedules] = useState([]);
  const [prescriptionId, setPrescriptionId] = useState();
  const [appointmentStatus, setAppointmentStatus] = useState("");
  const [onlinePatientsIDs, setOnlinePatientsIDs] = React.useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);

  const [params, setParams] = useState({
    per_page: 15,
    page: 1,
  });

  useEffect(() => {
    if (onlinePatients && onlinePatients.length > 0) {
      const oP = [...onlinePatients];
      let ids = oP.map((o) => o.id);
      setOnlinePatientsIDs(ids);
    } else {
      setOnlinePatientsIDs([]);
    }
  }, [onlinePatients]);
  console.log("heonlinePatientsr childdddde", onlinePatientsIDs);
  useEffect(() => {
    DoctorAPI.get(`/appointments`, { params: params })
      .then((response) => {
        setUpcomingSchedules(response.data.data.data);
        setTotalPage(response.data.data.last_page);
        setPage(response.data.data.current_page);
        // console.log(upcomingSchedules);
        console.log(response.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, [params]);

  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };
  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);

  const changeStatus = (event) => {
    // setAppointmentStatus(event.target.value);
    handleColumnWiseSearch(event.target.value, "status");
  };
  const timeDiff = (time) => {
    const dateFormat = "YYYY-MM-DD HH:mm:ss";
    // Get your start and end date/times
    const rightNow = moment().format(dateFormat);
    const appTime = moment(time).format(dateFormat);
    // pass in hours as the second parameter to the diff function
    const differenceInMinutes = moment(appTime).diff(rightNow, "minutes");
    if (differenceInMinutes > 0) {
      return differenceInMinutes;
    } else return "";

    console.log(
      `${differenceInMinutes} hours have passed since this time yesterday`
    );
  };

  const routeToPrescription = (id) => {
    console.log(id);
    history.push(`/doctor/video-conference/${id}`);
  };
  const routeToShowPrescription = (id) => {
    history.push(`/doctor/prescription-details/${id}`);
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-12 text-right">
            <select
              className=" form-control"
              onChange={(event) => changeStatus(event)}
            >
              <option value="">All</option>
              <option value="Pending">Pending</option>
              <option value="Accepted">Accepted</option>
              <option value="Paid">Upcoming</option>
              <option value="Rejected">Rejected</option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <table className="custom-responsive-table">
              <thead>
                <tr>
                  <th scope="col">Time</th>
                  <th scope="col">Patient's Name</th>
                  <th scope="col">Patient's Contact</th>

                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {upcomingSchedules.map((row, i) => (
                  <tr key={i}>
                    <td data-column="Time">
                      {moment(row.appointment_time).format(
                        "DD/MM/YYYY HH:mm:ss A"
                      )}
                    </td>
                    <td data-column="Patient's Name">
                      {row.patient.name}
                      {onlinePatientsIDs.includes(row.patient_id) && (
                        <span style={{ color: "green" }}> (Online) </span>
                      )}
                    </td>
                    <td data-column="Patient's Contact">{row.patient.phone}</td>

                    <td data-column="Status">
                      {row.status === "Paid" ? "Upcoming" : row.status}
                    </td>
                    <td data-column="Actions">
                      {row.status === "Paid" &&
                        timeDiff(row.appointment_time) <= 120 && (
                          <button
                            onClick={() => {
                              routeToPrescription(row.id);
                              setPrescriptionId(row.id);
                            }}
                            className="btn btn-sm btn-outline-primary"
                          >
                            Start Prescription
                          </button>
                        )}
                      {row.status === "Pending" ||
                      row.status === "Upcoming" ||
                      row.status === "Accepted" ||
                      row.status === "Rejected" ? (
                        <Link
                          type="button"
                          to={`/doctor/schedule-details/${row.id}`}
                          className="btn btn-sm btn-outline-secondary"
                        >
                          View Details
                        </Link>
                      ) : (
                        ""
                      )}
                      {row.status === "Prescribed" && (
                        <button
                          onClick={() => {
                            routeToShowPrescription(row.prescription.id);
                          }}
                          className="btn btn-sm btn-outline-success"
                        >
                          Show Prescription
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="row">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}
