import React, { useState, useEffect } from "react";
import { PatientAPI } from "../../../AxiosRequest/PatientAPI";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import GetAppIcon from "@material-ui/icons/GetApp";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import _ from "lodash";
import moment from "moment";
import axios from "axios";

export default function TestList() {
  const [testList, setTestList] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [selectedStartDate, handleDateChangeStart] = useState(null);
  const [selectedEndDate, handleDateChangeEnd] = useState(null);

  const [params, setParams] = useState({
    page: 1,
    per_page: 15,
  });

  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };

  const handleDateChangeDate = (value, col) => {
    if (value !== null) {
      handleColumnWiseSearch(moment(value).format("YYYY-MM-DD"), col);
    } else {
      handleColumnWiseSearch(value, col);
    }
  };

  useEffect(() => {
    PatientAPI.get(`test-invoices`, { params: params })
      .then((response) => {
        setTestList(response.data.data.data);
        setTotalPage(response.data.meta.last_page);
        setPage(response.data.meta.current_page);
      })
      .catch((error) => {});
  }, [params]);
  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);
  const filterByDepartment = (event) => {
    const selectedType = event.target.value;
    handleColumnWiseSearch(selectedType, "type");
  };

  const downloadPDF = (id) => {
    PatientAPI.get(`test-invoices/${id}/pdf`, {
      method: "GET",
      responseType: "blob", //Force to receive data in a Blob Format
    })
      .then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" }); //Build a URL from the file
        const fileURL = URL.createObjectURL(file); //Open the URL on new Window
        window.open(fileURL);

        console.log("pdf response", response, file);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const downloadReport = (testId, report) => {
    console.log("Report name", report);
    axios
      .get(`files/testInvoiceDetails/${testId}/${report}`, {
        responseType: "blob",
      })
      .then((response) => {
        const file = new Blob([response.data]); //Build a URL from the file
        const fileURL = window.URL.createObjectURL(file); //Open the URL on new Window
        var a = document.createElement("a");
        a.href = fileURL;
        a.download = report;
        a.click();
        window.URL.revokeObjectURL(fileURL);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div className="row my-2">
                <div className="col-md-6">
                  {" "}
                  <KeyboardDatePicker
                    clearable
                    value={selectedStartDate}
                    placeholder="Start Date"
                    onChange={(date) => {
                      handleDateChangeStart(date);
                      handleDateChangeDate(date, "start_date");
                    }}
                    format="MM/dd/yyyy"
                  />
                </div>

                <div className="col-md-6">
                  {" "}
                  <KeyboardDatePicker
                    clearable
                    value={selectedEndDate}
                    placeholder="End Date"
                    onChange={(date) => {
                      handleDateChangeEnd(date);
                      handleDateChangeDate(date, "end_date");
                    }}
                    format="MM/dd/yyyy"
                  />
                </div>
              </div>
            </MuiPickersUtilsProvider>
          </div>
          <div className="col-md-4 form-group">
            <div className="">
              <input
                placeholder="Search"
                className="form-control"
                type="text"
                onChange={(e) => {
                  handleColumnWiseSearch(e.target.value, "search");
                }}
              />
            </div>
          </div>
          <div className="col-md-2 form-group text-right">
            <Link
              type="button"
              to={`/patient/test/add`}
              className="btn btn-sm btn-outline-secondary"
            >
              Book Test
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <table className="custom-responsive-table">
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Diagnostic</th>
                  <th scope="col">Total Price</th>
                  <th scope="col">Discount</th>
                  <th scope="col">Bill</th>
                  <th scope="col">Type</th>
                  <th scope="col">Status</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              {testList.map((invoice, i) => (
                <tbody>
                  <tr key={i}>
                    <td data-column="Date">
                      {moment(invoice.created_at).format("DD/MM/YYYY hh:mm:ss")}
                    </td>
                    <td data-column="Diagnostic">
                      {invoice.service_provider !== undefined && (
                        <span>{invoice.service_provider.name} </span>
                      )}
                    </td>
                    <td data-column="Total Price">{invoice.price}</td>
                    <td data-column="Discount">{invoice.discount}</td>
                    <td data-column="Bill">{invoice.bill}</td>
                    <td data-column="Type">
                      {invoice.type === "Patient" ? "Oredered" : invoice.type}
                    </td>
                    <td data-column="Status">
                      {invoice.status == "Ready" ? (
                        <span className="text-success">Ready</span>
                      ) : (
                        <span>
                          {invoice.status == "Processed" ? (
                            <span className="text-primary">Processing</span>
                          ) : (
                            <span>{invoice.status}</span>
                          )}
                        </span>
                      )}
                    </td>
                    <td>
                      <span>
                        <Link
                          type="button"
                          to={`/patient/test/test-invoices/${invoice.id}`}
                          className="btn btn-sm btn-outline-secondary mx-1"
                        >
                          <VisibilityIcon />
                        </Link>
                        <button
                          onClick={() => downloadPDF(invoice.id)}
                          className="btn btn-sm btn-outline-success mx-1"
                        >
                          <GetAppIcon />
                        </button>
                      </span>
                    </td>
                  </tr>
                  {invoice.test_invoice_details.map((invoiceDetail, j) => (
                    <tr key={"detail" + j}>
                      <td className="empty-left-border"></td>
                      <td className="empty-left-border"></td>
                      <td className="empty-left-border"></td>
                      <td className="empty-left-border"></td>
                      <td className="test-color" data-column="Test">
                        {invoice.type === "BCorporate" ? (
                          <span>
                            {invoiceDetail.corporate_test !== null &&
                              invoiceDetail.corporate_test.name}
                          </span>
                        ) : (
                          <span>
                            {invoiceDetail.diagnostic_test.name !== null &&
                              invoiceDetail.diagnostic_test.name}
                          </span>
                        )}
                      </td>
                      <td className="test-color" data-column="Report">
                        {invoiceDetail.report !== null ? (
                          <button
                            className="btn  btn-sm btn-success"
                            onClick={() =>
                              downloadReport(invoice.id, invoiceDetail.report)
                            }
                          >
                            Report
                          </button>
                        ) : (
                          <span>{invoiceDetail.status}</span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              ))}
            </table>
          </div>
        </div>

        <div className="row">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}
