import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import AgentForm from "./AgentForm";

export default function AgentDetails() {
  return (
    <div>
      <AgentForm />
    </div>
  );
}
