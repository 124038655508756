import React, { useEffect } from "react";
import {
  Switch,
  Route,
  Link,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import patientAuth from "../../routingRules/patientAuth";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import PropTypes from "prop-types";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Tooltip from "@material-ui/core/Tooltip";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AddToQueueIcon from "@material-ui/icons/AddToQueue";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import PersonIcon from "@material-ui/icons/Person";

import TestList from "./invoices/TestList";
import AddTest from "./invoices/AddTest";

// import ResetPassword from "./reset-password";
import Profile from "./profile";
import UploadPrescription from "./invoices/UploadPrescription";
import UploadedTest from "./invoices/UploadedTest";
import UploadedTestDetails from "./invoices/UploadedTestDetails";
import ReportList from "./test-reports/ReportList";
import TestDetails from "./invoices/TestDetails";
import PatientRegistration from "./patient/PatientRegistration";
import PatientList from "./patient/PatientList";
import PatientDetails from "./patient/PatientDetails";
import ResetPassword from "./reset-password";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    // background: "#dfebf3",
  },
  title: {},
  btnRoot: {
    color: "white",
    fontSize: "20px",
    padding: "0 5px",
    flexGrow: 1,
  },
  label: {
    textTransform: "capitalize",
  },
}));
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      {/* <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "} */}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function AgentDashboard(props) {
  const { window } = props;
  let history = useHistory();
  let match = useRouteMatch();
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openSubLink, setOpenSubLink] = React.useState(false);

  const handleTestExpand = () => {
    setOpenSubLink(!openSubLink);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const logOut = () => {
    localStorage.removeItem("testCart");
    localStorage.removeItem("testImages");
    localStorage.clear();
    patientAuth.logout(() => {
      history.push("/");
    });
  };

  const routeToPortal = () => {
    history.push("/agent/dashboard");
  };
  const profile = () => {
    history.push("/agent/profile");
  };
  const resetPassword = () => {
    history.push("/agent/reset-password");
  };
  // useEffect(() => {
  //   history.push("/patient/appointments");
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const drawer = (
    <div>
      <div className="text-center">
        <img
          className="app-bar-logo"
          src={`${process.env.PUBLIC_URL}/assets/images/newLogo.jpg`}
          alt="app-bar-logo"
        />
      </div>
      <Divider />

      <List>
        <ListItem button onClick={handleTestExpand}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Test" />
          {openSubLink ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openSubLink} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              component={Link}
              to={`${match.url}/test/test-invoices`}
              className={classes.nested}
            >
              <ListItemIcon>
                <AddToQueueIcon />
              </ListItemIcon>
              <ListItemText primary="Test List" />
            </ListItem>
            <List component="div" disablePadding>
              <ListItem
                button
                component={Link}
                to={`${match.url}/test/report-list`}
                className={classes.nested}
              >
                <ListItemIcon>
                  <NoteAddIcon />
                </ListItemIcon>
                <ListItemText primary="Download Reports" />
              </ListItem>
            </List>
          </List>
        </Collapse>
        <ListItem button component={Link} to={`${match.url}/patient/list`}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Patient" />
        </ListItem>
      </List>
      <Divider />
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            
          </Typography> */}{" "}
          <Button
            onClick={() => routeToPortal()}
            classes={{
              root: classes.btnRoot, // class name, e.g. `classes-nesting-root-x`
              label: classes.label, // class name, e.g. `classes-nesting-label-x`
            }}
          >
            Agent Portal
          </Button>
          <Tooltip title="Reset Password">
            <IconButton onClick={resetPassword} color="inherit">
              <VpnKeyIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Profile">
            <IconButton onClick={profile} color="inherit">
              <PersonIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Logout">
            <IconButton onClick={logOut} color="inherit" aria-label="logout">
              <ExitToAppIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
          <Route exact path={`${match.path}/patient/register`}>
            <PatientRegistration />
          </Route>
          <Route exact path={`${match.path}/patient/list`}>
            <PatientList />
          </Route>
          <Route exact path={`${match.path}/patient/view/:pId`}>
            <PatientDetails />
          </Route>
          <Route exact={true} path={`${match.path}/test/uploaded-test`}>
            <UploadedTest />
          </Route>
          <Route path={`${match.path}/test/uploaded-test/:testId`}>
            <UploadedTestDetails />
          </Route>
          <Route path={`${match.path}/test/upload-prescription`}>
            <UploadPrescription />
          </Route>
          <Route exact={true} path={`${match.path}/test/test-invoices`}>
            <TestList />
          </Route>
          <Route exact={true} path={`${match.path}/test/report-list`}>
            <ReportList />
          </Route>
          <Route path={`${match.path}/test/test-invoices/:testId`}>
            <TestDetails />
          </Route>
          <Route exact path={`${match.path}/test/add`}>
            <AddTest />
          </Route>
          <Route exact path={`${match.path}/profile`}>
            <Profile />
          </Route>
          <Route exact path={`${match.path}/reset-password`}>
            <ResetPassword />
          </Route>
        </Switch>
      </main>
    </div>
  );
}

AgentDashboard.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
