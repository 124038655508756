/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import { TextField } from "formik-material-ui";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import * as Yup from "yup";
import CloseIcon from "@material-ui/icons/Close";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import { TextField as TextFieldSecond } from "formik-material-ui";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import _, { isEmpty } from "lodash";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { PatientAPI } from "../../../AxiosRequest/PatientAPI";
import { Dialog, IconButton, withStyles } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: "0px",
    top: "-5px",
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  input: {
    background: "#fff",
    border: "1px solid #007ffd",
    padding: "14px 5px!important",
  },
  fControl: {
    border: "1px solid #007ffd",
    minWidth: "100%",
    background: "#fff",
  },
  autoInput: {},
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {/* <Typography variant="h6">{children}</Typography> */}

      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const FormikDatePicker = ({
  name,
  form: { setFieldValue },
  field: { value },

  ...rest
}) => {
  // console.log(rest);
  return (
    <DatePicker
      disableFuture
      openTo="year"
      format="dd/MM/yyyy"
      // views={["year", "month", "date"]}
      name={name}
      keyboard
      clearable
      autoOk
      // handle clearing outside => pass plain array if you are not controlling value outside
      mask={(value) =>
        value
          ? [/[0-3]/, /\d/, "/", /0|1/, /\d/, "/", /1|2/, /\d/, /\d/, /\d/]
          : []
      }
      disableOpenOnEnter
      onChange={(value) => {
        console.log("setting value to", value);
        setFieldValue("date_of_birth", value);
      }}
      value={value}
      animateYearScrolling={false}
      classes={{
        root: {
          border: "1px solid #007ffd",
          minWidth: "100%",
          background: "#fff",
        },
      }}
    />
  );
};
export default function CardForm({ openBanner, setOpenBanner, patient }) {
  // const [openBanner, setOpenBanner] = React.useState(false);

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [pData, setPData] = useState({
    name: "",
    phone: "",
    email: "",
    date_of_birth: null,
    gender: "",
  });
  const [successMsg, setSuccessMsg] = useState("");
  const [pID, setPid] = useState(null);
  // Backend Error Messages
  const [nameErros, setNameErrors] = useState([]);
  const [phoneErros, setPhoneError] = useState([]);
  const [ageErrors, setAgeErrors] = useState([]);
  const [genderErrors, setGenderErrors] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const handleCloseBanner = () => {
    setOpenBanner(false);
  };

  useEffect(() => {
    if (!isEmpty(patient)) {
      setPData(patient);
    }
  }, [patient]);

  const validationSchema = Yup.object({
    name: Yup.string().required("Name field is required"),
    phone: Yup.string().required("Please enter your phone number"),

    date_of_birth: Yup.date().required("Date of Birth field is required"),
    gender: Yup.string().required("Gender field is required"),
  });
  const validationSchemaNew = Yup.object({
    name: Yup.string().required("Name field is required"),
    phone: Yup.string().required("Please enter your phone number"),
    date_of_birth: Yup.date().required("Date of Birth field is required"),
    gender: Yup.string().required("Gender field is required"),
  });
  const initialValues = {
    name: pData.name,
    phone: pData.phone,
    email: pData.email,
    date_of_birth: pData.date_of_birth,
    gender: pData.gender,
  };
  const initialValuesNew = {
    name: pData.name,
    phone: pData.phone,
    email: pData.email,
    date_of_birth: pData.date_of_birth,
    gender: pData.gender,
  };

  function fireClickEvent(element) {
    var evt = new window.MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });

    element.dispatchEvent(evt);
  }

  // this function will setup a virtual anchor element
  // and fire click handler to open new URL in the same room
  // it works better than location.href=something or location.reload()
  function openNewURLInTheSameWindow(targetURL) {
    var a = document.createElement("a");
    a.href = targetURL;
    fireClickEvent(a);
  }
  function openNewTabOrNewWindow(targetURL) {
    var a = document.createElement("a");
    a.href = targetURL;

    a.target = "_blank"; // now it will open new tab/window and bypass any popup blocker!
    // a.click()
    fireClickEvent(a);
  }
  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    setLoading(true);
    setPid(null);
    setSuccessMsg("");
    let objToPost = { ...values };
    objToPost.date_of_birth = moment(values.date_of_birth).format("YYYY-MM-DD");
    console.log(objToPost);
    PatientAPI.post("auth/buy-card", objToPost)
      .then((response) => {
        console.log(response);
        console.log(response.data.data.id);
        // setSuccessMsg(response.data.message);
        let id = response.data.data.id;
        setLoading(false);
        // setPid(id);
        const url = `${process.env.REACT_APP_PAYMENT_URL}/patient/card-payment/${id}`;
        openNewURLInTheSameWindow(url);
        // window.open(url, "_blank");
        // setSignedUp(true);
        // setSignedUpMessage(
        //   "You have successfully signed up. Press login to continue"
        // );
        // setLoading(false);
      })
      .catch((error) => {
        // let id = 1;
        // const url = `${process.env.REACT_APP_PAYMENT_URL}/patient/card-payment/${id}`;
        // window.open(url, "_blank");
        console.log(error.response);
        setLoading(false);
        if (error.response.data.message) {
        }

        if (error.response.data.errors !== undefined) {
          let nameErrors = error.response.data.errors.name;
          let phoneErrors = error.response.data.errors.phone;
          let birthErrors = error.response.data.errors.date_of_birth;
          let sexErrors = error.response.data.errors.gender;

          if (nameErrors !== undefined) {
            setNameErrors(nameErrors);
          }
          if (phoneErrors !== undefined) {
            setPhoneError(phoneErrors);
          }
          if (birthErrors !== undefined) {
            setAgeErrors(birthErrors);
          }
          if (sexErrors !== undefined) {
            setGenderErrors(sexErrors);
          }
        }
      });
  };
  return (
    <div>
      <Dialog
        onClose={handleCloseBanner}
        aria-labelledby="customized-dialog-title"
        open={openBanner}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleCloseBanner}>
          <span>H</span>
        </DialogTitle>
        <DialogContent dividers>
          <div className="pa-register-sectiona">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="pa-register-form">
                        <div className="form-newa">
                          <h1 className="text-center main-header">
                            Submit Your Details
                          </h1>
                          {/* <h3 className="text-center py-2">Patient Registration</h3> */}

                          <Formik
                            initialValues={
                              isEmpty(patient)
                                ? initialValuesNew
                                : initialValues
                            }
                            validationSchema={isEmpty(
                              patient ? validationSchemaNew : validationSchema
                            )}
                            onSubmit={onSubmit}
                          >
                            {({ setFieldValue, isValid, dirty }) => (
                              <Form>
                                <div className="form-group row">
                                  <label className="col-sm-2 offset-sm-1 col-form-label">
                                    Name
                                  </label>
                                  <div className="col-sm-7">
                                    <Field
                                      name="name"
                                      type="text"
                                      className="form-control"
                                      placeholder="Your Name"
                                    />
                                    <ErrorMessage
                                      name="name"
                                      component="div"
                                      className="error-message"
                                    />
                                    {nameErros.map((item, i) => (
                                      <p className="error-message" key={i}>
                                        {item}
                                      </p>
                                    ))}
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-sm-2 offset-sm-1 col-form-label">
                                    Mobile
                                  </label>
                                  <div className="col-sm-7">
                                    <Field
                                      name="phone"
                                      type="text"
                                      className="form-control"
                                      placeholder="Your Mobile number"
                                    />
                                    <ErrorMessage
                                      name="phone"
                                      component="div"
                                      className="error-message"
                                    />
                                    {phoneErros.map((item, i) => (
                                      <p className="error-message" key={i}>
                                        {item}
                                      </p>
                                    ))}
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-sm-2 offset-sm-1 col-form-label">
                                    Email
                                  </label>
                                  <div className="col-sm-7">
                                    <Field
                                      name="email"
                                      type="email"
                                      className="form-control"
                                      placeholder="Your Email"
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-sm-2 offset-sm-1 col-form-label">
                                    Date of birth
                                  </label>
                                  <div className="col-sm-7">
                                    <MuiPickersUtilsProvider
                                      utils={DateFnsUtils}
                                    >
                                      <div className="col-sm-12">
                                        <Field
                                          component={FormikDatePicker}
                                          name="date_of_birth"
                                        />

                                        <ErrorMessage
                                          name="date_of_birth"
                                          component="div"
                                          className="error-message"
                                        />

                                        {ageErrors.map((item, i) => (
                                          <p className="error-message" key={i}>
                                            {item}
                                          </p>
                                        ))}
                                      </div>
                                    </MuiPickersUtilsProvider>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-sm-2 offset-sm-1 col-form-label">
                                    Gender
                                  </label>
                                  <div className="col-sm-7">
                                    <Field
                                      className="form-control"
                                      as="select"
                                      name="gender"
                                    >
                                      <option value="">Gender</option>
                                      <option value="Male">Male</option>
                                      <option value="Female">Female</option>
                                      <option value="Others">Others</option>
                                    </Field>
                                    <ErrorMessage
                                      name="gender"
                                      component="div"
                                      className="error-message"
                                    />
                                    {genderErrors.map((item, i) => (
                                      <p className="error-message" key={i}>
                                        {item}
                                      </p>
                                    ))}
                                  </div>
                                </div>

                                <div className="form-group row pt-3 text-center">
                                  <button
                                    disabled={loading}
                                    // disabled={!isValid || !dirty}
                                    type="submit"
                                    className="btn btn-md btn-success btn-block"
                                  >
                                    {loading && (
                                      <i className="fa fa-refresh fa-spin"></i>
                                    )}

                                    {!loading && <span> Buy</span>}
                                  </button>
                                  {!isEmpty(successMsg) && (
                                    <p
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        marginTop: "1em",
                                      }}
                                    >
                                      {successMsg}
                                    </p>
                                  )}
                                  {pID && (
                                    <a
                                      className="btn btn-md btn-info btn-block"
                                      href={`${process.env.REACT_APP_PAYMENT_URL}/patient/card-payment/${pID}`}
                                      target="_blank"
                                    >
                                      {" "}
                                      Pay Now
                                    </a>
                                  )}
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Save changes
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}
