import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { PatientAPI } from "../../../AxiosRequest/PatientAPI";
import axios from "axios";

// import "../../css/add-institution.css";

export default function DoctorDetails() {
  let history = useHistory();
  let { doctorId } = useParams();
  const [docDetails, setDocDetails] = useState({});
  const [path, setPath] = useState("");
  
  useEffect(() => {
    getDocDetails();
  }, []);

  const getDocDetails = () => {
    axios.get(`doctors/${doctorId}`)
      .then((response) => {
        console.log("doc details", response.data.data);
        setDocDetails(response.data.data);
        setPath(response.data.path);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {docDetails !== undefined && (
              <div className="doc-details-admin">
                <div className="d-flex justify-content-lg-end justify-content-sm-start">
                  <div className=""></div>
                  <div className="">
                    <div className="doc-profile">
                      {path !== "" && docDetails.photo !== undefined && (
                        <img
                          src={
                            `${process.env.REACT_APP_PUBLIC_URL}${path}/${docDetails.photo}`
                          }
                          alt={docDetails.name}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-2 offset-md-1">Name</div>
                  <div className="col-sm-7">{docDetails.name}</div>
                </div>
                <div className="row">
                  <div className="col-md-2 offset-md-1">Email</div>
                  <div className="col-sm-7">{docDetails.email}</div>
                </div>
                <div className="row">
                  <div className="col-md-2 offset-md-1">Designation</div>
                  <div className="col-sm-7">{docDetails.designation}</div>
                </div>
                <div className="row">
                  <div className="col-md-2 offset-md-1">Degrees</div>
                  {docDetails.degrees !== undefined && (
                    <div className="col-sm-7">
                      {docDetails.degrees.map((degree, index) => (
                        <React.Fragment key={index}>
                          <span> {degree.degree}</span>
                          {index === docDetails.degrees.length - 1
                            ? ""
                            : ","}
                        </React.Fragment>
                      ))}
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-2 offset-md-1">Department</div>
                  <div className="col-sm-7">{docDetails.department}</div>
                </div>
                <div className="row">
                  <div className="col-md-2 offset-md-1">
                    Current Institution
                  </div>
                  <div className="col-sm-7">{docDetails.institution}</div>
                </div>
                <div className="row">
                  <div className="col-md-2 offset-md-1">
                    Passing Institution
                  </div>
                  <div className="col-sm-7">{docDetails.passing_institution}</div>
                </div>
                <div className="row">
                  <div className="col-md-2 offset-md-1">Passing Year</div>
                  <div className="col-sm-7">
                    {docDetails.passing_year}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 offset-md-1">Practicing Year</div>
                  <div className="col-sm-7">{docDetails.practice_year}</div>
                </div>
                <div className="row">
                  <div className="col-md-2 offset-md-1">Status</div>
                  <div className="col-sm-7">{docDetails.status}</div>
                </div>
                <div className="row">
                  <div className="col-md-2 offset-md-1">Schedules</div>
                  <div className="col-sm-7">
                    {docDetails.doctor_appointment_times !== undefined ?
                      (
                        <Link
                          type="button"
                          to={`/patient/doctor-schedule/${docDetails.id}`}
                          className="btn btn-sm btn-success"
                        >
                          View Schedule
                        </Link>
                      ) : (
                        <Link
                          type="button"
                          to="#"
                          className="btn btn-sm btn-danger"
                        >
                          No Schedule
                        </Link>
                      )
                    }
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
