import React, { useState, useEffect } from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import LockOpenIcon from "@material-ui/icons/LockOpen";

import * as Yup from "yup";
import { AdminAPI } from "../../AxiosRequest/AdminAPI";
import Navbar2 from "../Shared/Navbar2";
export default function AdminRegistration() {
  const [loading, setLoading] = useState(false);
  const [signedUpMessage, setSignedUpMessage] = useState("");
  const [singnedUp, setSignedUp] = useState(false);

  // Backend Error Messages
  const [nameErros, setNameErrors] = useState([]);
  const [phoneErros, setPhoneError] = useState([]);
  const [emailError, setEmailError] = useState([]);
  const [ageErrors, setAgeErrors] = useState([]);

  const [passwordErrors, setPasswordErrors] = useState([]);
  const [passwordConfirmationErrors, setPaswordConfirmationErros] = useState(
    []
  );

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationSchema = Yup.object({
    name: Yup.string().required("Name field is required"),
    phone: Yup.string().required("Please enter your phone number"),
    email: Yup.string().email("Please Enter a valid Email"),
    password: Yup.string()
      .min(6, "Password must contain at least 6 characters")
      .required("Enter your password"),
    confirm_password: Yup.string()
      .required("Confirm your password")
      .oneOf([Yup.ref("password")], "Password does not match"),
    date_of_birth: Yup.date().required("Date of Birth field is required"),
  });
  const initialValues = {
    name: "",
    phone: "",
    email: "",
    date_of_birth: "",

    password: "",
    confirm_password: "",
  };

  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    setLoading(true);
    AdminAPI.post("auth/register", {
      name: values.name,
      email: values.email,
      phone: values.phone,
      date_of_birth: values.date_of_birth,
      password: values.password,
      confirm_password: values.confirm_password,
    })
      .then((response) => {
        console.log(response);
        console.log(response.data.message);
        setSignedUp(true);
        setSignedUpMessage(
          "You have successfully signed up. Press login to continue"
        );
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response);
        setLoading(false);
        if (error.response.data.message) {
        }

        if (error.response.data.errors !== undefined) {
          let nameErrors = error.response.data.errors.name;
          let emailError = error.response.data.errors.email;
          let phoneErrors = error.response.data.errors.phone;
          let birthErrors = error.response.data.errors.date_of_birth;

          let passwordErrors = error.response.data.errors.password;

          let confirmPasswordErrors =
            error.response.data.errors.confirm_password;

          if (nameErrors !== undefined) {
            setNameErrors(nameErrors);
          }
          if (emailError !== undefined) {
            setEmailError(emailError);
          }
          if (phoneErrors !== undefined) {
            setPhoneError(phoneErrors);
          }
          if (birthErrors !== undefined) {
            setAgeErrors(birthErrors);
          }

          if (passwordErrors !== undefined) {
            setPasswordErrors(passwordErrors);
          }
          if (confirmPasswordErrors !== undefined) {
            setPaswordConfirmationErros(confirmPasswordErrors);
          }
        }
      });
  };
  return (
    <div>
      <Navbar2 />
      <div className="doc-register-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-3"></div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-md-12">
                  <div className="doc-register-form">
                    <div className="text-center">
                      <LockOpenIcon />
                    </div>
                    <h1 className="text-center">HealthCare</h1>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      {({ setFieldValue, isValid, dirty }) => (
                        <Form>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label">
                              Name
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="name"
                                type="text"
                                className="form-control"
                                placeholder="Your Name"
                              />
                              <ErrorMessage
                                name="name"
                                component="div"
                                className="error-message"
                              />
                              {nameErros.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))}
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label">
                              Phone
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="phone"
                                type="text"
                                className="form-control"
                                placeholder="Phone/Mobile Number"
                              />
                              <ErrorMessage
                                name="phone"
                                component="div"
                                className="error-message"
                              />
                              {phoneErros.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))}
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label">
                              Email
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="email"
                                type="email"
                                className="form-control"
                                placeholder="Email"
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="error-message"
                              />
                              {emailError.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))}
                            </div>
                          </div>

                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label">
                              Date of Birth
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="date_of_birth"
                                type="date"
                                className="form-control"
                                placeholder=""
                              />
                              <ErrorMessage
                                name="date_of_birth"
                                component="div"
                                className="error-message"
                              />

                              {ageErrors.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))}
                            </div>
                          </div>

                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label ">
                              Password
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="password"
                                type="password"
                                className="form-control "
                                placeholder="password"
                              />
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="error-message"
                              />
                              {passwordErrors.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))}
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label ">
                              Confirm Password
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="confirm_password"
                                type="password"
                                className="form-control "
                                placeholder="password"
                              />
                              <ErrorMessage
                                name="confirm_password"
                                component="div"
                                className="error-message"
                              />
                              {passwordConfirmationErrors.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))}
                            </div>
                          </div>

                          <div className="form-group row ">
                            <label className="col-sm-5 offset-sm-1">
                              <button
                                disabled={loading}
                                // disabled={!isValid || !dirty}
                                type="submit"
                                className="btn btn-lg btn-success"
                              >
                                {loading && (
                                  <i className="fa fa-refresh fa-spin"></i>
                                )}
                                {loading && <span>Signing Up</span>}
                                {!loading && <span> Sign Up</span>}
                              </button>
                            </label>
                            <div className="col-sm-7"></div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-11 offset-sm-1">
                              {signedUpMessage}
                              {singnedUp && (
                                <Link
                                  to="/admin/login"
                                  type="button"
                                  className="btn btn-lg btn-primary"
                                >
                                  Login
                                </Link>
                              )}
                            </label>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </div>
    </div>
  );
}
