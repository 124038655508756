import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <footer>
        <div class="container container-custom">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4">
              <div class="foot-contact-block">
                {/* <p>
                  Lorem ipsum dolor sit amet, consect <br /> etur adipisicing
                  elit, sed do eius mod <br />
                  tempor incididunt ut labore et dolore
                  <br /> magna aliqua. Ut enim ad minim
                </p> */}
                <h4 className="heal-txt">heal holmes Ltd.</h4>
                <div className="">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.companyname.healholmes_xamarin"
                    target="_blank"
                    rel="external"
                  >
                    {" "}
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/google-play-png-logo-3802.png`}
                      width={"200"}
                      alt=""
                    />
                  </a>
                </div>
                <a href="tel:09642919191">
                  <h4>
                    <i class="fa fa-phone"></i>09642919191
                  </h4>
                </a>
                <a href="mailto:heal.holmes@gmail.com">
                  <h4>
                    <i class="fa fa-envelope"></i>heal.holmes@gmail.com
                  </h4>
                </a>
              </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-2 offset-lg-1">
              <div class="foot-link-box">
                <h4>Quick Links</h4>
                <ul>
                  <li>
                    <Link to="/available-doctors" className="heal_a">
                      <i class="fa fa-angle-double-right"></i> Doctors
                    </Link>
                  </li>
                  <li>
                    <Link to="/available-tests" className="heal_a">
                      <i class="fa fa-angle-double-right"></i> Tests
                    </Link>
                  </li>
                  <li>
                    <Link to="/about-us" className="heal_a">
                      <i class="fa fa-angle-double-right"></i> About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy" className="heal_a">
                      <i class="fa fa-angle-double-right"></i> Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms-and-conditions" className="heal_a">
                      <i class="fa fa-angle-double-right"></i> Terms &
                      Conditions
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/payment-refund-cancellation-delivery-terms"
                      className="heal_a"
                    >
                      <i class="fa fa-angle-double-right"></i> Payment, Refund,
                      Cancellation and Delivery Terms
                    </Link>
                  </li>
                  <li>
                    <a
                      href="https://invoice.sslcommerz.com/invoice-form?&refer=61E3F31A03880"
                      target="_blank"
                    >
                      <i class="fa fa-angle-double-right"></i> Direct payment
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-md-4 col-lg-4 offset-lg-1">
              <div class="foot-link-box">
                <h4>Contact Us</h4>
                <div className="footer-contact-info">
                  <ul className="list-unstyled">
                    <li className="address">
                      <div className="row">
                        <div className="col-1">
                          {" "}
                          <i className="fa fa-map-marker"></i>
                        </div>
                        <div className="col-11">
                          {" "}
                          <a
                            target="_blank"
                            className="heal_a"
                            href="http://maps.google.com/maps?q=Heal+Holmes"
                          >
                            Plot-08, Block-C, Mohammadpur Future Town, 40 Feet
                            Main Road, Mohammadpur, Dhaka-1207
                          </a>
                        </div>
                      </div>
                    </li>
                    <li className="phone">
                      <div className="row">
                        <div className="col-1">
                          {" "}
                          <i className=" fa fa-phone"></i>
                        </div>
                        <div className="col-11">
                          {" "}
                          <a className="heal_a" href="tel://09642919191">
                            09642919191
                          </a>
                        </div>
                      </div>
                    </li>
                    <li className="email">
                      <div className="row">
                        <div className="col-1">
                          <i className=" fa fa-envelope-o"></i>{" "}
                        </div>
                        <div className="col-11">
                          {" "}
                          <a
                            className="heal_a"
                            href="mailto:heal.holmes@gmail.com"
                          >
                            heal.holmes@gmail.com
                          </a>
                        </div>
                      </div>
                    </li>
                    <li className="certificate">
                      <div className="row">
                        <div className="col-1">
                          <i>TIN</i>{" "}
                        </div>
                        <div className="col-11">
                          {" "}
                          <span style={{ color: "dededebd" }}>
                            124996472186
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="foot-link-box footlink-box_btn">
                  <ul>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/healholmesbd"
                      >
                        <i class="fa fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <i class="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/healholmesbd"
                      >
                        <i class="fa fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/company/heal-holmes/"
                      >
                        <i class="fa fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="copyright">
                <p>
                  © Heal Holmes {new Date().getFullYear()} Allright Reserved
                </p>
                <a href="#" id="scroll">
                  <i class="fa fa-angle-double-up"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <div className="services-sec footer-bg footer-grid container">
        <div className="div">
          <div className="">
            <div className="footer-img float-left">
              <div class="text-center">
                <img
                  className="foot-logo"
                  src={`${process.env.PUBLIC_URL}/assets/images/logo-trans.png`}
                  alt="Heal"
                />
              </div>
              <h4 className="heal-txt">heal holmes Ltd</h4>
              <div className="text-center">
                <a
                  href="https://play.google.com/store/apps/details?id=com.companyname.healholmes_xamarin"
                  target="_blank"
                  rel="external"
                >
                  {" "}
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/google-play-png-logo-3802.png`}
                    width={"200"}
                    alt=""
                  />
                </a>
              </div>
            </div>
            <br />
          </div>
        </div>
        <div className="div">
          <h3 style={{ textTransform: "uppercase", color: "#383996" }}>Menu</h3>
          <div className="footer-contact-info">
            <ul className="list-unstyled">
              <li className="address">
                {" "}
                <Link to="/available-doctors" className="heal_a">
                  Doctors
                </Link>
              </li>
              <li className="phone">
                {" "}
                <Link to="/available-tests" className="heal_a">
                  Tests
                </Link>
              </li>
              <li className="email">
                {" "}
                <Link to="/about-us" className="heal_a">
                  About Us
                </Link>
              </li>
              <li className="email">
                {" "}
                <Link to="/privacy-policy" className="heal_a">
                  Privacy Policy
                </Link>
              </li>
              <li className="email">
                {" "}
                <Link to="/terms-and-conditions" className="heal_a">
                  Terms & Conditions
                </Link>
              </li>
              <li className="email">
                {" "}
                <Link
                  to="/payment-refund-cancellation-delivery-terms"
                  className="heal_a"
                >
                  Payment, Refund, Cancellation and Delivery Terms
                </Link>
              </li>
              <li className="email">
                {" "}
                <a href="https://invoice.sslcommerz.com/invoice-form?&refer=61E3F31A03880"
                target="_blank" >Direct payment</a>
               
              </li>
            </ul>
          </div>
        </div>
        <div className="div">
          <h3 style={{ textTransform: "uppercase", color: "#383996" }}>
            Contact
          </h3>
          <div className="footer-contact-info">
            <ul className="list-unstyled">
              <li className="address">
                <i className="fa fa-map-marker"></i>
                <a
                  target="_blank"
                  className="heal_a"
                  href="http://maps.google.com/maps?q=Heal+Holmes"
                >
                  Plot-08, Block-C, Mohammadpur Future Town, 40 Feet Main Road, Mohammadpur,
                  Dhaka-1207
                </a>
              </li>
              <li className="phone">
                <i className=" fa fa-phone"></i>
                <a className="heal_a" href="tel://09642919191">
                  09642919191
                </a>
              </li>
              <li className="email">
                <i className=" fa fa-envelope-o"></i>{" "}
                <a className="heal_a" href="mailto:heal.holmes@gmail.com">
                  heal.holmes@gmail.com
                </a>
              </li>
              <li className="certificate">
                <i>TIN</i>{" "}
                <span>124996472186</span>
              </li>
            </ul>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Footer;
