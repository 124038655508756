import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import moment from "moment";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";

export default function UploadedTestDetails() {
  let history = useHistory();
  let { presId } = useParams();
  const [uploadedPrescription, setUploadedPrescription] = useState({});
  const [path, setPath] = useState("");

  useEffect(() => {
    console.log("persierd id ");
    getTestDetails();
  }, []);

  const routeToAddPrescription = () => {
    const url = `/admin/test/uploaded-test-prescription/${presId}`;
    window.open(url, "_blank");
  };

  const getTestDetails = () => {
    console.log("uploaded test called");
    AdminAPI.get(`uploaded-prescriptions/${presId}`)
      .then((response) => {
        setUploadedPrescription(response.data.data);
        setPath(
          response.data.path +
            "/" +
            moment(response.data.data.created_at).format("YYYY-MM-DD")
        );
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  return (
    <div>
      <div className="container">
        <div className="d-flex justify-content-lg-end justify-content-sm-start mt-1">
          {(uploadedPrescription.status !== "Reviewed" ||
            uploadedPrescription.test_invoice == null) && (
            <button
              className="btn btn-sm btn-outline-primary"
              onClick={() => {
                routeToAddPrescription();
              }}
            >
              Create invoice
            </button>
          )}

          {path !== "" && (
            <button
              className="btn btn-sm btn-outline-primary"
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_PUBLIC_URL}` +
                    path +
                    "/" +
                    uploadedPrescription.file,
                  "_blank"
                );
                // const a = document.createElement("a");
                // console.log(
                //   "asldjlkasd",
                //   `${process.env.REACT_APP_PUBLIC_URL}` +
                //     path +
                //     "/" +
                //     uploadedPrescription.file
                // );
                // const link=
                //   `${process.env.REACT_APP_PUBLIC_URL}` +
                //   path +
                //   "/" +
                //   uploadedPrescription.file;
                //   a.href= link
                // a.click();
              }}
            >
              Download
            </button>
          )}
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="uploaded-prescriptions">
              {path !== "" && (
                <img
                  src={
                    `${process.env.REACT_APP_PUBLIC_URL}` +
                    path +
                    "/" +
                    uploadedPrescription.file
                  }
                  width="100%"
                  alt="pres"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
