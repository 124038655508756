import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  // useHistory,
  useParams,
} from "react-router-dom";
import { Link } from "react-router-dom";
import { TextField } from "formik-material-ui";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import * as Yup from "yup";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import "../css/add-institution.css";
import _ from "lodash";

export default function DiagnosticTestForm({ admin }) {
  let { testId } = useParams();
  const [open, setOpen] = useState(false);
  const [erroropen, setErrorOpen] = useState(false);

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [diagnosticList, setDiagnosticList] = useState([]);
  const [testData, setTestData] = useState({});
  const [testSearchTerm, setTestSearchTerm] = useState("");
  const [testList, setTestList] = useState([]);
  const [testInputValue, setTestInputValue] = React.useState("");
  const [diagId, setDiagId] = React.useState("");

  const [testInputValueId, setTestInputValueId] = React.useState("");
  const [testObj, setTestObj] = React.useState({});

  // Backend Errors Handle
  const [backendErrorMessage, setBackendErrorMessage] = useState({});

  const [sameMedicineErrors, setSameMedicineErrors] = useState([]);
  const [params, setParams] = useState({
    page: 1,
    per_page: 15,
  });

  useEffect(() => {
    if (testId) {
      getTestData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getDiagnosticList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTestData = () => {
    // let url = `http://localhost:3001/purchase_order/${paramID}`;
    AdminAPI.get(`diagnostic-tests/${testId}`)
      .then((response) => {
        setTestData(response.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const getDiagnosticList = () => {
    AdminAPI.get("diagnostics")
      .then((response) => {
        console.log(response.data.data.data);
        setDiagnosticList(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const handleOnInputCaptureTest = _.debounce((event) => {
    setTestSearchTerm(event.target.value);
  }, 500);
  useEffect(() => {
    if (diagId !== "") {
      getTestList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testSearchTerm, diagId]);
  const getTestList = () => {
    const params = {
      name: testSearchTerm,
    };

    AdminAPI.get("tests", { params: params })
      .then((response) => {
        console.log(response.data.data);
        setTestList(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const validationSchema = Yup.object({
    buying_price: Yup.string().required("This field is required"),
    price: Yup.string().required("This field is required"),
    discount_type: Yup.string().required("This field is required"),
    discount: Yup.string().required("This field is required"),
    status: Yup.string().required("This field is required"),
  });
  const validationSchemaPost = Yup.object({
    service_provider_id: Yup.string().required("This field is required"),
    name: Yup.string().required("This field is required"),
    buying_price: Yup.string().required("This field is required"),
    price: Yup.string().required("This field is required"),
    discount_type: Yup.string().required("This field is required"),
    discount: Yup.string().required("This field is required"),
  });

  const initialValues = {
    service_provider_id: "",
    name: testData.name || "",
    buying_price: testData.buying_price || "",
    price: testData.price || "",
    discount_type: testData.discount_type || "",
    discount: testData.discount || "",
    department: testData.department || "",
    status: testData.status || "",
    home_delivery: testData.home_delivery || false,
    method: testData.method || "",
    details: testData.details || "",
    sample_type: testData.sample_type || "",
    tat: testData.tat || "",
  };

  const onSubmit = (values, { resetForm }) => {
    console.log(values);

    if (testId) {
      let objToSend = { ...values };
      delete objToSend.service_provider_id;
      delete objToSend.test_id;

      AdminAPI.put(`diagnostic-tests/${testId}`, objToSend)
        .then((response) => {
          console.log(response);
          if (response.data.message) {
            setSuccessMessage(response.data.message);
            setOpen(true);
            setErrorOpen(false);
          }
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response.data.errors) {
            setErrorMessage(error.response.data.errors);
            setErrorOpen(true);
            setOpen(false);
          }

          if (error.response.data.message) {
            setErrorMessage(error.response.data.message);
            setErrorOpen(true);
            setOpen(false);
          }
          if (error.response.data.data !== undefined) {
            setErrorOpen(true);
            setOpen(false);
            setSameMedicineErrors([
              ...sameMedicineErrors,
              error.response.data.data,
            ]);
          }

          if (error.response.data.errors !== undefined) {
            setBackendErrorMessage(error.response.data.errors);
          }
        });
    } else {
      let objToSend = { ...values };
      delete objToSend.status;

      AdminAPI.post("diagnostic-tests", objToSend)
        .then((response) => {
          console.log(response);
          if (response.data.message) {
            setSuccessMessage(response.data.message);
            setOpen(true);
            setErrorOpen(false);
          }
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response.data.message) {
            setErrorMessage(error.response.data.message);
            setErrorOpen(true);
            setOpen(false);
          }
          if (error.response.data.data !== undefined) {
            setErrorOpen(true);
            setOpen(false);
            setSameMedicineErrors([
              ...sameMedicineErrors,
              error.response.data.data,
            ]);
          }

          if (error.response.data.errors !== undefined) {
            setBackendErrorMessage(error.response.data.errors);
          }
        });
    }
  };
  return (
    <div>
      <div className="container">
        <div className="d-flex justify-content-lg-end justify-content-sm-start mt-2">
          <Link
            className="btn btn-outline-secondary"
            type="button"
            to={"/admin/diagnostic-test/list"}
          >
            Diagnostic Test
          </Link>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="add-institution">
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={
                      !testId ? validationSchemaPost : validationSchema
                    }
                    onSubmit={onSubmit}
                  >
                    {({ setFieldValue, isValid, dirty }) => (
                      <Form>
                        {!testId && (
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1">
                              Diagnostic
                            </label>
                            <div className="col-sm-7">
                              <Autocomplete
                                id="disabledHelperText"
                                name="service_provider_id"
                                options={diagnosticList.filter(
                                  (dig) => dig.id !== 1
                                )}
                                getOptionLabel={(option) => option.name}
                                onChange={(e, value) => {
                                  if (value == null) {
                                    console.log("Hello");
                                    setTestList([]);
                                  } else {
                                    console.log("sdfdsfsdf", value);
                                    setFieldValue(
                                      "service_provider_id",
                                      value.id
                                    );
                                    setDiagId(value.id);
                                    getTestList();
                                  }
                                }}
                                renderInput={(params) => (
                                  <Field
                                    component={TextField}
                                    {...params}
                                    name={`service_provider_id`}
                                    variant="outlined"
                                    fullWidth
                                  />
                                )}
                              />

                              <ErrorMessage
                                name={`service_provider_id`}
                                component="div"
                                className="error-message"
                              />
                              <p className="error-message">
                                {backendErrorMessage.service_provider_id}
                              </p>
                            </div>
                          </div>
                        )}

                        {testId && (
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1">
                              Diagnostic
                            </label>
                            <label className="col-sm-7 font-weight-bold">
                              {testData.service_provider !== undefined
                                ? testData.service_provider.name
                                : ""}
                            </label>
                          </div>
                        )}

                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1">Test</label>
                          <div className="col-sm-7">
                            <Field
                              className=" form-control"
                              type="text"
                              name="name"
                            />
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="error-message"
                            />
                            <p className="error-message">
                              {backendErrorMessage.name}
                            </p>
                          </div>
                        </div>

                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1">
                            Buying Price
                          </label>
                          {admin && (
                            <div className="col-sm-7">
                              <Field
                                className=" form-control"
                                type="text"
                                name="buying_price"
                              />
                              <ErrorMessage
                                name="buying_price"
                                component="div"
                                className="error-message"
                              />
                              <p className="error-message">
                                {backendErrorMessage.buying_price}
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1">Price</label>
                          <div className="col-sm-7">
                            <Field
                              className=" form-control"
                              type="text"
                              name="price"
                            />
                            <ErrorMessage
                              name="price"
                              component="div"
                              className="error-message"
                            />
                            <p className="error-message">
                              {backendErrorMessage.price}
                            </p>
                          </div>
                        </div>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1">
                            Department
                          </label>
                          <div className="col-sm-7">
                            <Field
                              className=" form-control"
                              type="text"
                              name="department"
                            />
                            <ErrorMessage
                              name="department"
                              component="div"
                              className="error-message"
                            />
                            <p className="error-message">
                              {backendErrorMessage.department}
                            </p>
                          </div>
                        </div>

                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1">
                            Discount
                          </label>
                          <div className="col-sm-7">
                            <Field
                              className=" form-control"
                              type="text"
                              name="discount"
                            />
                            <ErrorMessage
                              name="discount"
                              component="div"
                              className="error-message"
                            />
                            <p className="error-message">
                              {backendErrorMessage.discount}
                            </p>
                          </div>
                        </div>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1">
                            Discount Type
                          </label>
                          <div className="col-sm-7">
                            <Field
                              className=" form-control"
                              as="select"
                              name="discount_type"
                            >
                              <option value=""></option>
                              <option value="%">Percentage</option>

                              <option value="Flat">Flat</option>
                            </Field>
                            <ErrorMessage
                              name="discount_type"
                              component="div"
                              className="error-message"
                            />
                            <p className="error-message">
                              {backendErrorMessage.discount_type}
                            </p>
                          </div>
                        </div>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1">Method</label>
                          <div className="col-sm-7">
                            <Field
                              className=" form-control"
                              type="text"
                              name="method"
                              component="textarea"
                              rows="3"
                            />

                            <p className="error-message">
                              {backendErrorMessage.method}
                            </p>
                          </div>
                        </div>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1">
                            Details
                          </label>
                          <div className="col-sm-7">
                            <Field
                              className=" form-control"
                              type="text"
                              name="details"
                              component="textarea"
                              rows="6"
                            />

                            <p className="error-message">
                              {backendErrorMessage.details}
                            </p>
                          </div>
                        </div>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1">
                            Sample Type
                          </label>
                          <div className="col-sm-7">
                            <Field
                              className=" form-control"
                              type="text"
                              name="sample_type"
                              component="textarea"
                              rows="4"
                            />

                            <p className="error-message">
                              {backendErrorMessage.sample_type}
                            </p>
                          </div>
                        </div>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1">TAT</label>
                          <div className="col-sm-7">
                            <Field
                              className=" form-control"
                              type="text"
                              name="tat"
                            />

                            <p className="error-message">
                              {backendErrorMessage.tat}
                            </p>
                          </div>
                        </div>
                        {testId && (
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1">
                              Status
                            </label>
                            <div className="col-sm-7">
                              <Field
                                className=" form-control"
                                as="select"
                                name="status"
                              >
                                <option value="Active">Active</option>

                                <option value="Inactive">Inactive</option>
                              </Field>
                              <ErrorMessage
                                name="staus"
                                component="div"
                                className="error-message"
                              />
                              <p className="error-message">
                                {backendErrorMessage.status}
                              </p>
                            </div>
                          </div>
                        )}

                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1"></label>
                          <div className="col-sm-7">
                            <Field
                              type="checkbox"
                              name="home_delivery"
                              className={"form-check-input "}
                            />
                            <label
                              htmlFor="home_delivery"
                              className="form-check-label"
                            >
                              Home Sample Collection
                            </label>

                            <p className="error-message">
                              {backendErrorMessage.home_delivery}
                            </p>
                          </div>
                        </div>

                        <div className="col-sm-8 offset-1"></div>

                        <div className="row">
                          <div className="col-md-12 my-4">
                            <Collapse in={open}>
                              <Alert
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setOpen(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                {successMessage}
                              </Alert>
                            </Collapse>
                            <Collapse in={erroropen}>
                              <Alert
                                severity="error"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setErrorOpen(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                {errorMessage}
                              </Alert>
                            </Collapse>
                          </div>
                        </div>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1"></label>
                          <div className="col-sm-7 text-right">
                            <button
                              type="submit"
                              className="btn btn-lg btn-success"
                            >
                              {testId ? "Update" : "Submit"}
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
