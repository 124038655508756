import AppBar from "@material-ui/core/AppBar";
// import ListSubheader from "@material-ui/core/ListSubheader";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
// import ThumbUpIcon from "@material-ui/icons/ThumbUp";
// import Badge from "@material-ui/core/Badge";
import MenuIcon from "@material-ui/icons/Menu";
import PersonIcon from "@material-ui/icons/Person";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import {
  Link,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import adminAuth from "../../routingRules/adminAuth";
import AgentDetails from "./agent/AgentDetails";
import AgentList from "./agent/AgentList";
import AgentRegistration from "./agent/AgentRegistration";
import OfferList from "./offer/OfferList";
import OfferForm from "./offer/OfferForm";
import ViewOffer from "./offer/ViewOffer";
import CovidStore from "./covid/CovidStore";
import DegreeForm from "./degree/DegreeForm";
import DegreeList from "./degree/DegreeList";
import UploadDegreeCSV from "./degree/UploadDegreeCSV";
import DepartmentForm from "./department/DepartmentForm";
import DepartmentList from "./department/DepartmentList";
import UploadDepartmentCSV from "./department/UploadDepartmentCSV";
import DesignationForm from "./designation/DesignationForm";
import DesignationList from "./designation/DesignationList";
import UploadDesignationCSV from "./designation/UploadDesignationCSV";
import DiagnosticForm from "./diagnostics/DiagnosticForm";
import DiagnosticList from "./diagnostics/DiagnosticList";
import DiagnosticTest from "./diagnostics/DiagnosticTest";
import DiagnosticTestForm from "./diagnostics/DiagnosticTestForm";
import DoctorDetails from "./doctor/DoctorDetails";
import DoctorList from "./doctor/DoctorList";
import DoctorUpdate from "./doctor/DoctorUpdate";
import Prescriptions from "./generated-prescriptions/Prescriptions";
import ShowPrescriptionDetails from "./generated-prescriptions/ShowPrescriptionDetails";
import InstituitionForm from "./institution/InstituitionForm";
import InstituitionList from "./institution/InstituitionList";
import UploadInstituitionCSV from "./institution/UploadInstituitionCSV";
import MedicineForm from "./medicine/MedicineForm";
import MedicineList from "./medicine/MedicineList";
import UploadMedicineCSV from "./medicine/UploadMedicineCSV";
import PatientAppointments from "./patient-appointments/index";
import PatientDetails from "./patient/PatientDetails";
import PatientForm from "./patient/PatientForm";
import PatientList from "./patient/PatientList";
import AddPrescription from "./prescription/AddPrescription";
import UploadedPrescriptions from "./prescription/UploadedPrescriptions";
import ViewPrescriptions from "./prescription/ViewPrescriptions";
import ResetPassword from "./reset-password";
import AddTestInvoice from "./test-invoices/AddTestInvoice";
import AddTestPrecription from "./test-invoices/AddTestPrecription";
import CreatedTestDetails from "./test-invoices/CreatedTestDetails";
import PatientCreatedTest from "./test-invoices/PatientCreatedTest";
import PatientUploadedTest from "./test-invoices/PatientUploadedTest";
import UploadedTestDetails from "./test-invoices/UploadedTestDetails";
import IncomingInvoiceList from "./incoming-invoice/IncomingInvoiceList";
import IncomingInvoiceDetailList from "./incoming-invoice-detail/IncomingInvoiceDetailList";
import BankAccountList from "./bankAccount/BankAccountList";
import BankAccountForm from "./bankAccount/BankAccountForm";
import TransactionList from "./transaction/TransactionList";
import TransactionView from "./transaction/TransactionView";
import PaymentList from "./payment/PaymentList";
import PaymentDetail from "./payment/PaymentDetail";
// import ScheduleIcon from "@material-ui/icons/Schedule";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AddTest from "./test/AddTest";
import TestList from "./test/TestList";
import { toast } from "react-toastify";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import HospitalList from "./hospital/HospitalList";
import HospitalRegistration from "./hospital/HospitalRegistration";

import HospitalDetails from "./hospital/HospitalDetails";
import HospitalForm from "./hospital/HospitalForm";
import IncommingInvoiceDetail from "./incoming-invoice/IncomingInvoiceDetail";
import NonMaskingForm from "./sms/NonMaskingForm";
import MaskingForm from "./sms/MaskingForm";
import CustomInvoice from "./test-invoices/CustomInvoice";
import SMSList from "./sms/SMSList";
import CouponList from "./coupon/CouponList";
import CouponForm from "./coupon/CouponForm";
import UserDetails from "./users/UserDetails";
import UserList from "./users/UserList";
import UserForm from "./users/UserForm";
import DoctorForm from "./doctor/DoctorForm";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  nested: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    fontSize: 14,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },

  appBar: {
    backgroundColor: "#3f4d67",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },

  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  hide: {
    display: "none",
  },
  drawerPaper: {
    backgroundColor: "#3f4d67",
    paddingBottom: "15vh",
    width: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      top: "65px",
    },
    [theme.breakpoints.down("sm")]: {
      top: "55px",
    },
    [theme.breakpoints.down("xs")]: {
      top: "0",
    },
  },

  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: "4rem",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  list: {
    color: "#a9b7d0",
    "&:hover": {
      color: "#fff!important",
      // backgroundColor: "blue!important",
    },
  },
  selected: {
    color: "#fff",
    backgroundColor: "rgba(255, 201, 201, 0.08)!important",
    "&:hover": {
      // color: "#fff",
    },
    // background: "blue",
  },
}));
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      {/* <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "} */}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function AdminDashboard(props) {
  let history = useHistory();
  let match = useRouteMatch();
  let location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [admin, setAdmin] = React.useState(true);
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [open, setOpen] = React.useState(true);
  const [currentExpand, setCurrentExpand] = React.useState({
    name: "",
  });
  const [notificationData, setNotificationData] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  useEffect(() => {
    getLocalNotification();

    // getusers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    saveLocalNotification();
  }, [notificationData]);
  const saveLocalNotification = () => {
    var adminId = localStorage.getItem("adminId");
    localStorage.setItem(
      `HHAdmin${adminId}Notification`,
      JSON.stringify(notificationData)
    );
  };
  const getLocalNotification = () => {
    var adminId = localStorage.getItem("adminId");
    if (localStorage.getItem(`HHAdmin${adminId}Notification`) === null) {
      localStorage.setItem(`HHAdmin${adminId}Notification`, JSON.stringify([]));
    } else {
      let localNotification = JSON.parse(
        localStorage.getItem(`HHAdmin${adminId}Notification`)
      );

      setNotificationData(localNotification);
    }
  };
  const addNotification = (noti) => {
    var adminId = localStorage.getItem("adminId");
    let localNotification = JSON.parse(
      localStorage.getItem(`HHAdmin${adminId}Notification`)
    );

    let data = [];
    if (localNotification && localNotification.length > 0) {
      data = localNotification;
    }
    data.push(noti);

    setNotificationData(data);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleExpand = (list) => {
    if (currentExpand.name === list.name) {
      setCurrentExpand({ name: "" });
    } else {
      setCurrentExpand({ name: list.name });
    }
  };
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (data, index) => {
    console.log("data====>", data);
    if (data.type === "appointment_call") {
      history.push(`/patient/support/start-video/${data.id}`);
    }
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const token = JSON.parse(localStorage.getItem("adminToken"));
  const options = {
    broadcaster: "pusher",
    key: `${process.env.REACT_APP_PUSHER_KEY}`,
    cluster: `${process.env.REACT_APP_PUSHER_CLUSTER}`,
    encrypted: true,
    authEndpoint: `${process.env.REACT_APP_BASE_URL}/broadcasting/auth`,
    auth: {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    },
  };
  window.Echo = new Echo(options);

  useEffect(() => {
    window.Echo.private(`admin.activity`).listen(".admin.activities", (e) => {
      addNotification(e);
      toast.success(e.message);
      // alert(e.message);

      console.log(e);
    });
  }, []);
  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const logOut = () => {
    adminAuth.logout(() => {
      var adminId = localStorage.getItem("adminId");
      localStorage.removeItem(`HHAdmin${adminId}Notification`);
      localStorage.removeItem(`isSub_heal_holmes`);
      history.push("/");
    });
  };
  const profile = () => {
    history.push(`${match.url}/profile`);
  };
  const resetPassword = () => {
    history.push(`${match.url}/reset-password`);
  };
  useEffect(() => {
    myFunction();
    return () => {
      setAdmin(true); // This worked for me
    };
  }, []);

  const myFunction = () => {
    const isSub = JSON.parse(localStorage.getItem("isSub_heal_holmes"));
    if (isSub && isSub !== 1) {
      setAdmin(false);
    }
    // console.log("is sub from dash", isSub);
  };

  const drawer = (
    <div>
      <Divider />

      <Divider />
      <List>
        {adminRoutes.map((route, index) => (
          <React.Fragment>
            {!route.multiple && (
              <ListItem
                key={index}
                selected={location.pathname === route.route}
                button
                component={Link}
                onClick={() => {
                  if (isMobile) handleDrawerToggle();
                }}
                to={route.route}
                classes={{ root: classes.list, selected: classes.selected }}
              >
                <ListItemIcon>
                  <Icon
                    style={{
                      color:
                        location.pathname === route.route ? "#fff" : "#a9b7d0",
                    }}
                  >
                    {route.icon}
                  </Icon>
                </ListItemIcon>
                <ListItemText primary={route.name} />
              </ListItem>
            )}
            {route.multiple && (
              <div>
                <ListItem
                  selected={currentExpand.name === route.name}
                  button
                  onClick={() => handleExpand(route)}
                  classes={{ root: classes.list, selected: classes.selected }}
                >
                  <ListItemIcon>
                    <Icon
                      style={{
                        color:
                          location.pathname === route.route
                            ? "#fff"
                            : "#a9b7d0",
                      }}
                    >
                      {route.icon}
                    </Icon>
                  </ListItemIcon>
                  <ListItemText primary={route.name} />
                  {currentExpand.name === route.name ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItem>
                <Collapse
                  in={currentExpand.name === route.name}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {route.list && route.list.length > 0 && (
                      <div>
                        {route.list.map((list, i) => (
                          <ListItem
                            className={classes.nested}
                            key={i}
                            selected={location.pathname === list.route}
                            button
                            component={Link}
                            onClick={() => {
                              if (isMobile) handleDrawerToggle();
                            }}
                            to={list.route}
                            classes={{
                              root: classes.list,
                              selected: classes.selected,
                            }}
                          >
                            <ListItemIcon>
                              <Icon
                                style={{
                                  color:
                                    location.pathname === list.route
                                      ? "#fff"
                                      : "#a9b7d0",
                                }}
                              >
                                {list.icon}
                              </Icon>
                            </ListItemIcon>
                            <ListItemText primary={list.name} />
                          </ListItem>
                        ))}
                      </div>
                    )}
                  </List>
                </Collapse>
              </div>
            )}
          </React.Fragment>
        ))}
      </List>

      <Divider />
    </div>
  );

  const container =
    props.window !== undefined ? () => window().document.body : undefined;

  return (
    <div>
      <AppBar className={classes.appBar} position="fixed">
        <Toolbar className={classes.toolbar}>
          <Hidden smUp implementation="css">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              edge="start"
              className={clsx(classes.menuButton)}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Hidden xsDown implementation="css">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton)}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>

          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Dashboard
          </Typography>

          <Tooltip title="Change Password">
            <MenuItem>
              <IconButton
                onClick={handleClickListItem}
                aria-label="show 11 new notifications"
                color="inherit"
              >
                <Badge badgeContent={notificationData.length} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </MenuItem>
          </Tooltip>
          <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {notificationData.map((option, index) => (
              <MenuItem
                style={{ whiteSpace: "normal", maxWidth: "220" }}
                key={index}
                // disabled={index === 0}
                selected={index === selectedIndex}
                onClick={() => handleMenuItemClick(option.data, index)}
              >
                <p> {option.message}</p>
              </MenuItem>
            ))}
          </Menu>

          <Tooltip title="Change Password">
            <IconButton onClick={resetPassword} color="inherit">
              <VpnKeyIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Profile">
            <IconButton onClick={profile} color="inherit">
              <PersonIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Logout">
            <IconButton onClick={logOut} color="inherit" aria-label="logout">
              <ExitToAppIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.appBarSpacer} />
        {/* <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item md={12} lg={12}>
              <Paper> */}
        <Switch>
          <Route path={`${match.path}/test/list`}>
            <TestList />
          </Route>
          <Route path={`${match.path}/test/add`}>
            <AddTest />
          </Route>
          <Route path={`${match.path}/test/update-test/:testId`}>
            <AddTest />
          </Route>

          <Route path={`${match.path}/doctor/list`}>
            <DoctorList />
          </Route>
          <Route path={`${match.path}/doctor/add`}>
            <DoctorForm />
          </Route>
          <Route path={`${match.path}/patient/list`}>
            <PatientList />
          </Route>
          <Route path={`${match.path}/add-patient`}>
            <PatientForm />
          </Route>
          <Route path={`${match.path}/patient/update/:pId`}>
            <PatientForm />
          </Route>
          <Route path={`${match.path}/patient/view/:pId`}>
            <PatientDetails />
          </Route>
          <Route path={`${match.path}/doctor/view/:docId`}>
            <DoctorDetails />
          </Route>
          <Route path={`${match.path}/doctor/update/:doc`}>
            <DoctorUpdate />
          </Route>
          <Route path={`${match.path}/agent/list`}>
            <AgentList />
          </Route>
          <Route path={`${match.path}/agent/add`}>
            <AgentRegistration />
          </Route>

          <Route path={`${match.path}/agent/view/:id`}>
            <AgentDetails />
          </Route>
          <Route path={`${match.path}/coupon/list`}>
            <CouponList />
          </Route>
          <Route path={`${match.path}/coupon/add`}>
            <CouponForm />
          </Route>
          <Route path={`${match.path}/coupon/view/:id`}>
            <CouponForm />
          </Route>
          <Route path={`${match.path}/hospital/list`}>
            <HospitalList />
          </Route>
          <Route path={`${match.path}/hospital/add`}>
            <HospitalForm />
          </Route>
          <Route path={`${match.path}/hospital/view/:id`}>
            <HospitalDetails />
          </Route>
          <Route path={`${match.path}/reset-password`}>
            <ResetPassword />
          </Route>
          <Route exact={true} path={`${match.path}/uploaded-prescriptions`}>
            <UploadedPrescriptions />
          </Route>
          <Route exact={true} path={`${match.path}/generated-prescriptions`}>
            <Prescriptions />
          </Route>
          <Route
            exact={true}
            path={`${match.path}/generated-prescriptions/view/:id`}
          >
            <ShowPrescriptionDetails />
          </Route>
          <Route path={`${match.path}/prescription/view/:presId`}>
            <ViewPrescriptions />
          </Route>
          <Route path={`${match.path}/prescription/add/:presId`}>
            <AddPrescription />
          </Route>
          <Route exact={true} path={`${match.path}/test-list`}>
            <PatientCreatedTest admin={admin} />
          </Route>
          <Route path={`${match.path}/test/view/:testId`}>
            <CreatedTestDetails admin={admin} />
          </Route>
          <Route path={`${match.path}/add-invoice`}>
            <AddTestInvoice />
          </Route>
          <Route exact={true} path={`${match.path}/test/uploaded-test`}>
            <PatientUploadedTest />
          </Route>
          <Route path={`${match.path}/test/uploaded-test/:presId`}>
            <UploadedTestDetails />
          </Route>
          <Route exact={true} path={`${match.path}/incoming-invoice-list`}>
            <IncomingInvoiceList />
          </Route>
          <Route exact={true} path={`${match.path}/incoming-invoice/view/:id`}>
            <IncommingInvoiceDetail />
          </Route>
          <Route exact={true} path={`${match.path}/incoming-test-list`}>
            <IncomingInvoiceDetailList admin={admin} />
          </Route>
          <Route path={`${match.path}/test/uploaded-test-prescription/:presId`}>
            <AddTestPrecription />
          </Route>
          <Route exact={true} path={`${match.path}/diagnostic/list`}>
            <DiagnosticList />
          </Route>
          <Route exact={true} path={`${match.path}/diagnostics/:dId`}>
            <DiagnosticForm />
          </Route>
          <Route exact={true} path={`${match.path}/add-diagnostic`}>
            <DiagnosticForm />
          </Route>
          <Route exact={true} path={`${match.path}/diagnostic-test/list`}>
            <DiagnosticTest admin={admin} />
          </Route>
          <Route exact={true} path={`${match.path}/diagnostic-tests/:testId`}>
            <DiagnosticTestForm admin={admin} />
          </Route>
          <Route exact={true} path={`${match.path}/appointments`}>
            <PatientAppointments />
          </Route>
          <Route exact={true} path={`${match.path}/add-covid-banner`}>
            <CovidStore />
          </Route>
          <Route exact={true} path={`${match.path}/offer-list`}>
            <OfferList />
          </Route>
          <Route exact={true} path={`${match.path}/add-offer`}>
            <OfferForm />
          </Route>
          <Route exact={true} path={`${match.path}/view-offer/:id`}>
            <ViewOffer />
          </Route>
          <Route exact path={`${match.path}/medicine/list`}>
            <MedicineList />
          </Route>
          <Route path={`${match.path}/medicine/add`}>
            <MedicineForm />
          </Route>
          <Route path={`${match.path}/medicine/view/:id`}>
            <MedicineForm />
          </Route>
          <Route path={`${match.path}/medicine/upload-csv`}>
            <UploadMedicineCSV />
          </Route>
          <Route path={`${match.path}/institution/list`}>
            <InstituitionList />
          </Route>
          <Route path={`${match.path}/institution/add`}>
            <InstituitionForm />
          </Route>
          <Route path={`${match.path}/institution/view/:id`}>
            <InstituitionForm />
          </Route>
          <Route path={`${match.path}/institution/upload-csv`}>
            <UploadInstituitionCSV />
          </Route>
          <Route path={`${match.path}/degree/list`}>
            <DegreeList />
          </Route>
          <Route path={`${match.path}/degree/add`}>
            <DegreeForm />
          </Route>
          <Route path={`${match.path}/degree/view/:id`}>
            <DegreeForm />
          </Route>
          <Route path={`${match.path}/degree/upload-csv`}>
            <UploadDegreeCSV />
          </Route>
          <Route path={`${match.path}/department/list`}>
            <DepartmentList />
          </Route>
          <Route path={`${match.path}/department/add`}>
            <DepartmentForm />
          </Route>
          <Route path={`${match.path}/department/view/:id`}>
            <DepartmentForm />
          </Route>
          <Route path={`${match.path}/department/upload-csv`}>
            <UploadDepartmentCSV />
          </Route>
          <Route path={`${match.path}/designation/list`}>
            <DesignationList />
          </Route>
          <Route path={`${match.path}/designation/add`}>
            <DesignationForm />
          </Route>
          <Route path={`${match.path}/designation/view/:id`}>
            <DesignationForm />
          </Route>
          <Route path={`${match.path}/designation/upload-csv`}>
            <UploadDesignationCSV />
          </Route>

          <Route path={`${match.path}/sms-list`}>
            <SMSList />
          </Route>
          <Route path={`${match.path}/sms-non-masking`}>
            <NonMaskingForm />
          </Route>
          <Route path={`${match.path}/sms-masking`}>
            <MaskingForm />
          </Route>
          {admin && (
            <React.Fragment>
              <Route
                exact={true}
                path={`${match.path}/diagnostic-test/add-test`}
              >
                <DiagnosticTestForm admin={admin} />
              </Route>
              <Route path={`${match.path}/custom-invoice`}>
                <CustomInvoice admin={admin} />
              </Route>
              <Route path={`${match.path}/bank-account/list`}>
                <BankAccountList admin={admin} />
              </Route>
              <Route path={`${match.path}/bank-account/add`}>
                <BankAccountForm admin={admin} />
              </Route>
              <Route path={`${match.path}/bank-account/edit/:id`}>
                <BankAccountForm admin={admin} />
              </Route>
              <Route path={`${match.path}/transaction/list`}>
                <TransactionList admin={admin} />
              </Route>
              <Route path={`${match.path}/transaction/view/:id`}>
                <TransactionView admin={admin} />
              </Route>
              <Route path={`${match.path}/payment/list`}>
                <PaymentList admin={admin} />
              </Route>
              <Route path={`${match.path}/payment/view/:id`}>
                <PaymentDetail admin={admin} />
              </Route>
              <Route path={`${match.path}/user/view/:id`}>
                <UserDetails admin={admin} />
              </Route>
              <Route path={`${match.path}/user/list`}>
                <UserList admin={admin} />
              </Route>
              <Route path={`${match.path}/user/add`}>
                <UserForm admin={admin} />
              </Route>
            </React.Fragment>
          )}
        </Switch>
        {/* </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container> */}
      </main>
    </div>
  );
}

AdminDashboard.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const adminRoutes = [
  {
    name: "Dashboard",
    icon: "dashboard",
    route: "/admin",
    multiple: false,
  },
  {
    name: "Clients",
    icon: "people",
    multiple: true,
    list: [
      {
        name: "Agents",
        icon: "support_agent",
        route: "/admin/agent/list",
        multiple: false,
      },
      {
        name: "Hospitals",
        icon: "hospital",
        route: "/admin/hospital/list",
        multiple: false,
      },
    ],
  },
  {
    name: "Patients",
    icon: "people",
    multiple: true,
    list: [
      {
        name: "List",
        icon: "portrait",
        route: "/admin/patient/list",
        multiple: false,
      },
      {
        name: "Uploaded Test",
        icon: "picture_as_pdf",
        route: "/admin/test/uploaded-test",
        multiple: false,
      },
      // {
      //   name: "Uploaded Prescription",
      //   icon: "drive_folder_upload",
      //   route: "/admin/uploaded-prescriptions",
      //   multiple: false,
      // },
      {
        name: "Appointments",
        icon: "book_online",
        route: "/admin/appointments",
        multiple: false,
      },
    ],
  },
  {
    name: "Doctors",
    icon: "people",
    multiple: true,
    list: [
      {
        name: "Doctors",
        icon: "people",
        route: "/admin/doctor/list",
        multiple: false,
      },
      {
        name: "Prescriptions",
        icon: "assignment",
        route: "/admin/generated-prescriptions",
        multiple: false,
      },
    ],
  },
  {
    name: "Test Invoices",
    icon: "list_alt",
    multiple: true,
    list: [
      {
        name: "Test List",
        icon: "science",
        route: "/admin/test-list",
        multiple: false,
      },
      {
        name: "Custom Invoice",
        icon: "science",
        route: "/admin/custom-invoice",
        multiple: false,
      },
      // {
      //   name: "Uploaded Test",
      //   icon: "picture_as_pdf",
      //   route: "/admin/test/uploaded-test",
      //   multiple: false,
      // }
    ],
  },
  {
    name: "Incoming Invoices",
    icon: "list_alt",
    multiple: true,
    list: [
      {
        name: "Invoice list",
        icon: "list_alt",
        route: "/admin/incoming-invoice-list",
        multiple: false,
      },
      {
        name: "Test List",
        icon: "science",
        route: "/admin/incoming-test-list",
        multiple: false,
      },
    ],
  },
  {
    name: "Diagnostics",
    icon: "biotech",
    multiple: true,
    list: [
      {
        name: "Diagnostic List",
        icon: "list",
        route: "/admin/diagnostic/list",
        multiple: false,
      },
      {
        name: "Diagnostic Tests",
        icon: "bloodtype",
        route: "/admin/diagnostic-test/list",

        multiple: false,
      },
    ],
  },
  {
    name: "Anouncement",
    icon: "public",
    multiple: true,
    list: [
      {
        name: "Covid",
        icon: "coronavirus",
        route: "/admin/add-covid-banner",
        multiple: false,
      },
      {
        name: "Offer",
        icon: "celebration",
        route: "/admin/offer-list",
        multiple: false,
      },
    ],
  },
  {
    name: "Accounts",
    icon: "receipt_long",
    multiple: true,
    list: [
      {
        name: "Bank accounts",
        icon: "account_balance",
        route: "/admin/bank-account/list",
        multiple: false,
      },
      {
        name: "Transactions",
        icon: "receipt",
        route: "/admin/transaction/list",
        multiple: false,
      },
      {
        name: "Payments",
        icon: "paid",
        route: "/admin/payment/list",
        multiple: false,
      },
    ],
  },
  {
    name: "User",
    icon: "people",
    route: "/admin/user/list",
    multiple: false,
  },
  {
    name: "Dataset",
    icon: "pix",
    multiple: true,
    list: [
      {
        name: "Medicines",
        icon: "medication",
        route: "/admin/medicine/list",
        multiple: false,
      },
      {
        name: "Tests",
        icon: "opacity",
        route: "/admin/test/list",
        multiple: false,
      },
      {
        name: "Institutions",
        icon: "apartment",
        route: "/admin/institution/list",
        multiple: false,
      },
      {
        name: "Degree",
        icon: "school",
        route: "/admin/degree/list",
        multiple: false,
      },
      {
        name: "Department",
        icon: "local_library",
        route: "/admin/department/list",
        multiple: false,
      },
      {
        name: "Designation",
        icon: "mode",
        route: "/admin/designation/list",
        multiple: false,
      },
    ],
  },
  {
    name: "Coupon",
    icon: "support_agent",
    route: "/admin/coupon/list",
    multiple: false,
  },
  {
    name: "SMS",
    icon: "sms",
    route: "/admin/send-sms",
    multiple: true,
    list: [
      {
        name: "SMS List",
        icon: "message",
        route: "/admin/sms-list",
        multiple: false,
      },
      {
        name: "Non Masking",
        icon: "do_not_disturb_alt",
        route: "/admin/sms-non-masking",
        multiple: false,
      },
      {
        name: "Masking",
        icon: "masks",
        route: "/admin/sms-masking",
        multiple: false,
      },
    ],
  },
];
