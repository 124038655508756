import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { TextField as TextFieldSecond } from "formik-material-ui";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Link } from "react-router-dom";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import axios from "axios";
import * as Yup from "yup";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";

import _ from "lodash";
import { Button } from "@material-ui/core";

export default function CovidStore() {
  let history = useHistory();
  const [files, setFiles] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [serverErrors, setServerErrors] = useState({});

  const uploadMultipleFiles = (e) => {
    const fileList = Array.from(e.target.files);
    if (checkMimeType(e)) {
      setFiles(fileList);
      const mappedFiles = fileList.map((file) => ({
        ...file,
        preview: URL.createObjectURL(file),
      }));

      setPreviews(mappedFiles);
      // if return true allow to setState
    }
  };
  const checkMimeType = (event) => {
    //getting file object
    let files = event.target.files;
    //define message container
    let err = "";
    // list allow mime type
    const types = ["image/png", "image/jpeg", "image/gif"];
    // loop access array
    for (var x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every((type) => files[x].type !== type)) {
        // create error message and assign to container
        err += files[x].type + " is not a supported format\n";
      }
    }

    if (err !== "") {
      // if message not same old that mean has error
      event.target.value = null; // discard selected file
      alert(err);
      return false;
    }
    return true;
  };

  const onSubmit = () => {
    if (files.length === 0) {
      alert("Please upload an image");
    } else {
      let formData = new FormData();
      formData.append("image", files[0]);
      AdminAPI.post("covid", formData)
        .then((response) => {
          console.log(response);
          alert("Image successfully uploaded");

          //   console.log(response.data.message);

          history.push("/admin");
        })
        .catch((error) => {
          console.log(error.response);
          setServerErrors(error.response.data.errors);
        });
    }
  };
  return (
    <div>
      <div className="doc-register-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <div className="row">
                <div className="col-lg-12">
                  <div className="doc-register-form">
                    <div className="form-group row prescription  ">
                      <label className="col-md-3 ">Image</label>
                      <div className="col-md-9">
                        <input
                          id={`btn-input`}
                          style={{ display: "none" }}
                          multiple={false}
                          type="file"
                          onChange={(event) => {
                            uploadMultipleFiles(event);
                          }}
                        />

                        <label htmlFor={`btn-input`}>
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                          >
                            {files.length > 0 ? "Change" : "Upload"}
                          </Button>
                        </label>
                        {!_.isEmpty(serverErrors) &&
                          serverErrors.image !== undefined && (
                            <span className="error-msg">
                              {" "}
                              {serverErrors.image}
                            </span>
                          )}

                        {previews !== undefined && previews.length > 0 && (
                          <div className="form-group multi-preview">
                            {(previews || []).map((url, key) => (
                              <div key={key} className="container_img">
                                <img src={url.preview} alt="..." />
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <button
                          onClick={() => onSubmit()}
                          className="btn btn-lg btn-success"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-1"></div>
        </div>
      </div>
    </div>
  );
}
