import axios from "axios";
import history from "../history";
const PatientAPI = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/patient`,
  // baseURL: "https://beta.heal-holmes.com/api/api/patient",
});

PatientAPI.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const patientToken = JSON.parse(localStorage.getItem("patientToken"));

      if (patientToken) {
        config.headers.Authorization = `Bearer ${patientToken}`;
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);
PatientAPI.interceptors.response.use(
  (response) => {
    console.log(response.status);
    // console.log(response.status);
    // Edit response config
    return response;
  },
  (error) => {
    if (error.response !== undefined) {
      if (error.response.status === 401) {
        localStorage.removeItem("patientToken");
        history.push("/patient/login");
      }
      if (error.response.status === 403) {
        console.log("ERRRRR", error.response);
        alert("Please buy your healh card");
        return;
      }
    }

    console.log(error);
    return Promise.reject(error);
  }
);

export { PatientAPI };
