import axios from "axios";
import history from "../history";
const CorporateAPI = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/corporate`,
  // baseURL: "https://beta.heal-holmes.com/api/api/agent",
});

CorporateAPI.interceptors.request.use(
  (config) => {
    document.body.classList.add("loading-indicator");
    if (!config.headers.Authorization) {
      const corporateToken = JSON.parse(localStorage.getItem("corporateToken"));

      if (corporateToken) {
        config.headers.Authorization = `Bearer ${corporateToken}`;
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);
CorporateAPI.interceptors.response.use(
  (response) => {
    document.body.classList.remove("loading-indicator");
    console.log(response.status);
    // console.log(response.status);
    // Edit response config
    return response;
  },
  (error) => {
    document.body.classList.remove("loading-indicator");
    if (error.response !== undefined) {
      if (error.response.status === 401) {
        localStorage.removeItem("corporateToken");
        history.push("/corporate/login");
      }
    }

    console.log(error);
    return Promise.reject(error);
  }
);

export { CorporateAPI };
