import axios from "axios";
import history from "../history";
const HospitalAPI = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/hospital`,
  // baseURL: "https://beta.heal-holmes.com/api/api/agent",
});

HospitalAPI.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const hospitalToken = JSON.parse(localStorage.getItem("hospitalToken"));

      if (hospitalToken) {
        config.headers.Authorization = `Bearer ${hospitalToken}`;
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);
HospitalAPI.interceptors.response.use(
  (response) => {
    console.log(response.status);
    // console.log(response.status);
    // Edit response config
    return response;
  },
  (error) => {
    if (error.response !== undefined) {
      if (error.response.status === 401) {
        localStorage.removeItem("hospitalToken");
        history.push("/agent/login");
      }
    }

    console.log(error);
    return Promise.reject(error);
  }
);

export { HospitalAPI };
