import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import moment from "moment";
// import { PatientAPI } from "../../../AxiosRequest/PatientAPI";
import axios from "axios";

export default function UploadedTestDetails() {
  let history = useHistory();
  let { testId } = useParams();
  const [uploadedTestDetails, setUploadedTestDetails] = useState({});
  const [path, setPath] = useState();

  useEffect(() => {
    getTestDetails();
  }, []);

  const getTestDetails = () => {
    axios
      .get(`uploaded-tests/${testId}`)
      .then((response) => {
        console.log("test details", response.data);
        setUploadedTestDetails(response.data.data);
        setPath(
          response.data.path +
            "/" +
            moment(response.data.data.created_at).format("YYYY-MM-DD")
        );
        // setPatientDetails(response.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  return (
    <div>
      <div className="container">
        {/* <div className="d-flex justify-content-lg-end justify-content-sm-start mt-1">
          <button
            className="btn btn-lg btn-outline-secondary"
            onClick={() => {
              routeToAddPrescription();
            }}
          >
            Add Prescription
          </button>
        </div> */}
        <div className="row">
          <div className="col-md-12">
            <div className="uploaded-prescriptions">
              {uploadedTestDetails !== undefined && (
                <img
                  src={
                    `${process.env.REACT_APP_PUBLIC_URL}` +
                    path +
                    "/" +
                    uploadedTestDetails.file
                  }
                  width="100%"
                  alt="pres"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
