import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { isArray, debounce, isEmpty, get } from "lodash";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import { Button } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const FormikDatePicker = ({
  name,
  form: { setFieldValue },
  field: { value },
  ...rest
}) => {
  // console.log(rest);
  return (
    <DatePicker
      disablePast
      format="dd/MM/yyyy"
      views={["year", "month", "date"]}
      name={name}
      clearable
      autoOk
      inputVariant="outlined"
      // handle clearing outside => pass plain array if you are not controlling value outside
      mask={(value) =>
        value
          ? [/[0-3]/, /\d/, "/", /0|1/, /\d/, "/", /1|2/, /\d/, /\d/, /\d/]
          : []
      }
      onChange={(value) => {
        console.log("setting value to", value);
        console.log("setting value to", moment(value).format("DD/MM/YYYY"));

        setFieldValue("test_date", value);
      }}
      value={value}
      animateYearScrolling={false}
    />
  );
};
const CustomInvoice = () => {
  const navigate = useHistory();
  let { id } = useParams();
  const [serverErrors, setServerErrors] = useState({});
  const [product, setProduct] = useState({
    name: "",
    address: "",
    phone: "",
    date: new Date(),
    paid: "",
    bank_payment: true,
  });
  const [productVariant, setProductVariant] = useState([
    {
      item_name: "",
      price: "",
      quantity: "",
      discount: 0,
    },
  ]);

  const validationSchema = Yup.object({
    name: Yup.string().required("This field is required"),
    paid: Yup.string().required("This field is required"),
    details: Yup.array().of(
      Yup.object().shape({
        item_name: Yup.string().required("Required"),
        price: Yup.number().required("Required").typeError("Required"),
        quantity: Yup.number().required("Required").typeError("Required"),
        discount: Yup.number().required("Required").typeError("Required"),
      })
    ),
  });
  const initialValues = {
    name: product.name,
    address: product.description,
    phone: product.category_id,
    date: product.subcategory_id,
    paid: product.paid,
    bank_payment: product.bank_payment,
    details: productVariant,

    // status: product.status,
  };

  const onSubmit = async (values, { resetForm }) => {
    let objToPost = { ...values };
    objToPost.date = moment(objToPost.date).format("YYYY-MM-DD");
    try {
      const { data } = await AdminAPI.post(`custom-test-invoices`, objToPost, {
        responseType: "blob", //Force to receive data in a Blob Format
      });
      const file = new Blob([data], { type: "application/pdf" }); //Build a URL from the file
      const fileURL = URL.createObjectURL(file); //Open the URL on new Window

      var a = document.createElement("a");
      a.href = fileURL;

      a.download = "heal-holmes-invoice_" + values.name + ".pdf";

      a.click();
      window.URL.revokeObjectURL(fileURL);
      resetForm();
      //   navigate.push("/admin/test-list");
    } catch (error) {
      const { response } = error;
      if (response.data) {
        setServerErrors(response.data.errors);
      }
      console.log("errrr", response.data);
    }
  };
  return (
    <div>
      <div className="doc-register-section">
        <div className="doc-register-form">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ setFieldValue, values, dirty, errors, touched }) => (
              <Form>
                <div className="row">
                  <div className="col-md-4">
                    Name <br />
                    <Field
                      type="text"
                      className="form-control custom-form-control mb-2"
                      id="productName"
                      name="name"
                      placeholder=" Name"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="col-md-4">
                    Address <br />
                    <Field
                      type="text"
                      className="form-control custom-form-control mb-2"
                      id="productName"
                      name="address"
                      placeholder="Address"
                      component="textarea"
                    />
                  </div>
                  <div className="col-md-4">
                    Phone <br />
                    <Field
                      type="text"
                      className="form-control custom-form-control mb-2"
                      id="productName"
                      name="phone"
                      placeholder="phone"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    Date <br />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Field component={FormikDatePicker} name="date" />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div className="col-md-4">
                    Paid <br />
                    <Field
                      type="number"
                      className="form-control custom-form-control mb-2"
                      id="categoryName"
                      name="paid"
                      placeholder="Paid"
                    />
                    <ErrorMessage
                      name={`paid`}
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="col-md-4">
                    <br />
                    <Field
                      type="checkbox"
                      name="bank_payment"
                      className={
                        "form-check-input" +
                        (errors.bank_payment && touched.bank_payment
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <label htmlFor="bank_payment" className="form-check-label">
                      Bank Payment
                    </label>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <div className="areax">
                      <div>
                        <FieldArray
                          name="details"
                          render={(arrayHelpers) => (
                            <div>
                              {values.details.length > 0 &&
                                values.details.map((friend, index) => (
                                  <div className="row mt-2" key={index}>
                                    <div className="col-12">
                                      <div className="row">
                                        <div className="col-md-4">
                                          Test Name <br />
                                          <Field
                                            type="text"
                                            className="form-control custom-form-control mb-2"
                                            id="productName"
                                            name={`details.${index}.item_name`}
                                            placeholder="Test Name"
                                          />
                                          <ErrorMessage
                                            name={`details.${index}.item_name`}
                                            component="div"
                                            className="error-message"
                                          />
                                          {!isEmpty(serverErrors) &&
                                            get(
                                              serverErrors,
                                              `details.${index}.item_name`
                                            ) !== undefined && (
                                              <span className="error-msg">
                                                {get(
                                                  serverErrors,
                                                  `details.${index}.item_name`
                                                )}
                                              </span>
                                            )}
                                        </div>
                                        <div className="col-md-4">
                                          Price <br />
                                          <Field
                                            type="number"
                                            className="form-control custom-form-control mb-2"
                                            id="productName"
                                            name={`details.${index}.price`}
                                            placeholder="Price"
                                          />
                                          <ErrorMessage
                                            name={`details.${index}.price`}
                                            component="div"
                                            className="error-message"
                                          />
                                          {!isEmpty(serverErrors) &&
                                            get(
                                              serverErrors,
                                              `details.${index}.price`
                                            ) !== undefined && (
                                              <span className="error-msg">
                                                {get(
                                                  serverErrors,
                                                  `details.${index}.price`
                                                )}
                                              </span>
                                            )}
                                        </div>
                                        <div className="col-md-4">
                                          Quanity
                                          <Field
                                            type="number"
                                            className="form-control custom-form-control mb-2"
                                            id="productName"
                                            name={`details.${index}.quantity`}
                                            placeholder="Quantity"
                                          />
                                          <ErrorMessage
                                            name={`details.${index}.quantity`}
                                            component="div"
                                            className="error-message"
                                          />
                                          {!isEmpty(serverErrors) &&
                                            get(
                                              serverErrors,
                                              `details.${index}.quantity`
                                            ) !== undefined && (
                                              <span className="error-msg">
                                                {get(
                                                  serverErrors,
                                                  `details.${index}.quantity`
                                                )}
                                              </span>
                                            )}
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-4">
                                          Discount
                                          <Field
                                            type="number"
                                            className="form-control custom-form-control mb-2"
                                            id="productName"
                                            name={`details.${index}.discount`}
                                            placeholder="selling price"
                                          />
                                          <ErrorMessage
                                            name={`details.${index}.discount`}
                                            component="div"
                                            className="error-message"
                                          />
                                          {!isEmpty(serverErrors) &&
                                            get(
                                              serverErrors,
                                              `details.${index}.discount`
                                            ) !== undefined && (
                                              <span className="error-msg">
                                                {get(
                                                  serverErrors,
                                                  `details.${index}.discount`
                                                )}
                                              </span>
                                            )}
                                        </div>
                                      </div>

                                      {values.details.length > 1 && (
                                        <div className="row my-2">
                                          <div className="col-md-11 text-end">
                                            {}
                                            <Button
                                              onClick={() => {
                                                // removeImageIndex(
                                                //   index
                                                // );
                                                arrayHelpers.remove(index);
                                              }}
                                              variant="contained"
                                              color="secondary"
                                            >
                                              Remove
                                            </Button>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              <div className="row pt-3">
                                <div className="col-md-11 ">
                                  <Button
                                    onClick={() =>
                                      arrayHelpers.push({
                                        item_name: "",
                                        price: "",
                                        quantity: "",
                                        discount: 0,
                                      })
                                    }
                                    variant="contained"
                                    color="primary"
                                  >
                                    Add
                                  </Button>
                                </div>
                              </div>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-buttons d-flex justify-content-end">
                  {/* <button className=" btn btn-lg btn_save me-2">Save & New</button> */}
                  <button type="submit" className="btn btn-lg btn-success">
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>

          <hr />
        </div>
      </div>
    </div>
  );
};

export default CustomInvoice;
