import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import _ from "lodash";

export default function AvailableDoctors() {
  const [availableDoctors, setAvailableDoctors] = useState([]);
  const [availableDepartmnet, setAvailableDepartment] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);

  const [params, setParams] = useState({
    per_page: 15,
    page: 1,
  });

  useEffect(() => {
    GetDoctorSchedules();
    GetDoctorDepartment();
  }, []);
  useEffect(() => {
    GetDoctorSchedules();
  }, [params]);
  const GetDoctorSchedules = () => {
    axios
      .get("doctors", { params: params })
      .then((response) => {
        console.log("AvailableDoctors", response.data.data.data)
        setAvailableDoctors(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const GetDoctorDepartment = () => {
    axios
      .get("departments")
      .then((response) => {
        console.log("dept", response.data.data.data);
        setAvailableDepartment(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };

  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);

  const filterByDepartment = (event) => {
    const selectedDept = event.target.value;
    handleColumnWiseSearch(selectedDept, "department");
  };

  //   console.log(availableDoctors);
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="exampleFormControlSelect1">Department</label>
              <select
                className="form-control"
                onChange={(event) => {
                  filterByDepartment(event);
                }}
              >
                <option value="">Filter By Department</option>

                {availableDepartmnet.map((dept, index) => (
                  <option key={index} value={dept.name}>
                    {dept.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-md-4 offset-md-4">
            <div className="form-group">
              <label
                style={{ visibility: "hidden" }}
                htmlFor="exampleFormControlSelect1"
              >
                Department
              </label>
              <input
                placeholder="Search By Name"
                className="form-control"
                type="text"
                onChange={(e) => handleColumnWiseSearch(e.target.value, "name")}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 ">
            <table className="custom-responsive-table">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Degrees</th>
                  <th scope="col">Department</th>
                  <th scope="col">Institution</th>
                  <th scope="col">Designation</th>
                  <th scope="col">Practice Year</th>
                  <th scope="col">Actions</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {availableDoctors.map((doc, i) => (
                  <tr key={i}>
                    <td data-column="Name">{doc.name}</td>
                    <td data-column="Degrees">
                      {doc.doctor_degrees.map((degree, index) => (
                        <React.Fragment key={index}>
                          <span> {degree.degree}</span>
                          {index === doc.doctor_degrees.length - 1 ? "" : ","}
                        </React.Fragment>
                      ))}
                    </td>
                    <td data-column="Department">{doc.department}</td>
                    <td data-column="Institution">{doc.institution}</td>
                    <td data-column="Designation">{doc.designation}</td>
                    <td data-column="Practice Year">{doc.practice_year}</td>

                    <td data-column="Actions">
                      {doc.doctor_appointment_times.length !== 0 ? (
                        <Link
                          type="button"
                          to={`/patient/doctor-schedule/${doc.id}`}
                          className="btn btn-sm btn-success"
                        >
                          View Schedule
                        </Link>
                      ) : (
                        <Link
                          type="button"
                          to="#"
                          className="btn btn-sm btn-danger"
                        >
                          No Schedule
                        </Link>
                      )}
                    </td>
                    <td>
                      <Link
                        type="button"
                        to={`/patient/doctor-details/${doc.id}`}
                        className="btn btn-sm btn-secondary"
                      >
                        Doctor Details
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}
