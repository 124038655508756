import React from "react";
import NavbarHome from "../Shared/NavbarHome";
import PackageSlider from "./PackageSlider";
import Navbar2 from "../Shared/Navbar2";

const Packages = () => {
  return (
    <div>
      <Navbar2 />
      <PackageSlider title={"Our Attractive Test Packages"} />
    </div>
  );
};

export default Packages;
