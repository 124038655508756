class Auth {
  constructor() {
    const pharmacyToken = localStorage.getItem("pharmacyToken");

    if (pharmacyToken !== null) {
      this.authenticated = true;
    }
  }

  login(cb) {
    this.authenticated = true;

    cb();
  }
  logout(cb) {
    this.authenticated = false;
    localStorage.removeItem("pharmacyToken");
    cb();
  }

  isAuthenticated() {
    return this.authenticated;
  }
}

export default new Auth();
