import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import UserForm from "./UserForm";

export default function UserDetails() {
  return (
    <div>
      <UserForm />
    </div>
  );
}
