import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { TextField as TextFieldSecond } from "formik-material-ui";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import moment from "moment";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link, useHistory } from "react-router-dom";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import LockOpenIcon from "@material-ui/icons/LockOpen";
// import { FormikTextField } from "formik-material-fields";
import * as Yup from "yup";

import axios from "axios";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "@material-ui/pickers";
import { isEmpty } from "lodash";

const FormikDatePicker = ({
  name,
  form: { setFieldValue },
  field: { value },
  ...rest
}) => {
  // console.log(rest);
  return (
    <DatePicker
      disableFuture
      openTo="year"
      format="dd/MM/yyyy"
      views={["year", "month", "date"]}
      name={name}
      keyboard
      clearable
      autoOk
      // handle clearing outside => pass plain array if you are not controlling value outside
      mask={(value) =>
        value
          ? [/[0-3]/, /\d/, "/", /0|1/, /\d/, "/", /1|2/, /\d/, /\d/, /\d/]
          : []
      }
      disableOpenOnEnter
      onChange={(value) => {
        console.log("setting value to", value);
        setFieldValue("date_of_birth", value);
      }}
      value={value}
      animateYearScrolling={false}
    />
  );
};

const DoctorForm = () => {
  const history = useHistory();
  const [designations, setDesignations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const [degreeList, setDegreeList] = useState([]);
  const [degreeListChosen, setDegreeListChosen] = useState([]);

  const [fileForPreview, setFileForPreview] = useState("");
  const [uploadedFile, setUploadedFile] = useState("");
  const [fileName, setFileName] = useState("Choose an Image File");
  const [showImage, setShowImage] = useState(false);

  const [loading, setLoading] = useState(false);
  const [signedUpMessage, setSignedUpMessage] = useState("");
  const [singnedUp, setSignedUp] = useState(false);

  // Backend Error Messages
  const [nameErros, setNameErrors] = useState([]);
  const [emailError, setEmailError] = useState([]);
  const [phoneErros, setPhoneError] = useState([]);
  const [ageErrors, setAgeErrors] = useState([]);
  const [bmdcErrors, setBMDCErrors] = useState([]);
  const [designationErrors, setDesignationErrors] = useState([]);
  const [departmentErrors, setDepartmentErrors] = useState([]);
  const [institutionErrors, setInstitutionErrors] = useState([]);
  const [degreeErrors, setDegreeErrors] = useState([]);
  const [practiceYearErrors, setPracticeYearErrors] = useState([]);
  const [passigngYearErrors, setPassingYearErrors] = useState([]);
  const [passingInstituitionErrors, setPassingInstituitionErrors] = useState(
    []
  );
  const [newPatientVisitErrors, setNewPatientVisitErrors] = useState([]);
  const [oldPatientVisitErrors, setOldPatientVisitErrors] = useState([]);
  const [reportVisitErrors, setReportVisitErrors] = useState([]);
  const [oldPatientVisitValidity, setOldPatientVisitValidity] = useState([]);
  const [passwordErrors, setPasswordErrors] = useState([]);
  const [passwordConfirmationErrors, setPaswordConfirmationErros] = useState(
    []
  );
  const [photoErrors, setPhotoErrors] = useState([]);
  const [selectedDate, handleDateChange] = useState(new Date());
  const [backendErrors, setBackendErrors] = useState({});
  const [backendErrorMsg, setBackendErrorMsg] = useState("");
  const fileHandle = (event) => {
    setFileForPreview(URL.createObjectURL(event.target.files[0]));
    setUploadedFile(event.target.files[0]);
    setFileName(event.target.files[0].name);

    setShowImage(true);
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Name field is required"),
    phone: Yup.string().required("Please enter your phone number"),
    email: Yup.string()
      .email("Please Enter a valid Email")
      .required("Email field is required"),

    designation: Yup.string().required("Designation field is required"),
    department: Yup.string().required("Department field is required"),
    institution: Yup.string().required("Institution field is required"),
    type: Yup.string().required("Doctor type is required"),
    // degress: Yup.array().required("Degree is required"),
  });
  const initialValues = {
    name: "",
    phone: "",
    email: "",

    designation: "",
    department: "",
    institution: "",
    type: "",

    // degress: [],
  };

  useEffect(() => {
    getDesignations();
    getDegrees();
    getDepartments();
    getInstutitions();
  }, []);

  const getDesignations = () => {
    axios
      .get("designations")
      .then((response) => {
        setDesignations(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const getDegrees = () => {
    axios
      .get("degrees")
      .then((response) => {
        setDegreeList(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const getDepartments = () => {
    axios
      .get("departments")
      .then((response) => {
        setDepartments(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const getInstutitions = () => {
    axios
      .get("institutions")
      .then((response) => {
        setInstitutions(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    setLoading(true);
    setAgeErrors([]);
    setBackendErrorMsg("");
    let formData = new FormData();
    formData.append("name", values.name);
    formData.append("phone", values.phone);
    formData.append("email", values.email);

    formData.append("degrees", JSON.stringify(degreeListChosen));
    formData.append("department", values.department);
    formData.append("designation", values.designation);
    formData.append("institution", values.institution);
    formData.append("type", values.type);

    AdminAPI.post("doctors", formData, {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //   },
    })
      .then((response) => {
        console.log(response);
        setSignedUp(true);
        setSignedUpMessage("You have successfully signed up.");
        setLoading(false);
        history.push("/admin/doctor/list");
      })
      .catch((error) => {
        console.log(error.response);
        setLoading(false);

        if (error.response.data.message !== undefined) {
          setBackendErrorMsg(error.response.data.message);
        }
        if (error.response.data.errors !== undefined) {
          setBackendErrors(error.response.data.errors);
        }
      });
  };

  return (
    <div>
      <div className="doc-register-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="doc-register-form">
                    <div className="text-center">
                      <LockOpenIcon />
                    </div>
                    <h1 className="text-center">Heal-Holmes</h1>
                    <h3 className="text-center py-2">Doctor Registration</h3>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      {({ setFieldValue, errors, status, touched, values }) => (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row ">
                                <label className="col-sm-3">Name</label>
                                <div className="col-sm-8">
                                  <Field
                                    name="name"
                                    type="text"
                                    className="form-control"
                                    placeholder="Your Name"
                                  />
                                  <ErrorMessage
                                    name="name"
                                    component="div"
                                    className="error-message"
                                  />
                                  {!isEmpty(backendErrors) &&
                                    !isEmpty(backendErrors.name) && (
                                      <div className="error-message">
                                        {backendErrors.name.map((o) => (
                                          <p>{o}</p>
                                        ))}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row ">
                                <label className="col-sm-3">Email</label>
                                <div className="col-sm-8">
                                  <Field
                                    name="email"
                                    type="email"
                                    className="form-control"
                                    placeholder="Email"
                                  />
                                  <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="error-message"
                                  />
                                  {!isEmpty(backendErrors) &&
                                    !isEmpty(backendErrors.email) && (
                                      <div className="error-message">
                                        {backendErrors.email.map((o) => (
                                          <p>{o}</p>
                                        ))}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row ">
                                <label className="col-sm-3">Phone</label>
                                <div className="col-sm-8">
                                  <Field
                                    name="phone"
                                    type="text"
                                    className="form-control"
                                    placeholder="Phone/Mobile Number"
                                  />
                                  <ErrorMessage
                                    name="phone"
                                    component="div"
                                    className="error-message"
                                  />
                                  {!isEmpty(backendErrors) &&
                                    !isEmpty(backendErrors.phone) && (
                                      <div className="error-message">
                                        {backendErrors.phone.map((o) => (
                                          <p>{o}</p>
                                        ))}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row ">
                                <label className="col-sm-3">Designation</label>
                                <div className="col-sm-8">
                                  <Autocomplete
                                    freeSolo
                                    id="disabledHelperText"
                                    name="designation"
                                    options={designations}
                                    getOptionLabel={(option) => option.name}
                                    onInputCapture={(event) => {
                                      event.persist(); // As we are accessing the event in asynchronous way.
                                      setFieldValue(
                                        "designation",
                                        event.target.value
                                      );
                                      // console.log(event.target.value);
                                    }}
                                    onChange={(event, value) => {
                                      console.log("value", value);
                                      if (value == null) {
                                        setFieldValue("designation", "");
                                      } else {
                                        setFieldValue(
                                          "designation",
                                          value.name
                                        );
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <Field
                                        component={TextFieldSecond}
                                        {...params}
                                        name="designation"
                                        variant="outlined"
                                        fullWidth
                                      />
                                    )}
                                  />
                                  <ErrorMessage
                                    name="designation"
                                    component="div"
                                    className="error-message"
                                  />
                                  {!isEmpty(backendErrors) &&
                                    !isEmpty(backendErrors.designation) && (
                                      <div className="error-message">
                                        {backendErrors.designation.map((o) => (
                                          <p>{o}</p>
                                        ))}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row ">
                                <label className="col-sm-3">Department</label>
                                <div className="col-sm-8">
                                  <Autocomplete
                                    freeSolo
                                    id="disabledHelperText"
                                    name="department"
                                    options={departments}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(e, value) => {
                                      if (value == null) {
                                        setFieldValue("department", "");
                                      } else {
                                        setFieldValue("department", value.name);
                                      }
                                    }}
                                    onInputCapture={(event) => {
                                      event.persist(); // As we are accessing the event in asynchronous way.
                                      setFieldValue(
                                        "department",
                                        event.target.value
                                      );
                                      // console.log(event.target.value);
                                    }}
                                    renderInput={(params) => (
                                      <Field
                                        component={TextFieldSecond}
                                        {...params}
                                        name="department"
                                        variant="outlined"
                                        fullWidth
                                      />
                                    )}
                                  />

                                  <ErrorMessage
                                    name="department"
                                    component="div"
                                    className="error-message"
                                  />
                                  {!isEmpty(backendErrors) &&
                                    !isEmpty(backendErrors.department) && (
                                      <div className="error-message">
                                        {backendErrors.department.map((o) => (
                                          <p>{o}</p>
                                        ))}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row ">
                                <label className="col-sm-3">Institution</label>
                                <div className="col-sm-8">
                                  <Autocomplete
                                    id="disabledHelperText"
                                    freeSolo
                                    options={institutions}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, value) => {
                                      if (value == null) {
                                        console.log(null);
                                      } else {
                                        setFieldValue(
                                          "institution",
                                          value.name
                                        );
                                      }
                                    }}
                                    onInputCapture={(event) => {
                                      event.persist(); // As we are accessing the event in asynchronous way.
                                      setFieldValue(
                                        "institution",
                                        event.target.value
                                      );
                                      // console.log(event.target.value);
                                    }}
                                    renderInput={(params) => (
                                      <Field
                                        component={TextFieldSecond}
                                        {...params}
                                        name="institution"
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                  <ErrorMessage
                                    name="institution"
                                    component="div"
                                    className="error-message"
                                  />
                                  {!isEmpty(backendErrors) &&
                                    !isEmpty(backendErrors.institution) && (
                                      <div className="error-message">
                                        {backendErrors.institution.map((o) => (
                                          <p>{o}</p>
                                        ))}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row ">
                                <label className="col-sm-3">Degree</label>
                                <div className="col-sm-8">
                                  <Autocomplete
                                    multiple
                                    id="disabledHelperText"
                                    // id="tags-filled"
                                    options={degreeList.map(
                                      (option) => option.name
                                    )}
                                    // defaultValue={[degreeList[0].name]}
                                    freeSolo
                                    renderTags={(value, getTagProps) => {
                                      setDegreeListChosen(value);

                                      return value.map((option, index) => (
                                        <Chip
                                          variant="outlined"
                                          label={option}
                                          {...getTagProps({ index })}
                                        />
                                      ));
                                    }}
                                    renderInput={(params) => (
                                      <Field
                                        component={TextFieldSecond}
                                        {...params}
                                        name="degress"
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                  <ErrorMessage
                                    name="degress"
                                    component="div"
                                    className="error-message"
                                  />
                                  {!isEmpty(backendErrors) &&
                                    !isEmpty(backendErrors.degress) && (
                                      <div className="error-message">
                                        {backendErrors.degress.map((o) => (
                                          <p>{o}</p>
                                        ))}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row ">
                                <label className="col-sm-3 ">Type</label>
                                <div className="col-sm-8">
                                  <Field
                                    className=" form-control"
                                    as="select"
                                    name="type"
                                  >
                                    <option value=""></option>
                                    <option value="Referral">Referral</option>

                                    <option value="Regular">Regular</option>
                                  </Field>
                                  <ErrorMessage
                                    name="type"
                                    component="div"
                                    className="error-message"
                                  />
                                  {!isEmpty(backendErrors) &&
                                    !isEmpty(backendErrors.type) && (
                                      <div className="error-message">
                                        {backendErrors.type.map((o) => (
                                          <p>{o}</p>
                                        ))}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="form-group row ">
                            <label className="col-sm-5 offset-sm-1">
                              <button
                                disabled={loading}
                                // disabled={!isValid || !dirty}
                                type="submit"
                                className="btn btn-lg btn-success"
                              >
                                {loading && (
                                  <i className="fa fa-refresh fa-spin"></i>
                                )}
                                {loading && <span>Signing Up</span>}
                                {!loading && <span> Sign Up</span>}
                              </button>
                            </label>
                            <div className="col-sm-7"></div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-11 offset-sm-1">
                              {signedUpMessage}
                            </label>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-11 offset-sm-1">
                              {backendErrorMsg}
                            </label>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorForm;
