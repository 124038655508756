import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";

export default function ViewOffer() {
    let history = useHistory();
    let { id } = useParams();
    const [offerDetail, setOfferDetail] = useState({});
    const [path, setPath] = useState("");

    useEffect(() => {
        getOfferDetails();
    }, []);

    const getOfferDetails = () => {
    AdminAPI.get(`offers/${id}`)
        .then((response) => {
        console.log("doc details", response.data.data);
        setOfferDetail(response.data.data);
        setPath(response.data.path);
        })
        .catch((error) => {
            console.log(error.response);
        });
    };

    return (
        <div>
            <div className="row">
                <div className="col-md-2">Title</div>
                <div className="col-sm-7">{offerDetail.title}</div>
            </div>
            <div className="row">
                <div className="col-md-2">Description</div>
                <div className="col-sm-7">{offerDetail.description}</div>
            </div>
            <div className="row">
                <div className="col-md-2">URL</div>
                <div className="col-sm-7">{offerDetail.url}</div>
            </div>
            <div className="row">
                <div className="col-md-2">Status</div>
                <div className="col-sm-7">{offerDetail.status}</div>
            </div>
            <div className="row">
                <div className="col-12">
                    <img
                        src={
                        `${process.env.REACT_APP_PUBLIC_URL}${path}/` +
                        offerDetail.photo
                        }
                        alt="{offerDetail.photo}"
                        width={500}
                    />
                </div>
            </div>
        </div>
    );
}