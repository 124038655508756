import React, { useState, useEffect } from "react";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import { useParams } from "react-router-dom";

export default function ShowPrescriptionDetails() {
  let { id } = useParams();
  const [prescribedMedcine, setPrescribedMedicine] = useState([]);
  const [prescribedTest, setPrescribedTest] = useState([]);

  const [prescribedObservation, setPrescribedObservation] = useState([]);
  const [patientDetails, setPatientDetails] = useState();
  const [prescribedDiagnosis, setPrescribedDiagnosis] = useState([]);
  const [comment, setComment] = useState("");
  const [prescription, setPrescription] = useState({});
  const [loading, setLoading] = useState(false);
  const [printing, setPrinting] = useState(false);
  const paramID = parseInt(id);

  useEffect(() => {
    getPrescription();
  }, []);

  const getPrescription = () => {
    AdminAPI.get(`prescriptions/${paramID}`)
      .then((response) => {
        setPrescribedTest(response.data.data.prescribed_tests);
        setComment(response.data.data.comment);
        setPrescription(response.data.data);
        setPrescribedDiagnosis(response.data.data.prescribed_diagnosis);
        setPrescribedObservation(response.data.data.prescribed_observations);
        setPrescribedMedicine(response.data.data.prescribed_medicines);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const getPrescriptionInPdf = (type) => {
    if (type == "download") {
      setLoading(true);
    } else {
      setPrinting(true);
    }
    AdminAPI(`prescriptions/${paramID}/pdf`, {
      method: "GET",
      responseType: "blob",
    })
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);

      if (type === "download") {
        var a = document.createElement("a");
        a.href = fileURL;

        a.download = "heal-holmes-prescription_" + id + ".pdf";

        a.click();
        window.URL.revokeObjectURL(fileURL);
      } else {
        window.open(fileURL);
      }

      if (response.status === 200) {
        setLoading(false);
        setPrinting(false);
      }
    })
    .catch((error) => {
      setLoading(false);
      console.log(error);
    });
  };

  return (
    <div className=" container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="text-right py-3">
            <button
              onClick={() => getPrescriptionInPdf("print")}
              className="btn btn-sm btn-success mx-1"
              disabled={loading}
            >
              {printing && <i className="fa fa-refresh fa-spin"></i>}
              {printing && <span>Printing</span>}
              {!printing && <span> Print</span>}
            </button>

            <button
              onClick={() => getPrescriptionInPdf("download")}
              className="btn btn-sm btn-success mx-1"
              disabled={loading}
            >
              {loading && <i className="fa fa-refresh fa-spin"></i>}
              {loading && <span>Downloading</span>}
              {!loading && <span> Download</span>}
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="observation-test-area">
            <div className="card">
              <h5 className="card-header">Observations</h5>
              <div className="card-body">
                {prescribedObservation.map((data, index) => (
                  <React.Fragment key={index}>
                    <h5 className="card-title">{data.details}</h5>
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className="card">
              <h5 className="card-header">Diagnosis</h5>
              <div className="card-body">
                {prescribedDiagnosis.map((data, index) => (
                  <React.Fragment key={index}>
                    <h5 className="card-title">{data.details}</h5>
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className="card mt-2">
              <h5 className="card-header">Tests</h5>
              <div className="card-body">
                {prescribedTest.map((data, index) => (
                  <React.Fragment key={index}>
                    <h5 className="card-title">{data.test.name}</h5>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="card">
            <h5 className="card-header">Medicine</h5>
            <div className="card-body">
              {prescribedMedcine.map((data, index) => (
                <React.Fragment key={index}>
                  {/* <h5 className="card-title">{data.medicine.brand_name}</h5> */}
                  <div className="card mb-1">
                    <div className="card-body">
                      <h5 className="card-title">
                        {" "}
                        {data.medicine.brand_name}
                      </h5>
                      <h6 className="card-subtitle mb-2 text-muted">
                        {" "}
                        <span style={{ fontWeight: "bold" }}> Validity :</span>
                        {data.validity}
                      </h6>
                      <h6 className="card-subtitle mb-2 text-muted">
                        <span style={{ fontWeight: "bold" }}> Rule :</span>{" "}
                        {data.rule}
                      </h6>
                      <h6 className="card-subtitle mb-2 text-muted">
                        <span style={{ fontWeight: "bold" }}>
                          {" "}
                          Before/After Meal :
                        </span>{" "}
                        {data.timing}
                      </h6>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
          <div className="card">
            <h5 className="card-header">Comment</h5>
            <div className="card-body">
              <h5 className="card-title">{comment}</h5>
            </div>
          </div>
        </div>
      </div>
      {prescription.image && 
        <div className="row">
          <div className="col-12">
          <img
            src={
              `${process.env.REACT_APP_PUBLIC_URL}files/doctorPrescriptions/${paramID}/${prescription.image}`
            }
            alt="..."
          />
          </div>
        </div>
      }
    </div>
  );
}
