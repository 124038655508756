import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import axios from "axios";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export default function CreatedTestDetails({ admin }) {
  let history = useHistory();
  let { testId } = useParams();
  const [testDetails, setTestDetails] = useState({});
  const [testInvoice, setTestInvoice] = useState({
    expense: "",
    collected_by: "",
    buying_price: "",
  });
  const [state, setState] = useState({});
  const [inArr, setInArr] = useState([]);
  const [file, setFile] = useState("");
  const theme = useTheme();
  const isTabMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // const [path, setPath] = useState();

  useEffect(() => {
    getTestDetails();
  }, []);

  const handleClick = (id) => {
    setState({
      EDIT: id,
    });
  };

  const downloadPDF = (id, type) => {
    AdminAPI.get(`test-invoices/${id}/pdf`, {
      method: "GET",
      responseType: "blob", //Force to receive data in a Blob Format
    })
      .then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" }); //Build a URL from the file
        const fileURL = URL.createObjectURL(file); //Open the URL on new Window
        if (type == "download") {
          var a = document.createElement("a");
          a.href = fileURL;
          if (testDetails.pdf !== null) {
            a.download = testDetails.pdf;
          } else {
            a.download = "heal-holmes-invoice_" + testId + ".pdf";
          }
          a.click();
          window.URL.revokeObjectURL(fileURL);
        } else {
          window.open(fileURL);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTestDetails = () => {
    AdminAPI.get(`test-invoices/${testId}`)
      .then((response) => {
        setTestDetails(response.data.data);
        let inData = [...response.data.data.test_invoice_details];
        let aa = inData.map((o) => {
          let obj = {
            id: o.id,
            buying_price: o.buying_price,
            price: o.price,
            discount: o.discount,
          };
          return obj;
        });

        setInArr(aa);

        setTestInvoice({
          collected_by: response.data.data.collected_by,
          expense: response.data.data.expense,
          buying_price: response.data.data.buying_price,
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const download = (report) => {
    console.log(testId, report);
    axios
      .get(`files/testInvoiceDetails/${testId}/${report}`, {
        responseType: "blob",
      })
      .then((response) => {
        const file = new Blob([response.data]); //Build a URL from the file
        const fileURL = window.URL.createObjectURL(file); //Open the URL on new Window
        var a = document.createElement("a");
        a.href = fileURL;
        a.download = report;
        a.click();
        window.URL.revokeObjectURL(fileURL);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const uploadFile = (id) => {
    let formData = new FormData();
    formData.append("report", file);

    AdminAPI.post(`test-invoice-details/${id}/upload-report`, formData)
      .then((response) => {
        alert(response.data.message);
        getTestDetails();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const uploadMultipleFiles = async (e) => {
    const fileList = Array.from(e.target.files);
    if (checkMimeType(e)) {
      setFile(fileList[0]);
    }
  };

  const checkMimeType = (event) => {
    //getting file object
    let files = event.target.files;
    //define message container
    let err = "";
    // list allow mime type
    //  "application/pdf"
    const types = ["image/png", "image/jpeg", "image/gif", "application/pdf"];
    // loop access array
    for (var x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every((type) => files[x].type !== type)) {
        // create error message and assign to container
        err += files[x].type + " is not a supported format\n";
      }
    }

    if (err !== "") {
      // if message not same old that mean has error
      event.target.value = null; // discard selected file
      alert(err);
      return false;
    }
    return true;
  };

  const updateInvoice = () => {
    let objToUpdate = {};
    objToUpdate.expense = testInvoice.expense;
    objToUpdate.collected_by = testInvoice.collected_by;
    objToUpdate.buying_price = testInvoice.buying_price;
    objToUpdate.test_invoice_details = inArr;

    console.log("invoice", objToUpdate);
    AdminAPI.put(`test-invoices/${testId}`, objToUpdate)
      .then((response) => {
        console.log(response);
        alert("succesfully updated");
        getTestDetails();
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const updateStatus = (id, status) => {
    AdminAPI.post(`test-invoice-details/${id}/update-status`, {
      status: status,
    })
      .then((response) => {
        getTestDetails();
      })
      .catch((error) => {});
  };

  // let arr = [];
  const _handleFocusOut = (e, test) => {
    const { name, value } = e.target;
    let arr = [...inArr];
    let obj = {
      id: test.id,
      [name]: value,
    };

    if (!arr.find((item) => item.id === test.id)) {
      arr.push(obj);
      // setInArr(obj)
      // setInArr((prevArray) => [...prevArray, obj]);
    }
    arr[arr.findIndex((item) => item.id === test.id)][name] = value;
    setInArr(arr);

    // if (arr.length > 0) {
    //   console.log("obj arr obj");
    // }
  };

  console.log("obj arr", inArr);

  return (
    <div>
      <div className="container">
        <div className="observation-test-area">
          <div className="form-group row">
            <div className="col-12">
              <button
                onClick={() => {
                  updateInvoice();
                }}
                className="btn btn-sm btn-success float-right m-2"
              >
                Update
              </button>
              <button
                onClick={(event) => {
                  event.preventDefault();
                  downloadPDF(testId, "download");
                }}
                className="btn btn-sm btn-success float-right m-2"
              >
                Download
              </button>
              <button
                onClick={(event) => {
                  event.preventDefault();
                  downloadPDF(testId, "print");
                }}
                className="btn btn-sm btn-success float-right m-2"
              >
                Print
              </button>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-md-5">
              Bill no:
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {testDetails.unique_id}
              </span>
            </div>
            <div className="col-md-3">
              Test Date:
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {testDetails.test_date &&
                  moment(testDetails.test_date).format("DD/MM/YYYY")}{" "}
              </span>
            </div>
            <div className="col-md-4">
              Created At:
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {moment(testDetails.created_at).format(
                  "DD/MM/YYYY HH:mm A"
                )}{" "}
              </span>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-md-5">
              Patient:{" "}
              {testDetails.patient_name !== null ? (
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {testDetails.patient_name}
                </span>
              ) : (
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {testDetails.patient.name}
                </span>
              )}
            </div>
            <div className="col-md-3">
              Phone:{" "}
              {testDetails.patient !== undefined &&
                testDetails.patient !== null && (
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    {testDetails.patient.phone}
                  </span>
                )}
            </div>
            <div className="col-md-4">
              Gender:{" "}
              {testDetails.patient !== undefined &&
              testDetails.patient !== null ? (
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {testDetails.patient.gender}
                </span>
              ) : (
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {testDetails.gender}
                </span>
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-md-12">
              Patient Address:
              <span style={{ fontWeight: "bold" }}> {testDetails.address}</span>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-md-5">
              Diagnostic Name:
              {testDetails.service_provider !== undefined && (
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {testDetails.service_provider.name}
                </span>
              )}
            </div>
            <div className="col-md-7">
              <div className="row">
                <div className="col-3">Collected By :</div>

                <input
                  value={testInvoice.collected_by}
                  type="text"
                  className="form-control col-8"
                  onChange={(e) => {
                    setTestInvoice({
                      ...testInvoice,
                      collected_by: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-md-12">
              Diagnostic Address :
              {testDetails.service_provider !== undefined && (
                <span style={{ fontWeight: "bold" }}>
                  {testDetails.service_provider.address}
                </span>
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-md-12">
              Home Sample Collection :{" "}
              <span style={{ fontWeight: "bold" }}>
                {testDetails.home_delivery ? "Yes" : "No"}{" "}
              </span>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <table className="custom-responsive-table">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Department</th>
                    <th scope="col">Report</th>
                    <th scope="col">Buying</th>
                    <th scope="col">Price</th>
                    <th scope="col">Discount</th>
                    <th scope="col">Bill</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(testDetails).includes(
                    "test_invoice_details"
                  ) && (
                    <React.Fragment>
                      {testDetails.test_invoice_details.map((test, i) => (
                        <tr key={i}>
                          <td data-column="Name">
                            {test.diagnostic_test.name}
                          </td>

                          <td data-column="Department">
                            {test.diagnostic_test.department}
                          </td>
                          <td data-column="Report">
                            {test.report !== null ? (
                              <button
                                className="btn  btn-sm btn-success"
                                onClick={() => download(test.report)}
                              >
                                Download
                              </button>
                            ) : (
                              <React.Fragment>
                                <input
                                  id={`btn-input`}
                                  // style={{ display: "none" }}
                                  multiple={false}
                                  type="file"
                                  onChange={(event) => {
                                    uploadMultipleFiles(event);
                                    handleClick(test.id);
                                  }}
                                />
                                {state.EDIT === test.id ? (
                                  <button
                                    disabled={file === ""}
                                    onClick={() => uploadFile(test.id)}
                                    className="btn btn-sm btn-primary"
                                  >
                                    Upload
                                  </button>
                                ) : (
                                  ""
                                )}
                              </React.Fragment>
                            )}
                          </td>
                          <td data-column="Buying">
                            {admin && (
                              <input
                                defaultValue={test.buying_price}
                                type="text"
                                name="buying_price"
                                className="form-control"
                                onBlur={(e) => _handleFocusOut(e, test)}
                              />
                            )}
                          </td>
                          <td data-column="Price">
                            <input
                              defaultValue={Math.ceil(test.price)}
                              type="text"
                              name="price"
                              className="form-control"
                              onBlur={(e) => _handleFocusOut(e, test)}
                            />
                          </td>
                          <td data-column="Discount">
                            <input
                              defaultValue={test.discount}
                              type="text"
                              name="discount"
                              className="form-control"
                              onBlur={(e) => _handleFocusOut(e, test)}
                            />
                            {/* <select
                              name="discount_type"
                              className="form-control"
                              onChange={(e) => {
                                _handleFocusOut(e, test);
                              }}
                            >
                              <option value="Flat">Flat</option>
                              <option value="%">Percentage</option>
                            </select> */}
                          </td>
                          <td data-column="Bill">{test.bill}</td>
                          <td data-column="Bill">
                            {test.status != "Pending" ? (
                              <span>
                                {test.status == "Cancelled" && (
                                  <span className="text-danger">
                                    {test.status}
                                  </span>
                                )}
                                {test.status == "Processed" && (
                                  <span className="text-success">
                                    {test.status}
                                  </span>
                                )}
                                {test.status == "Ready" && (
                                  <span className="text-primary">
                                    {test.status}
                                  </span>
                                )}
                              </span>
                            ) : (
                              <select
                                className=" form-control"
                                defaultValue={test.status}
                                onChange={(event) =>
                                  updateStatus(test.id, event.target.value)
                                }
                              >
                                <option value="Pending">Pending</option>
                                <option value="Processed">Processed</option>
                                <option value="Cancelled">Cancel</option>
                              </select>
                            )}
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  )}
                  {testDetails.home_delivery && (
                    <tr>
                      <td data-column="Name">Home sample collections </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td data-column="Bill">
                        {testDetails.home_delivery_charge}
                      </td>
                      <td></td>
                    </tr>
                  )}

                  <tr className="border-less-row">
                    {!isTabMobile && (
                      <React.Fragment>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </React.Fragment>
                    )}
                    <td>Price</td>
                    <td>{Math.ceil(testDetails.price)}</td>
                  </tr>
                  <tr className="border-less-row">
                    {!isTabMobile && (
                      <React.Fragment>
                        <td> </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </React.Fragment>
                    )}
                    <td>Discount</td>
                    <td>{testDetails.discount}</td>
                  </tr>
                  <tr className="border-less-row">
                    {!isTabMobile && (
                      <React.Fragment>
                        <td> </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </React.Fragment>
                    )}
                    <td>Payable</td>
                    <td>{testDetails.bill}</td>
                  </tr>
                  <tr className="border-less-row">
                    {!isTabMobile && (
                      <React.Fragment>
                        <td> </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </React.Fragment>
                    )}
                    <td>Buying</td>
                    <td>
                      <input
                        value={testInvoice.buying_price}
                        type="text"
                        className="form-control col-8"
                        onChange={(e) => {
                          setTestInvoice({
                            ...testInvoice,
                            buying_price: e.target.value,
                          });
                        }}
                      />
                    </td>
                  </tr>
                  <tr className="border-less-row">
                    {!isTabMobile && (
                      <React.Fragment>
                        <td> </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </React.Fragment>
                    )}
                    <td>Expense</td>
                    <td>
                      <input
                        value={testInvoice.expense}
                        type="number"
                        className="form-control"
                        onChange={(e) => {
                          console.log("value", e.target.value);
                          setTestInvoice({
                            ...testInvoice,
                            expense: e.target.value,
                          });
                        }}
                      />

                      {/* {JSON.stringify(testDetails.expense)} */}
                    </td>
                  </tr>
                  <tr className="border-less-row">
                    {!isTabMobile && (
                      <React.Fragment>
                        <td> </td>
                        <td> </td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </React.Fragment>
                    )}
                    <td>Payment</td>
                    <td>0</td>
                  </tr>
                  <tr className="border-less-row">
                    {!isTabMobile && (
                      <React.Fragment>
                        <td> </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </React.Fragment>
                    )}
                    <td>Due</td>
                    <td>{testDetails.bill}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
