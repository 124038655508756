import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
// import AssignmentIcon from "@material-ui/icons/Assignment";
import AddToQueueIcon from "@material-ui/icons/AddToQueue";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import AvTimerIcon from "@material-ui/icons/AvTimer";
// import ListSubheader from "@material-ui/core/ListSubheader";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import PersonIcon from "@material-ui/icons/Person";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import clsx from "clsx";
import Echo from "laravel-echo";
import PropTypes from "prop-types";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { toast } from "react-toastify";
import React, { useEffect } from "react";
import {
  Link,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import patientAuth from "../../routingRules/patientAuth";
import Appointments from "./Appointments";
import ShowVideoCall from "./appointments/ShowVideoCall";
// import ScheduleIcon from "@material-ui/icons/Schedule";
import AvailableDoctors from "./AvailableDoctors";
import DoctorDetails from "./doctors/DoctorDetails";
import DoctorSchedule from "./DoctorSchedule";
import AddTest from "./invoices/AddTest";
import TestDetails from "./invoices/TestDetails";
import TestList from "./invoices/TestList";
import UploadedTest from "./invoices/UploadedTest";
import UploadedTestDetails from "./invoices/UploadedTestDetails";
import UploadPrescription from "./invoices/UploadPrescription";
import PatientPortal from "./PatientPortal";
import Prescriptions from "./prescription/Prescriptions";
import Profile from "./profile";
import ResetPassword from "./reset-password";
import ShowPrescription from "./ShowPrescription";
import ReportList from "./Test-report/List";
import Pusher from "pusher-js";
import { CardGiftcardOutlined } from "@material-ui/icons";
import BuyCard from "./BuyCard";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  nested: {
    marginLeft: theme.spacing(2),
    fontSize: 14,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },

  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },

  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  hide: {
    display: "none",
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      top: "65px",
    },
    [theme.breakpoints.down("sm")]: {
      top: "55px",
    },
    [theme.breakpoints.down("xs")]: {
      top: "0",
    },
  },

  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: "4rem",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
}));
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      {/* <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "} */}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Main(props) {
  let history = useHistory();
  let match = useRouteMatch();
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const [openSubLink, setOpenSubLink] = React.useState(false);
  const token = JSON.parse(localStorage.getItem("patientToken"));
  const options = {
    broadcaster: "pusher",
    key: `${process.env.REACT_APP_PUSHER_KEY}`,
    cluster: `${process.env.REACT_APP_PUSHER_CLUSTER}`,
    encrypted: true,
    authEndpoint: `${process.env.REACT_APP_BASE_URL}/broadcasting/users/auth`,
    auth: {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    },
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [notificationData, setNotificationData] = React.useState([]);
  const [onlineDocs, setOnlineDocs] = React.useState([]);
  useEffect(() => {
    getLocalNotification();

    // getusers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    saveLocalNotification();
  }, [notificationData]);
  console.log("notificationData====>", notificationData);
  const saveLocalNotification = () => {
    var patientId = localStorage.getItem("patientId");
    localStorage.setItem(
      `HHPatient${patientId}Notification`,
      JSON.stringify(notificationData)
    );
  };
  const getLocalNotification = () => {
    var patientId = localStorage.getItem("patientId");
    if (localStorage.getItem(`HHPatient${patientId}Notification`) === null) {
      localStorage.setItem(
        `HHPatient${patientId}Notification`,
        JSON.stringify([])
      );
    } else {
      let localNotification = JSON.parse(
        localStorage.getItem(`HHPatient${patientId}Notification`)
      );

      setNotificationData(localNotification);
    }
  };

  const addNotification = (noti) => {
    var patientId = localStorage.getItem("patientId");
    let localNotification = JSON.parse(
      localStorage.getItem(`HHPatient${patientId}Notification`)
    );

    let data = [];
    if (localNotification && localNotification.length > 0) {
      data = localNotification;
    }
    let obj = { ...noti };
    obj.read = false;

    data.push(obj);

    setNotificationData(data);
  };

  useEffect(() => {
    getLocalDocs();

    // getusers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    saveLocalDocs();
  }, [onlineDocs]);
  const saveLocalDocs = () => {
    var patientId = localStorage.getItem("patientId");
    localStorage.setItem(
      `HHPatient${patientId}PatientOnline`,
      JSON.stringify(onlineDocs)
    );
  };
  const getLocalDocs = () => {
    var patientId = localStorage.getItem("patientId");
    if (localStorage.getItem(`HHPatient${patientId}PatientOnline`) === null) {
      localStorage.setItem(
        `HHPatient${patientId}PatientOnline`,
        JSON.stringify([])
      );
    } else {
      let localNotification = JSON.parse(
        localStorage.getItem(`HHPatient${patientId}PatientOnline`)
      );

      setOnlineDocs(localNotification);
    }
  };
  const removeDocsOnline = (obj) => {
    console.log("pateinsfkdsdsf remove", obj);
    var patientId = localStorage.getItem("patientId");
    let localNotification = JSON.parse(
      localStorage.getItem(`HHPatient${patientId}PatientOnline`)
    );

    let data = [];
    if (localNotification && localNotification.length > 0) {
      data = localNotification;
    }
    console.log("pateinsfkdsdsf", data);
    const patients = data.filter((o) => o.id !== obj.id);
    console.log("pateinsfkdsdsf", patients);
    setOnlineDocs(patients);
  };

  const jointDocsOnline = (p) => {
    if (p.type === "doctor") {
      var patientId = localStorage.getItem("patientId");
      let localNotification = JSON.parse(
        localStorage.getItem(`HHPatient${patientId}PatientOnline`)
      );

      let data = [];
      if (localNotification && localNotification.length > 0) {
        data = localNotification;
      }
      if (data.filter((item) => item.id === p.id).length === 0) {
        data.push(p);
      }

      setOnlineDocs(data);
    }
  };
  const addDocsOnline = (p) => {
    var patientId = localStorage.getItem("patientId");
    let localNotification = JSON.parse(
      localStorage.getItem(`HHPatient${patientId}PatientOnline`)
    );

    let data = [];
    if (localNotification && localNotification.length > 0) {
      data = localNotification;
    }

    const mergedArray = [...data, ...p];
    const unique = [
      ...new Map(mergedArray.map((item) => [item["id"], item])).values(),
    ];

    console.log("unique====>", unique);
    setOnlineDocs(unique);
  };
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (data, index) => {
    console.log("data====>", data.data);
    if (data.type === "appointment_call") {
      history.push(`/patient/support/start-video/${data.data.id}`);
    }
    const newState = notificationData.map((obj) =>
      obj.data.id === data.data.id ? { ...obj, read: true } : obj
    );
    setNotificationData(newState);
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  window.Echo = new Echo(options);

  useEffect(() => {
    var patientId = localStorage.getItem("patientId");
    window.Echo.private(`patient.activity.${patientId}`).listen(
      ".patient.activities",
      (e) => {
        addNotification(e);
        // alert(e.message);
        toast.success(e.message);
        console.log(e);
      }
    );

    var channel = window.Echo.join("online");
    channel
      .here((members) => {
        // For example
        console.log("here", members);
        // addDocsOnline(members);
      })
      .joining((member) => {
        // For example
        console.log("joining", member);
        jointDocsOnline(member);
      })
      .leaving((member) => {
        // For example
        console.log("leaving", member);
        removeDocsOnline(member);
      });
    // window.Echo.join('patient.online')
    //   .here((patients) => {
    //     console.log("asdf", patients);
    //     // For example
    //     // update_member_count(members.count);
    //     // members.each((member) => {
    //     //   // For example
    //     //   add_member(member.id, member.info);
    //     // });
    // })
    // .joinning((patient) => {
    //   console.log("joinning", patient);
    // });

    return () => {};
  }, []);
  const handleTestExpand = () => {
    setOpenSubLink(!openSubLink);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  const logOut = () => {
    patientAuth.logout(() => {
      var patientId = localStorage.getItem("patientId");
      localStorage.removeItem(`HHPatient${patientId}Notification`);
      localStorage.removeItem(`HHPatient${patientId}PatientOnline`);
      localStorage.removeItem("testCart");
      localStorage.removeItem("testImages");
      history.push("/");
    });
  };

  const routeToPortal = () => {
    history.push("/patient/patient-portal");
  };
  const profile = () => {
    history.push("/patient/profile");
  };
  const resetPassword = () => {
    history.push("/patient/reset-password");
  };
  // useEffect(() => {
  //   history.push("/patient/appointments");
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const drawer = (
    <div>
      <div className="text-center">
        <img
          className="app-bar-logo"
          src={`${process.env.PUBLIC_URL}/assets/images/logo-trans.png`}
          alt="app-bar-logo"
        />
      </div>
      <Divider />

      <List>
        <ListItem button component={Link} to={`${match.url}/patient-portal`}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem button component={Link} to={`${match.url}/available-doctors`}>
          <ListItemIcon>
            <AssignmentIndIcon />
          </ListItemIcon>
          <ListItemText primary="Doctors" />
        </ListItem>

        <ListItem button component={Link} to={`${match.url}/appointments`}>
          <ListItemIcon>
            <AvTimerIcon />
          </ListItemIcon>
          <ListItemText primary="Appointments" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to={`${match.url}/test/test-invoices`}
        >
          <ListItemIcon>
            <AddToQueueIcon />
          </ListItemIcon>
          <ListItemText primary="Invoice List" />
        </ListItem>

        <ListItem button component={Link} to={`${match.url}/test/report-list`}>
          <ListItemIcon>
            <NoteAddIcon />
          </ListItemIcon>
          <ListItemText primary="Download Reports" />
        </ListItem>

        <ListItem button component={Link} to={`${match.url}/prescriptions`}>
          <ListItemIcon>
            <NoteAddIcon />
          </ListItemIcon>
          <ListItemText primary="Prescriptions" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to={`${match.url}/test/uploaded-test`}
        >
          <ListItemIcon>
            <AvTimerIcon />
          </ListItemIcon>
          <ListItemText primary="Upload Prescription" />
        </ListItem>
        <ListItem button component={Link} to={`${match.url}/buy-card`}>
          <ListItemIcon>
            <CardGiftcardOutlined />
          </ListItemIcon>
          <ListItemText primary="Buy Card" />
        </ListItem>
      </List>
      <Divider />
    </div>
  );

  const container =
    props.window !== undefined ? () => window().document.body : undefined;

  return (
    <div>
      <AppBar position="fixed">
        <Toolbar className={classes.toolbar}>
          <Hidden smUp implementation="css">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              edge="start"
              className={clsx(classes.menuButton)}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Hidden xsDown implementation="css">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton)}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>

          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Dashboard
          </Typography>
          <Tooltip title="Change Password">
            <MenuItem>
              <IconButton
                onClick={handleClickListItem}
                aria-label="show 11 new notifications"
                color="inherit"
              >
                <Badge
                  badgeContent={
                    notificationData.filter((o) => o.read === false).length
                  }
                  color="secondary"
                >
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </MenuItem>
          </Tooltip>
          <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {notificationData.map((option, index) => (
              <MenuItem
                style={{ whiteSpace: "normal", maxWidth: "220" }}
                key={index}
                // disabled={index === 0}
                selected={option.read === false}
                onClick={() => handleMenuItemClick(option, index)}
              >
                <p> {option.message}</p>
              </MenuItem>
            ))}
          </Menu>

          <Tooltip title="Change Password">
            <IconButton onClick={resetPassword} color="inherit">
              <VpnKeyIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Profile">
            <IconButton onClick={profile} color="inherit">
              <PersonIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Logout">
            <IconButton onClick={logOut} color="inherit" aria-label="logout">
              <ExitToAppIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.appBarSpacer} />
        <Switch>
          <Route exact path={`${match.path}/patient-portal`}>
            <PatientPortal />
          </Route>
          <Route exact path={`${match.path}/available-doctors`}>
            <AvailableDoctors />
          </Route>
          <Route path={`${match.path}/doctor-schedule/:doctorId`}>
            <DoctorSchedule />
          </Route>
          <Route path={`${match.path}/doctor-details/:doctorId`}>
            <DoctorDetails />
          </Route>
          <Route path={`${match.path}/appointments`}>
            <Appointments onlineDocs={onlineDocs} />
          </Route>

          <Route path={`${match.path}/prescriptions`}>
            <Prescriptions />
          </Route>
          <Route exact={true} path={`${match.path}/test/uploaded-test`}>
            <UploadedTest />
          </Route>
          <Route path={`${match.path}/test/uploaded-test/:testId`}>
            <UploadedTestDetails />
          </Route>
          <Route path={`${match.path}/test/upload-prescription`}>
            <UploadPrescription />
          </Route>

          <Route path={`${match.path}/prescription/:appointmentId`}>
            <ShowPrescription />
          </Route>
          <Route exact={true} path={`${match.path}/test/test-invoices`}>
            <TestList />
          </Route>
          <Route path={`${match.path}/test/test-invoices/:testId`}>
            <TestDetails />
          </Route>
          <Route exact path={`${match.path}/test/add`}>
            <AddTest />
          </Route>
          <Route exact path={`${match.path}/test/report-list`}>
            <ReportList />
          </Route>
          <Route exact path={`${match.path}/support/start-video/:videoId`}>
            <ShowVideoCall />
          </Route>
          <Route path={`${match.path}/reset-password`}>
            <ResetPassword />
          </Route>
          <Route path={`${match.path}/profile`}>
            <Profile />
          </Route>
          <Route path={`${match.path}/buy-card`}>
            <BuyCard />
          </Route>
        </Switch>
      </main>
    </div>
  );
}

Main.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
