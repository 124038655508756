import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DoctorAPI } from "../../../AxiosRequest/DoctorAPI";
import "./css/video.css";
import Lobby from "./Lobby";
import Room from "./Room";
const VideoChat = () => {
  const [doctorDetails, setDocDetails] = useState("");
  let { addPrescriptionId } = useParams();
  const paramID = parseInt(addPrescriptionId);

  const [username, setUsername] = useState("");
  const [roomName, setRoomName] = useState("");
  const [token, setToken] = useState(null);

  useEffect(() => {
    GetDoctorDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    let videoToken = JSON.parse(localStorage.getItem("videoToken"));
    if (videoToken !== undefined || videoToken !== null) {
      setToken(videoToken);
    } else {
      setToken(null);
    }
    console.log("aaaa", videoToken);

    // if (
    //   JSON.parse(localStorage.getItem("videoToken")) !== null &&
    //   JSON.parse(localStorage.getItem("videoToken")) !== undefined
    // ) {
    //   videoToken = JSON.parse(localStorage.getItem("videoToken"));
    // }
    // if (videoToken !== null && videoToken !== undefined) {
    //   setToken(videoToken);
    // }
    const roomName = JSON.parse(localStorage.getItem("roomName"));
    if (roomName !== null && roomName !== undefined) {
      setRoomName(roomName);
    }
  }, []);
  const GetDoctorDetails = () => {
    DoctorAPI.get(`auth/me`)
      .then((response) => {
        console.log("repost", response.data);
        setDocDetails(response.data.data);
        if (response.data) {
          setUsername(response.data.data.name);
          setRoomName(response.data.data.email);
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleUsernameChange = useCallback((event) => {
    setUsername(event.target.value);
  }, []);

  const handleRoomNameChange = useCallback((event) => {
    setRoomName(event.target.value);
  }, []);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      DoctorAPI.post(`/access-token`, {
        patient_appointment_id: paramID,
      })
        .then((response) => {
          console.log("kvideo taoken", response.data.data);
          let videoToken = response.data.data.doctor_token;
          if (videoToken !== undefined) {
            localStorage.setItem("videoToken", JSON.stringify(videoToken));
            localStorage.setItem("roomName", JSON.stringify(roomName));
            setToken(videoToken);
          }
        })
        .catch((error) => {
          console.log(error.response.data.message);
          alert(error.response.data.message);
        });
      // const data = await fetch(
      //   "https://beta.heal-holmes.com/api/api/doctor/access-token",
      //   {
      //     method: "POST",
      //     body: JSON.stringify({
      //       identity: username,
      //       room: roomName,
      //     }),
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //   }
      // ).then((res) => res.json());
      // console.log(data);
      // setToken(data.token);
    },
    [username, roomName]
  );
  const handleLogout = useCallback((event) => {
    setToken(null);
    localStorage.removeItem("videoToken");
    localStorage.removeItem("roomName");
  }, []);
  let render;
  if (token) {
    render = (
      <Room roomName={roomName} token={token} handleLogout={handleLogout} />
    );
  } else {
    render = (
      <Lobby
        doctorDetails={doctorDetails}
        username={username}
        roomName={roomName}
        handleUsernameChange={handleUsernameChange}
        handleRoomNameChange={handleRoomNameChange}
        handleSubmit={handleSubmit}
      />
    );
  }
  return render;
};

export default VideoChat;
