import React, { useState, useEffect } from "react";
import Pagination from "@material-ui/lab/Pagination";
import { DoctorAPI } from "../../AxiosRequest/DoctorAPI";
// import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import * as moment from "moment";
import { Link } from "react-router-dom";

export default function Prescriptions() {
  let history = useHistory();
  const [prescriptionList, setPrescriptionList] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);

  const [params, setParams] = useState({
    per_page: 15,
    page: 1,
  });

  useEffect(() => {
    DoctorAPI.get(`/prescriptions`, { params: params })
      .then((response) => {
        setPrescriptionList(response.data.data.data);
        setTotalPage(response.data.data.last_page);
        setPage(response.data.data.current_page);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, []);
  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };

  const routeToShowPrescription = (id) => {
    history.push(`/doctor/prescription-details/${id}`);
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <table className="custom-responsive-table">
              <thead>
                <tr>
                  <th scope="col">Time</th>
                  <th scope="col">Patient's Name</th>
                  <th scope="col">Patient's Contact</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {prescriptionList.map((prescription, i) => (
                  <tr key={i}>
                    <td data-column="Time">
                      {moment(prescription.created_at).format(
                        "dddd DD/MM/YYYY HH:mm:ss A"
                      )}
                    </td>
                    <td data-column="Patient's Name">
                      {" "}
                      {prescription.patient_appointment !== null ? (
                        <span>
                          {prescription.patient_appointment.patient.name}
                        </span>
                      ) : (
                        ""
                      )}
                    </td>
                    <td data-column="Patient's Contact">
                      {prescription.patient_appointment !== null ? (
                        <span>
                          {prescription.patient_appointment.patient.phone}
                        </span>
                      ) : (
                        ""
                      )}
                    </td>

                    <td data-column="Actions">
                      {prescription.patient_appointment !== null ? (
                        <button
                          onClick={() => {
                            routeToShowPrescription(prescription.id);
                          }}
                          className="btn btn-sm btn-outline-secondary"
                        >
                          View
                        </button>
                      ) : (
                        <button className="btn">Not Available</button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="row">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}
