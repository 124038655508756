import React from "react";
import Footer from "../Shared/Footer";
import NavbarHome from "../Shared/NavbarHome";
import Navbar2 from "../Shared/Navbar2";

const AboutUs = () => {
  return (
    <div>
      <Navbar2 />
      <div className="text-sections container">
        <h3 className="text-center">Message From The Chairman</h3>

        <div className="row">
          <div className="col-lg-5">
            <img
              className=" img-fluid"
              src={`${process.env.PUBLIC_URL}/assets/images/A_B_M_Abdul_Wadud.jpeg`}
              alt="  Praava Health"
            />
          </div>
          <div className="col-lg-7">
            {" "}
            <h5>Dr. A B M Abdul Wadud</h5>
            <p>
              Experienced Laboratory Consultant with a demonstrated history of
              working in the hospital & health care industry. Skilled in
              Healthcare Management, Healthcare, Clinical Research, Medical
              Education, and Hospitals. Strong research professional with an
              FCPS (Medical Microbiology) focused in Clinical Medical
              Microbiology from Bangladesh College of Physician and Surgeon
              (BCPS). Experienced in Medical Laboratory Quality Management
              system and qualified in Lead Assessor of Medical Laboratory
              Accreditation on ISO 15189: 2012 under Bangladesh Accreditation
              Board (BAB)
            </p>
            <p>
              e-Health has enormous potential to ensure healthcare quality,
              accessibility, and affordability in developing countries. The
              application of information and communication technology to
              healthcare, especially e-Health, is rapidly advancing in
              Bangladesh. Both the public and private sectors have contributed
              to the development of the e- Health infrastructure throughout the
              country. The current status of e-Health in Bangladesh, however,
              has not been assessed.{" "}
            </p>
            <p>
              e-health is an evolving field in the juncture of medical
              informatics, public health, and business, stating to the health
              services and information distributed or heightened through the
              Internet and associated technologies like mobile, smart watch.
            </p>
            <p>
              In a broader sense, the term exemplifies technical progress along
              with state-of-mind, an approach of thinking and an attitude for
              networked, global thinking, to expand health care locally,
              countywide, and universal by using information and communication
              technology. It improved communication potentials between health
              care institutions, and patient participation.
            </p>
            <p>
              It will also focus on quality assurance, aiming patient streams to
              the finest quality suppliers.
            </p>
            <p>
              By making the knowledge base of medicine and personal electronic
              records easily available to users over the internet, e-health
              unseals new opportunities for patient-centered medicine and
              facilitates evidence-based patient choice.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
