import React, { useEffect, useState } from "react";
import CardForm from "../Customer/Forms/CardForm";
import { PatientAPI } from "../../AxiosRequest/PatientAPI";
import { isEmpty } from "lodash";

const BuyCard = () => {
  const [openBanner, setOpenBanner] = useState(false);
  const [patient, setPatient] = useState({});
  useEffect(() => {
    getPatientData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getPatientData = () => {
    PatientAPI.get("auth/me")
      .then((response) => {
        console.log("response data", response.data.data);
        setPatient(response.data.data);

        // setPoliceStationValue(response.data.police_station);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  function fireClickEvent(element) {
    var evt = new window.MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });

    element.dispatchEvent(evt);
  }

  // this function will setup a virtual anchor element
  // and fire click handler to open new URL in the same room
  // it works better than location.href=something or location.reload()
  function openNewURLInTheSameWindow(targetURL) {
    var a = document.createElement("a");
    a.href = targetURL;
    fireClickEvent(a);
  }
  return (
    <div>
      {" "}
      <div className="form-group row ">
        <label className="col-sm-4 ">
          {!isEmpty(patient) && (
            <>
              {!patient.purchase_card ? (
                <button
                  // disabled={files.length === 0}
                  // onClick={(event) => {
                  //   event.preventDefault();

                  //   uploadPrescriptions();
                  // }}
                  type="button"
                  onClick={() => {
                    const url = `${process.env.REACT_APP_PAYMENT_URL}/patient/card-payment/${patient?.id}`;
                    openNewURLInTheSameWindow(url);
                  }}
                  className="btn btn-block btn-outline-success"
                >
                  Buy Card
                </button>
              ) : (
                <h4>You have already purchased.</h4>
              )}
            </>
          )}{" "}
        </label>
        <div className="col-sm-7 text-right"></div>
      </div>
    </div>
  );
};

export default BuyCard;
