import React, { useState, useEffect } from "react";
import Chip from "@material-ui/core/Chip";
import moment from "moment";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import { HospitalAPI } from "../../../AxiosRequest/HospitalAPI";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import LockOpenIcon from "@material-ui/icons/LockOpen";
// import { FormikTextField } from "formik-material-fields";
import * as Yup from "yup";
import axios from "axios";

const DoctorUpdate = () => {
  const [value, setValue] = useState([
    { id: "10", text: "One" },
    { id: "20", text: "Two" },
  ]);
  let history = useHistory();
  let { doc } = useParams();
  const [docDetails, setDocDetails] = useState({});
  const [designations, setDesignations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [institutions, setInstitutions] = useState([]);

  const [desigValue, setDesigvalue] = useState("");
  const [deptValue, setDeptValue] = useState("");
  const [currentIns, setCurrentIns] = useState("");
  const [passingIns, setPassingIns] = useState("");
  const [degreeList, setDegreeList] = useState([]);
  const [degreeValue, setDegreeValue] = useState([]);

  const [degreeListChosen, setDegreeListChosen] = useState([]);

  const [fileForPreview, setFileForPreview] = useState("");
  const [uploadedFile, setUploadedFile] = useState("");
  const [fileName, setFileName] = useState("Choose an Image File");

  const [loading, setLoading] = useState(false);
  const [signedUpMessage, setSignedUpMessage] = useState("");
  const [singnedUp, setSignedUp] = useState(false);

  // Backend Error Messages
  const [nameErros, setNameErrors] = useState([]);
  const [emailError, setEmailError] = useState([]);
  const [phoneErros, setPhoneError] = useState([]);
  const [ageErrors, setAgeErrors] = useState([]);
  const [bmdcErrors, setBMDCErrors] = useState([]);
  const [designationErrors, setDesignationErrors] = useState([]);
  const [departmentErrors, setDepartmentErrors] = useState([]);
  const [institutionErrors, setInstitutionErrors] = useState([]);
  const [degreeErrors, setDegreeErrors] = useState([]);
  const [practiceYearErrors, setPracticeYearErrors] = useState([]);
  const [passigngYearErrors, setPassingYearErrors] = useState([]);
  const [passingInstituitionErrors, setPassingInstituitionErrors] = useState(
    []
  );
  const [newPatientVisitErrors, setNewPatientVisitErrors] = useState([]);
  const [oldPatientVisitErrors, setOldPatientVisitErrors] = useState([]);
  const [reportVisitErrors, setReportVisitErrors] = useState([]);
  const [oldPatientVisitValidity, setOldPatientVisitValidity] = useState([]);
  const [passwordErrors, setPasswordErrors] = useState([]);
  const [passwordConfirmationErrors, setPaswordConfirmationErros] = useState(
    []
  );
  const [photoErrors, setPhotoErrors] = useState([]);
  const [selectedDate, handleDateChange] = useState(new Date());

  const validationSchema = Yup.object({
    // date_of_birth: Yup.date().required("Date of Birth field is required"),
    name: Yup.string().required("Name field is required"),
    phone: Yup.string()
      .required("Please enter your phone number")
      .matches(/^(01){1}[3-9]{1}\d{8}$/, "Enter a valid Mobile Number"),
    designation: Yup.string().required("Designation field is required"),
    department: Yup.string().required("Department field is required"),
    institution: Yup.string().required("Institution field is required"),
    password: Yup.string()
      .min(6, "Password must contain at least 6 characters")
      .required("Enter your password"),
    confirm_password: Yup.string()
      .required("Confirm your password")
      .oneOf([Yup.ref("password")], "Password does not match"),
    // degrees: Yup.string().required("Degree field is required"),

    // acceptTerms: Yup.bool().oneOf(
    //   [true],
    //   "Accept Terms & Conditions is required"
    // ),
  });
  const initialValues = {
    name: "" || docDetails.name,
    phone: "" || docDetails.phone,
    email: "" || docDetails.email,
    designation: "" || docDetails.designation,
    department: "" || docDetails.department,
    institution: "" || docDetails.institution,
    password: "",
    confirm_password: "",
  };

  const onSubmit = (values, { resetForm }) => {
    setLoading(true);
    console.log("objj", values);
    let objToSend = { ...values };

    HospitalAPI.post(`doctors`, objToSend)
      .then((response) => {
        console.log(response.data.message);

        alert(response.data.message);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response);
        setLoading(false);
        if (error.response.data.errors !== undefined) {
          let nameErrors = error.response.data.errors.name;

          let emailError = error.response.data.errors.email;
          let phoneErrors = error.response.data.errors.phone;
          let birthErrors = error.response.data.errors.date_of_birth;
          let bmdcErrors = error.response.data.errors.bmdc_number;
          let designationErrors = error.response.data.errors.designation;
          let departmentErrors = error.response.data.errors.department;
          let institutionErrors = error.response.data.errors.institution;
          let degreeErrors = error.response.data.errors.degrees;
          let practiceYearErrors = error.response.data.errors.practice_year;
          let passigngYearErrors = error.response.data.errors.passing_year;
          let passingInstituitionErrors =
            error.response.data.errors.passing_institution;
          let newPatientVisitErrors =
            error.response.data.errors.new_patient_visit;
          let oldPatientVisitErrors =
            error.response.data.errors.old_patient_visit;
          let reportVisitErrors = error.response.data.errors.report_visit;
          let oldPatientVisitValidityErrors =
            error.response.data.errors.old_patient_valid_days;
          let passwordErrors = error.response.data.errors.password;
          let confirmPasswordErrors =
            error.response.data.errors.confirm_password;
          let photoErrors = error.response.data.errors.photo;

          if (nameErrors !== undefined) {
            setNameErrors(nameErrors);
          }
          if (emailError !== undefined) {
            setEmailError(emailError);
          }
          if (phoneErrors !== undefined) {
            setPhoneError(phoneErrors);
          }

          if (birthErrors !== undefined) {
            console.log("birthe", birthErrors);
            setAgeErrors(birthErrors);
          }
          if (bmdcErrors !== undefined) {
            setBMDCErrors(bmdcErrors);
          }
          if (designationErrors !== undefined) {
            setDesignationErrors(designationErrors);
          }
          if (departmentErrors !== undefined) {
            setDepartmentErrors(departmentErrors);
          }
          if (institutionErrors !== undefined) {
            setInstitutionErrors(institutionErrors);
          }
          if (degreeErrors !== undefined) {
            setDegreeErrors(degreeErrors);
          }
          if (practiceYearErrors !== undefined) {
            setPracticeYearErrors(practiceYearErrors);
          }
          if (passigngYearErrors !== undefined) {
            setPassingYearErrors(passigngYearErrors);
          }
          if (passingInstituitionErrors !== undefined) {
            setPassingInstituitionErrors(passingInstituitionErrors);
          }
          if (newPatientVisitErrors !== undefined) {
            setNewPatientVisitErrors(newPatientVisitErrors);
          }
          if (oldPatientVisitErrors !== undefined) {
            setOldPatientVisitErrors(oldPatientVisitErrors);
          }
          if (reportVisitErrors !== undefined) {
            setReportVisitErrors(reportVisitErrors);
          }
          if (oldPatientVisitValidityErrors !== undefined) {
            setOldPatientVisitValidity(oldPatientVisitValidityErrors);
          }
          if (passwordErrors !== undefined) {
            setPasswordErrors(passwordErrors);
          }
          if (confirmPasswordErrors !== undefined) {
            setPaswordConfirmationErros(confirmPasswordErrors);
          }

          if (photoErrors !== undefined) {
            setPhotoErrors(photoErrors);
          }
        }
      });

    // setLoading(false);
  };

  return (
    <div>
      <div className="doc-register-section-a">
        <div className="container">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-md-12">
                  <div className="doc-register-form">
                    <div className="text-center">
                      <LockOpenIcon />
                    </div>

                    <Formik
                      enableReinitialize
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      {({ setFieldValue, errors, status, touched }) => (
                        <Form>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label">
                              Name
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="name"
                                type="text"
                                className="form-control"
                                placeholder="Your Name"
                              />
                              <ErrorMessage
                                name="name"
                                component="div"
                                className="error-message"
                              />
                              {nameErros.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))}
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label">
                              Email
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="email"
                                type="email"
                                className="form-control"
                                placeholder="Email"
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="error-message"
                              />
                              {emailError.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))}
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label">
                              Phone
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="phone"
                                type="text"
                                className="form-control"
                                placeholder="Phone/Mobile Number"
                              />
                              <ErrorMessage
                                name="phone"
                                component="div"
                                className="error-message"
                              />
                              {phoneErros.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))}
                            </div>
                          </div>

                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label">
                              Designation
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="designation"
                                type="text"
                                className="form-control"
                                placeholder="Designation"
                              />
                              <ErrorMessage
                                name="designation"
                                component="div"
                                className="error-message"
                              />
                              {designationErrors.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))}
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label">
                              Department
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="department"
                                type="text"
                                className="form-control"
                                placeholder="Department"
                              />

                              <ErrorMessage
                                name="department"
                                component="div"
                                className="error-message"
                              />
                              {departmentErrors.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))}
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label">
                              Institution
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="institution"
                                type="text"
                                className="form-control"
                                placeholder="Institution"
                              />

                              <ErrorMessage
                                name="institution"
                                component="div"
                                className="error-message"
                              />
                              {institutionErrors.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))}
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label">
                              Password
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="password"
                                type="password"
                                className="form-control "
                                placeholder="password"
                              />
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="error-message"
                              />
                              {passwordErrors.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))}
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label">
                              Confirm Password
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="confirm_password"
                                type="password"
                                className="form-control "
                                placeholder="password"
                              />
                              <ErrorMessage
                                name="confirm_password"
                                component="div"
                                className="error-message"
                              />
                              {passwordConfirmationErrors.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))}
                            </div>
                          </div>

                          <div className="form-group row ">
                            <label className="col-sm-5 offset-sm-1">
                              <button
                                disabled={loading}
                                // disabled={!isValid || !dirty}
                                type="submit"
                                className="btn btn-lg btn-success"
                              >
                                {loading && (
                                  <i className="fa fa-refresh fa-spin"></i>
                                )}
                                {loading && <span>Submitting</span>}
                                {!loading && <span> Submit</span>}
                              </button>
                            </label>
                            <div className="col-sm-7"></div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-11 offset-sm-1">
                              {signedUpMessage}
                            </label>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2"></div>
        </div>
      </div>
    </div>
  );
};

export default DoctorUpdate;
