import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import React from "react";
import ReactDOM from "react-dom";
// import { BrowserRouter } from "react-router-dom";
import { Router } from "react-router-dom";
import GA4React from "ga-4-react";
import { ToastContainer } from "react-toastify";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import App from "./App";
import history from "./history";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

library.add(faSpinner);

axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;
function noop() {}

if (process.env.NODE_ENV !== "development") {
  console.log = noop;
  console.warn = noop;
  // console.error = noop;
}
// axios.defaults.baseURL = "https://beta.heal-holmes.com/api/api";

// axios.interceptors.request.use(
//   (config) => {
//     if (!config.headers.Authorization) {
//       const token = JSON.parse(localStorage.getItem("token"));

//       if (token) {
//         config.headers.Authorization = `Bearer ${token}`;
//       }
//     }

//     return config;
//   },
//   (error) => Promise.reject(error)
// );

// axios.interceptors.response.use(
//   (response) => {
//     console.log(response);
//     // Edit response config
//     return response;
//   },
//   (error) => {
//     console.log(error);
//     if (error.response.status === 401) {
//       localStorage.removeItem("token");

//       // return (window.location.href = "/doctor/login");
//     }
//     return Promise.reject(error);
//   }
// );

const ga4react = new GA4React("G-T0R2JNHST2");
(async (_) => {
  await ga4react
    .initialize()
    .then((res) => console.log("Analytics Success."))
    .catch((err) => console.log("Analytics Failure."))
    .finally(() => {
      ReactDOM.render(
        <Router history={history}>
          <ToastContainer
            position="bottom-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
          />
          <App />
        </Router>,
        document.getElementById("root")
      );
    });
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
