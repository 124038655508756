import React, { useState, useEffect } from "react";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import Pagination from "@material-ui/lab/Pagination";

import _ from "lodash";

export default function SMSList() {
  const [agentList, setAgentList] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [params, setParams] = useState({
    per_page: 15,
    page: 1,
  });

  console.log("HOOOOO");

  useEffect(() => {
    getPatientList();
  }, [params]);

  const getPatientList = () => {
    AdminAPI.get(`sms`, { params: params })
      .then((response) => {
        setAgentList(response.data.data.data);
        setTotalPage(response.data.data.last_page);
        setPage(response.data.data.current_page);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };

  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);

  return (
    <div>
      <div className="row">
        <div className="col-md-7 form-group">
          <input
            placeholder="Search SMS"
            className="form-control"
            type="text"
            onChange={(e) => handleColumnWiseSearch(e.target.value, "search")}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className=" table-responsive">
            <table className="table table-striped table-bordered">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Phone</th>
                  <th scope="col">Message</th>
                  <th scope="col">Type </th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                {agentList.map((agent, i) => (
                  <tr key={i}>
                    <td>{agent.phone}</td>
                    <td>{agent.message}</td>
                    <td>{agent.type}</td>
                    <td>{agent.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="row">
        <Pagination
          color="primary"
          count={totalPage}
          page={page}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}
