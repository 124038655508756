import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import Pagination from "@material-ui/lab/Pagination";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import _ from "lodash";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
export default function IncommingInvoiceDetailList() {
  const [detailList, setDetailList] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [detailId, setDetailId] = useState(Number);
  const [checkedRowsId, setCheckedRowsId] = useState([]);
  const [defaultStatus, setDefaultStatus] = useState("");
  const [selecedStatus, setSelectedStatus] = useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [params, setParams] = useState({
    per_page: 15,
    page: 1,
  });
  const [updatedTestInvoice, setUpdatedTestInvoice] = useState([]);
  const [updatedCorporate, setUpdatedCorporate] = useState([]);

  useEffect(() => {
    getDetailList();
  }, [params]);

  const handleChckbox = (targetEventChecked, row) => {
    // handleChckbox is used from two places. So code was written like this.
    if (targetEventChecked) {
      setCheckedRowsId([...checkedRowsId, row.id]);
    } else {
      const filteredRowsId = checkedRowsId.filter((id) => id !== row.id); // when unchecking
      setCheckedRowsId(filteredRowsId);
    }
  };
  const updateAll = (updated = [], checked) => {
    console.log("updated", updated);
    console.log("checked", checked);
    let arrayToPost = [];
    const aaa = updated.map((o) => {
      let obj = {
        incoming_invoice_detail_id: o.id,
        diagnostic_test_id: o.matched_test_id,
      };
      if (checked.includes(obj.incoming_invoice_detail_id)) {
        arrayToPost.push(obj);
      }
    });

    console.log("array to post", arrayToPost);

    if (arrayToPost.length > 0) {
      AdminAPI.post(`incoming-invoice-detail/make-invoices`, {
        invoices: arrayToPost,
      })
        .then((response) => {
          alert(response.data.message);
          setCheckedRowsId([]);
          setUpdatedTestInvoice([]);
          getDetailList();
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
  };
  const getDetailList = () => {
    AdminAPI.get(`incoming-invoice-details`, { params: params })
      .then((response) => {
        setDetailList(response.data.data.data);
        setTotalPage(response.data.data.last_page);
        setPage(response.data.data.current_page);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };

  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);
  const updatePatient = (status, id) => {
    console.log(id, status);
    if (status === "Active") {
      setDefaultStatus("Inactive");
      setSelectedStatus("Inactive");
    }
    if (status === "Inactive") {
      setDefaultStatus("Active");
      setSelectedStatus("Active");
    }

    setDetailId(id);

    setOpenModal(true);
  };

  const changeStatus = (e) => {
    setSelectedStatus(e.target.value);
  };
  const submitStatus = () => {
    let data = {
      status: selecedStatus,
    };
    AdminAPI.post(`incoming-test/${detailId}/update-status`, data)
      .then((response) => {
        alert("You have successfully updated the patient status");
        getDetailList();
        setOpenModal(false);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const makeInvoice = (updated = [], id) => {
    const data = updated.filter((test) => test.id === id);

    if (data.length > 0) {
      AdminAPI.post(`incoming-invoice-details/${id}/make-invoice`, {
        diagnostic_test_id: data[0].matched_test_id,
      })
        .then((response) => {
          alert(response.data.message);
          setUpdatedTestInvoice([]);
          getDetailList();
        })
        .catch((error) => {
          console.log(error.response);
        });
    } else {
      alert("Fill up the matched test field correctly");
    }
  };

  const handleUpdateTestInput = (test, value) => {
    let index = updatedTestInvoice.findIndex((x) => x.id === test.id);
    if (index !== -1) {
      let temporaryarray = updatedTestInvoice.slice();
      temporaryarray[index]["matched_test_id"] = value;
      setUpdatedTestInvoice(temporaryarray);
    } else {
      let up = [...updatedTestInvoice];
      let obj = {
        id: test.id,
        matched_test_id: value,
      };

      obj["matched_test_id"] = value;
      up.push(obj);

      setUpdatedTestInvoice(up);
    }
  };
  const handleUpdateCorporate = (test, value) => {
    let index = updatedCorporate.findIndex((x) => x.id === test.id);
    if (index !== -1) {
      let temporaryarray = updatedCorporate.slice();
      temporaryarray[index]["corporate_id"] = value;
      setUpdatedCorporate(temporaryarray);
    } else {
      let up = [...updatedCorporate];
      let obj = {
        id: test.id,
        corporate_id: value,
      };

      obj["corporate_id"] = value;
      up.push(obj);

      setUpdatedCorporate(up);
    }
  };

  return (
    <div>
      <div>
        <div style={{ position: "sticky" }} className="row">
          <div className="col-md-3 form-group">
            <select
              className="form-control"
              onChange={(e) => {
                handleColumnWiseSearch(e.target.value, "status");
              }}
            >
              <option value="">All</option>
              <option value="Approved">Approved</option>
              <option value="Pending">Pending</option>
            </select>
          </div>
          <div className="col-md-3 form-group">
            <select
              className="form-control"
              onChange={(e) => {
                handleColumnWiseSearch(e.target.value, "referred_by");
              }}
            >
              <option value="">All</option>
              <option value="Self">Patient</option>
              <option value="Admin">Admin</option>
              <option value="Agent">Agent</option>
              <option value="Corporate">Corporate</option>
              <option value="BCorporate">BCorporate</option>
            </select>
          </div>
          <div className="col-md-4 text-right form-group">
            <input
              placeholder="Search Patient"
              className="form-control"
              type="text"
              onChange={(e) => handleColumnWiseSearch(e.target.value, "search")}
            />
          </div>
          <div className="col-md-2 form-group">
            <button
              onClick={() => updateAll(updatedTestInvoice, checkedRowsId)}
              type="button"
              className="btn btn-outline-secondary float-right"
            >
              Update Multiple
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="table-overflow">
              <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Check</th>
                    <th scope="col">Lab ID</th>
                    <th scope="col">Lab Inv ID</th>
                    <th scope="col">Lab</th>
                    <th scope="col">Test</th>
                    <th scope="col">Department</th>
                    <th scope="col">Type</th>
                    <th scope="col">Matched test</th>
                    <th scope="col">Price</th>
                    <th scope="col">Discount</th>
                    <th scope="col">Bill</th>
                    <th scope="col">Patient Name</th>
                    <th scope="col">Patient Contact</th>
                    <th scope="col">Patient Address</th>
                    <th scope="col">Test date</th>
                    <th scope="col">Status</th>

                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {detailList.map((detail, i) => (
                    <tr key={i}>
                      <td>
                        <Checkbox
                          checked={checkedRowsId.includes(detail.id)}
                          onChange={(event) =>
                            handleChckbox(event.target.checked, detail)
                          }
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </td>
                      <td>{detail.lab_id}</td>
                      <td>{detail.incoming_invoice.lab_invoice_id}</td>
                      <td>{detail.incoming_invoice.service_provider.name}</td>
                      <td>{detail.test_name}</td>
                      <td>{detail.test_department}</td>
                      <Test
                        handleCorporte={(test, value) =>
                          handleUpdateCorporate(test, value)
                        }
                        invoice={detail}
                        handleData={(test, value) =>
                          handleUpdateTestInput(test, value)
                        }
                        value={
                          detail.diagnostic_test != null
                            ? detail.diagnostic_test.name
                            : ""
                        }
                      />
                      {/* <td>
                        <TestType
                        
                        />
                      </td>
                      <td>
                        <MathcedTest
                      
                        />
                      </td> */}
                      <td>{Math.ceil(detail.price)}</td>
                      <td>{detail.discount}</td>
                      <td>{detail.bill}</td>
                      <td>{detail.incoming_invoice.patient_name}</td>
                      <td>{detail.incoming_invoice.patient_phone}</td>
                      <td>{detail.incoming_invoice.patient_address}</td>
                      <td>{detail.incoming_invoice.test_date}</td>

                      <td>{detail.status}</td>

                      <td>
                        <button
                          onClick={() => {
                            console.log(
                              "updated",
                              detail.id,
                              updatedTestInvoice
                            );
                            makeInvoice(updatedTestInvoice, detail.id);
                          }}
                        >
                          Update
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handleChange}
          />
        </div>
        <Dialog
          fullWidth
          maxWidth={`sm`}
          open={openModal}
          onClose={() => setOpenModal(false)}
        >
          <DialogTitle id="max-width-dialog-title">
            Change Patient Status
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <select
                className=" form-control"
                defaultValue={defaultStatus}
                onChange={(event) => changeStatus(event)}
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenModal(false)} color="primary">
              Close
            </Button>
            <Button onClick={() => submitStatus()} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

const Test = ({ invoice, value, handleCorporte, handleData }) => {
  const [corporateList, setCorporateList] = useState([]);
  const [corporateValue, setCorporateValue] = useState("");
  const [testType, setTestType] = useState("Patient");
  const [typeParams, setTypeParams] = useState({
    page: 1,
    per_page: 15,
  });
  const [testList, setTestList] = useState([]);
  const [testValue, setTestValue] = useState(value);
  const [testParams, setTestParams] = useState({
    page: 1,
    per_page: 15,
  });

  const handleOnInputCaptureDis = _.debounce((event) => {
    handleColumnWiseSearch(event.target.value, "name");
  }, 500);

  useEffect(() => {
    if (
      invoice &&
      invoice.incoming_invoice &&
      invoice.incoming_invoice.service_provider &&
      invoice.incoming_invoice.service_provider.id
    ) {
      setTestParams({
        ...testParams,
        service_provider_id: invoice.incoming_invoice.service_provider.id,
      });
      if (invoice.diagnostic_test && invoice.diagnostic_test.name) {
        setTestValue(invoice.diagnostic_test.name);
      }
    }
    return () => {};
  }, [invoice]);
  useEffect(() => {
    if (
      testParams.service_provider_id &&
      testType === "Patient" &&
      testParams.name
    ) {
      getDiagTestList();
    }
    if (
      testParams.service_provider_id &&
      testType === "Corporate" &&
      testParams.search
    ) {
      setTestValue("");
      getCorporateTestList();
    }

    return () => {};
  }, [testParams, testType]);
  const handleColumnWiseSearch = (inputValue, column) => {
    const paramState = { ...testParams };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setTestParams(paramState);
  };
  const getDiagTestList = () => {
    AdminAPI.get("diagnostic-tests", {
      params: testParams,
    })
      .then((response) => {
        console.log("Test list", response.data.data);
        setTestList(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const getCorporateTestList = () => {
    AdminAPI.get("corporate-tests", {
      params: testParams,
    })
      .then((response) => {
        console.log("Test list", response.data.data);
        setTestList(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleOnInputCaptureDisCorp = _.debounce((event) => {
    handleColumnWiseSearchCorp(event.target.value, "search");
  }, 500);

  useEffect(() => {
    if (typeParams.search) getCorpList();

    return () => {};
  }, [typeParams]);

  const handleColumnWiseSearchCorp = (inputValue, column) => {
    const paramState = { ...typeParams };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setTypeParams(paramState);
  };
  const getCorpList = () => {
    AdminAPI.get("corporates", {
      params: typeParams,
    })
      .then((response) => {
        console.log("COrporte list", response.data.data);
        setCorporateList(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  return (
    <React.Fragment>
      <td>
        <div style={{ minWidth: "150px" }}>
          <select
            className="form-control"
            value={testType}
            onChange={(e) => {
              setTestType(e.target.value);
            }}
          >
            <option value="Patient">Patient</option>
            <option value="Corporate">Corporate</option>
          </select>
          {testType === "Corporate" && (
            <Autocomplete
              style={{ width: 250 }}
              freeSolo
              id="free-solo-2-demo"
              value={corporateValue}
              options={corporateList}
              getOptionLabel={(option) => {
                return option.name || corporateValue;
              }}
              onInputCapture={(event) => {
                event.persist(); // As we are accessing the event in asynchronous way.
                handleOnInputCaptureDisCorp(event);
              }}
              onChange={(e, value) => {
                if (value == null) {
                  console.log("Hello");
                  setCorporateValue("");
                } else {
                  console.log(value);
                  setCorporateValue(value);
                  handleCorporte(invoice, value.id);
                }
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          )}
        </div>
      </td>
      <td>
        <div>
          <Autocomplete
            freeSolo
            style={{ minWidth: 300 }}
            id="free-solo-2-demo"
            value={testValue}
            options={testList}
            onInputCapture={(event) => {
              event.persist(); // As we are accessing the event in asynchronous way.
              handleOnInputCaptureDis(event);
            }}
            getOptionLabel={(option) => {
              return option.name || testValue;
            }}
            onChange={(e, value) => {
              if (value == null) {
                console.log("Hello");
                setTestValue("");
              } else {
                console.log(value);
                setTestValue(value);
                handleData(invoice, value.id);
              }
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </div>
      </td>
    </React.Fragment>
  );
};
