import React from "react";

import Slider from "react-slick";
import { useHistory } from "react-router-dom";
var settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 2,
  slidesToScroll: 2,
  initialSlide: 0,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const PackageSlider = ({ title }) => {
  let history = useHistory();
  const routeToAvailableTests = (route) => {
    history.push(`/available-tests/`);
  };
  return (
    <div>
      <section class="our-team">
        <div class="container container-custom">
          <div class="row">
            <div class="col-md-12">
              <div class="sub-title_center">
                <h2>{title}</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div
                onClick={() => routeToAvailableTests("")}
                className="display-grid"
              >
                <div>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/Arthitis-Profile.png`}
                    class="img-fluid"
                    alt="Arthitis-Profile"
                  />
                </div>
                <div>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/Diabetes-Screening.png`}
                    class="img-fluid"
                    alt="Diabetes-Screening"
                  />
                </div>
                <div>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/Executive-Health-Checkup-for-all.png`}
                    class="img-fluid"
                    alt="Executive-Health-Checkup-for-all"
                  />
                </div>
                <div>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/Executive-Health-Checkup-for-female.png`}
                    class="img-fluid"
                    alt="Executive-Health-Checkup-for-female"
                  />
                </div>
                <div>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/Executive-Health-Checkup-for-male.png`}
                    class="img-fluid"
                    alt="Executive-Health-Checkup-for-male"
                  />
                </div>
                <div>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/Female-Hormone-Test.png`}
                    class="img-fluid"
                    alt="Female-Hormone-Test"
                  />
                </div>
                <div>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/Hepatitis-Panel.png`}
                    class="img-fluid"
                    alt="Hepatitis-Panel"
                  />
                </div>
                <div>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/Kindney-Function-Test-(KFT).png`}
                    class="img-fluid"
                    alt="Kindney-Function-Test-(KFT)"
                  />
                </div>
                <div>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/Lipid-Profile.png`}
                    class="img-fluid"
                    alt="Lipid-Profile"
                  />
                </div>
                <div>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/Liver-Function-Test.png`}
                    class="img-fluid"
                    alt="Liver-Function-Test"
                  />
                </div>
                <div>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/Radiology-Package.png`}
                    class="img-fluid"
                    alt="Radiology-Package"
                  />
                </div>
                <div>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/Smokers-Health.png`}
                    class="img-fluid"
                    alt="Smokers-Health"
                  />
                </div>
                <div>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/Thalasemia-Panel.png`}
                    class="img-fluid"
                    alt="Thalasemia-Panel"
                  />
                </div>
                <div>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/Thyroid-Panel.png`}
                    class="img-fluid"
                    alt="Thyroid-Panel"
                  />
                </div>
                <div>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/Vitamin-D-Package.png`}
                    class="img-fluid"
                    alt="Vitamin-D-Package"
                  />
                </div>
              </div>
              {/* <div class="doctors-slider">
                <Slider {...settings}>
                  <div
                    onClick={() =>
                      routeToAvailableTests("oncos-molbiol/Thyroid Profile")
                    }
                  >
                    <div class="team-img_block blue">
                      <div class="team-img-socila-block">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/Thyroid-Pannel.png`}
                          class="img-fluid"
                          alt="Thyroid-Pannel"
                        />
                      </div>
                    </div>
                    <h4 className=" text-center">Thyroid Panel</h4>
                  </div>
                  <div
                    onClick={() =>
                      routeToAvailableTests("oncos-molbiol/Liver Function Test")
                    }
                  >
                    <div class="team-img_block blue">
                      <div class="team-img-socila-block">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/Liver-Function-panel.png`}
                          class="img-fluid"
                          alt="Liver-Function-panel"
                        />
                      </div>
                    </div>
                    <h4 className=" text-center">Liver Function Test</h4>
                  </div>
                  <div
                    onClick={() =>
                      routeToAvailableTests("oncos-molbiol/Hb A1c")
                    }
                  >
                    <div class="team-img_block blue">
                      <div class="team-img-socila-block">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/Diabetes-(3-Test)1.png`}
                          class="img-fluid"
                          alt="#"
                        />
                      </div>
                    </div>
                    <h4 className=" text-center">Diabetes</h4>
                  </div>
                  <div
                    onClick={() =>
                      routeToAvailableTests("oncos-molbiol/Lipid Profile")
                    }
                  >
                    <div class="team-img_block blue">
                      <div class="team-img-socila-block">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/Lipid-Profile.png`}
                          class="img-fluid"
                          alt="Lipid-Profile"
                        />
                      </div>
                    </div>
                    <h4 className=" text-center">Lipid Profile</h4>
                  </div>
                </Slider>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PackageSlider;
