import React, { useState, useEffect } from "react";
import { TextField } from "formik-material-ui";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { useParams } from "react-router-dom";

import { AdminAPI } from "../../../AxiosRequest/AdminAPI";

import _ from "lodash";

export default function HospitalForm() {
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [singnedUp, setSignedUp] = useState(false);
  const [policeStations, setPoliceStations] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [district, setDistrict] = useState("");
  const [stationSearchTerm, setStationSearchTerm] = useState("");
  const [districtSearchTerm, setDistrictSearchTerm] = useState("");
  const [districtValue, setDistrictValue] = useState("");
  const [policeStationValue, setPoliceStationValue] = useState("");
  let { id } = useParams();
  const [hospitalData, setHospitalData] = useState({});

  //   console.log("form data", formData);

  // Backend Error Messages

  const [errorMessage, setErrorMessage] = useState({});

  useEffect(() => {
    if (id !== undefined) {
      getAgentData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getAgentData = () => {
    AdminAPI.get(`hospitals/${id}`)
      .then((response) => {
        // setMedicineData(response.data[0]);
        console.log("response data", response.data);
        setHospitalData(response.data.data);
        setDistrict(response.data.data.police_station.district);
        setDistrictValue(response.data.data.police_station.district);
        setPoliceStationValue(response.data.data.police_station.name);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  useEffect(() => {
    GetPoliceStations();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stationSearchTerm, district]);
  useEffect(() => {
    getDistricts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [districtSearchTerm]);
  const GetPoliceStations = () => {
    if (district !== "") {
      axios
        .get(`districts/${district}?name=${stationSearchTerm}`)
        .then((response) => {
          setPoliceStations(response.data.data.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
  };
  const getDistricts = () => {
    axios
      .get(`districts?name=${districtSearchTerm}`)
      .then((response) => {
        console.log("dddd", response.data.data.data);
        setDistrictList(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleOnInputCaptureMed = _.debounce((event) => {
    setStationSearchTerm(event.target.value);
  }, 500);
  const handleOnInputCaptureDis = _.debounce((event) => {
    setDistrictSearchTerm(event.target.value);
  }, 500);
  const validationSchemaPost = Yup.object({
    name: Yup.string().required("Name field is required"),
    phone: Yup.string().required("Please enter your phone number"),
    email: Yup.string()
      .email("Please Enter a valid Email")
      .required("Email field is required"),
    contact_person: Yup.string().required(
      "Please enter your contact person number"
    ),

    address: Yup.string().required("Please enter your address"),
    password: Yup.string()
      .min(6, "Password must contain at least 6 characters")
      .required("Enter your password"),
    confirm_password: Yup.string()
      .required("Confirm your password")
      .oneOf([Yup.ref("password")], "Password does not match"),

    police_station_id: Yup.string().required(
      "Police Station field is required"
    ),
  });
  const validationSchemaUpdate = Yup.object({
    name: Yup.string().required("Name field is required"),
    phone: Yup.string().required("Please enter your phone number"),
    email: Yup.string()
      .email("Please Enter a valid Email")
      .required("Email field is required"),
    contact_person: Yup.string().required(
      "Please enter your contact person number"
    ),

    address: Yup.string().required("Please enter your address"),

    police_station_id: Yup.string().required(
      "Police Station field is required"
    ),
  });
  const initialValues = {
    name: "" || hospitalData.name,
    phone: "" || hospitalData.phone,
    email: "" || hospitalData.email,
    contact_person: "" || hospitalData.contact_person,
    address: "" || hospitalData.address,
    police_station_id: "" || hospitalData.police_station_id,
    password: "",
    confirm_password: "",
  };

  const onSubmit = (values, { resetForm }) => {
    if (id) {
      setLoading(true);
      console.log(values);
      let objToSend = { ...values };
      delete objToSend.password;
      delete objToSend.confirm_password;
      AdminAPI.put(`hospitals/${id}`, objToSend)
        .then((response) => {
          console.log(response);
          console.log(response.data.message);
          setSignedUp(true);
          setSuccessMessage(response.data.message);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error.response);
          setLoading(false);

          if (error.response.data.errors !== undefined) {
            setErrorMessage(error.response.data.errors);
          }
        });
    } else {
      setLoading(true);
      console.log(values);

      AdminAPI.post(`hospitals`, values)
        .then((response) => {
          console.log(response);
          console.log(response.data.message);
          alert(response.data.message);
          setSuccessMessage(response.data.message);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error.response);
          setLoading(false);

          if (error.response.data.errors !== undefined) {
            setErrorMessage(error.response.data.errors);
          }
        });
    }

    //
  };
  return (
    <div>
      <div className="container">
        <div className="add-institution">
          <div className="row">
            <div className="col-md-12">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={
                  id ? validationSchemaUpdate : validationSchemaPost
                }
                onSubmit={onSubmit}
              >
                {({ setFieldValue, isValid, dirty }) => (
                  <Form>
                    <div className="form-group row ">
                      <label className="col-sm-3 offset-sm-1 col-form-label">
                        Name
                      </label>
                      <div className="col-sm-7">
                        <Field
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Your Name"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="error-message"
                        />
                        <p className="error-message">{errorMessage.name}</p>
                      </div>
                    </div>
                    <div className="form-group row ">
                      <label className="col-sm-3 offset-sm-1 col-form-label">
                        Phone
                      </label>
                      <div className="col-sm-7">
                        <Field
                          name="phone"
                          type="text"
                          className="form-control"
                          placeholder="Phone/Mobile Number"
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="error-message"
                        />
                        <p className="error-message">{errorMessage.phone}</p>
                      </div>
                    </div>
                    <div className="form-group row ">
                      <label className="col-sm-3 offset-sm-1 col-form-label">
                        Email
                      </label>
                      <div className="col-sm-7">
                        <Field
                          name="email"
                          type="email"
                          className="form-control"
                          placeholder="Email"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error-message"
                        />
                        <p className="error-message">{errorMessage.email}</p>
                      </div>
                    </div>

                    <div className="form-group row ">
                      <label className="col-sm-3 offset-sm-1 col-form-label">
                        Contact Person
                      </label>
                      <div className="col-sm-7">
                        <Field
                          name="contact_person"
                          type="text"
                          className="form-control"
                          placeholder="Contact Person"
                        />
                        <ErrorMessage
                          name="contact_person"
                          component="div"
                          className="error-message"
                        />
                        <p className="error-message">
                          {errorMessage.contact_person}
                        </p>
                      </div>
                    </div>

                    <div className="form-group row ">
                      <label className="col-sm-3 offset-sm-1 col-form-label">
                        Address
                      </label>
                      <div className="col-sm-7">
                        <Field
                          name="address"
                          type="text"
                          className="form-control"
                          placeholder="Your Address"
                        />
                        <ErrorMessage
                          name="address"
                          component="div"
                          className="error-message"
                        />
                        <p className="error-message">{errorMessage.address}</p>
                      </div>
                    </div>

                    <div className="form-group row ">
                      <label className="col-sm-3 offset-sm-1 col-form-label">
                        District
                      </label>
                      <div className="col-sm-7">
                        <Autocomplete
                          freeSolo
                          id="disabledHelperText"
                          name="district"
                          options={districtList}
                          onInputCapture={(event) => {
                            event.persist(); // As we are accessing the event in asynchronous way.
                            handleOnInputCaptureDis(event);
                          }}
                          // getOptionLabel={(option) => option.name}
                          getOptionLabel={(option) => {
                            return option.district;
                          }}
                          // value={districtValue}
                          onChange={(e, value) => {
                            if (value == null) {
                              console.log("Hello");
                              setDistrictValue("");
                              // setPoliceStations([]);
                              // setFieldValue("police_station_id", "");
                            } else {
                              console.log(value);
                              // setPoliceStations([]);
                              setDistrict(value.district);
                              setDistrictValue(value.district);
                              GetPoliceStations();
                            }
                          }}
                          renderInput={(params) => (
                            <Field
                              component={TextField}
                              {...params}
                              onChange={(e, value) => {
                                console.log(e.target.value);
                              }}
                              name="district"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                        <ErrorMessage
                          name="district"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>

                    <div className="form-group row ">
                      <label className="col-sm-3 offset-sm-1 col-form-label">
                        Police Stations
                      </label>
                      <div className="col-sm-7">
                        <Autocomplete
                          disabled={district === ""}
                          freeSolo
                          id="disabledHelperText"
                          name="police_station_id"
                          options={policeStations}
                          onInputCapture={(event) => {
                            event.persist(); // As we are accessing the event in asynchronous way.
                            handleOnInputCaptureMed(event);
                          }}
                          getOptionLabel={(option) => {
                            return option.name || policeStationValue;
                          }}
                          value={policeStationValue}
                          onChange={(e, value) => {
                            if (value == null) {
                              setFieldValue("police_station_id", "");
                              setPoliceStationValue("");
                            } else {
                              console.log(value);

                              setPoliceStationValue(value.name);
                              setFieldValue("police_station_id", value.id);
                            }
                          }}
                          renderInput={(params) => (
                            <Field
                              component={TextField}
                              {...params}
                              onChange={(e, value) => {
                                console.log(e.target.value);
                              }}
                              name="police_station_id"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                        <ErrorMessage
                          name="police_station_id"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>
                    {id ? (
                      ""
                    ) : (
                      <React.Fragment>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1 col-form-label ">
                            Password
                          </label>
                          <div className="col-sm-7">
                            <Field
                              name="password"
                              type="password"
                              className="form-control "
                              placeholder="password"
                            />
                            <ErrorMessage
                              name="password"
                              component="div"
                              className="error-message"
                            />
                            <p className="error-message">
                              {errorMessage.password}
                            </p>
                          </div>
                        </div>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1 col-form-label ">
                            Confirm Password
                          </label>
                          <div className="col-sm-7">
                            <Field
                              name="confirm_password"
                              type="password"
                              className="form-control "
                              placeholder="password"
                            />
                            <ErrorMessage
                              name="confirm_password"
                              component="div"
                              className="error-message"
                            />
                            <p className="error-message">
                              {errorMessage.confirm_password}
                            </p>
                          </div>
                        </div>
                      </React.Fragment>
                    )}

                    <div className="form-group row ">
                      <label className="col-sm-5 offset-sm-1">
                        <button
                          disabled={loading}
                          // disabled={!isValid || !dirty}
                          type="submit"
                          className="btn btn-lg btn-success"
                        >
                          {loading && <i className="fa fa-refresh fa-spin"></i>}
                          {loading && id && <span>Updating</span>}
                          {loading && !id && <span>Submitting</span>}
                          {!loading && id && <span> Update</span>}
                          {!loading && !id && <span> Submit</span>}
                        </button>
                      </label>
                      <div className="col-sm-7"></div>
                    </div>
                    <div className="form-group row ">
                      <label className="col-sm-11 offset-sm-1">
                        {successMessage}
                      </label>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
