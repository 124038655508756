import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";

import { Modal } from "antd";

import "../css/add-institution.css";

export default function DoctorDetails() {
  let history = useHistory();
  let { docId } = useParams();
  const [docDetails, setDocDetails] = useState({});
  const [path, setPath] = useState("");

  const [approveRegistrationModal, setApproveRegistrationModal] = useState(
    false
  );
  const [acceptModal, setAcceptModal] = useState(false);
  const [confirmRejectModal, setConfirmRejectModal] = useState(false);
  const [declineRegistrationModal, setDeclineRegistrationModal] = useState(
    false
  );

  useEffect(() => {
    getDocDetails();
  }, []);

  const getDocDetails = () => {
    AdminAPI.get(`doctors/${docId}`)
      .then((response) => {
        console.log("doc details", response.data.data);
        setDocDetails(response.data.data);
        setPath(response.data.path);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const approveRegistration = () => {
    AdminAPI.post(`doctors/${docId}/update-status`, {
      status: "Active",
    })
      .then((response) => {
        console.log(response);
        setApproveRegistrationModal(false);
        setConfirmRejectModal(true);
        setTimeout(() => {
          history.push("/admin/doctor/list");
        }, 3000);
        // setStatusMessage(response.data.message);
        // setStatusModal(true);
      })
      .catch((error) => {
        console.log(error.response);
        // setStatusMessage(error.response.data.message);
      });
  };

  const declineRegistration = () => {
    AdminAPI.post(`doctors/${docId}/update-status`, {
      status: "Inactive",
    })
      .then((response) => {
        console.log(response);
        setDeclineRegistrationModal(false);
        setConfirmRejectModal(true);
        setTimeout(() => {
          history.push("/admin/doctor/list");
        }, 3000);
        // setStatusMessage(response.data.message);
        // setStatusModal(true);
      })
      .catch((error) => {
        console.log(error.response);
        // setStatusMessage(error.response.data.message);
      });
  };

  const closeAcceptModal = () => {
    setAcceptModal(false);
    setApproveRegistrationModal(false);
    setDeclineRegistrationModal(false);
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {docDetails !== undefined && (
              <div className="doc-details-admin">
                <div className="d-flex justify-content-lg-end justify-content-sm-start">
                  <div className=""></div>
                  <div className="">
                    <div className="doc-profile">
                      {path !== "" && docDetails.photo !== undefined && (
                        <img
                          src={
                            `${process.env.REACT_APP_PUBLIC_URL}${path}/` +
                            docDetails.photo
                          }
                          alt="..."
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-2 offset-md-1">Name</div>
                  <div className="col-sm-7">{docDetails.name}</div>
                </div>
                <div className="row">
                  <div className="col-md-2 offset-md-1">Phone</div>
                  <div className="col-sm-7">{docDetails.phone}</div>
                </div>
                <div className="row">
                  <div className="col-md-2 offset-md-1">Email</div>
                  <div className="col-sm-7">{docDetails.email}</div>
                </div>
                <div className="row">
                  <div className="col-md-2 offset-md-1">Date of Birth</div>
                  <div className="col-sm-7">{docDetails.date_of_birth}</div>
                </div>
                <div className="row">
                  <div className="col-md-2 offset-md-1">Designation</div>
                  <div className="col-sm-7">{docDetails.designation}</div>
                </div>
                <div className="row">
                  <div className="col-md-2 offset-md-1">Degrees</div>
                  {docDetails.doctor_degrees !== undefined && (
                    <div className="col-sm-7">
                      {docDetails.doctor_degrees.map((degree, index) => (
                        <React.Fragment key={index}>
                          <span> {degree.degree}</span>
                          {index === docDetails.doctor_degrees.length - 1
                            ? ""
                            : ","}
                        </React.Fragment>
                      ))}
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-2 offset-md-1">Department</div>
                  <div className="col-sm-7">{docDetails.department}</div>
                </div>
                <div className="row">
                  <div className="col-md-2 offset-md-1">
                    Current Institution
                  </div>
                  <div className="col-sm-7">{docDetails.institution}</div>
                </div>
                <div className="row">
                  <div className="col-md-2 offset-md-1">BMDC Number</div>
                  <div className="col-sm-7">{docDetails.bmdc_number}</div>
                </div>
                <div className="row">
                  <div className="col-md-2 offset-md-1">
                    Passing Institution
                  </div>
                  <div className="col-sm-7">
                    {docDetails.passing_institution}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 offset-md-1">Passing Year</div>
                  <div className="col-sm-7">{docDetails.passing_year}</div>
                </div>
                <div className="row">
                  <div className="col-md-2 offset-md-1">Practicing Year</div>
                  <div className="col-sm-7">{docDetails.practice_year}</div>
                </div>
                <div className="row">
                  <div className="col-md-2 offset-md-1">New Patient Visit</div>
                  <div className="col-sm-7">{docDetails.new_patient_visit}</div>
                </div>
                <div className="row">
                  <div className="col-md-2 offset-md-1">Old Patient Visit</div>
                  <div className="col-sm-7">{docDetails.old_patient_visit}</div>
                </div>
                <div className="row">
                  <div className="col-md-2 offset-md-1">Status</div>
                  <div className="col-sm-7">{docDetails.status}</div>
                </div>
                <div className="row">
                  {docDetails.status != "Active" && (
                    <div className="col-md-2 offset-md-1">
                      <button
                        onClick={() => {
                          setApproveRegistrationModal(true);
                        }}
                        className="btn btn-md btn-success"
                      >
                        Approve
                      </button>
                    </div>
                  )}
                  {docDetails.status != "Inactive" && (
                    <div className="col-md-2 offset-md-1">
                      <button
                        onClick={() => {
                          setDeclineRegistrationModal(true);
                        }}
                        className="btn btn-md btn-danger"
                      >
                        Decline
                      </button>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-md-12">
                      <Modal
                        title=""
                        centered
                        visible={declineRegistrationModal}
                        onOk={declineRegistration}
                        onCancel={closeAcceptModal}
                        footer={[
                          <button
                            className="btn btn-warning float-left"
                            key="back"
                            onClick={closeAcceptModal}
                          >
                            No
                          </button>,
                          <button
                            key="submit"
                            className="btn btn-danger"
                            onClick={declineRegistration}
                          >
                            Yes
                          </button>,
                        ]}
                      >
                        <p>
                          Do you really want to decline this doctor registration
                          request?
                        </p>
                      </Modal>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <Modal
                        title=""
                        centered
                        visible={approveRegistrationModal}
                        onOk={approveRegistration}
                        onCancel={closeAcceptModal}
                        footer={[
                          <button
                            className="btn btn-warning float-left"
                            key="back"
                            onClick={closeAcceptModal}
                          >
                            No
                          </button>,
                          <button
                            key="submit"
                            className="btn btn-success"
                            onClick={approveRegistration}
                          >
                            Yes
                          </button>,
                        ]}
                      >
                        <p>
                          Are you sure to approve this doctor registration
                          request?
                        </p>
                      </Modal>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <Modal
                        title="Confirmation"
                        visible={confirmRejectModal}
                        onOk={() => setConfirmRejectModal(false)}
                        onCancel={() => setConfirmRejectModal(false)}
                        //   cancelButtonProps={{ style: { display: "none" } }}
                      >
                        <p>Doctor status updated. </p>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
