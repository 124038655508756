import React, { useState, useEffect } from "react";
// import { Button, Modal } from "react-bootstrap";
import Alert from "@material-ui/lab/Alert";
import { useHistory, useParams } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { TextField as TextFieldSecond } from "formik-material-ui";
import * as Yup from "yup";

import "../css/global.css";

export default function UploadDepartmentCSV() {
  let history = useHistory();
  const [successMsg, setSuccessMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [preObj, setPreObj] = useState({});
  const [loading, setLoading] = useState(false);

  const [erroropen, setErrorOpen] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");

  const uploadMultipleFiles = async (e) => {
    if (checkMimeType(e)) {
      setFiles(e.target.files[0]);
    }
  };

  const checkMimeType = (event) => {
    //getting file object
    let files = event.target.files;
    //define message container
    let err = "";
    // list allow mime type
    //  "application/pdf"
    const types = [".csv", "application/csv"];

    if (err !== "") {
      // if message not same old that mean has error
      event.target.value = null; // discard selected file
      alert(err);
      return false;
    }
    return true;
  };

  const download = () => {
    AdminAPI.get(`sample-csv`, {
      method: "GET",
      responseType: "blob", //Force to receive data in a Blob Format
    })
      .then((response) => {
        const file = new Blob([response.data], { type: "application/csv" }); //Build a URL from the file
        const fileURL = URL.createObjectURL(file); //Open the URL on new Window
        var a = document.createElement("a");
        a.href = fileURL;
        a.download = "corporate patients.csv";
        a.click();
        window.URL.revokeObjectURL(fileURL);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmit = async (values, { resetForm }) => {
    setLoading(true);
    let formData = new FormData();

    formData.append("file", files);
    AdminAPI.post(`department/upload-csv`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        setLoading(false);
        setSuccessMsg(response.data.message);
        setErrorOpen(false);
        setOpen(true);
        localStorage.removeItem("upTestObj");
        history.push("/admin/department/list");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.response.data.message);
        setErrorOpen(true);
        setErrorMsg(error.response.data.message);
      });
  };
  const validationSchema = Yup.object({
    // service_provider_id: Yup.number().required("This field is required"),
  });
  const initialValues = {
    service_provider_id: "" || preObj.diagnosticID,
  };
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h6>
              <span style={{ backgroundColor: "yellow" }}>
                CSV Format: Name, Phone, Date of birth(date/month/year), Age,
                Gender, Address, Password, Lab name, Test name, Test date
                (date/month/year), Home sample (yes/no)
              </span>
            </h6>
          </div>
          <div className="col-12">
            <button
              onClick={(event) => {
                event.preventDefault();
                download();
              }}
              className="btn btn-outline-primary"
            >
              Download sample
            </button>
          </div>
        </div>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ setFieldValue, isValid, dirty }) => (
            <Form style={{ backgroundColor: "white", padding: "15px" }}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="file"
                      className="form-control"
                      onChange={uploadMultipleFiles}
                    />
                    <p>Upload your CSV here </p>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-12">
                  <div className="float-right">
                    <button
                      disabled={files.length === 0}
                      type="submit"
                      className="btn btn-outline-success"
                    >
                      {loading && <i className="fa fa-refresh fa-spin"></i>}
                      {loading && <span> Uploading CSV</span>}
                      {!loading && <span>Upload CSV</span>}
                    </button>
                  </div>
                </div>
              </div>
              <div className="py-3">
                <Collapse in={open}>
                  <Alert
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {successMsg}
                  </Alert>
                </Collapse>
              </div>
              <div className="py-3">
                <Collapse in={erroropen}>
                  <Alert
                    severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setErrorOpen(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {errorMsg}
                  </Alert>
                </Collapse>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
