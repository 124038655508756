import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { TextField as TextFieldSecond } from "formik-material-ui";
import axios from "axios";
import Badge from "@material-ui/core/Badge";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Pagination from "@material-ui/lab/Pagination";
import { useHistory, useParams } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Link } from "react-router-dom";
import AddShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";

import DeleteIcon from "@material-ui/icons/Delete";
import TextField from "@material-ui/core/TextField";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { withStyles } from "@material-ui/core/styles";
import "./css/home.css";
import * as Yup from "yup";

import Navbar2 from "../Shared/Navbar2";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import Drawer from "@material-ui/core/Drawer";
import { Icon } from "@material-ui/core";
import "./css/login.css";
import Footer from "../Shared/Footer";
const drawerWidthCart = 320;
const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge);
const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    top: "10%",
    left: "10%",
    overflow: "hidden",
    height: "100%",
    maxHeight: 500,
    display: "block",
  },
  icnBtnRoot: {
    padding: "0px",
  },
  header: {
    padding: "12px 0",
    borderBottom: "1px solid darkgrey",
  },
  content: {
    padding: 12,
    overflow: "scroll",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,

    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: "#3f51b5",
    fontWeight: "700",
  },
  hide: {
    display: "none",
  },

  drawerCart: {
    width: drawerWidthCart,
    flexShrink: 0,
  },
  drawerPaperCart: {
    width: drawerWidthCart,
    [theme.breakpoints.up("sm")]: {
      top: "65px",
      width: drawerWidthCart,
    },
    [theme.breakpoints.down("sm")]: {
      top: "55px",
      width: "100%",
    },
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: -drawerWidth,
  },

  contentShift2: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up("sm")]: {
      marginRight: drawerWidthCart,
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  root: {
    display: "flex",
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
}));
function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return "Choose your preferred lab from below...";
    case 1:
      return "Search test or upload your prescription";
    case 2:
      return "Press add to card button from the table";
    default:
      return "Unknown stepIndex";
  }
}
function getStepContentCovid(stepIndex) {
  switch (stepIndex) {
    case 0:
      return "Choose your preferred lab from below...";
    case 1:
      return "Type covid in the search test area if the covid related test doesn't appear below";
    case 2:
      return "You can change the lab for covid test by clicking on the Select Diagnostic Lab field. To add covid test Press add to cart button from the table";
    default:
      return "Unknown stepIndex";
  }
}
function getSteps() {
  return [
    "Select a Diagnostic Lab",
    "Search Test or Upload Prescription",
    "Add To Cart",
  ];
}

export default function TestListHome(props) {
  const storage = localStorage.getItem("names")
    ? JSON.parse(localStorage.getItem("names"))
    : [];
  let history = useHistory();
  const theme = useTheme();
  const isTabMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let { diagName, testName } = useParams();
  const [open, setOpen] = useState(false);
  const [policeStations, setPoliceStations] = useState([]);
  const [diagnosticList, setDiagnosticList] = useState([]);
  const [users, setUsers] = useState([]);
  const [tableData, setTableData] = useState(storage);
  const [totalRegularPrice, setTotalRegularPrice] = useState(Number);
  const [totalDiscountPrice, setTotalDiscountPrice] = useState(Number);
  const [totalPrice, setTotalPrice] = useState(Number);
  const [policeStationID, setPoliceStationID] = useState("");
  const [diagnosticID, setDiagnosticID] = useState("");
  const [erroropen, setErrorOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [testList, setTestList] = useState([]);
  const [openCart, setOpenCart] = React.useState(true);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Backend Errors Handle
  const [backendErrorMessage, setBackendErrorMessage] = useState({});

  const [sameMedicineErrors, setSameMedicineErrors] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const [openDetails, setOpeDetails] = React.useState(false);
  const [openCheck, setOpenCheck] = React.useState(false);
  const [stationSearchTerm, setStationSearchTerm] = useState("");
  const [diagnosticValue, setDiagnosticValue] = useState("");
  const [homeSample, setHomeSample] = React.useState(false);
  const [covid, setCovid] = React.useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [params, setParams] = useState({
    page: 1,
    per_page: 15,
  });
  const [files, setFiles] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [previews, setPreviews] = useState([]);
  const [upFile, setUpFile] = useState("");
  const [openFile, setOpenFile] = useState(false);
  const [addFirstIndex, setAddFirstIndex] = useState(false);
  const [availableSlot, setAvailableSlot] = useState([]);
  let some = [];
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = (step) => {
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setActiveStep(step);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setTableData([]);
    setSearchField("");
    setDiagnosticID("");
    setDiagnosticValue("");
  };
  console.log("test namemmmmmmmmeaesfdsaf asdf dsa fdsf ", diagName, testName);
  useEffect(() => {
    if (isMobile) {
      console.log("Hello is mobile");
      setOpenCart(false);
    } else {
      setOpenCart(true);
    }
  }, [isMobile]);
  useEffect(() => {
    if (diagName && testName === undefined) {
      setAddFirstIndex(false);
      if (diagName === "Thyrocare") {
        setDiagnosticValue("Thyrocare");
        setDiagnosticID(4);
        setParams({
          ...params,
          service_provider_id: 4,
        });
      } else if (diagName === "DNA-Solution") {
        console.log("inside first looooooooooooooooop DNA-Solution");
        setDiagnosticValue("DNA Solution");
        setDiagnosticID(5);
        setParams({
          ...params,
          service_provider_id: 5,
        });
      } else if (diagName === "Stemz-Healthcare") {
        console.log("inside first looooooooooooooooop Stemz-Healthcare");
        setDiagnosticValue("Stemz Healthcare");
        setDiagnosticID(6);
        setParams({
          ...params,
          service_provider_id: 6,
        });
      } else if (diagName === "CRL-Diagnostics") {
        setDiagnosticValue("CRL Diagnostics");
        setDiagnosticID(14);
        setParams({
          ...params,
          service_provider_id: 14,
        });
      } else if (diagName === "the-pathlabs") {
        setDiagnosticValue("The Pathlabs");
        setDiagnosticID(15);
        setParams({
          ...params,
          service_provider_id: 18,
        });
      } else if (diagName === "hatirpool-physiotherapy-center") {
        setDiagnosticValue("Hatirpool Physiotherapy Center");
        setDiagnosticID(20);
        setParams({
          ...params,
          service_provider_id: 20,
        });
      } else if (diagName === "praava-health") {
        setDiagnosticValue("Praava Health");
        setDiagnosticID(27);
        setParams({
          ...params,
          service_provider_id: 27,
        });
      } else if (diagName === "cccl") {
        setDiagnosticValue("Cure & Care Clinical Lab");
        setDiagnosticID(28);
        setParams({
          ...params,
          service_provider_id: 28,
        });
      } else if (diagName === "oncos-molbiol") {
        setDiagnosticValue("Oncos Molbiol Limited");
        setDiagnosticID(29);
        setParams({
          ...params,
          service_provider_id: 29,
        });
      } else if (diagName === "universal-medical") {
        setDiagnosticValue("Universal Medical College & Hospital");
        setDiagnosticID(30);
        setParams({
          ...params,
          service_provider_id: 30,
        });
      } else if (diagName === "covid") {
        setCovid(true);
        // getDiagnosticList();
        setSearchField("a");
        setDiagnosticValue("Stemz Healthcare");
        setDiagnosticID(6);
        setParams({
          ...params,
          service_provider_id: 6,
          test: "covid",
        });
      } else {
        console.log("diag nameeeee====>", diagName);
        if (diagName !== "covid-travellers") {
          getDiagnosticListCovid(diagName);

          // setDiagnosticValue("Stemz Healthcare");
          // setDiagnosticID(6);
          // setParams({
          //   ...params,
          //   service_provider_id: 6,
          // });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagName, testName]);
  useEffect(() => {
    if (diagName && testName) {
      setAddFirstIndex(true);
      if (diagName === "Thyrocare") {
        setDiagnosticValue("Thyrocare");
        setDiagnosticID(4);
        setParams({
          ...params,
          service_provider_id: 4,
          name: testName,
        });
      } else if (diagName === "DNA-Solution") {
        setDiagnosticValue("DNA Solution");
        setDiagnosticID(5);
        setParams({
          ...params,
          service_provider_id: 5,
          name: testName,
        });
      } else if (diagName === "Stemz-Healthcare") {
        setDiagnosticValue("Stemz Healthcare");
        setDiagnosticID(6);
        setParams({
          ...params,
          service_provider_id: 6,
          name: testName,
        });
      } else if (diagName === "CRL-Diagnostics") {
        setDiagnosticValue("CRL Diagnostics");
        setDiagnosticID(14);
        setParams({
          ...params,
          service_provider_id: 14,
          name: testName,
        });
      } else if (diagName === "the-pathlabs") {
        setDiagnosticValue("The Pathlabs");
        setDiagnosticID(15);
        setParams({
          ...params,
          service_provider_id: 18,
          name: testName,
        });
      } else if (diagName === "oncos-molbiol") {
        setDiagnosticValue("Oncos Molbiol Limited");
        setDiagnosticID(29);
        setParams({
          ...params,
          service_provider_id: 29,
          name: testName,
        });
      } else if (diagName === "praava-health") {
        setDiagnosticValue("Praava Health");
        setDiagnosticID(27);
        setParams({
          ...params,
          service_provider_id: 27,
          name: testName,
        });
      } else if (diagName === "covid") {
        setCovid(true);
        // getDiagnosticList();
        setSearchField("a");
        setDiagnosticValue("Stemz Healthcare");
        setDiagnosticID(6);
        setParams({
          ...params,
          service_provider_id: 6,
          test: "covid",
        });
      } else {
        // if (diagName !== "covid-travellers") {
        //   getDiagnosticListCovid(diagName);
        // }
        // setDiagnosticValue("Stemz Healthcare");
        // setDiagnosticID(6);
        // setParams({
        //   ...params,
        //   service_provider_id: 6,
        // });
      }
    } else {
      // setDiagnosticValue("Stemz Healthcare");
      // setDiagnosticID(6);
      // setParams({
      //   ...params,
      //   service_provider_id: 6,
      // });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagName, testName]);
  console.log("PPPPP", params);
  const getDiagnosticListCovid = (name) => {
    axios
      .get(`diagnostics/${name}`)
      .then((response) => {
        setDiagnosticList(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  useEffect(() => {
    GetPoliceStations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stationSearchTerm]);
  const GetPoliceStations = () => {
    // let url = `http://localhost:3001/purchase_order/${paramID}`;
    axios
      .get(`police-stations?name=${stationSearchTerm}`)
      .then((response) => {
        console.log("pppppp", response.data.data.data);
        setPoliceStations(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const handleOnInputCaptureMed = _.debounce((event) => {
    setStationSearchTerm(event.target.value);
  }, 500);

  const handleOpenDetails = () => {
    setOpeDetails(true);
  };

  const handleCloseDetails = () => {
    setOpeDetails(false);
  };

  const handleOpenCheck = () => {
    // setOpenCheck(true);
  };
  const handleDrawerOpenCart = () => {
    setOpenCart(!openCart);
  };
  const handleCloseCheck = () => {
    setOpenCheck(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangeSample = (event) => {
    setHomeSample(event.target.checked);
  };

  const getDiagnosticList = () => {
    // const params = {
    //   police_station_id: id,
    // };
    // , { params: params }

    axios
      .get("diagnostics")
      .then((response) => {
        let idsToRemove = [1, 2];
        let newArray = response.data.data.data;

        if (covid === true) {
          let covidLabs = newArray.filter(
            (item) => !idsToRemove.includes(item.id)
          );
          setDiagnosticList(covidLabs);
        } else {
          setDiagnosticList(newArray);
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  function handleChangePage(event, value) {
    setParams({ ...params, page: value });
  }
  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      setSearchField(inputValue);
      paramState[column] = inputValue;
    }

    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setAddFirstIndex(false);
    setParams(paramState);
  }, 500);
  useEffect(() => {
    console.log("this is triggered diagn", diagnosticID);
    if (diagnosticID !== "") {
      handleNext(1);
    }
    if (searchField !== "") {
      handleNext(2);
    }
    if (tableData.length > 0) {
      handleNext(3);
    }

    console.log("this is triggered search", searchField);
  }, [diagnosticID, searchField, tableData]);
  useEffect(() => {
    if (
      diagnosticID !== "" &&
      diagName &&
      testName &&
      diagName !== "covid-general" &&
      diagName !== "covid-travellers"
    ) {
      console.log("DISaaaa", diagName, testName);
      getTestList();
    } else if (
      diagnosticID !== "" &&
      diagName &&
      testName === undefined &&
      diagName !== "covid-general" &&
      diagName !== "covid-travellers"
    ) {
      getTestList();
    } else if (
      diagnosticID !== "" &&
      diagName &&
      diagName === "covid-general"
    ) {
      // || diagName === "covid-travellers"
      console.log("hello covidddddd test");
      getTestListCovid();
    } else if (diagnosticID !== "" && diagName === undefined) {
      getTestList();
    }
  }, [params]);
  const getTestListCovid = () => {
    axios
      .get(`diagnostic-tests/${diagName}`, {
        params: params,
      })
      .then((response) => {
        let testList = [...response.data.data.data];
        console.log("testlist", testList);
        if (diagName && diagName.includes("general")) {
          let idsToRemoveGeneral = [1058];

          console.log("test list", testList);
          let general = testList.filter(
            (item) => !idsToRemoveGeneral.includes(item.id)
          );
          setTestList(general);
        } else if (diagName && diagName.includes("traveller")) {
          let idsToRemoveTraveller = [1055, 1056, 1057];

          console.log("test list", testList);
          let traveller = testList.filter(
            (item) => !idsToRemoveTraveller.includes(item.id)
          );
          setTestList(traveller);
        } else if (diagName && testName) {
          setTestList(response.data.data.data);
          if (addFirstIndex === true) {
            addToCart(response.data.data.data[0], 0);
          }
        } else {
          setTestList(response.data.data.data);
        }
        setCurrentPage(response.data.data.current_page);
        setTotalPage(response.data.data.last_page);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const getTestList = () => {
    axios
      .get("diagnostic-tests", {
        params: params,
      })
      .then((response) => {
        let testList = [...response.data.data.data];
        if (diagName && diagName.includes("general")) {
          let idsToRemoveGeneral = [1058];
          let general = testList.filter(
            (item) => !idsToRemoveGeneral.includes(item.id)
          );
          setTestList(general);
        } else if (diagName && diagName.includes("traveller")) {
          let idsToRemoveTraveller = [1055, 1056, 1057];
          let traveller = testList.filter(
            (item) => !idsToRemoveTraveller.includes(item.id)
          );
          setTestList(traveller);
        } else if (diagName && testName) {
          setTestList(response.data.data.data);
          if (addFirstIndex === true) {
            addToCart(response.data.data.data[0], 0);
          }
        } else {
          setTestList(response.data.data.data);
        }
        setCurrentPage(response.data.data.current_page);
        setTotalPage(response.data.data.last_page);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  // const getusers = () => {
  //   axios
  //     .get("tests?diagnostic_id=1")
  //     .then((response) => {
  //       console.log(response.data.data);
  //       setUsers(response.data.data);
  //       // setTestList(response.data.data);
  //     })
  //     .catch((error) => {
  //       console.log(error.response);
  //     });
  // };
  useEffect(() => {
    getLocalTest(diagName, testName);
    console.log("testing general", diagName);
    if (
      diagName &&
      diagName !== "covid-general" &&
      diagName !== "covid-travellers"
    ) {
      getDiagnosticList();
    } else if (diagName === undefined) {
      getDiagnosticList();
    }

    GetPoliceStations();
    // getusers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [covid, diagName, testName]);
  useEffect(() => {
    const testsTotalPrice = tableData.map((o) => parseInt(o.price));

    const testsTotalPercentageDiscount = tableData
      .filter((o) => o.discount_type === "%")
      .map((o) => {
        return parseInt((o.price / 100) * o.discount);
      });
    // const testsTotalPercentageDiscount = tableData
    //   .filter((o) => o.discount_type === "%")
    //   .map((o) => {
    //     return parseInt((o.price / 100) * o.discount);
    //   });
    const testsTotalFlatDiscount = tableData
      .filter((o) => o.discount_type === "Flat")
      .map((o) => parseInt(o.discount));
    // const testsTotalFlatDiscount = tableData.map((o) => {
    //   if () {
    //     return parseInt(o.price - o.discount);
    //   }
    // });
    console.log("total discoutn====>", testsTotalPercentageDiscount);
    console.log("total disaacout flat====>", testsTotalFlatDiscount);
    let totalPrice = testsTotalPrice.reduce(function (a, b) {
      return a + b;
    }, 0);
    setTotalRegularPrice(totalPrice);
    console.log("total", totalPrice, "aaaaaa");
    let totalDicountP = testsTotalPercentageDiscount.reduce(function (a, b) {
      return a + b;
    }, 0);
    let totalDicountF = testsTotalFlatDiscount.reduce(function (a, b) {
      return a + b;
    }, 0);

    let totalD = totalDicountP + totalDicountF;
    setTotalDiscountPrice(totalD);
    let actualPrice = totalPrice - totalD;
    console.log("total");
    setTotalPrice(actualPrice);
    console.log("dddddd", totalD, "aaaaaa", actualPrice);
    console.log("dddddd tttt", totalD, "aaaaaa", actualPrice);
    // let savedArray = JSON.parse(localStorage.getItem("names"));
    // if (savedArray.length > 0) {
    //   setTableData(savedArray);
    // }
    // localStorage.setItem("names", JSON.stringify(tableData));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    saveLocalTest();
  }, [tableData]);
  const saveLocalTest = () => {
    localStorage.setItem("testCart", JSON.stringify(tableData));
  };
  const getLocalTest = (lab, test) => {
    if (localStorage.getItem("testCart") === null) {
      localStorage.setItem("testCart", JSON.stringify([]));
    } else {
      let localTest = JSON.parse(localStorage.getItem("testCart"));

      setTableData(localTest);

      if (localTest.length > 0) {
        setSearchField("a");
        setDiagnosticValue(localTest[0].service_provider.name);
        setDiagnosticID(localTest[0].service_provider.id);
        if (lab && test === undefined) {
          setParams({
            ...params,
            service_provider_id: localTest[0].service_provider.id,
            // name: localTest[0].name,
          });
        } else if (lab && test) {
          setParams({
            ...params,
            service_provider_id: localTest[0].service_provider.id,
            name: test,
          });
        }
      }
    }
  };

  const validationSchema = Yup.object({
    // diagnostic_id: Yup.number().required("This field is required"),
    police_station_id: Yup.number().required("This field is required"),
  });
  const initialValues = {
    area: "",
    police_station_id: "",
  };
  const handleDelete = (index) => {
    let copyArray = [...tableData];

    copyArray.splice(index, 1);
    console.log(copyArray);
    setTableData(copyArray);
  };
  const addToCart = (test, index) => {
    console.log("gggggg", test, index);
    if (tableData.some((el) => el.id === test.id)) {
      console.log("gggggg", index);
      let copyArrayCart = [...tableData];
      const filteredArray = copyArrayCart.filter((list) => {
        return list.id !== test.id;
      });
      console.log("aaaaaaaa", filteredArray);
      setTableData(filteredArray);
    } else if (!tableData.some((el) => el.id === test.id)) {
      setTableData([...tableData, test]);
    }
  };

  console.log("table data", tableData);

  const onSubmit = (values, { resetForm }) => {
    console.log("vvvvvvvvvvvvvvv", values, tableData.length, tableData);
    if (tableData.length > 0) {
      const testsArray = tableData.map((o) => {
        return {
          diagnostic_test_id: o.id,
        };
      });
      console.log("test aaarrrray", testsArray);

      let objToSend = {
        service_provider_id: diagnosticID,
        tests: testsArray,
        home_delivery: homeSample,
        // address: values.area,
        // police_station_id: values.police_station_id,
      };

      // console.log(service_provider_id, testsArray);
      //   PatientAPI.post("test-invoices", objToSend)
      //     .then((response) => {
      //       console.log(response);
      //       if (response.data.message) {
      //         setSuccessMessage(response.data.message);
      //         setOpen(true);
      //         setErrorOpen(false);
      //       }
      //     })
      //     .catch((error) => {
      //       console.log(error.response);
      //       if (error.response.data.message) {
      //         setErrorMessage(error.response.data.message);
      //         setErrorOpen(true);
      //         setOpen(false);
      //       }
      //       // if (error.response.data.data !== undefined) {
      //       //   setErrorOpen(true);
      //       //   setOpen(false);
      //       //   setSameMedicineErrors([
      //       //     ...sameMedicineErrors,
      //       //     error.response.data.data,
      //       //   ]);
      //       // }

      //       // if (error.response.data.errors !== undefined) {
      //       //   setBackendErrorMessage(error.response.data.errors);
      //       // }
      //     });
    }
  };
  useEffect(() => {
    if (availableSlot.includes(upFile) === false) {
      setAvailableSlot([...availableSlot, upFile]);
    }
  }, [upFile]);
  // useEffect(() => {
  //   saveLocalImage();
  // }, [availableSlot]);
  // const saveLocalImage = () => {
  //   localStorage.setItem("testImages", JSON.stringify(availableSlot));
  // };
  // console.log("uppp data", availableSlot);

  const uploadMultipleFiles = async (e) => {
    setAvailableSlot([]);
    const fileList = Array.from(e.target.files);
    console.log("filelist", fileList);

    if (checkMimeType(e)) {
      setFiles([...files, ...fileList]);
      const filePathsPromises = [];
      // console.log("files", files);
      for (let i = 0; i < fileList.length; i++) {
        let file = fileList[i];
        let bseFile = getBase64(file);

        const a = await bseFile;
        console.log("aaaaaaaa", a);

        let obj = {
          originalName: file.name,
          mimetype: file.type,
          preview: a,
        };
        filePathsPromises.push(obj);
      }
      // fileList.forEach(async (file) => {

      // });
      console.log("base 64, arrra", filePathsPromises);
      setPreviews([...filePathsPromises]);
      // const filePaths = await Promise.all(filePathsPromises);
      // const mappedFilesa = filePaths.map((base64File) => {
      //   console.log("base 64,", base64File);

      //   return {
      //     preview: base64File,
      //   };
      // });
      // setPreviews(mappedFilesa);
      // console.log("files mapped", mappedFilesa);
      // localStorage.setItem("blob", JSON.stringify(mappedFilesa));
      // return mappedFilesa;

      // for (let i = 0; i < fileList.length; i++) {
      //   getBase64(fileList[i]).then((base64) => {
      //     setUpFile(base64);
      //     // someArr.push(base64);
      //   });
      // }

      // const mappedFiles = fileList.map((file) => ({
      //   ...file,
      //   originalName: file.name,
      //   mimetype: file.type,
      //   preview: URL.createObjectURL(file),
      // }));
      // console.log("files mapped ppppp", mappedFiles);

      // if return true allow to setState
    }
  };
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  console.log("upfile", some);

  // const maxSelectFile = (event) => {
  //   let files = event.target.files; // create file object
  //   if (files.length > 5) {
  //     const msg = "Only 5 images can be uploaded at a time";
  //     event.target.value = null; // discard selected file
  //     alert(msg);
  //     return false;
  //   }
  //   return true;
  // };

  const checkMimeType = (event) => {
    //getting file object
    let files = event.target.files;
    //define message container
    let err = "";
    // list allow mime type
    //  "application/pdf"
    const types = ["image/png", "image/jpeg", "image/gif", "application/pdf"];
    // loop access array
    for (var x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every((type) => files[x].type !== type)) {
        // create error message and assign to container
        err += files[x].type + " is not a supported format\n";
      }
    }

    if (err !== "") {
      // if message not same old that mean has error
      event.target.value = null; // discard selected file
      alert(err);
      return false;
    }
    return true;
  };

  const upload = async () => {
    // localStorage.setItem("fileUpload", JSON.stringify("upload"));
    //
    // const fileArraysTOSend = [];
    // const pArr = [...previews];
    // for (let i = 0; i < pArr.length; i++) {
    //   const file = await getFile(pArr[i]);
    //   fileArraysTOSend.push(file);
    // }
    const upTestObj = {
      diagnosticID: diagnosticID,
      diagnosticValue: diagnosticValue,
      images: previews,
    };
    localStorage.setItem("upTestObj", JSON.stringify(upTestObj));
    history.push("/patient/login");
    // console.log("phototaaa push", fileArraysTOSend);
  };
  const getFile = async (paramObj) => {
    const { preview, originalName, mimetype } = paramObj;
    const blob = await fetch(preview).then((r) => r.blob());
    const newfileObj = new File([blob], `${originalName}`, { type: mimetype });
    return newfileObj;
  };

  const getUrl = (name) => {
    switch (name) {
      case "Care & Cure Clinical Lab (Mohakhali)":
        return "cccl";
      case "CRL Diagnostics (Green Road)":
        return "CRL-Diagnostics";
      case "DNA Solution (Panthopath)":
        return "DNA-Solution";
      case "Oncos Molbiol Limited (Uttara)":
        return "oncos-molbiol";
      case "Praava Health (Banani)":
        return "praava-health";
      case "Stemz Healthcare (Banglamotor)":
        return "Stemz-Healthcare";
      case "The Pathlabs":
        return "the-pathlabs";
      case "Thyrocare (Badda)":
        return "Thyrocare";
      case "Universal Medical College & Hospital":
        return "universal-medical";
      default:
        return "Thyrocare";
    }
  };
  return (
    <div>
      <Navbar2 />
      <Drawer
        className={classes.drawerCart}
        variant="persistent"
        anchor="right"
        open={openCart}
        classes={{
          paper: classes.drawerPaperCart,
        }}
      >
        <div className="shopptingCartButtona">
          <div className="shoppingCartButton">
            {/* <button>hello</button> */}
          </div>
          <div className="header-cart">
            <div className="row py-2">
              <div className="col-12">
                <div className="d-flex cart-flex">
                  <div className="px-2">
                    {" "}
                    <Icon fontSize="large">local_mall</Icon>{" "}
                  </div>
                  <div>
                    {" "}
                    <h3 className="">{tableData.length} Tests</h3>
                  </div>
                  <div className="px-4">
                    {" "}
                    <Button
                      onClick={() => handleDrawerOpenCart()}
                      variant="outlined"
                      color="primary"
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </div>
              {/* <div className="col-5"></div> */}
            </div>
          </div>
          <div className="cart-items-list">
            <table
              style={{ width: isTabMobile ? "100%" : "150px" }}
              className="table table-sm table-borderless"
            >
              <thead className=""></thead>
              <tbody>
                {tableData.map((row, index) => (
                  <tr key={index}>
                    <td>{row.name}</td>

                    <td>
                      {row !== undefined && (
                        <span>
                          {row.discount_type === "%" ? (
                            <span>
                              {Math.ceil(
                                row.price - (row.price / 100) * row.discount
                              )}
                            </span>
                          ) : (
                            <span>{Math.ceil(row.price - row.discount)}</span>
                          )}
                        </span>
                      )}
                    </td>
                    <td>
                      <IconButton
                        classes={{
                          root: classes.icnBtnRoot, // className name, e.g. `classes-nesting-root-x`
                        }}
                        onClick={() => handleDelete(index)}
                        color="secondary"
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </td>
                  </tr>
                ))}
                <tr>
                  <th scope="col">
                    <button
                      onClick={() => setTableData([])}
                      className="btn btn-sm btn-danger"
                    >
                      Clear Cart
                    </button>{" "}
                  </th>

                  <th scope="col">{Math.ceil(totalPrice)}</th>
                </tr>
                <tr>
                  <th scope="col">
                    <button
                      onClick={() => handleOpenDetails()}
                      className="btn btn-sm btn-primary"
                    >
                      View Details
                    </button>{" "}
                  </th>

                  <th scope="col"></th>
                  <th scope="col">
                    <Link
                      type="button"
                      to="/patient/register"
                      className="btn btn-sm btn-dark"
                    >
                      Checkout
                    </Link>{" "}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Drawer>

      <main
        className={clsx(classes.content, {
          [classes.contentShift2]: openCart,
        })}
      >
        <div onClick={() => handleDrawerOpenCart()} className="stickyHeader">
          <div className="text-center">
            <LocalMallOutlinedIcon fontSize="small" />
          </div>
          <h5 style={{ textAlign: "center", fontSize: "14px" }} className="">
            {tableData.length} Tests
          </h5>
          <h5 style={{ textAlign: "center", fontSize: "14px" }} className="">
            {totalPrice}
          </h5>
        </div>
        <div className="diagnostic-labs-home">
          <div className="container">
            <div style={{ width: "100%" }} className="test">
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <div>
                {activeStep === steps.length ? (
                  <div>
                    <Typography className={classes.instructions}>
                      Click on the 'Cart' Icon and click checkout button to book
                      your test
                    </Typography>
                    <Button
                      style={{ marginBottom: "1em" }}
                      color="secondary"
                      variant="contained"
                      onClick={handleReset}
                    >
                      Reset Step{" "}
                    </Button>
                  </div>
                ) : (
                  <div>
                    {!covid ? (
                      <Typography className={classes.instructions}>
                        {getStepContent(activeStep)}
                      </Typography>
                    ) : (
                      <Typography className={classes.instructions}>
                        {getStepContentCovid(activeStep)}
                      </Typography>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="row ">
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <div className="add-institutionss">
                      {/*  */}

                      <div className="form-group row ">
                        <label className="col-md-4 col-12">
                          Select Diagonstic Lab
                        </label>
                        <div className="col-md-8 col-12">
                          <Autocomplete
                            id="disabledHelperText"
                            name="diagnostic_id"
                            options={diagnosticList.filter(
                              (dig) => dig.id !== 1
                            )}
                            getOptionLabel={(option) =>
                              option.name || diagnosticValue
                            }
                            value={diagnosticValue}
                            onChange={(e, value) => {
                              if (value == null) {
                                console.log("Hello");
                                setDiagnosticValue("");
                                setDiagnosticID("");
                              } else {
                                setDiagnosticValue(value.name);
                                const url = getUrl(value.name);
                                history.push("/available-tests/" + url);
                                setDiagnosticID(value.id);
                                setParams({
                                  ...params,
                                  service_provider_id: value.id,
                                  test: "",
                                });
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="diagnostic_id"
                                variant="outlined"
                                fullWidth
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {diagnosticID !== "" && (
              <div className="row">
                <div className="col-md-6">
                  <input
                    type="text"
                    onChange={(e) =>
                      handleColumnWiseSearch(e.target.value, "name")
                    }
                    className="form-control"
                    placeholder="Search Test"
                  />
                </div>
                <div className="col-md-3 text-center mt-1">
                  <h4>Or</h4>
                </div>
                <div className="col-md-3 text-center mt-1">
                  <button
                    style={{ width: "65%", padding: "7px" }}
                    onClick={() => setOpenFile(true)}
                    className="btn btn-sm btn-primary "
                  >
                    Upload Prescription
                  </button>
                </div>
              </div>
            )}
            {diagnosticID !== "" && (
              <div className="row">
                <div className="col-md-12">
                  {!isMobile ? (
                    <table className="custom-responsive-table">
                      <thead>
                        <tr>
                          <th scope="col">Test Name</th>
                          <th scope="col">Test Price</th>
                          <th scope="col">Discounted Price</th>
                          <th scope="col">Home Sample Collection</th>
                          <th scope="col">Add To Cart</th>
                        </tr>
                      </thead>
                      <tbody>
                        {testList.map((test, i) => (
                          <tr key={i}>
                            <td data-column="Test Name">{test.name}</td>
                            <td data-column="Test Price">
                              {Math.ceil(test.price)}
                            </td>
                            <td data-column="Discounted Price">
                              {test !== undefined && (
                                <span>
                                  {test.discount_type === "%" ? (
                                    <span>
                                      {Math.ceil(
                                        test.price -
                                          (test.price / 100) * test.discount
                                      )}
                                    </span>
                                  ) : (
                                    <span>
                                      {Math.ceil(test.price - test.discount)}
                                    </span>
                                  )}
                                </span>
                              )}
                            </td>
                            <td data-column="Home Delivery">
                              {test.home_delivery ? "Yes" : "No"}
                            </td>

                            <td data-column="Add To Cart">
                              <IconButton
                                onClick={() => addToCart(test, i)}
                                color={
                                  tableData.some((el) => el.id === test.id)
                                    ? "secondary"
                                    : "primary"
                                }
                                aria-label="add to shopping cart"
                              >
                                {tableData.some((el) => el.id === test.id) ? (
                                  <DeleteOutlineIcon />
                                ) : (
                                  <AddShoppingCartIcon />
                                )}
                              </IconButton>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <table className="table table-borderless table-sm test-list">
                      <thead>
                        <tr>
                          <th scope="col">Test Name</th>
                          <th scope="col">Test Price</th>
                          <th scope="col">Discounted Price</th>

                          <th scope="col">Home Sample Collection</th>
                          <th scope="col">Add To Cart</th>
                        </tr>
                      </thead>
                      <tbody>
                        {testList.map((test, i) => (
                          <tr key={i}>
                            <td data-column="Test Name">{test.name}</td>
                            <td data-column="Test Price">
                              {Math.ceil(test.price)}
                            </td>
                            <td data-column="Discounted Price">
                              {test !== undefined && (
                                <span>
                                  {test.discount_type === "%" ? (
                                    <span>
                                      {Math.ceil(
                                        test.price -
                                          (test.price / 100) * test.discount
                                      )}
                                    </span>
                                  ) : (
                                    <span>
                                      {Math.ceil(test.price - test.discount)}
                                    </span>
                                  )}
                                </span>
                              )}
                            </td>

                            <td data-column="Home Sample">
                              {test.home_delivery ? "Yes" : "No"}
                            </td>
                            <td data-column="Add To Cart">
                              <IconButton
                                classes={{
                                  root: classes.icnBtnRoot, // className name, e.g. `classes-nesting-root-x`
                                }}
                                onClick={() => addToCart(test, i)}
                                color={
                                  tableData.some((el) => el.id === test.id)
                                    ? "secondary"
                                    : "primary"
                                }
                                aria-label="add to shopping cart"
                              >
                                {tableData.some((el) => el.id === test.id) ? (
                                  <DeleteOutlineIcon />
                                ) : (
                                  <AddShoppingCartIcon />
                                )}
                              </IconButton>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}

                  <div className="row">
                    <Pagination
                      color="primary"
                      count={totalPage}
                      page={currentPage}
                      // page={currentPage === 0 ? currentPage : currentPage - 1}
                      onChange={handleChangePage}
                    />
                    {/* <CommonPagination totalPage={totalPage} currentPage={setPage} /> */}
                  </div>
                </div>
              </div>
            )}

            <Dialog
              fullWidth
              maxWidth={`${isTabMobile ? "sm" : "md"}`}
              open={openDetails}
              onClose={handleCloseDetails}
            >
              <DialogTitle id="max-width-dialog-title">Report</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <table className="custom-responsive-table">
                    <thead className="">
                      <tr>
                        <th scope="col"> Name</th>
                        <th scope="col"> Home Sample</th>

                        <th scope="col">Regular Price</th>
                        <th scope="col">Discounted Price</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((row, index) => (
                        <tr key={index}>
                          <td data-column="Name">{row.name}</td>
                          <td data-column="Home Sample">
                            {" "}
                            {row.home_delivery ? "Yes" : "No"}
                          </td>
                          <td data-column="Regular Price">
                            {row !== undefined && <span>{row.price} </span>}
                          </td>
                          <td data-column="Discounted Price">
                            {row !== undefined && (
                              <span>
                                {row.discount_type === "%" ? (
                                  <span>
                                    {Math.ceil(
                                      row.price -
                                        (row.price / 100) * row.discount
                                    )}
                                  </span>
                                ) : (
                                  <span>
                                    {Math.ceil(row.price - row.discount)}
                                  </span>
                                )}
                              </span>
                            )}
                          </td>
                          <td data-column="Action">
                            <IconButton
                              onClick={() => handleDelete(index)}
                              color="secondary"
                              aria-label="delete"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </td>
                        </tr>
                      ))}
                      <tr>
                        {!isTabMobile && (
                          <React.Fragment>
                            <th scope="col"> </th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                          </React.Fragment>
                        )}

                        <th scope="col">Total Regular Price</th>
                        <th scope="col">{Math.ceil(totalRegularPrice)}</th>
                      </tr>
                      <tr>
                        {!isTabMobile && (
                          <React.Fragment>
                            <th scope="col"> </th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                          </React.Fragment>
                        )}
                        <th scope="col">Total Discount</th>
                        <th scope="col">{Math.ceil(totalDiscountPrice)}</th>
                      </tr>
                      <tr>
                        {!isTabMobile && (
                          <React.Fragment>
                            <th scope="col"> </th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                          </React.Fragment>
                        )}
                        <th scope="col">Total Payable</th>
                        <th scope="col">{Math.ceil(totalPrice)}</th>
                      </tr>
                    </tbody>
                  </table>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleCloseDetails()} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              fullWidth
              maxWidth={`${isTabMobile ? "sm" : "md"}`}
              open={openCheck}
              onClose={handleCloseCheck}
            >
              <DialogTitle id="max-width-dialog-title">Sumit</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({ setFieldValue, isValid, dirty }) => (
                      <Form>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1">
                            Police Station Area
                          </label>
                          <div className="col-sm-7">
                            <Autocomplete
                              freeSolo
                              id="disabledHelperText"
                              name="police_station_id"
                              options={policeStations}
                              onInputCapture={(event) => {
                                event.persist(); // As we are accessing the event in asynchronous way.
                                handleOnInputCaptureMed(event);
                              }}
                              // getOptionLabel={(option) => option.name}
                              getOptionLabel={(option) => {
                                // return option.name || "";
                                return (
                                  option.name +
                                    " " +
                                    "(" +
                                    option.district +
                                    ")" || ""
                                );

                                // let b = option.brand_name;
                                // let s = option.strength;
                                // let bd = b + " ( " + s + " " + " ) ";

                                // return b || "";
                              }}
                              onChange={(e, value) => {
                                if (value == null) {
                                  setFieldValue("police_station_id", "");
                                } else {
                                  console.log(value);
                                  console.log(value.id);
                                  setFieldValue("police_station_id", value.id);
                                }
                              }}
                              renderInput={(params) => (
                                <Field
                                  component={TextFieldSecond}
                                  {...params}
                                  onChange={(e, value) => {
                                    console.log(e.target.value);
                                    setFieldValue("police_station_id", 0);
                                  }}
                                  name="police_station_id"
                                  variant="outlined"
                                  fullWidth
                                />
                              )}
                            />
                            <ErrorMessage
                              name="police_station_id"
                              component="div"
                              className="error-message"
                            />
                          </div>
                        </div>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1">
                            Your Full Address
                          </label>
                          <div className="col-sm-7">
                            <Field
                              name="area"
                              type="text"
                              className="form-control"
                              placeholder="Your Address"
                            />
                            <ErrorMessage
                              name="area"
                              component="div"
                              className="error-message"
                            />
                          </div>
                        </div>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1"></label>
                          <div className="col-sm-7">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={homeSample}
                                  onChange={handleChangeSample}
                                />
                              }
                              label="Home Sample Collection"
                            />
                          </div>
                        </div>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1"></label>
                          <div className="col-sm-7">
                            {homeSample ? (
                              <span>
                                Total Price including Home Sample :{" "}
                                {totalPrice + 1000}
                              </span>
                            ) : (
                              <span>
                                Total Price without Home Sample : {totalPrice}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12 my-4">
                            <Collapse in={open}>
                              <Alert
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setOpen(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                {successMessage}
                              </Alert>
                            </Collapse>
                            <Collapse in={erroropen}>
                              <Alert
                                severity="error"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setErrorOpen(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                {errorMessage}
                              </Alert>
                            </Collapse>
                          </div>
                        </div>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1"></label>
                          <div className="col-sm-7 text-right">
                            <button
                              disabled={tableData.length === 0}
                              type="submit"
                              className="btn btn-lg btn-success"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleCloseCheck()} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              fullWidth
              maxWidth={`${isTabMobile ? "sm" : "md"}`}
              open={openFile}
              onClose={() => setOpenFile(false)}
            >
              <DialogTitle id="max-width-dialog-title">
                Upload Precription
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group multi-preview">
                        {(previews || []).map((url, key) => (
                          <img key={key} src={url.preview} alt="..." />
                        ))}
                      </div>

                      <div className="form-group">
                        <input
                          type="file"
                          className="form-control"
                          onChange={uploadMultipleFiles}
                          multiple
                        />
                        <p>You can upload your prescriptions here </p>
                      </div>
                      <button
                        onClick={() => upload()}
                        className="btn btn-sm btn-primary"
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenFile(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
