import React, { useState, useEffect } from "react";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory, useParams, Link } from "react-router-dom";
import { TextField } from "formik-material-ui";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import _ from "lodash";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "../css/prescription.css";

const useStyles = makeStyles({
  root: {
    padding: "0px",
  },
  label: {
    textTransform: "capitalize",
  },
});

const observations = [
  { name: "Blood Pressure", id: "1" },
  { name: "Diabetics", id: "2" },
];

const timings = [
  { name: "1+1+1", id: "1" },
  { name: "1+0+1", id: "2" },
  { name: "0+0+1", id: "3" },
  { name: "1+0+0", id: "4" },
  { name: "0+1+0", id: "5" },
];

const schema = () => {
  const schema = Yup.object().shape({
    prescribedMedicines: Yup.array().of(
      Yup.object().shape({
        validity: Yup.number()
          .typeError("accept numbers only")
          .required("Duration is a required field"),
        rule: Yup.string().required("This is a required field"),
        name: Yup.string().required("Medicine name is a required "),
        timing: Yup.string().required("Time is a required field"),
      })
    ),
    observations: Yup.array().of(
      Yup.object().shape({
        // details: Yup.string().required("This is a required field"),
      })
    ),
    prescribedTests: Yup.array().of(
      Yup.object().shape({
        // test_id: Yup.string().required("This is a required field"),
      })
    ),
  });

  return schema;
};

export default function AddPrescription() {
  const classes = useStyles();
  let history = useHistory();
  let { presId } = useParams();

  const [doctorDetails, setDocDetails] = useState();
  const [patientDetails, setPatientDetails] = useState();
  const [medicineList, setMedicineList] = useState([]);
  const [testList, setTestList] = useState([]);
  const paramID = parseInt(presId);
  const [obsvValue, setObsValue] = React.useState();
  const [testValue, setTestValue] = React.useState();
  const [medValue, setMedValue] = React.useState();
  const [observationData, setObservationData] = useState([]);
  const [diagnosisData, setDiagnosisData] = useState([]);
  const [testData, setTestData] = useState([]);
  const [medData, setMedData] = useState([]);
  const [obsInputValue, setObsInputValue] = React.useState("");
  const [comment, setComment] = React.useState("");
  const [testInputValue, setTestInputValue] = React.useState("");
  const [testInputValueId, setTestInputValueId] = React.useState("");
  const [medInputValue, setMedInputValue] = React.useState("");
  const [medInputValueId, setMedInputValueId] = React.useState("");
  const [path, setPath] = useState("");
  const [reportMessage, setReportMessage] = useState("");
  const [reports, setReports] = useState([]);

  const [timingInputValue, setTimingInputValue] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [loadingReports, setLoadingReports] = React.useState(false);
  const [openPhotoModal, setOpenPhotoModal] = React.useState(false);
  const [selectedImg, setSelectedImg] = React.useState({});
  const [showReports, setShowReports] = useState(false);
  const [reportShown, setReportShown] = useState(false);
  const [showEndButton, setShowEndButton] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [medicineSearchTerm, setMedicineSearchTerm] = useState("");
  const [testSearchTerm, setTestSearchTerm] = useState("");
  // const [params, setParams] = {};

  useEffect(() => {
    getPatientDetails();
    GetDoctorDetails();
    // getMedicineList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getMedicineList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicineSearchTerm]);
  const getMedicineList = () => {
    AdminAPI.get(`medicines?brand_name=${medicineSearchTerm}`)
      .then((response) => {
        setMedicineList(response.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleOnInputCaptureMed = _.debounce((event) => {
    setMedicineSearchTerm(event.target.value);
  }, 500);
  useEffect(() => {
    getTestList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testSearchTerm]);
  const getTestList = () => {
    AdminAPI.get(`tests?name=${testSearchTerm}`)
      .then((response) => {
        setTestList(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleOnInputCaptureTest = _.debounce((event) => {
    setTestSearchTerm(event.target.value);
  }, 500);

  const getPatientDetails = () => {
    // let url = `http://localhost:3001/purchase_order/${paramID}`;
    AdminAPI.get(`appointment/details/${paramID}`)
      .then((response) => {
        const patient = response.data.data.patient;
        setPatientDetails(patient);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const GetDoctorDetails = () => {
    AdminAPI.get(`auth/me`)
      .then((response) => {
        setDocDetails(response.data);
        console.log("respones", response.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const handleDelete = (index) => {
    let copyArray = [...observationData];

    copyArray.splice(index, 1);

    setObservationData(copyArray);
  };
  const handleDeleteDiagnosis = (index) => {
    let copyArray = [...diagnosisData];

    copyArray.splice(index, 1);

    setDiagnosisData(copyArray);
  };
  const handleDeleteTest = (index) => {
    let copyArray = [...testData];

    copyArray.splice(index, 1);

    setTestData(copyArray);
  };
  const handleDeleteMed = (index) => {
    let copyArray = [...medData];

    copyArray.splice(index, 1);

    setMedData(copyArray);
  };
  const getAgeInYears = (date) => {
    let a = moment();
    let b = moment(date);

    let years = a.diff(b, "year");
    b.add(years, "years");

    let months = a.diff(b, "months");
    b.add(months, "months");

    let days = a.diff(b, "days");
    let total = years + "y " + months + "m " + days + " days";

    return total;
  };

  const validationSchema = Yup.object({
    observation_details: Yup.string().required("This field is required"),
    test_id: Yup.number().required("This field is required"),
  });
  const initialValues = {
    observation_details: "",
    test_id: "",
    test_name: "",
    medicine_id: "",
    name: "",
    rule: "",
    validity: "",
    timing: "",
    diagnosis: "",
    comment: "",
  };

  const onSubmit = (values, { resetForm }) => {
    console.log("value", values);
    let testArray = testData.map((o) => {
      const obj = {
        test_id: o.id,
      };
      return obj;
    });
    let ObsArray = observationData.map((o) => {
      const obj = {
        details: o,
      };
      return obj;
    });
    let DiagArray = diagnosisData.map((o) => {
      const obj = {
        details: o,
      };
      return obj;
    });

    AdminAPI.post(`prescriptions`, {
      patient_uploaded_report_id: presId,
      prescribedMedicines: medData,
      prescribedTests: testArray,
      observations: ObsArray,
      diagnosis: DiagArray,
      comment: comment,
    })
      .then((response) => {
        console.log(response);
        setOpenDialog(true);
        setShowEndButton(true);
        // history.push("/doctor");
      })
      .catch((error) => {
        console.log(error.response.data.message);
        alert(error.response.data.message);
      });
  };

  const getReports = async () => {
    setShowReports(!showReports);
    if (!reportShown) {
      setLoadingReports(true);

      await AdminAPI.get(`${paramID}/reports`, {})
        .then((response) => {
          console.log(response);
          setPath(response.data.path);
          setReports(response.data.reports.patient_uploaded_reports);
          if (response.data.reports.patient_uploaded_reports.length === 0) {
            setReportMessage("No reports found");
          }
          // if (response.data.reports.patient_uploaded_reports.length === 0) {

          // }

          // history.push("/doctor");
        })
        .catch((error) => {
          console.log(error.response);
          setReportMessage("No reports found");
        });

      setLoadingReports(false);
      setReportShown(true);
    }
  };
  const openModal = (id) => {
    console.log(id);
    let imgArr = [...reports];
    let filtered = imgArr.filter((img) => img.id === id);
    console.log("filerte", filtered);
    setSelectedImg(filtered[0].report);
    setOpenPhotoModal(true);
  };
  // const goToPatientHistory = () => {
  //   const url = `/doctor/patient-history/${patientDetails.id}`;
  //   window.open(url, "_blank");
  // };

  const endPrescription = () => {
    AdminAPI.post(`end-call`, {
      patient_appointment_id: paramID,
    })
      .then((response) => {
        console.log(response);
        history.push("/doctor/appointments");
        // history.push("/doctor");
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  return (
    <div>
      <div className="row mb-2">
        <div className="col-md-6">
          {/* <button onClick={getReports} className="btn btn-lg btn-primary">
            {showReports ? "Hide Reports" : "Show Reports"}
          </button>{" "}
          <button
            onClick={goToPatientHistory}
            className="btn btn-lg btn-primary"
          >
            View Previous History
          </button>
          <br /> */}
          {loadingReports && (
            <span className="mt-1">
              <CircularProgress />
            </span>
          )}
          {showReports && (
            <div>
              {reportMessage}
              <div className=" multi-preview">
                {(reports || []).map((url, key) => (
                  <img
                    onClick={() => openModal(url.id)}
                    key={key}
                    src={
                      `${process.env.REACT_APP_PUBLIC_URL}` +
                      path +
                      "/" +
                      url.report
                    }
                    alt="..."
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <Dialog
        fullWidth
        maxWidth={`${isMobile ? "sm" : "md"}`}
        open={openPhotoModal}
        onClose={() => setOpenPhotoModal(false)}
      >
        <DialogTitle id="max-width-dialog-title">Report</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <img
              width="100%"
              src={
                `${process.env.REACT_APP_PUBLIC_URL}` + path + "/" + selectedImg
              }
              alt="..."
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPhotoModal(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <div className="row">
        <div className="col-lg-5">
          <div className="whole-prescription-area">
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              // validationSchema={validationSchema}
            >
              {({
                setFieldValue,
                values,
                errors,
                isValid,
                dirty,
                resetForm,
                validateField,
              }) => (
                <Form>
                  <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12">
                      <div className="obeservation">
                        <div>
                          {/* <Field name={`friends[${index}].name`} /> */}
                          <div className="form-group row prescription  ">
                            <label className="col-md-3 ">
                              Observations / Chief Complaints
                            </label>
                            <div className="col-md-6">
                              <Autocomplete
                                freeSolo
                                id="disabledHelperText"
                                name={`observation_details`}
                                getOptionLabel={(option) => option.name || ""}
                                inputValue={obsInputValue}
                                onInputChange={(event, newInputValue) => {
                                  setObsInputValue(newInputValue);
                                }}
                                // value={`prescribedMedicines.${index}.location`}
                                options={observations}
                                onChange={(event, value) => {
                                  if (value == null) {
                                    console.log("null");
                                  } else {
                                    // setLocation(`prescribedMedicines.${index}.location`);
                                    setFieldValue(
                                      `observation_details`,
                                      value.name
                                    );
                                  }
                                }}
                                renderInput={(params) => (
                                  <Field
                                    component={TextField}
                                    {...params}
                                    onChange={(event) => {
                                      setFieldValue(
                                        `observation_details`,
                                        event.target.value
                                      );
                                    }}
                                    name={`observation_details`}
                                    variant="outlined"
                                    fullWidth
                                  />
                                )}
                              />
                            </div>
                            <label className="col-md-3 ">
                              <Button
                                onClick={() => {
                                  // validateField("observation_details");
                                  if (obsInputValue !== "") {
                                    if (
                                      observationData.includes(
                                        obsInputValue
                                      ) === false
                                    ) {
                                      setObservationData([
                                        ...observationData,
                                        obsInputValue,
                                      ]);

                                      setObsInputValue("");
                                      setFieldValue("observation_details", "");
                                    }
                                  }
                                }}
                                variant="contained"
                                color="primary"
                              >
                                <CheckIcon />
                              </Button>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="obeservation">
                        <div className="form-group row prescription  ">
                          <label className="col-md-3 ">Diagnosis</label>
                          <div className="col-md-6">
                            <Field
                              className="form-control"
                              name={`diagnosis`}
                            />
                          </div>
                          <label className="col-md-3 ">
                            {" "}
                            <Button
                              onClick={() => {
                                if (values.diagnosis !== "") {
                                  if (
                                    diagnosisData.includes(values.diagnosis) ===
                                    false
                                  ) {
                                    setDiagnosisData([
                                      ...diagnosisData,
                                      values.diagnosis,
                                    ]);

                                    setFieldValue("diagnosis", "");
                                  }
                                }
                              }}
                              variant="contained"
                              color="primary"
                            >
                              <CheckIcon />
                            </Button>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12">
                      <div className="test-suggestion">
                        <div>
                          <div>
                            {/* <Field name={`friends[${index}].name`} /> */}
                            <div className="form-group row prescription">
                              <label className="col-md-3">Suggest Test</label>
                              <div className="col-sm-6">
                                <Autocomplete
                                  id="disabledHelperText"
                                  getOptionLabel={(option) => option.name || ""}
                                  inputValue={testInputValue}
                                  onInputChange={(event, newInputValue) => {
                                    console.log(newInputValue);
                                    setTestInputValue(newInputValue);
                                  }}
                                  options={testList}
                                  onInputCapture={(event) => {
                                    event.persist(); // As we are accessing the event in asynchronous way.
                                    handleOnInputCaptureTest(event);
                                  }}
                                  onChange={(event, value) => {
                                    console.log("test value", value);

                                    if (value == null) {
                                      console.log("null");
                                    } else {
                                      setTestInputValue(value.name);
                                      setTestInputValueId(value.id);
                                      setTestSearchTerm(value.name);
                                      // setFieldValue(`test_id`, value.id);
                                      // setFieldValue(`test_name`, value.name);
                                    }
                                    console.log(values.test_id);
                                    console.log(values.test_name);
                                  }}
                                  renderInput={(params) => (
                                    <Field
                                      component={TextField}
                                      {...params}
                                      // onChange={(event) => {
                                      //   setFieldValue(
                                      //     `test_id`,
                                      //     event.target.value
                                      //   );
                                      //   setFieldValue(
                                      //     `test_name`,
                                      //     event.target.value
                                      //   );
                                      // }}
                                      name={`hekk`}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  name={`test_id`}
                                  component="div"
                                  className="error-message"
                                />
                              </div>
                              <label className="col-md-3">
                                <Button
                                  onClick={() => {
                                    console.log("id", testInputValueId);
                                    console.log("date", testData);
                                    if (testInputValueId !== "") {
                                      if (
                                        !testData.some(
                                          (el) => el.id === testInputValueId
                                        )
                                      ) {
                                        setTestData([
                                          ...testData,
                                          {
                                            name: testInputValue,
                                            id: testInputValueId,
                                          },
                                        ]);
                                        // setFieldValue(`test_id`, "");
                                        // setFieldValue(`test_name`, "");
                                        setTestInputValue("");
                                        // setTestInputValueId("");
                                      }
                                    }
                                  }}
                                  variant="contained"
                                  color="primary"
                                >
                                  <CheckIcon />
                                </Button>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="prescription-area">
                        <div>
                          <div className="form-group row prescription">
                            <label className="col-md-4">Medicine Name</label>
                            <div className="col-md-8">
                              <Autocomplete
                                freeSolo
                                id="disabledHelperText"
                                name={`medicine_id`}
                                getOptionLabel={(option) => {
                                  // return option.name || "";
                                  return option.brand_name || "";

                                  // let b = option.brand_name;
                                  // let s = option.strength;
                                  // let bd = b + " ( " + s + " " + " ) ";

                                  // return b || "";
                                }}
                                // value={`prescribedMedicines.${index}.location`}
                                options={medicineList}
                                inputValue={medInputValue}
                                onInputChange={(event, newInputValue) => {
                                  setMedInputValue(newInputValue);
                                }}
                                onInputCapture={(event) => {
                                  event.persist(); // As we are accessing the event in asynchronous way.
                                  handleOnInputCaptureMed(event);
                                }}
                                onChange={(event, value) => {
                                  if (value == null) {
                                    console.log("null");
                                  } else {
                                    // setLocation(`prescribedMedicines.${index}.location`);
                                    // setFieldValue(`medicine_id`, value.id);
                                    setMedInputValue(value.brand_name);
                                    setMedInputValueId(value.id);
                                    setMedicineSearchTerm(value.brand_name);
                                    // setFieldValue(`name`, value.brand_name);
                                  }
                                }}
                                renderInput={(params) => (
                                  <Field
                                    component={TextField}
                                    {...params}
                                    onChange={(event) => {
                                      // setLocation(event.target.value);
                                      setMedInputValue(event.target.value);
                                      setMedInputValueId(event.target.value);
                                      // setFieldValue(`name`, event.target.value);
                                      // setFieldValue(
                                      //   `medicine_id`,
                                      //   event.target.value
                                      // );
                                    }}
                                    name={`name`}
                                    variant="outlined"
                                    fullWidth
                                  />
                                )}
                              />
                              <ErrorMessage
                                name={`name`}
                                component="div"
                                className="error-message"
                              />
                            </div>
                          </div>

                          <br />

                          <div className="form-group row prescription">
                            <label className="col-md-4">Timing</label>
                            <div className="col-md-8">
                              <Autocomplete
                                freeSolo
                                id="disabledHelperText"
                                getOptionLabel={(option) => option.name || ""}
                                inputValue={timingInputValue}
                                onInputChange={(event, newInputValue) => {
                                  setTimingInputValue(newInputValue);
                                }}
                                options={timings}
                                onChange={(event, value) => {
                                  if (value == null) {
                                    console.log("null");
                                  } else {
                                    // setFieldValue(`rule`, value.name);
                                    setTimingInputValue(value.name);
                                    // console.log(`rule`, value.name);
                                  }
                                }}
                                renderInput={(params) => (
                                  <Field
                                    component={TextField}
                                    {...params}
                                    onChange={(event) => {
                                      // setFieldValue(`rule`, event.target.value);
                                    }}
                                    name={`test_id`}
                                    variant="outlined"
                                    fullWidth
                                  />
                                )}
                              />
                              <ErrorMessage
                                name={`rule`}
                                component="div"
                                className="error-message"
                              />
                            </div>
                          </div>
                          <div className="form-group row prescription ">
                            <label className="col-md-4">Duration</label>
                            <div className="col-md-8">
                              <Field
                                className="form-control"
                                name={`validity`}
                              />
                              <ErrorMessage
                                component="div"
                                className="error-message"
                                name={`validity`}
                              />
                            </div>
                          </div>
                          <div className="form-group row prescription ">
                            <label className="col-md-4">Time</label>
                            <div className="col-md-8">
                              <Field
                                className="form-control"
                                as="select"
                                name={`timing`}
                              >
                                <option value="" label="Select a Timing" />
                                <option value="Before">Before Meal</option>
                                <option value="After">After Meal</option>
                              </Field>
                              <ErrorMessage
                                component="div"
                                className="error-message"
                                name={`timing`}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-12 text-right">
                              <Button
                                onClick={() => {
                                  if (
                                    medInputValueId !== "" &&
                                    values.timing !== "" &&
                                    values.validity !== "" &&
                                    timingInputValue !== ""
                                  ) {
                                    if (
                                      !medData.some(
                                        (el) =>
                                          el.medicine_id === medInputValueId
                                      )
                                    ) {
                                      setMedData([
                                        ...medData,
                                        {
                                          medicine_id: medInputValueId,
                                          name: medInputValue,
                                          rule: timingInputValue,
                                          validity: values.validity,
                                          timing: values.timing,
                                        },
                                      ]);

                                      setFieldValue(`name`, "");
                                      setFieldValue(`medicine_id`, "");
                                      setFieldValue(`timing`, "");
                                      setFieldValue(`validity`, "");
                                      setFieldValue(`rule`, "");
                                      console.log(`rule`, values.rule);
                                      console.log(`validity`, values.validity);
                                      setMedInputValue("");
                                      // setMedInputValue("");
                                      setTimingInputValue("");
                                    }
                                  }
                                }}
                                variant="contained"
                                color="primary"
                              >
                                <CheckIcon />
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="prescription-area">
                        <div className="form-group row prescription ">
                          <label className="col-md-4">Comment</label>
                          <div className="col-md-8">
                            <Field
                              className="form-control"
                              name="comment"
                              component="textarea"
                              rows="2"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 text-right">
                            <Button
                              onClick={() => {
                                if (values.comment !== "") {
                                  setComment(values.comment);
                                }
                              }}
                              variant="contained"
                              color="primary"
                            >
                              <CheckIcon />
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <button
                            disabled={
                              medData.length === 0 ||
                              observationData.length === 0 ||
                              testData.length === 0
                            }
                            className="btn btn-lg btn-primary"
                            type="submit"
                          >
                            Submit Prescription
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="col-lg-7">
          <div className="row">
            <div className="col-sm-12 text-right">
              {showEndButton && (
                <button
                  onClick={() => endPrescription()}
                  type="button"
                  className="btn btn-sm btn-danger mb-2"
                >
                  {" "}
                  End Precription
                </button>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div>
                <div style={{ width: "100%", height: "75px" }}>
                  <div style={{ width: "80%", float: "left" }}>
                    {doctorDetails !== undefined && (
                      <React.Fragment>
                        {/* <div style={{ width: "100%" }}>
                          {" "}
                          Dr. {doctorDetails.name}
                        </div> */}
                        <div style={{ width: "100%" }}>
                          {" "}
                          {doctorDetails.degree}
                        </div>

                        <div style={{ width: "100%" }}>
                          {doctorDetails.institution}
                        </div>
                        <div style={{ width: "100%" }}>
                          {doctorDetails.designation},{" "}
                          {doctorDetails.department}
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                  <div style={{ width: "15%", float: "right" }}>
                    <div style={{ width: "100%", height: "60px" }}>
                      <img
                        alt="app-bar-logo"
                        src={`${process.env.PUBLIC_URL}/assets/images/newLogo.jpg`}
                        style={{
                          height: "55px",
                          width: "50px",
                          float: "right",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <hr />
                </div>
                {/* {patientDetails !== undefined && (
                  <React.Fragment>
                    <div className="d-flex justify-content-start ">
                      <div className="px-1">
                        <strong>ID:{patientDetails.unique_id}</strong>
                      </div>
                      <div className="px-1">
                        <strong>Name: {patientDetails.name}</strong>
                      </div>
                      <div className="px-1">
                        <strong>
                          Age: {getAgeInYears(patientDetails.date_of_birth)}
                        </strong>
                      </div>
                      <div className="px-1">
                        <strong>Gender: {patientDetails.gender}</strong>
                      </div>
                      <div className="px-1">
                        <strong>Date: {moment().format("DD/MM/YYYY")}</strong>
                      </div>
                    </div>
                  </React.Fragment>
                )} */}

                <div style={{ width: "100%" }}>
                  <hr />
                </div>
                <div className="row">
                  <div
                    style={{
                      borderRight: "1px solid black",
                    }}
                    className="col-md-4"
                  >
                    <div style={{ height: "auto" }}>
                      <h4> Observations / Chief Complaints </h4>

                      <table className="table table-borderless custom-table">
                        <tbody>
                          {observationData.map((row, index) => (
                            <tr key={index}>
                              <td>{row}</td>

                              <td>
                                <IconButton
                                  classes={{
                                    root: classes.root,
                                  }}
                                  aria-label="delete"
                                  onClick={() => handleDelete(index)}
                                  color="secondary"
                                >
                                  <ClearIcon />
                                </IconButton>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div style={{ height: "auto" }}>
                      <h4> Diagnosis </h4>
                      <table className="table table-borderless custom-table">
                        <tbody>
                          {diagnosisData.map((row, index) => (
                            <tr key={index}>
                              <td>{row}</td>

                              <td>
                                <IconButton
                                  classes={{
                                    root: classes.root,
                                  }}
                                  aria-label="delete"
                                  onClick={() => handleDeleteDiagnosis(index)}
                                  color="secondary"
                                >
                                  <ClearIcon />
                                </IconButton>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div style={{ height: "auto" }}>
                      <h4> Investigation Advised </h4>
                      <table className="table table-borderless custom-table">
                        <tbody>
                          {testData.map((row, index) => (
                            <tr key={index}>
                              <td>{row.name}</td>

                              <td>
                                <IconButton
                                  classes={{
                                    root: classes.root,
                                  }}
                                  aria-label="delete"
                                  onClick={() => handleDeleteTest(index)}
                                  color="secondary"
                                >
                                  <ClearIcon />
                                </IconButton>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div style={{ position: "relative" }} className="col-md-8">
                    <div style={{ minHeight: "400px" }}>
                      <img
                        alt="Rx"
                        src={`${process.env.PUBLIC_URL}/assets/images/rx-bg.png`}
                        style={{
                          width: "35px",
                        }}
                      />
                      {/* <span style={{ fontSize: "24px" }}>℞</span> */}
                      <br />
                      <table className="table table-borderless custom-table">
                        <tbody>
                          {medData.map((row, index) => (
                            <tr key={index}>
                              <td>
                                {row.name} <br />
                                {row.rule} ({row.validity}) <br />
                                {row.timing} Meal
                              </td>

                              <td>
                                <IconButton
                                  classes={{
                                    root: classes.root,
                                  }}
                                  aria-label="delete"
                                  onClick={() => handleDeleteMed(index)}
                                  color="secondary"
                                >
                                  <ClearIcon />
                                </IconButton>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <h4> Comment </h4>
                      {comment}
                      <div className="">
                        <div
                          style={{
                            position: "absolute",
                            bottom: "0",
                            left: "0",
                          }}
                        >
                          <div style={{ fontSize: "8px", width: "98%" }}>
                            This electronic prescription has been generated
                            through telemedicine by the agreement of both
                            respective physician and patient and no signature is
                            required
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      width: "75%",
                      float: "left",
                      fontSize: "12px",
                    }}
                  ></div>
                </div>
                <div style={{ width: "100%" }}>
                  <hr />
                </div>
                <div style={{ width: "100%", fontSize: "8px" }}>
                  <p>
                    Heal-Holmes provides the platform for telemedicine and
                    diagnostic facilities for patient and physicians.
                  </p>
                  <p>
                    Heal-Holmes does not diagnose any disease or perform any
                    test by it self.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Success"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have successfully added your prescription
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
// const top100Films = [
//   { title: "Napa", year: 1994 },
//   { title: "Seclo", year: 1972 },
// ];
