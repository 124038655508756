class Auth {
  constructor() {
    const corporateToken = localStorage.getItem("corporateToken");

    if (corporateToken !== null) {
      this.authenticated = true;
    }
  }

  login(cb) {
    this.authenticated = true;

    cb();
  }
  logout(cb) {
    this.authenticated = false;
    localStorage.removeItem("corporateToken");
    cb();
  }

  isAuthenticated() {
    return this.authenticated;
  }
}

export default new Auth();
