import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import { HospitalAPI } from "../../../AxiosRequest/HospitalAPI";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";

export default function TestDetails() {
  let history = useHistory();
  let { testId } = useParams();
  const [testDetails, setTestDetails] = useState({});
  const [path, setPath] = useState();
  const theme = useTheme();
  const isTabMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    getTestDetails();
  }, []);

  const downloadPDF = (id, type) => {
    HospitalAPI.get(`test-invoices/${id}/pdf`, {
      method: "GET",
      responseType: "blob", //Force to receive data in a Blob Format
    })
      .then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" }); //Build a URL from the file
        const fileURL = URL.createObjectURL(file); //Open the URL on new Window
        if (type == "download") {
          var a = document.createElement("a");
          a.href = fileURL;
          if (testDetails.pdf !== null) {
            a.download = testDetails.pdf;
          } else {
            a.download = "heal-holmes-invoice.pdf";
          }
          a.click();
          window.URL.revokeObjectURL(fileURL);
        } else {
          window.open(fileURL);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const download = (report) => {
    axios
      .get(`files/testInvoiceDetails/${testId}/${report}`, {
        responseType: "blob",
      })
      .then((response) => {
        const file = new Blob([response.data]); //Build a URL from the file
        const fileURL = window.URL.createObjectURL(file); //Open the URL on new Window
        var a = document.createElement("a");
        a.href = fileURL;
        a.download = report;
        a.click();
        window.URL.revokeObjectURL(fileURL);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTestDetails = () => {
    HospitalAPI.get(`test-invoices/${testId}`)
      .then((response) => {
        console.log("test details", response.data.data);
        setTestDetails(response.data.data);
        console.log("test details", testDetails);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  console.log("test details", testDetails);
  return (
    <div>
      <div className="container">
        <div className="observation-test-area">
          <div className="row">
            <div className="col-12">
              <button
                onClick={(event) => {
                  event.preventDefault();
                  downloadPDF(testId, "download");
                }}
                className="btn btn-sm btn-success float-right m-2"
              >
                Download
              </button>
              <button
                onClick={(event) => {
                  event.preventDefault();
                  downloadPDF(testId, "print");
                }}
                className="btn btn-sm btn-success float-right m-2"
              >
                Print
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              Bill no:
              <span style={{ fontWeight: "bold" }}>
                {testDetails.unique_id}
              </span>
            </div>
            <div className="col-md-8">
              Date:
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {moment(testDetails.created_at).format("DD/MM/YYYY HH:mm")}{" "}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              Patient Name:{" "}
              {testDetails.patient != undefined ? (
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {testDetails.patient.name}
                </span>
              ) : (
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {testDetails.patient_name}
                </span>
              )}
            </div>
            <div className="col-md-8">
              Test Date:
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {testDetails.test_date &&
                  moment(testDetails.test_date).format("DD/MM/YYYY")}{" "}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              Patient Phone:{" "}
              {testDetails.patient !== undefined && (
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {testDetails.patient.phone}
                </span>
              )}
            </div>
            <div className="col-md-8">
              Doctor:
              <span style={{ fontWeight: "bold" }}> {testDetails.doctor_name}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              Diagnostic Name:
              {testDetails.service_provider !== undefined && (
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {testDetails.service_provider.name}
                </span>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              Diagnostic Address :
              {testDetails.service_provider !== undefined && (
                <span style={{ fontWeight: "bold" }}>
                  {testDetails.service_provider.address}
                </span>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <table className="custom-responsive-table">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Department</th>
                    <th scope="col">Price</th>
                    <th scope="col">Discount</th>
                    <th scope="col">Bill</th>
                    <th scope="col">Report</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(testDetails).includes(
                    "test_invoice_details"
                  ) && (
                    <React.Fragment>
                      {testDetails.test_invoice_details.map((test, i) => (
                        <tr key={i}>
                          <td data-column="Name">
                            {test.diagnostic_test.name}
                          </td>

                          <td data-column="Department">
                            {test.diagnostic_test.department}
                          </td>
                          <td data-column="Price">{Math.ceil(test.price)} </td>
                          <td data-column="Discount">
                            {Math.ceil(test.discount)}
                          </td>
                          <td data-column="Bill">{Math.ceil(test.bill)}</td>
                          <td data-column="Report">
                            {test.report !== null && (
                              <button
                                className="btn  btn-sm btn-success"
                                onClick={() => download(test.report)}
                              >
                                Ready
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  )}
                  {testDetails.home_delivery && (
                    <tr>
                      <td data-column="Name">Home sample collection </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td data-column="Bill">1000</td>
                    </tr>
                  )}

                  <tr className="border-less-row">
                    {!isTabMobile && (
                      <React.Fragment>
                        <td> </td>
                        <td></td>
                        <td></td>
                      </React.Fragment>
                    )}

                    <td>Total Regular Price</td>
                    <td>
                      {testDetails.home_delivery
                        ? Math.ceil(parseInt(testDetails.price) + 1000)
                        : Math.ceil(testDetails.price)}
                    </td>
                  </tr>
                  <tr className="border-less-row">
                    {!isTabMobile && (
                      <React.Fragment>
                        <td> </td>
                        <td></td>
                        <td></td>
                      </React.Fragment>
                    )}
                    <td>Total Discount</td>
                    <td>{testDetails.discount}</td>
                  </tr>
                  <tr className="border-less-row">
                    {!isTabMobile && (
                      <React.Fragment>
                        <td> </td>
                        <td></td>
                        <td></td>
                      </React.Fragment>
                    )}
                    <td>Total Payable</td>
                    <td>
                      {testDetails.home_delivery
                        ? parseInt(testDetails.bill) + 1000
                        : testDetails.bill}
                    </td>
                  </tr>
                  <tr className="border-less-row">
                    {!isTabMobile && (
                      <React.Fragment>
                        <td> </td>
                        <td></td>
                        <td></td>
                      </React.Fragment>
                    )}
                    <td>Total Payment</td>
                    <td>0</td>
                  </tr>
                  <tr className="border-less-row">
                    {!isTabMobile && (
                      <React.Fragment>
                        <td> </td>
                        <td></td>
                        <td></td>
                      </React.Fragment>
                    )}
                    <td>Due amount</td>
                    <td>
                      {testDetails.home_delivery
                        ? parseInt(testDetails.bill) + 1000
                        : testDetails.bill}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {testDetails.test_invoice_details !== undefined &&
                testDetails.test_invoice_details.length && (
                  <div>
                    <p>
                      {" "}
                      * Please complete your full payment during sample
                      collection or you can pay through our website.
                    </p>
                    <p>* Test will be performed by your selected lab.</p>
                    <p>
                      * During sample collection you will be notified regarding
                      report delivery date and time by the selected Lab.
                    </p>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
