import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Link,
  useHistory,
  // useHistory,
  useParams,
} from "react-router-dom";
import * as Yup from "yup";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import "../css/add-institution.css";

export default function MedicineForm() {
  // let history = useHistory();

  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const { id } = useParams();
  const [details, setDetails] = useState({ name: "" });
  const [backendErrors, setBackendErrors] = useState({});
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (id && id !== undefined) {
      getDetails();
    }
  }, []);

  const getDetails = () => {
    AdminAPI.get(`medicines/${id}`)
      .then((response) => {
        console.log(response);
        setDetails(response.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const handleDeleteItem = () => {
    AdminAPI.post(`medicines/${id}`)
      .then((response) => {
        console.log(response);
        setOpenDialog(false);
        alert("Successfully deleted");
        history.push("/admin/medicine/list");
      })
      .catch((error) => {
        setOpenDialog(false);
        console.log(error.response);
      });
  };

  const validationSchema = Yup.object({
    brand_name: Yup.string().required("This field is required"),
    generic_name: Yup.string().required("This field is required"),
  });
  const initialValues = {
    brand_name: details.brand_name || "",
    generic_name: details.generic_name || "",
  };
  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    if (id && id !== undefined) {
      AdminAPI.put(`medicines/${id}`, values)
        .then((response) => {
          console.log(response);
          setOpen(true);
          setAlertMsg(response.data.message);
        })
        .catch((error) => {
          console.log(error.response);

          if (error.response.data.errors !== undefined) {
            setBackendErrors(error.response.data.errors);
          }
        });
    } else {
      AdminAPI.post("medicines", values)
        .then((response) => {
          console.log(response);
          setAlertMsg(response.data.message);
          setOpen(true);
          history.push("/admin/medicine/list");
        })
        .catch((error) => {
          console.log(error.response);

          if (error.response.data.errors !== undefined) {
            setBackendErrors(error.response.data.errors);
          }
        });
    }
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-right ">
            {id && (
              <button
                onClick={handleClickOpen}
                type="button"
                className="btn btn-lg btn-danger mx-2"
              >
                Delete
              </button>
            )}

            <Link
              to="/admin/medicine/list"
              type="button"
              className="btn btn-lg btn-success"
            >
              Medicine List
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-md-12">
                <div className="add-institution">
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({ setFieldValue, isValid, dirty }) => (
                      <Form>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1">
                            Brand Name
                          </label>
                          <div className="col-sm-7">
                            <Field
                              className=" form-control"
                              type="test"
                              name="brand_name"
                            />
                            <ErrorMessage
                              name="brand_name"
                              component="div"
                              className="error-message"
                            />

                            <p className="error-message">
                              {backendErrors.brand_name}
                            </p>
                          </div>
                        </div>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1">
                            Generic Name
                          </label>
                          <div className="col-sm-7">
                            <Field
                              className=" form-control"
                              type="test"
                              name="generic_name"
                            />
                            <ErrorMessage
                              name="generic_name"
                              component="div"
                              className="error-message"
                            />
                            <p className="error-message">
                              {backendErrors.generic_name}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 my-4">
                            <Collapse in={open}>
                              <Alert
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setOpen(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                {alertMsg}
                              </Alert>
                            </Collapse>
                          </div>
                        </div>

                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1"></label>
                          <div className="col-sm-7 text-right">
                            <button
                              type="submit"
                              className="btn btn-lg btn-success"
                            >
                              {id ? "Update" : "Submit"}
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3"></div>
        </div>

        <Dialog
          open={openDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {` Are you sure to delete this ${details.brand_name}?`}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              No
            </Button>
            <Button onClick={handleDeleteItem} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
