import axios from "axios";
import history from "../history";

const DoctorAPI = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/doctor`,
  // baseURL: "https://beta.heal-holmes.com/api/api/doctor",
});

DoctorAPI.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const token = JSON.parse(localStorage.getItem("HHDoctorToken"));

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);
DoctorAPI.interceptors.response.use(
  (response) => {
    console.log(response.status);
    // console.log(response.status);
    // Edit response config
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      const path = document.location.pathname;
      console.log(path);
      localStorage.removeItem("HHDoctorToken");
      history.push("/doctor/login");
      // if (path !== "/doctor/login") {
      //   return (window.location.href = "/doctor/login");
      // }
    }
    console.log(error);
    return Promise.reject(error);
  }
);

export { DoctorAPI };
