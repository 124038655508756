import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import "../css/add-institution.css";
export default function InstituitionForm() {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const { id } = useParams();
  const [details, setDetails] = useState({ name: "" });
  const [backendErrors, setBackendErrors] = useState({});
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (id && id !== undefined) {
      getDetails();
    }
  }, []);

  const getDetails = () => {
    AdminAPI.get(`institutions/${id}`)
      .then((response) => {
        console.log(response);
        setDetails(response.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const handleDeleteItem = () => {
    AdminAPI.post(`institutions/${id}`)
      .then((response) => {
        console.log(response);
        setOpenDialog(false);
        alert("Successfully deleted");
        history.push("/admin/institution/list");
      })
      .catch((error) => {
        setOpenDialog(false);
        console.log(error.response);
      });
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("This field is required"),
  });
  const initialValues = {
    name: "" || details.name,
  };

  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    if (id && id !== undefined) {
      AdminAPI.put(`institutions/${id}`, values)
        .then((response) => {
          console.log(response);
          setOpen(true);
          setAlertMsg(response.data.message);
        })
        .catch((error) => {
          console.log(error.response);

          if (error.response.data.errors !== undefined) {
            setBackendErrors(error.response.data.errors);
          }
        });
    } else {
      AdminAPI.post("institutions", values)
        .then((response) => {
          console.log(response);
          setAlertMsg(response.data.message);
          setOpen(true);
          history.push("/admin/institution/list");
        })
        .catch((error) => {
          console.log(error.response);

          if (error.response.data.errors !== undefined) {
            setBackendErrors(error.response.data.errors);
          }
        });
    }
  };
  return (
    <div>
      <div
        className="site-layout-background"
        style={{ padding: 5, minHeight: 22 }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-right ">
              {id && (
                <button
                  onClick={handleClickOpen}
                  type="button"
                  className="btn btn-lg btn-danger mx-2"
                >
                  Delete
                </button>
              )}

              <Link
                to="/admin/institution/list"
                type="button"
                className="btn btn-lg btn-success"
              >
                Institution List
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3"></div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-md-12">
                  <div className="add-institution">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                      enableReinitialize={id ? true : false}
                    >
                      {({ setFieldValue, isValid, dirty }) => (
                        <Form>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1">Name</label>
                            <div className="col-sm-7">
                              <Field
                                className=" form-control"
                                type="test"
                                name="name"
                              />
                              <ErrorMessage
                                name="name"
                                component="div"
                                className="error-message"
                              />
                              {!isEmpty(backendErrors) &&
                                !isEmpty(backendErrors.name) && (
                                  <div className="error-message">
                                    {backendErrors.name.map((o) => (
                                      <p>{o}</p>
                                    ))}
                                  </div>
                                )}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-12 my-4">
                              <Collapse in={open}>
                                <Alert
                                  action={
                                    <IconButton
                                      aria-label="close"
                                      color="inherit"
                                      size="small"
                                      onClick={() => {
                                        setOpen(false);
                                      }}
                                    >
                                      <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                  }
                                >
                                  {alertMsg}
                                </Alert>
                              </Collapse>
                            </div>
                          </div>
                          <div className="form-group row ">
                            <div className="col-md-11 text-right">
                              <button
                                type="submit"
                                className="btn btn-lg btn-success"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3"></div>
          </div>
        </div>
      </div>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {` Are you sure to delete this ${details.name}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={handleDeleteItem} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
