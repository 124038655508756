import React, { useEffect } from "react";
import {
  Switch,
  Route,
  Link,
  useRouteMatch,
  useLocation,
  useHistory,
} from "react-router-dom";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import patientAuth from "../../routingRules/patientAuth";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import PropTypes from "prop-types";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Tooltip from "@material-ui/core/Tooltip";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AddToQueueIcon from "@material-ui/icons/AddToQueue";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import PersonIcon from "@material-ui/icons/Person";

import TestList from "./invoices/TestList";
import AddTest from "./invoices/AddTest";

// import ResetPassword from "./reset-password";
import Profile from "./profile";
import UploadPrescription from "./invoices/UploadPrescription";
import UploadedTest from "./invoices/UploadedTest";
import UploadedTestDetails from "./invoices/UploadedTestDetails";
import ReportList from "./test-reports/ReportList";
import TestDetails from "./invoices/TestDetails";
import PatientRegistration from "./patient/PatientRegistration";
import PatientList from "./patient/PatientList";
import PatientDetails from "./patient/PatientDetails";
import ResetPassword from "./reset-password";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Icon } from "@material-ui/core";
import DoctorUpdate from "./doctor/DoctorUpdate";
import DoctorList from "./doctor/DoctorList";
import DoctorDetails from "./doctor/DoctorDetails";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  nested: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    fontSize: 14,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },

  appBar: {
    backgroundColor: "#3f4d67",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },

  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  hide: {
    display: "none",
  },
  drawerPaper: {
    backgroundColor: "#3f4d67",
    paddingBottom: "15vh",
    width: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      top: "65px",
    },
    [theme.breakpoints.down("sm")]: {
      top: "55px",
    },
    [theme.breakpoints.down("xs")]: {
      top: "0",
    },
  },

  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: "4rem",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  list: {
    color: "#a9b7d0",
    "&:hover": {
      color: "#fff!important",
      // backgroundColor: "blue!important",
    },
  },
  selected: {
    color: "#fff",
    backgroundColor: "rgba(255, 201, 201, 0.08)!important",
    "&:hover": {
      // color: "#fff",
    },
    // background: "blue",
  },
}));
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      {/* <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "} */}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function HospitalDashboard(props) {
  const { window } = props;
  let history = useHistory();
  let match = useRouteMatch();
  let location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openSubLink, setOpenSubLink] = React.useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [currentExpand, setCurrentExpand] = React.useState({
    name: "",
  });
  const [open, setOpen] = React.useState(true);
  const handleTestExpand = () => {
    setOpenSubLink(!openSubLink);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const logOut = () => {
    localStorage.removeItem("testCart");
    localStorage.removeItem("testImages");
    localStorage.clear();
    patientAuth.logout(() => {
      history.push("/");
    });
  };

  const routeToPortal = () => {
    history.push("/hospital/dashboard");
  };
  const profile = () => {
    history.push("/hospital/profile");
  };
  const resetPassword = () => {
    history.push("/hospital/reset-password");
  };
  // useEffect(() => {
  //   history.push("/patient/appointments");
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  const handleExpand = (list) => {
    if (currentExpand.name === list.name) {
      setCurrentExpand({ name: "" });
    } else {
      setCurrentExpand({ name: list.name });
    }
  };
  const drawer = (
    <div>
      <Divider />

      <List>
        {adminRoutes.map((route, index) => (
          <React.Fragment>
            {!route.multiple && (
              <ListItem
                key={index}
                selected={location.pathname === route.route}
                button
                component={Link}
                onClick={() => {
                  if (isMobile) handleDrawerToggle();
                }}
                to={route.route}
                classes={{ root: classes.list, selected: classes.selected }}
              >
                <ListItemIcon>
                  <Icon
                    style={{
                      color:
                        location.pathname === route.route ? "#fff" : "#a9b7d0",
                    }}
                  >
                    {route.icon}
                  </Icon>
                </ListItemIcon>
                <ListItemText primary={route.name} />
              </ListItem>
            )}
            {route.multiple && (
              <div>
                <ListItem
                  selected={currentExpand.name === route.name}
                  button
                  onClick={() => handleExpand(route)}
                  classes={{ root: classes.list, selected: classes.selected }}
                >
                  <ListItemIcon>
                    <Icon
                      style={{
                        color:
                          location.pathname === route.route
                            ? "#fff"
                            : "#a9b7d0",
                      }}
                    >
                      {route.icon}
                    </Icon>
                  </ListItemIcon>
                  <ListItemText primary={route.name} />
                  {currentExpand.name === route.name ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItem>
                <Collapse
                  in={currentExpand.name === route.name}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {route.list && route.list.length > 0 && (
                      <div>
                        {route.list.map((list, i) => (
                          <ListItem
                            className={classes.nested}
                            key={i}
                            selected={location.pathname === list.route}
                            button
                            component={Link}
                            onClick={() => {
                              if (isMobile) handleDrawerToggle();
                            }}
                            to={list.route}
                            classes={{
                              root: classes.list,
                              selected: classes.selected,
                            }}
                          >
                            <ListItemIcon>
                              <Icon
                                style={{
                                  color:
                                    location.pathname === list.route
                                      ? "#fff"
                                      : "#a9b7d0",
                                }}
                              >
                                {list.icon}
                              </Icon>
                            </ListItemIcon>
                            <ListItemText primary={list.name} />
                          </ListItem>
                        ))}
                      </div>
                    )}
                  </List>
                </Collapse>
              </div>
            )}
          </React.Fragment>
        ))}
      </List>
      <Divider />
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div>
      <AppBar className={classes.appBar} position="fixed">
        <Toolbar className={classes.toolbar}>
          <Hidden smUp implementation="css">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              edge="start"
              className={clsx(classes.menuButton)}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Hidden xsDown implementation="css">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton)}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>

          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Dashboard
          </Typography>

          <Tooltip title="Change Password">
            <IconButton onClick={resetPassword} color="inherit">
              <VpnKeyIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Profile">
            <IconButton onClick={profile} color="inherit">
              <PersonIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Logout">
            <IconButton onClick={logOut} color="inherit" aria-label="logout">
              <ExitToAppIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.appBarSpacer} />
        <Switch>
          <Route exact path={`${match.path}/patient/register`}>
            <PatientRegistration />
          </Route>
          <Route exact path={`${match.path}/patient/list`}>
            <PatientList />
          </Route>
          <Route exact path={`${match.path}/patient/view/:pId`}>
            <PatientDetails />
          </Route>
          <Route exact path={`${match.path}/doctor/register`}>
            <DoctorUpdate />
          </Route>
          <Route exact path={`${match.path}/doctor/list`}>
            <DoctorList />
          </Route>
          <Route exact path={`${match.path}/doctor/view/:pId`}>
            <DoctorDetails />
          </Route>
          <Route exact={true} path={`${match.path}/test/uploaded-test`}>
            <UploadedTest />
          </Route>
          <Route path={`${match.path}/test/uploaded-test/:testId`}>
            <UploadedTestDetails />
          </Route>
          <Route path={`${match.path}/test/upload-prescription`}>
            <UploadPrescription />
          </Route>
          <Route exact={true} path={`${match.path}/test/test-invoices`}>
            <TestList />
          </Route>
          <Route exact={true} path={`${match.path}/test/report-list`}>
            <ReportList />
          </Route>
          <Route path={`${match.path}/test/test-invoices/:testId`}>
            <TestDetails />
          </Route>
          <Route exact path={`${match.path}/test/add`}>
            <AddTest />
          </Route>
          <Route exact path={`${match.path}/profile`}>
            <Profile />
          </Route>
          <Route exact path={`${match.path}/reset-password`}>
            <ResetPassword />
          </Route>
        </Switch>
      </main>
    </div>
  );
}

HospitalDashboard.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
const adminRoutes = [
  {
    name: "Dashboard",
    icon: "dashboard",
    route: "/hospital",
    multiple: false,
  },
  {
    name: "Test",
    icon: "",
    multiple: true,
    list: [
      {
        name: "Test List",
        icon: "support_agent",
        route: "/hospital/test/test-invoices",
        multiple: false,
      },
    ],
  },
  {
    name: "Patient",
    icon: "people",
    route: "/hospital/patient/list",
    multiple: false,
  },
  {
    name: "Doctor",
    icon: "people",
    route: "/hospital/doctor/list",
    multiple: false,
  },
];
