import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import Pagination from "@material-ui/lab/Pagination";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import VisibilityIcon from '@material-ui/icons/Visibility';
import Button from "@material-ui/core/Button";
import _ from "lodash";

export default function DiagnosticList() {
  const [diagnosticList, setDiagnosticList] = useState([]);
  const [diagId, setDiagId] = useState(Number);
  const [defaultStatus, setDefaultStatus] = useState("");
  const [selecedStatus, setSelectedStatus] = useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);

  const [params, setParams] = useState({
    per_page: 15,
    page: 1,
  });

  useEffect(() => {
    GetDiagnosticList();
  }, [params]);

  const GetDiagnosticList = () => {
    AdminAPI.get(`diagnostics`, { params: params })
      .then((response) => {
        setDiagnosticList(response.data.data.data);
        setTotalPage(response.data.data.last_page);
        setPage(response.data.data.current_page);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };

  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);

  const updateDiagnostic = (status, id) => {
    if (status === "Active") {
      setDefaultStatus("Inactive");
      setSelectedStatus("Inactive");
    }
    if (status === "Inactive") {
      setDefaultStatus("Active");
      setSelectedStatus("Active");
    }
    setDiagId(id);
    setOpenModal(true);
  };

  const changeStatus = (e) => {
    setSelectedStatus(e.target.value);
  };

  const submitStatus = () => {
    let data = {
      status: selecedStatus,
    };
    AdminAPI.post(`diagnostics/${diagId}/update-status`, data)
      .then((response) => {
        alert("You have successfully updated the diagnostic status");
        GetDiagnosticList();
        setOpenModal(false);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  return (
    <div>
      <div className="row mt-2">
        <div className="col-md-4 form-group">
          <select
            className="form-control"
            onChange={(e) => {
              handleColumnWiseSearch(e.target.value, "status");
            }}
          >
            <option value="">All</option>
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
          </select>
        </div>
        <div className="col-md-6 form-group">
          <input
            type="text"
            onChange={(e) => handleColumnWiseSearch(e.target.value, "search")}
            className="form-control"
            placeholder="Search Diagnostic"
          />
        </div>
        <div className="col-md-2 text-right form-group">
          <Link
            className="btn btn-outline-secondary"
            type="button"
            to={"/admin/add-diagnostic"}
          >
            Add Diagnostic
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mt-2">
          <div className="table-responsive">
            <table className="table table-sm table-striped table-bordered custom-sm">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Contact Person</th>
                  <th scope="col">Address</th>
                  <th scope="col">Status</th>
                  <th scope="col">Details</th>
                </tr>
              </thead>
              <tbody>
                {diagnosticList.map((diag, i) => (
                  <tr key={i}>
                    <td>{diag.name}</td>
                    <td>{diag.email}</td>
                    <td>{diag.contact_person}</td>
                    <td>{diag.address}</td>
                    <td>
                      {diag.status == "Active" ? (
                        <button
                          type="button"
                          onClick={() =>
                            updateDiagnostic(diag.status, diag.id)
                          }
                          className="btn btn-sm btn-info"
                        >
                          {diag.status === "Active" && "Active"}
                          {diag.status === "Inactive" && "Inactive"}
                          {diag.status === "" && "Not Available"}
                        </button>
                      ):(
                        <button
                          type="button"
                          onClick={() =>
                            updateDiagnostic(diag.status, diag.id)
                          }
                          className="btn btn-sm btn-warning"
                        >
                          {diag.status === "Active" && "Active"}
                          {diag.status === "Inactive" && "Inactive"}
                          {diag.status === "" && "Not Available"}
                        </button>
                      )}
                    </td>
                    <td>
                      <Link
                          type="button"
                          to={`/admin/diagnostics/${diag.id}`}
                          className="btn btn-sm btn-outline-primary mr-1"
                        >
                          <VisibilityIcon />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row">
        <Pagination
          color="primary"
          count={totalPage}
          page={page}
          onChange={handleChange}
        />
      </div>
      <Dialog
        fullWidth
        maxWidth={`sm`}
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <DialogTitle id="max-width-dialog-title">
          Change Diagnostic Status
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <select
              className=" form-control"
              defaultValue={defaultStatus}
              onChange={(event) => changeStatus(event)}
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)} color="primary">
            Close
          </Button>
          <Button onClick={() => submitStatus()} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
