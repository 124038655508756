import React from "react";
import Navbar2 from "../Shared/Navbar2";
import Footer from "../Shared/Footer";
const TermsConditions = () => {
  return (
    <div>
      <Navbar2 />

      <div className="text-sections container">
        <div className="WordSection1">
          <h4>Terms of Use</h4>

          <p className="MsoNormal">
            These terms of use (this&nbsp;&quot;Agreement&quot;) set forth the
            standards of use of&nbsp;www.heal-holmes&nbsp;located
            at&nbsp;http://www.heal-holmes.com;and all of its associated pages
            and Websites/Apps. The link www.heal-holmes and all such associated
            pages and Websites/Apps are collectively referred to herein as the
            &quot;Websites/Apps.&quot;
          </p>

          <p className="MsoNormal">
            The words &quot;You&quot; or &quot;User&quot; &quot;Your&quot; as
            used herein refer to all individuals accessing or using the
            Websites/Apps for any reason.
          </p>

          <p className="MsoNormal">
            By using the Websites/Apps, you represent that you have read and
            agree to be bound by the terms of this Agreement, as well as any
            other guidelines, privacy policy, rules and additional terms
            referenced herein, collectively referred to as &quot;Terms of
            Use&quot;. These Terms of Use set out the legally binding terms with
            respect to your access and use of the Websites/Apps and our
            provision of the Services (as defined below).
          </p>

          <p className="MsoNormal">
            Please read these Terms of Use carefully. Your access to
            Websites/Apps and/or use of the Service constitutes your acceptance
            of all the provisions of these Terms of Use. If you are unwilling to
            be bound by these Terms of Use, do not access Websites/Apps and/or
            use the Service.
          </p>

          <p className="MsoNormal">1. DEFINITIONS AND INTERPRETATIONS</p>

          <ol style={{ marginTop: "0in" }} type="I">
            <li className="MsoNormal">Definitions</li>
          </ol>

          <p className="MsoNormal">
            &quot;Account&quot; means the account successfully opened by the
            User on the Company's Websites/Apps by inserting information such as
            name, age, sex, contact details, user name, password as required to
            be filled in the webpage during the registration process and
            includes any further changes and additions to the information from
            time to time.
          </p>

          <p className="MsoNormal">
            &quot;Company&quot; means Heal Holmes Ltd or any of its assignee,
            incorporated under the Companies Act, 1994.
          </p>

          <p className="MsoNormal">
            &quot;Customer&quot; means any User who accesses the Websites/Apps
            completes the registration according to clause 6.
          </p>

          <p className="MsoNormal">
            &quot;E-Health Packages&quot; means the exclusive and customized
            health diagnostic packages being offered by the Company and any such
            other packages which the Company may be introduced from time to time
            through web portal.
            <br />
            &quot;Fee&quot; means the price prescribed by the Company or
            partnered laboratories for the E- Health Packages/Services as
            notified on its Websites/Apps from time to time.
          </p>

          <p className="MsoNormal">
            &quot;Home Service&quot; means the facility provided by the
            Company/partnered Laboratories to facilitate sample collection at
            the Customer's door step.
          </p>

          <p className="MsoNormal">
            &quot;Order ID&quot; means the unique identification identity
            allotted to a Customer upon placing a request for booking the
            Services/E-Health Packages.
          </p>

          <p className="MsoNormal">
            &quot;Registration Process&quot; means the entire process which a
            Customer/User undergoes while registering himself on the
            Websites/Apps in accordance with clause 6.
          </p>

          <p className="MsoNormal">
            &quot;Services&quot; means the services as mentioned in clause 3.2.
            <br />
            &quot;User&quot; means any person who accesses the Websites/Apps.
          </p>

          <ol style={{ marginTop: "0in" }} start="2" type="I">
            <li className="MsoNormal">Interpretation</li>
          </ol>

          <p className="MsoNormal">
            In this Agreement, unless the context otherwise requires references
            to recitals, clauses and sub-clauses are to recitals, clauses and
            sub-clauses of this Agreement; headings are inserted for ease of
            reference only and are not to be used to define, interpret or limit
            any of the provisions of this Agreement; references to the singular
            number shall include references to the plural number and vice versa;
            words denoting one gender include all genders; any reference in this
            Agreement to a statutory provision includes that provision and any
            regulation made in pursuance thereof, as from time to time modified
            or re-enacted, whether before or after the date of this Agreement;
            and any reference to a time limit in this Agreement means the time
            limit set out in the relevant clause or sub-clause or such other
            time limit which may be mutually agreed by the parties in writing.
          </p>

          <p className="MsoNormal">2. ELIGIBILITY</p>

          <ol style={{ marginTop: "0in" }} type="I">
            <li className="MsoNormal">
              You, if an individual, must be 18 or above, or the legal age to
              form a binding contract in your jurisdiction if that age is
              greater than 18 years of age, be a member or use the Websites/Apps
              and Services. Membership or use of this Websites/Apps is void
              where prohibited by applicable law, and the right to access the
              Websites/Apps will be deemed to be revoked in such jurisdiction’s
              ab initio. By using the Websites/Apps and/or the Services, you
              represent and warrant that You have the right, authority, and
              capacity to enter into these Terms of Use and to abide by all of
              the terms and conditions set forth herein. You also represent and
              warrant to the Company that You will use Websites/Apps in a manner
              consistent with any and all applicable laws and regulations.
            </li>
          </ol>

          <p className="MsoNormal">3. SERVICES</p>

          <ol style={{ marginTop: "0in" }} type="I">
            <li className="MsoNormal">
              The use of this Websites/Apps entitles the User, whether a User or
              a Customer, to avail certain services as provided in the following
              clauses (&quot;Services&quot;) and interpretation of the term
              &quot;Services&quot; shall be done accordingly depending upon the
              context.
            </li>
            <li className="MsoNormal">
              The Users are entitled to the following Services:
            </li>
            <ol style={{ marginTop: "0in" }} type="I">
              <li className="MsoNormal">
                If You have not completed the registration as per clause 6, You
                are entitled to view the snapshots of various E-Health Packages
                or other offers being offered on the Websites/Apps. i.e., home
                collection requests, customer feedback form etc.
              </li>
              <li className="MsoNormal">
                If You have completed the registration as per clause 6, on the
                payment of Fee, you are entitled:
              </li>
              <ol style={{ marginTop: "0in" }} type="I">
                <li className="MsoNormal">
                  to view the snapshots of various E-Health Packages or other
                  offers being offered on the Websites/Apps;
                </li>
                <li className="MsoNormal">
                  to book one or more E-Health Package/s or other offers;
                </li>
                <li className="MsoNormal">
                  to opt for Home Service (if available at that period of time)
                  wherein the Company/Partnered Laboratories shall send its
                  representatives to your door step for the sample collection or
                  You may visit the nearest center (as per your booking) of the
                  Company to give the sample. However, you shall have to visit
                  the designated test centers/ laboratories if the
                  Company/Partnered Laboratories requires You to do so;
                </li>
                <li className="MsoNormal">
                  to receive email/SMS/phone calls/letters which shall provide
                  You with the Order ID, the E-Health Package's details and the
                  other details;
                </li>
                <li className="MsoNormal">
                  to receive the test reports within the suggested time by the
                  partner laboratories as per your booking.
                </li>
                <li className="MsoNormal">
                  By clicking the button, I agree, you agree to be called on
                  behalf of Heal Holmes Ltd., using an automatic telephone
                  dialing system.
                </li>
              </ol>
            </ol>
            <li className="MsoNormal">
              The Services are non-transferable i.e., only the person on whose
              name the E-Health Package is assigned at the time of booking will
              be eligible to avail the Services at the lab or through home
              collection.
            </li>
            <li className="MsoNormal">
              All of the diagnostic test services are performed by the lab
              selected by Customer/s and the test quality, report accuracy and
              report releasing schedule is the sole responsibilities of that
              specific selected lab.{" "}
            </li>
            <li className="MsoNormal">
              Telemedicine services are performed by the BMDC registered
              physicians and Heal Holmes Ltd. is not liable for the misdiagnosis
              or mis leading of any sort.
            </li>
            <li className="MsoNormal">
              In case a booking is made before 4 pm on a business day, the
              Company shall endeavor to give a confirmation call to the Customer
              on the same day and if it is received after 4 pm, the Company
              shall endeavor to give a confirmation call to the Customer the
              next business day.
            </li>
            <li className="MsoNormal">
              The Customer is required to carry a photo-identification card, a
              copy of the invoice and Order ID or the transaction number at the
              time of visit to the Lab or when availing Home Service.
            </li>
            <li className="MsoNormal">
              The Company may put further terms and conditions with every
              E-Health Package or Services and in case of any conflict with the
              Terms of Use or Privacy Policy, the term and conditions put
              specifically with the E-Heath Package shall prevail.
            </li>
            <li className="MsoNormal">
              The Company reserves the right to change the nature of Services as
              mentioned in clause 3.2 at its sole discretion. Such change may be
              notified to the User/Customer by publishing the same on the
              Websites/Apps.
            </li>
          </ol>

          <p className="MsoNormal">4. RESTRICTIONS ON USE</p>

          <ol style={{ marginTop: "0in" }} type="I">
            <li className="MsoNormal">
              You shall not use the Websites/Apps in order to transmit,
              distribute, store or destroy material, including without
              limitation content provided by the Company:
            </li>
            <ol style={{ marginTop: "0in" }} type="I">
              <li className="MsoNormal">
                for any unlawful purpose or in violation of any applicable law,
                regulation, international law or laws of any other country; or
              </li>
              <li className="MsoNormal">
                in a manner that will infringe the copyright, trademark, trade
                secret or other intellectual property rights of others or
                violate the privacy, publicity or other personal rights of
                others, or
              </li>
              <li className="MsoNormal">
                that is defamatory, libelous, obscene, threatening, abusive or
                is offensive to users of the Websites/Apps, such as content or
                messages that promotes racism, bigotry, hatred or physical harm
                of any kind against any group or individual; or
              </li>
              <li className="MsoNormal">that is false or misleading; or</li>
              <li className="MsoNormal">
                that harasses or advocates harassment of another person.
              </li>
            </ol>
            <li className="MsoNormal">
              You are also prohibited from violating or attempting to violate
              the security of the Websites/Apps, including, without limitation
              the following activities: (a) accessing data not intended for You
              or logging into a server or account which You are not authorized
              to access; (b) attempting to probe, scan or test the vulnerability
              of a system or network or to breach security or authentication
              measures without proper authorization; (c) attempting to interfere
              with service to any user, host or network, including, without
              limitation, via means of submitting a virus to Websites/Apps,
              overloading, &quot;flooding&quot;, &quot;spamming&quot;,
              &quot;mail bombing&quot;, &quot;hacking&quot; or
              &quot;crashing&quot;; or (d) forging any TCP/IP packet header or
              any part of the header information in any e-mail or newsgroup
              posting. Violations of system or network security may result in
              civil or criminal liability.
            </li>
            <li className="MsoNormal">
              Specific Restrictions on Rights to Use: In addition to the above,
              You shall not:
            </li>
            <ol style={{ marginTop: "0in" }} type="I">
              <li className="MsoNormal">
                modify, adapt, translate, or reverse engineer any portion of the
                Websites/Apps and/or Services;
              </li>
              <li className="MsoNormal">
                remove any copyright, trademark or other proprietary rights
                notices contained in or on the Websites/Apps and/or Service;
              </li>
              <li className="MsoNormal">
                use any robot, spider, site search/retrieval application, or
                other device to retrieve or index any portion of the
                Websites/Apps and/or Service or for crawling the Websites/Apps
                and scraping content or to circumvent the technological methods
                adopted by the Websites/Apps to prevent such prohibited use;
              </li>
              <li className="MsoNormal">
                reformat or frame any portion of the web pages that are part of
                the Websites/Apps and/or Service;
              </li>
              <li className="MsoNormal">
                create user accounts by automated means or under false or
                fraudulent pretenses;
              </li>
              <li className="MsoNormal">
                create or transmit unwanted electronic communications such as
                &quot;spam&quot; to other users/customers of the Websites/Apps
                and/or Service or otherwise interfere with other User's or
                Customer's enjoyment of the Websites/Apps and/or Service;
              </li>
              <li className="MsoNormal">
                submit any content or material that falsely express or imply
                that such content or material is sponsored or endorsed by the
                Company or the Websites/Apps;
              </li>
              <li className="MsoNormal">
                transmit any viruses, worms, defects, Trojan horses or other
                items of a destructive nature;
              </li>
              <li className="MsoNormal">
                make use of the Websites/Apps or Services to violate the
                security of any computer network, crack passwords or security
                encryption codes, transfer or store illegal material including
                that are deemed threatening or obscene;
              </li>
              <li className="MsoNormal">
                copy or store any content offered on the Websites/Apps for other
                than Your own use;
              </li>
              <li className="MsoNormal">
                take any action that imposes, or may impose in our sole
                discretion, an unreasonable or disproportionately large load on
                the Company's IT infrastructure;
              </li>
            </ol>
          </ol>

          <p className="MsoNormal">5. REMEDIES WITH THE COMPANY</p>

          <ol style={{ marginTop: "0in" }} type="I">
            <li className="MsoNormal">
              You understand and agree that the Company or the Websites/Apps may
              review any content and in case the Company finds, in its sole
              discretion, that the User violates any terms of this Agreement
              especially clause 4, the Company and/or the Websites/Apps reserves
              the right to take actions to prevent/control such violation
              including without limitation, removing the offending communication
              or content from the Websites/Apps and/or terminating the
              membership of such violators and/or blocking their use of the
              Websites/Apps and/or Service.
            </li>
            <li className="MsoNormal">
              The Company shall also be entitled to investigate occurrences
              which may involve such violations and may and take appropriate
              legal action, involve and cooperate with law enforcement
              authorities in prosecuting Users/Customers who are involved in
              such violations.
            </li>
          </ol>

          <p className="MsoNormal">6. REGISTRATION PROCESS</p>

          <ol style={{ marginTop: "0in" }} type="I">
            <li className="MsoNormal">
              The User to be entitled to avail the Services shall have to
              complete the registration process (&quot;Registration
              Process&quot;) as provided below:
            </li>
            <ol style={{ marginTop: "0in" }} type="I">
              <li className="MsoNormal">
                The Registration Process involves the creating of a login id by
                the User in accordance with clause 3.2.2.
              </li>
              <li className="MsoNormal">
                Registration is mandatory for the Customers and requires them to
                provide certain basic information about themselves such as name,
                age, sex, email address, billing address, collection address,
                zip/postal code, passport number, NID number, Birth Certificate
                number and phone number and accordingly create an Account.
              </li>
            </ol>
            <li className="MsoNormal">
              Only after completing the Registration Process, the Users become
              Customers and become entitled to avail the Services as mentioned
              in the Clause 3.2, subject to payment of the Fee.
            </li>
            <li className="MsoNormal">
              The Websites/Apps may provide the facility of 'masking' which
              allows You to hide or keep confidential or not to fill any
              information except the information which are considered mandatory
              by the Company and which will indicated by asterisks. The Company
              further reserved the right to seek further information, even
              though masked by You, if in its sole view such information is
              necessary.
            </li>
            <li className="MsoNormal">
              The Customers understand and agree that the Company may screen and
              verify the information provided by the Customer/User and at its
              sole discretion, increase the amount or amount of information for
              the Registration Process and may ask for further information even
              after Registration Process. The Company may in its sole
              discretion, close the Account, if any information provided is
              found to be false or the information provided is not sufficient.
            </li>
          </ol>

          <p className="MsoNormal">7. PAYMENT</p>

          <ol style={{ marginTop: "0in" }} type="I">
            <li className="MsoNormal">
              The Company shall endeavor to provide the Customer with
              facilities/gateways to pay the Fee through credit cards (American
              Express, visa and Mastercard), debit cards, cash cards, Mobile
              Financial System (MFS) and internet banking.
            </li>
            <li className="MsoNormal">
              It is understood and agreed by the User/Customer that the Services
              shall only commence after realization of money in the accounts of
              the Company incase online payment is being opted for by them.
            </li>
            <li className="MsoNormal">
              It is understood and agreed by the User/Customer that payment
              mechanisms may be governed by separate/additional terms of use
              prescribed by the Company.
            </li>
            <li className="MsoNormal">
              The Company reserves the right to refuse or cancel any order
              placed for a product/package that is listed at an incorrect price.
              This shall be regardless of whether the order has been confirmed
              and/or payment been levied via credit card. In the event the
              payment has been processed by the Company, the same shall be
              credited to your credit card account within 10-15 working days and
              duly notified to you by email/phone. Once the order has been
              placed and in case You wish to cancel/modify the same You may do
              so subject to cancellation/modification charges as prescribed.
            </li>
            <li className="MsoNormal">
              It is understood and agreed by the User/Customer that payment
              mechanisms may be governed by separate agreements between the
              third parties who provide facilities for such payment mechanism
              and the Company.
            </li>
            <li className="MsoNormal">
              It is understood and agreed by the User/Customer that in no event
              whatsoever, the Company shall take any responsibility or liability
              for malfunctioning or defect in any payment procedure. Payment of
              the Price shall be the sole responsibility of the User/Customer.
            </li>
            <li className="MsoNormal">
              The Company reserves the right to charge listing fees for certain
              listings, as well as transaction fees based on certain completed
              transactions using the Services through the Websites/Apps or any
              other fee. The Company further reserves the right to alter any and
              all fees from time to time, without notice.
            </li>
            <li className="MsoNormal">
              The User/Customer may be liable to pay all applicable charges,
              fees, duties, taxes, levies and assessments for availing the
              Services through the Websites/Apps. Further, the Company reserves
              the right o change the Fees upon its sole discretion without any
              prior notice to the Customers/Users.
            </li>
          </ol>

          <p className="MsoNormal">8. REFUND AND CANCELLATION POLICY</p>

          <ol style={{ marginTop: "0in" }} type="I">
            <li className="MsoNormal">
              The E-Health Package is valid for the [7] days from the time and
              date of invoice generation. After 7 days, the Customer shall not
              be entitled to claim for Services and the company shall have the
              right to forfeit the fees already paid in such a case.
            </li>
            <li className="MsoNormal">
              Cancellation shall be acceptable only if the Customer informs the
              Company within 72 hours from the time of booking. 3% cancellation
              charges will be applicable on the total amount of invoice. The
              Customer can contact us through e-mail id, heal.holmes@gmail.com
              in case of cancellation and refund.
            </li>
            <li className="MsoNormal">
              The refund amount will be sent to the respective debit card/credit
              card/MFS/account from where payment was made and amount will not
              be refundable by any other mode.
            </li>
          </ol>

          <p className="MsoNormal">9. DELIVERY</p>

          <ol style={{ marginTop: "0in" }} type="I">
            <li className="MsoNormal">
              The Company shall endeavor to release the test report/s to the
              Customer’s portal if so opted for by him/her within&nbsp;12
              [Twelve] hours after partner laboratories (where customer has
              chosen to perform the test) has released the report. Please allow
              the minimum time required for processing as specified for the
              test. Turn Around Time (TAT) for a test started from the sample
              received at the specific lab of selection and standard TAT is
              maintained by the partner laboratories to release the test report.
              If the customer has made partial payment against the desired
              services to be availed, in such circumstances, the company may not
              be able to release the test reports due administrative/technical
              reasons.
            </li>
            <li className="MsoNormal">
              The Customer/s may also collect the reports by hand during working
              hours on the date mentioned on the receipt from the designated
              collection center/branch of the Company’s Partnered laboratories
              where test/s were booked.
            </li>
            <li className="MsoNormal">
              The test reports may also be displayed on the Websites/Apps within
              the prescribed period.
            </li>
          </ol>

          <p className="MsoNormal">10. MODIFICATION OF TERMS OF USE</p>

          <ol style={{ marginTop: "0in" }} type="I">
            <li className="MsoNormal">
              You understand and agree that these Terms of Use, the
              Websites/Apps and the Services can be modified by the Company at
              its sole discretion, at any time without prior notice, and such
              modifications will be effective upon such new terms and/or upon
              implementation of the new changes on the Websites/Apps. You agree
              to review the Terms of Use periodically so that you are aware of
              any such modifications and the Company shall not be liable for any
              loss suffered by You on your failure to review such modified Terms
              of Use. Unless expressly stated otherwise, any new features, new
              services, enhancements or modifications to the Websites/Apps or
              Service implemented after your initial access of Websites/Apps or
              use of the Service shall be subject to these Terms of Use.
            </li>
          </ol>

          <p className="MsoNormal">11. MAINTENANCE</p>

          <ol style={{ marginTop: "0in" }} type="I">
            <li className="MsoNormal">
              The Company may at its sole discretion and without assigning any
              reason whatsoever at any time deactivate or/and suspend the
              User's/Customer's access to the Websites/Apps and/or the Services
              (as the case may be) without giving any prior notice, to carry out
              system maintenance or/and upgrading or/and testing or/and repairs
              or/and other related work. Without prejudice to any other
              provisions of this Agreement, the Company shall not be liable to
              indemnify the User for any loss or/and damage or/and costs or/and
              expense that the User may suffer or incur, and no fees or/and
              charges payable by the User to the Company shall be deducted or
              refunded or rebated, as a result of such deactivation or/and
              suspension.
            </li>
          </ol>

          <p className="MsoNormal">12. TERM AND TERMINATION</p>

          <ol style={{ marginTop: "0in" }} type="I">
            <li className="MsoNormal">
              These Terms of Use, with modifications as contemplated, shall
              remain in full force and effect during the user of the
              Websites/Apps for all Users.
            </li>
            <li className="MsoNormal">
              For Customers, the Terms of Use shall commence from the time the
              Registration Process is concluded as per clause 6 of this
              Agreement and shall be valid until terminated as provided below or
              till the time the Account is maintained.
            </li>
            <li className="MsoNormal">
              The Company may terminate this Agreement with immediate effect,
              without prior notice and without assigning any reason/s whatsoever
              and without any prejudice to any/all other rights in the following
              events:
            </li>
            <ol style={{ marginTop: "0in" }} type="I">
              <li className="MsoNormal">
                where the Account remains unused for a period of six months or
                more; or
              </li>
              <li className="MsoNormal">
                if in the opinion of the Company, the User has breached any of
                the terms and conditions of this Agreement or/and the Terms of
                Use; or
              </li>
              <li className="MsoNormal">
                if, in the opinion of the Company or/and any regulatory
                authority, it is not in the public interest to continue
                providing the use or Service to the User for any reason.
              </li>
              <li className="MsoNormal">
                If, any unauthorized use of the account takes place.
              </li>
            </ol>
            <li className="MsoNormal">
              Notwithstanding anything contained in the Terms of Use, clauses 5,
              11, 12, 13, 14, 15, 16, 17, 18, 19, 20 and 21 shall survive any
              termination or expiration of these Terms of Use.
            </li>
          </ol>

          <p className="MsoNormal">13. LIABILITIES UPON TERMINATION</p>

          <ol style={{ marginTop: "0in" }} type="I">
            <li className="MsoNormal">
              If the Terms of Use is terminated pursuant to clauses set out in
              clause 12 above, without prejudice to any other remedies available
              to the Company, You shall not be refunded whether a part or whole
              of the Fee.
            </li>
          </ol>

          <p className="MsoNormal">14. OWNERSHIP</p>

          <ol style={{ marginTop: "0in" }} type="I">
            <li className="MsoNormal">
              Any material, content or logos, marks, software on or part of the
              Websites/Apps and all aspects thereof, including all copyrights
              and other intellectual property or proprietary rights therein, is
              owned by the Company or its licensors. You acknowledge that the
              Websites/Apps and any underlying technology or software on the
              Websites/Apps or used in connection with rendering the Services
              are proprietary information owned or duly licensed to the Company,
              except where it is indicated otherwise. You are prohibited to
              modify, reproduce, distribute, create derivative works of,
              publicly display or in any way exploit, any of the content,
              software, marks, logos, and/or materials available on the
              Websites/Apps in whole or in part except as expressly allowed
              under the Terms of Use. You have no other express or implied
              rights to use, in any manner whatsoever, the content, software,
              marks, logos, and/or materials available on the Websites/Apps.
            </li>
          </ol>

          <p className="MsoNormal">15. COPYRIGHT DISPUTE POLICY</p>

          <ol style={{ marginTop: "0in" }} type="I">
            <li className="MsoNormal">
              The Company has adopted the following general policy towards
              copyright infringement:
            </li>
            <li className="MsoNormal">
              If the Company believes in good faith any material on its
              Websites/Apps has been illegally copied or is posted, uploaded or
              made accessible through the Websites/Apps or Services and
              distributed by any advertisers, its affiliates, content providers,
              members or Users; it shall send an Infringement Notice and remove
              and discontinue Services to offenders.
            </li>
            <li className="MsoNormal">
              If despite the Infringement Notice, the offender does not take the
              requisite steps, the Company shall have the right to proceed
              against the offender by filing a suit in the appropriate court of
              law on ground of such infringement.
            </li>
          </ol>

          <p className="MsoNormal">16. DISCLAIMER</p>

          <ol style={{ marginTop: "0in" }} type="I">
            <li className="MsoNormal">
              THE WEBSITES/APPS IS PROVIDED BY THE COMPANY ON AN &quot;AS
              IS&quot; BASIS THE COMPANY AND ITS LICENSORS AND AFFILIATES MAKE
              NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS, STATUTORY
              OR IMPLIED AS TO THE OPERATION OF THE WEBSITES/APPS, PROVISION OF
              SERVICES OR SOFTWARE OR THE INFORMATION, CONTENT, MATERIALS, OR
              PRODUCTS INCLUDED ON THE WEBSITES/APPS OR IN ASSOCIATION WITH THE
              SERVICES. TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW, THE
              COMPANY AND ITS LICENSORS AND AFFILIATES DISCLAIM ALL WARRANTIES,
              EXPRESS, STATUTORY, OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
              IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
              PURPOSE AND NON-INFRINGEMENT. THE COMPANY AND ITS LICENSORS AND
              AFFILIATES FURTHER DO NOT WARRANT THE ACCURACY OR COMPLETENESS OF
              THE INFORMATION, TEXT, GRAPHICS, LINKS OR OTHER ITEMS CONTAINED
              WITHIN THE WEBSITES/APPS. THE COMPANY IS NOT RESPONSIBLE FOR THE
              CONDUCT, OF ANY USER OF THE WEBSITES/APPS. THE COMPANY DOES NOT
              WARRANT OR COVENANT THAT THE SERVICES WILL BE AVAILABLE AT ANY
              TIME OR FROM ANY PARTICULAR LOCATION, WILL BE SECURE OR
              ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SERVICES
              AND ACCESS TO THE WEBSITES/APPS IS FREE OF VIRUSES OR OTHER
              POTENTIALLY HARMFUL COMPONENTS. ANY MATERIAL OR CONTENT DOWNLOADED
              OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICES OR THE
              WEBSITES/APPS IS ACCESSED AT YOUR OWN DISCRETION AND RISK AND YOU
              WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM
              OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH
              MATERIAL. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN,
              OBTAINED BY ANY USER FROM THE COMPANY, THE WEBSITES/APPS OR
              THROUGH OR FROM THE SERVICES, THE INFORMATION, CONTENT, MATERIALS,
              OR PRODUCTS ON THE WEBSITES/APPS SHALL CREATE ANY WARRANTY NOT
              EXPRESSLY STATED HEREIN.
            </li>
            <li className="MsoNormal">
              ALL THE CONTENTS OF THIS WEBSITES/APPS ARE ONLY FOR GENERAL
              INFORMATION OR USE. THEY DO NOT CONSTITUTE ANY MEDICAL ADVICE AND
              SHOULD NOT BE RELIED UPON IN MAKING (OR REFRAINING FROM MAKING)
              ANY DECISION. ALTHOUGH THE LABORATORY PROVIDES THE LARGEST SINGLE
              SOURCE OF OBJECTIVE, SCIENTIFIC DATA ON PATIENT STATUS, IT IS ONLY
              ONE PART OF A COMPLEX BIOLOGICAL PICTURE OF HEALTH OR DISEASE. AS
              PROFESSIONAL CLINICAL LABORATORY SCIENTISTS, OUR GOAL IS TO ASSIST
              YOU IN UNDERSTANDING THE PURPOSE OF LABORATORY TESTS AND THE
              GENERAL MEANING OF YOUR LABORATORY RESULTS.IT IS IMPORTANT THAT
              YOU COMMUNICATE WITH YOUR PHYSICIAN SO THAT TOGETHER YOU CAN
              INTEGRATE THE PERTINENT INFORMATION, SUCH AS AGE, ETHNICITY,
              HEALTH HISTORY, SIGNS AND SYMPTOMS, LABORATORY AND OTHER
              PROCEDURES (RADIOLOGY, ENDOSCOPY, ETC.), TO DETERMINE YOUR HEALTH
              STATUS. THE INFORMATION PROVIDED THROUGH THIS SERVICE IS NOT
              INTENDED TO SUBSTITUTE FOR SUCH CONSULTATIONS WITH YOUR PHYSICIAN
              NOR MEDICAL ADVICE SPECIFIC TO YOUR HEALTH CONDITION. DISCLAIM ANY
              LIABILITY ARISING OUT OF YOUR USE OF THIS SERVICE OR FOR ANY
              ADVERSE OUTCOME FROM YOUR USE OF THE INFORMATION PROVIDED BY THIS
              SERVICE FOR ANY REASON, INCLUDING BUT NOT LIMITED TO ANY
              MISUNDERSTANDING OR MISINTERPRETATION OF THE INFORMATION PROVIDED
              THROUGH THIS SERVICE.ANY SPECIFIC ADVICE OR OPINION IN ANY PART OF
              THE REPORT IS/ARE THE PERSONAL OPINION OF SUCH
              EXPERTS/CONSULTANTS/PERSONS AND ARE NOT SUBSCRIBED TO BY THIS
              WEBSITES/APPS. FURTHER IT SHALL BE THE SOLE RESPONSIBILITY OF THE
              USERS/CUSTOMERS TO PROVIDE ANY INFORMATION AND/OR DISCLOSE TRUE
              AND CORRECT INFORMATION ABOUT THEIR MEDICAL HISTORY AT THE TIME OF
              SUBSCRIBING FOR THE SERVICES AND THE COMPANY DOES NOT TAKE ANY
              RESPONSIBILITY FOR THE ACCURACY OR VALIDITY OR TRUTH OF THE REPORT
              POSTED ON THE WEBSITES/APPS AND THE COMPANY SHALL NOT BE LIABLE ON
              THIS ACCOUNT BASED ON ANY INCORRECT/FASLE INFORMATION HAVING BEEN
              PROVIDED BY THE UER/CUSTOMER.
            </li>
            <li className="MsoNormal">
              SINCE THE COMPANY ACTS ONLY AS A SERVICE PORTAL FOR THE
              USERS/CUSTOMERS, IT SHALL NOT HAVE ANY LIABILITY WHATSOEVER FOR
              ANY ASPECT OF THE PAYMENT BETWEEN THE THIRD PARTY AND THE
              CUSTOMER/USER.
            </li>
            <li className="MsoNormal">
              IN NO EVENT SHALL THE COMPANY BE LIABLE FOR ANY DIRECT, INDIRECT,
              PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY OTHER
              DAMAGES RESULTING FROM: (A) THE USE OR THE INABILITY TO USE THE
              SERVICES; (B) UNAUTHORIZED ACCESS TO OR ALTERATION OF THE USER'S
              TRANSMISSIONS OR DATA; (C) ANY OTHER MATTER RELATING TO THE
              SERVICES; INCLUDING, WITHOUT LIMITATION, DAMAGES FOR ANY LOSS
              WHATSOEVER ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR
              PERFORMANCE OF THE WEBSITES/APPS.
            </li>
            <li className="MsoNormal">
              NEITHER SHALL THE COMPANY BE RESPONSIBLE FOR THE DELAY OR
              INABILITY TO USE THE WEBSITES/APPS OR RELATED SERVICES, THE
              PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY
              INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS
              OBTAINED THROUGH THE WEBSITES/APPS, OR OTHERWISE ARISING OUT OF
              THE USE OF THE WEBSITES/APPS, WHETHER BASED ON CONTRACT, TORT,
              NEGLIGENCE, STRICT LIABILITY OR OTHERWISE. FURTHER, THE COMPANY
              SHALL NOT BE HELD RESPONSIBLE FOR NON-AVAILABILITY OF THE
              WEBSITES/APPS DURING PERIODIC MAINTENANCE OPERATIONS OR ANY
              UNPLANNED SUSPENSION OF ACCESS TO THE WEBSITES/APPS THAT MAY OCCUR
              DUE TO TECHNICAL REASONS OR FOR ANY REASON BEYOND THE COMPANY'S
              CONTROL. THE USER/CUSTOMER UNDERSTANDS AND AGREES THAT ANY
              MATERIAL AND/OR DATA DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE
              WEBSITES/APPS IS DONE ENTIRELY AT THEIR OWN DISCRETION AND RISK
              AND THEY WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO THEIR
              COMPUTER SYSTEMS OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF
              SUCH MATERIAL AND/OR DATA.
            </li>
            <li className="MsoNormal">
              THESE LIMITATIONS, DISCLAIMER OF WARRANTIES AND EXCLUSIONS APPLY
              WITHOUT REGARD TO WHETHER THE DAMAGES ARISE FROM (A) BREACH OF
              CONTRACT, (B) BREACH OF WARRANTY, (C) NEGLIGENCE, OR (D) ANY OTHER
              CAUSE OF ACTION, TO THE EXTENT SUCH EXCLUSION AND LIMITATIONS ARE
              NOT PROHIBITED BY APPLICABLE LAW.
            </li>
          </ol>

          <p className="MsoNormal">17. LIMITATION ON LIABILITY</p>

          <ol style={{ marginTop: "0in" }} type="I">
            <li className="MsoNormal">
              The Company shall not be liable for any failure to perform its
              obligations hereunder where such failure results from any cause
              beyond the Company's reasonable control, including, without
              limitation, mechanical, electronic or communications failure or
              degradation (including &quot;line-noise&quot; interference).
              WITHOUT LIMITING THE FOREGOING, THE COMPANY AND ITS AFFILIATES AND
              SUPPLIERS WILL NOT BE LIABLE UNDER ANY LAW, FOR ANY INDIRECT,
              INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES, INCLUDING, BUT
              NOT LIMITED TO LOSS OF PROFITS, SERVICE INTERRUPTION, AND/OR LOSS
              OF INFORMATION OR DATA.
            </li>
          </ol>

          <p className="MsoNormal">18. INDEMNITY</p>

          <ol style={{ marginTop: "0in" }} type="I">
            <li className="MsoNormal">
              You agree to indemnify and hold the Company, its parents,
              subsidiaries, affiliates, officers and employees, harmless,
              including costs and attorneys' fees, from any claim or demand made
              by any third party due to or arising out of (i) your access to the
              Websites/Apps, (ii) your use of the Services, (iii) the violation
              of these Terms of Use by You, or (iv) the infringement by You, or
              any third party using Your account or User ID or password, of any
              intellectual property or other right of any person or entity.
            </li>
          </ol>

          <p className="MsoNormal">19. PRIVACY</p>

          <ol style={{ marginTop: "0in" }} type="I">
            <li className="MsoNormal">
              Use of the Websites/Apps and/or the Services is also governed by
              our Privacy Policy.
            </li>
          </ol>

          <p className="MsoNormal">20. CONFIDENTIALITY</p>

          <ol style={{ marginTop: "0in" }} type="I">
            <li className="MsoNormal">
              For the purpose of this Agreement and attachments thereto and all
              renewals, &quot;Confidential Information&quot; means all
              information (including any information relating to the Account,
              username or password etc.), methods developed for analysis,
              examination and verification and other such details (the
              &quot;Disclosing Party&quot;) or, which may be supplied to or may
              otherwise come into the possession of the other (the
              &quot;Receiving Party&quot;), whether orally or in writing or in
              any other form, and which is confidential or proprietary in nature
              or otherwise expressed by the Disclosing Party to be confidential
              and is not generally available to the public.
            </li>
            <li className="MsoNormal">
              The Receiving Party shall keep confidential and secret and not
              disclose to any third party the Confidential Information or any
              part of it. The Receiving Party agrees to take all possible
              precautions with regard to protecting Confidential Information
              from any third party.
            </li>
            <li className="MsoNormal">
              Further no use, reproduction, transformation or storage of the
              Confidential Information shall be made by the User without the
              prior written permission of the Company, except where required to
              be disclosed pursuant to any applicable law or legal process
              issued by any court or the rules of any competent regulatory body.
            </li>
            <li className="MsoNormal">
              All information and data submitted by the User shall become the
              property of the Company and all such information shall be
              disclosed in accordance with the Terms of Use. Notwithstanding
              anything contained in the Terms of Use, the User/Customer gives
              his unconditional consent to the Company that it may sell or
              license or permit third parties to use such data or information,
              on payment of consideration or otherwise.
            </li>
            <li className="MsoNormal">
              The User has access to only his own data and information stored in
              the database at Websites/Apps (subject to prior confirmation of
              identity) and nothing more. The User may edit or amend such data
              and information from time to time, if Company provides such an
              option.
            </li>
            <li className="MsoNormal">
              All Confidential Information (including name, e-mail address etc.)
              voluntarily revealed by the User in chat and bulletin board areas,
              is done at the sole discretion and risk of the User. The Company
              shall not be responsible for misuse of any such information,
              collected by a third party, or any unsolicited messages from such
              third parties.
            </li>
            <li className="MsoNormal">
              If the User is neither a genuine Customer nor an intended
              recipient and are using or accessing the Websites/Apps to gain
              Confidential Information and if such an User has obtained access
              to the Confidential Information, it shall be a breach of this
              Agreement and shall be kept absolutely confidential. Any use or
              divulgence of such Confidential Information by such User, shall
              entitle the Company to inquire and investigate and seek legal
              remedy against such User including to seek temporary and permanent
              injunction.
            </li>
          </ol>

          <p className="MsoNormal">21. MISCELLANEOUS</p>

          <ol style={{ marginTop: "0in" }} type="I">
            <li className="MsoNormal">
              Governing law and Dispute Resolution- This Agreement and Terms of
              Use shall be governed by and constructed in accordance with the
              laws of India only without reference to conflict of laws
              principles and all disputes arising in relation hereto shall be
              subject to the exclusive jurisdiction of the courts of Dhaka,
              Bangladesh.
            </li>
            <li className="MsoNormal">
              Assignability -The Company may assign any of its
              responsibilities/obligations to any other Person without notice to
              the User, at its sole discretion. However, you shall not assign,
              sub-license or otherwise transfer any of your rights under these
              Terms of Use to any other party, unless a written consent is taken
              from the Company.
            </li>
            <li className="MsoNormal">
              Severability - If any provision of these Terms of Use is found to
              be invalid, the invalidity of that provision will not affect the
              validity of the remaining provisions of the Terms of Use, which
              shall remain in full force and effect.
            </li>
            <li className="MsoNormal">
              Waiver - Failure by the Company to exercise any right or remedy
              under these Terms of Use does not constitute a waiver of that
              right or remedy.
            </li>
            <li className="MsoNormal">
              Force Majeure - The Company is not liable for failure to perform
              any of its obligations if such failure is as a result of Acts of
              God (including fire, flood, earthquake, storm, hurricane or other
              natural disaster), war, invasion, act of foreign enemies,
              hostilities (regardless of whether war is declared), civil war,
              rebellion, revolution, insurrection, military or usurped power or
              confiscation, terrorist activities, nationalization, government
              sanction, blockage, embargo, labor dispute, strike, bandh, lockout
              or any interruption or any failure of electricity or server,
              system, computer, internet or telephone service.
            </li>
            <li className="MsoNormal">
              Grievance Redressal: The Company shall endeavor to address
              grievance or complaints of the Users to the extent possible.
              Towards this the Company is committed to form a Grievance
              Redressal Cell, which can be reached on the telephone number as
              mentioned in the Websites/Apps or may mail to
              heal.holmes@gmail.com.
            </li>
            <li className="MsoNormal">Links to third party sites</li>
            <ol style={{ marginTop: "0in" }} type="I">
              <li className="MsoNormal">
                The Websites/Apps may contain links to other Websites/Apps
                (&quot;Linked Sites&quot;). The Linked Sites are not under the
                control of the Company or the Websites/Apps and the Company is
                not responsible for the contents of any Linked Site, including
                without limitation any link contained in a Linked Site, or any
                changes or updates to a Linked Site. The Company is not
                responsible for any form of transmission, whatsoever, received
                by the User from any Linked Site. The Company is providing these
                links to the User only as a convenience, and the inclusion of
                any link does not imply endorsement by the Company or the
                Websites/Apps of the Linked Sites or any association with its
                operators or owners including the legal heirs or assigns
                thereof.
              </li>
              <li className="MsoNormal">
                The Company is not responsible for any errors, omissions or
                representations on any Linked Site. The Company does not endorse
                any advertiser on any Linked Site in any manner. The Users are
                requested to verify the accuracy of all information on their own
                before undertaking any reliance on such information.
              </li>
            </ol>
          </ol>

          <p className="MsoNormal">
            22. Communication with the Customers/Users
          </p>

          <p className="MsoNormal">
            When you visit the Websites/Apps or use it to send emails/SMS,
            provide information or communicate to us, you understand and agree
            that you are communicating with us through electronic records. You
            hereby provide your consent to receive communications via electronic
            records from us periodically or as and when required. Further, you
            allow us to communicate with you through email or by such other mode
            of communication, electronic or otherwise as the need may be.
          </p>

          <p className="MsoNormal">
            These &quot;Terms of Use&quot; and &quot;Privacy Policy&quot; of the
            Websites/Apps constitute a binding agreement between You and the
            Company, and is accepted by You upon your use of the Websites/Apps.
          </p>

          <p className="MsoNormal">&nbsp;</p>

          <p className="MsoNormal">&nbsp;</p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default TermsConditions;
