import React, { useState, useEffect } from "react";
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
  setNestedObjectValues,
} from "formik";
import { TextField as TextFieldSecond } from "formik-material-ui";
import axios from "axios";
import Badge from "@material-ui/core/Badge";
import clsx from "clsx";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import Drawer from "@material-ui/core/Drawer";
import Menu from "@material-ui/core/Menu";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty, debounce } from "lodash";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Pagination from "@material-ui/lab/Pagination";
import { useHistory, useParams } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Popper from "@material-ui/core/Popper";
import { Link } from "react-router-dom";
import AddShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";

import DeleteIcon from "@material-ui/icons/Delete";
import TextField from "@material-ui/core/TextField";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { withStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import * as Yup from "yup";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { Icon } from "@material-ui/core";
import "./css/global.css";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import { DoctorAPI } from "../../AxiosRequest/DoctorAPI";

const drawerWidthCart = 320;

const FormikDatePicker = ({
  name,
  form: { setFieldValue },
  field: { value },
  ...rest
}) => {
  // console.log(rest);
  return (
    <DatePicker
      disablePast
      format="dd/MM/yyyy"
      views={["year", "month", "date"]}
      name={name}
      clearable
      autoOk
      inputVariant="outlined"
      // handle clearing outside => pass plain array if you are not controlling value outside
      mask={(value) =>
        value
          ? [/[0-3]/, /\d/, "/", /0|1/, /\d/, "/", /1|2/, /\d/, /\d/, /\d/]
          : []
      }
      onChange={(value) => {
        console.log("setting value to", value);
        console.log("setting value to", moment(value).format("DD/MM/YYYY"));

        setFieldValue("test_date", value);
      }}
      value={value}
      animateYearScrolling={false}
    />
  );
};
const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge);
const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    top: "10%",
    left: "10%",
    overflow: "hidden",
    height: "100%",
    maxHeight: 500,
    display: "block",
  },
  root: {
    padding: "0px",
  },
  header: {
    padding: "12px 0",
    borderBottom: "1px solid darkgrey",
  },
  content: {
    padding: 12,
    overflow: "scroll",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,

    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: "#3f51b5",
    fontWeight: "700",
  },
  icnBtnRoot: {
    padding: "0px",
  },
  header: {
    padding: "12px 0",
    borderBottom: "1px solid darkgrey",
  },
  content: {
    padding: 12,
    overflow: "scroll",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,

    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: "#3f51b5",
    fontWeight: "700",
  },
  hide: {
    display: "none",
  },

  drawerCart: {
    width: drawerWidthCart,
    flexShrink: 0,
  },
  drawerPaperCart: {
    width: drawerWidthCart,
    [theme.breakpoints.up("sm")]: {
      top: "65px",
      width: drawerWidthCart,
    },
    [theme.breakpoints.down("sm")]: {
      top: "55px",
      width: "100%",
    },
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: -drawerWidth,
  },

  contentShift2: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up("sm")]: {
      marginRight: drawerWidthCart,
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  root: {
    display: "flex",
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
}));
function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return "Choose your preferred lab from below...";
    case 1:
      return "Search test or upload your prescription";
    case 2:
      return "Press add to card button from the table";
    default:
      return "Unknown stepIndex";
  }
}
function getSteps() {
  return [
    "Select a Diagnostic Lab",
    "Search Test or Upload Prescription",
    "Add To Cart",
  ];
}
function getStepContentCovid(stepIndex) {
  switch (stepIndex) {
    case 0:
      return "Choose your preferred lab from below...";
    case 1:
      return "Type covid in the search test area if the covid test";
    case 2:
      return "You can change the lab for covid test by clicking on the Select Diagnostic Lab field. To add covid test Press add to cart button from the table";
    default:
      return "Unknown stepIndex";
  }
}

export default function AddTestInvoice() {
  const storage = localStorage.getItem("names")
    ? JSON.parse(localStorage.getItem("names"))
    : [];
  let history = useHistory();
  const theme = useTheme();
  const isTabMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let { medId } = useParams();
  const [open, setOpen] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [policeStations, setPoliceStations] = useState([]);
  const [patientList, setPatientList] = useState([]);
  const [doctorList, setDoctorList] = useState([]);
  const [diagnosticList, setDiagnosticList] = useState([]);
  const [users, setUsers] = useState([]);
  const [tableData, setTableData] = useState(storage);
  const [totalRegularPrice, setTotalRegularPrice] = useState(Number);
  const [totalDiscountPrice, setTotalDiscountPrice] = useState(Number);
  const [totalAdminDiscountPrice, setTotalAdminDiscountPrice] =
    useState(Number);
  const [totalPrice, setTotalPrice] = useState(Number);
  const [policeStationID, setPoliceStationID] = useState("");
  const [diagnosticID, setDiagnosticID] = useState("");
  const [erroropen, setErrorOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [testList, setTestList] = useState([]);

  const [successMessage, setSuccessMessage] = useState("");
  const [deliveryCharge, setDeliveryCharge] = useState(1000);
  const [errorMessage, setErrorMessage] = useState("");

  // Backend Errors Handle
  const [backendErrorMessage, setBackendErrorMessage] = useState({});

  const [sameMedicineErrors, setSameMedicineErrors] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const [openDetails, setOpeDetails] = React.useState(false);
  const [openCheck, setOpenCheck] = React.useState(false);
  const [stationSearchTerm, setStationSearchTerm] = useState("");
  const [patientSearchTerm, setPatientSearchTerm] = useState("");
  const [doctorSearchTerm, setDoctorSearchTerm] = useState("");
  const [diagnosticValue, setDiagnosticValue] = useState("");

  const [homeSample, setHomeSample] = React.useState(false);
  const [homeSampleCheck, setHomeSampleCheck] = React.useState(false);
  const [docRefCheck, setDocRefCheck] = React.useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [openCart, setOpenCart] = React.useState(false);
  const [page, setPage] = useState(1);
  const [extraDiscount, setExtraDiscount] = useState(0);

  const [updatedTestInvoice, setUpdatedTestInvoice] = useState([
    { id: "", discount: 0, type: "" },
  ]);
  const [params, setParams] = useState({
    page: 1,
    per_page: 15,
  });
  const [selectedPatient, setSelectedPatient] = useState({
    name: "",
    patient_name: "",
    gender: "",
    date_of_birth: null,
    police_station_id: "",
    address: "",
    phone: "",
    patient_id: "",
    age: "",
  });
  const [searchField, setSearchField] = useState("");
  const [covid, setCovid] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [dOB, setDOB] = React.useState("");
  const steps = getSteps();

  const handleNext = (step) => {
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setActiveStep(step);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setTableData([]);
    setSearchField("");
    setDiagnosticID("");
    setDiagnosticValue("");
    setCovid(true);
  };
  useEffect(() => {
    if (diagnosticID !== "") {
      handleNext(1);
    }
    if (searchField === "cov") {
      handleNext(2);
    }
    if (tableData.length > 0) {
      handleNext(3);
    }
  }, [diagnosticID, searchField, tableData]);

  useEffect(() => {
    setCovid(true);
    // getDiagnosticList();
    setSearchField("a");
  }, []);
  useEffect(() => {
    GetPoliceStations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stationSearchTerm]);
  const GetPoliceStations = () => {
    // let url = `http://localhost:3001/purchase_order/${paramID}`;
    axios
      .get(`police-stations?name=${stationSearchTerm}`)
      .then((response) => {
        setPoliceStations(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const handleOnInputCapturePolice = debounce((event) => {
    setStationSearchTerm(event.target.value);
  }, 500);
  useEffect(() => {
    getPatinetList();
  }, [patientSearchTerm]);
  const getPatinetList = () => {
    DoctorAPI.get(`patients?search=${patientSearchTerm}`)
      .then((response) => {
        console.log("asdfjlsdfksdj", response.data);
        setPatientList(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const handleOnInputCapturePatient = debounce((event) => {
    setPatientSearchTerm(event.target.value);
  }, 500);
  useEffect(() => {
    getDocList();
  }, [doctorSearchTerm]);
  useEffect(() => {
    GetCoupons();
  }, []);
  const GetCoupons = () => {
    // let url = `http://localhost:3001/purchase_order/${paramID}`;
    DoctorAPI.get(`coupons`)
      .then((response) => {
        setCoupons(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const getDocList = () => {
    axios
      .get(`doctors?name=${doctorSearchTerm}`)
      .then((response) => {
        setDoctorList(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const handleOnInputCaptureDoctor = debounce((event) => {
    setDoctorSearchTerm(event.target.value);
  }, 500);

  const handleOpenDetails = () => {
    setOpeDetails(true);
  };

  const handleCloseDetails = () => {
    setOpeDetails(false);
  };

  const handleOpenCheck = () => {
    setOpenCheck(true);
    console.log(tableData);
    const covidWithHomeSample = [];
    const covidWithOutHomeSample = [];
    const justHomeSample = [];
    const justNoHomeSample = [];
    for (let i = 0; i < tableData.length; i++) {
      let obj = tableData[i];
      if (obj.name.includes("COVID") && obj.home_delivery) {
        covidWithHomeSample.push(obj);
      } else if (obj.name.includes("COVID") && !obj.home_delivery) {
        covidWithOutHomeSample.push(obj);
      } else if (!obj.name.includes("COVID") && obj.home_delivery) {
        justHomeSample.push(obj);
      } else if (!obj.name.includes("COVID") && !obj.home_delivery) {
        justNoHomeSample.push(obj);
      }
    }

    const onlyCovid = [...covidWithHomeSample, ...covidWithOutHomeSample];
    if (
      onlyCovid.length > 0 &&
      justNoHomeSample.length === 0 &&
      justHomeSample.length === 0
    ) {
      console.log("No home collec");
      setHomeSampleCheck(false);
    }
    if (onlyCovid.length > 0 && justHomeSample.length > 0) {
      console.log("Home collection");
      setHomeSampleCheck(true);
    }
    if (onlyCovid.length === 0 && justHomeSample.length > 0) {
      console.log("Home collection");
      setHomeSampleCheck(true);
    }
    if (onlyCovid.length === 0 && justNoHomeSample.length > 0) {
      console.log("No home collection");
      setHomeSampleCheck(false);
    }
    if (
      onlyCovid.length > 0 &&
      covidWithHomeSample.length > 0 &&
      justNoHomeSample.length === 0 &&
      justHomeSample.length === 0
    ) {
      setHomeSampleCheck(true);
    }
    var r = tableData.some((i) => i.name.includes("COVID"));
    console.log("do something", covidWithHomeSample);
    console.log("do something with", covidWithOutHomeSample);
    console.log("jsut home", justHomeSample);
    console.log("not home", justNoHomeSample);
  };

  const handleCloseCheck = () => {
    setOpenCheck(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangeSample = (event) => {
    setHomeSample(event.target.checked);
  };
  const handleDocRefChange = (event) => {
    setDocRefCheck(event.target.checked);
  };
  const handleDrawerOpenCart = () => {
    setOpenCart(!openCart);
  };

  const getDiagnosticList = () => {
    // const params = {
    //   police_station_id: id,
    // };
    // , { params: params }

    axios
      .get("diagnostics")
      .then((response) => {
        console.log(response.data.data);
        let idsToRemove = [1, 2];
        let newArray = response.data.data.data;
        let covidLabs = newArray.filter(
          (item) => !idsToRemove.includes(item.id)
        );

        setDiagnosticList(covidLabs);
        setDiagnosticValue(covidLabs[0].name);
        setDiagnosticID(covidLabs[0].id);
        setParams({
          ...params,
          service_provider_id: covidLabs[0].id,
        });
        getTestList();
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  function handleChangePage(event, value) {
    setParams({ ...params, page: value });
  }
  const handleColumnWiseSearch = debounce((inputValue, column) => {
    const paramState = { ...params };
    if (isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
      setSearchField(inputValue);
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);
  useEffect(() => {
    if (diagnosticID !== "") {
      getTestList(params);
    }
  }, [params]);
  const getTestList = (params) => {
    if (!isEmpty(params) && params.hasOwnProperty("service_provider_id"))
      axios
        .get("diagnostic-tests", {
          params: params,
        })
        .then((response) => {
          console.log("testttttt", response.data);
          setTestList(response.data.data.data);
          setCurrentPage(response.data.data.current_page);
          setTotalPage(response.data.data.last_page);
        })
        .catch((error) => {
          console.log(error.response);
        });
  };
  // const getusers = () => {
  //   axios
  //     .get("tests?diagnostic_id=1")
  //     .then((response) => {
  //       console.log(response.data.data);
  //       setUsers(response.data.data);
  //       // setTestList(response.data.data);
  //     })
  //     .catch((error) => {
  //       console.log(error.response);
  //     });
  // };
  useEffect(() => {
    // getLocalTest();
    getDiagnosticList();
    GetPoliceStations();
    // getusers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [covid]);
  useEffect(() => {
    const testsTotalPrice = tableData.map((o) => parseInt(o.price));

    const testsTotalPercentageDiscount = tableData
      .filter((o) => o.discount_type === "%")
      .map((o) => {
        return parseInt((o.price / 100) * o.discount);
      });

    const testsTotalFlatDiscount = tableData
      .filter((o) => o.discount_type === "Flat")
      .map((o) => parseInt(o.discount));
    const testsTotalPercentageDiscountAdmin = tableData
      .filter((o) => o.adminDiscount.type === "%")
      .map((o) => {
        return parseInt((o.price / 100) * o.adminDiscount.discount);
      });

    const testsTotalFlatDiscountAdmin = tableData
      .filter((o) => o.adminDiscount.type === "Flat")
      .map((o) => parseInt(o.adminDiscount.discount));

    console.log("total discoutn====>", testsTotalPercentageDiscount);
    console.log("total disaacout flat====>", testsTotalFlatDiscount);
    console.log(
      "total discoutn testsTotalPercentageDiscountAdmin====>",
      testsTotalPercentageDiscountAdmin
    );
    console.log(
      "total disaacout flat testsTotalFlatDiscountAdmin====>",
      testsTotalFlatDiscountAdmin
    );
    let totalDicountPAdmin = testsTotalPercentageDiscountAdmin.reduce(function (
      a,
      b
    ) {
      return a + b;
    },
    0);
    let totalDicountFAdmin = testsTotalFlatDiscountAdmin.reduce(function (
      a,
      b
    ) {
      return a + b;
    },
    0);

    let totalDAdmin = totalDicountPAdmin + totalDicountFAdmin;
    setTotalAdminDiscountPrice(totalDAdmin);
    let totalPrice = testsTotalPrice.reduce(function (a, b) {
      return a + b;
    }, 0);
    setTotalRegularPrice(totalPrice);
    let totalDicountP = testsTotalPercentageDiscount.reduce(function (a, b) {
      return a + b;
    }, 0);
    let totalDicountF = testsTotalFlatDiscount.reduce(function (a, b) {
      return a + b;
    }, 0);

    let totalD = totalDicountP + totalDicountF;
    setTotalDiscountPrice(totalD);
    let actualPrice = totalPrice - (totalD + totalDAdmin);
    setTotalPrice(actualPrice);
    // let savedArray = JSON.parse(localStorage.getItem("names"));
    // if (savedArray.length > 0) {
    //   setTableData(savedArray);
    // }
    // localStorage.setItem("names", JSON.stringify(tableData));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    saveLocalTest();
  }, [tableData]);
  const saveLocalTest = () => {
    localStorage.setItem("HHAdminTestCart", JSON.stringify(tableData));
  };
  const getLocalTest = () => {
    if (localStorage.getItem("HHAdminTestCart") === null) {
      localStorage.setItem("HHAdminTestCart", JSON.stringify([]));
    } else {
      let localTest = JSON.parse(localStorage.getItem("HHAdminTestCart"));

      setTableData(localTest);

      if (localTest.length > 0) {
        setSearchField("a");
        setDiagnosticValue(localTest[0].service_provider.name);
        setDiagnosticID(localTest[0].service_provider.id);
        setParams({
          ...params,
          service_provider_id: localTest[0].service_provider.id,
        });
      }
    }
  };

  const validationSchema = Yup.object({
    // diagnostic_id: Yup.number().required("This field is required"),
    // police_station_id: Yup.string()
    //   .required("Please provide police stattion name")
    //   .typeError("Police Station field is required"),
    // phone: Yup.string()
    //   .required("Please provide patient name")
    //   .typeError("Patient field is required"),
  });
  const initialValues = {
    phone: "" || selectedPatient.phone,
  };
  const handleDelete = (index) => {
    let copyArray = [...tableData];

    copyArray.splice(index, 1);
    console.log(copyArray);
    setTableData(copyArray);
  };
  const addToCart = (test, index) => {
    if (tableData.some((el) => el.id === test.id)) {
      console.log("gggggg", index);
      let copyArrayCart = [...tableData];
      const filteredArray = copyArrayCart.filter((list) => {
        return list.id !== test.id;
      });
      console.log("aaaaaaaa", filteredArray);
      setTableData(filteredArray);
    } else if (!tableData.some((el) => el.id === test.id)) {
      let invoiceTest = [...updatedTestInvoice];
      const up = invoiceTest.filter((o) => o.id === test.id);
      let testToPush = { ...test };
      if (up.length === 0) {
        testToPush.adminDiscount = {
          discount: 0,
          id: test.id,
          type: "Flat",
        };
        setTableData([...tableData, testToPush]);
      } else if (up.length > 0) {
        testToPush.adminDiscount = {
          discount: up[0].discount,
          id: test.id,
          type: up[0].type,
        };
        setTableData([...tableData, testToPush]);
      }
    }
  };

  console.log("table data", tableData);

  const onSubmit = (values, { resetForm, setFieldValue }) => {
    console.log("vvvvvvvvvvvvvvv", values, tableData.length, tableData);
    if (tableData.length > 0) {
      const testsArray = tableData.map((o) => {
        return {
          diagnostic_test_id: o.id,
          discount: o.adminDiscount.discount,
          discount_type: o.adminDiscount.type,
        };
      });
      console.log("test aaarrrray", values);

      let objToSend = {
        tests: testsArray,

        phone: values.phone,
      };

      DoctorAPI.post("test-invoices", objToSend)
        .then((response) => {
          console.log(response);
          if (response.data.message) {
            setSuccessMessage(response.data.message);
            setTableData([]);
            setOpen(true);
            setErrorOpen(false);
            // history.push("/admin/test-list");
          }
        })
        .catch((error) => {
          console.log("AAAA", objToSend);
          console.log("AAAA", values.is_new_patient);

          setSelectedPatient({ ...objToSend, id: objToSend.patient_id });
          console.log(error.response);
          if (error.response.data.message) {
            setErrorMessage(error.response.data.message);
            setErrorOpen(true);
            setOpen(false);
          } else if (error.response.data.errors) {
            setBackendErrorMessage(error.response.data.errors);
          }
        });
    }
  };
  const handleUpdateTestInput = (test, e) => {
    console.log("tes", test);
    console.log("tes", e.target.value);
    console.log("tes", e.target.name);
    let index = updatedTestInvoice.findIndex((x) => x.id === test.id);
    let value = 0;
    let discountType = "Flat";

    if (e.target.name === "discount" && e.target.value !== "") {
      value = e.target.value;
    }
    if (e.target.name === "type" && e.target.value) {
      discountType = e.target.value;
    }
    const up = tableData.filter((o) => o.id === test.id);
    if (index !== -1) {
      let temporaryarray = updatedTestInvoice.slice();
      if (e.target.name === "discount") {
        temporaryarray[index][e.target.name] = value;
        if (up.length > 0) {
          const updatedData = tableData.map((x) =>
            x.id === test.id
              ? {
                  ...x,
                  adminDiscount: {
                    ...x.adminDiscount,
                    discount: value,
                  },
                }
              : x
          );
          setTableData(updatedData);
        }
      } else if (e.target.name === "type") {
        temporaryarray[index][e.target.name] = discountType;
        if (up.length > 0) {
          const updatedData = tableData.map((x) =>
            x.id === test.id
              ? {
                  ...x,
                  adminDiscount: {
                    ...x.adminDiscount,
                    type: discountType,
                  },
                }
              : x
          );
          setTableData(updatedData);
        }
      }

      setUpdatedTestInvoice(temporaryarray);
    } else {
      let up = [...updatedTestInvoice];
      let obj = {
        id: test.id,
        type: discountType,
        discount: value,
      };
      if (e.target.name === "discount") {
        obj[e.target.name] = value;
        if (up.length > 0) {
          const updatedData = tableData.map((x) =>
            x.id === test.id
              ? {
                  ...x,
                  adminDiscount: {
                    ...x.adminDiscount,
                    discount: value,
                  },
                }
              : x
          );
          setTableData(updatedData);
        }
      } else if (e.target.name === "type") {
        obj[e.target.name] = discountType;
        if (up.length > 0) {
          const updatedData = tableData.map((x) =>
            x.id === test.id
              ? {
                  ...x,
                  adminDiscount: {
                    ...x.adminDiscount,
                    type: discountType,
                  },
                }
              : x
          );
          setTableData(updatedData);
        }
      }

      up.push(obj);

      setUpdatedTestInvoice(up);
    }
  };

  function validateRequirement(value) {
    let error;

    if (!value) {
      error = "Required!";
    }

    return error;
  }
  function validationOnCondition(dob, age) {
    let error;
    console.log("DOB", dob, age);
    if (isEmpty(dob) && isEmpty(age)) {
      error = "Please fill up at least DOB or age";
    }

    return error;
  }
  function validatePhone(value) {
    let error;
    var pattern = /^(01){1}[3-9]{1}\d{8}$/;
    if (!pattern.test(value)) {
      error = "Enter a valid Mobile Number";
    } else if (!value) {
      error = "This field is required";
    }

    return error;
  }

  return (
    <div>
      <Drawer
        className={classes.drawerCart}
        variant="persistent"
        anchor="right"
        open={openCart}
        classes={{
          paper: classes.drawerPaperCart,
        }}
      >
        <div className="shopptingCartButtona">
          <div class="shoppingCartButton">{/* <button>hello</button> */}</div>
          <div className="header-cart">
            <div className="row py-2">
              <div className="col-12">
                <div className="d-flex cart-flex">
                  <div className="px-2">
                    {" "}
                    <Icon fontSize="large">local_mall</Icon>{" "}
                  </div>
                  <div>
                    {" "}
                    <h3 className="">{tableData.length} Tests</h3>
                  </div>
                  <div className="px-4">
                    {" "}
                    <Button
                      onClick={() => handleDrawerOpenCart()}
                      variant="outlined"
                      color="primary"
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </div>
              {/* <div className="col-5"></div> */}
            </div>
          </div>
          <div className="cart-items-list">
            <table
              style={{ width: isTabMobile ? "100%" : "150px" }}
              className="table table-sm table-borderless"
            >
              <thead className=""></thead>
              <tbody>
                {tableData.map((row, index) => (
                  <tr key={index}>
                    <td>{row.name}</td>

                    <td>
                      {row !== undefined && (
                        <span>
                          {row.discount_type === "%" ? (
                            <span>
                              {Math.ceil(
                                row.price - (row.price / 100) * row.discount
                              )}
                            </span>
                          ) : (
                            <span>{Math.ceil(row.price - row.discount)}</span>
                          )}
                        </span>
                      )}
                    </td>
                    <td>
                      <IconButton
                        classes={{
                          root: classes.icnBtnRoot, // class name, e.g. `classes-nesting-root-x`
                        }}
                        onClick={() => handleDelete(index)}
                        color="secondary"
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </td>
                  </tr>
                ))}
                <tr>
                  <th scope="col">
                    <button
                      onClick={() => setTableData([])}
                      className="btn btn-sm btn-danger"
                    >
                      Clear Cart
                    </button>{" "}
                  </th>

                  <th scope="col">{Math.ceil(totalPrice)}</th>
                </tr>
                <tr>
                  <th scope="col">
                    <button
                      onClick={() => handleOpenDetails()}
                      className="btn btn-sm btn-primary"
                    >
                      View Details
                    </button>{" "}
                  </th>

                  <th scope="col"></th>
                  <th scope="col">
                    <button
                      type="button"
                      onClick={() => handleOpenCheck()}
                      className="btn btn-sm btn-dark"
                    >
                      Checkout
                    </button>{" "}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Drawer>

      <main
        className={clsx(classes.content, {
          [classes.contentShift2]: openCart,
        })}
      >
        <div onClick={() => handleDrawerOpenCart()} className="stickyHeader">
          <div className="text-center">
            <LocalMallOutlinedIcon fontSize="small" />
          </div>
          <h5 style={{ textAlign: "center", fontSize: "14px" }} className="">
            {tableData.length} Tests
          </h5>
          <h5 style={{ textAlign: "center", fontSize: "14px" }} className="">
            {totalPrice}
          </h5>
        </div>
        <div className="container">
          <div style={{ width: "100%" }} className="test">
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
              {activeStep === steps.length ? (
                <div>
                  <Typography className={classes.instructions}>
                    Click on the 'Cart' Icon and click checkout button to book
                    your test
                  </Typography>
                  <Button
                    style={{ marginBottom: "1em" }}
                    color="secondary"
                    variant="contained"
                    onClick={handleReset}
                  >
                    Reset Step{" "}
                  </Button>
                </div>
              ) : (
                <div>
                  {!covid ? (
                    <Typography className={classes.instructions}>
                      {getStepContent(activeStep)}
                    </Typography>
                  ) : (
                    <Typography className={classes.instructions}>
                      {getStepContentCovid(activeStep)}
                    </Typography>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <div className="add-institutionss">
                    {/*  */}

                    <div className="form-group row ">
                      <label className="col-md-4 col-12">
                        Select Diagonstic
                      </label>
                      <div className="col-md-8 col-12">
                        <Autocomplete
                          id="disabledHelperText"
                          name="diagnostic_id"
                          options={diagnosticList.filter((dig) => dig.id !== 1)}
                          getOptionLabel={(option) =>
                            option.name || diagnosticValue
                          }
                          value={diagnosticValue}
                          onChange={(e, value) => {
                            if (value == null) {
                              console.log("Hello");
                              setDiagnosticValue("");
                              setDiagnosticID("");
                            } else {
                              setDiagnosticValue(value.name);
                              setDiagnosticID(value.id);
                              setParams({
                                ...params,
                                service_provider_id: value.id,
                              });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="diagnostic_id"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-7">
              <input
                type="text"
                onChange={(e) => handleColumnWiseSearch(e.target.value, "name")}
                className="form-control"
                placeholder="Search Test"
              />
            </div>
          </div>
          {diagnosticID !== "" && (
            <div className="row">
              <div className="col-md-12">
                {!isMobile ? (
                  <table className="custom-responsive-table">
                    <thead>
                      <tr>
                        <th scope="col">Test Name</th>
                        <th scope="col">Test Price</th>
                        <th scope="col">Discounted Price</th>
                        <th scope="col">Discount</th>
                        <th scope="col">Home Collection</th>
                        <th scope="col">Add To Cart</th>
                      </tr>
                    </thead>
                    <tbody>
                      {testList.map((test, i) => (
                        <tr key={i}>
                          <td data-column="Test Name">{test.name}</td>
                          <td data-column="Test Price">
                            {Math.ceil(test.price)}
                          </td>
                          <td data-column="Discounted Price">
                            {test !== undefined && (
                              <span>
                                {test.discount_type === "%" ? (
                                  <span>
                                    {Math.ceil(
                                      test.price -
                                        (test.price / 100) * test.discount
                                    )}
                                  </span>
                                ) : (
                                  <span>
                                    {Math.ceil(test.price - test.discount)}
                                  </span>
                                )}
                              </span>
                            )}
                          </td>
                          <td data-column="Discount">
                            <div key={test.id}>
                              <input
                                value={
                                  updatedTestInvoice.some(
                                    (el) => el.id === test.id
                                  )
                                    ? updatedTestInvoice.filter(
                                        (o) => o.id === test.id
                                      ).length > 0
                                      ? updatedTestInvoice.filter(
                                          (o) => o.id === test.id
                                        )[0].discount
                                      : ""
                                    : ""
                                }
                                type="number"
                                name="discount"
                                className="form-control"
                                onChange={(e) => handleUpdateTestInput(test, e)}
                              />
                              <select
                                name="type"
                                className="form-control"
                                onChange={(e) => handleUpdateTestInput(test, e)}
                              >
                                <option value="Flat">Flat</option>
                                <option value="%">Percentage</option>
                              </select>
                            </div>
                          </td>
                          <td data-column="Home Delivery">
                            {test.home_delivery ? "Yes" : "No"}
                          </td>

                          <td data-column="Add To Cart">
                            <IconButton
                              onClick={() => addToCart(test, i)}
                              color={
                                tableData.some((el) => el.id === test.id)
                                  ? "secondary"
                                  : "primary"
                              }
                              aria-label="add to shopping cart"
                            >
                              {tableData.some((el) => el.id === test.id) ? (
                                <DeleteOutlineIcon />
                              ) : (
                                <AddShoppingCartIcon />
                              )}
                            </IconButton>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <table className="table table-borderless table-sm test-list">
                    <thead>
                      <tr>
                        <th scope="col">Test Name</th>
                        <th scope="col">Test Price</th>
                        <th scope="col">Discounted Price</th>

                        <th scope="col">Add To Cart</th>
                        <th scope="col">Home Sample</th>
                      </tr>
                    </thead>
                    <tbody>
                      {testList.map((test, i) => (
                        <tr key={i}>
                          <td data-column="Test Name">{test.name}</td>
                          <td data-column="Test Price">
                            {Math.ceil(test.price)}
                          </td>
                          <td data-column="Discounted Price">
                            {test !== undefined && (
                              <span>
                                {test.discount_type === "%" ? (
                                  <span>
                                    {Math.ceil(
                                      test.price -
                                        (test.price / 100) * test.discount
                                    )}
                                  </span>
                                ) : (
                                  <span>
                                    {Math.ceil(test.price - test.discount)}
                                  </span>
                                )}
                              </span>
                            )}
                          </td>

                          <td data-column="Add To Cart">
                            <IconButton
                              classes={{
                                root: classes.root, // class name, e.g. `classes-nesting-root-x`
                              }}
                              onClick={() => addToCart(test, i)}
                              color={
                                tableData.some((el) => el.id === test.id)
                                  ? "secondary"
                                  : "primary"
                              }
                              aria-label="add to shopping cart"
                            >
                              {tableData.some((el) => el.id === test.id) ? (
                                <DeleteOutlineIcon />
                              ) : (
                                <AddShoppingCartIcon />
                              )}
                            </IconButton>
                          </td>
                          <td data-column="Home Sample">
                            {test.home_delivery ? "Yes" : "No"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}

                <div className="row">
                  <Pagination
                    color="primary"
                    count={totalPage}
                    page={currentPage}
                    onChange={handleChangePage}
                  />
                </div>
              </div>
            </div>
          )}

          <Dialog
            fullWidth
            maxWidth={`${isTabMobile ? "sm" : "md"}`}
            open={openDetails}
            onClose={handleCloseDetails}
          >
            <DialogTitle id="max-width-dialog-title">Report</DialogTitle>
            <DialogContent>
              <table className="custom-responsive-table">
                <thead className="">
                  <tr>
                    <th scope="col"> Name</th>
                    <th scope="col"> Home Sample</th>

                    <th scope="col">Regular Price</th>
                    <th scope="col">Discounted Price</th>
                    <th scope="col">Discount</th>

                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((row, index) => (
                    <tr key={index}>
                      <td data-column="Name">{row.name}</td>
                      <td data-column="Home Sample">
                        {" "}
                        {row.home_delivery ? "Yes" : "No"}
                      </td>
                      <td data-column="Regular Price">
                        {row !== undefined && (
                          <span>{Math.ceil(row.price)} </span>
                        )}
                      </td>
                      <td data-column="Discounted Price">
                        {row !== undefined && (
                          <span>
                            {row.discount_type === "%" ? (
                              <span>
                                {Math.ceil(
                                  row.price - (row.price / 100) * row.discount
                                )}
                              </span>
                            ) : (
                              <span>{Math.ceil(row.price - row.discount)}</span>
                            )}
                          </span>
                        )}
                      </td>
                      <td data-column="Discount">
                        <div key={row.id}>
                          <input
                            // defaultValue={
                            //   updatedTestInvoice.some((el) => el.id === test.id)
                            //     ? updatedTestInvoice.filter(
                            //         (o) => o.id === test.id
                            //       ).length > 0
                            //       ? updatedTestInvoice.filter(
                            //           (o) => o.id === test.id
                            //         )[0].discount
                            //       : "0"
                            //     : 0
                            // }
                            defaultValue={
                              row.adminDiscount
                                ? row.adminDiscount.discount
                                : "0"
                            }
                            type="number"
                            name="discount"
                            className="form-control"
                            onChange={(e) => handleUpdateTestInput(row, e)}
                          />
                          <select
                            defaultValue={
                              row.adminDiscount
                                ? row.adminDiscount.type
                                : "Flat"
                            }
                            name="type"
                            className="form-control"
                            onChange={(e) => handleUpdateTestInput(row, e)}
                          >
                            <option value="Flat">Flat</option>
                            <option value="%">Percentage</option>
                          </select>
                        </div>
                      </td>
                      <td data-column="Action">
                        <IconButton
                          onClick={() => handleDelete(index)}
                          color="secondary"
                          aria-label="delete"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    {!isTabMobile && (
                      <React.Fragment>
                        <th scope="col"> </th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                      </React.Fragment>
                    )}

                    <th scope="col">Total Regular Price</th>

                    <th scope="col">{Math.ceil(totalRegularPrice)}</th>
                  </tr>
                  <tr>
                    {!isTabMobile && (
                      <React.Fragment>
                        <th scope="col"> </th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                      </React.Fragment>
                    )}
                    <th scope="col">Total Discount</th>

                    <th scope="col">
                      {totalDiscountPrice + totalAdminDiscountPrice}
                    </th>
                  </tr>
                  <tr>
                    {!isTabMobile && (
                      <React.Fragment>
                        <th scope="col"> </th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                      </React.Fragment>
                    )}
                    <th scope="col">Total Payable</th>
                    <th scope="col">{Math.ceil(totalPrice)}</th>
                  </tr>
                </tbody>
              </table>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleCloseDetails()} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            fullWidth
            maxWidth={`${isTabMobile ? "sm" : "md"}`}
            open={openCheck}
            onClose={handleCloseCheck}
          >
            <DialogTitle id="max-width-dialog-title">Submit</DialogTitle>
            <DialogContent>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
              >
                {({
                  setFieldValue,
                  values,
                  dirty,
                  handleChange,
                  handleSubmit,
                  isValid,
                  setTouched,
                  setFieldError,
                }) => (
                  <Form>
                    <div className="form-group row ">
                      <label className="col-sm-3 offset-sm-1">
                        {" "}
                        Patient's Number <span className="text-danger">
                          *
                        </span>{" "}
                      </label>
                      <div className="col-sm-7">
                        <Field
                          className=" form-control"
                          name="phone"
                          validate={validatePhone}
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="error-message"
                        />
                        <p className="error-message">
                          {backendErrorMessage.phone}
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 my-4">
                        <Collapse in={open}>
                          <Alert
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setOpen(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {successMessage}
                          </Alert>
                        </Collapse>
                        <Collapse in={erroropen}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setErrorOpen(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {errorMessage}
                          </Alert>
                        </Collapse>
                      </div>
                    </div>
                    <div className="form-group row ">
                      <label className="col-sm-3 offset-sm-1"></label>
                      <div className="col-sm-7 text-right">
                        <button
                          disabled={tableData.length === 0}
                          type="submit"
                          // onClick={() => {
                          //   console.log("DOB", dOB, values.date_of_birth);
                          //   setFieldError("age", "ASDSAD");
                          //   // setTouched({
                          //   //   age: true,
                          //   //   date_of_birth: true,
                          //   // });

                          //   if (isValid) {
                          //     handleSubmit();
                          //   }
                          // }}
                          className="btn btn-lg btn-success"
                        >
                          {medId ? "Update" : "Submit"}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleCloseCheck()} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </main>
    </div>
  );
}
