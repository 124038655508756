import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import AddPrescription from "./AddPrescription";
import VideoChat from "./VideoComponents/VideoChat";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function VideoConference() {
  const classes = useStyles();

  // const jitsiContainerId = "jitsi-container-id";
  // const [jitsi, setJitsi] = React.useState({});

  // const loadJitsiScript = () => {
  //   let resolveLoadJitsiScriptPromise = null;

  //   const loadJitsiScriptPromise = new Promise((resolve) => {
  //     resolveLoadJitsiScriptPromise = resolve;
  //   });

  //   const script = document.createElement("script");
  //   script.src = "https://meet.jit.si/external_api.js";
  //   script.async = true;
  //   script.onload = () => resolveLoadJitsiScriptPromise(true);
  //   document.body.appendChild(script);

  //   return loadJitsiScriptPromise;
  // };

  // const initialiseJitsi = async () => {
  //   if (!window.JitsiMeetExternalAPI) {
  //     await loadJitsiScript();
  //   }

  //   const _jitsi = new window.JitsiMeetExternalAPI("meet.jit.si", {
  //     parentNode: document.getElementById(jitsiContainerId),
  //   });

  //   setJitsi(_jitsi);
  // };

  // React.useEffect(() => {
  //   initialiseJitsi();

  //   return () => jitsi?.dispose?.();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // return

  // ;
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AddPrescription />
        </Grid>
        {/* <Grid item xs={5}>
          <Paper className={classes.paper}>
            <VideoChat />aaa 
          
          </Paper>
        </Grid> */}
      </Grid>
    </div>
  );
}
