import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import Pagination from "@material-ui/lab/Pagination";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import _ from "lodash";

export default function HospitalList() {
  const [hospitalList, setHospitalList] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [hospitalId, setHospitalId] = useState(Number);

  const [defaultStatus, setDefaultStatus] = useState("");
  const [selecedStatus, setSelectedStatus] = useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [params, setParams] = useState({
    per_page: 15,
    page: 1,
  });

  useEffect(() => {
    getPatientList();
  }, [params]);

  const getPatientList = () => {
    AdminAPI.get(`hospitals`, { params: params })
      .then((response) => {
        setHospitalList(response.data.data.data);
        setTotalPage(response.data.data.last_page);
        setPage(response.data.data.current_page);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };

  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);
  const updateHospital = (status, id) => {
    console.log(id, status);
    if (status === "Active") {
      setDefaultStatus("Inactive");
      setSelectedStatus("Inactive");
    }
    if (status === "Inactive") {
      setDefaultStatus("Active");
      setSelectedStatus("Active");
    }

    setHospitalId(id);

    setOpenModal(true);
  };

  const changeStatus = (e) => {
    setSelectedStatus(e.target.value);
  };
  const submitStatus = () => {
    let data = {
      status: selecedStatus,
    };
    AdminAPI.post(`hospitals/${hospitalId}/update-status`, data)
      .then((response) => {
        alert("You have successfully updated the hospital status");
        getPatientList();
        setOpenModal(false);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  return (
    <div>
      <div className="row">
        <div className="col-md-3 form-group">
          <select
            className="form-control"
            onChange={(e) => {
              handleColumnWiseSearch(e.target.value, "status");
            }}
          >
            <option value="">All</option>
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
          </select>
        </div>
        <div className="col-md-7 form-group">
          <input
            placeholder="Search Hospital"
            className="form-control"
            type="text"
            onChange={(e) => handleColumnWiseSearch(e.target.value, "search")}
          />
        </div>
        <div className="col-md-2 text-right">
          <Link
            className="btn btn-outline-secondary"
            type="button"
            to={"/admin/hospital/add"}
          >
            New Hospital
          </Link>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className=" table-responsive">
            <table className="table table-striped table-bordered">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Contact </th>
                  <th scope="col">Address</th>
                  <th scope="col">Status</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {hospitalList.map((hospital, i) => (
                  <tr key={i}>
                    <td>{hospital.name}</td>
                    <td>{hospital.email}</td>
                    <td>{hospital.contact_person}</td>
                    <td>
                      {hospital.address}, {hospital.police_station.name},{" "}
                      {hospital.police_station.district}
                    </td>
                    <td>
                      {hospital.status == "Active" ? (
                        <button
                          type="button"
                          onClick={() =>
                            updateHospital(hospital.status, hospital.id)
                          }
                          className="btn btn-sm btn-info"
                        >
                          {hospital.status === "Active" && "Active"}
                          {hospital.status === "Inactive" && "Inactive"}
                          {hospital.status === "" && "Not Available"}
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() =>
                            updateHospital(hospital.status, hospital.id)
                          }
                          className="btn btn-sm btn-warning"
                        >
                          {hospital.status === "Active" && "Active"}
                          {hospital.status === "Inactive" && "Inactive"}
                          {hospital.status === "" && "Not Available"}
                        </button>
                      )}
                    </td>

                    <td>
                      <Link
                        to={`/admin/hospital/view/${hospital.id}`}
                        type="button"
                        className="btn btn-sm btn-primary mb-1"
                      >
                        <VisibilityIcon />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="row">
        <Pagination
          color="primary"
          count={totalPage}
          page={page}
          onChange={handleChange}
        />
      </div>
      <Dialog
        fullWidth
        maxWidth={`sm`}
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <DialogTitle id="max-width-dialog-title">
          Change Hospital Status
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <select
              className=" form-control"
              defaultValue={defaultStatus}
              onChange={(event) => changeStatus(event)}
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)} color="primary">
            Close
          </Button>
          <Button onClick={() => submitStatus()} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
