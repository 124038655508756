import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { useParams, useHistory, Link } from "react-router-dom";
import LockOpenIcon from "@material-ui/icons/LockOpen";

// import axios from "axios";
import { PatientAPI } from "../../AxiosRequest/PatientAPI";
import "./css/patientLogin.css";
import Navbar2 from "../Shared/Navbar2";
import _, { isObject } from "lodash";

export default function ForgetPassword({ hideNavBar = false }) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errmsg, setErrmsg] = useState("");
  const [showPostOTP, setShowPostOTP] = React.useState(false);
  const [countDown, setCountDown] = React.useState("");
  //   console.log("form data", formData);

  // Backend Error Messages

  const [errorMessage, setErrorMessage] = useState({});

  function validateRequirement(value) {
    let error;

    if (!value) {
      error = "Required!";
    }

    return error;
  }
  const validationSchemaBeforeCode = Yup.object({
    phone: Yup.string()
      .required("Please enter your phone number")
      .typeError("This field is required")
      .matches(/^(01){1}[3-9]{1}\d{8}$/, "Enter a valid Mobile Number"),
  });
  const validationSchemaAfterCode = Yup.object({
    phone: Yup.string()
      .required("Please enter your phone number")
      .typeError("This field is required")
      .matches(/^(01){1}[3-9]{1}\d{8}$/, "Enter a valid Mobile Number"),
    code: Yup.string().required("Code field is required"),
    new_password: Yup.string()
      .min(6, "Password must contain at least 6 characters")
      .required("Enter your password"),
    confirm_password: Yup.string()
      .required("Confirm your password")
      .oneOf([Yup.ref("new_password")], "Password does not match"),
  });

  const initialValues = {
    phone: "",
    code: "",
    new_password: "",
    confirm_password: "",
  };
  const postOTPAgain = (values) => {
    // setLoading(true);
    setCode("");
    setShowPostOTP(false);
    handlePostOTP(values);
  };

  const handlePostOTP = (values) => {
    PatientAPI.post(`auth/forgot-password`, {
      phone: values.phone,
    })
      .then((response) => {
        console.log("response=====>", response);
        setLoading(false);
        setCode(response.data.message);
        setErrmsg("");

        setErrorMessage({
          phone: "",
        });
        var timeLeft = 30;

        var timerId = setInterval(countdown, 1000);

        function countdown() {
          if (timeLeft === -1) {
            clearTimeout(timerId);
            setShowPostOTP(true);
          } else {
            setCountDown(timeLeft);
            timeLeft--;
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log("error from login", error.response);
        setLoading(false);
        if (error.response.data.message) setErrmsg(error.response.data.message);
        if (error.response.data.errors !== undefined) {
          setErrorMessage(error.response.data.errors);
        }
      });
  };
  const onSubmit = (values, { resetForm }) => {
    setLoading(true);
    console.log(values);
    if (code === "") {
      postOTPAgain(values);
    } else {
      PatientAPI.post(`auth/set-password`, values)
        .then((response) => {
          console.log(response);
          console.log(response.data.message);

          setErrmsg("");
          setSuccessMessage(response.data.message);
          setLoading(false);
          setTimeout(() => {
            //   history.push("/admin/user/list");
          }, 1000);
        })
        .catch((error) => {
          console.log(error.response);
          setLoading(false);
          if (error.response.data.message)
            setErrmsg(error.response.data.message);
          if (error.response.data.errors !== undefined) {
            setErrorMessage(error.response.data.errors);
          }
        });
    }
    //
  };
  return (
    <div>
      <Navbar2 />

      <div className={!hideNavBar ? "login-section" : ""}>
        <div className="container">
          <div className="row">
            <div className={!hideNavBar ? "col-lg-3" : ""}></div>
            <div className={!hideNavBar ? "col-lg-6" : "col-lg-12"}>
              <div className="login-form-section">
                <div className="text-center">
                  <LockOpenIcon />
                </div>

                <h3 className="text-center py-2">Forget Password</h3>
                <Formik
                  initialValues={initialValues}
                  validationSchema={
                    code === ""
                      ? validationSchemaBeforeCode
                      : validationSchemaAfterCode
                  }
                  onSubmit={onSubmit}
                >
                  {({ setFieldValue, isValid, dirty, values }) => (
                    <Form>
                      <div className="form-group row ">
                        <label className="col-sm-3 offset-sm-1 col-form-label">
                          Phone
                        </label>
                        <div className="col-sm-7">
                          <Field
                            name="phone"
                            type="text"
                            className="form-control"
                            placeholder="Your Phone Number"
                          />{" "}
                          {code === "" && (
                            <p style={{ fontSize: "12px" }}>
                              Please Enter your registered phone number to get
                              an otp code via sms
                            </p>
                          )}
                          {code !== "" && (
                            <>
                              <p style={{ marginBottom: 0 }}>{code}</p>
                              <div
                                style={{ marginTop: "-.25px" }}
                                className="text-right"
                              >
                                <button
                                  onClick={() => postOTPAgain(values)}
                                  type="button"
                                  disabled={!showPostOTP}
                                  className="btn btn-sm btn-primary text-right"
                                >
                                  Sent OTP Again{" "}
                                  {countDown === 0 ? (
                                    ""
                                  ) : (
                                    <span> ( {countDown} )</span>
                                  )}
                                </button>
                              </div>
                            </>
                          )}
                          <ErrorMessage
                            name="phone"
                            component="div"
                            className="error-message"
                          />
                          <p className="error-message">{errorMessage.phone}</p>
                        </div>
                      </div>

                      {code === "" ? (
                        <></>
                      ) : (
                        <React.Fragment>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label">
                              Code
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="code"
                                type="text"
                                className="form-control"
                                placeholder="Enter Your OTP here"
                              />
                              <ErrorMessage
                                name="code"
                                component="div"
                                className="error-message"
                              />
                              <p className="error-message">
                                {errorMessage.code}
                              </p>
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label ">
                              Password
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="new_password"
                                type="password"
                                className="form-control "
                                placeholder="password"
                              />
                              <ErrorMessage
                                name="new_password"
                                component="div"
                                className="error-message"
                              />
                              <p className="error-message">
                                {errorMessage.new_password}
                              </p>
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label ">
                              Confirm Password
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="confirm_password"
                                type="password"
                                className="form-control "
                                placeholder="password"
                              />
                              <ErrorMessage
                                name="confirm_password"
                                component="div"
                                className="error-message"
                              />
                              <p className="error-message">
                                {errorMessage.confirm_password}
                              </p>
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label "></label>
                            <div className="col-sm-7">
                              {errmsg !== "" && (
                                <p className=" error-message">{errmsg}</p>
                              )}
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label "></label>
                            <div className="col-sm-7">
                              {successMessage !== "" && (
                                <>
                                  <p>
                                    {successMessage}. Please press Login below
                                    to continue with your updated password
                                  </p>

                                  <Link
                                    to="/patient/login"
                                    type="button"
                                    className="btn btn-lg btn-primary btn-block"
                                  >
                                    Login
                                  </Link>
                                </>
                              )}
                            </div>
                          </div>
                        </React.Fragment>
                      )}

                      <div className="form-group row ">
                        <label className="col-sm-5 offset-sm-1">
                          <button
                            disabled={loading}
                            // disabled={!isValid || !dirty}
                            type="submit"
                            className="btn btn-lg btn-success"
                          >
                            {loading && (
                              <i className="fa fa-refresh fa-spin"></i>
                            )}
                            Submit
                          </button>
                        </label>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <div className={"col-lg-3"}></div>
          </div>
        </div>
      </div>
    </div>
  );
}
