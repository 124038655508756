import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";

import LockOpenIcon from "@material-ui/icons/LockOpen";
import Switch from "@material-ui/core/Switch";
import pharmacyAuth from "../../routingRules/pharmacyAuth";

// import axios from "axios";
import { PharmacyAPI } from "../../AxiosRequest/PharmacyAPI";
import * as Yup from "yup";
import "./css/pharmacyLogin.css";
import Navbar2 from "../Shared/Navbar2";

export default function PharmacyLogin() {
  let history = useHistory();
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  });
  const [loginErrors, setLoginErrors] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Please Enter a valid Email")
      .required("Email field is required"),
    password: Yup.string()
      .min(6, "Password must contain at least 6 characters")
      .required("Enter your password"),
  });
  const initialValues = {
    email: "",
    password: "",
  };

  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    setLoading(true);

    PharmacyAPI.post("auth/login", {
      email: values.email,
      password: values.password,
    })
      .then((response) => {
        console.log(response);
        let pharmacyToken = response.data.access_token;
        localStorage.setItem("pharmacyToken", JSON.stringify(pharmacyToken));
        console.log(pharmacyToken);
        setLoading(false);
        pharmacyAuth.login(() => {
          history.push("/pharmacy");
        });
      })
      .catch((error) => {
        if (error.response.data.error !== undefined) {
          setLoginErrors(error.response.data.error);
        }

        console.log(error.response);
        setLoading(false);
      });
  };
  return (
    <div>
      <Navbar2 />
      <div className="login-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-3"></div>
            <div className="col-lg-6">
              <div className="login-form-section">
                <div className="text-center">
                  <LockOpenIcon />
                </div>
                <h3 className="text-center py-2">HealthCare</h3>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  <Form>
                    <div className="form-group row ">
                      <div className="col-sm-12">
                        <Field
                          name="email"
                          type="text"
                          className="form-control"
                          placeholder="email"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>
                    <div className="form-group row ">
                      <div className="col-sm-12">
                        <Field
                          name="password"
                          type="password"
                          className="form-control"
                          placeholder="password"
                        />

                        <ErrorMessage
                          name="password"
                          component="div"
                          className="error-message"
                        />

                        <p className="error-message"> {loginErrors}</p>
                        <p className="error-message"> {loginErrors.email}</p>
                        <p className="error-message"> {loginErrors.phone}</p>
                        <p className="error-message"> {loginErrors.password}</p>
                      </div>
                    </div>
                    <div className="form-group row ">
                      <div className="col-sm-6">
                        <Switch
                          checked={state.checkedB}
                          onChange={handleChange}
                          color="primary"
                          name="checkedB"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />{" "}
                        Remember Me
                      </div>
                      <div className="col-sm-6">
                        <button
                          type="submit"
                          className="btn btn-block btn-primary"
                          disabled={loading}
                        >
                          {loading && <i className="fa fa-refresh fa-spin"></i>}
                          {loading && <span>Signing In</span>}
                          {!loading && <span> Sign In</span>}
                        </button>
                      </div>
                    </div>
                    <div className="form-group row ">
                      <div className="col-sm-8">
                        Forgot your password?{" "}
                        <a type="button" href="#reset">
                          Reset
                        </a>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
            <div className="col-lg-3"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
