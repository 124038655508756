import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import Pagination from "@material-ui/lab/Pagination";
import _ from "lodash";
export default function TestList() {
  const [testList, setTestList] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [params, setParams] = useState({
    per_page: 15,
    page: 1,
  });

  useEffect(() => {
    getTestList();
  }, [params]);

  const getTestList = () => {
    AdminAPI.get(`tests`, { params: params })
      .then((response) => {
        setTestList(response.data.data.data);
        setTotalPage(response.data.data.last_page);
        setPage(response.data.data.current_page);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };

  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 text-left">
            <div className="form-group">
              <input
                placeholder="Search Test"
                className="form-control"
                type="text"
                onChange={(e) =>
                  handleColumnWiseSearch(e.target.value, "search")
                }
              />
            </div>
          </div>
          <div className="col-md-6 text-right">
            <div className="form-group">
              <Link
                to="/admin/test/add"
                type="button"
                className="btn btn-md btn-primary"
              >
                Add Test
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-4">
            <div className=" table-responsive">
              <table className="table table-sm table-striped custom-sm table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col"> Name</th>
                    <th scope="col"> Department</th>
                    {/* <th scope="col">Required Elements</th> */}
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {testList.map((test, i) => (
                    <tr key={i}>
                      <td>{test.name}</td>
                      <td>{test.department}</td>
                      {/* <td>{test.required_elements}</td> */}
                      <td>{test.status}</td>

                      <td>
                        <Link
                          type="button"
                          to={`/admin/test/update-test/${test.id}`}
                          className="btn btn-sm btn-info"
                        >
                          Update
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}
