import React, { useState, useEffect } from "react";
import { TextField } from "formik-material-ui";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import axios from "axios";
import * as Yup from "yup";
import { PharmacyAPI } from "../../AxiosRequest/PharmacyAPI";
import Navbar2 from "../Shared/Navbar2";
export default function PharmacyRegistration() {
  const [policeStations, setPoliceStations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [signedUpMessage, setSignedUpMessage] = useState("");
  const [singnedUp, setSignedUp] = useState(false);

  // Backend Error Messages

  const [errorMessage, setErrorMessage] = useState({});

  useEffect(() => {
    GetPoliceStations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const GetPoliceStations = () => {
    axios
      .get("police-stations")
      .then((response) => {
        console.log(response.data.data);
        setPoliceStations(response.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name field is required"),
    phone: Yup.string().required("Please enter your phone number"),
    email: Yup.string()
      .email("Please Enter a valid Email")
      .required("Email field is required"),
    contact_person: Yup.string().required(
      "Please enter your contact person number"
    ),
    license_number: Yup.string().required("Please enter your license number"),
    address: Yup.string().required("Please enter your address"),
    password: Yup.string()
      .min(6, "Password must contain at least 6 characters")
      .required("Enter your password"),
    confirm_password: Yup.string()
      .required("Confirm your password")
      .oneOf([Yup.ref("password")], "Password does not match"),

    police_station_id: Yup.string().required(
      "Police Station field is required"
    ),
  });
  const initialValues = {
    name: "",
    phone: "",
    email: "",
    contact_person: "",
    license_number: "",
    address: "",
    police_station_id: "",
    password: "",
    confirm_password: "",
  };

  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    setLoading(true);
    PharmacyAPI.post("auth/register", {
      name: values.name,
      phone: values.phone,
      email: values.email,
      contact_person: values.contact_person,
      license_number: values.license_number,
      address: values.address,
      police_station_id: values.police_station_id,
      password: values.password,
      confirm_password: values.confirm_password,
    })
      .then((response) => {
        console.log(response);
        console.log(response.data.message);
        setSignedUp(true);
        setSignedUpMessage(
          "You have successfully signed up. Press login to continue"
        );
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response);
        setLoading(false);

        if (error.response.data.errors !== undefined) {
          setErrorMessage(error.response.data.errors);
        }
      });
  };
  return (
    <div>
      <Navbar2 />
      <div className="doc-register-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-3"></div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-md-12">
                  <div className="doc-register-form">
                    <div className="text-center">
                      <LockOpenIcon />
                    </div>
                    <h1 className="text-center">HealthCare</h1>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      {({ setFieldValue, isValid, dirty }) => (
                        <Form>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label">
                              Name
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="name"
                                type="text"
                                className="form-control"
                                placeholder="Your Name"
                              />
                              <ErrorMessage
                                name="name"
                                component="div"
                                className="error-message"
                              />
                              <p className="error-message">
                                {errorMessage.name}
                              </p>
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label">
                              Phone
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="phone"
                                type="text"
                                className="form-control"
                                placeholder="Phone/Mobile Number"
                              />
                              <ErrorMessage
                                name="phone"
                                component="div"
                                className="error-message"
                              />
                              <p className="error-message">
                                {errorMessage.phone}
                              </p>
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label">
                              Email
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="email"
                                type="email"
                                className="form-control"
                                placeholder="Email"
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="error-message"
                              />
                              <p className="error-message">
                                {errorMessage.email}
                              </p>
                            </div>
                          </div>

                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label">
                              Contact Person
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="contact_person"
                                type="text"
                                className="form-control"
                                placeholder="Contact Person"
                              />
                              <ErrorMessage
                                name="contact_person"
                                component="div"
                                className="error-message"
                              />
                              <p className="error-message">
                                {errorMessage.contact_person}
                              </p>
                            </div>
                          </div>

                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label">
                              License Number
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="license_number"
                                type="text"
                                className="form-control"
                                placeholder=" License Number"
                              />
                              <ErrorMessage
                                name="license_number"
                                component="div"
                                className="error-message"
                              />
                              <p className="error-message">
                                {errorMessage.license_number}
                              </p>
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label">
                              Address
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="address"
                                type="text"
                                className="form-control"
                                placeholder="Your Address"
                              />
                              <ErrorMessage
                                name="address"
                                component="div"
                                className="error-message"
                              />
                              <p className="error-message">
                                {errorMessage.address}
                              </p>
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label">
                              Police Stations
                            </label>
                            <div className="col-sm-7">
                              <Autocomplete
                                id="disabledHelperText"
                                name="police_station_id"
                                options={policeStations}
                                getOptionLabel={(option) => option.name}
                                onChange={(e, value) => {
                                  if (value == null) {
                                    console.log("Hello");
                                    setFieldValue("police_station_id", "");
                                  } else {
                                    console.log(value);
                                    console.log(value.id);
                                    setFieldValue(
                                      "police_station_id",
                                      value.id
                                    );
                                  }
                                }}
                                renderInput={(params) => (
                                  <Field
                                    component={TextField}
                                    {...params}
                                    name="police_station_id"
                                    variant="outlined"
                                    fullWidth
                                  />
                                )}
                              />
                              <ErrorMessage
                                name="police_station_id"
                                component="div"
                                className="error-message"
                              />
                              <p className="error-message">
                                {errorMessage.police_station_id}
                              </p>
                            </div>
                          </div>

                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label ">
                              Password
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="password"
                                type="password"
                                className="form-control "
                                placeholder="password"
                              />
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="error-message"
                              />
                              <p className="error-message">
                                {errorMessage.password}
                              </p>
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label ">
                              Confirm Password
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="confirm_password"
                                type="password"
                                className="form-control "
                                placeholder="password"
                              />
                              <ErrorMessage
                                name="confirm_password"
                                component="div"
                                className="error-message"
                              />
                              <p className="error-message">
                                {errorMessage.confirm_password}
                              </p>
                            </div>
                          </div>

                          <div className="form-group row ">
                            <label className="col-sm-5 offset-sm-1">
                              <button
                                disabled={loading}
                                // disabled={!isValid || !dirty}
                                type="submit"
                                className="btn btn-lg btn-success"
                              >
                                {loading && (
                                  <i className="fa fa-refresh fa-spin"></i>
                                )}
                                {loading && <span>Signing Up</span>}
                                {!loading && <span> Sign Up</span>}
                              </button>
                            </label>
                            <div className="col-sm-7"></div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-11 offset-sm-1">
                              {signedUpMessage}
                              {singnedUp && (
                                <Link
                                  to="/diagnostic/login"
                                  type="button"
                                  className="btn btn-lg btn-primary"
                                >
                                  Login
                                </Link>
                              )}
                            </label>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </div>
    </div>
  );
}
