import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Pagination from "@material-ui/lab/Pagination";
import Axios from "axios";
import _ from "lodash";
import * as moment from "moment";
import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import { PatientAPI } from "../../AxiosRequest/PatientAPI";

// import createDOMPurify from 'dompurify'
// import { JSDOM } from 'jsdom'

export default function Appointments({ onlineDocs }) {
  // const window = new JSDOM("").window;
  // const DOMPurify = createDOMPurify(window);

  let history = useHistory();

  const [appointmentList, setAppointmentList] = useState([]);
  const [prescriptionId, setPrescriptionId] = useState();

  const [buttonClicked, setButtonClicked] = useState(false);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [payMentUrl, setPaymentUrl] = useState("");
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [onlineDocsIDs, setOnlineDocsIDs] = React.useState([]);

  useEffect(() => {
    if (onlineDocs && onlineDocs.length > 0) {
      const oP = [...onlineDocs];
      let ids = oP.map((o) => o.id);
      setOnlineDocsIDs(ids);
    } else {
      setOnlineDocsIDs([]);
    }
  }, [onlineDocs]);
  console.log("heonlinePatientsr childdddde", onlineDocsIDs);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [params, setParams] = useState({
    per_page: 15,
    page: 1,
  });

  useEffect(() => {
    PatientAPI.get(`/appointments`, { params: params })
      .then((response) => {
        setAppointmentList(response.data.data.data);
        setTotalPage(response.data.data.last_page);
        setPage(response.data.data.current_page);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, [params]);

  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };
  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);

  const changeStatus = (event) => {
    // setAppointmentStatus(event.target.value);
    handleColumnWiseSearch(event.target.value, "status");
  };

  const routeToPrescription = (id) => {
    console.log(id);
    history.push(`/doctor/video-conference/${id}`);
    if (id !== prescriptionId) {
      setButtonClicked(true);
    }
  };
  const routeToShowPrescription = (id) => {
    history.push(`/doctor/prescription-details/${id}`);
  };
  const submitPayment = (id) => {
    PatientAPI.post(`/payments/create`, { appointment_id: id })
      .then((response) => {
        let appointment = response.data.data;
        let paymentURL = `${process.env.REACT_APP_PAYMENT_URL}/patient/appointment-payment/${id}`;
        window.open(paymentURL, "_self"); //to open new page
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-12 text-right">
            <select
              className=" form-control"
              onChange={(event) => changeStatus(event)}
            >
              <option value="">All</option>
              <option value="Pending">Pending</option>
              <option value="Accepted">Accepted</option>
              <option value="Paid">Upcoming</option>
              <option value="Rejected">Rejected</option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <table className="custom-responsive-table">
              <thead>
                <tr>
                  <th scope="col">Time</th>
                  <th scope="col">Doctor's Name</th>
                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {appointmentList.map((appointment, i) => (
                  <tr key={i}>
                    <td data-column="Time">
                      {moment(appointment.appointment_time).format(
                        "DD/MM/YYYY hh:mm:ss A"
                      )}
                    </td>
                    <td data-column="Doctor's Name">
                      {appointment.doctor.name} ({appointment.doctor.department}
                      )
                      {onlineDocsIDs.includes(appointment.doctor_id) && (
                        <span style={{ color: "green" }}> (Online) </span>
                      )}
                    </td>

                    <td data-column="Status">
                      {appointment.status === "Paid"
                        ? "Upcoming"
                        : appointment.status}
                    </td>
                    <td data-column="Actions">
                      <div>
                        {appointment.status === "Paid" &&
                          appointment.twilio_token !== null &&
                          appointment.twilio_token.doctor_started_at !== null &&
                          appointment.twilio_token.end_at === null && (
                            <Link
                              type="button"
                              to={`/patient/support/start-video/${appointment.id}`}
                              className="btn btn-outline-primary btn-sm"
                            >
                              Join Call
                            </Link>
                          )}
                        {appointment.status === "Prescribed" &&
                          appointment.prescription &&
                          appointment.prescription !== undefined && (
                            <Link
                              type="button"
                              to={`/patient/prescription/${appointment.prescription.id}`}
                              className="btn btn-outline-primary"
                            >
                              Show Prescription
                            </Link>
                          )}
                        {appointment.status === "Accepted" && (
                          <button
                            type="button"
                            onClick={() => submitPayment(appointment.id)}
                            className="btn btn-outline-primary"
                          >
                            Pay
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* <div className="row">{payMentUrl}</div> */}
        {/* <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{"Payment"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div dangerouslySetInnerHTML={{ __html: `${payMentUrl}` }} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              Disagree
            </Button>
            <Button onClick={handleClose} color="primary" autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog> */}

        <div className="row">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}
