import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";

export default function UploadedPrescriptions() {
  let history = useHistory();
  const [prescriptionList, setPrescriptionList] = useState([]);
  const [params, setParams] = useState({
    type: "Prescription",
    per_page: 15,
    page: 1,
  });

  useEffect(() => {
    AdminAPI.get(`uploaded-prescriptions`, {params: params})
      .then((response) => {
        console.log(response.data.data.data);
        setPrescriptionList(response.data.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  const routeToShowPrescription = (id) => {
    history.push(`/admin/prescription/view/${id}`);
  };

  return (
    <div>
      <div className="container">
        <table className="custom-responsive-table">
          <thead>
            <tr>
              <th scope="col">Uploaded Time</th>
              <th scope="col">Patient's Name</th>
              <th scope="col">Contact</th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {prescriptionList.map((prescription, i) => (
              <tr key={i}>
                <td data-column="Time">
                  {moment(prescription.created_at).format(
                    "DD/MM/YYYY HH:mm:ss A"
                  )}
                </td>
                <td data-column="Patient's Name">
                  {prescription.patient.phone}
                </td>

                <td data-column="Contact">{prescription.patient.name}</td>
                <td data-column="Contact">{prescription.status}</td>
                <td data-column="Action">
                  <button
                    onClick={() => routeToShowPrescription(prescription.id)}
                    className="btn btn-sm btn-outline-success"
                  >
                    Uploaded File
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
