import { Box } from "@material-ui/core";
import axios from "axios";
import { isEmpty } from "lodash";

import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

const PaymentSuccess = () => {
  const patientToken = JSON.parse(localStorage.getItem("patientToken"));
  const [docDetails, setDocDetails] = useState({});
  let { id } = useParams();

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = () => {
    axios
      .get(`patients/${id}`)
      .then((response) => {
        console.log("doc details", response.data.data);
        setDocDetails(response.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"80vh"}
    >
      {!isEmpty(docDetails) ? (
        <div className="payment_card">
          <div className="card">
            <div className="check">
              <i className="checkmark">✓</i>
            </div>
            <h2> Congratulations!</h2>

            <h4> {docDetails.name}!</h4>

            <h1>Payment Successful</h1>

            <p>We received your purchase request.</p>
            {!patientToken && (
              <>
                <p>Press the button below to login !</p>
                <Link to="/patient/login" className="btn btn-block btn-info ">
                  Login
                </Link>
              </>
            )}
            {patientToken && (
              <>
                <p>Press the button below to go back to your dashboard !</p>
                <Link
                  to="/patient/patient-portal"
                  className="btn btn-block btn-info "
                >
                  Dashboard
                </Link>
              </>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default PaymentSuccess;
