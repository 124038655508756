import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import jwt_decode from "jwt-decode";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import Switch from "@material-ui/core/Switch";
import adminAuth from "../../routingRules/adminAuth";

import { AdminAPI } from "../../AxiosRequest/AdminAPI";
import * as Yup from "yup";
import "./css/admin-login.css";
import Navbar2 from "../Shared/Navbar2";

export default function AdminLogin() {
  let history = useHistory();
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  });
  const [loginErrors, setLoginErrors] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Please Enter a valid Email")
      .required("Email field is required"),
    password: Yup.string()
      .min(6, "Password must contain at least 6 characters")
      .required("Enter your password"),
  });
  const initialValues = {
    email: "",
    password: "",
  };

  const onSubmit = (values, { resetForm }) => {
    setLoading(true);

    AdminAPI.post("auth/login", {
      email: values.email,
      password: values.password,
    })
      .then((response) => {
        let adminToken = response.data.data.access_token;
        localStorage.setItem("adminToken", JSON.stringify(adminToken));
        localStorage.setItem(
          "adminId",
          JSON.stringify(response.data.data.user_id)
        );
        setLoading(false);
        var token = adminToken;
        var decoded = jwt_decode(token);
        let sub = decoded.sub;
        // console.log("isSub", sub);
        localStorage.setItem("isSub_heal_holmes", JSON.stringify(sub));
        adminAuth.login(() => {
          history.push("/admin");
        });
      })
      .catch((error) => {
        if (error.response !== undefined) {
          let loginErrors = error.response.data.error;
          setLoginErrors(loginErrors);
        }
        setLoading(false);
      });
  };
  return (
    <div>
      <Navbar2 />
      <div className="login-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-3"></div>
            <div className="col-lg-6">
              <div className="login-form-section">
                <div className="text-center">
                  <LockOpenIcon />
                </div>
                <h3 className="text-center py-2">HealthCare</h3>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  <Form>
                    <div className="form-group row ">
                      <div className="col-sm-12">
                        <Field
                          name="email"
                          type="text"
                          className="form-control"
                          placeholder="email"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>
                    <div className="form-group row ">
                      <div className="col-sm-12">
                        <Field
                          name="password"
                          type="password"
                          className="form-control"
                          placeholder="password"
                        />

                        <p className="error-message"> {loginErrors}</p>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>
                    <div className="form-group row ">
                      <div className="col-sm-6">
                        <Switch
                          checked={state.checkedB}
                          onChange={handleChange}
                          color="primary"
                          name="checkedB"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />{" "}
                        Remember Me
                      </div>
                      <div className="col-sm-6">
                        <button
                          type="submit"
                          className="btn btn-block btn-primary"
                          disabled={loading}
                        >
                          {loading && <i className="fa fa-refresh fa-spin"></i>}
                          {loading && <span>Signing In</span>}
                          {!loading && <span> Sign In</span>}
                        </button>
                      </div>
                    </div>
                    <div className="form-group row ">
                      <div className="col-sm-8">
                        Forgot your password?{" "}
                        <a type="button" href="#reset">
                          Reset
                        </a>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
            <div className="col-lg-3"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
