import React, { useState, useEffect } from "react";
import {
  useParams,
} from "react-router-dom";
import { Link } from "react-router-dom";
import moment from "moment";
import * as Yup from "yup";
import { Layout } from "antd";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";

const { Content } = Layout;

export default function PaymentView() {
    let { id } = useParams();
    const [paymentData, setPaymentData] = useState({});
    useEffect(() => {
        if (id) {
          getPaymentDetail();
        }
      }, []);
      const getPaymentDetail = () => {
        AdminAPI.get(`payments/${id}`)
          .then((response) => {
            if (response.data) {
                setPaymentData(response.data.data);
                console.log(response.data.data);
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
    };
    return (
        <div>
            <div className="row">
                <div className="col-md-12 text-right">
                    <Link
                        to="/admin/payment/list"
                        type="button"
                        className="btn btn-sm btn-success"
                    >
                    Payment List
                    </Link>
                </div>
            </div>
            <div className="row my-2">
                <div className="col-12">
                    <div className="form-group row">
                        <div className="col-md-4">
                            Trx ID: <span className="font-weight-bold">{paymentData.tran_id}</span>
                        </div>
                        <div className="col-md-3">
                            Amount: <span className="font-weight-bold">{paymentData.product_name}</span>
                        </div>
                        <div className="col-md-5">
                            Date: <span className="font-weight-bold">{moment(paymentData.created_at).format("DD/MM/YYYY HH:mm A")}</span>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-md-4">
                            Paid amount: <span className="font-weight-bold">{paymentData.total_amount}</span>
                        </div>
                        <div className="col-md-3">
                            Fee: <span className="font-weight-bold">{paymentData.store_amount}</span>
                        </div>
                        <div className="col-md-5">
                            Card type: <span className="font-weight-bold">{paymentData.card_type}</span>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-md-4">
                            Patient: <span className="font-weight-bold">{paymentData.cus_name}</span>
                        </div>
                        <div className="col-md-3">
                            Phone: <span className="font-weight-bold">{paymentData.cus_phone}</span>
                        </div>
                        <div className="col-md-5">
                            Address: <span className="font-weight-bold">{paymentData.cus_add1}</span>
                        </div>
                    </div>
                    {paymentData.appointment != null && paymentData.appointment.doctor != undefined &&
                        <div className="form-group row">
                            <div className="col-md-4">
                                Doctor: <span className="font-weight-bold">{paymentData.appointment.doctor.name}</span>
                            </div>
                            <div className="col-md-3">
                                Department: <span className="font-weight-bold">{paymentData.appointment.doctor.department}</span>
                            </div>
                            <div className="col-md-5">
                                Institution: <span className="font-weight-bold">{paymentData.appointment.doctor.institution}</span>
                            </div>
                        </div>
                    }
                    {paymentData.appointment != null &&
                        <div className="form-group row">
                            <div className="col-md-4">
                                Appointment: <span className="font-weight-bold">{paymentData.appointment.unique_id}</span>
                            </div>
                            <div className="col-md-3">
                                Time: <span className="font-weight-bold">{moment(paymentData.appointment.appointment_time).format("DD/MM/YYYY HH:mm A")}</span>
                            </div>
                        </div>
                    }
                    <div className="form-group row">
                        <div className="col-md-4">
                            Status: <span className="font-weight-bold">{paymentData.status}</span>
                        </div>
                        <div className="col-md-3">
                            Trx status: <span className="font-weight-bold">{paymentData.trx_status}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
