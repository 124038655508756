import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import { DiagnosticAPI } from "../../../AxiosRequest/DiagnosticAPI";
import axios from "axios";
export default function IncomingInvoiceDetail() {
  let history = useHistory();
  let { id } = useParams();
  const [testDetails, setTestDetails] = useState({});
  const [state, setState] = useState({});
  const [path, setPath] = useState();
  const [file, setFile] = useState("");
  // const [path, setPath] = useState();

  useEffect(() => {
    getTestDetails();
  }, []);

  const getTestDetails = () => {
    DiagnosticAPI.get(`incoming-invoices/${id}`)
      .then((response) => {
        setTestDetails(response.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const handleClick = (id) => {
    setState({
      EDIT: id,
    });
  };

  const downloadPDF = (id, type) => {
    DiagnosticAPI.get(`test-invoices/${id}/pdf`, {
      method: "GET",
      responseType: "blob", //Force to receive data in a Blob Format
    })
      .then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" }); //Build a URL from the file
        const fileURL = URL.createObjectURL(file); //Open the URL on new Window
        if (type === "download") {
          var a = document.createElement("a");
          a.href = fileURL;
          if (testDetails.pdf !== null) {
            a.download = testDetails.pdf;
          } else {
            a.download = "heal-holmes-invoice_" + id + ".pdf";
          }
          a.click();
          window.URL.revokeObjectURL(fileURL);
        } else {
          window.open(fileURL);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const download = (report) => {
    axios
      .get(`files/incoming-invoice-details/${id}/${report}`, {
        responseType: "blob",
      })
      .then((response) => {
        const file = new Blob([response.data]); //Build a URL from the file
        const fileURL = window.URL.createObjectURL(file); //Open the URL on new Window
        var a = document.createElement("a");
        a.href = fileURL;
        a.download = report;
        a.click();
        window.URL.revokeObjectURL(fileURL);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const uploadFile = (id) => {
    let formData = new FormData();
    formData.append("report", file);

    DiagnosticAPI.post(`incoming-invoice-details/${id}/upload-report`, formData)
      .then((response) => {
        console.log("response data", response.data.message);
        alert(response.data.message);
        getTestDetails();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log("test details", testDetails);
  const uploadMultipleFiles = async (e) => {
    const fileList = Array.from(e.target.files);
    if (checkMimeType(e)) {
      setFile(fileList[0]);
    }
  };

  const checkMimeType = (event) => {
    //getting file object
    let files = event.target.files;
    //define message container
    let err = "";
    // list allow mime type
    //  "application/pdf"
    const types = ["image/png", "image/jpeg", "image/gif", "application/pdf"];
    // loop access array
    for (var x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every((type) => files[x].type !== type)) {
        // create error message and assign to container
        err += files[x].type + " is not a supported format\n";
      }
    }

    if (err !== "") {
      // if message not same old that mean has error
      event.target.value = null; // discard selected file
      alert(err);
      return false;
    }
    return true;
  };
  return (
    <div>
      <div className="container">
        <div className="observation-test-area">
          <div className="form-group row"></div>
          <div className="form-group row">
            <div className="col-md-5">
              Bill no:
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {testDetails.unique_id}
              </span>
            </div>
            <div className="col-md-3">
              Test Date:
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {testDetails.test_date &&
                  moment(testDetails.test_date).format("DD/MM/YYYY")}{" "}
              </span>
            </div>
            <div className="col-md-4">
              Created At:
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {moment(testDetails.created_at).format(
                  "DD/MM/YYYY HH:mm A"
                )}{" "}
              </span>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-md-5">
              Patient:{" "}
              {testDetails.patient_name !== null ? (
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {testDetails.patient_name}
                </span>
              ) : (
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {testDetails.patient.name}
                </span>
              )}
            </div>
            <div className="col-md-3">
              Phone:{" "}
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {testDetails.patient_phone}
              </span>
            </div>
            <div className="col-md-4">
              Gender:{" "}
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {testDetails.patient_gender}
              </span>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-md-12">
              Patient Address:
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {testDetails.patient_address}
              </span>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-12">
              Home Sample Collection :{" "}
              <span style={{ fontWeight: "bold" }}>
                {testDetails.home_delivery ? "Yes" : "No"}{" "}
              </span>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div style={{ overflow: "auto" }} className="">
                <table className="custom-responsive-table">
                  <thead>
                    <tr>
                      <th scope="col">Lab ID</th>
                      <th scope="col">Lab Inv ID</th>
                      <th scope="col">Test</th>
                      <th scope="col">Department</th>

                      <th scope="col">Matched test</th>
                      <th scope="col">Price</th>
                      <th scope="col">Discount</th>
                      <th scope="col">Bill</th>
                      <th scope="col">Status</th>
                      <th scope="col">Report</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(testDetails).includes(
                      "incoming_invoice_details"
                    ) && (
                      <React.Fragment>
                        {testDetails.incoming_invoice_details.map(
                          (detail, i) => (
                            <tr key={i}>
                              <td data-column="Lab ID">{detail.lab_id}</td>
                              <td data-column="Lab Inv ID">
                                {testDetails.lab_invoice_id}
                              </td>

                              <td data-column="Test">{detail.test_name}</td>
                              <td data-column="Department">
                                {detail.test_department}
                              </td>
                              <td data-column="Matched test">
                                {detail.diagnostic_test
                                  ? detail.diagnostic_test.name
                                  : ""}
                              </td>
                              <td data-column="Price">
                                {Math.ceil(detail.price)}
                              </td>

                              <td data-column="Discount">{detail.discount}</td>
                              <td data-column="Bill">{detail.bill}</td>

                              <td data-column="Status">{detail.status}</td>
                              <td data-column="Report">
                                {detail.report !== null && (
                                  <button
                                    className="btn  btn-sm btn-success"
                                    onClick={() => download(detail.report)}
                                  >
                                    Download
                                  </button>
                                )}
                                {detail.status !== "Approved" &&
                                  detail.report === null && (
                                    <React.Fragment>
                                      <input
                                        id={`btn-input`}
                                        // style={{ display: "none" }}
                                        multiple={false}
                                        type="file"
                                        onChange={(event) => {
                                          uploadMultipleFiles(event);
                                          handleClick(detail.id);
                                        }}
                                      />
                                      {state.EDIT === detail.id ? (
                                        <button
                                          disabled={file === ""}
                                          onClick={() => uploadFile(detail.id)}
                                          className="btn btn-sm btn-primary"
                                        >
                                          Upload
                                        </button>
                                      ) : (
                                        ""
                                      )}
                                    </React.Fragment>
                                  )}
                              </td>
                            </tr>
                          )
                        )}
                      </React.Fragment>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
