import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Pagination from "@material-ui/lab/Pagination";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";

export default function DegreeList() {
  const [list, setList] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [id, setId] = useState(1);

  const [selecedStatus, setSelectedStatus] = useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [params, setParams] = useState({
    per_page: 15,
    page: 1,
  });

  useEffect(() => {
    getList();
  }, [params]);

  const getList = () => {
    AdminAPI.get("medicines", { params: params })
      .then((response) => {
        setList(response.data.data.data);
        console.log(response.data.data);
        setTotalPage(response.data.data.last_page);
        setPage(response.data.data.current_page);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };

  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);
  const changeStatus = (status, id) => {
    setSelectedStatus(status);
    setId(id);
    setOpenModal(true);
  };
  const updateStatus = () => {
    let data = {
      status: selecedStatus,
    };
    AdminAPI.post(`medicines/${id}/update-status`, data)
      .then((response) => {
        setOpenModal(false);
        alert("You have successfully updated the status");
        getList();
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group my-2">
              <input
                placeholder="Search"
                className="form-control"
                type="text"
                onChange={(e) =>
                  handleColumnWiseSearch(e.target.value, "search")
                }
              />
            </div>
          </div>
          <div className="col-md-6 text-right">
            <Link
              to="/admin/medicine/upload-csv"
              type="button"
              className="btn btn-md btn-secondary"
            >
              Upload CSV
            </Link>
            <Link
              to="/admin/medicine/add"
              type="button"
              className="btn btn-md btn-primary mx-2"
            >
              Add Medicine
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-4">
            <table className="custom-responsive-table">
              <thead>
                <tr>
                  <th scope="col">Brand Name</th>
                  <th scope="col">Generic Name</th>

                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {list.map((list, i) => (
                  <tr key={i}>
                    <td data-column="Brand Name">{list.brand_name}</td>
                    <td data-column="Generic Name">{list.generic_name}</td>
                    <td data-column="Status">
                      <select
                        className="form-control"
                        value={list.status}
                        onChange={(e) => changeStatus(e.target.value, list.id)}
                      >
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </select>
                    </td>
                    <td data-column="Action">
                      <Link
                        to={`/admin/medicine/view/${list.id}`}
                        type="button"
                        className="btn btn-sm btn-primary mb-1"
                      >
                        View
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handleChange}
          />
        </div>
        <Dialog
          fullWidth
          maxWidth={`sm`}
          open={openModal}
          onClose={() => setOpenModal(false)}
        >
          <DialogTitle>Change Status</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure to update status?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="mr-auto"
              onClick={() => setOpenModal(false)}
              color="default"
            >
              Close
            </Button>
            <Button
              className="ml-auto"
              onClick={() => updateStatus()}
              color="primary"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
