import React from "react";
import Navbar2 from "../Shared/Navbar2";
import Footer from "../Shared/Footer";
const PrivacyPolicy = () => {
  return (
    <div>
      <Navbar2 />
      <div className="text-sections container">
        <h3>Privacy Policy</h3>
        <p>
          {" "}
          This privacy policy ("Privacy Policy") sets forth our commitment to
          respect your online privacy and recognize your requirements for
          appropriate protection and management of any Personal Information (as
          defined below) you share with us. The Privacy Policy will be applied
          to our available services under the domain www.heal-holmes.com
          (hereinafter referred to as the "Website/App"). By visiting the
          Website/App or going through the Privacy Policy, as detailed below
          herein, you agree to be bound by the terms and conditions of this
          Privacy Policy.
        </p>
        <h3>IF YOU DON’T AGREE PLEASE DON’T USE OR ACCESS THE WEBSITE/APP.</h3>
        <p>
          The words "you" or "your" or "user" or "customer" or “consumer “as
          used herein, refer to all individuals and/or entities accessing or
          using the Website/App for any reason. The words "we" or "us" or "our"
          or the "Company" as used herein, refer to Heal Holmes Ltd. and/or any
          authorized assignees.
        </p>
        <p>
          This Privacy Policy describes the information, which as part of the
          normal procedures of our Services; we collect from you and what may
          happen to that information. Although this policy may point out long,
          we have prepared a detailed policy because we believe you should know
          as much as you can about the Website/App, Services, and our practices
          so that you can make conversant decisions.
        </p>

        <p>
          By accepting the Privacy Policy and the Terms of Use, which prescribes
          terms and conditions for use of Website/App or assisting services, you
          expressly consent to our use and disclosure of your Personal
          Information in consequence of this Privacy Policy. This Privacy Policy
          is incorporated into and is subject to Terms of Use and the terms not
          defined here, have their meanings attributed to them in the Terms of
          Use. This Privacy Policy and the Terms of Use are effective upon your
          visit of our Website/App or you going through the same. We inspire you
          to read the terms of the Privacy Policy and the Terms of Use in their
          entirety before you use the Website/App or avail of our Services.
        </p>

        <h5>1. Your Privacy - Our Commitment</h5>
        <p>
          Your privacy is our priority and we are committed to protect it. We
          are exceptionally sensitive to protect your privacy. We value your
          trust in us. We will work with great effort to earn your confidence so
          that you can enthusiastically use our Services and recommend us to
          friends and family. Please go through the following policy to
          understand how your Personal Information will be secured & used as you
          make full use of our Website/App/avail of our services.
        </p>
        <p>
          For this Privacy Policy, the term "Personal Information" shall mean
          any information that may be used to identify you including, but not
          limited to, (i) first and last name with the salutation, a home or
          other physical address, an email address and mobile number or other
          contact information, whether at work or home, (ii) date of birth and
          gender (iii) correspondence address (iv) Medical Records and History
          (vii) Medical Information (the amount of information you choose to
          keep confidential is entirely up to your discretion; you may enter as
          much or as little information as you choose, except for the
          mandatorily essential information). The information essentially
          required for conducting the test may be gathered at the time when the
          patient gets registered at any authorized partner labs/diagnostics
          center/affiliated hospital of the company.
        </p>

        <h5>2. Information we collect</h5>
        <p>
          During use our Website/App, we collect and store your data. Our
          leading goal in doing so is to provide a safe, efficient, and
          customized experience to our Users. This allows us to provide services
          and making that most likely meet your necessary requirements, and to
          customize our Website/App to make your experience safer and easier.
          Certainly/ (Virtually) we only collect personal data about you that we
          consider necessary for achieving this objective.
        </p>

        <p>
          Additionally, we also collect the location data of our (i) service
          provider personnel/phlebotomists when the website/app is running in
          the foreground or background of their mobile device through GPS, IP
          address, to track their trips to our Customers location and keep them
          updated about our Phlebotomists real-time location status (ii)
          Customer from their mobile devices through GPS, if they enable us to
          do so, as to reach their serving location perfectly and also to spread
          your use of our apps, including to improve pick-up locations, enable
          safety features, and prevent and detect fraud.
        </p>

        <p>
          Usually, you can browse the Website/App without telling us who you are
          or revealing any personal data about yourself. To fully use our
          Website/App, you will be required to register using our online
          registration form, where you may be required to provide us with your
          contact and identity information and other personal data as indicated
          on the forms throughout the Website/App and complete the registration
          process. Once you give us your required data and initiate the
          Registration Process, you are not anonymous to us. Where possible, we
          indicate which fields are mandatorily required and which ground is
          optional. You always have the option to not provide information by
          choosing not to use a particular service or feature on the
          Website/App, unless we require it so.
        </p>

        <p>
          We may systemically track particular information about you based upon
          your behavior on our Website/App. You agree that we may use such
          information to do internal research on our users’ demographics and
          medical history to better understand, protect and serve our users.
          This information is compiled and analyzed on an aggregated basis. This
          information may include but is not limited to, the URL that you just
          came from (whether this URL is on our site or not), which URL you
          further go to (whether this URL is on our Website/App or not), your
          computer browser information, and your Internet Protocol ("IP")
          address.
        </p>

        <p>
          We use data collection devices such as "cookies" on particular pages
          of the Website/App to help analyze our web page flow, measure
          promotional effectiveness, and promote confidence and safety.
          "Cookies" are small files placed on your hard drive that assist us in
          providing our services. We offer particular features that are only
          available through the use of a "cookie". We also use cookies to allow
          you to enter your password less frequently during a session. Cookies
          can also help us provide information that is targeted to your
          interests. Most cookies are "session cookies," meaning that they are
          automatically deleted from your hard drive at the end of a session.
          You are always free to degeneration our cookies if your browser
          allows, although in that case, you may not be able to use certain
          features on the Website/App and you may be required to re-enter your
          password more repeatedly during a session.
        </p>

        <h5>Website/App</h5>

        <p>
          You agree that if you or any third-party shares correspondences with
          us in any electronic form such as emails, letters, etc., containing
          your data, we may collect or store the same, as part of your
          exploration/query resolution or for the purposes mentioned in Clause 3
          of 4 below.
        </p>

        <p>
          You agree that we may collect your IP address Website/App Website/App
          as a visitor to our Website/App. An IP address is a number that is
          automatically assigned to your computer when you use the internet. We
          use IP addresses to help diagnose problems with our server, administer
          our Website/App, analyze trends, track users' movement, gather broad
          demographic information for assemblage use for us to improve the
          Website/App, and deliver customized, personalized content.
        </p>

        <h5>3. Use of your Personal Information</h5>
        <p>
          You agree that we may use your data to facilitate the services you
          request. You agree that we may use your data and other information we
          obtain on the Website/App or at the time of your registration to
          enable detailed examination of the medical tests conducted, inform you
          about online and offline offers, products, services, password
          retrieval, and updates; customize your experience; enforce Terms of
          Use; and as otherwise described to you at the time of sample
          collection.
        </p>
        <p>
          Further, you hereby consent that we may use your anonymized sample/
          data for research and development purposes. You agree that we may use
          personal data about you to improve our marketing and promotional
          efforts, to analyze site usage, improve the Website/App's content and
          service offerings, and customize the Website/App's content, layout,
          and services. These uses improve the Website/App and customize it to
          meet your needs, to provide you with an efficient, safe, and
          customized experience while using the Website/App.
        </p>

        <p>
          You agree that we may use your data to contact you and deliver
          information to you that, in some cases, are targeted to your
          interests, such as targeted banner advertisements, administrative
          notices, services offerings, and communications relevant to your use
          of the Website/App. By accepting the Terms of Use and the Privacy
          Policy, you agree to receive this information. If you do not wish to
          receive these communications, we encourage you to unsubscribe/opt-out
          of the receipt of certain communications in your profile. You may make
          changes to your profile at any time.
        </p>

        <h5>4. Disclosure of your Personal Information</h5>

        <p>
          You agree and confirm that we do not rent, sell or share personal data
          about you with other people (save with your consent) or non-affiliated
          companies except to provide products or Services under the Terms of
          Use or this Privacy Policy, or under the following circumstances:
        </p>
        <ol type="I">
          <li>
            to provide the personal data to physicians and other authorized
            health care professionals who need to access your laboratory report
            and medical history to making a proper diagnosis;
          </li>
          <li>
            to support our healthcare operations, such as performing quality
            checks on your testing, for teaching purposes, or for developing
            normal reference ranges for the tests we conduct;
          </li>
          <li>
            to insurance companies, hospitals, physicians, or third parties with
            our billing department for payment purposes;
          </li>
          <li>
            to the state authorized health department or any other Government
            body as and when required by them for collecting or processing
            health information of the state/country;
          </li>
          <li>
            to other laboratories/medical institutions for research and
            development purposes from time to time, to reveal general
            statistical information about our Website/App and visitors, such as
            number of visitors, number and type of services purchased, etc.;
          </li>
          <li>
            to transfer/disclose Personal Information about you to trusted
            partners, may or may not be for avail, to promote certain
            products/services for commercial purposes, without any prior notice
            to you.
          </li>
          <li>
            to respond to summons, court orders, or legal process, or to
            establish or exercise our legal rights or defend against legal
            claims;
          </li>
        </ol>
        <p>
          Additionally, you agree that we may share your Personal Information
          for the following categories of activities from time to time:
        </p>

        <ol type="I">
          <li>
            Advertisements
            <ol type="a">
              <li>
                When you enter Personal Information on any form of an
                advertiser, such information is at one time collected by the
                Website/App and the advertiser. The Personal Information is used
                by Website/App following the terms of this Privacy Policy and is
                used by the advertiser as per the advertiser's prevalent privacy
                policies. Because we do not control the privacy practices of
                these advertisers, you should assess their practices before
                deciding to provide the said information.
              </li>
              <li>
                Website/App may also aggregate (gather up data across all
                Accounts) Personal Information and disclose such information in
                a non-personally identifiable manner to advertisers and other
                third parties for other marketing and promotional purposes.
              </li>
            </ol>
          </li>
          <li>
            Other Corporate Entities.
            <ol type="a">
              <li>
                The Company shares much of the data, including Personal
                Information about you, with its parent, affiliates,
                subsidiaries, and joint ventures that are committed to serving
                your online needs and related services, throughout the world. To
                the extent that these entities have access to your Personal
                Information, they will treat it at least as protectively as they
                treat information they obtain from their other users. The
                Company's parent, affiliates, subsidiaries, and joint ventures
                follow privacy practices no less protective of all users than
                our practices described in this document, to the extent allowed
                by applicable law. The Company, its parent, affiliates,
                subsidiaries, its joint ventures, or any combination of such,
                will share some or all of your Personal Information with another
                business entity should we plan to, merge with, or be acquired by
                that business entity.
              </li>
            </ol>
          </li>
          <li>
            Posting to public areas of the Website/App
            <ol type="a">
              <li>
                Please remember that if you post any of your Personal
                Information in public areas of the Website/App such as in online
                forums or chat rooms, or the Website/App's searchable database,
                such information may be collected and used by others over whom
                we have no control. We are not liable for the use of information
                by third parties based on the information you post or otherwise
                make available in public areas of the Website/App.
              </li>
            </ol>
          </li>
        </ol>
        <h5>5. Access or change your Personal Information</h5>

        <p>
          You may review, correct, update or change your account information at
          any time. To protect your privacy and security, we will verify your
          identity before accepting access or making changes to your Personal
          Information. If you have registered your profile on the Website/App,
          your ID and Password are essential to access your Account. Your
          Personal Information shall be retained till is required for the
          Purpose or required under applicable law, whichever is later.
        </p>

        <h5>6. Information security</h5>

        <p>
          The Company has implemented most appropriate security practices and
          standards and has a comprehensively documented information security
          program and information security policies that hold managerial,
          technical, operational, and physical security control measures that
          are commensurate with the information assets being protected with the
          nature of business. Further, the Company takes appropriate security
          measures to protect against unauthorized access to or unauthorized
          alteration, disclosure, or destruction of data and restricts access to
          your data to the Company’s employees who need to have that information
          to fulfill your request or supply our services.
        </p>

        <h5>7. Other Website/Apps</h5>
        <p>
          Our Website/App may contain links to other Websites/Apps. Please note
          that when you click on one of these links, you are entering another
          Website/App over which the Website/App has no control and will bear no
          responsibility. Often these Websites/Apps require you to enter your
          Personal Information. We encourage you to read the privacy statements
          on all such Websites/Apps as their policies may differ from ours. You
          agree that we shall not be liable for any breach of your privacy of
          Personal Information or loss incurred by your use of these
          Websites/Apps.
        </p>

        <h5>8. Changes to this Privacy Policy</h5>
        <p>
          We reserve the right to update, change or modify this Privacy Policy
          at any time. The amendment to this Privacy Policy shall come to effect
          from the time of such update, change, or modification and the same
          will be published on this Website/App.
        </p>

        <h5>9. Disclaimer</h5>
        <p>
          The Company does not access, store, or keep credit card data or other
          financial transaction data. All financial transactions happen using
          third-party gateways and the Company plays no role in the transaction,
          except for directing the customers to gateways or the relevant
          webpage. Accordingly, the Company shall not be responsible or liable
          for any loss or damage due to any disclosure whatsoever of Personal
          Information or any other information collected by the gateways or such
          Website/Apps.
        </p>
        <p>
          The Company shall not be liable for any loss or damage sustained
          because of any disclosure (inadvertent or otherwise) of any Personal
          Information concerning the User's account and/or information relating
          to or regarding online transactions using credit cards/debit cards
          /cash cards / MFS and/or their verification process and particulars
          nor for any error, omission or inaccuracy concerning any information
          so disclosed and used on such third-party gateways.
        </p>

        <h5>10. Governing Law and Dispute Resolution</h5>
        <p>
          This Privacy Policy and Terms of Use shall be governed by and
          constructed following the laws of the Peoples’ Republic of Bangladesh
          Governments only without reference to conflict of laws principles and
          disputes arising in relation hereto and shall be subject to the
          exclusive jurisdiction of the competent courts of Dhaka, Bangladesh.
        </p>

        <h5>11. Assignability</h5>

        <p>
          The Company may assign any of its responsibilities/obligations to any
          other authorized person without prior notice to the User, at its sole
          discretion. However, you shall not assign, sub-license or otherwise
          transfer any of your rights under this Privacy Policy to any other
          party, unless written approval is taken from the Company.
        </p>

        <h5>12. Contacting the Website/App</h5>

        <p>
          If you have any queries about this Privacy Policy, the privacy
          practices of this Website/App, or if you want to make use of any of
          the rights that you are given under this Privacy Policy, you can
          contact Heal Holmes Ltd. at heal.holmes@gmail.com The details of the
          contact information may be changed by us from as an when required for
          updating this Privacy Policy.
        </p>
      </div>

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
