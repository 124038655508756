import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";

import LockOpenIcon from "@material-ui/icons/LockOpen";
import Switch from "@material-ui/core/Switch";
import patientAuth from "../../routingRules/patientAuth";

// import axios from "axios";
import { PatientAPI } from "../../AxiosRequest/PatientAPI";
import * as Yup from "yup";
import "./css/patientLogin.css";
import NavbarHome from "../Shared/NavbarHome";
import _, { isObject } from "lodash";
import Navbar2 from "../Shared/Navbar2";

export default function PatientLogin({ hideNavBar }) {
  let history = useHistory();
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  });
  const [loginErrors, setLoginErrors] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const validationSchema = Yup.object({
    phone: Yup.string().required("Mobile number is required"),
    password: Yup.string()
      .min(6, "Password must contain at least 6 characters")
      .required("Enter your password"),
  });
  const initialValues = {
    phone: "",
    password: "",
  };

  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    setLoading(true);

    PatientAPI.post("auth/login", {
      phone: values.phone,
      password: values.password,
    })
      .then((response) => {
        let patientToken = response.data.data.access_token;
        localStorage.setItem("patientToken", JSON.stringify(patientToken));
        localStorage.setItem(
          "patientId",
          JSON.stringify(response.data.data.user_id)
        );
        setLoading(false);
        const storage = localStorage.getItem("testCart")
          ? JSON.parse(localStorage.getItem("testCart"))
          : [];
        const doc = localStorage.getItem("docBooked")
          ? JSON.parse(localStorage.getItem("docBooked"))
          : "";
        const upTestObj = localStorage.getItem("upTestObj")
          ? JSON.parse(localStorage.getItem("upTestObj"))
          : {};
        console.log("stora", storage);
        console.log("doc", doc);
        console.log("file", upTestObj);
        if (storage.length > 0) {
          patientAuth.login(() => {
            history.push("/patient/test/add");
          });
        } else if (doc !== "") {
          patientAuth.login(() => {
            history.push(`/patient/doctor-schedule/${doc}`);
          });
        } else if (!_.isEmpty(upTestObj)) {
          patientAuth.login(() => {
            history.push(`/patient/test/upload-prescription`);
          });
        } else {
          patientAuth.login(() => {
            history.push("/patient/patient-portal");
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        setLoading(false);
        if (error.response.data && error.response.data.errors !== undefined) {
          setLoginErrors(error.response.data.errors);
        } else if (error.response.data && error.response.data.message) {
          setLoginErrors(error.response.data.message);
        }
      });
  };
  return (
    <div>
      <Navbar2 />

      <div className={!hideNavBar ? "login-section" : ""}>
        <div className="container">
          <div className="row">
            <div className={!hideNavBar ? "col-lg-3" : ""}></div>
            <div className={!hideNavBar ? "col-lg-6" : "col-lg-12"}>
              <div className="login-form-section">
                <div className="text-center">
                  <LockOpenIcon />
                </div>

                <h3 className="text-center py-2">Patient Login</h3>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  <Form>
                    <div className="form-group row ">
                      <div className="col-sm-12">
                        <Field
                          name="phone"
                          type="text"
                          className="form-control"
                          placeholder="phone"
                        />
                        <ErrorMessage
                          name="Mobile Number"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>
                    <div className="form-group row ">
                      <div className="col-sm-12">
                        <Field
                          name="password"
                          type="password"
                          className="form-control"
                          placeholder="password"
                        />
                        {!isObject(loginErrors) && (
                          <p className="error-message"> {loginErrors}</p>
                        )}
                        {isObject(loginErrors) && (
                          <>
                            {" "}
                            <p className="error-message">
                              {" "}
                              {loginErrors.phone}
                            </p>
                            <p className="error-message">
                              {" "}
                              {loginErrors.password}
                            </p>
                          </>
                        )}

                        <ErrorMessage
                          name="password"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>
                    <div className="form-group row ">
                      <div className="col-sm-6">
                        <Switch
                          checked={state.checkedB}
                          onChange={handleChange}
                          color="primary"
                          name="checkedB"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />{" "}
                        Remember Me
                      </div>
                      <div className="col-sm-6">
                        <button
                          type="submit"
                          className="btn btn-block btn-primary"
                          disabled={loading}
                        >
                          {loading && <i className="fa fa-refresh fa-spin"></i>}
                          {loading && <span>Signing In</span>}
                          {!loading && <span> Sign In</span>}
                        </button>
                      </div>
                    </div>
                    <div className="form-group row ">
                      <div className="col-sm-8">
                        Forgot your password?{" "}
                        <Link
                          className="px-1"
                          type="button"
                          to="/patient/forget-password"
                        >
                          Reset
                        </Link>
                      </div>
                    </div>
                    <div className="form-group row ">
                      <div className="col-sm-8">
                        Don't have an account? Register
                        <Link
                          className="px-1"
                          type="button"
                          to="/patient/register"
                        >
                          here
                        </Link>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
            <div className={!hideNavBar ? "col-lg-3" : ""}></div>
          </div>
        </div>
      </div>
    </div>
  );
}
