import React, { useState, useEffect } from "react";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import { Link } from "react-router-dom";
import _ from "lodash";
import { Layout } from "antd";
import Pagination from "@material-ui/lab/Pagination";

const { Content } = Layout;

export default function PatientAppointments() {
  const [appointments, setAppointments] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);

  const [params, setParams] = useState({
    per_page: 15,
    page: 1,
  });

  useEffect(() => {
    getAppointments();
  }, [params]);

  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);

  const getAppointments = () => {
    AdminAPI.get("patient-appointments", {params: params})
      .then((response) => {
        setAppointments(response.data.data.data);
        console.log(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  
  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };
  return (
    <div>
      <div className="row">
        <div className="col-md-3 form-group">
          <select className="form-control"
            onChange={(e) => {
              handleColumnWiseSearch(e.target.value, "status");
            }}
          >
            <option value="">All</option>
            <option value="Pending">Pending</option>
            <option value="Accepted">Accepted</option>
            <option value="Paid">Paid</option>
            <option value="Prescribed">Prescribed</option>
            <option value="Rejected">Rejected</option>
          </select>
        </div>
        <div className="col-md-4 ml-auto form-group">
          <input
            placeholder="Search"
            className="form-control"
            type="text"
            onChange={(e) => {
              handleColumnWiseSearch(e.target.value, "search");
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive">
            <table className="table table-striped table-bordered">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Doctor</th>
                  <th scope="col">Patient</th>
                  <th scope="col">Time</th>
                  <th scope="col">Status</th>
                  <th scope="col">Type</th>
                </tr>
              </thead>
              <tbody>
                {appointments.map((appointment, i) => (
                  <tr key={i}>
                    <td>{appointment.doctor != null ? appointment.doctor.name : ""}</td>
                    <td>{appointment.patient != null ? appointment.patient.name : ""}</td>
                    <td>{appointment.appointment_time}</td>
                    <td>{appointment.status}</td>
                    <td>{appointment.type}</td>
                    {/* <td>
                      <Link
                        type="button"
                        to={`/admin/appointment/${appointment.id}`}
                        className="btn btn-sm btn-info"
                      >
                        Details
                      </Link>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row">
        <Pagination
          color="primary"
          count={totalPage}
          page={page}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}
