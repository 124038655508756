import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { TextField as TextFieldSecond } from "formik-material-ui";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import Chip from "@material-ui/core/Chip";
import * as Yup from "yup";

// import "../css/add-institution.css";
import { DoctorAPI } from "../../../AxiosRequest/DoctorAPI";

export default function Profile() {
  const [open, setOpen] = useState(false);
  const [erroropen, setErrorOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [docData, setDocData] = useState({});
  const [designations, setDesignations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const [degrees, setDegrees] = useState([]);
  const [passingIns, setPassingIns] = useState("");

  //Autocomplete values

  const [designationValue, setDesignationValue] = useState(null);
  const [departmentValue, setDepartmentValue] = useState(null);
  const [degreeList, setDegreeList] = useState([]);
  const [degreeValue, setDegreeValue] = useState([]);
  const [institutionValue, setInstitutionValue] = useState(null);
  const [passingInstitutionValue, setPassingInstitutionValue] = useState(null);
  const [degreeListChosen, setDegreeListChosen] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Backend Errors Handle
  const [backendErrorMessage, setBackendErrorMessage] = useState({});

  useEffect(() => {
    getDocData();
    getDesignations();
    getDepartments();
    getDegrees();
    getInstitutions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDocData = () => {
    DoctorAPI.get("auth/me")
      .then((response) => {
        console.log("response data", response.data.data);
        setDocData(response.data.data);
        setDesignationValue(response.data.data.designation);
        setDepartmentValue(response.data.data.department);
        setInstitutionValue(response.data.data.institution);
        const selected = response.data.data.doctor_degrees;
        console.log("response data", selected);
        setDegreeValue(selected);
        setPassingIns(response.data.data.passing_institution);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const getDegrees = () => {
    axios
      .get("degrees")
      .then((response) => {
        setDegreeList(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const getDesignations = () => {
    axios
      .get("designations")
      .then((response) => {
        setDesignations(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const getInstitutions = () => {
    axios
      .get("institutions")
      .then((response) => {
        setInstitutions(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const getDepartments = () => {
    axios
      .get("departments")
      .then((response) => {
        setDepartments(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("This field is required"),

    phone: Yup.string().required("Please enter your phone number"),

    designation: Yup.string().required("Designation field is required"),
    department: Yup.string().required("Department field is required"),
    institution: Yup.string().required("Institution field is required"),
    passing_institution: Yup.string().required(
      "Passing Institution field is required"
    ),
    degrees: Yup.string().required("Degree field is required"),
    passing_year: Yup.number()
      .typeError("Passing Year must be a number")
      .required("Passing Year field is required"),
    practice_year: Yup.number()
      .typeError("Practice Year must be a number")
      .required("Practice Year field is required"),
    new_patient_visit: Yup.number()
      .typeError("New Patient Visit field must be a number")
      .required("New Patient Visit field is required"),
    old_patient_visit: Yup.number()
      .typeError("Old Patient Visit field must be a number")
      .required("Old Patient Visit field is required"),
    old_patient_valid_days: Yup.number()
      .typeError("Old Patient Validity field must be a number")
      .required("Old Patient Validity field is required"),
  });
  const initialValues = {
    name: docData.name || "",
    phone: docData.phone || "",
    designation: docData.designation || "",
    department: docData.department || "",
    institution: docData.institution || "",
    passing_institution: docData.passing_institution || "",
    degrees: docData.degrees || "",
    passing_year: docData.passing_year || "",
    practice_year: docData.practice_year || "",
    new_patient_visit: docData.new_patient_visit || "",
    old_patient_visit: docData.old_patient_visit || "",
    old_patient_valid_days: docData.old_patient_valid_days || "",
  };

  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    values.degrees = degreeListChosen;
    setLoading(true);

    DoctorAPI.post("auth/me", values)
      .then((response) => {
        console.log(response);
        if (response.data.message) {
          setSuccessMessage(response.data.message);
          setOpen(true);
          setErrorOpen(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error.response);
        setLoading(false);
        if (error.response.data.message) {
          setErrorMessage(error.response.data.message);
          setErrorOpen(true);
          setOpen(false);
        }
        if (error.response.data.data !== undefined) {
          setErrorOpen(true);
          setOpen(false);
        }

        if (error.response.data.errors !== undefined) {
          setBackendErrorMessage(error.response.data.errors);
        }
      });
  };
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-md-12">
                <div className="add-institution">
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({ setFieldValue, isValid, dirty }) => (
                      <Form>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1 col-form-label">
                            Name
                          </label>
                          <div className="col-sm-7">
                            <Field
                              name="name"
                              type="text"
                              className="form-control"
                              placeholder="Your Name"
                            />
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="error-message"
                            />
                          </div>
                        </div>

                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1 col-form-label">
                            Phone
                          </label>
                          <div className="col-sm-7">
                            <Field
                              name="phone"
                              type="text"
                              className="form-control"
                              placeholder="Phone/Mobile Number"
                            />
                            <ErrorMessage
                              name="phone"
                              component="div"
                              className="error-message"
                            />
                          </div>
                        </div>
                        {/* <div className="form-group row ">
                                <label className="col-sm-3 offset-sm-1 col-form-label">
                                  Date of Birth
                                </label>
                                <div className="col-sm-7">
                                  <Field
                                    name="date_of_birth"
                                    type="date"
                                    className="form-control"
                                    placeholder=""
                                  />
                                  <ErrorMessage
                                    name="date_of_birth"
                                    component="div"
                                    className="error-message"
                                  />
                                </div>
                              </div> */}

                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1 col-form-label">
                            Designation
                          </label>
                          <div className="col-sm-7">
                            <Autocomplete
                              freeSolo
                              id="disabledHelperText"
                              name="designation_id"
                              options={designations}
                              getOptionLabel={(option) =>
                                option.name || designationValue
                              }
                              getOptionSelected={(option, value) => {
                                return option.name === value;
                              }}
                              value={designationValue}
                              onChange={(e, value) => {
                                if (value == null) {
                                  setFieldValue("designation_id", "");
                                } else {
                                  setFieldValue("designation_id", value.id);
                                  // setDesignationValue(value.name);
                                }
                              }}
                              renderInput={(params) => (
                                <Field
                                  component={TextFieldSecond}
                                  {...params}
                                  onChange={(e, value) => {
                                    console.log(e.target.value);
                                    //   setFieldValue("designation", value);
                                  }}
                                  name="designation_id"
                                  variant="outlined"
                                  fullWidth
                                />
                              )}
                            />
                            <ErrorMessage
                              name="designation_id"
                              component="div"
                              className="error-message"
                            />
                          </div>
                        </div>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1 col-form-label">
                            Department
                          </label>
                          <div className="col-sm-7">
                            <Autocomplete
                              freeSolo
                              id="disabledHelperText"
                              name="department_id"
                              options={departments}
                              getOptionLabel={(option) =>
                                option.name || departmentValue
                              }
                              getOptionSelected={(option, value) => {
                                return option.name === value;
                              }}
                              value={departmentValue}
                              onChange={(e, value) => {
                                if (value == null) {
                                  setFieldValue("department_id", "");
                                } else {
                                  setFieldValue("department_id", value.id);
                                }
                              }}
                              renderInput={(params) => (
                                <Field
                                  component={TextFieldSecond}
                                  {...params}
                                  onChange={(e, value) => {
                                    console.log(e.target.value);
                                    setFieldValue(
                                      "department_id",
                                      e.target.value
                                    );
                                  }}
                                  name="department_id"
                                  variant="outlined"
                                  fullWidth
                                />
                              )}
                            />

                            <ErrorMessage
                              name="department_id"
                              component="div"
                              className="error-message"
                            />
                          </div>
                        </div>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1 col-form-label">
                            Institution
                          </label>
                          <div className="col-sm-7">
                            <Autocomplete
                              id="disabledHelperText"
                              freeSolo
                              options={institutions}
                              getOptionLabel={(option) =>
                                option.name || institutionValue
                              }
                              getOptionSelected={(option, value) => {
                                return option.name === value;
                              }}
                              value={institutionValue}
                              onChange={(event, value) => {
                                if (value == null) {
                                  setFieldValue("institution_id", "");
                                } else {
                                  setFieldValue("institution_id", value.id);
                                }
                              }}
                              renderInput={(params) => (
                                <Field
                                  component={TextFieldSecond}
                                  {...params}
                                  name="institution_id"
                                  variant="outlined"
                                />
                              )}
                            />
                            <ErrorMessage
                              name="institution_id"
                              component="div"
                              className="error-message"
                            />
                          </div>
                        </div>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1 col-form-label">
                            Degree
                          </label>
                          <div className="col-sm-7">
                            {degreeValue.length > 0 && (
                              <Autocomplete
                                multiple
                                id="tags-filled"
                                options={degreeList.map(
                                  (option) => option.name
                                )}
                                defaultValue={degreeValue.map(
                                  (option) => option.degree
                                )}
                                freeSolo
                                // value={degreeValue}
                                renderTags={(value, getTagProps) => {
                                  setDegreeListChosen(value);

                                  return value.map((option, index) => (
                                    <Chip
                                      variant="outlined"
                                      label={option}
                                      {...getTagProps({ index })}
                                    />
                                  ));
                                }}
                                renderInput={(params) => (
                                  <Field
                                    component={TextFieldSecond}
                                    {...params}
                                    name="degress"
                                    variant="outlined"
                                  />
                                )}
                              />
                            )}

                            <ErrorMessage
                              name="degress"
                              component="div"
                              className="error-message"
                            />
                            {/* {degreeErrors.map((item, i) => (
                              <p className="error-message" key={i}>
                                {item}
                              </p>
                            ))} */}
                          </div>
                        </div>

                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1 col-form-label ">
                            Practice Year
                          </label>
                          <div className="col-sm-7">
                            <Field
                              name="practice_year"
                              type="text"
                              className="form-control"
                              placeholder="Practice Year"
                            />
                            <ErrorMessage
                              name="practice_year"
                              component="div"
                              className="error-message"
                            />
                          </div>
                        </div>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1 col-form-label ">
                            Passing Year
                          </label>
                          <div className="col-sm-7">
                            <Field
                              name="passing_year"
                              type="text"
                              className="form-control"
                              placeholder="Passing Year"
                            />
                            <ErrorMessage
                              name="passing_year"
                              component="div"
                              className="error-message"
                            />
                          </div>
                        </div>

                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1 col-form-label ">
                            Passing Institution
                          </label>
                          <div className="col-sm-7">
                            <Autocomplete
                              id="disabledHelperText"
                              freeSolo
                              options={institutions}
                              value={passingIns}
                              getOptionLabel={(option) =>
                                option.name || passingIns
                              }
                              onChange={(event, value) => {
                                if (value == null) {
                                  console.log(null);
                                } else {
                                  setFieldValue(
                                    "passing_institution",
                                    value.name
                                  );
                                }
                              }}
                              onInputCapture={(event) => {
                                event.persist(); // As we are accessing the event in asynchronous way.
                                setFieldValue(
                                  "passing_institution",
                                  event.target.value
                                );
                                // console.log(event.target.value);
                              }}
                              renderInput={(params) => (
                                <Field
                                  component={TextFieldSecond}
                                  {...params}
                                  name="passing_institution"
                                  variant="outlined"
                                />
                              )}
                            />
                            <ErrorMessage
                              name="passing_institution"
                              component="div"
                              className="error-message"
                            />
                            {/* {passingInstituitionErrors.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))} */}
                          </div>
                        </div>

                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1 col-form-label ">
                            New Patient Visit
                          </label>
                          <div className="col-sm-7">
                            <Field
                              name="new_patient_visit"
                              type="text"
                              className="form-control "
                              placeholder="Amount (Taka)"
                            />
                            <ErrorMessage
                              name="new_patient_visit"
                              component="div"
                              className="error-message"
                            />
                          </div>
                        </div>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1 col-form-label ">
                            Old Patient Visit
                          </label>
                          <div className="col-sm-7">
                            <Field
                              name="old_patient_visit"
                              type="text"
                              className="form-control "
                              placeholder="Amount (Taka)"
                            />
                            <ErrorMessage
                              name="old_patient_visit"
                              component="div"
                              className="error-message"
                            />
                          </div>
                        </div>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1 col-form-label ">
                            Old Patient Validity
                          </label>
                          <div className="col-sm-7">
                            <Field
                              name="old_patient_valid_days"
                              type="text"
                              className="form-control "
                              placeholder="Days"
                            />
                            <ErrorMessage
                              name="old_patient_valid_days"
                              component="div"
                              className="error-message"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 my-4">
                            <Collapse in={open}>
                              <Alert
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setOpen(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                {successMessage}
                              </Alert>
                            </Collapse>
                            <Collapse in={erroropen}>
                              <Alert
                                severity="error"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setErrorOpen(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                {errorMessage}
                              </Alert>
                            </Collapse>
                          </div>
                        </div>

                        <div className="form-group row ">
                          <label className="col-sm-5 offset-sm-1">
                            <button
                              disabled={loading}
                              // disabled={!isValid || !dirty}
                              type="submit"
                              className="btn btn-lg btn-success"
                            >
                              {loading && (
                                <i className="fa fa-refresh fa-spin"></i>
                              )}
                              {loading && <span>Updating</span>}
                              {!loading && <span> Update</span>}
                            </button>
                          </label>
                          <div className="col-sm-7"></div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </div>
    </div>
  );
}
