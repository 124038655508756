import React, { useState, useEffect } from "react";
import {
  useParams,
} from "react-router-dom";
import { Link } from "react-router-dom";
import moment from "moment";
import * as Yup from "yup";
import { Layout } from "antd";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";

const { Content } = Layout;

export default function TransactionDetail() {
    let { id } = useParams();
    const [transactionData, setTransactionData] = useState({});
    useEffect(() => {
        if (id) {
          getTransactionDetails();
        }
      }, []);
      const getTransactionDetails = () => {
        AdminAPI.get(`transactions/${id}`)
          .then((response) => {
            if (response.data) {
                setTransactionData(response.data.data);
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
    };
    return (
        <div>
            <div className="row">
                <div className="col-md-12 text-right">
                    <Link
                        to="/admin/bank-account/list"
                        type="button"
                        className="btn btn-sm btn-success"
                    >
                    Transaction List
                    </Link>
                </div>
            </div>
            <div className="row my-2">
                <div className="col-12">
                    <div className="form-group row">
                        <div className="col-md-4">
                            Bill no: <span className="font-weight-bold"> {transactionData.unique_id}</span>
                        </div>
                        <div className="col-md-4">
                            Amount: <span className="font-weight-bold"> {transactionData.amount} Tk</span>
                        </div>
                        <div className="col-md-4">
                            Date: <span className="font-weight-bold">{moment(transactionData.created_at).format("DD/MM/YYYY HH:mm A")}</span>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-md-4">
                            Sender: <span className="font-weight-bold"> {transactionData.client_name}</span>
                        </div>
                        <div className="col-md-4">
                            Sender type: <span className="font-weight-bold"> {transactionData.client_type}</span>
                        </div>
                        <div className="col-md-4">
                            Receiver: <span className="font-weight-bold">{transactionData.receiver_name}</span>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-md-4">
                            Type: <span className="font-weight-bold"> {transactionData.income_expense}</span>
                        </div>
                        <div className="col-md-4">
                            Method: <span className="font-weight-bold"> {transactionData.method}</span>
                        </div>
                        <div className="col-md-4">
                            Category: <span className="font-weight-bold">{transactionData.category}</span>
                        </div>
                    </div>
                    {transactionData.bank_transaction != null && 
                        <div className="form-group row">
                            <div className="col-md-4">
                                From: <span className="font-weight-bold">{transactionData.bank_transaction.from_account} ({transactionData.bank_transaction.from_bank})</span>
                            </div>
                            <div className="col-md-4">
                                To: <span className="font-weight-bold">{transactionData.bank_transaction.bank_account.account_number} ({transactionData.bank_transaction.bank_account.bank})</span>
                            </div>
                            <div className="col-md-4">
                                Cheque: <span className="font-weight-bold">{transactionData.bank_transaction.cheque}</span>
                            </div>
                        </div>
                    }
                    {transactionData.test_invoices != null && 
                        <div className="form-group row">
                            <div className="col-md-12">
                                Test Invoices: {" "}
                                <span className="font-weight-bold">
                                    {transactionData.test_invoices.map((invoice, i) => (
                                        <Link
                                            to={`/admin/test/view/${invoice.id}`}
                                            type="button"
                                            className="btn btn-sm btn-outline-primary m-1"
                                            >
                                            {invoice.unique_id}
                                        </Link>
                                    ))}
                                </span>
                            </div>
                        </div>
                    }
                    <div className="form-group row">
                        <div className="col-md-4">
                            Status: <span className="font-weight-bold"> {transactionData.status}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
