import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from "@material-ui/pickers";
import axios from "axios";
import DateFnsUtils from "@date-io/date-fns";
import _ from "lodash";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
export default function PatientCreatedTest({ admin }) {
  let history = useHistory();
  const [createdList, setCreatedList] = useState([]);
  const [updatedTestInvoice, setUpdatedTestInvoice] = useState([]);
  const [totalInvoice, setTotalInvoice] = useState(0);
  const [totalBill, setTotalBill] = useState(0);
  const [totalBuy, setTotalBuy] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);
  const [diagnosticList, setDiagnosticList] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [selectedStartDate, handleDateChangeStart] = useState(null);
  const [selectedEndDate, handleDateChangeEnd] = useState(null);

  const [params, setParams] = useState({
    per_page: 15,
    page: 1,
  });

  const handleDateChangeDate = (value, col) => {
    if (value !== null) {
      handleColumnWiseSearch(moment(value).format("YYYY-MM-DD"), col);
    } else {
      handleColumnWiseSearch(value, col);
    }
  };

  useEffect(() => {
    getTestList();
    window.Echo.private(`admin.activity`).listen(".admin.activities", (e) => {
      alert(e.message);
      console.log(e);
    });
  }, [params]);
  useEffect(() => {
    getDiagnosticList();
  }, []);
  const getDiagnosticList = () => {
    axios
      .get("diagnostics")
      .then((response) => {
        setDiagnosticList(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const handleUpdateTestInput = (test, e) => {
    let index = updatedTestInvoice.findIndex((x) => x.id === test.id);
    if (index !== -1) {
      let temporaryarray = updatedTestInvoice.slice();
      temporaryarray[index][e.target.name] = e.target.value;
      setUpdatedTestInvoice(temporaryarray);
    } else {
      let up = [...updatedTestInvoice];
      let obj = {
        id: test.id,
        expense: test.expense,
        collected_by: test.collected_by,
        buying_price: test.buying_price,
      };

      obj[e.target.name] = e.target.value;
      up.push(obj);

      setUpdatedTestInvoice(up);
    }
  };
  const updateTestInvoice = (id) => {
    const arr = [...updatedTestInvoice];
    let testToUpdate = arr.filter((o) => o.id === id);
    if (testToUpdate.length === 0) {
      alert("Nothing to update");
      return;
    } else if (testToUpdate.length > 0) {
      let objToUpdate = { ...testToUpdate[0] };
      delete objToUpdate.id;

      console.log("invoice", objToUpdate);
      AdminAPI.put(`test-invoices/${id}`, objToUpdate)
        .then((response) => {
          alert("succesfully updated");
          getTestList();
        })
        .catch((error) => {
          console.log(error.response);
          getTestList();
        });
    }
  };

  const getTestList = () => {
    AdminAPI.get(`test-invoices`, { params: params })
      .then((response) => {
        setCreatedList(response.data.data.data);
        setTotalInvoice(response.data.data.total);
        setTotalBill(response.data.totalBill);
        setTotalBuy(response.data.totalBuyingPrice);
        setTotalExpense(response.data.totalExpense);
        setTotalPage(response.data.data.last_page);
        setPage(response.data.data.current_page);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);

  const handleStatus = (event) => {
    const paramState = { ...params };
    if (event.target.checked) {
      paramState["status"] = "Cancelled";
    } else {
      delete paramState["status"];
    }
    setParams(paramState);
  };

  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };
  const updateStatus = (id, status) => {
    AdminAPI.post(`test-invoices/${id}/update-status`, {
      status: status,
    })
      .then((response) => {
        getTestList();
      })
      .catch((error) => {});
  };
  const downloadPDF = (id) => {
    console.log("iddddd", id);
    AdminAPI.get(`test-invoices/${id}/pdf`, {
      method: "GET",
      responseType: "blob", //Force to receive data in a Blob Format
    })
      .then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" }); //Build a URL from the file
        const fileURL = URL.createObjectURL(file); //Open the URL on new Window
        window.open(fileURL);

        console.log("pdf response", response, file);
      })
      .catch((error) => {});
  };
  return (
    <div className="container-fluid">
      <div>
        <div className="row">
          <div className="col-10 mt-2">
            <h6>
              <div className="row">
                <div className="col-md-2 form-group">
                  Invoice: {totalInvoice}
                </div>
                {admin && (
                  <React.Fragment>
                    <div className="col-md-3 form-group">Bill: {totalBill}</div>
                    <div className="col-md-3 form-group">Buy: {totalBuy}</div>
                    <div className="col-md-2 form-group">
                      Expense: {totalExpense}
                    </div>
                    <div className="col-md-2 form-group">
                      Profit: {totalBill - totalBuy - totalExpense}
                    </div>
                  </React.Fragment>
                )}
              </div>
            </h6>
          </div>
          <div className="col-2 text-right">
            <Link
              className="btn btn-outline-secondary"
              type="button"
              to={"/admin/add-invoice"}
            >
              New invoice
            </Link>
          </div>
        </div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="row my-3">
            <div className="col-md-4 form-group">
              {" "}
              <KeyboardDatePicker
                clearable
                value={selectedStartDate}
                placeholder="Start Date"
                onChange={(date) => {
                  handleDateChangeStart(date);
                  handleDateChangeDate(date, "start_date");
                }}
                format="MM/dd/yyyy"
              />
            </div>
            <div className="col-md-4 form-group">
              {" "}
              <KeyboardDatePicker
                clearable
                value={selectedEndDate}
                placeholder="End Date"
                onChange={(date) => {
                  handleDateChangeEnd(date);
                  handleDateChangeDate(date, "end_date");
                }}
                format="MM/dd/yyyy"
              />
            </div>
            {/* <div className="col-md-2">
              <div class="form-check">
                <input
                  className="form-check-input text-danger"
                  value="Cancelled"
                  type="checkbox"
                  id="flexCheckDefault"
                  onChange={(e) => handleStatus(e)}
                />
                <label
                  className="form-check-label text-danger"
                  for="flexCheckDefault"
                >
                  {" "}
                  Cancelled{" "}
                </label>
              </div>
            </div> */}
          </div>
        </MuiPickersUtilsProvider>
        <div className="row">
          <div className="col-md-4 form-group">
            <label htmlFor="">Type</label>
            <select
              className="form-control"
              onChange={(e) => {
                handleColumnWiseSearch(e.target.value, "type");
              }}
            >
              <option value="">All</option>
              <option value="Patient">Patient</option>
              <option value="Admin">Admin</option>
              <option value="Agent">Agent</option>
              <option value="Corporate">Corporate</option>
            </select>
          </div>
          <div className="col-md-6 form-group">
            <label htmlFor="">Lab</label>
            <Autocomplete
              id="disabledHelperText"
              name="diagnostic_id"
              options={diagnosticList.filter((dig) => dig.id !== 1)}
              getOptionLabel={(option) => option.name}
              onChange={(e, value) => {
                if (value == null) {
                  handleColumnWiseSearch(null, "service_provider_id");
                } else {
                  setParams({
                    ...params,
                    service_provider_id: value.id,
                  });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="diagnostic_id"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 form-group">
            <label htmlFor="">Status</label>
            <select
              className="form-control"
              onChange={(e) => {
                handleColumnWiseSearch(e.target.value, "status");
              }}
            >
              <option value="">All</option>
              <option value="Pending">Pending</option>
              <option value="Processed">Processed</option>
              <option value="Ready">Ready</option>
              <option value="Cancelled">Cancelled</option>
            </select>
          </div>
          <div className="col-md-6 form-group">
            <label htmlFor="">Search </label>
            <input
              placeholder="Search"
              className="form-control"
              type="text"
              onChange={(e) => {
                handleColumnWiseSearch(e.target.value, "search");
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <table className="table custom-responsive-table">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Invoice ID</th>
                  <th scope="col">Test date</th>
                  <th scope="col">Patient</th>
                  <th scope="col">Diagnostic</th>
                  <th scope="col">Ref. Doctor</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Age</th>
                  <th scope="col">Address</th>
                  <th scope="col">Bill</th>
                  <th scope="col">Buy</th>
                  <th scope="col">Expense</th>
                  <th scope="col">Collection</th>
                  <th scope="col">Update</th>
                  <th scope="col">Type</th>
                  <th scope="col">Status</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {createdList.map((test, i) => (
                  <tr key={i}>
                    <td data-column="Invoice ID">{test.unique_id}</td>
                    <td data-column="Test Date">
                      {test.test_date != null ? (
                        moment(test.test_date).format("DD/MM/YYYY")
                      ) : (
                        <span style={{ visibility: "hidden" }}>Empty</span>
                      )}
                    </td>
                    <td data-column="Patient">
                      {test.patient_name !== null ? (
                        <span> {test.patient_name}</span>
                      ) : (
                        <React.Fragment>
                          {test.patient !== null &&
                            test.patient.name !== null && (
                              <span> {test.patient.name}</span>
                            )}
                        </React.Fragment>
                      )}
                    </td>
                    <td data-column="Diagnostic">
                      {test.service_provider.name}
                    </td>
                    <td data-column="Ref. Doctor">{test.doctor_name}</td>
                    <td data-column="Phone">
                      <React.Fragment>
                        {test.patient !== null &&
                          test.patient.phone !== null && (
                            <span>{test.patient.phone}</span>
                          )}
                      </React.Fragment>
                    </td>
                    <td data-column="Age">
                      {test.date_of_birth && (
                        <>{moment().diff(test.date_of_birth, "years")}</>
                      )}
                    </td>
                    <td data-column="Address">{""}</td>
                    <td data-column="Bill">{test.bill}</td>
                    <td data-column="Buy">
                      {admin && (
                        <div key={test.buying_price}>
                          <input
                            defaultValue={test.buying_price}
                            type="text"
                            name="buying_price"
                            className="form-control"
                            onChange={(e) => handleUpdateTestInput(test, e)}
                          />
                        </div>
                      )}
                    </td>
                    <td data-column="Expense">
                      <div key={test.expense}>
                        <input
                          defaultValue={test.expense}
                          type="text"
                          name="expense"
                          className="form-control"
                          onChange={(e) => handleUpdateTestInput(test, e)}
                        />
                      </div>
                    </td>
                    <td data-column="Collection">
                      <div key={test.collected_by}>
                        <input
                          defaultValue={test.collected_by}
                          type="text"
                          name="collected_by"
                          className="form-control"
                          onChange={(e) => handleUpdateTestInput(test, e)}
                        />
                      </div>
                    </td>
                    <td data-column="Update Test">
                      <button
                        onClick={() => updateTestInvoice(test.id)}
                        className="btn btn-sm btn-outline-secondary"
                      >
                        <i
                          class="fa fa-pencil"
                          style={{ fontSize: "16px" }}
                          aria-hidden="true"
                        ></i>
                      </button>
                    </td>
                    <td data-column="Type">
                      {test.type == "Corporate" || test.type == "Agent" ? (
                        <span>
                          {test.type} (
                          {test.referred_by && test.referred_by.name
                            ? test.referred_by.name
                            : ""}
                          )
                        </span>
                      ) : (
                        <span>{test.type}</span>
                      )}
                    </td>
                    <td data-column="Process">
                      {test.status != "Pending" ? (
                        <span>
                          {test.status == "Cancelled" && (
                            <span className="text-danger">{test.status}</span>
                          )}
                          {test.status == "Processed" && (
                            <span className="text-success">{test.status}</span>
                          )}
                          {test.status == "Ready" && (
                            <span className="text-primary">{test.status}</span>
                          )}
                        </span>
                      ) : (
                        <select
                          className=" form-control"
                          defaultValue={test.status}
                          onChange={(event) =>
                            updateStatus(test.id, event.target.value)
                          }
                        >
                          <option value="Pending">Pending</option>
                          <option value="Processed">Process</option>
                          <option value="Cancelled">Cancel</option>
                        </select>
                      )}
                    </td>
                    <td data-column="Action">
                      <Link
                        type="button"
                        to={`/admin/test/view/${test.id}`}
                        className="btn btn-sm btn-outline-primary m-1"
                      >
                        <i class="fa fa-eye" aria-hidden="true"></i>
                      </Link>
                      <button
                        onClick={() => downloadPDF(test.id)}
                        className="btn btn-sm btn-outline-success m-1"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="row">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}
