import Switch from "@material-ui/core/Switch";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
// import axios from "axios";
import { DoctorAPI } from "../../AxiosRequest/DoctorAPI";
import auth from "../../routingRules/auth";
import Footer from "../Shared/Footer";
import NavbarHome from "../Shared/NavbarHome";
import "./css/login.css";
import Navbar2 from "../Shared/Navbar2";

export default function Login() {
  let history = useHistory();
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  });
  const [loginErrors, setLoginErrors] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Please Enter a valid Email")
      .required("Email field is required"),
    password: Yup.string()
      .min(6, "Password must contain at least 6 characters")
      .required("Enter your password"),
  });
  const initialValues = {
    email: "",
    password: "",
  };

  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    setLoading(true);

    DoctorAPI.post("auth/login", {
      email: values.email,
      password: values.password,
    })
      .then((response) => {
        console.log(response);
        let loginToken = response.data.data.access_token;
        localStorage.setItem("HHDoctorToken", JSON.stringify(loginToken));
        localStorage.setItem(
          "doctorId",
          JSON.stringify(response.data.data.user_id)
        );
        console.log(loginToken);
        auth.login(() => {
          redirect();
        });
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response !== undefined) {
          let loginErrors = error.response.data.error;
          setLoginErrors(loginErrors);
        }

        setLoading(false);
      });
  };

  const redirect = () => {
    DoctorAPI.get("auth/me")
      .then((response) => {
        setLoading(false);
        if (response.data.data.type === "Regular") {
          history.push("/doctor/appointments");
        } else {
          history.push("/doctor/reports");
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response !== undefined) {
          let loginErrors = error.response.data.error;
          setLoginErrors(loginErrors);
        }

        setLoading(false);
      });
  };
  return (
    <div>
      <Navbar2 />
      <div className="login-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-3"></div>
            <div className="col-lg-6">
              <div className="login-form-section">
                <div className="text-center">
                  <LockOpenIcon />
                </div>

                <h3 className="text-center py-2">Doctor Login</h3>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  <Form>
                    <div className="form-group row ">
                      <div className="col-sm-12">
                        <Field
                          name="email"
                          type="text"
                          className="form-control"
                          placeholder="email"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>
                    <div className="form-group row ">
                      <div className="col-sm-12">
                        <Field
                          name="password"
                          type="password"
                          className="form-control"
                          placeholder="password"
                        />

                        <p className="error-message"> {loginErrors}</p>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>
                    <div className="form-group row ">
                      <div className="col-sm-6">
                        <Switch
                          checked={state.checkedB}
                          onChange={handleChange}
                          color="primary"
                          name="checkedB"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />{" "}
                        Remember Me
                      </div>
                      <div className="col-sm-6">
                        <button
                          type="submit"
                          className="btn btn-block btn-primary"
                          disabled={loading}
                        >
                          {loading && <i className="fa fa-refresh fa-spin"></i>}
                          {loading && <span>Signing In</span>}
                          {!loading && <span> Sign In</span>}
                        </button>
                      </div>
                    </div>
                    <div className="form-group row ">
                      <div className="col-sm-8">
                        Forgot your password?{" "}
                        <a type="button" href="#reset">
                          Reset
                        </a>
                      </div>
                    </div>
                    <div className="form-group row ">
                      <div className="col-sm-8">
                        Don't have an account? Register
                        <Link
                          className="px-1"
                          type="button"
                          to="/doctor/register"
                        >
                          here
                        </Link>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
            <div className="col-lg-3"></div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
