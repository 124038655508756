import React, { useState, useEffect } from "react";
import { TextField } from "formik-material-ui";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import axios from "axios";
import * as Yup from "yup";
import { HospitalAPI } from "../../../AxiosRequest/HospitalAPI";
export default function Profile() {
  const [policeStations, setPoliceStations] = useState([]);
  const [loading, setLoading] = useState(false);

  const [erroropen, setErrorOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const [patientData, setPatientData] = useState({});
  const [bloodGroupValue, setBloodGroupValue] = useState("");
  const [policeStationValue, setPoliceStationValue] = useState("");

  // Backend Error Messages
  const [nameErros, setNameErrors] = useState([]);
  const [phoneErros, setPhoneError] = useState([]);
  const [emailError, setEmailError] = useState([]);
  const [ageErrors, setAgeErrors] = useState([]);
  const [genderErrors, setGenderErrors] = useState([]);

  const [bloodGroupErrors, setBloodgroupErrors] = useState([]);

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Backend Errors Handle
  const [backendErrorMessage, setBackendErrorMessage] = useState({});

  useEffect(() => {
    getPatientData();
    GetPoliceStations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getPatientData = () => {
    HospitalAPI.get("auth/me")
      .then((response) => {
        console.log(response.data);

        setPatientData(response.data);
        setBloodGroupValue(response.data.blood_group);
        // setPoliceStationValue(response.data.police_station);
        // setPoliceStationValue(response.data.police_station);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const GetPoliceStations = () => {
    // let url = `http://localhost:3001/purchase_order/${paramID}`;
    axios
      .get("police-stations")
      .then((response) => {
        console.log(response.data.data);
        setPoliceStations(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name field is required"),
    phone: Yup.string().required("Please enter your phone number"),
  });
  const initialValues = {
    name: patientData.name || "",
    phone: patientData.phone || "",
    email: patientData.email || "",
    license_number: patientData.license_number || "",
    contact_person: patientData.contact_person || "",
    address: patientData.address || "",
    police_station_id: patientData.police_station_id || "",
  };

  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    setLoading(true);
    HospitalAPI.post("auth/me", values)
      .then((response) => {
        console.log(response);
        if (response.data.message) {
          setSuccessMessage(response.data.message);
          setOpen(true);
          setErrorOpen(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error.response);
        setLoading(false);
        if (error.response.data.message) {
          setErrorMessage(error.response.data.message);
          setErrorOpen(true);
          setOpen(false);
        }
        if (error.response.data.data !== undefined) {
          setErrorOpen(true);
          setOpen(false);
        }

        if (error.response.data.errors !== undefined) {
          setBackendErrorMessage(error.response.data.errors);
        }
      });
  };

  return (
    <div>
      <div className="doc-register-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-md-12">
                  <div className="doc-register-form">
                    <div className="text-center">
                      <LockOpenIcon />
                    </div>
                    <h1 className="text-center">HealthCare</h1>
                    <Formik
                      enableReinitialize
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      {({ setFieldValue, isValid, dirty }) => (
                        <Form>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label">
                              Name
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="name"
                                type="text"
                                className="form-control"
                                placeholder="Your Name"
                              />
                              <ErrorMessage
                                name="name"
                                component="div"
                                className="error-message"
                              />
                              {nameErros.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))}
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label">
                              Phone
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="phone"
                                type="text"
                                className="form-control"
                                placeholder="Phone/Mobile Number"
                              />
                              <ErrorMessage
                                name="phone"
                                component="div"
                                className="error-message"
                              />
                              {phoneErros.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))}
                            </div>
                          </div>

                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label">
                              Email
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="email"
                                type="text"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="error-message"
                              />
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label ">
                              Contact Person
                            </label>
                            <div className="col-sm-7">
                              <Field
                                className="form-control"
                                name="contact_person"
                              />

                              <ErrorMessage
                                name="contact_person"
                                component="div"
                                className="error-message"
                              />
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label ">
                              License Number
                            </label>
                            <div className="col-sm-7">
                              <Field
                                className="form-control"
                                name="license_number"
                              />

                              <ErrorMessage
                                name="license_number"
                                component="div"
                                className="error-message"
                              />
                            </div>
                          </div>

                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label">
                              Address
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="address"
                                type="text"
                                className="form-control"
                                placeholder="Your Address"
                              />
                              <ErrorMessage
                                name="address"
                                component="div"
                                className="error-message"
                              />
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label">
                              Police Stations
                            </label>
                            <div className="col-sm-7">
                              <Autocomplete
                                freeSolo
                                id="disabledHelperText"
                                name="police_station_id"
                                options={policeStations}
                                getOptionLabel={(option) =>
                                  option.name || policeStationValue
                                }
                                getOptionSelected={(option, value) => {
                                  return option.name === value.name;
                                }}
                                value={policeStationValue}
                                onChange={(e, value) => {
                                  if (value == null) {
                                    setFieldValue("police_station_id", "");
                                  } else {
                                    setFieldValue(
                                      "police_station_id",
                                      value.id
                                    );
                                  }
                                }}
                                renderInput={(params) => (
                                  <Field
                                    component={TextField}
                                    {...params}
                                    onChange={(e, value) => {
                                      console.log(e.target.value);
                                      setFieldValue("police_station_id", 0);
                                    }}
                                    name="police_station_id"
                                    variant="outlined"
                                    fullWidth
                                  />
                                )}
                              />
                              <ErrorMessage
                                name="police_station_id"
                                component="div"
                                className="error-message"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 my-4">
                              <Collapse in={open}>
                                <Alert
                                  action={
                                    <IconButton
                                      aria-label="close"
                                      color="inherit"
                                      size="small"
                                      onClick={() => {
                                        setOpen(false);
                                      }}
                                    >
                                      <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                  }
                                >
                                  {successMessage}
                                </Alert>
                              </Collapse>
                              <Collapse in={erroropen}>
                                <Alert
                                  severity="error"
                                  action={
                                    <IconButton
                                      aria-label="close"
                                      color="inherit"
                                      size="small"
                                      onClick={() => {
                                        setErrorOpen(false);
                                      }}
                                    >
                                      <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                  }
                                >
                                  {errorMessage}
                                </Alert>
                              </Collapse>
                            </div>
                          </div>

                          <div className="form-group row ">
                            <label className="col-sm-5 offset-sm-1">
                              <button
                                disabled={loading}
                                // disabled={!isValid || !dirty}
                                type="submit"
                                className="btn btn-lg btn-success"
                              >
                                {loading && (
                                  <i className="fa fa-refresh fa-spin"></i>
                                )}
                                {loading && <span>Updating</span>}
                                {!loading && <span> Update</span>}
                              </button>
                            </label>
                            <div className="col-sm-7"></div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2"></div>
        </div>
      </div>
    </div>
  );
}

const bloodGroups = [
  { name: "A+" },
  { name: "B+" },
  { name: "O+" },
  { name: "AB+" },
  { name: "A-" },
  { name: "B-" },
  { name: "O-" },
  { name: "AB-" },
];
