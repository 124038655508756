import DateFnsUtils from "@date-io/date-fns";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import { TimePicker } from "formik-material-ui-pickers";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { DoctorAPI } from "../../AxiosRequest/DoctorAPI";
import "./css/doc-dashboard.css";

// Depending on the library you picked

export default function AddSchedule() {
  let { editScheduleid } = useParams();
  const [open, setOpen] = useState(false);
  const [erroropen, setErrorOpen] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [startErros, setStartErros] = useState([]);
  const [endErros, setEndErros] = useState([]);
  const [dayErros, setDayErros] = useState([]);
  const [locationErros, setLocationErros] = useState([]);
  const [location, setLocation] = useState("");
  const [schedulesData, setSchedulesData] = useState([]);
  const [sameTimeError, setSameTimeErrors] = useState([]);
  const [selectedStartTime, setSelectedStartTime] = React.useState(
    new Date("2014-08-18T00:00:00")
  );
  const [selectedEndTime, setSelectedEndTime] = React.useState(
    new Date("2014-08-18T00:00:00")
  );

  useEffect(() => {
    if (editScheduleid !== "" && editScheduleid !== undefined) {
      GetAllSchedules();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const GetAllSchedules = () => {
    // let url = `http://localhost:3001/purchase_order/${paramID}`;
    DoctorAPI.get(`schedules/${editScheduleid}`)
      .then((response) => {
        setSchedulesData(response.data.data);
        console.log(response.data.data.location);
        setLocation(response.data.data.location);
        setSelectedStartTime(
          new Date(`2014-08-18T${response.data.data.start_time}`)
        );

        setSelectedEndTime(
          new Date(`2014-08-18T${response.data.data.end_time}`)
        );
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const validationSchema = Yup.object({
    start_time: Yup.string().required("This field is required"),
    end_time: Yup.string().required("This field is required"),
    day_name: Yup.string().required("This field is required"),
    location: Yup.string().required("This field is required"),
  });
  const initialValues = {
    start_time: selectedStartTime || "",
    end_time: selectedEndTime || "",
    day_name: schedulesData.day_name || "",
    location: location || "",
  };

  const onSubmit = (values, { resetForm }) => {
    let start = moment(values.start_time).format("HH:mm:ss");
    let end = moment(values.end_time).format("HH:mm:ss");
    if (editScheduleid !== "" && editScheduleid !== undefined) {
      DoctorAPI.put(`schedules/${editScheduleid}`, {
        start_time: start,
        end_time: end,
        day_name: values.day_name,
        location: values.location,
      })
        .then((response) => {
          console.log(response);
          if (response.data.message) {
            setSuccessMsg(response.data.message);
            setOpen(true);
            setErrorOpen(false);
            resetForm();
          }
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response.data.message) {
            setErrorMsg(error.response.data.message);
            setErrorOpen(true);
            setOpen(false);
          }
          if (error.response.data.data !== undefined) {
            setErrorOpen(true);
            setOpen(false);
            setSameTimeErrors(error.response.data.data);
          }
          if (error.response.data.errors !== undefined) {
            let start = error.response.data.errors.start_time;
            let end = error.response.data.errors.end_time;
            let day_name = error.response.data.errors.day_name;
            let location = error.response.data.errors.location;

            if (start !== undefined) {
              setStartErros(start);
            }
            if (end !== undefined) {
              setEndErros(end);
            }
            if (day_name !== undefined) {
              setDayErros(day_name);
            }

            if (location !== undefined) {
              setLocationErros(location);
            }
          }
        });
    } else {
      DoctorAPI.post("schedules", {
        start_time: start,
        end_time: end,
        day_name: values.day_name,
        location: values.location,
      })
        .then((response) => {
          console.log(response);
          if (response.data.message) {
            setSuccessMsg(response.data.message);
            setOpen(true);
            setErrorOpen(false);
          }
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response.data.message) {
            setErrorMsg(error.response.data.message);
            setErrorOpen(true);
            setOpen(false);
          }
          if (error.response.data.data !== undefined) {
            setErrorOpen(true);
            setOpen(false);
            setSameTimeErrors(error.response.data.data);
          }
          if (error.response.data.errors !== undefined) {
            let start = error.response.data.errors.start_time;
            let end = error.response.data.errors.end_time;
            let day_name = error.response.data.errors.day_name;
            let location = error.response.data.errors.location;

            if (start !== undefined) {
              setStartErros(start);
            }
            if (end !== undefined) {
              setEndErros(end);
            }
            if (day_name !== undefined) {
              setDayErros(day_name);
            }

            if (location !== undefined) {
              setLocationErros(location);
            }
          }
        });
    }
  };
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-md-12">
                <div className="add-doc-schedule">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Formik
                      enableReinitialize
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      {({ setFieldValue, isValid, dirty }) => (
                        <Form>
                          <div className="form-group row ">
                            <label className="col-md-3 offset-md-1">
                              Start Time
                            </label>
                            <div className="col-sm-7">
                              {/* <Field component={TimePicker} name="start_time" /> */}
                              <TimePicker
                                autoOk
                                value={selectedStartTime}
                                onChange={setSelectedStartTime}
                              />

                              <ErrorMessage
                                name="start_time"
                                component="div"
                                className="error-message"
                              />
                              {startErros.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))}
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-md-3 offset-md-1">
                              End Time
                            </label>
                            <div className="col-sm-7">
                              <TimePicker
                                autoOk
                                value={selectedEndTime}
                                onChange={setSelectedEndTime}
                              />

                              <ErrorMessage
                                name="end_time"
                                component="div"
                                className="error-message"
                              />
                              {endErros.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))}
                            </div>
                          </div>

                          <div className="form-group row ">
                            <label className="col-md-3 offset-md-1">Day</label>
                            <div className="col-sm-7">
                              <Field
                                className="form-control"
                                as="select"
                                name="day_name"
                              >
                                <option value=""></option>
                                <option value="Saturday">Saturday</option>
                                <option value="Sunday">Sunday</option>
                                <option value="Monday">Monday</option>
                                <option value="Tuesday">Tuesday</option>
                                <option value="Wednesday">Wednesday</option>
                                <option value="Thursday">Thursday</option>
                                <option value="Friday">Friday</option>
                              </Field>
                              <ErrorMessage
                                name="day_name"
                                component="div"
                                className="error-message"
                              />
                              {dayErros.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))}
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-md-3 offset-md-1">
                              Location
                            </label>
                            <div className="col-sm-7">
                              <Autocomplete
                                freeSolo
                                id="disabledHelperText"
                                name="location"
                                getOptionLabel={(option) =>
                                  option.name || location
                                }
                                value={location}
                                options={locations}
                                onChange={(event, value) => {
                                  if (value == null) {
                                    console.log("null");
                                  } else {
                                    setFieldValue("location", value.name);
                                  }

                                  console.log(value);
                                }}
                                onInputCapture={(event) => {
                                  event.persist(); // As we are accessing the event in asynchronous way.

                                  setFieldValue("location", event.target.value);
                                }}
                                renderInput={(params) => (
                                  <Field
                                    component={TextField}
                                    {...params}
                                    onChange={(event) => {
                                      console.log(
                                        "this is input",
                                        event.target.value
                                      );

                                      console.log(event.target.value);
                                    }}
                                    name="location"
                                    variant="outlined"
                                    fullWidth
                                  />
                                )}
                              />
                              <ErrorMessage
                                name="location"
                                component="div"
                                className="error-message"
                              />
                              {locationErros.map((item, i) => (
                                <p className="error-message" key={i}>
                                  {item}
                                </p>
                              ))}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 my-4">
                              <Collapse in={open}>
                                <Alert
                                  action={
                                    <IconButton
                                      aria-label="close"
                                      color="inherit"
                                      size="small"
                                      onClick={() => {
                                        setOpen(false);
                                      }}
                                    >
                                      <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                  }
                                >
                                  {successMsg}
                                </Alert>
                              </Collapse>
                              <Collapse in={erroropen}>
                                <Alert
                                  severity="error"
                                  action={
                                    <IconButton
                                      aria-label="close"
                                      color="inherit"
                                      size="small"
                                      onClick={() => {
                                        setErrorOpen(false);
                                      }}
                                    >
                                      <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                  }
                                >
                                  {errorMsg}
                                </Alert>
                              </Collapse>

                              {sameTimeError.map((item, i) => (
                                <Collapse key={i} in={erroropen}>
                                  <Alert
                                    style={{ margin: "5px 0px" }}
                                    severity="error"
                                    action={
                                      <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                          setErrorOpen(false);
                                        }}
                                      >
                                        <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                    }
                                  >
                                    {item.day_name} {item.start_time}
                                    {"-"}
                                    {item.end_time} {item.location}
                                  </Alert>
                                </Collapse>
                              ))}
                            </div>
                          </div>
                          <div className="form-group row ">
                            <div className="col-md-7 offset-md-1">
                              <button
                                type="submit"
                                className="btn btn-lg btn-success"
                              >
                                {" "}
                                Submit
                              </button>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </div>
    </div>
  );
}
const locations = [{ name: "Online" }];
