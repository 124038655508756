import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { HospitalAPI } from "../../../AxiosRequest/HospitalAPI";

import "../css/add-institution.css";

export default function PatientDetails() {
  let { pId } = useParams();
  const [patientDetails, setPatientDetails] = useState({});

  useEffect(() => {
    getPatientDetails();
  }, []);

  const getPatientDetails = () => {
    HospitalAPI.get(`patients/${pId}`)
      .then((response) => {
        console.log("patient details", response.data);
        setPatientDetails(response.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {patientDetails !== undefined && (
              <div className="patient-details-admin">
                <div className="d-flex justify-content-lg-end justify-content-sm-start">
                  <div className=""></div>
                </div>

                <div className="row">
                  <div className="col-md-2 offset-md-1">Name</div>
                  <div className="col-sm-7">{patientDetails.name}</div>
                </div>
                <div className="row">
                  <div className="col-md-2 offset-md-1">Phone</div>
                  <div className="col-sm-7">{patientDetails.phone}</div>
                </div>
                <div className="row">
                  <div className="col-md-2 offset-md-1">Email</div>
                  <div className="col-sm-7">{patientDetails.email}</div>
                </div>
                <div className="row">
                  <div className="col-md-2 offset-md-1">Date of Birth</div>
                  <div className="col-sm-7">{patientDetails.date_of_birth}</div>
                </div>
                <div className="row">
                  <div className="col-md-2 offset-md-1">Blood Group</div>
                  <div className="col-sm-7">{patientDetails.blood_group}</div>
                </div>
                <div className="row">
                  <div className="col-md-2 offset-md-1">NID</div>
                  <div className="col-sm-7">{patientDetails.nid}</div>
                </div>
                <div className="row">
                  <div className="col-md-2 offset-md-1">Passport</div>
                  <div className="col-sm-7">{patientDetails.passport}</div>
                </div>
                <div className="row">
                  <div className="col-md-2 offset-md-1">Gender</div>
                  <div className="col-sm-7">{patientDetails.gender}</div>
                </div>
                <div className="row">
                  <div className="col-md-2 offset-md-1">Marital Status</div>
                  <div className="col-sm-7">
                    {patientDetails.marital_status}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 offset-md-1">Area</div>
                  <div className="col-sm-7">{patientDetails.area}</div>
                </div>

                <div className="row">
                  <div className="col-md-2 offset-md-1">Status</div>
                  <div className="col-sm-7">{patientDetails.status}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
