import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { TextField as TextFieldSecond } from "formik-material-ui";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  // useHistory,

  useParams,
} from "react-router-dom";
import { Link } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import * as Yup from "yup";
import { Layout } from "antd";
// import "../css/add-institution.css";
import { DiagnosticAPI } from "../../../AxiosRequest/DiagnosticAPI";

const { Content } = Layout;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function AddTest() {
  // let history = useHistory();
  let { medId } = useParams();
  const [open, setOpen] = useState(false);
  const [erroropen, setErrorOpen] = useState(false);

  const [testList, setTestList] = useState([]);

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [homeSample, setHomeSample] = React.useState(false);
  const [testData, setTestData] = useState({});
  let paramID = parseInt(medId);

  // Backend Errors Handle
  const [backendErrorMessage, setBackendErrorMessage] = useState({});

  const [sameMedicineErrors, setSameMedicineErrors] = useState([]);

  const handleChangeSample = (event) => {
    setHomeSample(event.target.checked);
  };
  useEffect(() => {
    getTestList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTestList = () => {
    axios
      .get("tests")
      .then((response) => {
        setTestList(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const validationSchema = Yup.object({
    test_id: Yup.number().required("This field is required"),
    price: Yup.number().required("This field is required"),
    discount: Yup.number().required("This field is required"),
    discount_type: Yup.string().required("This field is required"),
    required_time: Yup.number().required("This field is required"),
  });
  const initialValues = {
    test_id: testData.test_id || "",
    price: testData.price || "",
    discount: testData.discount || "",
    discount_type: testData.discount_type || "",
    required_time: testData.required_time || "",
  };

  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    values.home_delivery = homeSample;

    if (medId) {
      DiagnosticAPI.post("medicine/update", values)
        .then((response) => {
          console.log(response);
          if (response.data.message) {
            setSuccessMessage(response.data.message);
            setOpen(true);
            setErrorOpen(false);
          }
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response.data.errors) {
            setErrorMessage(error.response.data.errors);
            setErrorOpen(true);
            setOpen(false);
          }

          if (error.response.data.message) {
            setErrorMessage(error.response.data.message);
            setErrorOpen(true);
            setOpen(false);
          }
          if (error.response.data.data !== undefined) {
            setErrorOpen(true);
            setOpen(false);
            setSameMedicineErrors([
              ...sameMedicineErrors,
              error.response.data.data,
            ]);
          }

          if (error.response.data.errors !== undefined) {
            setBackendErrorMessage(error.response.data.errors);
          }
        });
    } else
      DiagnosticAPI.post("tests", values)
        .then((response) => {
          console.log(response);
          if (response.data.message) {
            setSuccessMessage(response.data.message);
            setOpen(true);
            setErrorOpen(false);
          }
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response.data.message) {
            setErrorMessage(error.response.data.message);
            setErrorOpen(true);
            setOpen(false);
          }
          if (error.response.data.data !== undefined) {
            setErrorOpen(true);
            setOpen(false);
            setSameMedicineErrors([
              ...sameMedicineErrors,
              error.response.data.data,
            ]);
          }

          if (error.response.data.errors !== undefined) {
            setBackendErrorMessage(error.response.data.errors);
          }
        });
  };
  return (
    <div>
      <Layout style={{ minHeight: "100vh" }}>
        <Content style={{ margin: "0 16px" }}>
          <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 22 }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-right">
                  <Link
                    to="/diagnostic/tests/list"
                    type="button"
                    className="btn btn-lg btn-success"
                  >
                    Test List
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3"></div>
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="add-institution">
                        <Formik
                          enableReinitialize
                          initialValues={initialValues}
                          validationSchema={validationSchema}
                          onSubmit={onSubmit}
                        >
                          {({ setFieldValue, isValid, dirty }) => (
                            <Form>
                              <div className="form-group row ">
                                <label className="col-sm-3 offset-sm-1">
                                  Test Name
                                </label>
                                <div className="col-sm-7">
                                  <Autocomplete
                                    id="disabledHelperText"
                                    name="test_id"
                                    options={testList}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(e, value) => {
                                      if (value == null) {
                                        console.log("Hello");
                                        setFieldValue("test_id", "");
                                      } else {
                                        console.log(value);
                                        console.log(value.id);
                                        setFieldValue("test_id", value.id);
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <Field
                                        component={TextFieldSecond}
                                        {...params}
                                        name="test_id"
                                        variant="outlined"
                                        fullWidth
                                      />
                                    )}
                                  />
                                  <ErrorMessage
                                    name="test_id"
                                    component="div"
                                    className="error-message"
                                  />

                                  <p className="error-message">
                                    {backendErrorMessage.test_id}
                                  </p>
                                </div>
                              </div>
                              <div className="form-group row ">
                                <label className="col-sm-3 offset-sm-1">
                                  Price
                                </label>
                                <div className="col-sm-7">
                                  <Field
                                    className=" form-control"
                                    type="test"
                                    name="price"
                                  />
                                  <ErrorMessage
                                    name="price"
                                    component="div"
                                    className="error-message"
                                  />
                                  <p className="error-message">
                                    {backendErrorMessage.price}
                                  </p>
                                </div>
                              </div>
                              <div className="form-group row ">
                                <label className="col-sm-3 offset-sm-1">
                                  Discount
                                </label>
                                <div className="col-sm-7">
                                  <Field
                                    className=" form-control"
                                    type="test"
                                    name="discount"
                                  />
                                  <ErrorMessage
                                    name="discount"
                                    component="div"
                                    className="error-message"
                                  />
                                  <p className="error-message">
                                    {backendErrorMessage.discount}
                                  </p>
                                </div>
                              </div>
                              <div className="form-group row ">
                                <label className="col-sm-3 offset-sm-1">
                                  Discount Type
                                </label>
                                <div className="col-sm-7">
                                  <Field
                                    className=" form-control"
                                    as="select"
                                    name="discount_type"
                                  >
                                    <option value="%">Percentage</option>

                                    <option value="flat">Flat</option>
                                  </Field>
                                  <ErrorMessage
                                    name="discount_type"
                                    component="div"
                                    className="error-message"
                                  />
                                  <p className="error-message">
                                    {backendErrorMessage.discount_type}
                                  </p>
                                </div>
                              </div>
                              <div className="form-group row ">
                                <label className="col-sm-3 offset-sm-1">
                                  Required Time
                                </label>
                                <div className="col-sm-7">
                                  <Field
                                    className=" form-control"
                                    type="text"
                                    name="required_time"
                                  />
                                  <ErrorMessage
                                    name="required_time"
                                    component="div"
                                    className="error-message"
                                  />
                                  <p className="error-message">
                                    {backendErrorMessage.required_time}
                                  </p>
                                </div>
                              </div>

                              <div className="form-group row ">
                                <label className="col-sm-3 offset-sm-1"></label>
                                <div className="col-sm-7">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={homeSample}
                                        onChange={handleChangeSample}
                                      />
                                    }
                                    label="Home Sample Delivery"
                                  />
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-12 my-4">
                                  <Collapse in={open}>
                                    <Alert
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setOpen(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      {successMessage}
                                    </Alert>
                                  </Collapse>
                                  <Collapse in={erroropen}>
                                    <Alert
                                      severity="error"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setErrorOpen(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      {errorMessage}
                                    </Alert>
                                  </Collapse>

                                  {sameMedicineErrors.map((item, i) => (
                                    <Collapse key={i} in={erroropen}>
                                      <Alert
                                        style={{ margin: "5px 0px" }}
                                        severity="error"
                                        action={
                                          <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                              setErrorOpen(false);
                                            }}
                                          >
                                            <CloseIcon fontSize="inherit" />
                                          </IconButton>
                                        }
                                      >
                                        Brand Name: {item.brand_name}, Generic
                                        Name:{item.generic_name}, Company ID :{" "}
                                        {item.company_id}, Dosage Form:{" "}
                                        {item.dosage_form}
                                      </Alert>
                                    </Collapse>
                                  ))}
                                </div>
                              </div>
                              <div className="form-group row ">
                                <label className="col-sm-3 offset-sm-1"></label>
                                <div className="col-sm-7 text-right">
                                  <button
                                    type="submit"
                                    className="btn btn-lg btn-success"
                                  >
                                    {medId ? "Update" : "Submit"}
                                  </button>
                                </div>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3"></div>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </div>
  );
}
