import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// import { PatientAPI } from "../../../AxiosRequest/PatientAPI";
import { Link } from "react-router-dom";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
import axios from "axios";
// import _ from "lodash";
export default function UploadedTest() {
  let history = useHistory();
  const [uploadedList, setUploadedList] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);

  const [params, setParams] = useState({
    per_page: 15,
    page: 1,
  });

  useEffect(() => {
    getUploadedTest();
  }, [params]);

  const getUploadedTest = () => {
    axios
      .get(`uploaded-tests`, { params: params })
      .then((response) => {
        console.log(response.data.data);
        setUploadedList(response.data.data.data);
        setTotalPage(response.data.data.last_page);
        setPage(response.data.data.current_page);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };
  const routeToUploadPrescription = () => {
    history.push(`/agent/test/upload-prescription`);
  };
  const downloadPDF = (id) => {
    console.log("iddddd", id);
    axios
      .get(`test-invoices/${id}/pdf`)
      .then((response) => {
        console.log("tetttt", response.data.data);
      })
      .catch((error) => {});
  };
  return (
    <div>
      <div className="container">
        <div className=" d-flex justify-content-lg-end justify-content-sm-start">
          <button
            onClick={routeToUploadPrescription}
            className="btn btn-sm btn-outline-primary"
          >
            Upload Prescription
          </button>
        </div>
        <div className="row">
          <div className="col-md-12">
            <table className="custom-responsive-table">
              <thead>
                <tr>
                  <th scope="col">Uploaded Date</th>
                  <th scope="col">Diagnostic</th>

                  <th scope="col">Reviewed By</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {uploadedList.map((test, i) => (
                  <tr key={i}>
                    <td data-column="Uploaded Date">
                      {moment(test.created_at).format("DD/MM/YYYY hh:mm:ss A")}
                    </td>
                    <td data-column="Diagnostic">
                      {test.service_provider.name}
                    </td>

                    <td data-column="Reviewed By">
                      {test.user !== null ? test.user.name : ""}
                    </td>
                    <td data-column="Status"> {test.status}</td>
                    <td data-column="Action">
                      {" "}
                      {test.status === "Reviewed" ? (
                        <div>
                          <Link
                            type="button"
                            to={`/agent/test/uploaded-test/${test.id}`}
                            className="btn btn-sm btn-primary"
                          >
                            File
                          </Link>
                          <span>&nbsp;</span>
                          <Link
                            type="button"
                            to={`/agent/test/test-invoices/${test.test_invoice.id}`}
                            className="btn btn-sm btn-outline-primary"
                          >
                            Invoice
                          </Link>
                        </div>
                      ) : (
                        <Link
                          type="button"
                          to={`/agent/test/uploaded-test/${test.id}`}
                          className="btn btn-sm btn-primary"
                        >
                          File
                        </Link>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="row">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}
