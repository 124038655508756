import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { DiagnosticAPI } from "../../../AxiosRequest/DiagnosticAPI";
import Pagination from "@material-ui/lab/Pagination";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import _ from "lodash";

export default function IncomingInvoiceList() {
  const [invoiceList, setInvoiceList] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [invoiceId, setInvoiceId] = useState(Number);

  const [defaultStatus, setDefaultStatus] = useState("");
  const [selecedStatus, setSelectedStatus] = useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [params, setParams] = useState({
    per_page: 15,
    page: 1,
  });

  useEffect(() => {
    getInvoiceList();
  }, [params]);

  const getInvoiceList = () => {
    DiagnosticAPI.get(`incoming-invoices`, { params: params })
      .then((response) => {
        setInvoiceList(response.data.data.data);
        setTotalPage(response.data.data.last_page);
        setPage(response.data.data.current_page);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };

  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);
  
  return (
    <div>
      <div className="container">
        <div className="row py-1">
          <div className="col-md-4 form-group">
            <select
              className="form-control"
              onChange={(e) => {
                handleColumnWiseSearch(e.target.value, "status");
              }}
            >
              <option value="">All</option>
              <option value="Pending">Pending</option>
              <option value="Ready">Ready</option>
              <option value="Approved">Approved</option>
            </select>
          </div>
          <div className="col-md-8 text-right form-group">
            <input
              placeholder="Search"
              className="form-control"
              type="text"
              onChange={(e) => handleColumnWiseSearch(e.target.value, "search")}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className=" table-overflow">
              <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Lab Inv ID</th>

                    <th scope="col">Price</th>
                    <th scope="col">Discount</th>
                    <th scope="col">Bill</th>
                    <th scope="col">H/C</th>
                    <th scope="col">Patient Name</th>
                    <th scope="col">Patient Contact</th>
                    <th scope="col">Patient Address</th>
                    <th scope="col">Date</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceList.length > 0 &&
                    invoiceList.map((invoice, i) => (
                      <tr key={i}>
                        <td>{invoice.lab_invoice_id}</td>

                        <td>{Math.ceil(invoice.price)}</td>
                        <td>{invoice.discount}</td>
                        <td>{invoice.bill}</td>
                        <td>{invoice.home_collection ? "Yes" : "No"}</td>
                        <td>{invoice.patient_name}</td>
                        <td>{invoice.patient_phone}</td>
                        <td>{invoice.patient_address}</td>
                        <td>{invoice.test_date}</td>
                        <td>{invoice.status}</td>
                        <td>
                          <Link
                            to={`/diagnostic/incoming-invoice/view/${invoice.id}`}
                            type="button"
                            className="btn btn-sm btn-primary mb-1"
                          >
                            <VisibilityIcon />
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}
