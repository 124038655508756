import React, { useState, useEffect } from "react";
// import { Button, Modal } from "react-bootstrap";
import Alert from "@material-ui/lab/Alert";
import { useParams } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import { PatientAPI } from "../../../AxiosRequest/PatientAPI";
import moment from "moment";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { TextField as TextFieldSecond } from "formik-material-ui";
import * as Yup from "yup";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "../css/global.css";

export default function UploadPrescription() {
  const [successMsg, setSuccessMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [diagnosticList, setDiagnosticList] = useState([]);
  const [diagnosticValue, setDiagnosticValue] = useState("");
  const [files, setFiles] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [preObj, setPreObj] = useState({});

  const [erroropen, setErrorOpen] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    const upTestObj = localStorage.getItem("upTestObj")
      ? JSON.parse(localStorage.getItem("upTestObj"))
      : {};
    if (upTestObj) {
      setDiagnosticValue(upTestObj.diagnosticValue);
      setPreviews(upTestObj.images);
      setPreObj(upTestObj);

      // setPreviews(file);
    }
    // if (localStorage.getItem("blob") === null) {
    //   localStorage.setItem("blob", JSON.stringify([]));
    // } else {
    //   let localPreviews = JSON.parse(localStorage.getItem("blob"));
    //   setPreviews(localPreviews);
    // }
    // const images = localStorage.getItem("testImages")
    //   ? JSON.parse(localStorage.getItem("testImages"))
    //   : [];
    // if (images.length > 0) {
    //   setUpfiles(images);

    //   // const mappedFiles = images.map((file) => ({
    //   //   ...file,
    //   //   preview: URL.createObjectURL(file),
    //   // }));

    //   // setPreviews(mappedFiles);
    // }

    // if (file === "upload") {
    //   localStorage.removeItem("fileUpload");
    // }

    getDiagnosticList();
  }, []);
  useEffect(() => {
    if (previews !== undefined) {
      setFilesToInput();
    }
    // }   await
    // Execute the created function directly
    // anyNameFunction();
  }, [previews]);

  console.log("previessssss", previews);
  const setFilesToInput = async () => {
    const fileArraysTOSend = [];
    const pArr = [...previews];
    console.log("p arrrrrrrrrrr", pArr);
    for (let i = 0; i < pArr.length; i++) {
      const file = await getFile(pArr[i]);
      fileArraysTOSend.push(file);
    }

    console.log("filessssss", fileArraysTOSend);
    setFiles(fileArraysTOSend);
  };

  const uploadMultipleFiles = async (e) => {
    const fileList = Array.from(e.target.files);
    if (checkMimeType(e)) {
      setFiles(fileList);
      localStorage.removeItem("upTestObj");
      const filePathsPromises = [];
      // console.log("files", files);
      for (let i = 0; i < fileList.length; i++) {
        let file = fileList[i];
        let bseFile = getBase64(file);

        const a = await bseFile;
        console.log("aaaaaaaa", a);

        let obj = {
          originalName: file.name,
          mimetype: file.type,
          preview: a,
        };
        filePathsPromises.push(obj);
      }
      // fileList.forEach(async (file) => {

      // });
      console.log("base 64, arrra", filePathsPromises);
      setPreviews([...filePathsPromises]);
      // const mappedFiles = fileList.map((file) => ({
      //   ...file,
      //   preview: URL.createObjectURL(file),
      // }));

      // setPreviews(mappedFiles);
      // if return true allow to setState
    }
  };
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  // const maxSelectFile = (event) => {
  //   let files = event.target.files; // create file object
  //   if (files.length > 5) {
  //     const msg = "Only 5 images can be uploaded at a time";
  //     event.target.value = null; // discard selected file
  //     alert(msg);
  //     return false;
  //   }
  //   return true;
  // };

  const checkMimeType = (event) => {
    //getting file object
    let files = event.target.files;
    //define message container
    let err = "";
    // list allow mime type
    //  "application/pdf"
    const types = ["image/png", "image/jpeg", "image/gif", "application/pdf"];
    // loop access array
    for (var x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every((type) => files[x].type !== type)) {
        // create error message and assign to container
        err += files[x].type + " is not a supported format\n";
      }
    }

    if (err !== "") {
      // if message not same old that mean has error
      event.target.value = null; // discard selected file
      alert(err);
      return false;
    }
    return true;
  };
  const getDiagnosticList = () => {
    axios
      .get("diagnostics")
      .then((response) => {
        setDiagnosticList(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const getFile = async (paramObj) => {
    const { preview, originalName, mimetype } = paramObj;
    const blob = await fetch(preview).then((r) => r.blob());
    const newfileObj = new File([blob], `${originalName}`, { type: mimetype });
    return newfileObj;
  };
  const onSubmit = async (values, { resetForm }) => {
    console.log("file array", files);
    // const fileArraysTOSend = [];
    // const pArr = [...previews];
    // for (let i = 0; i < pArr.length; i++) {
    //   const file = await getFile(pArr[i]);
    //   fileArraysTOSend.push(file);
    // }

    // console.log("file array to ", fileArraysTOSend);
    // setFiles(fileArraysTOSend);

    // let upFIle = [];
    // for (let i = 0; i < upFiles.length; i++) {
    //   const data = dataURItoBlob(upFiles[i]);
    //   upFIle.push(data);
    // }
    // console.log("data", upFIle);
    let formData = new FormData();
    if (files.length > 0) {
      // data.append("reports", files);
      console.log(values, preObj);
      formData.append(
        "service_provider_id",
        values.service_provider_id || preObj.diagnosticID
      );
      for (var x = 0; x < files.length; x++) {
        formData.append("prescriptions[]", files[x]);
      }
    }

    // // console.log("files", formData);

    PatientAPI.post(`uploaded-tests`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        console.log(response);
        setSuccessMsg("Your request has been submitted");
        setErrorOpen(false);
        setOpen(true);
        localStorage.removeItem("upTestObj");
      })
      .catch((error) => {
        console.log(error.response);
        setErrorOpen(true);

        if (error.response.data.errors !== undefined) {
          setErrorMsg(error.response.data.errors.service_provider_id[0]);
          // setErrorMsgSameDay(
          //   error.response.data.errors.appointment[0].appointment_time
          // );
        }
      });
  };
  const validationSchema = Yup.object({
    service_provider_id: Yup.number().required("This field is required"),
  });
  const initialValues = {
    service_provider_id: "" || preObj.diagnosticID,
  };
  return (
    <div>
      <div className="container">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ setFieldValue, isValid, dirty }) => (
            <Form style={{ backgroundColor: "white", padding: "15px" }}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group multi-preview">
                    {(previews || []).map((url, key) => (
                      <img key={key} src={url.preview} alt="..." />
                    ))}
                  </div>
                  {/* <div className="form-group multi-preview">
                    {(upFiles || []).map((url, key) => (
                      <img key={key} src={url} alt="..." />
                    ))}
                  </div> */}

                  <div className="form-group">
                    <input
                      type="file"
                      className="form-control"
                      onChange={uploadMultipleFiles}
                      multiple
                    />
                    <p>You can upload your prescriptions here </p>
                  </div>
                </div>
              </div>
              <div className="form-group row ">
                <label className="col-sm-3 offset-sm-1">
                  Select Diagnostic
                </label>
                <div className="col-sm-7">
                  <Autocomplete
                    id="disabledHelperText"
                    name="service_provider_id"
                    options={diagnosticList.filter((dig) => dig.id !== 1)}
                    getOptionLabel={(option) => option.name || diagnosticValue}
                    value={diagnosticValue}
                    onChange={(e, value) => {
                      if (value == null) {
                        console.log("Hello");
                      } else {
                        setFieldValue("service_provider_id", value.id);
                        // console.log(value.id);
                      }
                    }}
                    renderInput={(params) => (
                      <Field
                        component={TextFieldSecond}
                        {...params}
                        onChange={(e, value) => {
                          console.log(e.target.value);
                          setFieldValue("service_provider_id", 0);
                        }}
                        name="service_provider_id"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                  <ErrorMessage
                    name="service_provider_id"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>

              <div className="py-3">
                <Collapse in={open}>
                  <Alert
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {successMsg}
                  </Alert>
                </Collapse>
              </div>
              <div className="py-3">
                <Collapse in={erroropen}>
                  <Alert
                    severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setErrorOpen(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {errorMsg}
                  </Alert>
                </Collapse>
              </div>
              <div className="form-group row ">
                <label className="col-sm-3 offset-sm-1"></label>
                <div className="col-sm-7 text-right">
                  <button
                    // disabled={files.length === 0}
                    // onClick={(event) => {
                    //   event.preventDefault();

                    //   uploadPrescriptions();
                    // }}
                    type="submit"
                    className="btn btn-lg btn-outline-success"
                  >
                    Upload Prescription
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
