import React, { useState, useEffect } from "react";
import { PatientAPI } from "../../AxiosRequest/PatientAPI";
import "./css/prescription.css";
import { useParams } from "react-router-dom";

export default function ShowPrescription() {
  let { appointmentId } = useParams();
  const [prescription, setPrescription] = useState({});
  const [prescribedMedcine, setPrescribedMedicine] = useState([]);
  const [prescribedTest, setPrescribedTest] = useState([]);
  const [prescribedObservation, setPrescribedObservation] = useState([]);
  const [prescribedDiagnosis, setPrescribedDiagnosis] = useState([]);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [printing, setPrinting] = useState(false);

  const paramID = parseInt(appointmentId);

  useEffect(() => {
    getPrescription();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPrescription = () => {
    PatientAPI.get(`prescriptions/${paramID}`)
      .then((response) => {
        setPrescribedTest(response.data.data.prescribed_tests);
        setPrescribedObservation(response.data.data.prescribed_observations);
        setPrescription(response.data.data);
        setComment(response.data.data.comment);
        setPrescribedDiagnosis(response.data.data.prescribed_diagnosis);
        setPrescribedMedicine(response.data.data.prescribed_medicines);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const downloadPDF = (id, type) => {
    if (type == "download") {
      setLoading(true);
    } else {
      setPrinting(true);
    }
    PatientAPI.get(`prescriptions/${paramID}/pdf`, {
      method: "GET",
      responseType: "blob", //Force to receive data in a Blob Format
    })
      .then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" }); //Build a URL from the file
        const fileURL = URL.createObjectURL(file); //Open the URL on new Window
        if (type === "download") {
          var a = document.createElement("a");
          a.href = fileURL;
          a.download = "heal-holmes-prescription_" + paramID + ".pdf";
          a.click();
          window.URL.revokeObjectURL(fileURL);
        } else {
          window.open(fileURL);
        }
        setLoading(false);
        setPrinting(false);
      })
      .catch((error) => {
        setLoading(false);
        setPrinting(false);
      });
  };

  return (
    <div className=" container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="text-right py-3">
            <button
              onClick={() => downloadPDF("", "download")}
              className="btn btn-lg btn-success"
              disabled={loading}
            >
              {loading && <i className="fa fa-refresh fa-spin"></i>}
              {loading && <span>Downloading</span>}
              {!loading && <span> Download</span>}
            </button>
            <button
              onClick={() => downloadPDF("", "print")}
              className="btn btn-lg btn-success mx-3"
              disabled={printing}
            >
              {printing && <i className="fa fa-refresh fa-spin"></i>}
              {printing && <span>Printing</span>}
              {!printing && <span> Print</span>}
            </button>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-lg-6">
          <div className="observation-test-area">
            <div className="card">
              <h5 className="card-header">Observations</h5>
              <div className="card-body">
                {prescribedObservation.map((data, index) => (
                  <React.Fragment key={index}>
                    <h5 className="card-title">{data.details}</h5>
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className="card">
              <h5 className="card-header">Diagnosis</h5>
              <div className="card-body">
                {prescribedDiagnosis.map((data, index) => (
                  <React.Fragment key={index}>
                    <h5 className="card-title">{data.details}</h5>
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className="card mt-2">
              <h5 className="card-header">Tests</h5>
              <div className="card-body">
                {prescribedTest.map((data, index) => (
                  <React.Fragment key={index}>
                    <h5 className="card-title">{data.test.name}</h5>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="card">
            <h5 className="card-header">Medicine</h5>
            <div className="card-body">
              {prescribedMedcine.map((data, index) => (
                <React.Fragment key={index}>
                  {/* <h5 className="card-title">{data.medicine.brand_name}</h5> */}
                  <div className="card mb-1">
                    <div className="card-body">
                      <h5 className="card-title">
                        {" "}
                        {data.medicine.brand_name} ({data.medicine.strength}{" "}
                        {data.medicine.dosage_form})
                      </h5>
                      <h6 className="card-subtitle mb-3 text-muted">
                        {data.rule}
                      </h6>
                      <h6 className="card-subtitle mb-3 text-muted">
                        {data.validity} days
                      </h6>
                      <h6 className="card-subtitle mb-2 text-muted">
                        <span style={{ fontWeight: "bold" }}>
                          {data.timing} Meal
                        </span>
                      </h6>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
          <div className="card">
            <h5 className="card-header">Comment</h5>
            <div className="card-body">
              <h5 className="card-title">{comment}</h5>
            </div>
          </div>
        </div>
      </div>
      {prescription.image && (
        <div className="row">
          <div className="col-12">
            <img
              src={`${process.env.REACT_APP_PUBLIC_URL}files/doctorPrescriptions/${paramID}/${prescription.image}`}
              alt="..."
            />
          </div>
        </div>
      )}
    </div>
  );
}
