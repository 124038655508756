import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";
import "./css/home.css";
import Navbar2 from "../Shared/Navbar2";
import MessengerCustomerChat from "react-messenger-customer-chat";
// import { ButtonGroup, Button } from "react-bootstrap";
// import { ButtonDropdown } from "react-bootstrap";
// import DropdownMenu from "react-bootstrap";
// import DropdownToggle from "react-bootstrap";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Footer from "../Shared/Footer";
import { Box } from "@material-ui/core";
import CardForm from "./Forms/CardForm";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: "0px",
    top: "-5px",
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {/* <Typography variant="h6">{children}</Typography> */}

      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
var settingsLab = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  autoplay: true,

  autoplaySpeed: 2500,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
export default function CardsPage() {
  let history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [doctorList, setDoctorList] = useState([]);
  const [photoPath, setPhotoPath] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openBanner, setOpenBanner] = React.useState(false);
  const [count, setCount] = useState(1);
  const [countM, setCountM] = useState(1);
  const [countS, setCountS] = useState(1);
  const [countXS, setCountXS] = useState(1);
  const handleClickOpenBanner = () => {
    setOpenBanner(true);
    // const url = `https://invoice.sslcommerz.com/invoice-form?&refer=61E3F31A03880`;
    // window.open(url, "_blank");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const routeToPatient = () => {
    history.push("/patient/login");
  };
  const routeToDocDetails = (id) => {
    history.push(`/doctor/${id}/schedules`);
  };
  const routeToAvailableDoctors = () => {
    history.push(`/available-doctors`);
  };
  const routeToAvailableTests = (route) => {
    if (route !== "Default") {
      history.push(`/available-tests/${route}`);
    } else {
      history.push(`/available-tests`);
    }
  };
  const setTextLimit = (text, size) => {
    if (!text) return "";
    text = text.toString();

    if (text.length <= size) {
      return text;
    }
    return text.substr(0, size) + "...";
  };

  useEffect(() => {
    getDocDetails();
  }, []);

  const getDocDetails = () => {
    axios
      .get(`doctors`)
      .then((response) => {
        let filteredArray = [...response.data.data.data];
        setPhotoPath(response.data.path);
        let newArray = filteredArray.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });

        let idsToRemove = [1, 8];
        const arra = newArray.filter((item) => !idsToRemove.includes(item.id));
        let dataC = arra.length;
        if (dataC === 1) {
          setCount(1);
          setCountM(1);
          setCountS(1);
          setCountXS(1);
        } else if (dataC === 2) {
          setCount(2);
          setCountM(2);
          setCountS(2);
          setCountXS(2);
        } else if (dataC === 3) {
          setCount(3);
          setCountM(3);
          setCountS(2);
          setCountXS(2);
        } else if (dataC >= 4) {
          setCount(4);
          setCountM(3);
          setCountS(2);
          setCountXS(2);
        }
        setDoctorList(arra);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const comingSoon = () => {
    alert("Coming Soon!");
  };
  const settingsDocs = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: count,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToScroll: count,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: countM,
          slidesToScroll: countM,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: countS,
          slidesToScroll: countS,
          initialSlide: countS,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: countXS,
          slidesToScroll: countXS,
        },
      },
    ],
  };
  return (
    <div>
      <Navbar2 showSearchIcon />

      <CardForm openBanner={openBanner} setOpenBanner={setOpenBanner} />
      <section class="spacea lighta card_bg">
        <div class="container container-custom">
          <div class="row">
            <div class="col-md-6">
              <h2>
                Introducing the International Family Health Card: Your Key to
                Global Healthcare Access!
              </h2>
              <button
                onClick={() => handleClickOpenBanner()}
                className="btn btn-lg"
              >
                Apply For A Card
              </button>
            </div>
            <div class="col-md-6">
              <img
                alt="#"
                style={{ width: "100%" }}
                src={`${process.env.PUBLIC_URL}/assets/images/heatlh_back.png`}
              />
            </div>
          </div>
        </div>
      </section>

      <div className="container py-5 int_card">
        <h2>About International Family Health Card</h2>
        <div className="why-choose-container">
          <div className="row no-gutters">
            <div className="col-md-12 mb-5">
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6 ">
                  {" "}
                  <div className="video2">
                    <img
                      className="vector"
                      alt="Vector"
                      src={`${process.env.PUBLIC_URL}/assets/images/vector-play.svg`}
                    />
                  </div>
                </div>
                <div className="col-md-3"></div>
              </div>{" "}
            </div>
            <div className="col-md-12">
              {" "}
              <p>
                In today's globalized world, access to quality healthcare is
                crucial for individuals and families. For Bangladeshi patients,
                navigating healthcare systems, especially when seeking medical
                treatment abroad, can be daunting. However, a powerful tool can
                significantly ease this process—the International Family Health
                Card (IFHC). The Family Health Card (FHC) stands as a beacon of
                empowerment, offering a myriad of transformative benefits on an
                international scale.
              </p>
              <h3>The Transformative Benefits of the Family Health Card:</h3>
              <ul>
                <li>
                  <span style={{ fontSize: 20, fontWeight: 600 }}>
                    Seamless Continuity of Care Across Borders:{" "}
                  </span>{" "}
                  The FHC serves as a comprehensive repository of an
                  individual's medical history, ensuring seamless continuity of
                  care regardless of geographical location. Whether seeking
                  medical treatment abroad or relocating to a new country,
                  individuals with an FHC can effortlessly transfer their
                  medical records, diagnoses, and treatment plans, thereby
                  eliminating the barriers to effective healthcare delivery
                  posed by geographical boundaries.
                </li>
                <li>
                  <span style={{ fontSize: 20, fontWeight: 600 }}>
                    Facilitated Access to Emergency Medical Services:
                  </span>{" "}
                  During medical emergencies at home & abroad, every moment is
                  critical. The FHC equips individuals with essential medical
                  information, including allergies, pre-existing conditions, and
                  emergency contact details, enabling healthcare providers to
                  deliver timely and informed care. This streamlined access to
                  emergency medical services can be lifesaving, particularly in
                  situations where language or cultural barriers may impede
                  communication.
                </li>
                <li>
                  <span style={{ fontSize: 20, fontWeight: 600 }}>
                    Enhanced Coordination of Healthcare Services:
                  </span>{" "}
                  The FHC fosters enhanced coordination among healthcare
                  providers, facilitating the exchange of medical information
                  and ensuring comprehensive, patient-centered care. By
                  standardizing the format of medical records and enabling
                  secure data sharing, the FHC empowers individuals to navigate
                  complex healthcare systems with ease, minimizing redundancies
                  and improving treatment outcomes.
                </li>
                <li>
                  <span style={{ fontSize: 20, fontWeight: 600 }}>
                    Cost-Effective Healthcare Solutions:
                  </span>{" "}
                  For individuals traveling or residing abroad, accessing
                  healthcare services can be financially burdensome. The FHC
                  opens doors to cost-effective healthcare solutions, including
                  discounted rates, insurance coverage, and special offers. By
                  presenting their FHC, individuals can often access
                  preferential treatment options, thereby reducing the economic
                  barriers to healthcare access and promoting equitable health
                  outcomes worldwide.
                </li>
                <li>
                  <span style={{ fontSize: 20, fontWeight: 600 }}>
                    Promotion of Medical Tourism and Global Health Equity:
                  </span>{" "}
                  As medical tourism continues to thrive, the FHC emerges as a
                  catalyst for promoting global health equity. By empowering
                  individuals to seek medical treatment across borders, the FHC
                  fosters competition, innovation, and collaboration in the
                  healthcare sector, ultimately driving improvements in the
                  quality, affordability, and accessibility of healthcare
                  services worldwide.
                </li>
                <li>
                  <span style={{ fontSize: 20, fontWeight: 600 }}>
                    Advocacy for Public Health Initiatives and Disease
                    Prevention:
                  </span>{" "}
                  Beyond individual healthcare benefits, the FHC facilitates
                  data collection, analysis, and surveillance, supporting public
                  health initiatives and disease prevention efforts on a global
                  scale. By aggregating anonymized health data, the FHC enables
                  policymakers, researchers, and healthcare professionals to
                  identify emerging health trends, allocate resources
                  effectively, and implement targeted interventions to safeguard
                  population health.
                </li>
                <li>
                  <span style={{ fontSize: 20, fontWeight: 600 }}>
                    Health Promotion and Wellness Initiatives:
                  </span>{" "}
                  The FHC can serve as a platform for promoting health and
                  wellness initiatives on a global scale. By providing
                  individuals with access to personalized health
                  recommendations, preventive screenings, and lifestyle
                  interventions, the FHC empowers them to take proactive steps
                  towards optimizing their health and well-being, thereby
                  reducing the burden of preventable diseases and improving
                  overall quality of life.
                </li>
                <li>
                  <span style={{ fontSize: 20, fontWeight: 600 }}>
                    Cultural Competency and Patient-Centered Care:
                  </span>{" "}
                  In multicultural societies and global healthcare settings,
                  cultural competency is paramount for delivering
                  patient-centered care. The FHC can include culturally
                  sensitive information and language preferences, enabling
                  healthcare providers to tailor their services to the diverse
                  needs and preferences of patients from different cultural
                  backgrounds, thereby fostering trust, communication, and
                  mutual understanding.
                </li>
                <li>
                  <span style={{ fontSize: 20, fontWeight: 600 }}>
                    Support for Expatriates and Global Nomads :
                  </span>{" "}
                  For expatriates, digital nomads, and individuals living
                  transient lifestyles, maintaining continuity of healthcare can
                  be challenging. The FHC offers a portable solution, allowing
                  individuals to carry their medical records and health
                  information with them wherever they go. This ensures that
                  expatriates and global nomads can access healthcare services
                  seamlessly, regardless of their location or travel itinerary.
                </li>
                <li>
                  <span style={{ fontSize: 20, fontWeight: 600 }}>
                    Chronic Disease Management and Care Coordination :
                  </span>{" "}
                  For individuals living with chronic conditions such as
                  diabetes, hypertension, or asthma, effective disease
                  management relies on coordinated care and regular monitoring.
                  The FHC facilitates care coordination by enabling healthcare
                  providers to track disease progression, monitor treatment
                  adherence, and adjust management strategies as needed. This
                  holistic approach to chronic disease management promotes
                  better health outcomes and enhances patient satisfaction.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div class="Wellness-program">
          <div>
            <p class="Wellness-Program-by-Heal-Holmes">
              Wellness Program by Heal Holmes
            </p>
            <p class="Heal-Holmes-provides-great-health-solutions-for-as-little-as-1000tk-each-year">
              Heal Holmes provides great health solutions for as little as
              1000tk each year.
            </p>
          </div>
          <div className="card">
            <div className="box">
              <p class="Heal-Holmes-Existence">Heal Holmes Existence</p>
              <p className="desc">
                Hospital cash cover, prescription support, discounts at partner
                labs, and much more are all available. All for only Tk 1000 per
                year.
              </p>
            </div>
            <div className="card_check">
              <Box display={"flex"} gridGap={"16px"} alignItems={"center"}>
                <img
                  width={52}
                  alt="#"
                  src={`${process.env.PUBLIC_URL}/assets/images/check_bold.png`}
                />
                <span class="Lab-Diagnostic-Test-Cashback">
                  Lab & Diagnostic Test Cashback
                </span>
              </Box>
              <Box display={"flex"} gridGap={"16px"} alignItems={"center"}>
                <img
                  width={52}
                  alt="#"
                  src={`${process.env.PUBLIC_URL}/assets/images/check_bold.png`}
                />
                <span class="Lab-Diagnostic-Test-Cashback">
                  Partner Diagnostic Centre Discount
                </span>
              </Box>
              <Box display={"flex"} gridGap={"16px"} alignItems={"center"}>
                <img
                  width={52}
                  alt="#"
                  src={`${process.env.PUBLIC_URL}/assets/images/check_bold.png`}
                />
                <span class="Lab-Diagnostic-Test-Cashback">
                  24/7 TeleMedicine
                </span>
              </Box>
              <Box display={"flex"} gridGap={"16px"} alignItems={"center"}>
                <img
                  width={52}
                  alt="#"
                  src={`${process.env.PUBLIC_URL}/assets/images/check_bold.png`}
                />
                <span class="Lab-Diagnostic-Test-Cashback">OPD Cashback</span>
              </Box>
            </div>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <button
                onClick={() => handleClickOpenBanner()}
                className="btn btn-lg"
              >
                Apply Now
              </button>
            </Box>
          </div>
        </div>
      </div>

      <section class="our-team">
        <div class="container container-custom">
          <div class="row">
            <div class="col-md-12">
              <div class="sub-title_center">
                <h2>Our Dedicated Doctors</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="doctors-slider">
                <Slider {...settingsDocs}>
                  {doctorList.slice(0, 10).map((doc, index) => (
                    <div onClick={() => routeToDocDetails(doc.id)} key={index}>
                      <div class="team-img_block yellow">
                        <div class="team-img-socila-block">
                          <img
                            src={`${process.env.REACT_APP_PUBLIC_URL}${photoPath}/${doc.photo}`}
                            class="img-fluid"
                            alt="#"
                          />
                        </div>
                        <h4>{doc.name}</h4>
                        <p>{doc.designation}</p>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!--==================== Testimonials ====================--> */}

      {/* <!--//End Testimonials --> */}
      <section class="bg-img3 mt-4">
        <div class="container container-custom">
          {/* <div class="row">
            <div class="col-lg-5 offset-lg-7">
              <div class="consultant-content">
                <h2>We Believe in a Healthier You</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eius mod tempor incididunt ut labore et dolore magna
                  aliqua. Ut enim ad minim veniam, quis nostrud exercitation.
                </p>
                <p>
                  Adipisicing elit, sed do eius mod tempor incididunt ut labore
                  et dolore magna aliqua. Ut enim ad minim veniam,
                </p>
                <a href="#" class="btn btn-success">
                  Get a Consultant
                </a>
                <a
                  href="https://www.youtube.com/watch?v=pBFQdxA-apI"
                  class="popup-youtube"
                >
                  {" "}
                  <i class="fa fa-play"></i>
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      <Footer />
      <div className="row">
        <div className="col-md-12">
          <MessengerCustomerChat
            pageId="112975200580661"
            appId="434998947911975"
          />
        </div>
      </div>
    </div>
  );
}
