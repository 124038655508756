import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import Pagination from "@material-ui/lab/Pagination";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import _ from "lodash";
import moment from "moment";
import CreateIcon from '@material-ui/icons/Create';
export default function PatientList() {
  const [patientList, setPatientList] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [patientId, setpatientId] = useState(Number);

  const [defaultStatus, setDefaultStatus] = useState("");
  const [selecedStatus, setSelectedStatus] = useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [params, setParams] = useState({
    per_page: 15,
    page: 1,
  });

  useEffect(() => {
    getPatientList();
  }, [params]);

  const getPatientList = () => {
    AdminAPI.get(`patients`, { params: params })
      .then((response) => {
        setPatientList(response.data.data.data);
        setTotalPage(response.data.data.last_page);
        setPage(response.data.data.current_page);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };

  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);
  const updatePatient = (status, id) => {
    console.log(id, status);
    if (status === "Active") {
      setDefaultStatus("Inactive");
      setSelectedStatus("Inactive");
    }
    if (status === "Inactive") {
      setDefaultStatus("Active");
      setSelectedStatus("Active");
    }

    setpatientId(id);

    setOpenModal(true);
  };

  const changeStatus = (e) => {
    setSelectedStatus(e.target.value);
  };
  const submitStatus = () => {
    let data = {
      status: selecedStatus,
    };
    AdminAPI.post(`patients/${patientId}/update-status`, data)
      .then((response) => {
        alert("You have successfully updated the patient status");
        getPatientList();
        setOpenModal(false);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  return (
    <div>
      <div>
        <div className="row">
          <div className="col-md-3 form-group">
            <select
              className="form-control"
              onChange={(e) => {
                handleColumnWiseSearch(e.target.value, "status");
              }}
            >
              <option value="">All</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
          </div>
          <div className="col-md-3 form-group">
            <select
              className="form-control"
              onChange={(e) => {
                handleColumnWiseSearch(e.target.value, "referred_by");
              }}
            >
              <option value="">All</option>
              <option value="Self">Patient</option>
              <option value="Admin">Admin</option>
              <option value="Agent">Agent</option>
              <option value="Corporate">Corporate</option>
              <option value="BCorporate">BCorporate</option>
            </select>
          </div>
          <div className="col-md-4 text-right form-group">
            <input
              placeholder="Search Patient"
              className="form-control"
              type="text"
              onChange={(e) => handleColumnWiseSearch(e.target.value, "search")}
            />
          </div>
          <div className="col-md-2 form-group">
            <Link
              to={`/admin/add-patient`}
              type="button"
              className="btn btn-outline-secondary float-right"
            >
              New patient
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className=" table-responsive">
              <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Contact </th>
                    <th scope="col">Age </th>
                    <th scope="col">Area</th>
                    <th scope="col">Gender</th>
                    <th scope="col">Referred</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {patientList.map((patient, i) => (
                    <tr key={i}>
                      <td>{patient.name}</td>
                      <td>{patient.email}</td>
                      <td>{patient.phone}</td>
                      <td>
                        {" "}
                        {patient.date_of_birth && (
                          <>{moment().diff(patient.date_of_birth, "years")}</>
                        )}
                      </td>

                      <td>{patient.area}</td>
                      <td>{patient.gender}</td>
                      <td>
                        {patient.reference != null && (
                          <span>
                            {patient.referred_by == "Patient" ? (
                              <span>Self</span>
                            ) : (
                              <span>
                                {" "}
                                {patient.referred_by} ({patient.reference.name})
                              </span>
                            )}
                          </span>
                        )}
                      </td>
                      <td>
                        {patient.status == "Active" ? (
                          <button
                            type="button"
                            onClick={() =>
                              updatePatient(patient.status, patient.id)
                            }
                            className="btn btn-sm btn-info"
                          >
                            {patient.status === "Active" && "Active"}
                            {patient.status === "Inactive" && "Inactive"}
                            {patient.status === "" && "Not Available"}
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={() =>
                              updatePatient(patient.status, patient.id)
                            }
                            className="btn btn-sm btn-warning"
                          >
                            {patient.status === "Active" && "Active"}
                            {patient.status === "Inactive" && "Inactive"}
                            {patient.status === "" && "Not Available"}
                          </button>
                        )}
                      </td>

                      <td>
                        <Link
                          to={`/admin/patient/view/${patient.id}`}
                          type="button"
                          className="btn btn-sm btn-primary mb-1"
                        >
                          <VisibilityIcon />
                        </Link>
                        <Link
                          to={`/admin/patient/update/${patient.id}`}
                          type="button"
                          className="btn btn-outline-secondary"
                        >
                          <CreateIcon />
                        </Link>

                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handleChange}
          />
        </div>
        <Dialog
          fullWidth
          maxWidth={`sm`}
          open={openModal}
          onClose={() => setOpenModal(false)}
        >
          <DialogTitle id="max-width-dialog-title">
            Change Patient Status
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <select
                className=" form-control"
                defaultValue={defaultStatus}
                onChange={(event) => changeStatus(event)}
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenModal(false)} color="primary">
              Close
            </Button>
            <Button onClick={() => submitStatus()} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
