import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import Alert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { DiagnosticAPI } from "../../../AxiosRequest/DiagnosticAPI";
export default function Profile() {
  const [policeStations, setPoliceStations] = useState([]);
  const [loading, setLoading] = useState(false);

  const [erroropen, setErrorOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const [diagnosticData, setDiagnosticData] = useState({});
  const [policeStationValue, setPoliceStationValue] = useState("");

  // Backend Error Messages
  const [nameErros, setNameErrors] = useState([]);
  const [phoneErros, setPhoneError] = useState([]);
  const [emailErrors, setEmailError] = useState([]);
  const [contactPersonErrors, setContactPersonErrors] = useState([]);

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Backend Errors Handle
  const [backendErrorMessage, setBackendErrorMessage] = useState({});

  useEffect(() => {
    getDiagnosticData();
    GetPoliceStations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getDiagnosticData = () => {
    DiagnosticAPI.get("auth/me")
      .then((response) => {
        console.log("response data", response.data);
        setDiagnosticData(response.data);
        setPoliceStationValue(response.data.police_station.name);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const GetPoliceStations = () => {
    // let url = `http://localhost:3001/purchase_order/${paramID}`;
    axios
      .get("police-stations")
      .then((response) => {
        console.log(response.data.data);
        setPoliceStations(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name field is required"),
    phone: Yup.string().required("Please enter your phone number"),
    email: Yup.string().required("Please enter your email address"),
    contact_person: Yup.string().required("Please enter your contact person"),
  });
  const initialValues = {
    name: diagnosticData.name || "",
    phone: diagnosticData.phone || "",
    email: diagnosticData.email || "",
    address: diagnosticData.address || "",
    contact_person: diagnosticData.contact_person || "",
    police_station_id: diagnosticData.police_station_id || "",
  };

  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    setLoading(true);
    DiagnosticAPI.post("auth/me", values)
      .then((response) => {
        console.log(response);
        if (response.data.message) {
          setSuccessMessage(response.data.message);
          setOpen(true);
          setErrorOpen(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error.response);
        setLoading(false);
        if (error.response.data.message) {
          setErrorMessage(error.response.data.message);
          setErrorOpen(true);
          setOpen(false);
        }
        if (error.response.data.data !== undefined) {
          setErrorOpen(true);
          setOpen(false);
        }

        if (error.response.data.errors !== undefined) {
          setBackendErrorMessage(error.response.data.errors);
        }
      });
  };

  return (
    <div>
      <div className="container">
        <div className="observation-test-area">
          <div className="form-group row"></div>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ setFieldValue, isValid, dirty }) => (
              <Form>
                <div className="form-group row">
                  <label className="col-sm-3 offset-sm-1 col-form-label">
                    Unique ID
                  </label>
                  <label className="col-sm-7 col-form-label">
                    {diagnosticData.unique_id}
                  </label>
                </div>
                <div className="form-group row ">
                  <label className="col-sm-3 offset-sm-1 col-form-label">
                    Name
                  </label>
                  <div className="col-sm-7">
                    <Field
                      name="name"
                      type="text"
                      className="form-control"
                      placeholder="Name"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="error-message"
                    />
                    {nameErros.map((item, i) => (
                      <p className="error-message" key={i}>
                        {item}
                      </p>
                    ))}
                  </div>
                </div>
                <div className="form-group row ">
                  <label className="col-sm-3 offset-sm-1 col-form-label">
                    Phone
                  </label>
                  <div className="col-sm-7">
                    <Field
                      name="phone"
                      type="text"
                      className="form-control"
                      placeholder="Phone/Mobile Number"
                    />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="error-message"
                    />
                    {phoneErros.map((item, i) => (
                      <p className="error-message" key={i}>
                        {item}
                      </p>
                    ))}
                  </div>
                </div>
                <div className="form-group row ">
                  <label className="col-sm-3 offset-sm-1 col-form-label">
                    Email
                  </label>
                  <div className="col-sm-7">
                    <Field
                      name="email"
                      type="text"
                      className="form-control"
                      placeholder="Email address"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error-message"
                    />
                    {emailErrors.map((item, i) => (
                      <p className="error-message" key={i}>
                        {item}
                      </p>
                    ))}
                  </div>
                </div>
                <div className="form-group row ">
                  <label className="col-sm-3 offset-sm-1 col-form-label">
                    Contact Person
                  </label>
                  <div className="col-sm-7">
                    <Field
                      name="contact_person"
                      type="text"
                      className="form-control"
                      placeholder="Contact person"
                    />
                    <ErrorMessage
                      name="contact_person"
                      component="div"
                      className="error-message"
                    />
                    {contactPersonErrors.map((item, i) => (
                      <p className="error-message" key={i}>
                        {item}
                      </p>
                    ))}
                  </div>
                </div>
                <div className="form-group row ">
                  <label className="col-sm-3 offset-sm-1 col-form-label">
                    Address
                  </label>
                  <div className="col-sm-7">
                    <Field
                      name="address"
                      type="text"
                      className="form-control"
                      placeholder="Address"
                    />
                    <ErrorMessage
                      name="address"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>
                <div className="form-group row ">
                  <label className="col-sm-3 offset-sm-1 col-form-label">
                    Police Stations
                  </label>
                  <div className="col-sm-7">
                    <Autocomplete
                      freeSolo
                      id="disabledHelperText"
                      name="police_station_id"
                      options={policeStations}
                      getOptionLabel={(option) =>
                        option.name || policeStationValue
                      }
                      getOptionSelected={(option, value) => {
                        return option.name === value.name;
                      }}
                      value={policeStationValue}
                      onChange={(e, value) => {
                        if (value == null) {
                          setFieldValue("police_station_id", "");
                        } else {
                          setFieldValue(
                            "police_station_id",
                            value.id
                          );
                        }
                      }}
                      renderInput={(params) => (
                        <Field
                          component={TextField}
                          {...params}
                          onChange={(e, value) => {
                            console.log(e.target.value);
                            setFieldValue("police_station_id", 0);
                          }}
                          name="police_station_id"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                    <ErrorMessage
                      name="police_station_id"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 my-4">
                    <Collapse in={open}>
                      <Alert
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setOpen(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {successMessage}
                      </Alert>
                    </Collapse>
                    <Collapse in={erroropen}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setErrorOpen(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {errorMessage}
                      </Alert>
                    </Collapse>
                  </div>
                </div>

                <div className="form-group row ">
                  <label className="col-sm-5 offset-sm-1">
                    <button
                      disabled={loading}
                      // disabled={!isValid || !dirty}
                      type="submit"
                      className="btn btn-lg btn-success"
                    >
                      {loading && (
                        <i className="fa fa-refresh fa-spin"></i>
                      )}
                      {loading && <span>Updating</span>}
                      {!loading && <span> Update</span>}
                    </button>
                  </label>
                  <div className="col-sm-7"></div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
