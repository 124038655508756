import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import Pagination from "@material-ui/lab/Pagination";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import _ from "lodash";

export default function IncomingInvoiceList() {
  const [invoiceList, setInvoiceList] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [invoiceId, setInvoiceId] = useState(Number);

  const [defaultStatus, setDefaultStatus] = useState("");
  const [selecedStatus, setSelectedStatus] = useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [params, setParams] = useState({
    per_page: 15,
    page: 1,
  });

  useEffect(() => {
    getInvoiceList();
  }, [params]);

  const getInvoiceList = () => {
    AdminAPI.get(`incoming-invoices`, { params: params })
      .then((response) => {
        setInvoiceList(response.data.data.data);
        setTotalPage(response.data.data.last_page);
        setPage(response.data.data.current_page);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };

  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);
  const updatePatient = (status, id) => {
    console.log(id, status);
    if (status === "Active") {
      setDefaultStatus("Inactive");
      setSelectedStatus("Inactive");
    }
    if (status === "Inactive") {
      setDefaultStatus("Active");
      setSelectedStatus("Active");
    }

    setInvoiceId(id);

    setOpenModal(true);
  };

  const changeStatus = (e) => {
    setSelectedStatus(e.target.value);
  };
  const submitStatus = () => {
    let data = {
      status: selecedStatus,
    };
    AdminAPI.post(`patients/${invoiceId}/update-status`, data)
      .then((response) => {
        alert("You have successfully updated the patient status");
        getInvoiceList();
        setOpenModal(false);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  return (
    <div>
      <div>
        <div className="row">
          <div className="col-md-3 form-group">
            <select
              className="form-control"
              onChange={(e) => {
                handleColumnWiseSearch(e.target.value, "status");
              }}
            >
              <option value="">All</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
          </div>
          <div className="col-md-3 form-group">
            <select
              className="form-control"
              onChange={(e) => {
                handleColumnWiseSearch(e.target.value, "referred_by");
              }}
            >
              <option value="">All</option>
              <option value="Self">Patient</option>
              <option value="Admin">Admin</option>
              <option value="Agent">Agent</option>
              <option value="Corporate">Corporate</option>
              <option value="BCorporate">BCorporate</option>
            </select>
          </div>
          <div className="col-md-4 text-right form-group">
            <input
              placeholder="Search Patient"
              className="form-control"
              type="text"
              onChange={(e) => handleColumnWiseSearch(e.target.value, "search")}
            />
          </div>
          <div className="col-md-2 form-group">
            <Link
              to={`/admin/add-patient`}
              type="button"
              className="btn btn-outline-secondary float-right"
            >
              New patient
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className=" table-overflow">
              <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Lab Inv ID</th>
                    <th scope="col">Lab</th>
                    <th scope="col">Price</th>
                    <th scope="col">Discount</th>
                    <th scope="col">Bill</th>
                    <th scope="col">H/C</th>
                    <th scope="col">Patient Name</th>
                    <th scope="col">Patient Contact</th>
                    <th scope="col">Patient Age</th>
                    <th scope="col">Patient Address</th>
                    <th scope="col">Date</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceList.map((invoice, i) => (
                    <tr key={i}>
                      <td>{invoice.lab_invoice_id}</td>
                      <td>{invoice.service_provider.name}</td>
                      <td>{Math.ceil(invoice.price)}</td>
                      <td>{invoice.discount}</td>
                      <td>{invoice.bill}</td>
                      <td>{invoice.home_collection ? "Yes" : "No"}</td>
                      <td>{invoice.patient_name}</td>
                      <td>{invoice.patient_phone}</td>
                      <td>{invoice.patient_age}</td>
                      <td>{invoice.patient_address}</td>
                      <td>{invoice.test_date}</td>
                      <td>{invoice.status}</td>
                      <td>
                        <Link
                          to={`/admin/incoming-invoice/view/${invoice.id}`}
                          type="button"
                          className="btn btn-sm btn-primary mb-1"
                        >
                          <VisibilityIcon />
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handleChange}
          />
        </div>
        <Dialog
          fullWidth
          maxWidth={`sm`}
          open={openModal}
          onClose={() => setOpenModal(false)}
        >
          <DialogTitle id="max-width-dialog-title">
            Change Patient Status
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <select
                className=" form-control"
                defaultValue={defaultStatus}
                onChange={(event) => changeStatus(event)}
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenModal(false)} color="primary">
              Close
            </Button>
            <Button onClick={() => submitStatus()} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
