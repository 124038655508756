import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { DoctorAPI } from "../../AxiosRequest/DoctorAPI";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { TimePicker } from "@material-ui/pickers";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import { Modal } from "antd";

export default function ViewScheduleDetails(props) {
  let history = useHistory();
  let { scheduleId } = useParams();
  const [scheduleDetail, setSchedulesDetail] = useState([]);
  const [selectedDate, handleDateChange] = useState(new Date());

  const [acceptModal, setAcceptModal] = useState(false);
  const [confirmAppointmentModal, setConfirmAppointmentModal] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [confirmRejectModal, setConfirmRejectModal] = useState(false);
  const [rejectAppointmentModal, setRejectAppointmentModal] = useState(false);

  // const paramID = props.match.params.id;

  useEffect(() => {
    GetScheduleDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const GetScheduleDetail = () => {
    DoctorAPI.get(`/appointments/${scheduleId}`)
      .then((response) => {
        setSchedulesDetail([...scheduleDetail, response.data.data]);
        console.log(response.data.data);
        handleDateChange(response.data.data.appointment_time);
        if (response.data.data.status !== "Accepted") {
          setShowButtons(true);
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const submitAppointMent = () => {
    DoctorAPI.post("appointment/confirm", {
      id: scheduleId,
      status: "Accepted",
    })
      .then((response) => {
        console.log(response);
        setAcceptModal(false);
        setConfirmAppointmentModal(true);
        setTimeout(() => {
          history.push("/doctor/appointments");
        }, 3000);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        alert(error.response.data.message);
        // setStatusMessage(error.response.data.message);
      });
  };

  const rejectAppointment = () => {
    DoctorAPI.post("appointment/confirm", {
      id: scheduleId,
      status: "Rejected",
    })
      .then((response) => {
        console.log(response);
        setRejectAppointmentModal(false);
        setConfirmRejectModal(true);
        setTimeout(() => {
          history.push("/doctor/appointments");
        }, 3000);
        // setStatusMessage(response.data.message);
        // setStatusModal(true);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        alert(error.response.data.message);
        // setStatusMessage(error.response.data.message);
      });
  };

  const closeAcceptModal = () => {
    setAcceptModal(false);
    setRejectAppointmentModal(false);
  };
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-right"></div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-4">
            <div className=" table-responsive">
              <table className="table table-info">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Appointment Time</th>
                    <th scope="col">Patient's Name</th>
                    <th scope="col">Patient's Area</th>
                    <th scope="col">Patient's Contact</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {scheduleDetail.map((item, i) => (
                    <tr key={i}>
                      <td>
                        {moment(item.appointment_time).format(
                          "dddd DD-MMM-YYYY, hh:mm A"
                        )}
                      </td>
                      <td>{item.patient.name}</td>
                      <td>{item.patient.area}</td>
                      <td>{item.patient.phone}</td>
                      <td>{item.status}</td>
                      <td></td>
                    </tr>
                    // <li key={item.id}>{item.value}</li>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {showButtons && (
          <div className="row">
            <div className="col-md-6 d-flex justify-content-lg-start justify-content-md-start justify-content-sm-center">
              <button
                onClick={() => {
                  setRejectAppointmentModal(true);
                }}
                className="btn btn-lg btn-danger"
              >
                Reject
              </button>
            </div>
            <div className="col-md-6 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-center">
              <button
                onClick={() => {
                  setAcceptModal(true);
                }}
                className="btn btn-lg btn-success"
              >
                Accept{" "}
              </button>
            </div>
          </div>
        )}

        {/* <div className="row">
          <div className="col-md-12">
            <Modal
              title=""
              centered
              visible={acceptModal}
              onOk={submitAppointMent}
              onCancel={closeAcceptModal}
              footer={[
                <button
                  className="btn btn-primary"
                  key="back"
                  onClick={closeAcceptModal}
                >
                  Return
                </button>,
                <button
                  key="submit"
                  className="btn btn-success"
                  onClick={submitAppointMent}
                >
                  Submit
                </button>,
              ]}
            >
              <div className="form-group">
                <label ht="exampleInputEmail1">Appointment Tme</label> <br />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <TimePicker
                    autoOk
                    value={selectedDate}
                    onChange={handleDateChange}
                  />
                </MuiPickersUtilsProvider>
                <small id="emailHelp" className="form-text text-muted">
                  Please give your suitable time for this appointment
                </small>
              </div>
            </Modal>
          </div>
        </div> */}

        <div className="row">
          <div className="col-md-12">
            <Modal
              title=""
              centered
              visible={acceptModal}
              onOk={submitAppointMent}
              onCancel={closeAcceptModal}
              footer={[
                <button
                  className="btn btn-primary"
                  key="back"
                  onClick={closeAcceptModal}
                >
                  No
                </button>,
                <button
                  key="submit"
                  className="btn btn-success"
                  onClick={submitAppointMent}
                >
                  Yes
                </button>,
              ]}
            >
              <p>Confirm appointment time?</p>
            </Modal>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <Modal
              title="Confirmation"
              visible={confirmAppointmentModal}
              onOk={() => setConfirmAppointmentModal(false)}
              onCancel={() => setConfirmAppointmentModal(false)}
              //   cancelButtonProps={{ style: { display: "none" } }}
            >
              <p>Your have successfully set your appointment time </p>
            </Modal>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <Modal
              title=""
              centered
              visible={rejectAppointmentModal}
              onOk={rejectAppointment}
              onCancel={closeAcceptModal}
              footer={[
                <button
                  className="btn btn-primary"
                  key="back"
                  onClick={closeAcceptModal}
                >
                  No
                </button>,
                <button
                  key="submit"
                  className="btn btn-success"
                  onClick={rejectAppointment}
                >
                  Yes
                </button>,
              ]}
            >
              <p>Do you really want to reject this appointment</p>
            </Modal>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <Modal
              title="Confirmation"
              visible={confirmRejectModal}
              onOk={() => setConfirmRejectModal(false)}
              onCancel={() => setConfirmRejectModal(false)}
              //   cancelButtonProps={{ style: { display: "none" } }}
            >
              <p>Your have successfully reject this appointment </p>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}
