import React, { useState, useEffect } from "react";
import { TextField } from "formik-material-ui";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import axios from "axios";
import * as Yup from "yup";
import { HospitalAPI } from "../../AxiosRequest/HospitalAPI";
import Navbar2 from "../Shared/Navbar2";

import moment from "moment";
import { TextField as TextFieldSecond } from "formik-material-ui";
import _ from "lodash";

export default function HospitalRegistration() {
  const [policeStations, setPoliceStations] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [signedUpMessage, setSignedUpMessage] = useState("");
  const [singnedUp, setSignedUp] = useState(false);
  const [stationSearchTerm, setStationSearchTerm] = useState("");
  const [districtSearchTerm, setDistrictSearchTerm] = useState("");
  // Backend Error Messages
  const [nameErros, setNameErrors] = useState([]);
  const [phoneErros, setPhoneError] = useState([]);
  const [emailError, setEmailError] = useState([]);
  const [ageErrors, setAgeErrors] = useState([]);
  const [genderErrors, setGenderErrors] = useState([]);

  const [bloodGroupErrors, setBloodgroupErrors] = useState([]);

  const [passwordErrors, setPasswordErrors] = useState([]);
  const [passwordConfirmationErrors, setPaswordConfirmationErros] = useState(
    []
  );
  const [district, setDistrict] = useState("");

  useEffect(() => {
    GetPoliceStations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stationSearchTerm, district]);
  useEffect(() => {
    getDistricts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [districtSearchTerm]);
  const GetPoliceStations = () => {
    if (district !== "") {
      axios
        .get(`districts/${district}?name=${stationSearchTerm}`)
        .then((response) => {
          setPoliceStations(response.data.data.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
  };
  const getDistricts = () => {
    axios
      .get(`districts?name=${districtSearchTerm}`)
      .then((response) => {
        console.log("dddd", response.data.data.data);
        setDistrictList(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleOnInputCaptureMed = _.debounce((event) => {
    setStationSearchTerm(event.target.value);
  }, 500);
  const handleOnInputCaptureDis = _.debounce((event) => {
    setDistrictSearchTerm(event.target.value);
  }, 500);

  const validationSchema = Yup.object({
    name: Yup.string().required("Name field is required"),
    phone: Yup.string().required("Please enter your phone number"),

    email: Yup.string()
      .email("Please Enter a valid Email")
      .required("Email field is required"),
    password: Yup.string()
      .min(6, "Password must contain at least 6 characters")
      .required("Enter your password"),
    confirm_password: Yup.string()
      .required("Confirm your password")
      .oneOf([Yup.ref("password")], "Password does not match"),

    // blood_group: Yup.string().required("Blood Group field is required"),
  });
  const initialValues = {
    name: "",
    phone: "",
    email: "",
    contact_person: "",
    license_number: "",
    address: "",
    police_station_id: "",
    password: "",
    confirm_password: "",
  };

  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    setLoading(true);
    let objToPost = { ...values };
    if (objToPost.police_station_id === "") {
      delete objToPost.police_station_id;
    }
    if (objToPost.police_station_id === "") {
      delete objToPost.police_station_id;
    }
    Object.keys(objToPost).forEach(
      (k) => objToPost[k] === "" && delete objToPost[k]
    );
    delete objToPost.district;
    console.log("obe to post", objToPost);

    HospitalAPI.post("auth/register", objToPost)
      .then((response) => {
        console.log(response);
        console.log(response.data.message);
        setSignedUp(true);
        setSignedUpMessage(
          "You have successfully signed up. Press login to continue"
        );
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response);
        setLoading(false);
        if (error.response.data.message) {
        }

        if (error.response.data.errors !== undefined) {
          let nameErrors = error.response.data.errors.name;
          let emailError = error.response.data.errors.email;
          let phoneErrors = error.response.data.errors.phone;
          let birthErrors = error.response.data.errors.date_of_birth;
          let sexErrors = error.response.data.errors.gender;
          let bloodErrors = error.response.data.errors.blood_group;
          let passwordErrors = error.response.data.errors.password;

          let confirmPasswordErrors =
            error.response.data.errors.confirm_password;

          if (nameErrors !== undefined) {
            setNameErrors(nameErrors);
          }
          if (emailError !== undefined) {
            setEmailError(emailError);
          }
          if (phoneErrors !== undefined) {
            setPhoneError(phoneErrors);
          }
          if (birthErrors !== undefined) {
            setAgeErrors(birthErrors);
          }
          if (sexErrors !== undefined) {
            setGenderErrors(sexErrors);
          }
          if (bloodErrors !== undefined) {
            setBloodgroupErrors(bloodErrors);
          }
          if (passwordErrors !== undefined) {
            setPasswordErrors(passwordErrors);
          }
          if (confirmPasswordErrors !== undefined) {
            setPaswordConfirmationErros(confirmPasswordErrors);
          }
        }
      });
  };
  return (
    <div>
      <Navbar2 />
      <div className="doc-register-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <div className="row">
                <div className="col-lg-12">
                  <div className="doc-register-form">
                    <div className="text-center">
                      <LockOpenIcon />
                    </div>
                    <h1 className="text-center">Heal-Holmes</h1>

                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      {({ setFieldValue, isValid, dirty }) => (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group row ">
                                <label className="col-sm-3">Name</label>
                                <div className="col-sm-8">
                                  <Field
                                    name="name"
                                    type="text"
                                    className="form-control"
                                    placeholder="Your Name"
                                  />
                                  <ErrorMessage
                                    name="name"
                                    component="div"
                                    className="error-message"
                                  />
                                  {nameErros.map((item, i) => (
                                    <p className="error-message" key={i}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group row ">
                                <label className="col-sm-3">Phone</label>
                                <div className="col-sm-8">
                                  <Field
                                    name="phone"
                                    type="text"
                                    className="form-control"
                                    placeholder="Phone/Mobile Number"
                                  />
                                  <ErrorMessage
                                    name="phone"
                                    component="div"
                                    className="error-message"
                                  />
                                  {phoneErros.map((item, i) => (
                                    <p className="error-message" key={i}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row ">
                                <label className="col-sm-3 ">
                                  Contact Person
                                </label>
                                <div className="col-sm-8">
                                  <Field
                                    className="form-control"
                                    name="contact_person"
                                  />

                                  <ErrorMessage
                                    name="contact_person"
                                    component="div"
                                    className="error-message"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row ">
                                <label className="col-sm-3">Email</label>
                                <div className="col-sm-8">
                                  <Field
                                    name="email"
                                    type="email"
                                    className="form-control"
                                    placeholder="Email"
                                  />
                                  <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="error-message"
                                  />
                                  {emailError.map((item, i) => (
                                    <p className="error-message" key={i}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6"></div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group row ">
                                <label className="col-sm-3">District</label>
                                <div className="col-sm-8">
                                  <Autocomplete
                                    freeSolo
                                    id="disabledHelperText"
                                    name="district"
                                    options={districtList}
                                    onInputCapture={(event) => {
                                      event.persist(); // As we are accessing the event in asynchronous way.
                                      handleOnInputCaptureDis(event);
                                    }}
                                    // getOptionLabel={(option) => option.name}
                                    getOptionLabel={(option) => {
                                      return option.district;
                                    }}
                                    onChange={(e, value) => {
                                      if (value == null) {
                                        console.log("Hello");
                                        // setFieldValue("police_station_id", "");
                                      } else {
                                        console.log(value);
                                        setDistrict(value.district);
                                        GetPoliceStations();
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <Field
                                        component={TextField}
                                        {...params}
                                        onChange={(e, value) => {
                                          console.log(e.target.value);
                                        }}
                                        name="district"
                                        variant="outlined"
                                        fullWidth
                                      />
                                    )}
                                  />
                                  <ErrorMessage
                                    name="district"
                                    component="div"
                                    className="error-message"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group row ">
                                <label className="col-sm-3">
                                  Police Stations
                                </label>
                                <div className="col-sm-8">
                                  <Autocomplete
                                    disabled={district === ""}
                                    freeSolo
                                    id="disabledHelperText"
                                    name="police_station_id"
                                    options={policeStations}
                                    onInputCapture={(event) => {
                                      event.persist(); // As we are accessing the event in asynchronous way.
                                      handleOnInputCaptureMed(event);
                                    }}
                                    getOptionLabel={(option) => {
                                      return (
                                        option.name +
                                          " " +
                                          "(" +
                                          option.district +
                                          ")" || ""
                                      );
                                    }}
                                    onChange={(e, value) => {
                                      if (value == null) {
                                        setFieldValue("police_station_id", "");
                                      } else {
                                        console.log(value);
                                        console.log(value.id);
                                        setFieldValue(
                                          "police_station_id",
                                          value.id
                                        );
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <Field
                                        component={TextField}
                                        {...params}
                                        onChange={(e, value) => {
                                          console.log(e.target.value);
                                        }}
                                        name="police_station_id"
                                        variant="outlined"
                                        fullWidth
                                      />
                                    )}
                                  />
                                  <ErrorMessage
                                    name="police_station_id"
                                    component="div"
                                    className="error-message"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row ">
                                <label className="col-sm-3">
                                  License Number
                                </label>
                                <div className="col-sm-8">
                                  <Field
                                    className="form-control"
                                    name="license_number"
                                  />

                                  <ErrorMessage
                                    name="license_number"
                                    component="div"
                                    className="error-message"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              {" "}
                              <div className="form-group row ">
                                <label className="col-sm-3">Address</label>
                                <div className="col-sm-8">
                                  <Field
                                    name="address"
                                    type="text"
                                    className="form-control"
                                    placeholder="Your Address"
                                  />
                                  <ErrorMessage
                                    name="address"
                                    component="div"
                                    className="error-message"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group row ">
                                <label className="col-sm-3 ">Password</label>
                                <div className="col-sm-8">
                                  <Field
                                    name="password"
                                    type="password"
                                    className="form-control "
                                    placeholder="password"
                                  />
                                  <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="error-message"
                                  />
                                  {passwordErrors.map((item, i) => (
                                    <p className="error-message" key={i}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group row ">
                                <label className="col-sm-3 ">
                                  Confirm Password
                                </label>
                                <div className="col-sm-8">
                                  <Field
                                    name="confirm_password"
                                    type="password"
                                    className="form-control "
                                    placeholder="password"
                                  />
                                  <ErrorMessage
                                    name="confirm_password"
                                    component="div"
                                    className="error-message"
                                  />
                                  {passwordConfirmationErrors.map((item, i) => (
                                    <p className="error-message" key={i}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="form-group row ">
                            <label className="col-sm-5 offset-sm-1">
                              <button
                                disabled={loading}
                                // disabled={!isValid || !dirty}
                                type="submit"
                                className="btn btn-lg btn-success"
                              >
                                {loading && (
                                  <i className="fa fa-refresh fa-spin"></i>
                                )}
                                {loading && <span>Signing Up</span>}
                                {!loading && <span> Sign Up</span>}
                              </button>
                            </label>
                            <div className="col-sm-8"></div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-sm-11 offset-sm-1">
                              {signedUpMessage} <br />
                              {singnedUp && (
                                <Link
                                  to="/agent/login"
                                  type="button"
                                  className="btn btn-lg btn-primary"
                                >
                                  Login
                                </Link>
                              )}
                            </label>
                          </div>
                          <div className="form-group row ">
                            <div className="col-sm-8 offset-sm-1">
                              Already have an account? Login
                              <Link
                                className="px-1"
                                type="button"
                                to="/agent/login"
                              >
                                here
                              </Link>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-1"></div>
        </div>
      </div>
    </div>
  );
}
