import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import axios from "axios";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import _ from "lodash";
export default function CreatedTestDetails() {
  let history = useHistory();
  let { id } = useParams();
  const [testDetails, setTestDetails] = useState({});
  const theme = useTheme();
  const [updatedTestInvoice, setUpdatedTestInvoice] = useState([]);
  const [updatedCorporate, setUpdatedCorporate] = useState([]);

  // const [path, setPath] = useState();

  useEffect(() => {
    getTestDetails();
  }, []);

  const getTestDetails = () => {
    AdminAPI.get(`incoming-invoices/${id}`)
      .then((response) => {
        setTestDetails(response.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const makeInvoice = (updated = [], id) => {
    const data = updated.filter((test) => test.id === id);
    console.log("DATA", data);
    if (data.length > 0) {
      AdminAPI.post(`incoming-invoice-details/${id}/make-invoice`, {
        diagnostic_test_id: data[0].matched_test_id,
      })
        .then((response) => {
          alert(response.data.message);
          getTestDetails();
        })
        .catch((error) => {
          console.log(error.response);
        });
    } else {
      alert("Fill up the matched test field correctly");
    }
  };

  const handleUpdateTestInput = (test, value) => {
    let index = updatedTestInvoice.findIndex((x) => x.id === test.id);
    if (index !== -1) {
      let temporaryarray = updatedTestInvoice.slice();
      temporaryarray[index]["matched_test_id"] = value;
      setUpdatedTestInvoice(temporaryarray);
    } else {
      let up = [...updatedTestInvoice];
      let obj = {
        id: test.id,
        matched_test_id: value,
      };

      obj["matched_test_id"] = value;
      up.push(obj);

      setUpdatedTestInvoice(up);
    }
  };
  const handleUpdateCorporate = (test, value) => {
    let index = updatedCorporate.findIndex((x) => x.id === test.id);
    if (index !== -1) {
      let temporaryarray = updatedCorporate.slice();
      temporaryarray[index]["corporate_id"] = value;
      setUpdatedCorporate(temporaryarray);
    } else {
      let up = [...updatedCorporate];
      let obj = {
        id: test.id,
        corporate_id: value,
      };

      obj["corporate_id"] = value;
      up.push(obj);

      setUpdatedCorporate(up);
    }
  };
  console.log("Mathced test", updatedTestInvoice);
  console.log("Mathced Corporate", updatedCorporate);
  return (
    <div>
      <div className="container">
        <div className="observation-test-area">
          <div className="form-group row"></div>
          <div className="form-group row">
            <div className="col-md-5">
              Bill no:
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {testDetails.unique_id}
              </span>
            </div>
            <div className="col-md-3">
              Test Date:
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {testDetails.test_date &&
                  moment(testDetails.test_date).format("DD/MM/YYYY")}{" "}
              </span>
            </div>
            <div className="col-md-4">
              Created At:
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {moment(testDetails.created_at).format(
                  "DD/MM/YYYY HH:mm A"
                )}{" "}
              </span>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-md-5">
              Patient:{" "}
              {testDetails.patient_name !== null ? (
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {testDetails.patient_name}
                </span>
              ) : (
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {testDetails.patient.name}
                </span>
              )}
            </div>
            <div className="col-md-3">
              Phone:{" "}
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {testDetails.patient_phone}
              </span>
            </div>
            <div className="col-md-4">
              Gender:{" "}
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {testDetails.patient_gender}
              </span>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-md-12">
              Patient Address:
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {testDetails.patient_address}
              </span>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-md-5">
              Diagnostic Name:
              {testDetails.service_provider !== undefined && (
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {testDetails.service_provider.name}
                </span>
              )}
            </div>
            <div className="col-md-7"></div>
          </div>
          <div className="form-group row">
            <div className="col-md-12">
              Diagnostic Address :
              {testDetails.service_provider !== undefined && (
                <span style={{ fontWeight: "bold" }}>
                  {testDetails.service_provider.address}
                </span>
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-md-12">
              Home Sample Collection :{" "}
              <span style={{ fontWeight: "bold" }}>
                {testDetails.home_delivery ? "Yes" : "No"}{" "}
              </span>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div style={{ overflow: "auto" }} className="">
                <table className="custom-responsive-table">
                  <thead>
                    <tr>
                      <th scope="col">Lab ID</th>
                      <th scope="col">Lab Inv ID</th>
                      <th scope="col">Test</th>
                      <th scope="col">Department</th>
                      <th scope="col">Type</th>
                      <th scope="col">Matched test</th>
                      <th scope="col">Price</th>
                      <th scope="col">Discount</th>
                      <th scope="col">Bill</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(testDetails).includes(
                      "incoming_invoice_details"
                    ) && (
                      <React.Fragment>
                        {testDetails.incoming_invoice_details.map(
                          (detail, i) => (
                            <tr key={i}>
                              <td data-column="Lab ID">{detail.lab_id}</td>
                              <td data-column="Lab Inv ID">
                                {testDetails.lab_invoice_id}
                              </td>

                              <td data-column="Test">{detail.test_name}</td>
                              <td data-column="Department">
                                {detail.test_department}
                              </td>
                              <Test
                                handleCorporte={(test, value) => {
                                  handleUpdateCorporate(test, value);
                                }}
                                invoice={testDetails}
                                testInvoice={detail}
                                handleData={(test, value) => {
                                  handleUpdateTestInput(test, value);
                                }}
                                value={
                                  detail.diagnostic_test != null
                                    ? detail.diagnostic_test.name
                                    : ""
                                }
                              />

                              <td data-column="Price">
                                {Math.ceil(detail.price)}
                              </td>
                              <td data-column="Discount">{detail.discount}</td>
                              <td data-column="Bill">{detail.bill}</td>

                              <td data-column="Status">{detail.status}</td>

                              <td data-column="Action">
                                <button
                                  onClick={() => {
                                    // console.log(
                                    //   "updated",
                                    //   detail.id,
                                    //   updatedTestInvoice
                                    // );
                                    makeInvoice(updatedTestInvoice, detail.id);
                                  }}
                                >
                                  Update
                                </button>
                              </td>
                            </tr>
                          )
                        )}
                      </React.Fragment>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const Test = ({ invoice, value, handleCorporte, handleData, testInvoice }) => {
  const [corporateList, setCorporateList] = useState([]);
  const [corporateValue, setCorporateValue] = useState("");
  const [testType, setTestType] = useState("Patient");
  const [typeParams, setTypeParams] = useState({
    page: 1,
    per_page: 15,
  });
  const [testList, setTestList] = useState([]);
  const [testValue, setTestValue] = useState(value);
  const [testParams, setTestParams] = useState({
    page: 1,
    per_page: 15,
  });

  const handleOnInputCaptureDis = _.debounce((event) => {
    handleColumnWiseSearch(event.target.value, "name");
  }, 500);

  useEffect(() => {
    if (invoice && invoice.service_provider_id && testInvoice) {
      setTestParams({
        ...testParams,
        service_provider_id: invoice.service_provider_id,
      });
      if (testInvoice.diagnostic_test && testInvoice.diagnostic_test.name) {
        setTestValue(testInvoice.diagnostic_test.name);
      }
    }
    return () => {};
  }, [invoice]);
  useEffect(() => {
    if (
      testParams.service_provider_id &&
      testType === "Patient" &&
      testParams.name
    ) {
      getDiagTestList();
    }
    if (
      testParams.service_provider_id &&
      testType === "Corporate" &&
      testParams.search
    ) {
      setTestValue("");
      getCorporateTestList();
    }

    return () => {};
  }, [testParams, testType]);
  const handleColumnWiseSearch = (inputValue, column) => {
    const paramState = { ...testParams };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setTestParams(paramState);
  };
  const getDiagTestList = () => {
    AdminAPI.get("diagnostic-tests", {
      params: testParams,
    })
      .then((response) => {
        console.log("Test list", response.data.data);
        setTestList(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const getCorporateTestList = () => {
    AdminAPI.get("corporate-tests", {
      params: testParams,
    })
      .then((response) => {
        console.log("Test list", response.data.data);
        setTestList(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleOnInputCaptureDisCorp = _.debounce((event) => {
    handleColumnWiseSearchCorp(event.target.value, "search");
  }, 500);

  useEffect(() => {
    if (typeParams.search) getCorpList();

    return () => {};
  }, [typeParams]);
  const handleColumnWiseSearchCorp = (inputValue, column) => {
    const paramState = { ...typeParams };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setTypeParams(paramState);
  };
  const getCorpList = () => {
    AdminAPI.get("corporates", {
      params: typeParams,
    })
      .then((response) => {
        console.log("COrporte list", response.data.data);
        setCorporateList(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  return (
    <React.Fragment>
      <td data-column="Type">
        <div style={{ minWidth: "150px" }}>
          <select
            className="form-control"
            value={testType}
            onChange={(e) => {
              setTestType(e.target.value);
            }}
          >
            <option value="Patient">Patient</option>
            <option value="Corporate">Corporate</option>
          </select>
          {testType === "Corporate" && (
            <Autocomplete
              style={{ width: 250 }}
              freeSolo
              id="free-solo-2-demo"
              value={corporateValue}
              options={corporateList}
              getOptionLabel={(option) => {
                return option.name || corporateValue;
              }}
              onInputCapture={(event) => {
                event.persist(); // As we are accessing the event in asynchronous way.
                handleOnInputCaptureDisCorp(event);
              }}
              onChange={(e, value) => {
                if (value == null) {
                  console.log("Hello");
                  setCorporateValue("");
                } else {
                  console.log(value);
                  setCorporateValue(value);
                  handleCorporte(testInvoice, value.id);
                }
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          )}
        </div>
      </td>
      <td data-column="Matched test">
        <div>
          <Autocomplete
            freeSolo
            style={{ width: 300 }}
            id="free-solo-2-demo"
            value={testValue}
            options={testList}
            onInputCapture={(event) => {
              event.persist(); // As we are accessing the event in asynchronous way.
              handleOnInputCaptureDis(event);
            }}
            getOptionLabel={(option) => {
              return option.name || testValue;
            }}
            onChange={(e, value) => {
              if (value == null) {
                console.log("Hello");
                setTestValue("");
              } else {
                console.log(value);
                setTestValue(value);
                handleData(testInvoice, value.id);
              }
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </div>
      </td>
    </React.Fragment>
  );
};
