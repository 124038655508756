import React from "react";

function SuccessMessage() {
  return (
    <div>
      <h1>Success</h1>
    </div>
  );
}

export default SuccessMessage;
